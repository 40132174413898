import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  Button,
  Checkbox,
  FormGroup,
  FormControl,
  FormControlLabel,
  Grid,
  Typography,
  TextField,
  TextareaAutosize,
  MenuItem,
} from "@material-ui/core";
import { ArrowBack, ArrowForward } from "@material-ui/icons";

import { MANAGER_ROUTES } from "../../utils/constants";
import { common, form, navigation } from "../../messages";
import { DatePicker, TimePicker } from "../../ui/DatePicker";

import FormSection from "./input/FormSection";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { PERIOD_TYPE } from "../../utils/campaignMessagesConstants";
import { intlFormatMessage } from "../../utils/utils";
import { startOfDay } from "date-fns";
//import { format } from "date-fns";

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline"],
    ["clean"],
  ],
  //htmlEditButton: { debug: true, syntax: true },
};

function Navigation() {
  const intl = useIntl();

  return (
    <FormControl margin="normal" fullWidth>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid item>
          <Button
            component={RouterLink}
            to={MANAGER_ROUTES.MESSAGES_CAMPAIGNS.replace(":campaignId", "")}
            startIcon={<ArrowBack />}
          >
            {intl.formatMessage(common.backMessages)}
          </Button>
        </Grid>
        <Grid item>
          <Button
            component={RouterLink}
            to={MANAGER_ROUTES.HOME}
            startIcon={<ArrowForward />}
          >
            {intl.formatMessage(navigation.goHome)}
          </Button>
        </Grid>
      </Grid>
    </FormControl>
  );
}

export default function MessageCampaingCreateForm({
  handleSubmit,
  title,
  body,
}) {
  const intl = useIntl();
  const [values, setValues] = useState({
    title: title,
    body: body,
    expire_at: null,
    banner_close: false,
    banner_close_mode: "DAY",
    banner_close_date:
      "" /*format(new Date(), "yyyy-MM-dd00:00").replace(
      /(\d{4}-\d{2}-\d{2})(\d{2}:\d{2})/,
      "$1T$2"
    ),*/,
    banner_close_number: 1,
    close_date: new Date(),
    close_time: startOfDay(new Date()),
  });
  const [valueRichEditor, setValueRichEditor] = useState("");
  const [errorRichEditor, setErrorRichEditor] = useState("");
  const today = new Date();

  const isDate = values.banner_close_mode === "DAY";

  const onSubmit = (e) => {
    setErrorRichEditor("");
    e.preventDefault();
    handleSubmit(values);
  };

  const handleChange = useCallback((label) => {
    const updateValue = (val) => {
      setValues((v) => ({ ...v, [label]: val }));
    };

    return (i, f) => {
      if (typeof f === "boolean") {
        if ((label === "banner" || label === "banner_close") && !f) {
          updateValue(false);
          setValues((v) => ({
            ...v,
            banner_close: false,
            banner_close_mode: "DAY",
            //banner_close_date: format(new Date(), "yyyy-MM-dd"),
            /*banner_close_date: format(new Date(), "yyyy-MM-dd00:00").replace(
              /(\d{4}-\d{2}-\d{2})(\d{2}:\d{2})/,
              "$1T$2"
            ),*/
            banner_close_number: 1,
            close_date: new Date(),
            close_time: startOfDay(new Date()),
          }));
        } else {
          updateValue(f);
        }
      } else if (i?.target) {
        updateValue(i.target.value);
      } else {
        updateValue(i);
      }
    };
  }, []);

  return (
    <Box className="msg">
      <form onSubmit={onSubmit}>
        <FormSection>
          <Box style={{ minHeight: "300px" }}>
            <TextField
              name="title"
              label={intl.formatMessage(form.title)}
              value={values.title}
              onChange={handleChange("title")}
              key="title"
              fullWidth
              margin="normal"
              required
            />
            <Typography
              className="MuiFormLabel-root"
              style={
                errorRichEditor == ""
                  ? { fontSize: "16px", margin: "10px 0px" }
                  : {
                      fontSize: "16px",
                      margin: "10px 0px",
                      color: "red",
                    }
              }
            >
              {intl.formatMessage(common.text)} *
            </Typography>
            <TextareaAutosize
              name="body"
              label={intl.formatMessage(common.text)}
              value={values.body}
              onChange={handleChange("body")}
              key="body"
              style={{
                width: "100%",
                height: "200px",
              }}
              aria-label="maximum height"
              placeholder={intl.formatMessage(form.insertMessage)}
            />
            {false && (
              <ReactQuill
                className={errorRichEditor != "" ? "red1d" : ""}
                theme="snow"
                modules={modules}
                value={valueRichEditor}
                placeholder={intl.formatMessage(form.insertMessage)}
                onChange={setValueRichEditor}
                error={errorRichEditor == ""}
              />
            )}
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={values.banner}
                    onChange={handleChange("banner")}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={intl.formatMessage(form.banner)}
              />
              {values.banner && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.banner_close}
                      onChange={handleChange("banner_close")}
                    />
                  }
                  label={intl.formatMessage(form.bannerClose)}
                />
              )}
            </FormGroup>
            {values.banner_close && (
              <>
                <TextField
                  margin="normal"
                  autoFocus
                  label={intl.formatMessage(form.selectPeriod)}
                  value={values.banner_close_mode}
                  onChange={handleChange("banner_close_mode")}
                  fullWidth
                  select
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {PERIOD_TYPE.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {intlFormatMessage(
                        common[option.label],
                        option.label,
                        intl
                      )}
                    </MenuItem>
                  ))}
                </TextField>
                {isDate ? (
                  <>
                    <DatePicker
                      label={intl.formatMessage(form.selectDate)}
                      value={values.close_date}
                      onChange={handleChange("close_date")}
                      fullWidth
                      margin="normal"
                      required
                      clearable
                    />
                    <TimePicker
                      label={intl.formatMessage(form.selectTime)}
                      value={values.close_time}
                      onChange={handleChange("close_time")}
                      fullWidth
                      margin="normal"
                      clearable
                    />
                  </>
                ) : (
                  <TextField
                    required
                    margin="normal"
                    label={intl.formatMessage(form.insertQuantity)}
                    fullWidth
                    autoFocus
                    type="number"
                    value={values.banner_close_number}
                    onChange={handleChange("banner_close_number")}
                    onInput={(e) => {
                      e.target.value =
                        e.target.value !== ""
                          ? e.target.value === "0"
                            ? e.target.value.replace(/^[0]|[^0-9]/g, "")
                            : +e.target.value.replace(/^[0]|[^0-9]/g, "")
                          : e.target.value;
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      min: "1",
                    }}
                  />
                )}
              </>
            )}
            {/*             {values.banner_close && (
              <Box display="flex">
                <TextField
                  margin="normal"
                  autoFocus
                  label={intl.formatMessage(form.selectPeriod)}
                  value={values.banner_close_mode}
                  onChange={handleChange("banner_close_mode")}
                  fullWidth
                  select
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {PERIOD_TYPE.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {intlFormatMessage(
                        common[option.label],
                        option.label,
                        intl
                      )}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  required
                  margin="normal"
                  label={
                    isDate
                      ? intl.formatMessage(form.selectDate)
                      : intl.formatMessage(form.insertQuantity)
                  }
                  fullWidth
                  autoFocus
                  type={isDate ? "datetime-local" : "number"}
                  value={
                    isDate
                      ? values.banner_close_date
                      : values.banner_close_number
                  }
                  onChange={
                    isDate
                      ? handleChange("banner_close_date")
                      : handleChange("banner_close_number")
                  }
                  onInput={
                    isDate
                      ? false
                      : (e) => {
                          e.target.value =
                            e.target.value !== ""
                              ? e.target.value === "0"
                                ? e.target.value.replace(/^[0]|[^0-9]/g, "")
                                : +e.target.value.replace(/^[0]|[^0-9]/g, "")
                              : e.target.value;
                        }
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: "1",
                  }}
                />
              </Box>
            )} */}

            <FormControlLabel
              control={
                <Checkbox
                  checked={values.push_notification}
                  onChange={handleChange("push_notification")}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={intl.formatMessage(form.pushNotification)}
            />
            <DatePicker
              minDate={today}
              margin="normal"
              fullWidth
              label="Expire At"
              format="yyyy-MM-dd"
              value={values.expire_at}
              onChange={handleChange("expire_at")}
            />
            <TextField
              name="app_link_label"
              label="App Link Label"
              value={values.app_link_label}
              onChange={handleChange("app_link_label")}
              key="app_link_label"
              fullWidth
              margin="normal"
            />
            <TextField
              name="app_link_uri"
              label="App Link URI"
              value={values.app_link_uri}
              onChange={handleChange("app_link_uri")}
              key="app_link_uri"
              fullWidth
              margin="normal"
            />
            <TextField
              name="image_url"
              label="Image URL"
              value={values.image_url}
              onChange={handleChange("image_url")}
              key="image_url"
              fullWidth
              margin="normal"
            />
            <TextField
              name="link_uri"
              label="Link URI"
              value={values.link_uri}
              onChange={handleChange("link_uri")}
              key="link_uri"
              fullWidth
              margin="normal"
            />
          </Box>
        </FormSection>
        <Button variant="contained" fullWidth color="primary" type="submit">
          {intl.formatMessage(title ? common.save : common.create)}
        </Button>
      </form>
      <Navigation />
    </Box>
  );
}
