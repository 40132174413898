import React, { useState, useEffect, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { Container, IconButton } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

import CommonCreateForm from "../../ui/forms/CommonCreateForm";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { getInitialState } from "../../utils/campaignCreation";
import { MANAGER_ROUTES } from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";
import { addDays, addMonths } from "date-fns";
import { parseBmarkenDate } from "../../utils/utils";
import { errors } from "../../messages";

export default function CampaignEdit() {
  const { campaignId } = useParams();
  const intl = useIntl();
  const history = useHistory();
  const {
    bmapi,
    notifySuccess,
    startLoading,
    stopLoading,
    notifyError,
  } = useBmapi();
  const [campaign, setCampaign] = useState();
  const [initialValues, setInitialValues] = useState(false);
  const [performance, setPerformance] = useState(false);
  const [dirty, setDirty] = useState(false);

  useEffect(() => {
    startLoading();
    bmapi.getCampaignDetails(campaignId).then(({ campaign }) => {
      if (campaign.product_lifespan_type === "") {
        campaign.product_lifespan_type = "M";
      }
      campaign.max_product_date = getMaxProductDate(campaign);
      campaign.use_frequency_per_month = getUseFrequency(campaign);
      setCampaign(campaign);
      setInitialValues(getInitialState(false, bmapi, false, campaign));
      stopLoading();
    });
    loadPerformance();
  }, [bmapi, campaignId, startLoading, stopLoading, loadPerformance]);

  const getMaxProductDate = (campaign) => {
    let maxDate;
    if (campaign.product_lifespan) {
      if (campaign.product_lifespan_type === "D") {
        maxDate = addDays(
          parseBmarkenDate(campaign.expiration_date),
          campaign.product_lifespan
        );
      } else {
        maxDate = addMonths(
          parseBmarkenDate(campaign.expiration_date),
          campaign.product_lifespan
        );
      }
    }
    return (maxDate =
      !campaign.product_limit_date ||
      parseBmarkenDate(campaign.product_limit_date) < maxDate
        ? maxDate
        : campaign.product_limit_date);
  };

  const getUseFrequency = (campaign) => {
    if (campaign.rules.use_period !== -30) {
      if (campaign.rules.use_period === -7) {
        return campaign.rules.use_frequency * 4;
      } else if (campaign.rules.use_period === -1) {
        return campaign.rules.use_frequency * 30;
      }
    } else {
      return campaign.rules.use_frequency;
    }
  };

  const loadPerformance = useCallback(() => {
    setPerformance(false);
    bmapi
      .getCampaignDetails(campaignId)
      .then(({ performance }) => {
        setPerformance(performance);
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  }, [bmapi, intl, notifyError, campaignId]);

  const handleSubmit = (values, after) => {
    startLoading();

    return bmapi
      .saveCampaign(values, campaignId)
      .then(after)
      .then(() => {
        notifySuccess(
          intl.formatMessage({
            id: "pages.campaignEdit.saved",
            defaultMessage: "Campagna modificata con successo",
          })
        );
        history.push(MANAGER_ROUTES.CAMPAIGNS.replace(":filter?/", ""));
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
        if (
          getErrorMessageString(e, intl).includes(
            "[VTENEX synchronization ERROR]: response error - code: 500"
          )
        ) {
          notifyError(intl.formatMessage(errors.syncCRM));
          setDirty(false);
          history.push(MANAGER_ROUTES.CAMPAIGNS.replace(":filter?/", ""));
        }
      })
      .finally(() => {
        stopLoading();
      });
    //.catch(stopLoading);
  };

  return (
    <Container maxWidth="sm">
      <Title>
        <IconButton
          component={RouterLink}
          to={MANAGER_ROUTES.CAMPAIGNS.replace(":filter?/", "")}
          size="small"
        >
          <ArrowBack />
        </IconButton>{" "}
        <FormattedMessage
          id="pages.campaignEdit.title"
          defaultMessage="Modifica campagna per {businessName}"
          values={{
            businessName: <strong>{bmapi.getUserInfo().business.name}</strong>,
          }}
        />
      </Title>

      {!!initialValues && (
        <CommonCreateForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          campaign={campaign}
          performance={performance}
          dirty={dirty}
          setDirty={setDirty}
        />
      )}
    </Container>
  );
}
