import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import FormSection from "../../ui/forms/input/FormSection";
import Title from "../../ui/Title";
import { DatePicker } from "../../ui/DatePicker";
import {
  STATS,
  STATUS,
  TASK_ERRORS,
  TASK_RESULTS,
} from "../../utils/settingsConstants";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { useIntl } from "react-intl";
import { format } from "date-fns";
import { common, navigation } from "../../messages";
import { settings } from "../../messages/settings";
import {
  getArrLabel,
  intlFormatMessage,
  removeParams,
} from "../../utils/utils";
import { ArrowBack, Info } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import { MANAGER_ROUTES } from "../../utils/constants";

function ResultsTable({ arr, constArr, getResult, useId }) {
  const intl = useIntl();
  return (
    <React.Fragment>
      {arr.map((element, i) => (
        <Box mt={2} key={element.id}>
          {i !== 0 && (
            <Divider
              key={i}
              variant="middle"
              style={{ border: "1px solid #bdbdbd", marginBottom: 10 }}
            />
          )}
          <TableContainer>
            <Table size="small">
              <TableBody>
                {constArr.map((el) => (
                  <TableRow key={el.value}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ fontWeight: 500 }}
                    >
                      {intlFormatMessage(settings[el.label], el.label, intl)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {getResult(i, el, useId ? element.id : null)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ))}
    </React.Fragment>
  );
}

function TaskMonitoring() {
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const intl = useIntl();
  const initialFilter = {
    status: "-",
    from: new Date(),
    to: null,
    processType: null,
    processRef: null,
  };
  const [filter, setFilter] = useState(initialFilter);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [taskStats, setTaskStats] = useState([]);
  const [taskErrors, setTaskErrors] = useState([]);

  const handleFilter = useCallback(
    (varName, type) => (e) => {
      ((val) => setFilter((v) => ({ ...v, [varName]: val })))(
        type ? e : e.target.value
      );
    },
    []
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    const params = {
      ...filter,
      status: filter.status === "-" ? null : filter.status,
      from: format(filter.from, "yyyyMMdd"),
      to: filter.to ? format(filter.to, "yyyyMMdd") : null,
    };

    if (bmapi) {
      startLoading();
      bmapi
        .getTasks(removeParams(params))
        .then((resp) => {
          if (!resp) {
            resp = [];
          }
          setTasks(resp);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    }
  };

  const showTask = (id) => {
    setTaskId(id);

    if (bmapi) {
      startLoading();
      bmapi
        .getTaskStats({ task: id })
        .then((resp) => {
          if (!resp) {
            resp = [];
          }
          setTaskStats(resp);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    }
  };

  const getErrors = () => {
    setShowErrors(!showErrors);

    if (bmapi && taskErrors.length === 0) {
      startLoading();
      bmapi
        .getTaskErrors({ task: taskId })
        .then((resp) => {
          if (!resp) {
            resp = [];
          }
          setTaskErrors(resp);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    }
  };

  const getResult = (i, varName, id) => {
    if (varName.value === "status") {
      const label = getArrLabel(STATUS, tasks[i][varName.value]);

      return label === "running" || label === "executedErrors" ? (
        <React.Fragment>
          {intlFormatMessage(common[label], label, intl)}
          <IconButton
            size="small"
            onClick={() => {
              showTask(id);
              setOpen(true);
            }}
          >
            <Info />
          </IconButton>
        </React.Fragment>
      ) : (
        intlFormatMessage(common[label], label, intl)
      );
    }

    if (id) return tasks[i][varName.value] || "-";
    else return taskErrors[i][varName.value] || "-";
  };

  return (
    <React.Fragment>
      {/* FILTER */}

      <form onSubmit={handleSubmit}>
        <DatePicker
          label={intl.formatMessage(common.filterFrom)}
          value={filter.from}
          onChange={handleFilter("from", "date")}
          fullWidth
          clearable
        />
        <DatePicker
          label={intl.formatMessage(common.filterTo)}
          value={filter.to}
          onChange={handleFilter("to", "date")}
          fullWidth
          minDate={filter.from}
          clearable
          onError={setError}
        />
        <TextField
          fullWidth
          margin="normal"
          select
          label={intl.formatMessage(common.filterStatus)}
          value={filter.status}
          onChange={handleFilter("status")}
        >
          {STATUS.map((s) => (
            <MenuItem key={s.value} value={s.value}>
              {intlFormatMessage(common[s.label], s.label, intl)}
            </MenuItem>
          ))}
        </TextField>
        <FormControl style={{ float: "right" }} margin="normal">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={error}
          >
            {intl.formatMessage(common.openView)}
          </Button>
        </FormControl>
      </form>

      {/* TASK TABLE */}
      {tasks.length !== 0 && (
        <ResultsTable
          arr={tasks}
          constArr={TASK_RESULTS}
          getResult={getResult}
          useId={true}
        />
      )}
      {tasks.length === 0 && (
        <Box mt={2}>
          <Typography>{intl.formatMessage(common.noElementToShow)}</Typography>
        </Box>
      )}

      {/* POP UP ESECUZIONE TASK */}
      {taskStats.length !== 0 && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={open}
          onClose={() => {
            setOpen(false);
            setTaskStats([]);
            setTaskId(null);
            setTaskErrors([]);
          }}
        >
          <DialogTitle>{intl.formatMessage(settings.executing)}</DialogTitle>
          <DialogContent>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {Object.entries(taskStats).map((t) => (
                    <TableRow key={t[0]}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ fontWeight: 500 }}
                      >
                        {getArrLabel(STATS, t[0])}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {t[1]}
                      </TableCell>
                      <TableCell align="right">
                        {t[0] === "Errors" && t[1] > 0 && (
                          <Button onClick={getErrors} variant="contained">
                            {intl.formatMessage(settings.showErrors)}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
                setTaskStats([]);
                setTaskId(null);
                setTaskErrors([]);
              }}
              color="primary"
            >
              {intl.formatMessage(common.close)}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* POP UP TASK TABLE ERRORS */}
      {showErrors && (
        <Dialog
          fullWidth
          maxWidth="sm"
          open={showErrors}
          onClose={() => {
            setShowErrors(false);
          }}
        >
          <DialogTitle>{intl.formatMessage(settings.executing)}</DialogTitle>
          <DialogContent>
            <ResultsTable
              arr={taskErrors}
              constArr={TASK_ERRORS}
              getResult={getResult}
              useId={false}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setShowErrors(false);
              }}
              color="primary"
            >
              {intl.formatMessage(common.close)}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </React.Fragment>
  );
}

export default function SettingsManagement() {
  const intl = useIntl();

  return (
    <Container maxWidth="xl">
      <Title>{intl.formatMessage(navigation.settings)}</Title>
      <FormSection title={intl.formatMessage(settings.taskMonitoring)}>
        <TaskMonitoring />
      </FormSection>
      <Button
        component={RouterLink}
        to={MANAGER_ROUTES.HOME}
        startIcon={<ArrowBack />}
      >
        {intl.formatMessage(navigation.goHome)}
      </Button>
    </Container>
  );
}
