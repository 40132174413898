import { defineMessages } from "react-intl";

export const roles = defineMessages({
  campaignManager: {
    id: "common.roles.campaignManager",
    defaultMessage: "Campaign Manager",
  },
  manager: {
    id: "common.roles.manager",
    defaultMessage: "Manager",
  },
  operator: {
    id: "common.roles.operator",
    defaultMessage: "Operator",
  },
  storeAssistant: {
    id: "common.roles.storeAssistant",
    defaultMessage: "Administrator Assistant",
  },
  storeManager: {
    id: "common.roles.storeManager",
    defaultMessage: "Administrator",
  },
  viewer: {
    id: "common.roles.viewer",
    defaultMessage: "Viewer",
  },
  subscriptionManager: {
    id: "common.roles.subscriptionManager",
    defaultMessage: "Subscription Manager",
  },
  subscriptionPlanManager: {
    id: "common.roles.subscriptionPlanManager",
    defaultMessage: "Subscription Plan Manager",
  },
  subscriptionStampManager: {
    id: "common.roles.subscriptionStampManager",
    defaultMessage: "Subscription Stamp Manager",
  },
});
