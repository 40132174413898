import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { format } from "date-fns";
import React, { useCallback } from "react";
import { useIntl } from "react-intl";
import { common } from "../messages";
import { useBmapi } from "../utils/bmapi-context";
import { getErrorMessageString } from "../utils/errors";
import utils from "../utils/utils";
import PushNotifications from "./PushNotifications";

const getDateString = (date) => {
  return date.substring(0, 8).replace(/(\d{4})(\d{2})(\d{2})/g, "$1-$2-$3");
};

const getDateHourString = (date) => {
  return date
    .substring(0, 12)
    .replace(/(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/g, "$1-$2-$3-$4-$5");
};

function Report({ data, groupBy, id, password, anonymous, encrypt }) {
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const intl = useIntl();

  const formatDate = (d) => {
    const newDate = getDateString(d);
    const formattedDate = format(new Date(newDate), "dd/MM/yyyy");
    return !groupBy
      ? formattedDate
      : formattedDate + " " + d.substring(8, 10) + ":00";
  };

  const byDate = (a, b) => {
    if (groupBy) {
      return getDateHourString(a[0]) > getDateHourString(b[0]);
    } else {
      return getDateString(a[0]) > getDateString(b[0]);
    }
  };

  const downloadCheckin = (row) => {
    if (!anonymous && encrypt && password === "")
      notifyError("Inserire una password");
    else {
      startLoading();

      const params = {
        eventAction: id,
        timeWindow: groupBy ? row : null,
        anonymous: anonymous || null,
      };

      for (const property in params) {
        if (params[property] === null) {
          delete params[property];
        }
      }

      const pwd = { pwd: password };

      const name = anonymous ? "anonymous_" : "";
      const date = format(new Date(), "yyyy-MM-dd_HH_mm");
      const ext = password === "" ? "csv" : "zip";

      bmapi
        .checkinDowload(pwd, params)
        .then((blob) =>
          utils.download(blob, `export_checkins_${name}${date}.${ext}`)
        )
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    }
  };

  return (
    <React.Fragment>
      <Box mt={2}>
        <Card>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Data e ora</TableCell>
                  <TableCell>Numero</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Object.entries(data)
                  .sort(byDate)
                  .map((d) => (
                    <TableRow
                      key={d[0]}
                      onClick={() => downloadCheckin(d[0])}
                      hover
                      style={{ cursor: "pointer" }}
                    >
                      <React.Fragment>
                        <TableCell>{formatDate(d[0])}</TableCell>
                        <TableCell>{d[1]}</TableCell>
                      </React.Fragment>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Box>
    </React.Fragment>
  );
}

const CheckInExtraction = ({ id }) => {
  const intl = useIntl();
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const [open, setOpen] = React.useState(false);
  const [groupBy, setGroupBy] = React.useState(false);
  const [personalData, setPersonalData] = React.useState(false);
  const [encrypt, setEncrypt] = React.useState(false);
  const [checkIns, setCheckIns] = React.useState(null);
  const [password, setPassword] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  const loadCheckins = useCallback(
    (split) => {
      startLoading();
      if (bmapi) {
        const params = {
          eventAction: id,
          splitBy: split ? "H" : null,
        };

        bmapi
          .getCheckins(params)
          .then((resp) => {
            if (!resp) {
              resp = [];
              setCheckIns(resp);
            } else {
              setCheckIns(resp);
            }
          })
          .catch((e) => {
            notifyError(getErrorMessageString(e, intl));
          })
          .finally(() => {
            stopLoading();
          });
      }
    },
    [bmapi, intl, notifyError, startLoading, stopLoading, id]
  );

  const handleClickOpen = () => {
    setOpen(true);
    loadCheckins(false);
  };

  const handleClose = () => {
    setOpen(false);
    setCheckIns(null);
    setGroupBy(false);
    setPersonalData(false);
    setPassword("");
  };

  const handleChange = (event) => {
    setGroupBy(event.target.checked);
    setCheckIns(null);
    loadCheckins(event.target.checked);
  };

  return (
    <React.Fragment>
      <FormControl fullWidth margin="normal">
        <Button variant="contained" color="secondary" onClick={handleClickOpen}>
          ESTRAZIONE
        </Button>
      </FormControl>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
        <DialogTitle>Estrazione</DialogTitle>
        <DialogContent>
          {checkIns && JSON.stringify(checkIns) === "{}" && (
            <Typography>Non ci sono checkin da mostrare.</Typography>
          )}
          {checkIns && JSON.stringify(checkIns) !== "{}" && (
            <React.Fragment>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={groupBy}
                    onChange={handleChange}
                    name="groupBy"
                    color="primary"
                  />
                }
                label="Raggruppa per ora"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={personalData}
                    onChange={(e) => {
                      setPersonalData(e.target.checked);
                      setPassword("");
                      setEncrypt(true);
                    }}
                    name="personalData"
                    color="primary"
                  />
                }
                label="Includi dati personali"
              />
              {personalData && (
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={3} style={{ marginTop: 10 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={encrypt}
                          onChange={(e) => {
                            setEncrypt(e.target.checked);
                            setPassword("");
                          }}
                          name="encrypt"
                          color="primary"
                        />
                      }
                      label="Cripta il file"
                    />
                  </Grid>
                  {encrypt && (
                    <Grid item xs={12} sm={9}>
                      <TextField
                        label={intl.formatMessage(common.password)}
                        name="password"
                        value={password}
                        type={showPassword ? "text" : "password"}
                        id="password"
                        autoComplete="new-password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                        error={password.length < 8}
                        helperText={
                          password.length < 8 &&
                          "La password deve contenere minimo 8 caratteri"
                        }
                        fullWidth
                        required
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              )}
              <Report
                data={checkIns}
                groupBy={groupBy}
                id={id}
                password={password}
                anonymous={!personalData}
                encrypt={encrypt}
              />
              <Box mt={8}>
                <PushNotifications list={false} />
              </Box>
            </React.Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {intl.formatMessage(common.close)}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default CheckInExtraction;
