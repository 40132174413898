import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Confirm from "./Confirm";
import { useBmapi } from "../utils/bmapi-context";
import { getErrorMessageString } from "../utils/errors";

export default function CampaignDelete({
  campaign,
  open,
  onSuccess,
  onCancel,
}) {
  const {
    bmapi,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  const intl = useIntl();

  const handleDelete = () => {
    startLoading();

    return bmapi
      .deleteCampaign(campaign.campaign_id || campaign.id)
      .then(() => {
        onSuccess();
        notifySuccess(
          intl.formatMessage(
            {
              id: "component.campaignDelete.successMessage",
              defaultMessage: "Hai eliminato con successo la campagna {name}",
            },
            {
              name: campaign.name,
            }
          )
        );
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    onCancel();
  };

  return (
    <Confirm
      open={open}
      onConfirm={handleDelete}
      onCancel={handleCancel}
      title={campaign.name}
      text={
        <FormattedMessage
          id="component.campaignDelete.confirm"
          defaultMessage="Sei sicuro di voler eliminare la campagna?"
        />
      }
      flag={true}
    />
  );
}
