import { defineMessages } from "react-intl";

export const campaignPerformance = defineMessages({
  balance: {
    id: "component.campaignPerformance.balance",
    defaultMessage: "Saldo",
  },
  plays: {
    id: "component.campaignPerformance.plays",
    defaultMessage: "Giocate effettuate",
  },
  issuedCards: {
    id: "component.campaignPerformance.issuedCards",
    defaultMessage: "Carte rilasciate",
  },
  issuedPoints: {
    id: "component.campaignPerformance.issuedPoints",
    defaultMessage: "Punti rilasciati",
  },
  usedPoints: {
    id: "component.campaignPerformance.usedPoints",
    defaultMessage: "Punti usati",
  },
  expiredPoints: {
    id: "component.campaignPerformance.expiredPoints",
    defaultMessage: "Punti scaduti",
  },
  participatingStores: {
    id: "component.campaignPerformance.participatingStores",
    defaultMessage: "Negozi aderenti",
  },
  remainingValue: {
    id: "component.campaignPerformance.remainingValue",
    defaultMessage: "Valore residuo",
  },
  expiredValue: {
    id: "component.campaignPerformance.expiredValue",
    defaultMessage: "Valore scaduto",
  },
  usedValue: {
    id: "component.campaignPerformance.usedValue",
    defaultMessage: "Valore usato",
  },
  lastUsedCoupon: {
    id: "component.campaignPerformance.lastUsedCoupon",
    defaultMessage: "Ultimo coupon usato",
  },
  usedCoupons: {
    id: "component.campaignPerformance.usedCoupons",
    defaultMessage: "Coupon usati",
  },
  availablesCoupons: {
    id: "component.campaignPerformance.availablesCoupons",
    defaultMessage: "Coupon disponibili",
  },
  issuedCoupons: {
    id: "component.campaignPerformance.issuedCoupons",
    defaultMessage: "Coupon rilasciati",
  },
  expiredCoupons: {
    id: "component.campaignPerformance.expiredCoupons",
    defaultMessage: "Coupon scaduti",
  },
  lastUsedPass: {
    id: "component.campaignPerformance.lastUsedPass",
    defaultMessage: "Ultimo pass usato",
  },
  usedPasses: {
    id: "component.campaignPerformance.usedPasses",
    defaultMessage: "Pass usati",
  },
  availablesPasses: {
    id: "component.campaignPerformance.availablesPasses",
    defaultMessage: "Pass disponibili",
  },
  issuedPasses: {
    id: "component.campaignPerformance.issuedPasses",
    defaultMessage: "Pass rilasciati",
  },
  expiredPasses: {
    id: "component.campaignPerformance.expiredPasses",
    defaultMessage: "Pass scaduti",
  },
  allStores: {
    id: "component.campaignPerformance.allStores",
    defaultMessage:
      "Le statistiche visualizzate sono quelle generate da tutti i punti vendita",
  },
  onlyMine: {
    id: "component.campaignPerformance.onlyMine",
    defaultMessage:
      "Le statistiche visualizzate sono solo quelle generate dal punto vendita",
  },
});
