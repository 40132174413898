import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { useIntl } from "react-intl";
import { format } from "date-fns";
import { account, common, confirm } from "../../messages";
import Confirm from "../../ui/Confirm";
import { useHistory } from "react-router-dom";
import { MANAGER_ROUTES } from "../../utils/constants";
import { ArrowBack } from "@material-ui/icons";
//import { employeeAccess } from "../../messages/employeeAccess";

export default function Reviews() {
  const {
    bmapi,
    notifyError,
    startLoading,
    stopLoading,
    notifySuccess,
  } = useBmapi();
  const intl = useIntl();
  const history = useHistory();
  const [reviews, setReviews] = useState([]);
  //const [selection, setSelection] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [idAction, setIdAction] = useState("");
  const [statusAction, setStatusAction] = useState("");
  const [statusFilter, setStatusFilter] = useState("PENDING");

  const onDelete = statusAction === "CANCELED";

  const goToHome = () => {
    history.push(MANAGER_ROUTES.HOME_MENU);
  };

  const loadReviews = useCallback(() => {
    startLoading();
    if (bmapi) {
      bmapi
        .getReviews({ type: "REVIEW", status: statusFilter })
        .then((resp) => {
          if (!resp) {
            resp = [];
          }
          bmapi.getUserInfo().business.type === "Loop"
            ? setReviews(resp)
            : setReviews(
                resp.filter(
                  (r) => r.business_id === bmapi.getUserInfo().business.id
                )
              );
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    }
  }, [bmapi, notifyError, startLoading, stopLoading, intl, statusFilter]);

  useEffect(() => {
    loadReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadReviews]);

  const handleActions = (id, status) => {
    setShowDeleteAlert(true);
    setIdAction(id);
    setStatusAction(status);
  };

  const updateActions = () => {
    startLoading();

    bmapi
      .updateActionStatus(idAction, statusAction)
      .then(() => {
        notifySuccess(
          onDelete
            ? intl.formatMessage(common.deleteElement)
            : intl.formatMessage(account.saveConfirm)
        );
        setShowDeleteAlert(false);
      })
      .then(() => {
        loadReviews();
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  };

  /*const selectAll = () => {
    setSelection(
      selection.length === reviews.length ? [] : reviews.map((r) => r)
    );
  };*/

  return (
    <Container maxWidth="md">
      {reviews.length !== 0 && (
        <Box my={2}>
          <Button onClick={goToHome} startIcon={<ArrowBack />}>
            {intl.formatMessage(common.backHome)}
          </Button>
        </Box>
      )}

      <Container maxWidth="sm">
        <Box mb={2}>
          <TextField
            margin="normal"
            fullWidth
            select
            label={intl.formatMessage(common.filterStatus)}
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            {[
              { value: "", label: "Nessun filtro" },
              { value: "PENDING", label: "Pending" },
              { value: "APPROVED", label: "Approvate" },
              { value: "CANCELED", label: "Cancellate" },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Container>

      {/*reviews.length !== 0 && (
        <Box mb={2} mt={3} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: 15 }}
            onClick={selectAll}
          >
            {selection.length === reviews.length
              ? intl.formatMessage(form.deselectAll)
              : intl.formatMessage(form.selectAll)}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: 15 }}
            disabled={selection.length === 0}
            onClick={() => {
            authorizeAccess(selection);
          }}
          >
            {intl.formatMessage(employeeAccess.enableSelected)}
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={selection.length === 0}
            onClick={() => {
            setShowDeleteAlert(true);
            setSelectedRegs(selection);
          }}
          >
            {intl.formatMessage(form.deleteSelected)}
          </Button>
        </Box>
      )*/}

      {reviews.length !== 0 &&
        reviews.map((r) => (
          <Box key={r} mb={2}>
            <Card>
              <CardHeader
                action={
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {/*<Checkbox
                      edge="start"
                      checked={selection.includes(r)}
                      color="primary"
                      style={{ padding: 0, marginRight: 5 }}
                />*/}
                    <Typography variant="body">{r.status}</Typography>
                  </Box>
                }
                style={{
                  color: r.status === "CANCELED" ? "red" : "green",
                  fontWeight: 500,
                  margin: 10,
                }}
              />
              <CardContent>
                {[
                  { label: "Utente:", content: r.user_email },
                  {
                    label: "Data:",
                    content: format(new Date(r.created_at), "dd-MM-yyyy"),
                  },
                  { label: "Negozio:", content: r.business_name },
                  { label: "Commento:", content: r.comment },
                ].map((el) => (
                  <Grid key={el.label} container spacing={2}>
                    <Grid item sm={1} />
                    <Grid item sm={2}>
                      {el.label}
                    </Grid>
                    <Grid item sm={7}>
                      {el.content}
                    </Grid>
                  </Grid>
                ))}
              </CardContent>
              <CardActions
                disableSpacing
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0 30px",
                  margin: "10px 0",
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={r.status === "CANCELED"}
                  onClick={() => handleActions(r.id, "CANCELED")}
                >
                  {intl.formatMessage(common.delete)}
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={r.status === "APPROVED"}
                  onClick={() => handleActions(r.id, "APPROVED")}
                >
                  {intl.formatMessage(common.accept)}
                </Button>
              </CardActions>
            </Card>
          </Box>
        ))}
      {reviews.length === 0 && (
        <Box mt={2}>
          <Typography>Nessuna recensione trovata.</Typography>
          <Box my={2}>
            <Button onClick={goToHome} startIcon={<ArrowBack />}>
              {intl.formatMessage(common.backHome)}
            </Button>
          </Box>
        </Box>
      )}
      {reviews.length !== 0 && (
        <Box my={2}>
          <Button onClick={goToHome} startIcon={<ArrowBack />}>
            {intl.formatMessage(common.backHome)}
          </Button>
        </Box>
      )}

      <Confirm
        open={showDeleteAlert}
        onConfirm={updateActions}
        onCancel={() => {
          setIdAction("");
          setStatusAction("");
          setShowDeleteAlert(false);
        }}
        text={
          onDelete
            ? intl.formatMessage(confirm.deleteElement)
            : intl.formatMessage(confirm.acceptElement)
        }
      />
    </Container>
  );
}
