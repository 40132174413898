import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  Fab,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Switch,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from "@material-ui/core";
import Title from "../../ui/Title";
import FormSection from "../../ui/forms/input/FormSection";
import { useIntl } from "react-intl";
import { account, common, form, subscriptions } from "../../messages";
import { useBmapi } from "../../utils/bmapi-context";
import { MANAGER_ROUTES, PLANS } from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";
import { useHistory } from "react-router-dom";
import {
  ArrowBack,
  BrightnessLow,
  CheckCircle,
  Error,
} from "@material-ui/icons";
import { getSubscriptionLogo, mergeSubscrPlans } from "../../utils/utils";
import { tiers } from "../../components/Pricing";
import NumberInput from "../../ui/NumberInput";
import Logo from "../../ui/Logo";
import Confirm from "../../ui/Confirm";

export default function SubscriptionCreate() {
  const initialValues = {
    description: "",
    create_tenant: false,
    plan: "",
    billingPeriod: 12,
    store_quantity: 1,
    company_name: "",
    friendly_url: "",
    primary_color: "#333444",
  };

  const DEFAULT_LOGO =
    "https://storage.googleapis.com/bmarken-assets/tenants/demo/logo-consumer.svg";

  const intl = useIntl();
  const history = useHistory();
  const { bmapi, notifySuccess, notifyError } = useBmapi();
  const slugInput = useRef();
  const theme = useTheme();

  const [deleteLogoRequest, setDeleteLogoRequest] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [plans, setPlans] = useState([]);
  const [slugExists, setSlugExists] = useState(false);
  const [slugValid, setSlugValid] = useState(false);
  const [checkTimeout, setCheckTimeout] = useState(false);
  const [subLogoShown, setSubLogoShown] = useState(DEFAULT_LOGO);
  const [subLogoId, setSubLogoId] = useState("");

  const fplans = plans ? plans.filter((p) => p.action !== "contact") : null;

  useEffect(() => {
    bmapi
      .getSubscriptionPlans()
      .then((resp) => {
        setPlans(mergeSubscrPlans(tiers, resp));
      })
      .catch((e) => console.log("...subscriptions error", e));
  }, [bmapi]);

  useEffect(() => {
    clearTimeout(checkTimeout);
    const to = setTimeout(checkSlug, 500);
    setCheckTimeout(to);
    return () => {
      clearTimeout(to);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.friendly_url]);

  const handleValue = useCallback(
    (varName) => (e) => {
      if (varName === "create_tenant") {
        ((val) => setValues((v) => ({ ...v, [varName]: val })))(
          e.target.checked
        );
      } else {
        ((val) => setValues((v) => ({ ...v, [varName]: val })))(e.target.value);
      }
    },
    []
  );

  const checkSlug = () => {
    setSlugExists(false);
    if (slugInput.current.validity.valid && values.friendly_url) {
      bmapi
        .getSubscriptionsBySlug(values.friendly_url, bmapi.subscription.id)
        .then((s) => {
          setSlugValid(!s.length);
          setSlugExists(!!s.length);
        });
    } else {
      setSlugValid(false);
    }
  };

  const setLogo = (subscription) => {
    const sub = subscription?.length ? subscription[0] : subscription;
    const { logoId, logoUrl } = getSubscriptionLogo(sub);
    setSubLogoShown(logoUrl || DEFAULT_LOGO);
    setSubLogoId(logoId);
  };

  const handleInputFile = (event) => {
    const file = event.target.files[0];
    const newValues = {
      external: false,
      lang: "",
    };
    bmapi
      .addSubscriptionMediaContent(bmapi.subscription?.id, newValues, file)
      .then(() => bmapi.getSubscriptions({ id: bmapi.subscription?.id }))
      .then((subscriptions) => {
        setLogo(subscriptions);
        notifySuccess(intl.formatMessage(common.addElement));
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
  };

  const submit = (e) => {
    e.preventDefault();
    bmapi
      .createSubscription(values)
      .then(() => {
        notifySuccess(intl.formatMessage(account.saveConfirm));
      })
      .then(() => {
        history.push(MANAGER_ROUTES.ADMIN_SUBSCRIPTIONS);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
  };

  const deleteLogo = () => {
    if (!subLogoId) {
      return;
    }
    bmapi
      .deleteSubscriptionMediaContent(bmapi.subscription?.id, subLogoId)
      .then(() => bmapi.getSubscriptions({ id: bmapi.subscription?.id }))
      .then((subscriptions) => {
        setLogo(subscriptions);
        notifySuccess(intl.formatMessage(common.deleteElement));
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => setDeleteLogoRequest(false));
  };

  return (
    <Container maxWidth="sm">
      <Title backUrl={MANAGER_ROUTES.ADMIN_SUBSCRIPTIONS}>
        {intl.formatMessage(subscriptions.configuration)}
      </Title>
      <Box mb={2}>
        {!!deleteLogoRequest && (
          <Confirm
            open={!!deleteLogoRequest}
            onConfirm={deleteLogo}
            onCancel={() => setDeleteLogoRequest(false)}
            text={intl.formatMessage(confirm.deleteLogo)}
          />
        )}

        <form onSubmit={submit}>
          <Box mb={4}>
            <FormSection title="Informazioni generali">
              <TextField
                name="description"
                label={intl.formatMessage(form.description)}
                value={values.description}
                onChange={handleValue("description")}
                fullWidth
                margin="normal"
              />
              <FormControl component="fieldset" margin="normal">
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.create_tenant}
                      onChange={handleValue("create_tenant")}
                      name="createTcreate_tenantenant"
                      color="primary"
                    />
                  }
                  label="Tenant ID"
                />
              </FormControl>
            </FormSection>
            <FormSection title={intl.formatMessage(subscriptions.subscription)}>
              <TextField
                name="plan"
                label={intl.formatMessage(form.plan)}
                //value={fplans && fplans.length ? values.plan : ""}
                value={values.plan}
                onChange={handleValue("plan")}
                key="plan"
                fullWidth
                margin="normal"
                select
                required
                helperText={
                  values.plan === PLANS.TRIAL
                    ? intl.formatMessage(form.plan_trial)
                    : intl.formatMessage(form.plan_help)
                }
              >
                {fplans &&
                  fplans.map(
                    (tier) =>
                      tier.action !== "contact" && (
                        <MenuItem value={tier.id} key={tier.id}>
                          {tier.name}
                        </MenuItem>
                      )
                  )}
              </TextField>
              <TextField
                name="billingPeriod"
                label={intl.formatMessage(form.billingPeriod)}
                value={values.billingPeriod}
                onChange={handleValue("billingPeriod")}
                key="billingPeriod"
                fullWidth
                //disabled={values.plan === PLANS.TRIAL}
                margin="normal"
                select
                required
              >
                {[1, 3, 6, 12].map((months) => (
                  <MenuItem
                    value={months}
                    key={months}
                    disabled={values.plan === PLANS.TRIAL && months !== 3}
                  >
                    {intl.formatMessage(form.monthsNum, { months })}{" "}
                    {values.plan === PLANS.TRIAL && months !== 3
                      ? `[${intl.formatMessage(
                          subscriptions.notAvailableinTrial
                        )}]`
                      : ""}
                  </MenuItem>
                ))}
              </TextField>
              <NumberInput
                label={intl.formatMessage(form.storesQuantity)}
                fullWidth
                value={values.store_quantity}
                margin="normal"
                required
                //disabled={values.plan === PLANS.TRIAL}
                onChange={handleValue("store_quantity")}
                min={1}
                max={values.plan === PLANS.TRIAL ? 1 : 100}
              />
            </FormSection>
            <FormSection title={intl.formatMessage(subscriptions.companyInfo)}>
              <TextField
                name="company_name"
                label={intl.formatMessage(form.company_name)}
                value={values.company_name}
                onChange={handleValue("company_name")}
                key="company_name"
                fullWidth
                margin="normal"
                required
              />
            </FormSection>
            <FormSection title={intl.formatMessage(subscriptions.webAppConf)}>
              <TextField
                name="friendly_url"
                label={intl.formatMessage(form.friendly_url)}
                value={values.friendly_url}
                onChange={handleValue("friendly_url")}
                key="friendly_url"
                fullWidth
                margin="normal"
                //required={values.plan !== PLANS.TRIAL}
                inputRef={slugInput}
                //disabled={values.plan === PLANS.TRIAL}
                error={!!values.friendly_url && !slugValid}
                helperText={
                  values.plan === PLANS.TRIAL ? (
                    intl.formatMessage(form.friendly_url_disabled)
                  ) : slugValid ? (
                    <span>
                      {bmapi.settings.subscriptionsURL}
                      <b>{values.friendly_url}</b>
                    </span>
                  ) : slugExists ? (
                    intl.formatMessage(form.friendly_url_exists)
                  ) : (
                    intl.formatMessage(form.friendly_url_help)
                  )
                }
                InputProps={{
                  endAdornment: !!values.friendly_url && (
                    <InputAdornment position="end">
                      {slugValid ? (
                        <CheckCircle style={{ color: "#4caf50" }} />
                      ) : (
                        <Error style={{ color: "#f44336" }} />
                      )}
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  pattern: "^[a-z0-9](-?[a-z0-9]){7,19}$",
                  maxLength: 20,
                }}
              />
              <Typography variant="caption" display="block" gutterBottom>
                {intl.formatMessage(form.friendly_url_info)}
              </Typography>
              <Box pb={4} pt={4}>
                <InputLabel>Logo</InputLabel>
              </Box>
              <Box display="flex">
                <Box>
                  <Logo
                    logo={subLogoShown}
                    alt={
                      values.company_name || intl.formatMessage(form.logo_add)
                    }
                  />
                </Box>
                <Box display="flex" justifyContent="flex-end" flexGrow={1}>
                  {!subLogoId && (
                    <>
                      <input
                        accept="image/*"
                        style={{ display: "none" }}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={handleInputFile}
                      />
                      <label htmlFor="contained-button-file">
                        <Button color="primary" component="span">
                          {intl.formatMessage(common.uploadLogo)}
                        </Button>
                      </label>
                    </>
                  )}
                  {!!subLogoId && (
                    <Button
                      color="primary"
                      component="span"
                      onClick={() => setDeleteLogoRequest(true)}
                    >
                      {intl.formatMessage(common.deleteLogo)}
                    </Button>
                  )}
                </Box>
              </Box>
              <Box pt={4}>
                <TextField
                  name="primary_color"
                  label={intl.formatMessage(form.primary_color)}
                  //value={values.primary_color}
                  //onChange={handleChange("primary_color")}
                  key="primary_color"
                  fullWidth
                  margin="normal"
                  type="color"
                />
              </Box>
            </FormSection>
            <Box>
              <FormSection title={intl.formatMessage(form.preview)}>
                <Box pb={2}>
                  <AppBar
                    position="static"
                    style={{
                      background: values.primary_color,
                      color: theme.palette.getContrastText(
                        values.primary_color
                      ),
                    }}
                  >
                    <Toolbar>
                      <div style={{ flexGrow: 1 }}>
                        <Logo
                          logo={subLogoShown}
                          alt={
                            values.company_name ||
                            intl.formatMessage(form.logo_add)
                          }
                        />
                      </div>
                      <IconButton edge="end" color="inherit">
                        <Menu />
                      </IconButton>
                    </Toolbar>
                  </AppBar>
                  <Container maxWidth="sm">
                    <Title>Title</Title>
                    <Typography>
                      BMarkEn is an ideal tool to allow Merchants, Retailers,
                      Producers to match two basic needs: how to promote their
                      products/services and how to retain customers.
                    </Typography>
                    <Box align="center" mt={3}>
                      <Fab
                        variant="extended"
                        style={{
                          background: values.primary_color,
                          color: theme.palette.getContrastText(
                            values.primary_color
                          ),
                        }}
                      >
                        <BrightnessLow style={{ marginRight: 10 }} />
                        Action
                      </Fab>
                    </Box>
                  </Container>
                </Box>
              </FormSection>
            </Box>
          </Box>
          <FormControl fullWidth margin="normal">
            <Button variant="contained" color="primary" type="submit">
              {intl.formatMessage(common.create)}
            </Button>
          </FormControl>
        </form>
      </Box>
      <FormControl margin="normal">
        <Button
          onClick={() => history.push(MANAGER_ROUTES.ADMIN_SUBSCRIPTIONS)}
          startIcon={<ArrowBack />}
        >
          {intl.formatMessage(common.back)}
        </Button>
      </FormControl>
    </Container>
  );
}
