import React from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  TextField,
  Typography,
} from "@material-ui/core";
import { useBmapi } from "../../utils/bmapi-context";
import { useIntl } from "react-intl";
import { common } from "../../messages";
import { useState } from "react";
import { getErrorMessageString } from "../../utils/errors";
import { MANAGER_ROUTES } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import { ArrowBack } from "@material-ui/icons";

export default function Assistance() {
  const { bmapi, notifyError, notifySuccess } = useBmapi();
  const intl = useIntl();
  const history = useHistory();

  const [message, setMessage] = useState("");

  const user = bmapi.getUserInfo();

  const goToHome = () => {
    history.push(MANAGER_ROUTES.HOME_MENU);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    bmapi
      .sendSupportEmail(message)
      .then(() => {
        notifySuccess("Email inviata con successo", "info");
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
  };

  return (
    <Container maxWidth="sm">
      <Card>
        <CardContent>
          <Typography gutterBottom>
            Descrivi qui la tua segnalazione di assistenza: ti risponderemo al
            più presto sulla casella di posta elettronica indicata nel tuo
            profilo <div style={{ color: "#7f202e" }}>{user.email}.</div>
          </Typography>
          <form onSubmit={sendEmail}>
            <TextField
              label={intl.formatMessage(common.message)}
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              margin="normal"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              helperText={message.length > 1000 && "Massimo 1000 caratteri"}
              error={message.length > 1000}
              required
            />
            <CardActions>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                disabled={message.length > 1000}
              >
                Invia richiesta
              </Button>
            </CardActions>
          </form>
        </CardContent>
      </Card>
      <Box my={2}>
        <Button onClick={goToHome} startIcon={<ArrowBack />}>
          {intl.formatMessage(common.backHome)}
        </Button>
      </Box>
    </Container>
  );
}
