import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import {
  CircularProgress,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {
  AddCircleOutline,
  Close,
  ControlPointDuplicate,
  DeleteForever,
  DeleteSweep,
  Edit,
  Equalizer,
  Info,
  MoreVert,
  Notifications,
  PlayArrow,
  PlaylistAddCheck,
  RemoveCircleOutline,
  Send,
  Share,
  Stop,
} from "@material-ui/icons";

import CampaignDelete from "../ui/CampaignDelete";
import CampaignEventStart from "../ui/CampaignEventStart";
import CampaignEventStop from "../ui/CampaignEventStop";
import CampaignJoin from "../ui/CampaignJoin";
import CampaignUnjoin from "../ui/CampaignUnjoin";
import IssueProduct from "../ui/IssueProduct";
import { useBmapi } from "../utils/bmapi-context";
import { getLabel } from "../utils/campaigns";
import {
  CAMPAIGN_ACTION,
  EVENT_STATUS,
  FEATURES,
  MANAGER_ROUTES,
  PRODUCTS,
  SKINS,
  VARIANTS,
} from "../utils/constants";
import { getErrorMessageString } from "../utils/errors";
import {
  actions as actionsMsg,
  notifications,
  product as productMsg,
} from "../messages";
import styles from "../utils/styles";
import CampaignProdsDelete from "../ui/CampaignProdsDelete";

export default function CampaignActions({
  campaign,
  collapse = true,
  events = [],
  edge,
  filter,
  hideInfo,
  isPrize,
  loading,
  loadPerformance,
  onUpdate,
  performance,
  showInfo,
  trigger,
  visibleInfo,
}) {
  const history = useHistory();
  const intl = useIntl();
  const classes = styles.useStyles();
  const { bmapi, notifyError, notifySuccess } = useBmapi();

  const [anchorEl, setAnchorEl] = useState(null);
  const [task, setTask] = useState(false);

  const copyLink = () => {
    navigator.clipboard
      .writeText(campaign.shareLink)
      .then(() => notifySuccess(intl.formatMessage(notifications.linkCopied)))
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  };

  const goTo = (route, id) => () =>
    history.push(route.replace(":campaignId", id));

  const endTask = () => {
    setTask(false);
  };

  const after = () => {
    endTask();
    onUpdate();
  };

  const afterStart = () => {
    endTask();
    goTo(MANAGER_ROUTES.CAMPAIGN, campaign.campaign_id)();
  };

  const canDeleteProds = () => campaign.ownCampaign && campaign.demo;

  const canDelete = () =>
    campaign.ownCampaign &&
    !isPrize &&
    (campaign.demo ||
      (performance?.issued_value === 0 && performance?.issued_qty === 0));

  const CampaignJoinDialog = () => (
    <CampaignJoin
      campaign={campaign}
      open={task === CAMPAIGN_ACTION.JOIN}
      onSuccess={after}
      onCancel={endTask}
    />
  );

  const CampaignUnjoinDialog = () => (
    <CampaignUnjoin
      campaign={campaign}
      open={task === CAMPAIGN_ACTION.UNJOIN}
      onSuccess={after}
      onCancel={endTask}
    />
  );

  const IssueProductDialog = () => (
    <IssueProduct
      campaign={campaign}
      open={task === CAMPAIGN_ACTION.ISSUE}
      onClose={after}
    />
  );

  const CampaignDeleteDialog = () => (
    <CampaignDelete
      campaign={campaign}
      open={task === CAMPAIGN_ACTION.DELETE}
      onSuccess={after}
      onCancel={endTask}
    />
  );

  const CampaignProdsDeleteDialog = () => (
    <CampaignProdsDelete
      campaign={campaign}
      open={task === CAMPAIGN_ACTION.DELETE_PRODUCTS}
      onSuccess={after}
      onCancel={endTask}
    />
  );

  const CampaignEventStartDialog = () => (
    <CampaignEventStart
      campaign={campaign}
      open={task === CAMPAIGN_ACTION.START}
      onSuccess={afterStart}
      onCancel={endTask}
    />
  );

  const CampaignEventStopDialog = () => (
    <CampaignEventStop
      campaign={campaign}
      event={events.find((e) => e.status === EVENT_STATUS.STARTED)}
      open={task === CAMPAIGN_ACTION.STOP}
      onSuccess={after}
      onCancel={endTask}
    />
  );

  const actions = [
    {
      action: goTo(MANAGER_ROUTES.CAMPAIGN, campaign.campaign_id),
      Icon: Info,
      id: CAMPAIGN_ACTION.LINK,
      label: intl.formatMessage(actionsMsg.info),
    },
    {
      action: visibleInfo ? hideInfo : showInfo,
      disabled: campaign.toSign,
      Icon: visibleInfo ? Close : Equalizer,
      id: CAMPAIGN_ACTION.MOREINFO,
      label: intl.formatMessage(actionsMsg.stats),
    },
    {
      action: () => setTask(CAMPAIGN_ACTION.START),
      disabled:
        !campaign.isActive ||
        bmapi.isTenantManager() ||
        campaign.campaign_data.front_end_type !== PRODUCTS.COURSE_PASS ||
        !bmapi.can(FEATURES.EVENTS) ||
        (events || []).some((e) => e?.status === EVENT_STATUS.STARTED),
      Icon: PlayArrow,
      id: CAMPAIGN_ACTION.START,
      label: intl.formatMessage(actionsMsg.start),
      render: CampaignEventStartDialog,
    },
    {
      action: () => setTask(CAMPAIGN_ACTION.STOP),
      disabled:
        campaign.campaign_data.front_end_type !== PRODUCTS.COURSE_PASS ||
        !bmapi.can(FEATURES.EVENTS) ||
        (events || []).every((e) => e?.status !== EVENT_STATUS.STARTED),
      Icon: Stop,
      id: CAMPAIGN_ACTION.STOP,
      label: intl.formatMessage(actionsMsg.stop),
      render: CampaignEventStopDialog,
    },
    {
      action: () => setTask(CAMPAIGN_ACTION.JOIN),
      disabled: !campaign.toSign,
      Icon: AddCircleOutline,
      id: CAMPAIGN_ACTION.JOIN,
      label: intl.formatMessage(actionsMsg.join),
      render: CampaignJoinDialog,
    },
    {
      action: () => setTask(CAMPAIGN_ACTION.UNJOIN),
      disabled: !campaign.canUnjoin,
      Icon: RemoveCircleOutline,
      id: CAMPAIGN_ACTION.UNJOIN,
      label: intl.formatMessage(actionsMsg.unjoin),
      render: CampaignUnjoinDialog,
    },
    {
      action: () => setTask(CAMPAIGN_ACTION.ISSUE),
      disabled:
        !campaign.canIssue ||
        (bmapi.settings.skin === SKINS.EDUCATION &&
          campaign.campaign_data.front_end_type === PRODUCTS.COURSE_PASS &&
          campaign.campaign_data.rules.main_event_id === ""),
      Icon: Send,
      id: CAMPAIGN_ACTION.ISSUE,
      label: intl.formatMessage(productMsg[getLabel(campaign.type)].issue),
      render: IssueProductDialog,
    },
    {
      action: goTo(MANAGER_ROUTES.RESERVATIONS, campaign.campaign_id),
      disabled: !campaign.canIssue || !campaign.campaign_data.waiting_list,
      Icon: PlaylistAddCheck,
      id: CAMPAIGN_ACTION.WAITINGLIST,
      label: intl.formatMessage(actionsMsg.waiting),
    },
    {
      action: copyLink,
      disabled: !campaign.campaign_data.link_distribution,
      Icon: Share,
      id: CAMPAIGN_ACTION.SHARE,
      label: intl.formatMessage(actionsMsg.copyLink),
    },
    {
      action: goTo(MANAGER_ROUTES.EDIT_CAMPAIGN, campaign.campaign_id),
      disabled: !campaign.ownCampaign || !bmapi.can(FEATURES.MANAGE_CAMPAIGN),
      Icon: Edit,
      id: CAMPAIGN_ACTION.EDIT,
      label: intl.formatMessage(actionsMsg.edit),
    },
    {
      action: goTo(MANAGER_ROUTES.CLONE_CAMPAIGN, campaign.campaign_id),
      disabled: !campaign.ownCampaign || !bmapi.can(FEATURES.MANAGE_CAMPAIGN),
      Icon: ControlPointDuplicate,
      id: CAMPAIGN_ACTION.CLONE,
      label: intl.formatMessage(actionsMsg.clone),
    },
    {
      action: () => setTask(CAMPAIGN_ACTION.DELETE_PRODUCTS),
      disabled: !canDeleteProds() || !bmapi.can(FEATURES.MANAGE_CAMPAIGN),
      Icon: DeleteSweep,
      id: CAMPAIGN_ACTION.DELETE_PRODUCTS,
      label: intl.formatMessage(actionsMsg.deleteProducts),
      render: CampaignProdsDeleteDialog,
    },
    {
      action: () => setTask(CAMPAIGN_ACTION.DELETE),
      color: VARIANTS.ERROR,
      disabled: !canDelete() || !bmapi.can(FEATURES.MANAGE_CAMPAIGN),
      Icon: DeleteForever,
      id: CAMPAIGN_ACTION.DELETE,
      label: intl.formatMessage(actionsMsg.delete),
      render: CampaignDeleteDialog,
    },
    {
      action: goTo(MANAGER_ROUTES.CAMPAIGN_NOTIFICATIONS, campaign.campaign_id),
      disabled: !bmapi.can(FEATURES.NOTIFICATIONS),
      Icon: Notifications,
      id: CAMPAIGN_ACTION.NOTIFICATIONS,
      label: intl.formatMessage(actionsMsg.notifications),
    },
  ].filter((a) => (!filter || filter(a)) && !a.disabled);

  const firstAction = actions[0];

  const handleOpen = (e) => {
    const target = e.currentTarget;
    if (performance) {
      setAnchorEl(target);
    } else {
      loadPerformance().then(() => setAnchorEl(target));
    }
  };

  return (
    <span style={{ display: "inline-block", position: "relative" }}>
      {actions.length === 1 && collapse && !trigger ? (
        <IconButton
          edge={edge}
          aria-label={firstAction.label}
          onClick={firstAction.action}
        >
          {!!firstAction.render && firstAction.render()}
          <firstAction.Icon />
        </IconButton>
      ) : (
        <React.Fragment>
          {trigger ? (
            trigger(handleOpen)
          ) : (
            <IconButton onClick={handleOpen} edge={edge}>
              <MoreVert />
            </IconButton>
          )}
          <Menu
            anchorEl={anchorEl}
            keepMounted={!!task}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem disabled>
              <Typography variant="overline">{campaign.name}</Typography>
            </MenuItem>
            {actions.map((action) => (
              <MenuItem
                key={action.label}
                onClick={() => {
                  setAnchorEl(null);
                  action.action();
                }}
                disabled={loading}
              >
                {!!action.render && action.render()}
                <ListItemIcon>
                  <action.Icon fontSize="small" color={action.color} />
                </ListItemIcon>
                <Typography variant="inherit" color={action.color}>
                  {action.label}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      )}
      {loading && (
        <CircularProgress size={48} className={classes.fabProgress} />
      )}
    </span>
  );
}
