import React, { useState, useCallback, useEffect } from "react";
import { useIntl } from "react-intl";

import { Container } from "@material-ui/core";

import { navigation } from "../../messages";
import FormManager from "../../ui/FormManager";
import QrScanner from "../../ui/QrScanner";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { USER_PREFIX, UUID_REGEX_PREFIX } from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";

export default function Scanner() {
  const intl = useIntl();
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const [productInfo, setProductInfo] = useState(null);
  const [qrCode, setQrCode] = useState(null);

  const reset = useCallback(() => {
    setProductInfo(null);
    setQrCode(null);
  }, [setProductInfo, setQrCode]);

  const onError = useCallback(
    (error) => {
      stopLoading();
      notifyError(error);
    },
    [notifyError, stopLoading]
  );

  const sendCode = useCallback(
    (code) => {
      if (code && UUID_REGEX_PREFIX.test(code)) {
        const [, prefix, uuid] = UUID_REGEX_PREFIX.exec(code);

        if (prefix === USER_PREFIX) {
          onError(
            intl.formatMessage({
              id: "pages.scanner.wrongQrCode",
              defaultMessage:
                "Il QR Code letto non appartiene ad un coupon o una card valida, ma serve sono per ricevere nuovi coupon/card.",
            })
          );
        } else {
          startLoading();

          bmapi
            .getProductInfo(uuid)
            .then(setProductInfo)
            .catch((e) => onError(getErrorMessageString(e, intl)));
        }
      } else {
        onError(
          intl.formatMessage({
            id: "pages.scanner.invalidQrCode",
            defaultMessage: "Codice scannerizzato non valido",
          })
        );
      }
    },
    [bmapi, intl, onError, startLoading]
  );

  useEffect(() => {
    if (qrCode) sendCode(qrCode);
  }, [qrCode, sendCode]);

  return (
    <Container maxWidth="sm">
      {productInfo ? (
        <React.Fragment>
          <Title>{intl.formatMessage(navigation.actionForm)}</Title>
          <FormManager
            info={productInfo}
            onCancel={reset}
            onSuccess={reset}
            qrCode={qrCode}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Title>{intl.formatMessage(navigation.scanner)}</Title>
          <QrScanner
            handleCode={setQrCode}
            handleError={onError}
            infoText={intl.formatMessage({
              id: "component.qrScanner.instructions",
              defaultMessage:
                "Inquadra il QR Code di un coupon o una card per visualizzarne le informazioni e le azioni possibili. È anche possibile usare un lettore di barcode compatibile con i QR Code.",
            })}
          />
        </React.Fragment>
      )}
    </Container>
  );
}
