import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  TextField,
  Typography,
} from "@material-ui/core";
import { DatePicker } from "../ui/DatePicker";
import { Add, Delete } from "@material-ui/icons";
import { useIntl } from "react-intl";
import { account, common, confirm } from "../messages";
import { useBmapi } from "../utils/bmapi-context";
import { addYears, format } from "date-fns";
import Confirm from "../ui/Confirm";
import { getErrorMessageString } from "../utils/errors";

const byDate = (a, b) => new Date(a.from) - new Date(b.from);

export default function EOrderClosingDates() {
  const getEmptyObj = (arr) => {
    return !arr || arr.length === 0 ? [{ from: null, to: null }] : arr;
  };

  const intl = useIntl();
  const { bmapi, notifyError, notifySuccess } = useBmapi();
  //const [save, setSave] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showCloneAlert, setShowCloneAlert] = useState(false);
  const [open, setOpen] = useState(false);
  const [openClone, setOpenClone] = useState(false);
  const [periodToDelete, setPeriodToDelete] = useState(null);
  const [cloneYear, setCloneYear] = useState(null);
  const [datesToClone, setDatesToClone] = useState(null);
  const [closingDates, setClosingDates] = useState([]);
  const [closingDatesArr, setClosingDatesArr] = useState([]);
  const [filterYear, setFilterYear] = useState(format(new Date(), "yyyy"));

  const businessID = bmapi.getUserInfo().business.id;

  const [period, setPeriod] = useState({
    date: getEmptyObj(null),
  });

  useEffect(() => {
    loadClosingDates();
  }, [bmapi, loadClosingDates]);

  const handleDates = useCallback(
    (label, index) => (e) => {
      const newPeriod = { ...period };
      if (label === "from") {
        newPeriod.date[index][label] = e;
        newPeriod.date[index]["to"] = e;
      } else newPeriod.date[index][label] = e;
      setPeriod(newPeriod);
      //setSave(true);
    },
    [period]
  );

  const handlePeriods = useCallback(
    (index, action) => () => {
      let newPeriod = { ...period };
      if (action) {
        newPeriod.date.splice(index + 1, 0, {
          from: null,
          to: null,
        });
        //setSave(true);
      } else {
        newPeriod.date.splice(index, 1);
        if (!newPeriod.date.length) {
          newPeriod.date.push({ from: null, to: null });
        }
        //confirmDates();
      }
      setPeriod(newPeriod);
      setShowDeleteAlert(false);
      setPeriodToDelete(null);
    },
    [period]
  );

  const handleCloneYear = useCallback(
    (e) => {
      const hasClosingDates = closingDatesArr.filter((date) =>
        date.startsWith(
          cloneYear || format(addYears(new Date(filterYear), -1), "yyyy")
        )
      );
      if (!hasClosingDates.length)
        alert(
          "Non sono stati inseriti giorni di chiusura per l'anno selezionato."
        );
      else {
        if (
          closingDates.find((date) => format(date.from, "yyyy") === filterYear)
        ) {
          setShowCloneAlert(true);
          setDatesToClone(hasClosingDates);
        } else {
          confirmDates(
            e,
            hasClosingDates,
            cloneYear || format(addYears(new Date(filterYear), -1), "yyyy")
          );
        }
      }
    },
    [cloneYear, closingDatesArr, filterYear, closingDates, confirmDates]
  );

  const fixPeriod = (d) => {
    return new Date(
      d.substring(0, 4) + "/" + d.substring(4, 6) + "/" + d.substring(6)
    );
  };

  const loadClosingDates = useCallback(() => {
    bmapi.getBusiness(businessID).then((resp) => {
      let closingDates = resp.closing_dates;
      setClosingDatesArr(resp.closing_dates);
      if (closingDates) {
        let newDate = [];
        for (let i = 0; i < closingDates.length; i++) {
          let newObj = {
            from:
              closingDates[i].length === 8
                ? fixPeriod(closingDates[i])
                : fixPeriod(closingDates[i].substring(0, 8)),
            to:
              closingDates[i].length === 8
                ? fixPeriod(closingDates[i])
                : fixPeriod(closingDates[i].substring(9, 17)),
            date_index: i, // used in onDelete
          };
          newDate.push(newObj);
        }
        //let newPeriod = { ...period, date: newDate };
        //setPeriod(newPeriod);
        setClosingDates(newDate);
      } else setClosingDates([]);
    });
  }, [bmapi, businessID]);

  const checkSameDate = (date) => {
    const from = format(date.from, "yyyyMMdd");
    const to = format(date.to, "yyyyMMdd");
    if (from === to) {
      return from;
    } else return from + "-" + to;
  };

  const confirmDates = useCallback(
    (event, clone, param) => {
      if (clone) {
        const formatDate = closingDates
          .map((date) => checkSameDate(date))
          .filter((d) => !d.startsWith(filterYear));
        let regex = new RegExp(param, "g");
        const formatClone = clone.map((date) =>
          date.replace(regex, filterYear)
        );
        const newDates = [...formatDate, ...formatClone];
        bmapi
          .createClosingDates({ dates: newDates })
          .then(() => {
            setShowCloneAlert(false);
            setOpenClone(false);
            setOpen(false);
            setPeriod({
              date: getEmptyObj(null),
            });
            notifySuccess(intl.formatMessage(account.saveConfirm));
            loadClosingDates();
            //setSave(false);
          })
          .catch((e) => {
            notifyError(getErrorMessageString(e, intl));
          });
      } else {
        event?.preventDefault();
        for (let i = 0; i < period.date.length; i++) {
          if (
            period.date.length > 0 &&
            period.date.find((p) => p.from === null)
          ) {
            notifyError("Il periodo inserito non è valido o è vuoto.");
            return;
          }
        }
        let newDates = [...closingDates, ...period.date];
        newDates = newDates.map((date) => checkSameDate(date));
        /*let newDates = {};
        if (period.date[0].from === null) {
          newDates.dates = [];
        } else {
          newDates.dates = period.date.map((date) => checkSameDate(date));
        }*/
        bmapi
          .createClosingDates({ dates: newDates })
          .then(() => {
            setOpen(false);
            setPeriod({
              date: getEmptyObj(null),
            });
            notifySuccess(intl.formatMessage(account.saveConfirm));
            loadClosingDates();
            //setSave(false);
          })
          .catch((e) => {
            notifyError(getErrorMessageString(e, intl));
          });
      }
    },
    [
      bmapi,
      intl,
      loadClosingDates,
      notifyError,
      notifySuccess,
      period,
      closingDates,
      filterYear,
    ]
  );

  const onDelete = useCallback(
    (index) => {
      let newDates = [...closingDates];
      newDates.splice(index, 1);
      newDates = newDates.map((date) => checkSameDate(date));
      bmapi
        .createClosingDates(newDates.length !== 0 ? { dates: newDates } : null)
        .then(() => {
          setShowDeleteAlert(false);
          setPeriodToDelete(null);
          notifySuccess(intl.formatMessage(account.saveConfirm));
          loadClosingDates();
        })
        .then(() => {
          loadClosingDates();
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    },
    [intl, loadClosingDates, notifyError, notifySuccess, closingDates, bmapi]
  );

  return (
    <React.Fragment>
      <Box mt={2}>
        <DatePicker
          style={{ width: "50%" }}
          label="Seleziona l'anno da visualizzare"
          views={["year"]}
          value={filterYear}
          format="yyyy"
          minDate={addYears(new Date(), -5)}
          maxDate={addYears(new Date(), 5)}
          onChange={(e) => setFilterYear(format(e, "yyyy"))}
        />
        <Card style={{ marginTop: 20 }}>
          <CardContent>
            {closingDates &&
              closingDates
                .filter((date) => format(date.from, "yyyy") === filterYear)
                .sort(byDate)
                .map((date, i) => (
                  <List key={i} style={{ padding: 0 }}>
                    {i !== 0 && <Divider component="li" />}
                    <ListItem>
                      <Typography
                        style={{
                          color: new Date() > date.from ? "grey" : "inherit",
                        }}
                      >
                        {format(date.from, "dd/MM/yyyy") ===
                        format(date.to, "dd/MM/yyyy")
                          ? format(date.from, "dd/MM/yyyy")
                          : `${format(date.from, "dd/MM/yyyy")} - ${format(
                              date.to,
                              "dd/MM/yyyy"
                            )}`}
                      </Typography>
                    </ListItem>
                    <ListItemSecondaryAction>
                      <IconButton
                        //onClick={handlePeriods(i, 0)}
                        disabled={new Date() > date.from}
                        onClick={() => {
                          setShowDeleteAlert(true),
                            setPeriodToDelete(date.date_index);
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </List>
                ))}
            {!closingDates.length && (
              <Typography>Non ci sono configurazioni inserite</Typography>
            )}
          </CardContent>
        </Card>

        <Box mt={2} style={{ float: "right" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
            disabled={filterYear < new Date().getFullYear().toString()}
          >
            {intl.formatMessage(common.add)}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: 10 }}
            disabled={filterYear < new Date().getFullYear().toString()}
            onClick={() => setOpenClone(true)}
          >
            Copia precedente
          </Button>
        </Box>

        {/*save && (
            <Box mt={2}>
              <Button type="submit" variant="contained" color="primary">
                {intl.formatMessage(common.confirm)}
              </Button>
            </Box>
          )*/}
      </Box>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setPeriod({
            date: getEmptyObj(null),
          });
        }}
        fullWidth
      >
        <DialogTitle>Date di chiusura</DialogTitle>
        <form onSubmit={confirmDates}>
          <DialogContent>
            {period.date.map((date, i) => (
              <List key={i} style={{ padding: 0 }}>
                <DatePicker
                  style={{ marginTop: 0 }}
                  label={intl.formatMessage(common.from)}
                  inputFormat="dd/MM/yyyy"
                  value={date.from}
                  onChange={handleDates("from", i)}
                  format="dd/MM/yy"
                  renderInput={(params) => <TextField {...params} />}
                  disablePast
                  // seleziona date per anni successivi e non passati
                  minDate={new Date(`${filterYear}-01-01`)}
                  maxDate={addYears(new Date(`${filterYear - 1}-12-31`), 1)}
                />
                <DatePicker
                  style={{ marginTop: 0 }}
                  label={intl.formatMessage(common.to)}
                  inputFormat="dd/MM/yyyy"
                  value={date.to}
                  onChange={handleDates("to", i)}
                  format="dd/MM/yy"
                  renderInput={(params) => <TextField {...params} />}
                  minDate={date.from}
                  disablePast
                />
                <ListItemSecondaryAction>
                  <IconButton onClick={handlePeriods(i, 1)}>
                    <Add />
                  </IconButton>
                  <IconButton
                    onClick={handlePeriods(i, 0)}
                    /*onClick={() => {
                        setShowDeleteAlert(true), setPeriodToDelete(i);
                      }}*/
                  >
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </List>
            ))}
            <DialogActions>
              <Button variant="outlined" onClick={() => setOpen(false)}>
                {intl.formatMessage(common.cancel)}
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {intl.formatMessage(common.confirm)}
              </Button>
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>

      <Dialog
        open={openClone}
        onClose={() => {
          setOpenClone(false);
        }}
        fullWidth
      >
        <DialogTitle>Copia da anno precedente</DialogTitle>
        <DialogContent>
          <DatePicker
            margin="dense"
            label="Seleziona l'anno da copiare"
            views={["year"]}
            value={
              cloneYear || format(addYears(new Date(filterYear), -1), "yyyy")
            }
            format="yyyy"
            minDate={addYears(new Date(), -5)}
            maxDate={addYears(new Date(filterYear), -1)}
            onChange={(e) => setCloneYear(format(e, "yyyy"))}
            helperText="Cliccando su Conferma i giorni precedentemente impostati verranno sostituiti dalla configurazione dell'anno selezionato."
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              setOpenClone(false);
              setCloneYear(null);
            }}
          >
            {intl.formatMessage(common.cancel)}
          </Button>
          <Button variant="contained" color="primary" onClick={handleCloneYear}>
            {intl.formatMessage(common.confirm)}
          </Button>
        </DialogActions>
      </Dialog>

      <Confirm
        open={showDeleteAlert}
        //onConfirm={handlePeriods(periodToDelete, 0)}
        onConfirm={() => onDelete(periodToDelete)}
        onCancel={() => {
          setShowDeleteAlert(false);
          setPeriodToDelete(null);
        }}
        text={intl.formatMessage(confirm.deleteElement)}
      />

      <Confirm
        open={showCloneAlert}
        onConfirm={(e) =>
          confirmDates(
            e,
            datesToClone,
            cloneYear || format(addYears(new Date(filterYear), -1), "yyyy")
          )
        }
        onCancel={() => {
          setShowCloneAlert(false);
          setDatesToClone(null);
        }}
        text={intl.formatMessage(confirm.clonePrompt)}
      />
    </React.Fragment>
  );
}
