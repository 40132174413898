import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {
  Add,
  ArrowBack,
  Create,
  Delete,
  FileCopy,
  MoreVert,
} from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { campaign, common, confirm, form } from "../../messages";
import { DatePicker } from "../../ui/DatePicker";
import Title from "../../ui/Title";
import FloatingActions, { Action } from "../../ui/FloatingActions";
import { MANAGER_ROUTES } from "../../utils/constants";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { EVENT_TYPES } from "../../utils/eventActionsConstants";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { format } from "date-fns";
import Confirm from "../../ui/Confirm";
import { intlFormatMessage } from "../../utils/utils";
import { eventActions } from "../../messages/eventActions";

function parseDate(dateString) {
  const [day, month, year] = dateString.split("-");
  return new Date(`${year}-${month}-${day}`);
}

function getFilterValues(str) {
  const typeValue = str?.match(/type([^from]+)/);
  const fromValue = str?.match(/from([^to]+)/);
  const toValue = str?.match(/to(.+)$/);

  const selectedType = typeValue ? typeValue[1] : "-";
  const selectedFrom = fromValue ? parseDate(fromValue[1].trim()) : null;
  const selectedTo = toValue ? parseDate(toValue[1].trim()) : null;

  return {
    selected_type: selectedType,
    from: selectedFrom,
    to: selectedTo,
  };
}

function CreateEventActionButton({
  trigger,
  handleFiltersApplied,
  filtersApplied,
}) {
  const intl = useIntl();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const create = (subtype) => {
    const query = "?subtype=";
    const param = subtype.toLowerCase();
    handleClose();
    if (filtersApplied) {
      handleFiltersApplied(query, param);
    } else history.push(MANAGER_ROUTES.EVENT_ACTION_CREATE + query + param);
  };

  return (
    <React.Fragment>
      {trigger(handleClick)}
      <Drawer anchor="bottom" open={Boolean(anchorEl)} onClose={handleClose}>
        <List>
          {EVENT_TYPES.filter((type) => type.value !== "-").map((type) => (
            <MenuItem key={type.value} onClick={() => create(type.value)}>
              <ListItemIcon>{type.icon}</ListItemIcon>
              <ListItemText
                primary={intlFormatMessage(
                  eventActions[type.label],
                  type.label,
                  intl
                )}
              />
            </MenuItem>
          ))}
        </List>
      </Drawer>
    </React.Fragment>
  );
}

export default function EventAction() {
  const intl = useIntl();
  const {
    bmapi,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  const history = useHistory();
  const [filters, setFilters] = useState({
    from: null,
    to: null,
    selected_type: "-",
  });
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(false);
  const [eventId, setEventId] = useState(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [campaignList, setCampaignList] = useState([]);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleFilters = useCallback((label) => {
    const updateValue = (val) => {
      setFilters((v) => ({ ...v, [label]: val }));
    };

    return (i, f) => {
      if (typeof f === "boolean") updateValue(f);
      else if (i?.target) updateValue(i.target.value);
      else updateValue(i);
    };
  }, []);

  const byDate = (a, b) => new Date(b.end_at) - new Date(a.end_at);

  const getLabel = (type) => {
    const filtered = EVENT_TYPES.filter((obj) => obj.value === type);
    return filtered && filtered.length
      ? intl.formatMessage(eventActions[filtered[0].label])
      : "";
  };

  const getCampaignLabel = (id) => {
    const filtered = campaignList.filter((c) => c.id === id);
    return filtered && filtered.length
      ? filtered[0].name
      : intl.formatMessage(campaign.noCampaign);
  };

  const checkObject = (filters) => {
    const isFalsy = (currentValue) => !currentValue;

    return !Object.values(filters).every(isFalsy);
  };

  const loadEventList = useCallback(
    (params) => {
      params = {
        from: params.from ? format(params.from, "yyyy-MM-dd") : null,
        to: params.to ? format(params.to, "yyyy-MM-dd") : null,
        type: params.selected_type === "-" ? null : params.selected_type,
      };

      for (const property in params) {
        if (params[property] === null) {
          delete params[property];
        }
      }

      startLoading();

      bmapi
        .getEventActions(params)
        .then((resp) => {
          if (!resp) {
            resp = [];
            setEvents(resp);
          } else {
            setEvents(resp);
            params && setFiltersApplied(true);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    },
    [bmapi, intl, notifyError, startLoading, stopLoading]
  );

  /*const loadEventActions = useCallback(() => {
    startLoading();
    if (bmapi) {
      bmapi
        .getEventActions()
        .then((resp) => {
          if (!resp) {
            resp = [];
            setEvents(resp);
          } else {
            setEvents(resp);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    }
  }, [bmapi, intl, notifyError, startLoading, stopLoading]);*/

  useEffect(() => {
    const extractFilterValues = (str) => {
      const filterIndex = str.indexOf("filters=");
      if (filterIndex === -1) {
        return null;
      }

      const filterValues = str.substring(filterIndex);
      return filterValues;
    };

    const filterResult = extractFilterValues(location.pathname);

    const result = getFilterValues(filterResult);
    setFilters(result);

    history.push(MANAGER_ROUTES.EVENT_ACTION);
    loadEventList(result);
  }, [history, loadEventList]);

  /*useEffect(() => {
    loadEventList(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadEventList]);*/

  useEffect(() => {
    const readCampaigns = async () => {
      let list = await bmapi.getAllTenantCampaigns();
      setCampaignList(list);
    };
    readCampaigns();
  }, [bmapi]);

  /*useEffect(() => {
    const extractFilterValues = (str) => {
      const filterIndex = str.indexOf("filters=");
      if (filterIndex === -1) {
        return null;
      }

      const filterValues = str.substring(filterIndex);
      return filterValues;
    };

    const filterResult = extractFilterValues(location.pathname);

    const result = getFilterValues(filterResult);
    setFilters(result);

    history.push(MANAGER_ROUTES.EVENT_ACTION);
  }, [history]);*/

  const handleOpen = (e, event) => {
    setAnchorEl(e.currentTarget);
    setEventId(event.id);
  };

  const handleFiltersApplied = (query, param, clone = "") => {
    const newFilters = {
      ...filters,
      selected_type:
        filters.selected_type === "-" ? null : filters.selected_type,
    };
    const isFiltered = checkObject(newFilters);
    if (isFiltered) {
      history.push(
        MANAGER_ROUTES.EVENT_ACTION_CREATE +
          clone +
          "filters=" +
          (newFilters.selected_type ? "type" + newFilters.selected_type : "") +
          (newFilters.from
            ? "from" + format(new Date(newFilters.from), "dd-MM-yyyy")
            : "") +
          (newFilters.to
            ? "to" + format(new Date(newFilters.to), "dd-MM-yyyy")
            : "") +
          query +
          param
      );
    } else {
      history.push(MANAGER_ROUTES.EVENT_ACTION_CREATE + clone + query + param);
    }
  };

  const update = (e) => {
    startLoading();
    e.preventDefault();
    loadEventList(filters);
    /*const params = {
      from: filters.from ? format(filters.from, "yyyy-MM-dd") : null,
      to: filters.to ? format(filters.to, "yyyy-MM-dd") : null,
      type: filters.selected_type === "-" ? null : filters.selected_type,
    };

    for (const property in params) {
      if (params[property] === null) {
        delete params[property];
      }
    }

    bmapi
      .getEventActions(params)
      .then((resp) => {
        if (!resp) {
          resp = [];
          setEvents(resp);
        } else {
          setEvents(resp);
          setFiltersApplied(true);
        }
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });*/
  };

  const eventActionDelete = (eventId, filters) => {
    startLoading();

    setShowDeleteAlert(false);
    bmapi
      .deleteEventAction(eventId)
      .then(() => {
        setEventId(null);
        notifySuccess(intl.formatMessage(eventActions.successDelete));
        loadEventList(filters);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  };

  const handleEvent = (eventId, clone = "") => {
    const query = "?id=";
    const param = eventId;
    if (filtersApplied) {
      handleFiltersApplied(query, param, clone);
    } else
      history.push(MANAGER_ROUTES.EVENT_ACTION_CREATE + clone + query + param);
  };

  /*const modifyEvent = (eventId) => {
    formatUrl(eventId, "");
    const query = "?id=";
    const param = eventId;
    if (filtersApplied) {
      handleFiltersApplied(query, param);
    } else history.push(MANAGER_ROUTES.EVENT_ACTION_CREATE + query + param);
  };

  const cloneEvent = (eventId) => {
    formatUrl(eventId, "clone");
    const clone = "clone";
    const query = "?id=";
    const param = eventId;
    if (filtersApplied) {
      handleFiltersApplied(query, param, clone);
    } else
      history.push(MANAGER_ROUTES.EVENT_ACTION_CREATE + clone + query + param);
  };*/

  return (
    <Container maxWidth="xl">
      <Title>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box>
            <FormattedMessage
              id="navigation.manager.events"
              defaultMessage="Eventi"
            />
          </Box>
        </Box>
      </Title>
      <Container maxWidth="md">
        <Box mb={2}>
          <Card>
            <CardContent>
              <form onSubmit={update}>
                <TextField
                  style={{ minWidth: "50%" }}
                  select
                  label={intl.formatMessage(eventActions.filterType)}
                  value={filters.selected_type}
                  onChange={handleFilters("selected_type")}
                >
                  {EVENT_TYPES.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {intlFormatMessage(
                        eventActions[option.label],
                        option.label,
                        intl
                      )}
                    </MenuItem>
                  ))}
                </TextField>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexWrap="wrap"
                  style={{ width: "50%" }}
                >
                  <DatePicker
                    label={intl.formatMessage(common.filterFrom)}
                    value={filters.from}
                    onChange={handleFilters("from")}
                    margin="normal"
                    clearable
                  />
                  <DatePicker
                    label={intl.formatMessage(common.filterTo)}
                    value={filters.to}
                    onChange={handleFilters("to")}
                    margin="normal"
                    minDate={filters.from}
                    clearable
                    onError={(e) => (e ? setError(true) : setError(false))}
                  />
                </Box>
                <Box mt={2}>
                  <FormControl style={{ float: "right" }} margin="normal">
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={error}
                    >
                      {intl.formatMessage(common.update)}
                    </Button>
                  </FormControl>
                </Box>
              </form>
            </CardContent>
          </Card>
        </Box>
      </Container>
      <Box mt={2} mb={2}>
        <Button
          component={RouterLink}
          to={MANAGER_ROUTES.HOME}
          startIcon={<ArrowBack />}
        >
          {intl.formatMessage(common.backHome)}
        </Button>
      </Box>
      {smallScreen && (
        <React.Fragment>
          {events.length !== 0 ? (
            <Card>
              <List>
                {events.sort(byDate).map((ev, i) => (
                  <React.Fragment key={i}>
                    {i !== 0 && <Divider variant="inset" component="li" />}
                    <ListItem>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography gutterBottom variant="h6">
                              {ev.title}
                            </Typography>
                            <Typography gutterBottom>
                              {intl.formatMessage(form.type)}:{" "}
                              {getLabel(ev.type)}
                            </Typography>
                            <Typography gutterBottom>
                              {intl.formatMessage(common.campaign)}:{" "}
                              {getCampaignLabel(ev.campaign_id)}
                            </Typography>
                          </React.Fragment>
                        }
                        secondary={`${intl.formatMessage(
                          common.from
                        )} ${new Date(ev.start_at).toLocaleDateString("it-IT", {
                          day: "numeric",
                          year: "numeric",
                          month: "short",
                        })} ${intl.formatMessage(common.to)} ${new Date(
                          ev.end_at
                        ).toLocaleDateString("it-IT", {
                          day: "numeric",
                          year: "numeric",
                          month: "short",
                        })}`}
                      />
                      <ListItemSecondaryAction>
                        <IconButton onClick={(e) => handleOpen(e, ev)}>
                          <MoreVert />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </React.Fragment>
                ))}
              </List>
            </Card>
          ) : (
            <Card>
              <CardContent style={{ padding: 16 }}>
                {intl.formatMessage(eventActions.noEvent)}
              </CardContent>
            </Card>
          )}
        </React.Fragment>
      )}
      {!smallScreen && (
        <React.Fragment>
          {events.length !== 0 ? (
            <Card>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{intl.formatMessage(common.name)}</TableCell>
                      <TableCell>{intl.formatMessage(form.type)}</TableCell>
                      <TableCell>
                        {intl.formatMessage(common.campaign)}
                      </TableCell>
                      <TableCell>{intl.formatMessage(common.date)}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  {events.sort(byDate).map((ev) => (
                    <TableBody key={ev.id}>
                      <TableRow>
                        <TableCell>{ev.title}</TableCell>
                        <TableCell>{getLabel(ev.type)}</TableCell>
                        <TableCell>
                          {getCampaignLabel(ev.campaign_id)}
                        </TableCell>
                        <TableCell>
                          {ev.start_at === "0001-01-01T00:00:00Z" &&
                          ev.end_at === "0001-01-01T00:00:00Z"
                            ? "-"
                            : `${intl.formatMessage(
                                common.from
                              )} ${intl.formatDate(
                                ev.start_at
                              )} ${intl.formatMessage(
                                common.from
                              )} ${intl.formatDate(ev.end_at)}`}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={(e) => handleOpen(e, ev)}
                            style={{ padding: 0 }}
                          >
                            <MoreVert />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
                </Table>
              </TableContainer>
            </Card>
          ) : (
            <Card>
              <CardContent style={{ padding: 16 }}>
                {intl.formatMessage(eventActions.noEvent)}
              </CardContent>
            </Card>
          )}
        </React.Fragment>
      )}

      <Menu
        key={eventId}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setEventId(null);
        }}
        keepMounted
        onClick={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => handleEvent(eventId)}>
          <ListItemIcon>
            <Create />
          </ListItemIcon>
          <Typography variant="inherit">
            {intl.formatMessage(common.modify)}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setShowDeleteAlert(true);
          }}
        >
          <ListItemIcon>
            <Delete />
          </ListItemIcon>
          <Typography variant="inherit">
            {intl.formatMessage(common.delete)}
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleEvent(eventId, "clone/");
          }}
        >
          <ListItemIcon>
            <FileCopy />
          </ListItemIcon>
          <Typography variant="inherit">
            {intl.formatMessage(common.clone)}
          </Typography>
        </MenuItem>
      </Menu>

      <Confirm
        open={showDeleteAlert}
        onConfirm={() => {
          eventActionDelete(eventId, filters);
        }}
        onCancel={() => {
          setEventId(null);
          setShowDeleteAlert(false);
        }}
        text={intl.formatMessage(confirm.deleteElement)}
      />

      <FloatingActions>
        <CreateEventActionButton
          handleFiltersApplied={handleFiltersApplied}
          filtersApplied={filtersApplied}
          trigger={(onClick) => (
            <Action
              icon={<Add />}
              label={intl.formatMessage(eventActions.addEvent)}
              action={onClick}
            />
          )}
        />
      </FloatingActions>
    </Container>
  );
}
