import React from "react";
import { useIntl } from "react-intl";

import { TextField } from "@material-ui/core";

import { common } from "../../../messages";
import { useBmapi } from "../../../utils/bmapi-context";

export function Mobile({ value, onChange }) {
  const intl = useIntl();
  const { bmapi } = useBmapi();

  return (
    <TextField
      fullWidth
      inputProps={{ maxLength: 15, pattern: "^[+]?[0-9 ]{1,15}$" }}
      label={intl.formatMessage(common.mobile)}
      margin="normal"
      name="mobile"
      onChange={onChange}
      required={bmapi.settings.profileRequired.includes("mobile")}
      type="tel"
      value={value}
    />
  );
}
