export function getKeyValuesUpdated(keyValues, key, values, genlist_id) {
  if (!key || !values || !Array.isArray(values)) {
    return keyValues;
  }

  // Filter out objects with matching `key`
  const updatedKeyValues = keyValues
    ? keyValues.filter((item) => item.key !== key)
    : [];

  // Add the new object with the provided `key` and `ids`
  updatedKeyValues.push({ key, values: values, genlist_id });

  return updatedKeyValues;
}

export function getKeyValuesByKey(keyValues, key) {
  const kv = keyValues?.find((item) => item.key === key);
  const values = kv?.values || [];
  return values;
}
