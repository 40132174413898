import React, { useState, useCallback, useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams, useHistory } from "react-router-dom";
import { addMinutes } from "date-fns";

import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  Check as CheckIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
} from "@material-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";

import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { IconsMap } from "../../utils/campaigns";
import { CONSUMER_ROUTES } from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";
import styles from "../../utils/styles";

function getEventDatetime(event) {
  const minutes = event.start_hour
    ? +event.start_hour.split(":")[0] * 60 + +event.start_hour.split(":")[1]
    : 0;
  return addMinutes(new Date(event.start_date), minutes);
}

function byDate(a, b) {
  return getEventDatetime(a) - getEventDatetime(b);
}

function byBusinessName(a, b) {
  return a.business_name.localeCompare(b.business_name);
}

function EventCard({ event, products, waitingList }) {
  const classes = styles.useStyles();
  const intl = useIntl();
  const { bmapi, notifyError } = useBmapi();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [issued, setIssued] = useState(false);
  const [booked, setBooked] = useState(false);
  const [full, setFull] = useState(false);

  const Icon = useMemo(() => {
    if (products?.length || issued) return CheckIcon;
    if (waitingList || booked) return PlaylistAddCheckIcon;
    return IconsMap[event.rules.subtype];
  }, [products, waitingList, event.rules.subtype, issued, booked]);

  const iconBg = useMemo(() => {
    if (products?.length || issued) return bmapi.theme.app.palette.success.main;
    if (waitingList || booked) return bmapi.theme.app.palette.warning.main;
    return undefined;
  }, [products, waitingList, bmapi.theme.app.palette, issued, booked]);

  const actionEnabled = useMemo(() => {
    if (issued || booked || full) return false;
    if (!bmapi.checkIfLoggedIn()) return false;
    if (bmapi.needsVerification()) return false;
    if (products?.length) return false;
    if (waitingList) return false;
    if (!event.link_distribution) return false;
    return true;
  }, [bmapi, event, products, waitingList, issued, booked, full]);

  const issue = () => {
    return bmapi.acceptProduct(event.id).then(() => setIssued(true));
    // .finally(() => {
    //   setAcceptTOS(!campaign.tos_consumer_url);
    // });
  };

  const book = () => {
    return bmapi.reserveCampaign(event.id).then(() => setBooked(true));
  };

  const runAction = () => {
    if (event.max_issue_number === -1) return book();
    if (event.max_issue_number === 0) return issue();

    if (event.max_issue_number > 0) {
      return bmapi.getCampaign(event.id).then((c) => {
        if (c.issued_qty < event.max_issue_number) return issue();
        if (event.waiting_list) return book();
        setFull(true);
        notifyError(
          intl.formatMessage({
            id: "pages.calendar.noSlotsAvailable",
            defaultMessage: "Posti esauriti",
          })
        );
      });
    }
  };

  const action = () => {
    setLoading(true);
    runAction().then(() => setLoading(false));
  };

  return (
    <ListItem
      button
      onClick={() => {
        history.push(CONSUMER_ROUTES.CAMPAIGN.replace(":campaignId", event.id));
      }}
    >
      {Icon && (
        <ListItemAvatar>
          <Avatar
            className={classes.cardIcon}
            style={iconBg && { backgroundColor: iconBg }}
          >
            <Icon />
          </Avatar>
        </ListItemAvatar>
      )}
      <ListItemText
        primary={
          <React.Fragment>
            <Typography
              variant="caption"
              display="block"
              color={event.loop_campaign ? "primary" : "inherit"}
            >
              {event.business_name}
            </Typography>
            <Typography display="block" gutterBottom>
              {event.name}
            </Typography>
          </React.Fragment>
        }
        secondary={`${intl.formatDate(new Date(event.start_date))} ${
          event.start_hour
        }${
          event.expiration_date !== event.start_date || event.end_hour
            ? " → "
            : ""
        }${
          event.expiration_date !== event.start_date
            ? intl.formatDate(new Date(event.expiration_date))
            : ""
        } ${event.end_hour}`}
      />
      {actionEnabled && (
        <ListItemSecondaryAction>
          <span style={{ display: "inline-block", position: "relative" }}>
            <IconButton onClick={action} disabled={loading}>
              <AddIcon />
            </IconButton>
            {loading && (
              <CircularProgress size={48} className={classes.fabProgress} />
            )}
          </span>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

export default function Calendar() {
  const { campaignId } = useParams();
  const history = useHistory();
  const intl = useIntl();
  const { bmapi, userId, startLoading, stopLoading, notifyError } = useBmapi();
  const [campaign, setCampaign] = useState(null);
  const [events, setEvents] = useState(null);
  const [products, setProducts] = useState(null);
  const [reservations, setReservations] = useState(null);
  const [stores, setStores] = useState(null);
  const [currentStore, setCurrentStore] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const selectStore = useCallback(
    (id) => {
      setCurrentStore(id);
      setAnchorEl(null);
      bmapi.setUserData({ calendarFilter: id });
    },
    [bmapi]
  );

  const updateData = useCallback(() => {
    startLoading();

    Promise.all([
      bmapi.getCampaign(campaignId),
      bmapi.getEventsByCampaign(campaignId),
      userId ? bmapi.getUserProducts() : [],
      userId ? bmapi.getUserReservations() : [],
    ])
      .then(([campaign, events, products, reservations]) => {
        setCampaign(campaign);
        setEvents(events.sort(byDate));
        setStores(
          Object.entries(
            events
              .filter((e) => !e.loop_campaign)
              .sort(byBusinessName)
              .reduce(
                (acc, e) => ({
                  ...acc,
                  [e.business_id]: e.business_name,
                }),
                {}
              )
          )
        );
        setProducts(products);
        setReservations(reservations);
        selectStore(
          bmapi.getUserData().calendarFilter !== undefined
            ? bmapi.getUserData().calendarFilter
            : products
                .filter(
                  (p) => p.campaign_id === campaignId && p.business_restrictions
                )
                .map((p) => p.business_restrictions)
                .flat()[0] || 0
        );
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  }, [
    campaignId,
    bmapi,
    intl,
    userId,
    startLoading,
    stopLoading,
    notifyError,
    selectStore,
  ]);

  useEffect(() => {
    updateData();
  }, [updateData]);

  const eventsForDate = (date) => (event) => {
    return event.start_date === date;
  };

  const storeEvents = (event) => {
    return (
      currentStore === 0 ||
      event.loop_campaign ||
      event.business_id === currentStore
    );
  };

  const dates = [
    ...new Set(events?.filter(storeEvents).map((e) => e.start_date)),
  ];

  return (
    !!events && (
      <Container maxWidth="sm">
        <Title>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            flexWrap="wrap"
          >
            <Box>
              <IconButton onClick={history.goBack} size="small">
                <ArrowBackIcon />
              </IconButton>{" "}
              {campaign.campaign.name}
            </Box>
            <Button
              onClick={(event) => setAnchorEl(event.currentTarget)}
              color={currentStore ? "primary" : "inherit"}
            >
              <FormattedMessage id="common.filter" defaultMessage="Filtro" />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem onClick={() => selectStore(0)}>
                <FormattedMessage
                  id="common.allStores"
                  defaultMessage="Tutti i negozi"
                />
              </MenuItem>
              {stores?.map((option) => (
                <MenuItem
                  key={option[0]}
                  onClick={() => selectStore(option[0])}
                  selected={option[0] === currentStore}
                >
                  {option[1]}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Title>

        <Box mb={4}>
          {events.length > 0 ? (
            dates.map((date) => (
              <Box mb={2} key={date}>
                <Card>
                  <List
                    subheader={
                      <ListSubheader>
                        {intl.formatDate(new Date(date), {
                          month: "long",
                          year: "numeric",
                          day: "numeric",
                        })}
                      </ListSubheader>
                    }
                  >
                    {events
                      .filter(storeEvents)
                      .filter(eventsForDate(date))
                      .map((event) => (
                        <EventCard
                          key={event.id}
                          event={event}
                          products={products?.filter(
                            (p) => p.campaign_id === event.id
                          )}
                          waitingList={reservations?.some(
                            (res) =>
                              res.campaign_id === event.id && res.status === 0
                          )}
                        />
                      ))}
                  </List>
                </Card>
              </Box>
            ))
          ) : (
            <Card>
              <CardContent>
                <FormattedMessage
                  id="pages.calendar.noEvents"
                  defaultMessage="Non ci sono eventi programmati"
                />
              </CardContent>
            </Card>
          )}
        </Box>

        <Box my={2}>
          <Button onClick={history.goBack} startIcon={<ArrowBackIcon />}>
            <FormattedMessage id="common.back" defaultMessage="Indietro" />
          </Button>
        </Box>
      </Container>
    )
  );
}
