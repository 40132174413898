import {
  Dialog,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  TextField,
  useMediaQuery,
  useTheme,
  Box,
  Button,
  DialogActions,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import { common } from "../messages";
import { langs } from "../utils/dictionaries";
import { getLanguageLabel } from "../utils/utils";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import CheckTranslations from "../ui/CheckTranslations";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const FieldTranslation = ({
  items,
  availableLanguages,
  onChange,
  title,
  language,
  editor,
}) => {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const intl = useIntl();

  const valueRichEditor = items;

  const getNewArr = () => {
    const newArr = [];
    for (let i = 0; i < availableLanguages.length; i++) {
      newArr.push(items.get(availableLanguages[i]) || "");
    }
    return newArr;
  };

  const newItemsArr = getNewArr();

  const translatedItems = (text) => text !== "" && text !== "<p><br></p>"; // ReactQuill empty ""
  const checkItemsTranslation = newItemsArr?.map((item) =>
    translatedItems(item)
  );

  const handleOpenTranslation = () => {
    setOpen(true);
  };

  const handleCloseTranslation = () => {
    setOpen(false);
  };

  const currentLang = language && langs.find((l) => l.value === language);

  const otherLang = availableLanguages.map((language) =>
    langs.find((l) => l.value === language)
  );

  if (!currentLang) {
    return <></>;
  }

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline"],
      ["clean"],
    ],
    //htmlEditButton: { debug: true, syntax: true },
  };

  return (
    <>
      <Button onClick={handleOpenTranslation} disableRipple>
        <CheckTranslations
          icon={<PlaylistAddIcon />}
          secondIcon={
            <currentLang.Flag
              title={currentLang.label}
              style={{ margin: "5px 0", width: 24 }}
            />
          }
          translations={checkItemsTranslation}
        />
      </Button>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleCloseTranslation}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <List>
            {editor
              ? otherLang.map((item, index) => (
                  <ListItem key={index} style={{ display: "flex" }}>
                    <div style={{ position: "absolute", top: "-25px" }}>
                      <item.Flag
                        style={{ margin: "11px 10px", width: 24 }}
                        key={item}
                      />
                      <Typography
                        variant="h6"
                        style={{
                          position: "absolute",
                          top: "3px",
                          left: "35px",
                        }}
                      >
                        {getLanguageLabel(availableLanguages[index]).label}
                      </Typography>
                    </div>
                    <Box marginLeft="10px">
                      {editor == undefined && (
                        <TextField
                          //value={items[index]}
                          value={items.get(item.value)}
                          fullWidth
                          multiline
                          //onChange={onChange(index)}
                          onChange={onChange(item.value)}
                        />
                      )}
                      {editor != undefined && (
                        <ReactQuill
                          //onChange={onChange(index)}
                          onChange={onChange(item.value)}
                          placeholder="Inserire il nome dell’autore e il tipo di licenza con il quale il contenuto può essere condiviso"
                          modules={modules}
                          //value={valueRichEditor[index]}
                          value={valueRichEditor.get(item.value)}
                        />
                      )}
                    </Box>
                  </ListItem>
                ))
              : otherLang.map((item, index) => (
                  <ListItem key={index} style={{ display: "flex" }}>
                    <item.Flag
                      style={{ margin: "5px", width: 24 }}
                      key={item}
                    />
                    <ListItemText variant="h6">
                      {getLanguageLabel(availableLanguages[index]).label}
                    </ListItemText>
                    <Box marginLeft="10px">
                      <TextField
                        //value={items[index]}
                        value={items.get(item.value)}
                        fullWidth
                        multiline
                        //onChange={onChange(index)}
                        onChange={onChange(item.value)}
                      />
                    </Box>
                  </ListItem>
                ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTranslation} variant="contained">
            {intl.formatMessage(common.close)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FieldTranslation;
