import React from "react";
import {
  Done,
  Label,
  Grade,
  LocalActivity,
  CheckCircle,
  CardMembership,
  EmojiEvents,
  CheckBoxOutlineBlank,
  CropFree,
  Loop,
  Class,
  Eco,
} from "@material-ui/icons";

export const EVENT_TYPES = [
  { value: "-", label: "noType" },
  {
    value: "CHECKIN_EVENT_ACTION",
    label: "checkin",
    icon: <CheckCircle />,
    show_campaign: true,
  },
  { value: "PRE_CHECKIN_EVENT_ACTION", label: "preCheckin", icon: <Done /> },
  {
    value: "BONUS_POINTS_EVENT_ACTION",
    label: "bonusPoints",
    icon: <EmojiEvents />,
  },
  {
    value: "BONUS_PLAY_EVENT_ACTION",
    label: "bonusPlay",
    icon: <LocalActivity />,
  },
  {
    value: "MULTIPLY_PLAY_EVENT_ACTION",
    label: "multiplyPlay",
    icon: <Loop />,
    show_campaign: true,
  },
  {
    value: "COMPOSITE_PLAY_EVENT_ACTION_TYPE_MULTI_STAMPS",
    label: "compositePlayStamps",
    icon: <CardMembership />,
    show_campaign: true,
  },
  {
    value: "COMPOSITE_PLAY_EVENT_ACTION_TYPE_MULTI_COUPON",
    label: "compositePlayCoupons",
    icon: <CheckBoxOutlineBlank />,
    show_campaign: true,
  },
  {
    value: "BONUS_EASTER_EVENT_ACTION",
    label: "bonusEaster",
    icon: <Grade />,
  },
  {
    value: "BONUS_CATEGORY_EVENT_ACTION",
    label: "bonusCategory",
    icon: <Class />,
    show_campaign: true,
  },
  { value: "BANNER_EVENT_ACTION", label: "banner", icon: <Label /> },
  {
    value: "SCANNER_MESSAGE_EVENT_ACTION",
    label: "scanner",
    icon: <CropFree />,
  },
  {
    value: "GREEN_EVENT_ACTION",
    label: "greenPlay",
    icon: <Eco />,
  },
];
