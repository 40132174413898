import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Container,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { account, common, confirm, errors, form } from "../../messages";
import Confirm from "../../ui/Confirm";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { MANAGER_ROUTES } from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";

const tags = [
  { value: "SANDWICH", label: "Panini" },
  { value: "SALADS", label: "Insalate" },
  { value: "BURGERS", label: "Hamburger" },
  { value: "DRINKS", label: "Bevande" },
  { value: "PIZZA", label: "Pizza" },
  { value: "SIDES", label: "Contorni" },
];

export default function EProductCategory() {
  const [values, setValues] = useState({
    name: "",
    description: "",
    tags: [],
  });
  const intl = useIntl();
  const history = useHistory();
  const { bmapi, notifyError, notifySuccess } = useBmapi();

  const handleValue = useCallback(
    (varName) => (e) => {
      ((val) => setValues((v) => ({ ...v, [varName]: val })))(e.target.value);
    },
    []
  );

  const isSelected = (code) => {
    if (!values.tags) {
      return false;
    }
    const filtered = values.tags.filter((obj) => obj.value === code);
    return filtered && filtered.length;
  };

  const deleteTag = (tagToDelete) => {
    let newTag = [];
    values.tags.map((tag) => {
      if (tag.value != tagToDelete.value) {
        newTag.push(tag);
      }
    });
    setValues((v) => ({
      ...v,
      ["tags"]: newTag,
    }));
  };

  const getLabel = useCallback((code) => {
    const filtered = tags.filter((obj) => obj.value === code);
    return filtered && filtered.length ? filtered[0].label : "";
  }, []);

  useEffect(() => {
    const categoryId = new URLSearchParams(location.search).get("id");
    if (categoryId) {
      if (bmapi) {
        loadCategory(categoryId);
      }
    }
  }, [bmapi, loadCategory]);

  const loadCategory = useCallback(
    (categoryId) => {
      bmapi
        .getEProductCategories({
          category: categoryId,
          //business: bmapi.getUserInfo().business.id,
        })
        .then((resp) => {
          if (!resp) {
            notifyError(intl.formatMessage(errors.genericError));
          } else {
            let category = {
              ...resp[0],
              tags: !resp[0].tags
                ? []
                : resp[0].tags.map((code) => {
                    return { value: code, label: getLabel(code) };
                  }),
            };
            setValues(category);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    },
    [bmapi, intl, notifyError, getLabel]
  );

  const confirmCategory = (event) => {
    event.preventDefault();
    let category = {
      ...values,
      tags: !values.tags ? [] : values.tags.map((obj) => obj.value),
    };
    if (!category.id) {
      bmapi
        .createEProductCategory(category)
        .then((resp) => {
          history.push(MANAGER_ROUTES.EPRODUCT_CATEGORY + "?id=" + resp.id);
          setValues(category);
          //loadCategory(category.id);
          notifySuccess(intl.formatMessage(account.saveConfirm));
          history.push(MANAGER_ROUTES.MANAGE_PRODUCTS);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    } else {
      bmapi
        .updateCategory(category)
        .then(() => {
          notifySuccess(intl.formatMessage(account.saveConfirm));
        })
        .then(() => {
          history.push(MANAGER_ROUTES.MANAGE_PRODUCTS);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
  };

  return (
    <Container maxWidth="sm">
      <Title>Nuova Categoria</Title>
      <form onSubmit={confirmCategory}>
        <Box mb={2}>
          <Card>
            <CardContent>
              <FormControl fullWidth required margin="normal">
                <InputLabel>{intl.formatMessage(common.name)}</InputLabel>
                <Input
                  type="text"
                  value={values.name}
                  name="name"
                  onChange={handleValue("name")}
                />
              </FormControl>
              <FormControl fullWidth required margin="normal">
                <InputLabel>{intl.formatMessage(form.description)}</InputLabel>
                <Input
                  type="text"
                  value={values.description}
                  name="description"
                  onChange={handleValue("description")}
                  multiline
                />
              </FormControl>
              <FormControl fullWidth required margin="normal">
                <InputLabel>Tag</InputLabel>
                <Select
                  name="tags"
                  multiple
                  fullWidth
                  value={values.tags}
                  onChange={handleValue("tags")}
                  input={<Input id="select-multiple-chip" />}
                  renderValue={(selected) => (
                    <Box style={{ display: "flex", flexWrap: "wrap" }}>
                      {selected.map((tag) => (
                        <Chip
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                          onDelete={() => deleteTag(tag)}
                          key={tag.value}
                          label={tag.label}
                        />
                      ))}
                    </Box>
                  )}
                >
                  {tags.map(
                    (tag) =>
                      !isSelected(tag.value) && (
                        <MenuItem key={tag.value} value={tag}>
                          {tag.label}
                        </MenuItem>
                      )
                  )}
                </Select>
              </FormControl>
            </CardContent>
          </Card>
        </Box>

        <Box mb={2}>
          <CardActions>
            <Button type="submit" variant="contained" color="primary">
              {intl.formatMessage(values.id ? common.update : common.create)}
            </Button>
            {values.id && (
              <>
                <Button
                  onClick={() => {
                    history.push(MANAGER_ROUTES.MANAGE_PRODUCTS);
                    //setShowUnsavedAlert(true);
                  }}
                  variant="contained"
                >
                  {intl.formatMessage(common.cancel)}
                </Button>
                <Confirm
                  //open={showUnsavedAlert}
                  onConfirm={() => history.push(MANAGER_ROUTES.MANAGE_PRODUCTS)}
                  onCancel={() => {
                    //setShowUnsavedAlert(false);
                  }}
                  text={intl.formatMessage(confirm.exitPrompt)}
                />
              </>
            )}
          </CardActions>
        </Box>
      </form>

      <FormControl margin="normal">
        <Button
          onClick={() => history.push(MANAGER_ROUTES.MANAGE_PRODUCTS)}
          startIcon={<ArrowBackIcon />}
        >
          {intl.formatMessage(common.back)}
        </Button>
      </FormControl>
    </Container>
  );
}
