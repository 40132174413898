import { defineMessages } from "react-intl";

export const account = defineMessages({
  changePhoto: {
    id: "pages.account.changePhotoButton",
    defaultMessage: "Cambia foto",
  },
  changePassword: {
    id: "pages.account.changePasswordSection",
    defaultMessage: "Cambio password",
  },
  currentPassword: {
    id: "pages.account.currentPassword",
    defaultMessage: "Password corrente",
  },
  deleteConfirm: {
    id: "page.account.deleteConfirm",
    defaultMessage: "Procedere con la richiesta di eliminazione dell'account?",
  },
  deleteConfirmMessage: {
    id: "pages.account.deleteConfirmMessage",
    defaultMessage: "Richiesta inoltrata",
  },
  extraInfo: {
    id: "page.account.extraInfo",
    defaultMessage: "Informazioni aggiuntive",
  },
  invalidPassword: {
    id: "pages.account.invalidPassword",
    defaultMessage: "Password non valida",
  },
  marketing: {
    id: "pages.account.marketing",
    defaultMessage: "Consensi al trattamento dei dati personali",
  },
  newPassword: {
    id: "pages.account.newPassword",
    defaultMessage: "Nuova password",
  },
  pageTitle: {
    id: "pages.account.title",
    defaultMessage: "Profilo",
  },
  personalData: {
    id: "pages.account.personal",
    defaultMessage: "Dati personali",
  },
  removeAccount: {
    id: "page.account.removeAccount",
    defaultMessage: "Rimozione account",
  },
  removeAccountText: {
    id: "page.account.removeAccountText",
    defaultMessage:
      "Se desideri rimuovere il tuo account ed eliminare tutti i tuoi dati <link>clicca qui</link>.",
  },
  saveConfirm: {
    id: "pages.account.saveConfirm",
    defaultMessage: "Salvataggio effettuato",
  },
});

export const accountExtra = defineMessages({
  registration_business: {
    id: "account.extra.registration_business",
    defaultMessage: "Negozio di registrazione",
  },
});
