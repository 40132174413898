import React, { useEffect } from "react";
import {
  Button,
  //TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";

const FormDialog = ({ openModal, handleSubmit }) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    openModal.current = openDialog;
  }, [openModal]);

  function openDialog() {
    setOpen(true);
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>Salvataggio</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Proseguendo aggiornerai tutte le modifiche apportate.
          </DialogContentText>
          {/*<TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
  />*/}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            Annulla
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              handleSubmit(0, 0, 0);
            }}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FormDialog;
