import React from "react";
import { useIntl } from "react-intl";

import { TextField } from "@material-ui/core";

import { form } from "../../../messages";

export default function Description({ values, handleChange }) {
  const intl = useIntl();

  return (
    <TextField
      name="description"
      label={intl.formatMessage(form.description)}
      value={values.description}
      onChange={handleChange("description")}
      key="description"
      fullWidth
      margin="normal"
      required
      multiline
    />
  );
}
