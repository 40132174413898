import React from "react";
import { format } from "date-fns";
import { useIntl } from "react-intl";

import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";

const byDate = (a, b) => new Date(a.check_in_at) - new Date(b.check_in_at);

export default function CardCheckins({ checkins }) {
  const intl = useIntl();

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">
          {intl.formatMessage({
            id: "component.campaign.checkin",
            defaultMessage: "Presenze",
          })}
        </Typography>
      </CardContent>
      {checkins &&
        (checkins.length ? (
          <List dense>
            {Object.values(
              checkins.reduce(
                (a, c) => ({
                  ...a,
                  [c.business_event_id]: [...(a[c.business_event_id] || []), c],
                }),
                {}
              )
            ).map((checkin) => (
              <ListItem key={checkin[0].id}>
                <ListItemText
                  primary={`${intl.formatDate(
                    new Date(checkin[0].check_in_at)
                  )} @ ${checkin[0].location_name}`}
                  secondary={checkin
                    .sort(byDate)
                    .map(
                      (c) =>
                        `${format(
                          new Date(c.check_in_at),
                          "HH:mm"
                        )} -> ${format(new Date(c.check_out_at), "HH:mm")}`
                    )
                    .join(" | ")}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <CardContent>
            <Typography>
              {intl.formatMessage({
                id: "component.campaign.noCheckin",
                defaultMessage: "Nessuna presenza ancora registrata",
              })}
            </Typography>
          </CardContent>
        ))}
    </Card>
  );
}
