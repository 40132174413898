import React, { useCallback, useEffect } from "react";
import {
  Button,
  Divider,
  Fab,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  Typography,
} from "@material-ui/core";
import {
  GroupWork as GroupWorkIcon,
  Close as CloseIcon,
  Check as CheckIcon,
  Lock as LockIcon,
} from "@material-ui/icons";
import { useBmapi } from "../utils/bmapi-context";
import { useIntl } from "react-intl";
import { cookieConsent } from "../messages/cookieConsent";

export default function CookiePreferences({
  setCookie,
  reloadConsent,
  updateCustomConsent,
}) {
  const { bmapi } = useBmapi();
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mandatoryConsent, setMandatoryConsent] = React.useState(false);
  const [preferencesConsent, setPreferencesConsent] = React.useState(false);
  const [marketingConsent, setMarketingConsent] = React.useState(false);
  const [statisticsConsent, setStatisticsConsent] = React.useState(false);
  const tenantId = bmapi.settings.tenantId;

  const handleOpenPreferences = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePreferences = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    checkMandatoryConsent();
    checkPreferencesConsent();
    checkMarketingConsent();
    checkStatisticsConsent();
  }, [
    checkMandatoryConsent,
    checkPreferencesConsent,
    checkMarketingConsent,
    checkStatisticsConsent,
  ]);

  const checkMandatoryConsent = useCallback(() => {
    if (document.cookie.split(";").some((item) => item.includes("mandatory:1")))
      setMandatoryConsent(true);
  }, []);

  const checkPreferencesConsent = useCallback(() => {
    if (
      document.cookie.split(";").some((item) => item.includes("preferences:1"))
    )
      setPreferencesConsent(true);
  }, []);

  const checkMarketingConsent = useCallback(() => {
    if (document.cookie.split(";").some((item) => item.includes("marketing:1")))
      setMarketingConsent(true);
  }, []);

  const checkStatisticsConsent = useCallback(() => {
    if (
      document.cookie.split(";").some((item) => item.includes("statistics:1"))
    )
      setStatisticsConsent(true);
  }, []);

  let custom = {
    preferences: preferencesConsent,
    marketing: marketingConsent,
    statistics: statisticsConsent,
  };

  return (
    <>
      <Fab
        variant="extended"
        color="secondary"
        style={{ position: "fixed", left: 10, bottom: 10, zIndex: 1210 }}
        onClick={handleOpenPreferences}
      >
        <GroupWorkIcon />
      </Fab>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClosePreferences}
      >
        <ListItem>
          <ListItemText
            primary={
              <Typography
                component={"span"}
                display="block"
                gutterBottom
                variant="h6"
              >
                {intl.formatMessage(cookieConsent.cookieSettings)}
              </Typography>
            }
          />
          <ListItemSecondaryAction>
            <IconButton onClick={handleClosePreferences}>
              <CloseIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem onClick={handleClosePreferences}>
          <Typography
            component={"span"}
            display="block"
            gutterBottom
            variant="h6"
          >
            {intl.formatMessage(cookieConsent.cookieStatus)}
          </Typography>
        </ListItem>
        <ListItem>
          {mandatoryConsent ? (
            <LockIcon style={{ margin: 5 }} />
          ) : (
            <CloseIcon style={{ margin: 5 }} />
          )}
          <Typography component={"span"} display="block" gutterBottom>
            {intl.formatMessage(cookieConsent.mandatory)}
          </Typography>
        </ListItem>
        <ListItem>
          {preferencesConsent ? (
            <CheckIcon style={{ margin: 5 }} />
          ) : (
            <CloseIcon style={{ margin: 5 }} />
          )}
          <Typography component={"span"} display="block" gutterBottom>
            {intl.formatMessage(cookieConsent.preferences)}
          </Typography>
        </ListItem>
        <ListItem>
          {statisticsConsent ? (
            <CheckIcon style={{ margin: 5 }} />
          ) : (
            <CloseIcon style={{ margin: 5 }} />
          )}
          <Typography component={"span"} display="block" gutterBottom>
            {intl.formatMessage(cookieConsent.statistics)}
          </Typography>
        </ListItem>
        <ListItem>
          {marketingConsent ? (
            <CheckIcon style={{ margin: 5 }} />
          ) : (
            <CloseIcon style={{ margin: 5 }} />
          )}
          <Typography component={"span"} display="block" gutterBottom>
            {intl.formatMessage(cookieConsent.marketing)}
          </Typography>
        </ListItem>
        <Divider />
        <Button
          onClick={() => {
            setCookie(tenantId, "1", "0", "0", "0", "0");
            setPreferencesConsent(false);
            setMarketingConsent(false);
            setStatisticsConsent(false);
          }}
          color="primary"
          style={{ margin: 10 }}
        >
          {intl.formatMessage(cookieConsent.revokeConsent)}
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: 10 }}
          onClick={() => {
            reloadConsent(true);
            updateCustomConsent(custom);
          }}
        >
          {intl.formatMessage(cookieConsent.modifyConsent)}
        </Button>
      </Menu>
    </>
  );
}
