import React, { useEffect, useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  List,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  Adjust as AdjustIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
} from "@material-ui/icons";

import ListItemInfo from "../ListItemInfo";
import { useBmapi } from "../../utils/bmapi-context";
import { VARIANTS } from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";

/**
 * STATUS ORDINI
 *  
 *  OrderInserted     = 0
	OrderProcessing   = 1
	OrderCompleted    = 2
	OrderDelivered    = 3
	OrderCanceled     = 4
	OrderNotCollected = 5
	OrderRemoved      = 6
 */
export default function OrderActionForm({ info }) {
  const intl = useIntl();
  const { bmapi, startLoading, stopLoading } = useBmapi();
  const [isDelivering, setIsDelivering] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  //TODO tradurre bene
  /*const ORDER_STATUS_MAP = {
    0: "Ordine Inserito",
    1: "Ordine In Fase Di Preparazione",
    2: "Ordine Completato",
    3: "Ordine Consegnato",
    4: "Ordine Cancellato",
    5: "Ordine Non Ritirato",
    6: "Ordine Rimosso",
  };*/

  function onSubmit(event) {
    event.preventDefault();
    setIsDelivering(true);
  }

  const deliver = useCallback(() => {
    startLoading();

    bmapi
      .changeOrderStatus(info.order.id, 3)
      .then(() => setSuccess(true))
      .catch((e) => {
        if (e.message == "order delivered yet") {
          setError(
            "L'ordine " +
              info.order.id.substring(info.order.id.length - 5) +
              " risulta essere già consegnato"
          );
        } else {
          setError(getErrorMessageString(e, intl));
        }
      })
      .finally(() => {
        stopLoading();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bmapi, intl, setError, setSuccess, startLoading, stopLoading, info]);

  useEffect(() => {
    if (isDelivering) deliver();
  }, [isDelivering, deliver]);

  //TODO vedere se usare il fast scan
  /*useEffect(() => {
    if (bmapi.settings.fastScan) setIsDelivering(true);
  }, [bmapi.settings.fastScan]);*/

  const hasRestrictions = () =>
    !!info.business_restriction &&
    !info.business_restriction.includes(bmapi.getUserInfo().business.id);

  return (
    <React.Fragment>
      {hasRestrictions() && (
        <Box mb={2}>
          <Alert severity="warning">
            <FormattedMessage
              id="component.order.actionForm.wrongBusiness"
              defaultMessage="Ordine valido ma associato ad un altro punto vendita"
            />
          </Alert>
        </Box>
      )}
      <Card>
        <CardContent>
          <form onSubmit={onSubmit}>
            <List>
              <ListItemInfo
                Icon={ConfirmationNumberIcon}
                label={"Ordine"}
                text={info.order.id.substring(info.order.id.length - 5)}
              />
              <ListItemInfo
                Icon={AdjustIcon}
                label={"Data Consegna"}
                text={new Date(info.order.planned_delivery).toLocaleString(
                  "it-IT",
                  {
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                )}
              />
              {/*<ListItemInfo
                Icon={AdjustIcon}
                label={intl.formatMessage({
                  id: "common.order.status",
                  defaultMessage: "Status",
                })}
                text={ORDER_STATUS_MAP[info.order.status]}
              />*/}
            </List>

            <FormControl fullWidth margin="normal">
              {!success && !error ? (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isDelivering}
                >
                  <FormattedMessage
                    id="common.confirm"
                    defaultMessage="Conferma"
                  />
                </Button>
              ) : (
                <Alert
                  variant="filled"
                  severity={success ? VARIANTS.SUCCESS : VARIANTS.ERROR}
                >
                  {success ? (
                    <FormattedMessage
                      id="component.orderActionForm.delivered"
                      defaultMessage="Ordine modificato correttamente"
                    />
                  ) : (
                    error
                  )}
                </Alert>
              )}
            </FormControl>
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
