import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  makeStyles,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import { useBmapi } from "../utils/bmapi-context";
import { cookieConsent } from "../messages/cookieConsent";
import { useIntl } from "react-intl";
import { form } from "../messages";

export default function CookieBanner2({
  openConsent,
  setOpenConsent,
  checkACookieExists,
  setCookie,
  customConsent,
  setCustomConsent,
}) {
  const { bmapi } = useBmapi();
  const intl = useIntl();
  const [filter, setFilter] = useState("consenso");
  const [customCookie, setCustomCookie] = React.useState({
    tenantId: tenantId,
    mandatory: "1",
    preferences: "0",
    marketing: "0",
    statistics: "0",
  });
  const tenantId = bmapi.settings.tenantId;

  const handleCustomCookie = (varName) => (e) => {
    ((val) => setCustomConsent((v) => ({ ...v, [varName]: val })))(
      e.target.checked
    );
    switch (varName) {
      case "preferences":
        if (e.target.checked) {
          setCustomCookie({ ...customCookie, preferences: "1" });
        } else setCustomCookie({ ...customCookie, preferences: "0" });
        break;
      case "statistics":
        if (e.target.checked) {
          setCustomCookie({ ...customCookie, statistics: "1" });
        } else setCustomCookie({ ...customCookie, statistics: "0" });
        break;
      case "marketing":
        if (e.target.checked) {
          setCustomCookie({ ...customCookie, marketing: "1" });
        } else setCustomCookie({ ...customCookie, marketing: "0" });
    }
  };

  const handleUserPreferences = () => {
    if (filter === "dettagli") {
      setCookie(
        tenantId,
        customCookie.mandatory,
        customCookie.preferences,
        customCookie.marketing,
        customCookie.statistics
      );
      setOpenConsent(false);
      checkACookieExists();
    } else {
      setFilter("dettagli");
    }
  };

  const handleDenyConsent = () => {
    setCookie(tenantId, "1", "0", "0", "0", "0");
    setOpenConsent(false);
    checkACookieExists();
  };

  const handleAllowConsent = () => {
    console.log(".......");
    setCookie(tenantId, "1", "1", "1", "1", "1");
    setOpenConsent(false);
    checkACookieExists();
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (openConsent) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openConsent]);

  const useStyles = makeStyles({
    topScrollPaper: {
      alignItems: "flex-end",
    },
    topPaperScrollBody: {
      verticalAlign: "top",
    },
  });

  const classes = useStyles();

  return (
    <Dialog
      open={openConsent}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll="paper"
      classes={{
        scrollPaper: classes.topScrollPaper,
        paperScrollBody: classes.topPaperScrollBody,
      }}
    >
      <Tabs
        value={filter}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        onChange={(_, v) => setFilter(v)}
      >
        <Tab
          label={intl.formatMessage(cookieConsent.consent)}
          value="consenso"
          style={{ minWidth: 0 }}
        />
        <Tab
          label={intl.formatMessage(form.details)}
          value="dettagli"
          style={{ minWidth: 0 }}
        />
        <Tab
          label={intl.formatMessage(cookieConsent.cookieInfo)}
          value="info cookie"
          style={{ minWidth: 0 }}
        />
      </Tabs>
      {/* TO DO: dynamic content */}
      {filter === "consenso" && (
        <DialogContent>
          <DialogTitle id="customized-dialog-title">
            {intl.formatMessage(cookieConsent.useCookie)}
          </DialogTitle>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Typography gutterBottom component={"span"}>
              Utilizziamo i cookie per personalizzare contenuti ed annunci, per
              fornire funzionalità dei social media e per analizzare il nostro
              traffico. Condividiamo inoltre informazioni sul modo in cui
              utilizza il nostro sito con i nostri partner che si occupano di
              analisi dei dati web, pubblicità e social media, i quali
              potrebbero combinarle con altre informazioni che ha fornito loro o
              che hanno raccolto dal suo utilizzo dei loro servizi.
            </Typography>
          </DialogContentText>
        </DialogContent>
      )}

      {filter === "dettagli" && (
        <DialogContent>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
            component="div"
          >
            <Box style={{ display: "flex" }}>
              <Accordion square>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div>
                    <Typography
                      component={"span"}
                      display="block"
                      gutterBottom
                      variant="h6"
                    >
                      {intl.formatMessage(cookieConsent.mandatory)}
                    </Typography>
                    <Typography component={"span"} display="block" gutterBottom>
                      I cookie necessari contribuiscono a rendere fruibile il
                      sito web abilitandone funzionalità di base quali la
                      navigazione sulle pagine e l&apos;accesso alle aree
                      protette del sito. Il sito web non è in grado di
                      funzionare correttamente senza questi cookie.
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography
                        component={"span"}
                        display="block"
                        gutterBottom
                        variant="h6"
                      >
                        Nome del cookie
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Card>
                        <CardContent style={{ backgroundColor: "#f5f5f5" }}>
                          <Typography
                            component={"span"}
                            display="block"
                            gutterBottom
                            style={{ fontWeight: "bold" }}
                          >
                            __RequestVerificationToken
                          </Typography>
                          <Typography
                            component={"span"}
                            display="block"
                            gutterBottom
                          >
                            Aiuta a prevenire gli attacchi CSRF (Cross-Site
                            Request Forgery).
                          </Typography>
                        </CardContent>
                        <Divider />
                        <CardContent
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            component={"span"}
                            display="block"
                            gutterBottom
                          >
                            Scadenza: Session
                          </Typography>
                          <Typography
                            component={"span"}
                            display="block"
                            gutterBottom
                          >
                            Tipo: HTTP
                          </Typography>
                        </CardContent>
                      </Card>
                    </AccordionDetails>
                  </Accordion>
                </AccordionDetails>
              </Accordion>
              <Switch color="primary" checked />
            </Box>
            <Box style={{ display: "flex" }}>
              <Accordion square>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div>
                    <Typography
                      component={"span"}
                      display="block"
                      gutterBottom
                      variant="h6"
                    >
                      {intl.formatMessage(cookieConsent.preferences)}
                    </Typography>
                    <Typography component={"span"} display="block" gutterBottom>
                      I cookie di preferenza consentono al sito web di
                      memorizzare informazioni che ne influenzano il
                      comportamento o l&apos;aspetto, quali la lingua preferita
                      o la località nella quale ti trovi.
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography
                        component={"span"}
                        display="block"
                        gutterBottom
                        variant="h6"
                      >
                        Nome del cookie
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Card>
                        <CardContent style={{ backgroundColor: "#f5f5f5" }}>
                          <Typography
                            component={"span"}
                            display="block"
                            gutterBottom
                            style={{ fontWeight: "bold" }}
                          >
                            __RequestVerificationToken
                          </Typography>
                          <Typography
                            component={"span"}
                            display="block"
                            gutterBottom
                          >
                            Aiuta a prevenire gli attacchi CSRF (Cross-Site
                            Request Forgery).
                          </Typography>
                        </CardContent>
                        <Divider />
                        <CardContent
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            component={"span"}
                            display="block"
                            gutterBottom
                          >
                            Scadenza: Session
                          </Typography>
                          <Typography
                            component={"span"}
                            display="block"
                            gutterBottom
                          >
                            Tipo: HTTP
                          </Typography>
                        </CardContent>
                      </Card>
                    </AccordionDetails>
                  </Accordion>
                </AccordionDetails>
              </Accordion>
              <Switch
                color="primary"
                name="preferences"
                onChange={handleCustomCookie("preferences")}
                checked={customConsent.preferences}
                key="preferences"
              />
            </Box>
            <Box style={{ display: "flex" }}>
              <Accordion square>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div>
                    <Typography
                      component={"span"}
                      display="block"
                      gutterBottom
                      variant="h6"
                    >
                      {intl.formatMessage(cookieConsent.statistics)}
                    </Typography>
                    <Typography component={"span"} display="block" gutterBottom>
                      I cookie statistici aiutano i proprietari del sito web a
                      capire come i visitatori interagiscono con i siti
                      raccogliendo e trasmettendo informazioni in forma anonima.
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography
                        component={"span"}
                        display="block"
                        gutterBottom
                        variant="h6"
                      >
                        Nome del cookie
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Card>
                        <CardContent style={{ backgroundColor: "#f5f5f5" }}>
                          <Typography
                            component={"span"}
                            display="block"
                            gutterBottom
                            style={{ fontWeight: "bold" }}
                          >
                            __RequestVerificationToken
                          </Typography>
                          <Typography
                            component={"span"}
                            display="block"
                            gutterBottom
                          >
                            Aiuta a prevenire gli attacchi CSRF (Cross-Site
                            Request Forgery).
                          </Typography>
                        </CardContent>
                        <Divider />
                        <CardContent
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            component={"span"}
                            display="block"
                            gutterBottom
                          >
                            Scadenza: Session
                          </Typography>
                          <Typography
                            component={"span"}
                            display="block"
                            gutterBottom
                          >
                            Tipo: HTTP
                          </Typography>
                        </CardContent>
                      </Card>
                    </AccordionDetails>
                  </Accordion>
                </AccordionDetails>
              </Accordion>
              <Switch
                color="primary"
                name="statistics"
                onChange={handleCustomCookie("statistics")}
                checked={customConsent.statistics}
                key="statistics"
              />
            </Box>
            <Box style={{ display: "flex" }}>
              <Accordion square>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <div>
                    <Typography
                      component={"span"}
                      display="block"
                      gutterBottom
                      variant="h6"
                    >
                      {intl.formatMessage(cookieConsent.marketing)}
                    </Typography>
                    <Typography component={"span"} display="block" gutterBottom>
                      I cookie di marketing vengono utilizzati per tracciare i
                      visitatori sui siti web. La finalità è quella di
                      presentare annunci pubblicitari che siano rilevanti e
                      coinvolgenti per il singolo utente e quindi di maggior
                      valore per editori e inserzionisti di terze parti.
                    </Typography>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography
                        component={"span"}
                        display="block"
                        gutterBottom
                        variant="h6"
                      >
                        Nome del cookie
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Card>
                        <CardContent style={{ backgroundColor: "#f5f5f5" }}>
                          <Typography
                            component={"span"}
                            display="block"
                            gutterBottom
                            style={{ fontWeight: "bold" }}
                          >
                            __RequestVerificationToken
                          </Typography>
                          <Typography
                            component={"span"}
                            display="block"
                            gutterBottom
                          >
                            Aiuta a prevenire gli attacchi CSRF (Cross-Site
                            Request Forgery).
                          </Typography>
                        </CardContent>
                        <Divider />
                        <CardContent
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            component={"span"}
                            display="block"
                            gutterBottom
                          >
                            Scadenza: Session
                          </Typography>
                          <Typography
                            component={"span"}
                            display="block"
                            gutterBottom
                          >
                            Tipo: HTTP
                          </Typography>
                        </CardContent>
                      </Card>
                    </AccordionDetails>
                  </Accordion>
                </AccordionDetails>
              </Accordion>
              <Switch
                color="primary"
                name="marketing"
                onChange={handleCustomCookie("marketing")}
                checked={customConsent.marketing}
                key="marketing"
              />
            </Box>
          </DialogContentText>
        </DialogContent>
      )}

      {filter === "info cookie" && (
        <DialogContent>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Typography gutterBottom>
              I cookie sono piccoli file di testo che possono essere utilizzati
              dai siti web per rendere più efficiente l&apos;esperienza per
              l&apos;utente.
            </Typography>
            <Typography gutterBottom>
              La legge afferma che possiamo memorizzare i cookie sul suo
              dispositivo se sono strettamente necessari per il funzionamento di
              questo sito. Per tutti gli altri tipi di cookie abbiamo bisogno
              del suo permesso.
            </Typography>
            <Typography gutterBottom>
              Questo sito utilizza diversi tipi di cookie. Alcuni cookie sono
              collocate da servizi di terzi che compaiono sulle nostre pagine.
            </Typography>
            <Typography gutterBottom>
              In qualsiasi momento è possibile modificare o revocare il proprio
              consenso dalla Dichiarazione dei cookie sul nostro sito Web.
            </Typography>
            <Typography gutterBottom>
              Scopri di più su chi siamo, come può contattarci e come trattiamo
              i dati personali nella nostra Informativa sulla privacy.
            </Typography>
          </DialogContentText>
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={handleDenyConsent} color="primary">
          {intl.formatMessage(cookieConsent.denyConsent)}
        </Button>
        <Button
          onClick={handleUserPreferences}
          color="primary"
          variant={filter === "dettagli" ? "contained" : "text"}
        >
          {filter === "dettagli"
            ? intl.formatMessage(cookieConsent.acceptChanges)
            : intl.formatMessage(cookieConsent.customize)}
        </Button>
        <Button
          onClick={handleAllowConsent}
          color="primary"
          variant={filter === "dettagli" ? "text" : "contained"}
        >
          {intl.formatMessage(cookieConsent.acceptAll)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
