import { defineMessages } from "react-intl";

export const actions = defineMessages({
  clone: {
    id: "component.campaignActions.cloneCampaign",
    defaultMessage: "Duplica campagna",
  },
  copyLink: {
    id: "component.campaignActions.copyLink",
    defaultMessage: "Copia link di condivisione",
  },
  delete: {
    id: "component.campaignActions.deleteCampaign",
    defaultMessage: "Elimina la campagna",
  },
  deleteProducts: {
    id: "component.campaignActions.deleteProducts",
    defaultMessage: "Elimina i prodotti della campagna",
  },
  edit: {
    id: "component.campaignActions.editCampaign",
    defaultMessage: "Modifica campagna",
  },
  info: {
    id: "component.campaignActions.moreInfo",
    defaultMessage: "Maggiori informazioni",
  },
  join: {
    id: "component.campaignActions.joinCampaign",
    defaultMessage: "Aderisci alla campagna",
  },
  unjoin: {
    id: "component.campaignActions.unjoinCampaign",
    defaultMessage: "Sospendi adesione alla campagna",
  },
  joinWaitingList: {
    id: "common.actions.joinWaitingList",
    defaultMessage: "Lista d'attesa",
  },
  loginOrRegister: {
    id: "common.actions.loginOrRegister",
    defaultMessage: "Accedi o registrati",
  },
  loginOrRegisterAfterQrCode: {
    id: "common.actions.loginOrRegisterAfterQrCode",
    defaultMessage:
      'Complimenti hai letto un bollino di "{campaignName}". Per proseguire devi autenticarti premendo il pulsante "{btnText}"',
  },
  useAfterQrCode: {
    id: "common.actions.useAfterQrCode",
    defaultMessage:
      'Complimenti hai letto un bollino di "{campaignName}". Per proseguire ed usufruire delle promozioni premi il pulsante "{btnText}"',
  },
  notifications: {
    id: "common.actions.notifications",
    defaultMessage: "Notifiche",
  },
  showCode: {
    id: "common.actions.showCode",
    defaultMessage: "Visualizza il codice",
  },
  start: {
    id: "common.actions.start",
    defaultMessage: "Avvia evento",
  },
  stats: {
    id: "common.actions.stats",
    defaultMessage: "Statistiche",
  },
  stop: {
    id: "common.actions.stop",
    defaultMessage: "Ferma evento",
  },
  verifyAccount: {
    id: "common.actions.verifyAccount",
    defaultMessage: "Verifica il tuo account",
  },
  waiting: {
    id: "component.campaignActions.waitingList",
    defaultMessage: "Lista d'attesa",
  },
});
