export const DISABILITIES = [
  { value: "PHYSICAL", label: "disabilityPhysical" },
  { value: "COGNITIVE", label: "disabilityCognitive" },
  { value: "AUTISM", label: "autism" },
  { value: "HEARING", label: "disabilityHearing" },
  { value: "VISUAL", label: "disabilityVisual" },
  { value: "IPOVISION", label: "disabilityIpoVision" },
  { value: "DEAFSIGNING", label: "disabilityDeafSigning" },
  { value: "AGED", label: "aged" },
  { value: "KIDS", label: "kids" },
];

export const ACTIVITIES = [
  { value: "cultural", label: "activityCultural" },
  { value: "naturalistic", label: "activityNature" },
  { value: "food_and_bev", label: "activityFood_Bev" },
  { value: "recreational", label: "activityRecreational" },
];

export const SUPPORTS = [
  { value: "FACILITATOR_COMPANION", label: "facilitatorCompanion" },
  { value: "OSS", label: "oss" },
  { value: "AUDIO_DESCRIPTION", label: "audioDescription" },
  { value: "FACILITATOR_GUIDE", label: "facilitatorGuide" },
  { value: "LIS", label: "lis" },
  { value: "LIS_VIDEO", label: "lisVideo" },
];

export const TRANSPORTS = [
  { value: "WALK", label: "transportWalk" },
  { value: "BIKE", label: "transportBike" },
  { value: "PUBLIC", label: "transportPublic" },
  { value: "PRIVATE", label: "transportPrivateTransfer" },
  { value: "CAR", label: "transportPrivateCar" },
];

export const LANGUAGES = [
  { value: "IT", label: "italian" },
  { value: "EN", label: "english" },
  { value: "FR", label: "french" },
  { value: "ES", label: "spanish" },
];

export const bgPos = {
  "": {
    backgroundSize: "cover",
    cursor: "pointer",
  },
  contain: {
    backgroundSize: "contain",
    cursor: "pointer",
  },
  center: {
    backgroundPosition: "center center",
    cursor: "pointer",
    backgroundSize: "cover",
  },
  top: {
    backgroundPosition: "25% 0%",
    cursor: "pointer",
    backgroundSize: "cover",
  },
  bottom: {
    backgroundPosition: "25% 100%",
    cursor: "pointer",
    backgroundSize: "cover",
  },
  right: {
    backgroundPosition: "right center ",
    cursor: "pointer",
    backgroundSize: "cover",
  },
  left: {
    backgroundPosition: "left center",
    cursor: "pointer",
    backgroundSize: "cover",
  },
};
