import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import {
  Card,
  CardContent,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";

import { useBmapi } from "../utils/bmapi-context";
import { CONSUMER_ROUTES, MESSAGE_STATUS } from "../utils/constants";
import { getErrorMessageString } from "../utils/errors";
import Title from "../ui/Title";
import { navigation } from "../messages";

function Message({ message }) {
  const history = useHistory();

  const goToMessage = () => {
    history.push(
      CONSUMER_ROUTES.MESSAGE.replace(":id", message.id).replace(
        ":messageId",
        message.message.id
      )
    );
  };

  return (
    <ListItem button onClick={goToMessage}>
      <ListItemIcon>
        {message.status !== MESSAGE_STATUS.READ && (
          <FiberManualRecord color="primary" />
        )}
      </ListItemIcon>
      <ListItemText
        primary={
          message.status === MESSAGE_STATUS.READ ? (
            message.message.title
          ) : (
            <strong>{message.message.title}</strong>
          )
        }
      />
    </ListItem>
  );
}

const alpha = (a, b) => new Date(b.created_at) - new Date(a.created_at);

export default function Messages() {
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const intl = useIntl();
  const [messages, setMessages] = useState(false);

  useEffect(() => {
    startLoading();
    const update = () =>
      bmapi
        .getMessages()
        .then((ms) => setMessages(ms.sort(alpha)))
        .catch((e) => notifyError(getErrorMessageString(e, intl)));

    update().finally(stopLoading);
    const to = setInterval(update, 10000);
    return () => clearInterval(to);
  }, [bmapi, intl, notifyError, startLoading, stopLoading]);

  return (
    messages && (
      <Container maxWidth="sm">
        <Title>{intl.formatMessage(navigation.notifications)}</Title>
        <Card>
          {messages.length ? (
            <List>
              {messages.map((m) => (
                <Message key={m.id} message={m} />
              ))}
            </List>
          ) : (
            <CardContent>
              <Typography>
                {intl.formatMessage({
                  id: "page.messages.noNotification",
                  defaultMessage: "Nessuna notifica ancora ricevuta",
                })}
              </Typography>
            </CardContent>
          )}
        </Card>
      </Container>
    )
  );
}
