import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import { Card, CardContent, Container, Typography } from "@material-ui/core";

import { useBmapi } from "../../utils/bmapi-context";
import { CONSUMER_ROUTES } from "../../utils/constants";

export default function FriendCode() {
  const history = useHistory();
  const { friendCode } = useParams();
  const { bmapi } = useBmapi();

  useEffect(() => {
    if (bmapi) bmapi.setTenantData({ friendCode });
    history.push(CONSUMER_ROUTES.SIGN_UP);
  }, [bmapi, friendCode, history]);

  return (
    <Container maxWidth="sm">
      <Card>
        <CardContent align="center">
          <Typography gutterBottom style={{ fontSize: 70 }}>
            {friendCode}
          </Typography>
        </CardContent>
      </Card>
    </Container>
  );
}
