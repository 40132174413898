import React from "react";
import { useIntl } from "react-intl";

import { FormControl, FormControlLabel, Switch } from "@material-ui/core";

import { PRODUCT_SUBTYPES } from "../../../utils/constants";
import { form } from "../../../messages";

export default function ManagerRestriction({ values, handleChange }) {
  const intl = useIntl();

  return (
    [PRODUCT_SUBTYPES.PROVISIONING_CARD].includes(values.subtype) && (
      <FormControl margin="normal">
        <FormControlLabel
          control={
            <Switch
              checked={values.manager_restriction}
              color="primary"
              onChange={handleChange("manager_restriction")}
              key="manager_restriction"
              name="manager_restriction"
            />
          }
          label={intl.formatMessage(form.managerRestriction)}
        />
      </FormControl>
    )
  );
}
