import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  List,
  ListItemText,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { addYears, endOfYear, format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { common } from "../messages";
import { employeeAccess } from "../messages/employeeAccess";
import { BUSINESS_TYPES } from "../utils/constants";
import { parseBmarkenDate } from "../utils/utils";
import { DatePicker } from "./DatePicker";
import NumberInput from "./NumberInput";

const AccessDialog = ({
  requestAuthorization,
  handleDelete,
  businesses,
  reset,
  values,
}) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const [value, setValue] = React.useState("lifespan");
  const [lifeSpan, setLifeSpan] = useState(12);
  const [date, setDate] = useState(addYears(new Date(), 1));

  const handleChange = (event) => {
    setValue(event.target.value);
    setLifeSpan(12);
    setDate(addYears(new Date(), 1));
  };

  useEffect(() => {
    setOpen(values.value);
  }, [values]);

  const getMaxMonth = () => {
    const today = new Date();
    const maxDate = endOfYear(addYears(new Date(), 5));

    const months =
      (maxDate.getFullYear() - today.getFullYear()) * 12 +
      (maxDate.getMonth() - today.getMonth());

    return months;
  };

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={() => {
        setOpen(false);
        reset();
      }}
    >
      {values.type === "access" ? (
        <DialogContent>
          {values.accesses?.map((acc, i) => (
            <React.Fragment key={acc.id}>
              {i !== 0 ? <Divider /> : null}
              <List>
                <Typography variant="h6">
                  {
                    businesses
                      .filter((b) => b.type !== BUSINESS_TYPES.LOOP)
                      .find((b) => b.id === acc.business_id).name
                  }
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={9}>
                    <ListItemText
                      primary={
                        <Typography>
                          {intl.formatMessage(
                            employeeAccess.confirmDeleteAccess,
                            {
                              date: format(
                                parseBmarkenDate(acc.created_at),
                                "dd/MM/yyyy"
                              ),
                            }
                          )}
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleDelete(acc, "disable")}
                    >
                      {intl.formatMessage(common.disable)}
                    </Button>
                  </Grid>
                </Grid>
              </List>
            </React.Fragment>
          ))}
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setOpen(false);
                reset();
              }}
            >
              {intl.formatMessage(common.close)}
            </Button>
          </DialogActions>
        </DialogContent>
      ) : (
        <DialogContent>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              {intl.formatMessage(common.duration)}
            </FormLabel>
            <RadioGroup row value={value} onChange={handleChange}>
              <FormControlLabel
                value="lifespan"
                control={<Radio />}
                label={intl.formatMessage(employeeAccess.monthlyPeriod)}
              />
              <FormControlLabel
                value="date"
                control={<Radio />}
                label={intl.formatMessage(common.expirationDate)}
              />
            </RadioGroup>
          </FormControl>
          {value === "lifespan" ? (
            <NumberInput
              name="access_lifespan"
              label={intl.formatMessage(employeeAccess.durationMonths)}
              value={lifeSpan}
              onChange={(e) => {
                setLifeSpan(e.target.value);
              }}
              fullWidth
              margin="dense"
              required
              max={getMaxMonth()}
            />
          ) : (
            <DatePicker
              label={intl.formatMessage(common.expirationDate)}
              value={date}
              onChange={(e) => setDate(e)}
              fullWidth
              clearable
              autoOk
              required
              margin="dense"
              maxDate={endOfYear(addYears(new Date(), 5))}
            />
          )}
          <Typography gutterBottom style={{ marginTop: 20 }}>
            {intl.formatMessage(employeeAccess.confirmRequest)}
          </Typography>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => {
                setOpen(false);
                reset();
                setLifeSpan(12);
                setDate(addYears(new Date(), 1));
                setValue("lifespan");
              }}
            >
              {intl.formatMessage(common.close)}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                requestAuthorization(value === "lifespan" ? lifeSpan : date)
              }
            >
              {intl.formatMessage(common.send)}
            </Button>
          </DialogActions>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default AccessDialog;
