import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { account, common, confirm, errors } from "../messages";
import { useBmapi } from "../utils/bmapi-context";
import { getErrorMessageString } from "../utils/errors";
import DeleteIcon from "@material-ui/icons/Delete";
import CreateIcon from "@material-ui/icons/Create";
import Confirm from "../ui/Confirm";
//import { langs } from "../utils/dictionaries";
import FieldTranslation from "./FieldTranslation";
import { getEmptyMap, getMapFields } from "../utils/utils";
import { wikiMuseo } from "../messages/wikiMuseo";
import CheckTranslations from "../ui/CheckTranslations";

const ChallengeCreate = (props) => {
  const initialAnswerValues = () => ({
    text: "",
    correct: false,
  });
  const { bmapi, notifySuccess, notifyError } = useBmapi();
  const intl = useIntl();
  const [addQuestion, setAddQuestion] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [questionIdToDelete, setQuestionIdToDelete] = React.useState(null);
  const [text, setText] = useState("");
  const [idDefaultLng, setIdDefaultLng] = useState("");
  const [id, setId] = useState("");

  /*const [valuesLng, setValuesLng] = useState({
    text: [],
    answers: [],
  });*/

  const [valuesLng, setValuesLng] = useState({
    text: new Map(),
    answers: new Map(),
  });

  const [answers, setAnswers] = useState([
    initialAnswerValues(),
    initialAnswerValues(),
  ]);

  const resetFormat = useCallback(() => {
    //id: getEmptyMap(availableLang),
    const newValuesLng = {
      id: getEmptyMap(props.availableLanguages),
      text: getEmptyMap(props.availableLanguages),
      answers: [
        getEmptyMap(props.availableLanguages),
        getEmptyMap(props.availableLanguages),
      ],
      /*id: getEmptyArray(props.availableLanguages.length),
      text: getEmptyArray(props.availableLanguages.length),
      answers: [
        getEmptyArray(props.availableLanguages.length),
        getEmptyArray(props.availableLanguages.length),
      ],*/
    };
    setText("");
    setAnswers([initialAnswerValues(), initialAnswerValues()]);
    setValuesLng(newValuesLng);
  }, [props]);

  useEffect(() => {
    resetFormat();
  }, [resetFormat, bmapi, props]);

  /*const handleValuesLng = useCallback(
    (varName, i) => (index) => (e) => {
      const value = [...valuesLng[varName]];
      if (varName === "answers") {
        value[i][index] = e.target.value;
        const newValuesLng = { ...valuesLng, [varName]: value };
        setValuesLng(newValuesLng);
      } else {
        value[index] = e.target.value;
        const newValuesLng = { ...valuesLng, [varName]: value };
        setValuesLng(newValuesLng);
      }
    },
    [valuesLng]
  );*/

  const handleValuesLng = useCallback(
    (varName, i) => (lang) => (e) => {
      let value;
      if (varName === "answers") {
        value = valuesLng[varName];
        value[i].set(lang, e.target.value);
      } else {
        value = valuesLng[varName];
        value.set(lang, e.target.value);
      }
      const newValuesLng = { ...valuesLng, [varName]: value };
      setValuesLng(newValuesLng);
    },
    [valuesLng]
  );

  const handleQuestionValue = (e) => {
    setText(e.target.value);
  };

  const handleAddQuestion = () => {
    setId("");
    setAddQuestion(true);
    resetFormat();
  };

  const addAnswer = () => {
    setAnswers((v) => [...v, initialAnswerValues()]);
    const newAnswers = [
      ...valuesLng.answers,
      getEmptyMap(props.availableLanguages), // getEmptyArray(props.availableLanguages.length)
    ];
    const newValuesLng = { ...valuesLng, answers: newAnswers };
    setValuesLng(newValuesLng);
  };

  const delAnswer = (index) => {
    if (index >= 0 && index < answers.length) {
      const newAnswers = [...answers];
      newAnswers.splice(index, 1);
      const newAnswersLng = [...valuesLng.answers];
      newAnswersLng.splice(index, 1);
      const newValuesLng = { ...valuesLng, answers: newAnswersLng };
      setValuesLng(newValuesLng);
      setAnswers(newAnswers);
    }
  };

  const updateAnswer = (index) => (e) => {
    e.persist();
    setAnswers((v) =>
      v.map((a, i) => (i === index ? { ...a, text: e.target.value } : a))
    );
  };

  const selectRightAnswer = (_, index) => {
    setAnswers((v) =>
      v.map((a, i) => ({ ...a, correct: parseInt(index) === i }))
    );
  };

  const questionLabel = (quest) => {
    const defaultLangLabel = quest.localized_questions.filter(
      (q) => q.lang === bmapi.getUserInfo().business.default_language
    );
    return defaultLangLabel[0].text;
  };

  const checkTranslation = (quest) => {
    let check = [];
    const questions = quest.localized_questions
      .filter((q) => q.lang !== bmapi.getUserInfo().business.default_language)
      .map((q) => q.text !== "");
    for (let i = 0; i < props.availableLanguages.length; i++) {
      while (questions.length < props.availableLanguages.length) {
        questions.push(false);
      }
    }
    check.push(questions);
    const answers = quest.localized_questions
      .filter((q) => q.lang !== bmapi.getUserInfo().business.default_language)
      .map((q) => q.answers.map((q) => q.text))
      .flat()
      .map((q) => q !== "");
    for (let i = 0; i < props.availableLanguages.length; i++) {
      while (answers.length < props.availableLanguages.length) {
        answers.push(false);
      }
    }
    check.push(answers);
    return check.flat();
  };

  props.questions?.sort((q1, q2) => q1.priority - q2.priority);

  const getArrAnswers = (i) => {
    const arr = [];
    for (let y = 0; y < valuesLng.answers.length; y++) {
      arr.push({
        text: valuesLng.answers[y].get(props.availableLanguages[i]),
        correct: answers[y].correct,
        id: "id" + y,
      });
    }
    return arr;
  };

  const createLocalizedObj = () => {
    const localizedObj = [];
    for (let i = 0; i < props.availableLanguages.length; ++i) {
      //const textLng = valuesLng.text[i];
      localizedObj.push({
        id: id,
        question_id: valuesLng.id.get(props.availableLanguages[i]) || "",
        text: valuesLng.text.get(props.availableLanguages[i]) || "",
        lang: props.availableLanguages[i],
        answers: getArrAnswers(i),
        /*question_id: valuesLng.id[i],
        text: textLng,
        lang: props.availableLanguages[i],
        answers: valuesLng.answers.map((answer, j) => {
          return { text: answer[i], correct: answers[j].correct, id: "id" + j };
        }),*/
      });
    }
    localizedObj.push({
      id: id,
      question_id: idDefaultLng,
      text: text,
      lang: props.defaultLanguage,
      answers: answers.map((answer, j) => {
        return { text: answer.text, correct: answer.correct, id: "id" + j };
      }),
    });
    return localizedObj;
  };

  /*const currentLang = langs.find(
    (l) => l.value === bmapi.getUserInfo().business.default_language
  );*/

  const confirmQuestion = () => {
    const localizedQuestions = createLocalizedObj();
    let question = {
      id: id,
      localized_questions: localizedQuestions,
      poi_id: props.selectedPoi.id,
      tour_id: props.tourId,
    };
    if (!question.id) {
      bmapi
        .createQuestion(question)
        .then(() => {
          notifySuccess(intl.formatMessage(account.saveConfirm));
          props.closeDialogueQuestion();
          setAddQuestion(false);
          setAnswers([initialAnswerValues(), initialAnswerValues()]);
          setText("");
          props.loadQuestionsList(props.tourId);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    } else {
      bmapi
        .updateQuestion(question)
        .then(() => {
          notifySuccess(intl.formatMessage(account.saveConfirm));
          setAddQuestion(false);
        })
        .then(() => {
          props.loadQuestionsList(props.tourId);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
  };

  function getMapFieldsForAnswers(arrobjects, field, lang) {
    const arr = [];
    for (let j = 0; j < arrobjects[0][field].length; j++) {
      const map = new Map();
      for (let i = 0; i < arrobjects.length; i++) {
        map.set(arrobjects[i][lang], arrobjects[i][field][j].text);
      }
      arr.push(map);
    }
    return arr;
  }

  const loadQuestion = useCallback(
    (questionId) => {
      setId(questionId);
      resetFormat();
      bmapi
        .getQuestions({
          question: questionId,
          business: bmapi.getUserInfo().business.id,
        })
        .then((resp) => {
          if (!resp) {
            notifyError(intl.formatMessage(errors.genericError));
            setAddQuestion(false);
          } else {
            setAddQuestion(true);
            let question = {
              ...resp,
            };
            const defaultLang = bmapi.getUserInfo().business.default_language;
            const newLocalizedQuest = resp[0].localized_questions.filter(
              (question) => question.lang !== defaultLang
            );
            /*newLocalizedQuest.sort((q1, q2) =>
              q1.lang > q2.lang ? 1 : q1.lang > q2.lang ? -1 : 0
            );
            const newLocalizedAnswers = [];
            if (newLocalizedQuest.length) {
              const answersCount = newLocalizedQuest[0].answers?.length;
              for (let i = 0; i < answersCount; i++) {
                const answersLng = [];
                for (let j = 0; j < newLocalizedQuest.length; j++) {
                  answersLng.push(newLocalizedQuest[j].answers[i].text);
                }
                newLocalizedAnswers.push(answersLng);
              }
            }
            const newValues = {
              id: getFields(newLocalizedQuest, "id"),
              text: getFields(newLocalizedQuest, "text"),
              answers: newLocalizedAnswers,
            };*/
            const newValues = {
              id: getMapFields(newLocalizedQuest, "id", "lang"),
              text: getMapFields(newLocalizedQuest, "text", "lang"),
              //answers: newLocalizedAnswers,
              answers: getMapFieldsForAnswers(
                newLocalizedQuest,
                "answers",
                "lang"
              ),
            };
            setValuesLng(newValues);
            const newDefaultQuest = resp[0].localized_questions.filter(
              (localizedQuest) => localizedQuest.lang === defaultLang
            );
            if (newDefaultQuest.length === 1) {
              question = {
                ...question,
                idDefaultLng: newDefaultQuest[0].id,
                text: newDefaultQuest[0].text,
                answers: newDefaultQuest[0].answers,
              };
            }
            setIdDefaultLng(question.idDefaultLng);
            setAnswers(question.answers);
            setText(question.text);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
          setAddQuestion(false);
        });
    },
    [bmapi, intl, notifyError, resetFormat]
  );

  const handleDeleteQuestion = (questionId) => {
    if (questionId !== null) {
      bmapi
        .deleteQuestion(questionId)
        .then(() => {
          setShowDeleteAlert(false);
          notifySuccess(intl.formatMessage(common.deleteElement));
          props.loadQuestionsList(props.tourId);
        })
        .catch((e) => {
          //loading false
          notifyError(getErrorMessageString(e, intl));
        });
    }
  };

  return (
    <Dialog open={props.openDialogQuestion} maxWidth="sm" fullWidth>
      <DialogContent>
        {!addQuestion && (
          <>
            <Box>
              <Typography variant="h6">
                {intl.formatMessage(wikiMuseo.questions)}
              </Typography>
            </Box>
            <List>
              {props.questions &&
                props.questions.map((quest, i) => {
                  return (
                    <React.Fragment key={quest.id}>
                      {i !== 0 && <Divider key={i} component="li" />}
                      <ListItem key={quest.id}>
                        <ListItemText
                          primary={
                            <>
                              <Typography
                                component={"span"}
                                display="block"
                                gutterBottom
                              >
                                {questionLabel(quest)}
                              </Typography>
                            </>
                          }
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            onClick={() => {
                              loadQuestion(quest.id);
                            }}
                          >
                            <CreateIcon />
                            <CheckTranslations
                              translations={checkTranslation(quest)}
                            />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setShowDeleteAlert(true);
                              setQuestionIdToDelete(quest.id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                          <Confirm
                            open={showDeleteAlert}
                            onConfirm={() =>
                              handleDeleteQuestion(questionIdToDelete)
                            }
                            onCancel={() => {
                              setQuestionIdToDelete(null);
                              setShowDeleteAlert(false);
                            }}
                            text={intl.formatMessage(confirm.deleteElement)}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    </React.Fragment>
                  );
                })}
            </List>
            <Box my={2}>
              <Button
                onClick={handleAddQuestion}
                variant="contained"
                color="primary"
              >
                {intl.formatMessage(wikiMuseo.questionAdd)}
              </Button>
              <Button
                onClick={props.closeDialogueQuestion}
                variant="contained"
                style={{ marginLeft: 10 }}
              >
                {intl.formatMessage(common.close)}
              </Button>
            </Box>
          </>
        )}

        {addQuestion && (
          <>
            <Box mb={2}>
              <Typography variant="h6" margin="normal">
                {intl.formatMessage(
                  id ? wikiMuseo.questionModify : wikiMuseo.questionNew
                )}
              </Typography>

              <Box mt={2}>
                <InputLabel>
                  {intl.formatMessage(wikiMuseo.question)}
                </InputLabel>
                <Input
                  fullWidth
                  required
                  //margin="normal"
                  type="text"
                  value={text}
                  name="text"
                  onChange={handleQuestionValue}
                  endAdornment={
                    <InputAdornment position="end">
                      <FieldTranslation
                        language={props.defaultLanguage}
                        items={valuesLng.text}
                        availableLanguages={props.availableLanguages}
                        onChange={handleValuesLng("text")}
                        title={"text"}
                      />
                    </InputAdornment>
                  }
                />
              </Box>

              {/* OLD
              {!!answers.length && (
              <FormControl required component="fieldset" margin="normal">
                    <FormLabel component="legend">
                      {intl.formatMessage(wikiMuseo.answers)}
                    </FormLabel>
                    <RadioGroup value={1} onChange={selectRightAnswer}>
                      {answers.map((q, i) => (
                        <Box display="flex" key={i}>
                          <FormControlLabel
                            value={i}
                            control={
                              <Radio
                                required
                                checked={q.correct}
                                color="default"
                              />
                            }
                            label={
                              <Input
                                type="text"
                                value={q.text}
                                name="q.text"
                                onChange={updateAnswer(i)}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <FieldTranslation
                                      language={props.defaultLanguage}
                                      items={valuesLng.answers[i]}
                                      availableLanguages={
                                        props.availableLanguages
                                      }
                                      onChange={handleValuesLng("answers", i)}
                                      title={"answers"}
                                    />
                                  </InputAdornment>
                                }
                              />
                            }
                          />
                          <IconButton
                            onClick={() => delAnswer(i)}
                            tabIndex="-1"
                          >
                            <Delete />
                          </IconButton>
                        </Box>
                      ))}
                    </RadioGroup>
                    <FormHelperText>
                      {intl.formatMessage(wikiMuseo.answerCorrect)}
                    </FormHelperText>
                  </FormControl> 
                  )}
                  */}

              {!!answers.length && (
                <Box mt={2}>
                  <FormLabel component="legend">
                    {intl.formatMessage(wikiMuseo.answers)}
                  </FormLabel>
                  <RadioGroup
                    required
                    component="fieldset"
                    margin="normal"
                    name="answers"
                    value={1}
                    onChange={selectRightAnswer}
                  >
                    {answers.map((q, i) => (
                      <div display="flex" key={i}>
                        <FormControlLabel
                          style={{ marginRight: 0 }}
                          value={i}
                          control={
                            <Radio
                              required
                              checked={q.correct}
                              color="default"
                            />
                          }
                        />
                        <Input
                          type="text"
                          value={q.text}
                          name="q.text"
                          onChange={updateAnswer(i)}
                          endAdornment={
                            <InputAdornment position="end">
                              <FieldTranslation
                                language={props.defaultLanguage}
                                items={valuesLng.answers[i]}
                                availableLanguages={props.availableLanguages}
                                onChange={handleValuesLng("answers", i)}
                                title={"answers"}
                              />
                            </InputAdornment>
                          }
                        />
                        <IconButton onClick={() => delAnswer(i)} tabIndex="-1">
                          <Delete />
                        </IconButton>
                      </div>
                    ))}
                  </RadioGroup>
                  <FormHelperText>
                    {intl.formatMessage(wikiMuseo.answerCorrect)}
                  </FormHelperText>
                </Box>
              )}
            </Box>

            <Box mt={2}>
              <Button onClick={addAnswer} variant="contained" disableElevation>
                {intl.formatMessage(wikiMuseo.answerAdd)}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ marginLeft: 10 }}
                onClick={confirmQuestion}
              >
                {intl.formatMessage(wikiMuseo.questionSave)}
              </Button>
              <Button
                onClick={() => {
                  setAddQuestion(false);
                  setText("");
                }}
                variant="contained"
                style={{ marginLeft: 10 }}
              >
                {intl.formatMessage(common.cancel)}
              </Button>
            </Box>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ChallengeCreate;
