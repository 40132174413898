import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Confirm from "./Confirm";
import { useBmapi } from "../utils/bmapi-context";
import { getErrorMessageString } from "../utils/errors";

export default function CampaignUnjoin({
  campaign,
  open,
  onSuccess,
  onCancel,
}) {
  const {
    bmapi,
    startLoading,
    stopLoading,
    notifyError,
    notifySuccess,
  } = useBmapi();
  const intl = useIntl();

  const handleUnjoin = () => {
    startLoading();

    return bmapi
      .unJoinCampaign(campaign.id)
      .then(() => {
        onSuccess();
        notifySuccess(
          intl.formatMessage(
            {
              id: "component.campaignUnjoin.successMessage",
              defaultMessage:
                "Hai sospeso con successo l'adesione alla campagna {name}",
            },
            {
              name: campaign.name,
            }
          )
        );
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    onCancel();
  };

  return (
    <Confirm
      open={open}
      onConfirm={handleUnjoin}
      onCancel={handleCancel}
      title={campaign.name}
      text={
        <FormattedMessage
          id="component.campaignUnjoin.confirm"
          defaultMessage="Sei sicuro di voler sospendere l'adesione alla campagna?"
        />
      }
    />
  );
}
