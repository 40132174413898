import React from "react";
import Badge from "@material-ui/core/Badge";
import { withStyles } from "@material-ui/core/styles";

export default function CheckTranslations({
  icon,
  secondIcon,
  translations,
  type,
}) {
  const StyledBadge = withStyles(() => ({
    badge: {
      right: type === "questions" ? -15 : -5,
      top: type === "questions" ? -15 : 0,
      backgroundColor: getColor,
      height: 15,
      width: 15,
      borderRadius: 50,
    },
  }))(Badge);

  const translatedItems = (val) => val !== false;

  const getColor = () => {
    const part = (element) => element !== false;
    if (
      translations.some(part) &&
      translations.every(translatedItems) !== true
    ) {
      return "orange";
    } else if (
      translations.every(translatedItems) === true &&
      translations.length > 0
    ) {
      return "green";
    } else if (translations.every(translatedItems) === false) {
      return "red";
    }
  };

  return (
    <StyledBadge badgeContent=" " variant="dot">
      {icon}
      {secondIcon}
    </StyledBadge>
  );
}
