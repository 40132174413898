import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router";
import { useIntl } from "react-intl";
import { fromString } from "css-color-converter";
import slugify from "slugify";

import {
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";

//import { v4 as uuidv4 } from "uuid";

import { tiers } from "../../components/Pricing";
import { common, form, subscriptions } from "../../messages";
import FormSection from "../../ui/forms/input/FormSection";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { useForm } from "../../utils/form";
import { CONSUMER_ROUTES, PLANS } from "../../utils/constants";
import { AddCircle, CheckCircle, Error } from "@material-ui/icons";
import NumberInput from "../../ui/NumberInput";
import {
  checkFiscalCodeIT,
  checkVatNumberIT,
  getSubscribeStep2Url,
  isAlphanumeric,
  mergeSubscrPlans,
  validateEmail,
} from "../../utils/utils";
import AddressAutocomplete2 from "../../components/AddressAutocomplete2";
/*
function getFormData(object) {
  const formData = new FormData();
  Object.keys(object).forEach((key) => {
    if (typeof object[key] !== "object") formData.append(key, object[key]);
    else formData.append(key, JSON.stringify(object[key]));
  });
  return formData;
}*/

const CURRENCY_SCALE = 100;

function post(path, params, method = "post") {
  // The rest of this code assumes you are not using a library.
  // It can be made less verbose if you use one.
  const form = document.createElement("form");
  form.method = method;
  form.action = path;

  for (const key in params) {
    //if (params.hasOwnProperty(key)) {
    const hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = key;
    hiddenField.value = params[key];

    form.appendChild(hiddenField);
    //}
  }

  document.body.appendChild(form);
  form.submit();
}

function getPrice(price, plan, intl) {
  if (plan === PLANS.TRIAL) {
    return intl.formatNumber(0, {
      style: "currency",
      currency: "EUR",
    });
  }
  if (!price) {
    return "";
  }
  return intl.formatNumber(price, {
    style: "currency",
    currency: "EUR",
  });
}

export default function SubscribeStep1() {
  const intl = useIntl();
  const history = useHistory();
  const {
    bmapi,
    notifyError,
    //notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  //const [plans, setPlans] = useState(false);
  const [discountObj, setDiscountObj] = useState(null);
  const [values, handleChange] = useForm({
    discountCode: "",
    company_name: "",
    company_email: "", // TODO: aggiungere alle api email referente?
    invoice_code: "",
    vat_number: "",
    invoice_address: {},
    fiscal_code: "",
    invoice_email: "",

    friendly_url: {
      default: "",
      format: (v) => slugify(v.replace(" ", "-"), { lower: true }),
    },
    logo_big: "",
    billingPeriod:
      new URLSearchParams(location.search).get("billingPeriod") || 12,
    plan: new URLSearchParams(location.search).get("item_id") || "SMSTD",
    primary_color: fromString(bmapi.themeConf.primary).toHexString(),
    store_quantity: 1,
  });
  const [plans, setPlans] = useState([]);
  const [priceNotDiscounted, setPriceNotDiscounted] = useState(null);
  const [priceDiscounted, setPriceDiscounted] = useState(null);
  const [canCreate, setCanCreate] = useState(true);

  // slug status
  const [slugExists, setSlugExists] = useState(false);
  const [slugValid, setSlugValid] = useState(false);
  const [checkTimeout, setCheckTimeout] = useState(false);
  const slugInput = useRef();

  const vatNumberRef = useRef();
  const fiscalCodeRef = useRef();
  const invoiceCodeRef = useRef();
  const invoiceEmailRef = useRef();

  const checkInteger = (num) => {
    if (!num) return 1;
    if ((num ^ 0) === num) return num; // num is numeric
    return parseInt(num, 10);
  };

  useEffect(() => {
    bmapi
      .getSubscriptionPlans()
      .then((resp) => {
        setPlans(mergeSubscrPlans(tiers, resp));
      })
      .catch((e) => console.log("...subscriptions error", e));
  }, [bmapi]);

  const handleTrial = () => {
    startLoading();

    const newValues = {
      ...values,
      store_quantity: checkInteger(values.store_quantity),
      number_of_months: +values.billingPeriod,
    };
    delete newValues.billingPeriod;
    bmapi
      .subscribe({
        ...newValues,
        logo_big:
          values.logo_big ||
          "https://storage.googleapis.com/bmarken-assets/tenants/demo/logo-consumer.svg",
      })
      .then((resp) => {
        /*
        history.push(CONSUMER_ROUTES.HOME);
        notifySuccess(
          intl.formatMessage({
            id: "subscribe.requestSent",
            defaultMessage: "Richiesta inoltrata con successo",
          })
        );*/
        if (!resp) {
          return;
        }
        console.log("trial", resp);
        history.push(CONSUMER_ROUTES.SUBSCRIBE_PAYMENT_OUTCOME, {
          trialKey: resp.subscription_key,
        });
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  };

  const handlePayment = () => {
    startLoading();

    let msg;
    if (!slugValid) {
      msg = subscriptions.duplicateFriendlyUrlError;
    } else if (!values.company_name) {
      msg = subscriptions.missingCompanyNameError;
    } else if (!values.vat_number && !values.fiscal_code) {
      msg = subscriptions.missingVatNumFiscCodeError;
      vatNumberRef.current.focus();
    } else if (!isVatNumberCorrect(values.vat_number)) {
      msg = form.vatNumberWrongFormat;
      vatNumberRef.current.focus();
    } else if (!isFiscalCodeCorrect(values.fiscal_code)) {
      msg = form.fiscalCodeWrongFormat;
      fiscalCodeRef.current.focus();
    } else if (!values.invoice_code && !values.invoice_email) {
      msg = subscriptions.missingInvoiceCodeEmailError;
      invoiceCodeRef.current.focus();
    } else if (!isInvoiceCodeCorrect(values.invoice_code)) {
      msg = form.invoiceCodeWrongFormat;
      invoiceCodeRef.current.focus();
    } else if (!isEmailCorrect(values.invoice_email)) {
      msg = form.emailWrongFormat;
      invoiceEmailRef.current.focus();
    }
    if (msg) {
      notifyError(intl.formatMessage(msg));
      stopLoading();
      return;
    }

    //const callbackUrl =
    //  getRouteBase(location) + CONSUMER_ROUTES.SUBSCRIBE_PAYMENT_OUTCOME;

    const addr = { ...values.invoice_address };
    if (!addr.state) {
      addr.state = "IT";
    }
    const newValues = {
      company_name: values.company_name,
      subscription_friendly_url: values.friendly_url,
      /*
      subscription_friendly_url: uuidv4()
        .replace(new RegExp("\\-", "g"), "")
        .substring(0, 19),*/
      store_quantity: checkInteger(values.store_quantity),
      number_of_months: checkInteger(values.billingPeriod),
      logo_big: values.logo_big,
      primary_color: values.primary_color,
      item_id: plan.id,
      //success_url: callbackUrl,
      //error_url: callbackUrl,
      discount_id: discountObj ? discountObj.id : "",
      invoice_code: values.invoice_code,
      invoice_address: addr,
      vat_number: values.vat_number,
      fiscal_code: values.fiscal_code,
      invoice_email: values.invoice_email,
    };
    bmapi
      .subscribeStep1({
        ...newValues,
        /*
        logo_big:
          values.logo_big ||
          "https://storage.googleapis.com/bmarken-assets/tenants/demo/logo-consumer.svg",
          */
      })
      .then((resp) => {
        /*
        const nexiUrl = resp.url;
        const nexiBody = resp.body;
        console.log("step1");
        post(nexiUrl, nexiBody, "POST");
        */
        console.log("......", resp, !!post);
        stopLoading();
        if (!bmapi.settings.subscriptionsURL) {
          console.info("subscriptionsURL not set");
          return;
        }
        if (!resp.payment_id) {
          notifyError("registration procedure interrupted");
          return;
        }
        let url = bmapi.settings.subscriptionsURL.replace(
          "{friendlyUrl}",
          resp.friendly_url
        );
        //url = `${url}${MANAGER_ROUTES.SUBSCRIBE_STEP2}?payment=${resp.payment_id}&subscription=${resp.subscription_id}`;
        url = getSubscribeStep2Url({
          baseUrl: url,
          paymentId: resp.payment_id,
          subscriptionId: resp.subscription_id,
        });
        console.info("redirect ##subscriptionreg", url);
        window.location.href = url;
      })
      .catch((e) => {
        stopLoading();
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(stopLoading);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!plan) {
      return;
    }
    if (plan.id === "TRIAL") {
      handleTrial();
    } else {
      handlePayment();
    }
  };

  const interceptSubmit = (e) => {
    if (e.key === "Enter") {
      addDiscountCode(e);
    }
  };

  /*
  const checkSlug = () => {
    setSlugExists(false);
    if (slugInput.current.validity.valid && values.friendly_url) {
      bmapi.getSubscriptionsBySlug(values.friendly_url).then((s) => {
        setSlugValid(!s.length);
        setSlugExists(!!s.length);
      });
    } else {
      setSlugValid(false);
    }
  };*/

  useEffect(() => {
    if (values.plan === PLANS.TRIAL) {
      handleChange("billingPeriod")(3);
      handleChange("store_quantity")(1);
      //handleChange("friendly_url")("");
      setPriceNotDiscounted(0);
      setPriceDiscounted(0);
      return;
    }
    try {
      const storeQty = checkInteger(values.store_quantity);
      const period = checkInteger(values.billingPeriod);

      // call price not discounted
      setCanCreate(false);
      bmapi
        .getSubscriptionPrice(values.plan, period, storeQty, "")
        .then((resp) => {
          const p = Number.parseFloat(resp.price / CURRENCY_SCALE).toFixed(2);
          setPriceNotDiscounted(p);
          if (!discountObj) {
            setPriceDiscounted(p);
          } else {
            // call price discounted
            bmapi
              .getSubscriptionPrice(
                values.plan,
                period,
                storeQty,
                discountObj.id
              )
              .then((resp) => {
                const p = Number.parseFloat(
                  resp.price / CURRENCY_SCALE
                ).toFixed(2);
                setPriceDiscounted(p);
              })
              .catch((e) => {
                console.log("price doscount api error", e);
                notifyError(getErrorMessageString(e, intl));
                setPriceDiscounted(null);
              })
              .finally(() => setCanCreate(true));
          }
        })
        .catch((e) => {
          console.log("price api error", e);
          setPriceNotDiscounted(null);
          setPriceDiscounted(null);
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => setCanCreate(true));
    } catch (e) {
      console.log("price input error", e);
      setPriceNotDiscounted(null);
      setPriceDiscounted(null);
      setCanCreate(true);
      notifyError(getErrorMessageString(e, intl));
    }
  }, [
    handleChange,
    values.plan,
    values.store_quantity,
    values.billingPeriod,
    discountObj,
    bmapi,
    intl,
    notifyError,
    startLoading,
    stopLoading,
  ]);

  const checkSlug = async () => {
    setSlugExists(false);
    if (slugInput.current.validity.valid && values.friendly_url) {
      try {
        await bmapi.subscriptionLookupFast(values.friendly_url);
        setSlugExists(true);
        setSlugValid(false);
      } catch (e) {
        if (e.code === "NOT_FOUND") {
          setSlugExists(false);
          setSlugValid(true);
        } else {
          setSlugExists(false);
          setSlugValid(false);
        }
      }
      /*
      bmapi.subscriptionLookupFast(values.friendly_url).then((s) => {
        setSlugValid(!s.length);
        setSlugExists(!!s.length);
      });
      */
    } else {
      setSlugValid(false);
    }
  };

  useEffect(() => {
    clearTimeout(checkTimeout);
    const to = setTimeout(checkSlug, 500);
    setCheckTimeout(to);
    return () => {
      clearTimeout(to);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.friendly_url]);

  /*
  useEffect(() => {
    if (values.plan !== PLANS.TRIAL) {
      handleChange("friendly_url")(
        slugify(values.company_name, { lower: true })
      );
    }
  }, [handleChange, values.company_name, values.plan]);

  /*
  useEffect(() => {
    clearTimeout(checkTimeout);
    const to = setTimeout(checkSlug, 500);
    setCheckTimeout(to);
    return () => {
      clearTimeout(to);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.friendly_url]);
  */

  /*
  const getDiscount = () => {
    if (discountCode.startsWith("VV") && discountCode.length > 2) return 0.05;
    if (discountCode === "CC1B" && values.plan === "SMBAS") return 0.1;
    if (discountCode === "CC2S" && values.plan === "SMSTD") return 0.15;
    if (discountCode === "CC3A" && values.plan === "SMADV") return 0.2;
    return 0;
  };*/

  /*
  const getDiscountValue = () => {
    return intl.formatNumber(
      getPrice(false) * getDiscount() * (values.store_quantity || 1),
      {
        style: "currency",
        currency: "EUR",
      }
    );
  };*/

  /*
  const getPrice = (format = true) => {
    const tier = tiers.find((t) => t.id === values.plan);
    const price = tier.pricing[values.billingPeriod];
    const discounts = tier.quantityDiscount;
    const discountedPrice = Array(+values.store_quantity || 1)
      .fill(price)
      .map((p, i) => p * (discounts[i] || discounts.slice(-1)[0]))
      .reduce((a, b) => a + b);
    return format
      ? intl.formatNumber(discountedPrice, {
          style: "currency",
          currency: "EUR",
        })
      : discountedPrice;
      
    console.log("getPrice.format", format);
    return "xx";
  };
  
  const getTotalPrice = (format = true) => {
    const total = getPrice(false) * (1 - getDiscount());

    return format
      ? intl.formatNumber(total, {
          style: "currency",
          currency: "EUR",
        })
      : total;
  };*/

  const addDiscountCode = (e) => {
    e.preventDefault();
    setCanCreate(false);
    bmapi
      .getSubscriptionDiscount(values.discountCode)
      .then((resp) => {
        if (!resp) {
          notifyError(intl.formatMessage(form.invalidDiscountCode));
        } else {
          handleChange("discountCode")("");
          setDiscountObj(resp.length ? resp[0] : resp);
        }
      })
      .catch((e) => {
        console.log("discount code error", e);
        notifyError(intl.formatMessage(form.invalidDiscountCode));
      })
      .finally(() => setCanCreate(true));
  };

  const removeDiscountCode = (e) => {
    e.preventDefault();
    setDiscountObj(null);
  };

  const plan = plans.find((t) => t.id === values.plan);
  const fplans = plans ? plans.filter((p) => p.action !== "contact") : null;

  const isEmailCorrect = (email) => !email || validateEmail(email);
  const isInvoiceCodeCorrect = (invoiceCode) =>
    !invoiceCode || (isAlphanumeric(invoiceCode) && invoiceCode.length === 7);
  const isVatNumberCorrect = (vatNumber) =>
    !vatNumber || checkVatNumberIT(vatNumber);
  const isFiscalCodeCorrect = (fiscalCode) =>
    !fiscalCode || checkFiscalCodeIT(fiscalCode);
  //console.log("..........", fplans);
  //console.log("..........", values);

  return (
    <form onSubmit={handleSubmit}>
      <Container maxWidth="sm">
        <Title backUrl={CONSUMER_ROUTES.HOME}>
          {intl.formatMessage(subscriptions.configuration)}
        </Title>
        <Box mb={4}>
          <FormSection title={intl.formatMessage(subscriptions.subscription)}>
            <Box py={2}>
              <Typography variant="subtitle2">
                {intl.formatMessage(subscriptions.webAppConf)}
              </Typography>
            </Box>

            <>
              <TextField
                name="friendly_url"
                label={intl.formatMessage(form.friendly_url)}
                value={values.friendly_url}
                onChange={handleChange("friendly_url")}
                key="friendly_url"
                fullWidth
                margin="normal"
                required={values.plan !== PLANS.TRIAL}
                inputRef={slugInput}
                //disabled={values.plan === PLANS.TRIAL}
                error={!!values.friendly_url && !slugValid}
                helperText={
                  //values.plan === PLANS.TRIAL
                  //  ? intl.formatMessage(form.friendly_url_disabled)
                  //  :
                  slugValid
                    ? bmapi.settings.subscriptionsURL?.replace(
                        "{friendlyUrl}",
                        values.friendly_url
                      )
                    : slugExists
                    ? intl.formatMessage(form.friendly_url_exists)
                    : intl.formatMessage(form.friendly_url_help)
                }
                InputProps={{
                  endAdornment: !!values.friendly_url && (
                    <InputAdornment position="end">
                      {slugValid ? (
                        <CheckCircle style={{ color: "#4caf50" }} />
                      ) : (
                        <Error style={{ color: "#f44336" }} />
                      )}
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  pattern: "^[a-z0-9](-?[a-z0-9]){7,19}$",
                }}
              />

              <Typography variant="caption" display="block" gutterBottom>
                {intl.formatMessage(form.friendly_url_info)}
              </Typography>
            </>

            <TextField
              name="plan"
              label={intl.formatMessage(form.plan)}
              value={fplans && fplans.length ? values.plan : ""}
              onChange={handleChange("plan")}
              key="plan"
              fullWidth
              margin="normal"
              select
              required
              helperText={
                values.plan === PLANS.TRIAL
                  ? intl.formatMessage(form.plan_trial)
                  : intl.formatMessage(form.plan_help)
              }
            >
              {fplans &&
                fplans.map(
                  (tier) =>
                    tier.action !== "contact" && (
                      <MenuItem value={tier.id} key={tier.id}>
                        {tier.name}
                      </MenuItem>
                    )
                )}
            </TextField>
            <TextField
              name="billingPeriod"
              label={intl.formatMessage(form.billingPeriod)}
              value={values.billingPeriod}
              onChange={handleChange("billingPeriod")}
              key="billingPeriod"
              fullWidth
              disabled={values.plan === PLANS.TRIAL}
              margin="normal"
              select
              required
            >
              {[1, 3, 6, 12].map((months) => (
                <MenuItem
                  value={months}
                  key={months}
                  disabled={values.plan === PLANS.TRIAL && months !== 3}
                >
                  {intl.formatMessage(form.monthsNum, { months })}{" "}
                  {values.plan === PLANS.TRIAL && months !== 3
                    ? `[${intl.formatMessage(
                        subscriptions.notAvailableinTrial
                      )}]`
                    : ""}
                </MenuItem>
              ))}
            </TextField>
            <NumberInput
              label={intl.formatMessage(form.storesQuantity)}
              fullWidth
              value={values.store_quantity}
              margin="normal"
              required
              disabled={values.plan === PLANS.TRIAL}
              onChange={handleChange("store_quantity")}
              min={1}
              max={values.plan === PLANS.TRIAL ? 1 : 100}
            />
            {!!plan && (
              <>
                <Box style={{ marginTop: 20 }}></Box>
                <Typography variant="h6" gutterBottom>
                  {intl.formatMessage(form.order_summary)}
                </Typography>
                <TableContainer>
                  <Table size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {intl.formatMessage(form.monthsNum, {
                            months: values.billingPeriod,
                          })}{" "}
                          * {plan.name} *{" "}
                          {intl.formatMessage(form.storesNum, {
                            stores: values.store_quantity || 1,
                          })}
                        </TableCell>
                        <TableCell align="right">
                          {getPrice(priceNotDiscounted, values.plan, intl)}
                        </TableCell>
                      </TableRow>

                      {discountObj ? (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {intl.formatMessage(form.discountCodeApplied, {
                              code: discountObj.item_key,
                            })}{" "}
                            [
                            <Link onClick={removeDiscountCode}>
                              {intl.formatMessage(common.delete)}
                            </Link>
                            ]
                          </TableCell>
                          <TableCell align="right">
                            {discountObj.name}
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell
                            component="th"
                            scope="row"
                            colSpan={2}
                            padding="none"
                          >
                            <TextField
                              name="discountCode"
                              label={intl.formatMessage(form.discountCode)}
                              value={values.discountCode}
                              onChange={handleChange("discountCode")}
                              onKeyPress={interceptSubmit}
                              key="discountCode"
                              fullWidth
                              margin="normal"
                              variant="filled"
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={addDiscountCode}
                                      disabled={!values.discountCode}
                                    >
                                      <AddCircle />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell component="th" scope="row">
                          <strong>{intl.formatMessage(common.total)}</strong>
                        </TableCell>
                        <TableCell align="right">
                          <strong>
                            {getPrice(priceDiscounted, values.plan, intl)}
                          </strong>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          </FormSection>

          <FormSection title={intl.formatMessage(subscriptions.companyInfo)}>
            <Box pb={4}>
              <TextField
                name="company_name"
                label={intl.formatMessage(form.company_name)}
                value={values.company_name}
                onChange={handleChange("company_name")}
                key="company_name"
                fullWidth
                margin="normal"
                required
              />
              {values.plan !== PLANS.TRIAL && (
                <>
                  <TextField
                    name="vat_number"
                    label={intl.formatMessage(form.vatNumber)}
                    value={values.vat_number}
                    onChange={handleChange("vat_number")}
                    key="vat_number"
                    fullWidth
                    margin="normal"
                    type="number"
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/\D/g, "")
                        .substring(0, 11);
                    }}
                    error={!isVatNumberCorrect(values.vat_number)}
                    helperText={
                      !isVatNumberCorrect(values.vat_number) &&
                      intl.formatMessage(form.vatNumberWrongFormat)
                    }
                    inputRef={vatNumberRef}
                  />
                  <TextField
                    name="fiscal_code"
                    label={intl.formatMessage(form.fiscalCode)}
                    value={values.fiscal_code}
                    onChange={handleChange("fiscal_code")}
                    key="fiscal_code"
                    fullWidth
                    margin="normal"
                    inputProps={{ maxLength: 16 }}
                    error={!isFiscalCodeCorrect(values.fiscal_code)}
                    helperText={
                      !isFiscalCodeCorrect(values.fiscal_code) &&
                      intl.formatMessage(form.fiscalCodeWrongFormat)
                    }
                    inputRef={fiscalCodeRef}
                  />
                  <TextField
                    name="invoice_code"
                    label={intl.formatMessage(form.invoiceCode)}
                    value={values.invoice_code}
                    onChange={handleChange("invoice_code")}
                    key="invoice_code"
                    fullWidth
                    margin="normal"
                    inputProps={{ maxLength: 7 }}
                    error={!isInvoiceCodeCorrect(values.invoice_code)}
                    helperText={
                      !isInvoiceCodeCorrect(values.invoice_code) &&
                      intl.formatMessage(form.invoiceCodeWrongFormat)
                    }
                    inputRef={invoiceCodeRef}
                  />
                  <TextField
                    name="invoice_email"
                    label={intl.formatMessage(form.invoiceEmail)}
                    value={values.invoice_email}
                    onChange={handleChange("invoice_email")}
                    key="invoice_email"
                    fullWidth
                    margin="normal"
                    error={!isEmailCorrect(values.invoice_email)}
                    helperText={
                      !isEmailCorrect(values.invoice_email) &&
                      intl.formatMessage(form.emailWrongFormat)
                    }
                    inputRef={invoiceEmailRef}
                  />
                </>
              )}
            </Box>
            {values.plan !== PLANS.TRIAL && (
              <Box pb={4}>
                <Box py={0}>
                  <Typography variant="subtitle2" gutterBottom>
                    {intl.formatMessage(form.invoiceAddress)}
                  </Typography>
                </Box>
                <AddressAutocomplete2
                  types="address"
                  onChange={handleChange("invoice_address")}
                  address={values.invoice_address}
                  required={true}
                  margin="normal"
                  config={{ hasLatLng: false }}
                />
              </Box>
            )}
          </FormSection>
        </Box>
        {!!plan && (
          <FormControl fullWidth margin="normal">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!canCreate}
            >
              {intl.formatMessage(common.send)}
            </Button>
          </FormControl>
        )}
      </Container>
    </form>
  );
}
