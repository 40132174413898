import React, { useState } from "react";
//import { useIntl } from "react-intl";
//import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Container,
  FormControl,
  TextField,
  Typography,
} from "@material-ui/core";
import { useBmapi } from "../../utils/bmapi-context";
import Title from "../../ui/Title";
import ConsentDialog from "../../ui/ConsentDialog";
import InfoIcon from "@material-ui/icons/Info";

export default function UserInspection() {
  //const { campaignId } = useParams();
  //const intl = useIntl();
  const { bmapi, startLoading, stopLoading } = useBmapi();
  const [email, setEmail] = useState("");
  const [userDetails, setUserDetails] = useState({
    id: null,
    complete_name: "-",
    //surname: "-",
    mobile: "000-0000000",
    birthDate: "dd/MM/yyyy",
    language: "-",
  });
  const [userConsents, setUserConsents] = useState([]);
  const [userProducts, setUserProducts] = useState([]);
  const consentModal = React.useRef(null);

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmitWithDialog = (type, id) => {
    if (type == "consents") getConsents(id);
    if (type == "products") loadUserTransactions(userDetails.id, id);
  };

  const handleSubmit = () => {
    startLoading();
    getUser(email);
  };

  const getUser = (data) => {
    return bmapi
      .getUser(data)
      .then((values) => {
        var userData = Object.assign({}, userDetails);
        userData["complete_name"] = values.complete_name;
        userData["surname"] = values.last_name;
        userData["id"] = values.id;
        userData["mobile"] = values.mobile;
        userData["birthDate"] = values.birthday;
        userData["language"] = values.language;
        setUserDetails(userData);
        loadUserConsents(values.id);
        loadUserProducts(values.id);
      })
      .finally(stopLoading)
      .catch(stopLoading);
  };

  const getConsents = (consent_id) => {
    return bmapi
      .getConsents()
      .then((values) => {
        var obj = {
          type: "consents",
          data: [values.find((x) => x.id == consent_id)],
        };
        consentModal.current.value = obj;
        consentModal.current();
      })
      .finally(stopLoading)
      .catch(stopLoading);
  };

  const loadUserConsents = (user) => {
    return bmapi
      .getConsentInfo(user)
      .then((values) => {
        console.log(values);
        var consents = values.consents;
        var consentExpresssions = values.consent_expressions;
        consentExpresssions.forEach(
          (element) =>
            (element["consent"] = consents.find(
              (x) => x.id == element.consent_id
            ))
        );
        setUserConsents(consentExpresssions);
      })
      .finally(stopLoading)
      .catch(stopLoading);
  };

  const loadUserProducts = (user) => {
    var params = { user: user };
    return bmapi
      .getUserInspectionProducts(null, params)
      .then((values) => {
        console.log(values);
        setUserProducts(values);
      })
      .finally(stopLoading)
      .catch(stopLoading);
  };

  const loadUserTransactions = (user, productId) => {
    var query = { userId: user, productId: productId };
    return bmapi
      .getTransactions(query)
      .then((values) => {
        var obj = { type: "transactions", data: values };
        consentModal.current.value = obj;
        consentModal.current();
      })
      .finally(stopLoading)
      .catch(stopLoading);
  };

  return (
    <Container maxWidth="sm">
      <Title>Ispezione Utente</Title>
      <Box mt={3}>
        <Card>
          <CardContent>
            <div style={{ display: "flex" }}>
              <div style={{ marginRight: "auto" }}>
                <FormControl required fullWidth>
                  <TextField
                    id="outlined-name"
                    label="Indirizzo email"
                    value={email}
                    onChange={handleChange}
                    style={{ width: "390px" }}
                  />
                </FormControl>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Cerca
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      </Box>
      {userDetails.complete_name != "-" ? (
        <Box mt={3}>
          <Card>
            <CardHeader title={"Dati utente"}></CardHeader>
            <CardContent>
              <div style={{ display: "flex" }}>
                <Typography variant="h6">Email:</Typography>
                <Typography style={{ margin: "3px" }}>{email}</Typography>
              </div>
              <div style={{ display: "flex" }}>
                <Typography variant="h6">Nome:</Typography>
                <Typography style={{ margin: "3px" }}>
                  {userDetails.complete_name}
                </Typography>
              </div>
              {
                <div style={{ display: "flex" }}>
                  <Typography variant="h6">Cognome:</Typography>
                  <Typography style={{ margin: "3px" }}>
                    {userDetails.surname}
                  </Typography>
                </div>
              }
              <div style={{ display: "flex" }}>
                <Typography variant="h6">Mobile:</Typography>
                <Typography style={{ margin: "3px" }}>
                  {userDetails.mobile}
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <Typography variant="h6">Data di nascita:</Typography>
                <Typography style={{ margin: "3px" }}>
                  {userDetails.birthDate}
                </Typography>
              </div>
              <div style={{ display: "flex" }}>
                <Typography variant="h6">Lingua:</Typography>
                <Typography style={{ margin: "3px" }}>
                  {userDetails.language}
                </Typography>
              </div>
            </CardContent>
          </Card>
        </Box>
      ) : null}
      {userConsents.length != 0 ? (
        <Box mt={3}>
          <Card>
            <CardHeader title={"Consensi"}></CardHeader>
            <CardContent>
              <div style={{ width: "100%" }}>
                <table style={{ width: "inherit" }}>
                  <thead>
                    <tr>
                      <th>
                        <Typography variant="h6">Tipo</Typography>
                      </th>
                      <th>
                        <Typography variant="h6">Data</Typography>
                      </th>
                      <th>
                        <Typography variant="h6">Valore</Typography>
                      </th>
                      <th>
                        <Typography variant="h6">Stato</Typography>
                      </th>
                      <th>
                        <Typography variant="h6">Campagna</Typography>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {userConsents.map((k, i) => {
                      return (
                        <tr key={i}>
                          <td>{k.consent.type}</td>
                          <td>{k.signed_on.substring(0, 10)}</td>
                          <td>{k.value}</td>
                          <td>{k.status}</td>
                          <td>{k.campaign_id}</td>
                          <td>
                            <InfoIcon
                              color="primary"
                              onClick={() => {
                                handleSubmitWithDialog(
                                  "consents",
                                  k.consent_id
                                );
                              }}
                            ></InfoIcon>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </CardContent>
          </Card>
        </Box>
      ) : null}
      {userProducts.length != 0 ? (
        <Box mt={3}>
          <Card>
            <CardHeader title={"Prodotti"}></CardHeader>
            <CardContent>
              <div style={{ width: "100%" }}>
                {userProducts.map((k, i) => {
                  return (
                    <table
                      key={i}
                      style={{ margin: "10px", borderTop: "1px solid grey" }}
                    >
                      <tbody>
                        <tr>
                          <td>
                            <Typography
                              variant="h6"
                              style={{ fontSize: "18px" }}
                            >
                              Id:
                            </Typography>
                            <Typography style={{ fontSize: "15px" }}>
                              {k.id}
                            </Typography>
                          </td>
                          <td>
                            <div style={{ display: "flex" }}>
                              <Typography
                                variant="h6"
                                style={{ fontSize: "18px" }}
                              >
                                Mostra transazioni:
                              </Typography>
                              <Typography style={{ fontSize: "18px" }}>
                                <InfoIcon
                                  color="primary"
                                  onClick={() => {
                                    handleSubmitWithDialog("products", k.id);
                                  }}
                                ></InfoIcon>
                              </Typography>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              variant="h6"
                              style={{ fontSize: "18px" }}
                            >
                              Campagna:
                            </Typography>
                            <Typography style={{ fontSize: "18px" }}>
                              {k.campaign_name}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              variant="h6"
                              style={{ fontSize: "18px" }}
                            >
                              Business:
                            </Typography>
                            <Typography style={{ fontSize: "18px" }}>
                              {k.business_name}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              variant="h6"
                              style={{ fontSize: "18px" }}
                            >
                              Tipo:
                            </Typography>
                            <Typography style={{ fontSize: "18px" }}>
                              {k.type}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              variant="h6"
                              style={{ fontSize: "18px" }}
                            >
                              Sottotipo:
                            </Typography>
                            <Typography style={{ fontSize: "18px" }}>
                              {k.subtype}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              variant="h6"
                              style={{ fontSize: "18px" }}
                            >
                              Creato:
                            </Typography>
                            <Typography style={{ fontSize: "18px" }}>
                              {k.created_at.substring(0, 10)}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              variant="h6"
                              style={{ fontSize: "18px" }}
                            >
                              Scadenza:
                            </Typography>
                            <Typography style={{ fontSize: "18px" }}>
                              {k.expiration_date.substring(0, 10)}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              variant="h6"
                              style={{ fontSize: "18px" }}
                            >
                              Stato:
                            </Typography>
                            <Typography style={{ fontSize: "18px" }}>
                              {k.status}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              variant="h6"
                              style={{ fontSize: "18px" }}
                            >
                              Utilizzi disponibili:
                            </Typography>
                            <Typography style={{ fontSize: "18px" }}>
                              {k.available_shots}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              variant="h6"
                              style={{ fontSize: "18px" }}
                            >
                              Valore:
                            </Typography>
                            <Typography style={{ fontSize: "18px" }}>
                              {k.value}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              variant="h6"
                              style={{ fontSize: "18px" }}
                            >
                              Valore residuo:
                            </Typography>
                            <Typography style={{ fontSize: "18px" }}>
                              {k.available_value}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              variant="h6"
                              style={{ fontSize: "18px" }}
                            >
                              Giorno giocata:
                            </Typography>
                            <Typography style={{ fontSize: "18px" }}>
                              {k.play_date}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              variant="h6"
                              style={{ fontSize: "18px" }}
                            >
                              Esito giocata:
                            </Typography>
                            <Typography style={{ fontSize: "18px" }}>
                              {k.play_result}
                            </Typography>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              variant="h6"
                              style={{ fontSize: "18px" }}
                            >
                              Premio giocata:
                            </Typography>
                            <Typography style={{ fontSize: "18px" }}>
                              {k.play_prize}
                            </Typography>
                          </td>
                          <td>
                            <Typography
                              variant="h6"
                              style={{ fontSize: "18px" }}
                            >
                              Lotto:
                            </Typography>
                            <Typography style={{ fontSize: "18px" }}>
                              {k.batch_id}
                            </Typography>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  );
                })}
              </div>
            </CardContent>
          </Card>
        </Box>
      ) : null}
      <ConsentDialog
        consentModal={consentModal}
        handleSubmitWithDialog={() => {
          handleSubmitWithDialog();
        }}
      />
    </Container>
  );
}
