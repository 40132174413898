import React, { useState, useCallback, useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";
import QRCodeGenerator from "qrcode";
import { endOfDay } from "date-fns";

import {
  AccountCircle as AccountCircleIcon,
  Add as AddIcon,
  AddCircle as AddCircleIcon,
  FilterCenterFocus as FilterCenterFocusIcon,
  PlaylistAdd as PlaylistAddIcon,
  PlaylistAddCheck as PlaylistAddCheckIcon,
  Remove as RemoveIcon,
} from "@material-ui/icons";
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@material-ui/core";

import {
  actions as actionsMsg,
  campaign as campaignMsg,
  product as productMsg,
} from "../messages";
import Confirm from "../ui/Confirm";
import ProductUI from "../ui/Product";
import { useBmapi } from "../utils/bmapi-context";
import { getLabel, IconsMap } from "../utils/campaigns";
import {
  CAMPAIGN_ACTIONS as STATUS,
  CAMPAIGN_STATUS,
  CODE_FORMATS,
  CONSUMER_ROUTES,
  FEATURES,
  PRODUCTS,
  PRODUCT_SUBTYPES,
  PRODUCT_TYPES,
  TENANTS,
} from "../utils/constants";
import { getErrorMessageString } from "../utils/errors";
import { Alert } from "@material-ui/lab";

const updateIntervalTime = 5000;

const btnText = (type, status) =>
  ({
    [STATUS.ANONYMOUS]: actionsMsg.loginOrRegister,
    [STATUS.NO_PERMISSION]: actionsMsg.loginOrRegister,
    [STATUS.DISABLED_ACCOUNT]: actionsMsg.verifyAccount,
    [STATUS.CAMPAIGN_NOT_STARTED]: productMsg[type].notYetUsable,
    [STATUS.CAMPAIGN_ENDED]: campaignMsg.campaignEnded,
    [STATUS.NO_PRODUCTS_AVAILABLE]: campaignMsg.noProductsAvailables,
    [STATUS.ALL_PRODUCTS_USED]: productMsg[type].allUsed,
    [STATUS.NOT_AVAILABLE]: productMsg[type].notAvailable,
    [STATUS.BOOKED]: actionsMsg.joinWaitingList,
    [STATUS.REEDEMABLE]: productMsg[type].get,
    [STATUS.ENABLED]: productMsg[type].use,
    [STATUS.BOOKABLE]: actionsMsg.joinWaitingList,
    [STATUS.EXTERNAL]: actionsMsg.showCode,
  }[status]);

const messageText = (status) =>
  ({
    [STATUS.ANONYMOUS]: actionsMsg.loginOrRegisterAfterQrCode,
    [STATUS.NO_PERMISSION]: actionsMsg.loginOrRegisterAfterQrCode,
    [STATUS.REEDEMABLE]: actionsMsg.useAfterQrCode,
  }[status]);

function getSignificantDateStatus(campaign, availability) {
  if (campaign.status !== 0) return campaignMsg.campaignEnded;
  if (new Date(campaign.start_date) > new Date()) {
    if (campaign.type === PRODUCT_TYPES.CAMPAIGN_EVENT_PASS) {
      return campaign.start_hour
        ? campaignMsg.eventStartTime
        : campaignMsg.eventStart;
    }
    return productMsg[getLabel(campaign.rules.subtype)].dateInfoIncoming;
  }
  if (availability === 0) return campaignMsg.willEnd;
  return campaignMsg.nextExpiration;
}

function isExpired(expiration) {
  return (
    expiration && new Date(expiration) - new Date() - updateIntervalTime <= 0
  );
}

function canPlay(products, availability, campaign) {
  return (
    products
      ?.filter((p) => p.campaign_id === campaign.id)
      .filter((p) => p.status === 0).length > 0 &&
    availability > 0 &&
    campaign.rules.subtype === PRODUCT_SUBTYPES.EARNING_CARD_INSTANT_WIN
  );
}

export function ProductSelector({ campaign, children, onSelect, products }) {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);
  const CampaignIcon = IconsMap[campaign.rules.subtype];

  const handleClick = (event) => {
    if (products.length > 1) {
      setAnchorEl(event.currentTarget);
    } else {
      handleChoice(0);
    }
  };

  const handleChoice = (cluster) => {
    setAnchorEl(false);
    return onSelect(cluster);
  };

  return (
    <React.Fragment>
      {products.length > 1 && (
        <React.Fragment>
          <Backdrop
            open={Boolean(anchorEl)}
            onClick={() => setAnchorEl(null)}
            style={{ zIndex: 1290 }}
          />

          <Menu
            anchorEl={anchorEl}
            keepMounted={false}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {products.map((ps, i) => (
              <MenuItem onClick={() => handleChoice(i)} key={i}>
                <ListItemIcon>
                  <CampaignIcon />
                </ListItemIcon>
                <ListItemText
                  primary={intl.formatMessage(
                    {
                      id: "component.product.remainingValue",
                      defaultMessage:
                        "Valore residuo: {val, number} {currency}",
                    },
                    {
                      currency: ps[0].currency,
                      val: ps[0].available_value / 100,
                    }
                  )}
                  secondary={intl.formatMessage(
                    {
                      id: "component.campaigns.expiration",
                      defaultMessage:
                        "Scadenza: {expirationDate, date, medium}",
                    },
                    { expirationDate: new Date(ps[0].expiration_date) }
                  )}
                />
              </MenuItem>
            ))}
          </Menu>
        </React.Fragment>
      )}
      {children(handleClick)}
    </React.Fragment>
  );
}

export function UseProduct({ campaign, campaignStatus, products, onUpdate }) {
  const {
    bmapi,
    startLoading,
    stopLoading,
    notifyError,
    notifySuccess,
  } = useBmapi();
  const history = useHistory();
  const intl = useIntl();
  const theme = useTheme();
  const { campaignId } = useParams();
  const [image, setImage] = useState(false);
  const [code, setCode] = useState(false);
  const [permission, setPermission] = useState(false);
  const [productIds, setProductIds] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [cluster, setCluster] = useState(false);
  const [updateInterval, setUpdateInterval] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [urlParams, setUrlParams] = useState(false);

  const btnIcon = {
    [STATUS.ANONYMOUS]: <AccountCircleIcon />,
    [STATUS.NO_PERMISSION]: <AccountCircleIcon />,
    [STATUS.REEDEMABLE]: <AddCircleIcon />,
    [STATUS.ENABLED]: <FilterCenterFocusIcon />,
    [STATUS.BOOKABLE]: <PlaylistAddIcon />,
    [STATUS.BOOKED]: <PlaylistAddCheckIcon />,
    [STATUS.EXTERNAL]: <FilterCenterFocusIcon />,
  };

  const joinWaitingList = useCallback(() => {
    startLoading();

    return bmapi
      .reserveCampaign(campaign.id)
      .then(onUpdate)
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  }, [
    bmapi,
    campaign.id,
    intl,
    notifyError,
    onUpdate,
    startLoading,
    stopLoading,
  ]);

  const showExternalCode = useCallback(
    (productIds) => {
      startLoading();

      return bmapi
        .getExternalCode(productIds[0])
        .then(setCode)
        .catch((e) => notifyError(getErrorMessageString(e, intl)))
        .finally(stopLoading);
    },
    [bmapi, intl, notifyError, startLoading, stopLoading]
  );

  const showQrCode = useCallback(
    (productIds) => {
      startLoading();

      function getCode() {
        return bmapi.settings.multiburn
          ? bmapi.createMultiPermissionCode(productIds)
          : bmapi.createPermissionCode(productIds[0]);
      }

      return getCode()
        .then((perm) => {
          setPermission(perm);
          setCode(perm.id);
          setUpdateInterval(
            setInterval(
              () => isExpired(perm.expire_at) && showQrCode(productIds),
              updateIntervalTime
            )
          );
        })
        .catch((e) => notifyError(getErrorMessageString(e, intl)))
        .finally(stopLoading);
    },
    [bmapi, intl, notifyError, startLoading, stopLoading]
  );

  const hideQrCode = () => {
    setCluster(false);
    setProductIds([]);
    setCode(false);
    setPermission(false);
    setUpdateInterval(false);
    if (!campaign.external_ids_format) {
      onUpdate();
    }
  };

  const acceptProduct = () => {
    startLoading();
    bmapi
      .acceptProduct(campaign.id)
      .then(() => {
        notifySuccess(
          intl.formatMessage(
            productMsg[getLabel(campaign.rules.subtype)].received
          )
        );
        onUpdate();
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(() => {
        stopLoading();
        setShowConfirm(false);
      });
  };

  const modifyQuantity = (q) => {
    setQuantity(q);
    clearTimeout(debounceTimeout);
    setDebounceTimeout(
      setTimeout(() => {
        setProductIds(products[cluster].slice(0, q).map((p) => p.id));
      }, 500)
    );
  };

  const addProduct = () => {
    modifyQuantity(quantity + 1);
  };

  const removeProduct = () => {
    modifyQuantity(quantity - 1);
  };

  const handleClick = (onSelect) => (event) => {
    if (campaignStatus === STATUS.REEDEMABLE) {
      setShowConfirm(true);
    } else if (
      [STATUS.ANONYMOUS, STATUS.NO_PERMISSION].includes(campaignStatus)
    ) {
      bmapi.setCallbackUrl(window.location.href);
      urlParams
        ? history.push(
            CONSUMER_ROUTES.HOME +
              "?c=" +
              encodeURIComponent(window.location.href) +
              "&t=e"
          )
        : history.push(CONSUMER_ROUTES.HOME);
    } else if (campaignStatus === STATUS.BOOKABLE) {
      joinWaitingList();
    } else {
      onSelect(event);
    }
  };

  const copyCode = (code) => {
    navigator.clipboard
      .writeText(code)
      .then(() =>
        notifySuccess(
          intl.formatMessage({
            id: "component.product.codeCopied",
            defaultMessage: "Codice copiato con successo",
          })
        )
      )
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  };

  const createLink = (str) => (
    <Link
      href={campaign.tos_consumer_url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {str}
    </Link>
  );

  const onError = useCallback(
    (error) => {
      stopLoading();
      notifyError(error);
    },
    [notifyError, stopLoading]
  );

  const sendCode = useCallback(
    (qrCode) => {
      if (qrCode) {
        bmapi
          .processStamp(qrCode)
          .then((res) => {
            let msg = intl.formatMessage({
              id: "common.receivedPoints",
              defaultMessage: "Punti ricevuti",
            });
            if (res && res.points) {
              // DECIMALS
              msg += ": " + Math.round(res.points / 100);
            }
            notifySuccess(msg);
            window.history.pushState(
              {},
              "",
              location.origin + location.pathname
            );
          })
          .then(() => onUpdate())
          .catch((e) => {
            onError(getErrorMessageString(e, intl));
            window.history.pushState(
              {},
              "",
              location.origin + location.pathname
            );
          });
      } else {
        onError(
          intl.formatMessage({
            id: "pages.scanner.invalidQrCode",
            defaultMessage: "Codice scannerizzato non valido",
          })
        );
        window.history.pushState({}, "", location.origin + location.pathname);
      }
    },
    [intl, notifySuccess, onError, bmapi, onUpdate]
  );

  useEffect(() => {
    setUrlParams(false);
    const qrCode = new URLSearchParams(location.search).get("qrcode");
    const campaignType = new URLSearchParams(location.search).get("t");
    if (qrCode && campaignType === "E") {
      setUrlParams(true);
    }
    if (qrCode && campaignType === "E" && campaignStatus === STATUS.ENABLED) {
      sendCode(qrCode);
    }
  }, [sendCode, campaignStatus]);

  useEffect(() => {
    const showImage =
      campaignStatus !== STATUS.EXTERNAL ||
      campaign.external_ids_format === CODE_FORMATS.QR_CODE;

    if (code && showImage) {
      QRCodeGenerator.toDataURL(campaign.codes_prefix + code, {
        scale: 16,
      }).then(setImage);
    } else {
      setImage(false);
    }
  }, [
    campaign.codes_prefix,
    campaign.external_ids_format,
    campaignStatus,
    code,
  ]);

  useEffect(() => {
    setQuantity(productIds.length);

    if (productIds.length > 0) {
      setUpdateInterval(false);
      campaignStatus === STATUS.EXTERNAL
        ? showExternalCode(productIds)
        : showQrCode(productIds);
    }
  }, [campaignStatus, productIds, showExternalCode, showQrCode]);

  useEffect(() => {
    if (typeof cluster === "number") setProductIds([products[cluster][0].id]);
  }, [cluster, products]);

  useEffect(() => {
    const ui = updateInterval;
    return () => ui && clearInterval(ui);
  }, [updateInterval]);

  const isDisabled = campaignStatus <= 0;
  const visibleCode =
    !!permission.short_code && bmapi.settings.shortCode
      ? campaign.codes_prefix + permission.short_code
      : campaign.codes_prefix + code;

  if (
    campaign.rules.subtype === PRODUCT_SUBTYPES.EARNING_CARD_INSTANT_WIN &&
    campaignStatus === STATUS.ENABLED
  ) {
    return null;
  }

  console.log(
    ".....",
    campaignStatus,
    campaign.rules.subtype,
    getLabel(campaign.rules.subtype)
  );

  return (
    products && (
      <React.Fragment>
        <Dialog
          onClose={hideQrCode}
          open={typeof cluster === "number"}
          maxWidth={"sm"}
          keepMounted={false}
          style={{ alignItems: "center", textAlign: "center" }}
        >
          <DialogTitle>
            {campaign.name}
            {!campaign.loop_campaign && (
              <React.Fragment>
                {" "}
                /{" "}
                <span style={{ color: theme.palette.primary.main }}>
                  {campaign.business_name}
                </span>
              </React.Fragment>
            )}
          </DialogTitle>
          {!campaign.external_ids_format && (
            <DialogContent>
              {intl.formatMessage(
                productMsg[getLabel(campaign.rules.subtype)].showToManager
              )}
            </DialogContent>
          )}
          <DialogContent>
            {image && (
              <img
                src={image || ""}
                alt="QR Code"
                style={{ maxWidth: "100%" }}
              />
            )}

            {(bmapi.settings.showCode ||
              (campaignStatus === STATUS.EXTERNAL &&
                campaign.external_ids_format === CODE_FORMATS.STRING)) && (
              <React.Fragment>
                <div>
                  <Button onClick={() => copyCode(visibleCode)}>
                    <Typography
                      variant="h5"
                      style={{ wordBreak: "break-all", textTransform: "none" }}
                    >
                      {visibleCode}
                    </Typography>
                  </Button>
                </div>
                <div>
                  <Button onClick={() => copyCode(visibleCode)}>
                    <Typography variant="caption">
                      <FormattedMessage
                        id="component.product.copyCodeAction"
                        defaultMessage="Tocca sul codice per copiarlo"
                      />
                    </Typography>
                  </Button>
                </div>
              </React.Fragment>
            )}

            {bmapi.settings.multiburn &&
              [PRODUCT_TYPES.CAMPAIGN_COUPON].includes(campaign.type) &&
              (campaign.rules.shot_number === 0 ||
                campaign.rules.shot_number === 1) &&
              products[cluster] &&
              products[cluster].length > 1 && (
                <Grid
                  container
                  alignItems="center"
                  justify="center"
                  spacing={2}
                >
                  <Grid item>
                    <Fab
                      size="small"
                      disabled={quantity <= 1}
                      color="secondary"
                      style={{ boxShadow: "none" }}
                      onClick={removeProduct}
                    >
                      <RemoveIcon />
                    </Fab>
                  </Grid>
                  <Grid item>
                    <Typography>
                      <span style={{ fontSize: "3em", fontWeight: "bold" }}>
                        {quantity}
                      </span>
                      / {products[cluster] ? products[cluster].length : 1}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Fab
                      size="small"
                      disabled={
                        quantity >=
                        (products[cluster] ? products[cluster].length : 1)
                      }
                      color="secondary"
                      style={{ boxShadow: "none" }}
                      onClick={addProduct}
                    >
                      <AddIcon />
                    </Fab>
                  </Grid>
                </Grid>
              )}
          </DialogContent>
          <DialogActions>
            {!campaignId && campaign.external_ids_format && (
              <Button
                component={RouterLink}
                to={CONSUMER_ROUTES.CAMPAIGN.replace(
                  ":campaignId",
                  campaign.id
                )}
              >
                <FormattedMessage
                  id="common.instructions"
                  defaultMessage="Istruzioni"
                />
              </Button>
            )}
            <Button
              autoFocus
              onClick={hideQrCode}
              color="primary"
              variant="contained"
            >
              <FormattedMessage id="common.close" defaultMessage="Chiudi" />
            </Button>
          </DialogActions>
        </Dialog>

        <Confirm
          open={showConfirm}
          onConfirm={acceptProduct}
          onCancel={() => setShowConfirm(false)}
          title={campaign.name}
          text={intl.formatMessage(
            productMsg[getLabel(campaign.rules.subtype)].confirmAccept,
            {
              campaignName: (
                <strong key={Math.random()}>{campaign.name}</strong>
              ),
            }
          )}
          flag={
            campaign.tos_consumer_url && (
              <FormattedMessage
                id="component.product.tos"
                defaultMessage="Dichiaro di avere letto e approvato il regolamento della campagna e le condizioni di adesione pubblicate su <link>questa pagina</link>"
                values={{ link: createLink }}
              />
            )
          }
        />

        {!!campaignStatus && (
          <ProductSelector
            campaign={campaign}
            products={products}
            onSelect={setCluster}
          >
            {(onClick) => (
              <Button
                color="primary"
                onClick={handleClick(onClick)}
                startIcon={btnIcon[campaignStatus]}
                disabled={isDisabled}
                size={bmapi.tenant === TENANTS.EKOM ? "large" : "medium"}
                variant={bmapi.tenant === TENANTS.EKOM ? "contained" : "text"}
                disableElevation
              >
                {intl.formatMessage(
                  btnText(getLabel(campaign.rules.subtype), campaignStatus)
                )}
              </Button>
            )}
          </ProductSelector>
        )}
      </React.Fragment>
    )
  );
}

export default function Product({
  campaign,
  products,
  issuedQuantity,
  availability,
  onUpdate = () => {},
  link = false,
  reserved = false,
  showMessage = false,
}) {
  const intl = useIntl();
  const { bmapi } = useBmapi();

  function getProductExpiration(campaignId) {
    const nextProd = getCampaignProducts(campaignId)[0];
    return nextProd && new Date(nextProd.expiration_date);
  }

  const getCampaignProducts = useCallback(
    (campaignId) => {
      return (products || [])
        .filter((p) => p.campaign_id === campaignId)
        .filter((p) => p.status === 0)
        .sort(
          (a, b) => new Date(a.expiration_date) - new Date(b.expiration_date)
        );
    },
    [products]
  );

  const getToUseProducts = useCallback(
    (campaignId) => {
      if (campaign.rules.subtype === PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE) {
        return Object.values(
          getCampaignProducts(campaignId).reduce(
            (acc, cur) => ({
              ...acc,
              [cur.available_value]: [...(acc[cur.available_value] || []), cur],
            }),
            {}
          )
        );
      }
      return [getCampaignProducts(campaignId)];
    },
    [campaign.rules.subtype, getCampaignProducts]
  );

  const clusters = useMemo(() => {
    return getToUseProducts(campaign.id);
  }, [campaign.id, getToUseProducts]);

  const campaignStatus = useMemo(() => {
    if (!bmapi.checkIfLoggedIn()) {
      return !campaign.link_distribution
        ? STATUS.NO_PERMISSION
        : STATUS.ANONYMOUS;
    }
    if (bmapi.needsVerification()) {
      return STATUS.DISABLED_ACCOUNT;
    }

    if (endOfDay(new Date(campaign.expiration_date)) < new Date()) {
      return STATUS.CAMPAIGN_ENDED;
    }

    if (
      clusters?.[0]?.length > 0 &&
      availability === 0 &&
      campaign.type !== PRODUCT_TYPES.CAMPAIGN_EARNING_CARD
    ) {
      return STATUS.ALL_PRODUCTS_USED;
    }

    if (clusters?.[0]?.length > 0) {
      if (new Date(campaign.start_date) > new Date()) {
        return STATUS.CAMPAIGN_NOT_STARTED;
      }

      return !campaign.external_ids_format ? STATUS.ENABLED : STATUS.EXTERNAL;
    }

    if (reserved) {
      return STATUS.BOOKED;
    }

    if (
      campaign.max_issue_number !== 0 &&
      campaign.max_issue_number <= issuedQuantity
    ) {
      if (campaign.waiting_list) {
        return STATUS.BOOKABLE;
      }

      return STATUS.NO_PRODUCTS_AVAILABLE;
    }

    if (campaign.link_distribution) {
      return STATUS.REEDEMABLE;
    }

    return STATUS.NOT_AVAILABLE;
  }, [availability, bmapi, campaign, clusters, issuedQuantity, reserved]);

  function getStatusText() {
    switch (campaign.rules.subtype) {
      case PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE:
        return intl.formatMessage(
          {
            id: "component.product.earningBalance",
            defaultMessage: "Saldo: {balance} {currency}",
          },
          { balance: availability, currency: campaign.rules.currency }
        );

      case PRODUCT_SUBTYPES.PROVISIONING_CARD:
      case PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE:
        return intl.formatMessage(
          {
            id: "component.product.giftcardBalance",
            defaultMessage: "Valore disponibile: {balance} {currency}",
          },
          { balance: availability, currency: campaign.rules.currency }
        );

      case PRODUCT_SUBTYPES.COUPON_DISCOUNT:
      case PRODUCT_SUBTYPES.COUPON_SIMPLE:
      case PRODUCT_SUBTYPES.COUPON_VALUE:
        return intl.formatMessage(
          {
            id: "component.product.couponBalance",
            defaultMessage:
              "{availability, plural, one {1 coupon disponibile} other {# coupon disponibili}}",
          },
          { availability }
        );

      case PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE:
        return intl.formatMessage(
          {
            id: "component.product.eventPassBalance",
            defaultMessage:
              "{availability, plural, one {1 pass disponibile} other {# pass disponibili}}",
          },
          { availability }
        );

      case PRODUCT_SUBTYPES.EARNING_CARD_INSTANT_WIN:
        return intl.formatMessage(
          {
            id: "component.product.multiwinBalance",
            defaultMessage:
              "{availability, plural, one {# giocata disponibile} other {# giocate disponibili}}",
          },
          { availability }
        );

      default:
        break;
    }

    return "";
  }

  const hasPrize = () => {
    const byThresold = (a, b) => a.threshold - b.threshold;
    return (
      campaign.rules.subtype === PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE &&
      campaign.rules.prizes &&
      Array.isArray(campaign.rules.prizes) &&
      campaign.rules.prizes.sort(byThresold)[0].threshold / 100 <= availability
    );
  };

  const canShare = () => {
    return (
      [
        PRODUCT_TYPES.CAMPAIGN_COUPON,
        PRODUCT_TYPES.CAMPAIGN_EVENT_PASS,
        PRODUCT_TYPES.CAMPAIGN_SHOPPING_CARD,
      ].includes(campaign.type) &&
      products
        ?.filter((p) => p.campaign_id === campaign.id)
        .filter((p) => p.status === 0).length > 0 &&
      availability > 0 &&
      campaign.status === CAMPAIGN_STATUS.ACTIVE &&
      endOfDay(new Date(campaign.expiration_date)) >= new Date() &&
      bmapi.can(FEATURES.SHARE_PRODUCT)
    );
  };

  const hasEvents = () => {
    return (
      bmapi.can(FEATURES.CALENDAR) &&
      campaign.front_end_type === PRODUCTS.EVENT_PASS
    );
  };

  const hasProducts = () => {
    return products?.length > 0;
  };

  const dateInfoMsg = intl.formatMessage(
    getSignificantDateStatus(campaign, availability),
    {
      start_date: new Date(campaign.start_date),
      start_hour: campaign.start_hour || false,
      expiration_date: new Date(campaign.expiration_date),
      next_expiration:
        getProductExpiration(campaign.id) || new Date(campaign.expiration_date),
    }
  );

  console.log(
    "xxxxx",
    campaignStatus,
    showMessage,
    messageText(campaignStatus)
  );
  const msgText =
    !!campaignStatus && showMessage && messageText(campaignStatus);
  return (
    <>
      {!!msgText && (
        <Alert severity="success" variant="filled" style={{ marginBottom: 20 }}>
          {intl.formatMessage(msgText, {
            campaignName: campaign?.name,
            btnText: intl.formatMessage(
              btnText(getLabel(campaign.rules.subtype), campaignStatus)
            ),
          })}
        </Alert>
      )}
      <ProductUI
        campaign={campaign}
        campaignStatus={campaignStatus}
        canPlay={canPlay(products, availability, campaign)}
        canShare={canShare()}
        cta={
          <UseProduct
            campaign={campaign}
            campaignStatus={campaignStatus}
            products={clusters}
            onUpdate={onUpdate}
          />
        }
        firstProductId={clusters[0]?.[0]?.id}
        hasEvents={hasEvents()}
        hasPrize={hasPrize()}
        hasProducts={hasProducts()}
        link={link}
        onUpdate={onUpdate}
        products={clusters}
        textDateInfo={dateInfoMsg}
        textStatus={getStatusText()}
      />
    </>
  );
}
