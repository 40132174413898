import React from "react";
import { useIntl } from "react-intl";

import { FormControl, FormControlLabel, Switch } from "@material-ui/core";

import { form } from "../../../messages";
import { PRODUCT_SUBTYPES } from "../../../utils/constants";

export default function LinkDistribution({ values, handleChange }) {
  const intl = useIntl();

  const onChange = (_, v) => {
    handleChange("link_distribution")(v);
    if (v) handleChange("dynamic_value")(false);
    handleChange("max_products_per_user")("1");
  };

  return (
    ![PRODUCT_SUBTYPES.EARNING_CARD_INSTANT_WIN].includes(values.subtype) && (
      <FormControl margin="normal">
        <FormControlLabel
          control={
            <Switch
              checked={values.link_distribution}
              color="primary"
              onChange={onChange}
              key="link_distribution"
              name="link_distribution"
            />
          }
          label={intl.formatMessage(form.linkDistribution)}
          //disabled={!!campaign}
          disabled={
            performance?.issued_qty > 0 &&
            values.rules &&
            (values.dynamic_value || values.rules.max_products_per_user !== 1)
          }
        />
      </FormControl>
    )
  );
}
