import React from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
} from "@material-ui/core";
import { common } from "../messages";
import { useIntl } from "react-intl";

export default function InfoDialog({ info, onClose, open }) {
  const intl = useIntl();
  const text = info && info.text;
  const title = info && info.title;
  const linkURL = info && info.linkURL;
  const linkLabel = info && info.linkLabel;
  const actions = info && info.actions;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
        {linkURL && linkLabel && (
          <Link href={linkURL} target="_blank" rel="noopener">
            {linkLabel}
          </Link>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{intl.formatMessage(common.close)}</Button>
        {actions &&
          actions?.map((action, i) => (
            <Button key={i} {...action} onClick={action?.onClick}>
              {action.text}
            </Button>
          ))}
      </DialogActions>
    </Dialog>
  );
}
