import React from "react";
import { useIntl } from "react-intl";

import { TextField } from "@material-ui/core";

import { common } from "../../../messages";
import { useBmapi } from "../../../utils/bmapi-context";

export function FiscalCode({ value, onChange }) {
  const intl = useIntl();
  const { bmapi } = useBmapi();

  return (
    <TextField
      fullWidth
      label={intl.formatMessage(common.fiscalCode)}
      margin="normal"
      name="fiscal_code"
      onChange={(e) => onChange(e.target.value.toUpperCase())}
      inputProps={{
        pattern:
          "^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$",
      }}
      required={bmapi.settings.profileRequired.includes("fiscal_code")}
      value={value}
    />
  );
}
