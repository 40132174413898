import { makeStyles } from "@material-ui/core/styles";

import wheel from "../images/wheel.png";
import wheelFrame from "../images/wheel-frame.png";

const createTheme = (theme) => ({
  avatar: {
    backgroundColor: theme.palette.getContrastText(theme.palette.primary.main),
    color: theme.palette.primary.main,
  },
  bannerMedia: {
    paddingTop: `${(5 / 16) * 100}%`,
    backgroundSize: "contain",
  },
  buttonMenu: {
    padding: "40px 10px",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  buttonMenuSmall: {
    padding: "10px",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  campaignIcon: {
    color: theme.palette.getContrastText(theme.palette.primary.main),
    backgroundColor: theme.palette.primary.main,
  },
  card: {
    position: "relative",
  },
  cardIcon: {
    backgroundColor: theme.palette.primary.main,
  },
  cardIconLarge: {
    width: theme.spacing(theme.props.bigAvatarSize),
    height: theme.spacing(theme.props.bigAvatarSize),
  },
  cardMedia: {
    paddingTop: `${(10 / 16) * 100}%`,
  },
  cardSecondaryActions: {
    marginLeft: "auto",
  },
  chip: {
    maxWidth: "100%",
  },
  chipWrapper: {
    maxWidth: "100%",
  },
  confirmLabel: {
    lineHeight: 1,
  },
  coverUpload: {
    marginBottom: 3,
    position: "relative",

    "& img": {
      display: "block",
      width: "100%",
    },
  },
  coverUploadButtons: {
    bottom: theme.spacing(2),
    position: "absolute",
    right: theme.spacing(2),

    "& > *": {
      marginLeft: theme.spacing(1),
    },
  },
  dangerButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.getContrastText(theme.palette.error.main),

    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
  demoChip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
  },
  demoRibbon: {
    backgroundColor: theme.palette.primary.main,
    boxShadow: "0 0 20px rgba(255, 255, 255, 0.7)",
    color: theme.palette.getContrastText(theme.palette.primary.main),
    display: "block",
    height: "1.6em",
    lineHeight: "1.6em",
    position: "absolute",
    right: "-5em",
    textAlign: "center",
    top: "-0.8em",
    transform: "rotate(45deg) translateY(3em)",
    width: "10em",
  },
  disabled: {
    filter: "saturate(0)",
  },
  disabledText: {
    color: "#a2a2a2",
  },
  fabProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
  },
  fieldset: {
    width: "100%",
  },
  floatingActionsWrapper: {
    position: "fixed",
    left: "50%",
    transform: "translateX(-50%)",
    bottom: theme.spacing(2),
    zIndex: theme.zIndex.drawer + 2,
    whiteSpace: "nowrap",
  },
  floatingActionsBg: {
    backgroundImage: `linear-gradient(to top, ${theme.palette.background.default} 40%, transparent)`,
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    height: 110,
    pointerEvents: "none",
    zIndex: theme.zIndex.drawer + 1,
  },
  floatingActionsButtonLabel: {
    marginLeft: theme.spacing(1),
    whiteSpace: "nowrap",
  },
  expand: {
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  header: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  headerLogo: {
    objectFit: "contain",
    objectPosition: "left center",
    width: "100%",
    maxHeight: 60,
    maxWidth: 300,
    verticalAlign: "middle",
  },
  langButton: {
    background: theme.palette.primary.light,
  },
  listChip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    marginLeft: 5,
  },
  listChipFilter: {
    backgroundColor: "grey",
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    marginLeft: 10,
  },
  listItemAvatar: {
    minWidth: theme.spacing(theme.props.bigAvatarSize + 2),
  },
  listItemInset: {
    paddingLeft: theme.spacing(theme.props.bigAvatarSize + 2),
  },
  loginColor: {
    color:
      theme.palette.common.contrastText ||
      theme.palette.getContrastText(theme.palette.background.default),
  },
  loginButton: {
    borderRadius: theme.shape.borderRadius,
    maxWidth: 220,
    textTransform: "none",
  },
  mainMenu: {
    width: "100vw",
    maxWidth: 300,
    background: theme.palette.common.bg,
    color:
      theme.palette.common.contrastText ||
      theme.palette.getContrastText(theme.palette.common.bg),

    "@media (min-width: 400px)": {
      "&.MuiDrawer-paperAnchorLeft": {
        borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
      },
      "&.MuiDrawer-paperAnchorRight": {
        borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
      },
    },
  },
  mainMenuBack: {
    minHeight: 56,
    [`${theme.breakpoints.up("xs")} and (orientation: landscape)`]: {
      minHeight: 48,
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: 64,
    },
  },
  menuItemIcon: {
    "@media (max-width: 240px)": {
      display: "none",
    },
  },
  mainMenuLinks: {
    flexGrow: 1,
    overflow: "auto",
  },
  mainMenuLogoWrapper: {
    padding: theme.spacing(1),
  },
  mainMenuLogo: {
    objectFit: "contain",
    objectPosition: "center center",
    maxHeight: 80,
    maxWidth: 160,
    width: "100%",
    margin: "auto",
    display: "block",
  },
  menuIcon: {
    height: "100px",
    width: "100px",
    margin: "0 auto",
    backgroundColor: "white",
    color: "black",
  },
  menuItem: {
    fontWeight: "normal",
  },
  menuItemSelected: {
    fontWeight: "bold",
    backgroundColor: "#fff3",
  },
  pageIcon: {
    backgroundColor: theme.palette.secondary.main,
    display: "inline-flex",
    margin: theme.spacing(2),
  },
  spinningWheelContainer: {
    alignItems: "center",
    display: "flex",
    height: "100vh",
    justifyContent: "center",
    overflow: "hidden",
    backgroundColor: theme.palette.primary.main,
  },
  spinningWheelFrame: {
    alignItems: "center",
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    height: "100vmin",
    width: "100vmin",
    minWidth: 300,
    minHeight: 300,
    maxWidth: 600,
    maxHeight: 600,
    backgroundSize: "contain",
    backgroundImage: `url(${wheelFrame})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  spinningWheel: {
    transformOrigin: "center",
    height: "100vmin",
    width: "100vmin",
    minWidth: 300,
    minHeight: 300,
    maxWidth: 600,
    maxHeight: 600,
    backgroundSize: "contain",
    backgroundImage: `url(${wheel})`,
  },
  spinningWheelMessage: {
    position: "absolute",
  },
  statsIcon: {
    backgroundColor: theme.palette.primary.main,
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  statsTitle: {
    textAlign: "right",
  },
  subtitle: {
    marginTop: theme.spacing(1),
  },
  tetrisBlock: {
    margin: "0.15em",
    minWidth: "1.75em",
    height: "1.75em",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tierMedia: {
    paddingTop: `${(2 / 16) * 100}%`,
  },
  tosLogo: {
    backgroundColor: theme.palette.common.bg,
    backgroundSize: "contain",
    border: "20px solid transparent",
    height: 120,
  },
  topBar: {
    background: theme.palette.common.bg,
    color:
      theme.palette.common.contrastText ||
      theme.palette.getContrastText(theme.palette.common.bg),
  },
});

export default {
  useStyles: makeStyles(createTheme, { index: 1 }),
};
