import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import { format } from "date-fns";

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { ArrowBack, Check, Clear } from "@material-ui/icons";

import { common } from "../../messages";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { MANAGER_ROUTES, USER_EVENT_STATUS } from "../../utils/constants";
import { useHistory } from "react-router-dom";

function Partecipant({ checkins, event, person }) {
  const intl = useIntl();

  const getCheckinTime = (checkin) =>
    `${format(new Date(checkin.check_in_at), "HH:mm")}${
      checkin.checked_out
        ? ` → ${format(new Date(checkin.check_out_at), "HH:mm")}`
        : ""
    }`;

  const status =
    checkins.length === 0
      ? USER_EVENT_STATUS.ABSENT
      : checkins.some((c) => !c.checked_out)
      ? USER_EVENT_STATUS.PRESENT
      : USER_EVENT_STATUS.OUT;

  const byDate = (a, b) => new Date(a.check_in_at) - new Date(b.check_in_at);

  const timeText =
    status === USER_EVENT_STATUS.ABSENT
      ? intl.formatMessage(common.notPresent)
      : checkins.sort(byDate).map(getCheckinTime).join(" | ");

  return (
    <ListItem>
      {event && (
        <ListItemIcon>
          {status === USER_EVENT_STATUS.ABSENT ? (
            <Clear />
          ) : (
            <Check style={{ color: "green" }} />
          )}
        </ListItemIcon>
      )}
      <ListItemText
        primary={
          person.complete_name
            ? `${person.last_name} ${person.complete_name}`
            : person.email
        }
        secondary={event ? timeText : ""}
      />
    </ListItem>
  );
}

export default function CampaignEvents() {
  const { campaignId } = useParams();
  const history = useHistory();
  const intl = useIntl();
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const [checkins, setCheckins] = useState([]);
  const [currentEvent, setCurrentEvent] = useState(false);
  const [events, setEvents] = useState(false);
  const [partecipants, setPartecipants] = useState([]);

  useEffect(() => {
    startLoading();
    Promise.all([
      bmapi.getCampaignEvents(campaignId),
      bmapi.getCampaignUsers(campaignId),
    ])
      .then(([evs, parts]) => {
        const byStart = (a, b) =>
          new Date(b.started_at) - new Date(a.started_at);
        const sorted = (evs || [])
          .filter((e) => e.started_at !== "0001-01-01T00:00:00Z")
          .sort(byStart);
        setEvents(sorted);
        setCurrentEvent(sorted[0]);
        setPartecipants(parts);
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  }, [bmapi, campaignId, intl, notifyError, startLoading, stopLoading]);

  useEffect(() => {
    if (currentEvent) {
      startLoading();
      bmapi
        .getEventCheckIns(currentEvent.id)
        .then(setCheckins)
        .catch((e) => notifyError(getErrorMessageString(e, intl)))
        .finally(stopLoading);
    }
  }, [bmapi, currentEvent, intl, notifyError, startLoading, stopLoading]);

  return (
    <Container maxWidth="sm">
      <Box my={2}>
        <Button
          onClick={() =>
            history.push(
              MANAGER_ROUTES.CAMPAIGN.replace(":campaignId", campaignId)
            )
          }
          startIcon={<ArrowBack />}
        >
          {intl.formatMessage(common.back)}
        </Button>
      </Box>

      {events && (
        <Box mb={4}>
          <Card>
            {events.length === 0 && (
              <CardContent>
                <Typography>
                  {intl.formatMessage({
                    id: "component.campaignEvents.noEvents",
                    defaultMessage: "Nessun evento ancora iniziato",
                  })}
                </Typography>
              </CardContent>
            )}
            {currentEvent && (
              <CardContent>
                <Box my={2}>
                  <TextField
                    margin="dense"
                    label={intl.formatMessage({
                      id: "component.campaignEvents.event",
                      defaultMessage: "Evento",
                    })}
                    value={currentEvent.id}
                    onChange={(e) =>
                      setCurrentEvent(
                        events.find((evt) => evt.id === e.target.value)
                      )
                    }
                    fullWidth
                    select
                  >
                    {events.map((evt) => (
                      <MenuItem value={evt.id} key={evt.id}>
                        {`${intl.formatDate(new Date(evt.starting_date))} ${
                          evt.starting_time
                        } → ${evt.ending_time} @ ${evt.location_name}`}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                <Typography>
                  <strong>{intl.formatMessage(common.location)}: </strong>
                  {currentEvent.location_name}
                </Typography>
                <Typography>
                  <strong>{intl.formatMessage(common.business)}: </strong>
                  {currentEvent.business_name}
                </Typography>
              </CardContent>
            )}
          </Card>
        </Box>
      )}
      <Box mb={4}>
        <Card>
          <CardContent>
            <Typography variant="h5">
              {intl.formatMessage({
                id: "component.campaignEvents.enrolled",
                defaultMessage: "Iscritti",
              })}
            </Typography>
          </CardContent>
          {partecipants?.length ? (
            <List dense>
              {partecipants.map((person) => (
                <Partecipant
                  person={person}
                  checkins={checkins.filter((c) => c.user_id === person.id)}
                  key={person.id}
                  event={currentEvent}
                />
              ))}
            </List>
          ) : (
            <CardContent>
              <Typography>
                {intl.formatMessage({
                  id: "component.campaignEvents.noPartecipants",
                  defaultMessage: "Nessuno utente ancora iscritto",
                })}
              </Typography>
            </CardContent>
          )}
        </Card>
      </Box>

      <Box my={2}>
        <Button
          onClick={() =>
            history.push(
              MANAGER_ROUTES.CAMPAIGN.replace(":campaignId", campaignId)
            )
          }
          startIcon={<ArrowBack />}
        >
          {intl.formatMessage(common.back)}
        </Button>
      </Box>
    </Container>
  );
}
