import React, { useEffect } from "react";
import { useBmapi } from "../../utils/bmapi-context";
import {
  getParameter,
  getRouteBase,
  getSignInSubscribeUrl,
} from "../../utils/utils";
import { MANAGER_ROUTES } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import { getErrorMessageString } from "../../utils/errors";
//import ConsumerSubscriptions from "./ConsumerSubscriptions";
import { useIntl } from "react-intl";
import {
  //Box,
  Container,
  LinearProgress,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { paymentMessages } from "../../messages/paymentMessages";

/*function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}*/

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
});

export default function SubscribeStep2() {
  const { bmapi, userId, notifyError, startLoading } = useBmapi();
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const [progress, setProgress] = React.useState(10);

  function post(path, params, method = "post") {
    // The rest of this code assumes you are not using a library.
    // It can be made less verbose if you use one.
    const form = document.createElement("form");
    form.method = method;
    form.action = path;

    for (const key in params) {
      //if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";
      hiddenField.name = key;
      hiddenField.value = params[key];

      form.appendChild(hiddenField);
      //}
    }

    document.body.appendChild(form);
    form.submit();
  }

  const loading = () => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 10 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  };

  useEffect(() => {
    const load = async () => {
      console.log("eeeeeeeeeeeeeee");
      const paymentId = getParameter("payment");
      const subscriptionId = getParameter("subscription");
      if (
        paymentId &&
        subscriptionId &&
        bmapi.settings.subscriptionPaymentMode
      ) {
        if (!userId) {
          /*
          const formPage = `${MANAGER_ROUTES.SUBSCRIBE_STEP2}?payment=${paymentId}&subscription=${subscriptionId}`;
          history.push(
            MANAGER_ROUTES.SIGN_IN +
              addUrlParameters(URL_TRACE_VALUES.SUBSCRIPTION, formPage)
          );*/
          const newUrl = getSignInSubscribeUrl({ paymentId, subscriptionId });
          history.push(newUrl);
        } else {
          const callbackUrl = getRouteBase() + MANAGER_ROUTES.SUBSCRIBE_STEP3;
          const query = {
            success_url: callbackUrl,
            error_url: callbackUrl,
            payment: paymentId,
          };
          try {
            loading();
            const resp = await bmapi.subscribeStep2(subscriptionId, query);
            setProgress(100);
            const nexiUrl = resp.url;
            const nexiBody = resp.body;
            console.log("step1");
            post(nexiUrl, nexiBody, "POST");
          } catch (e) {
            notifyError(getErrorMessageString(e, intl));
          }
        }
      }
    };
    load();
  }, [bmapi, userId, history, notifyError, intl, startLoading]);

  return (
    <Container maxWidth="sm">
      <Typography gutterBottom variant="h6">
        {intl.formatMessage(paymentMessages.inProgress)}
      </Typography>
      <div className={classes.root}>
        <LinearProgress variant="determinate" value={progress} />
      </div>
    </Container>
  );
}
