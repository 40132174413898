import React from "react";
import UsersStatistics from "../components/UsersStatistics";

export const STATISTIC_TYPE = [
  {
    value: "users",
    label: "Statistiche utenti",
    component: <UsersStatistics />,
  },
  { value: "prizes", label: "Statistiche premi" },
  { value: "merchants", label: "Statistiche operatori" },
];

export const SLOT_VALUES = [10, 15, 20, 30, 60];

/* USERS */

export const INIT_QUERY = {
  page: 0,
  qty: 25,
  orderBy: "-issuedValue",
  email: "",
};

export const USERS_ORDER = [
  { value: "email", label: "Email (crescente)" },
  { value: "-email", label: "Email (decrescente)" },
  { value: "status", label: "Stato (crescente)" },
  { value: "-status", label: "Stato (decrescente)" },
  { value: "issuedValue", label: "Punti ricevuti (crescente)" },
  { value: "-issuedValue", label: "Punti ricevuti (decrescente)" },
  { value: "usedValue", label: "Punti utilizzati o cancellati (crescente)" },
  { value: "-usedValue", label: "Punti utilizzati o cancellati (decrescente)" },
];

/* PRIZES */

export const INIT_QUERY_PRIZES = {
  page: 0,
  qty: 25,
  orderBy: "-Received",
};

export const PRIZES_ORDER = [
  { value: "Email", label: "Email (crescente)" },
  { value: "-Email", label: "Email (decrescente)" },
  { value: "Prize", label: "Nome Premio (crescente)" },
  { value: "-Prize", label: "Nome Premio (decrescente)" },
  { value: "Received", label: "Data richiesta (crescente)" },
  { value: "-Received", label: "Data richiesta (decrescente)" },
  { value: "Status", label: "Stato (crescente)" },
  { value: "-Status", label: "Stato (decrescente)" },
];

/* MERCHANTS */

export const INIT_QUERY_MERCHANTS = {
  page: 0,
  qty: 25,
  orderBy: "Name",
};

export const MERCHANTS_ORDER = [
  { value: "Name", label: "Nome (crescente)" },
  { value: "-Name", label: "Nome (decrescente)" },
  { value: "Categories", label: "Categoria (crescente)" },
  { value: "-Categories", label: "Categoria (decrescente)" },
];

export const MERCHANT_ACTIONS = [
  { value: "PURCHASE", label: "Acquisto" },
  { value: "VISIT", label: "Visita" },
  { value: "FAIR", label: "Fiera" },
  { value: "REVIEW", label: "Recensione" },
  { value: "GIFT", label: "Benvenuto" },
  { value: "PROFILE50", label: "Bonus profilo" },
  { value: "ENTRY", label: "Ingresso" },
];

export const STATUS_ACTIONS = [
  { value: "APPROVED", label: "Confermata" },
  { value: "PENDING", label: "Da confermare" },
  { value: "CANCELED", label: "Cancellata" },
];
