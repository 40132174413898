import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Card, CardContent, Grid, Typography } from "@material-ui/core";

import { useBmapi } from "../../utils/bmapi-context";
import TetrisChart, { Block } from "../../ui/TetrisChart";
import { getAvailability } from "../../utils/campaigns";
import { campaign, storeTypes } from "../../messages";

export default function CardTetris({ stats }) {
  const { bmapi } = useBmapi();
  const intl = useIntl();
  const [statistics, setStatistics] = useState(false);

  const data = [...(bmapi.settings.businessCategories || []), "BONUS"].map(
    (key) => ({
      label: storeTypes[key] ? intl.formatMessage(storeTypes[key]) : key,
      value:
        (statistics?.categories || []).find((c) => c.category === key)?.count ||
        0,
    })
  );

  useEffect(() => {
    stats && bmapi.getCategories(stats.campaign.id).then(setStatistics);
  }, [bmapi, stats]);

  return (
    <Card>
      <CardContent>
        <Typography>
          {intl.formatMessage({
            id: "component.cardTetris.title",
            defaultMessage: "Punti accumulati da utilizzare",
          })}
        </Typography>
      </CardContent>
      <CardContent>
        <TetrisChart data={data} />
      </CardContent>
      <CardContent>
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item xs={4} zeroMinWidth>
            <Typography noWrap align="right" variant="body2">
              {intl.formatMessage(campaign.availablePlays)}
            </Typography>
          </Grid>
          <Grid item>
            <Block>
              <Typography variant="h6">
                {stats && getAvailability(stats)}
              </Typography>
            </Block>
          </Grid>
        </Grid>
        <Grid container direction="row" alignItems="center" spacing={1}>
          <Grid item xs={4} zeroMinWidth>
            <Typography noWrap align="right" variant="body2">
              {intl.formatMessage(campaign.usedPlays)}
            </Typography>
          </Grid>
          <Grid item>
            <Block>
              <Typography variant="h6">
                {stats &&
                  stats.statistics.used_value /
                    stats.campaign.rules.instant_win_threshold}
              </Typography>
            </Block>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
