import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { endOfDay } from "date-fns";

import { Box, Button } from "@material-ui/core";

import Product from "../components/Product";
import { getAvailability } from "../utils/campaigns";
import {
  CAMPAIGN_STATUS,
  CONSUMER_ROUTES,
  PRODUCT_TYPES,
} from "../utils/constants";
import Grid from "./Grid";

function available(p) {
  return (
    p.campaign.status === CAMPAIGN_STATUS.ACTIVE &&
    endOfDay(new Date(p.campaign.expiration_date)) >= new Date() &&
    (getAvailability(p) ||
      p.campaign.type === PRODUCT_TYPES.CAMPAIGN_EARNING_CARD)
  );
}

function notAvailable(p) {
  return !available(p);
}

function campaignLink(campaign) {
  return CONSUMER_ROUTES.CAMPAIGN.replace(":campaignId", campaign.campaign.id);
}

function SingleProduct(products, updateData) {
  const Component = (campaign) => (
    <Product
      key={campaign.campaign.id}
      campaign={campaign.campaign}
      products={products}
      availability={getAvailability(campaign)}
      onUpdate={updateData}
      link={campaignLink(campaign)}
    />
  );
  return Component;
}

export default function ProductsList({
  products = [],
  stats = [],
  updateData,
}) {
  const [showAll, setShowAll] = useState(false);

  const itemsAvailable = stats.filter(available);
  const itemsNotAvailable = stats.filter(notAvailable);

  const Renderer = SingleProduct(products, updateData);

  return (
    <React.Fragment>
      <Grid items={itemsAvailable} render={Renderer} />
      {showAll && <Grid items={itemsNotAvailable} render={Renderer} />}
      {itemsNotAvailable.length > 0 && (
        <Box my={4}>
          <Button
            variant="contained"
            color="default"
            onClick={() => setShowAll((f) => !f)}
          >
            {showAll ? (
              <FormattedMessage
                id="components.productList.hideAll"
                defaultMessage="Nascondi card scadute ed esaurite"
              />
            ) : (
              <FormattedMessage
                id="components.productList.showAll"
                defaultMessage="Mostra tutte le card e coupon"
              />
            )}
          </Button>
        </Box>
      )}
    </React.Fragment>
  );
}
