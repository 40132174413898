import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";
import { format } from "date-fns";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useBmapi } from "../utils/bmapi-context";
import { getErrorMessageString } from "../utils/errors";
import { STATUS_ACTIONS } from "../utils/gdspConstants";
import {
  parseBmarkenDate,
  parseBmeIntToFloat,
  parseBmeIntToInt,
} from "../utils/utils";

const getDate = (ra) => {
  if (ra.type === "FAIR" || ra.type === "REVIEW") {
    return ra.created_at;
  } else if (ra.type === "ENTRY") {
    return ra.created_at;
  }
  return ra.action_date;
};

function DetailsView({ ra, /*showScanner*/ setDetailsView }) {
  const getDetail = (ra) => {
    if (ra.type === "FAIR") {
      return "Stand operatore: " + ra.business_name;
    } else if (ra.type === "PURCHASE") {
      return parseBmeIntToFloat(ra.expense) + "€";
    } else if (ra.type === "REVIEW") {
      return ra.comment;
    } else {
      return "-";
    }
  };

  return (
    ra && (
      <React.Fragment>
        <Typography variant="h6" align="center" gutterBottom>
          Ecco il dettaglio della tua interazione
        </Typography>
        <Card>
          <CardContent>
            {[
              {
                name: "DATA:",
                datum: format(parseBmarkenDate(getDate(ra)), "dd-MM-yy"),
              },
              { name: "FORNITORE", datum: ra.business_name },
              { name: "PUNTI:", datum: parseBmeIntToInt(ra.reward) },
              { name: "DETTAGLIO:", datum: getDetail(ra) },
              {
                name: "STATO",
                datum: STATUS_ACTIONS.find((s) => s.value === ra.status).label,
              },
            ].map((el, i) => (
              <React.Fragment key={i}>
                {i !== 0 && <Divider />}
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <Typography gutterBottom>{el.name}</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography align="right" gutterBottom>
                      {el.datum}
                    </Typography>
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}
          </CardContent>
        </Card>
        {/* {showScanner !== null &&
        ra.status === "PENDING" &&
        ra.type !== "FAIR" &&
        ra.type != "REVIEW" ? (
          <Box mt={2}>
            <Typography align="center" gutterBottom>
              I punti della tua interazione non sono ancora stati raddoppiati:
              recati presso il punto vendita e scansiona il codice del negozio
            </Typography>
            <Typography align="center" gutterBottom>
              COSI’ POTRAI RADDOPPIARE I TUOI PUNTI
            </Typography>

            <Button
              onClick={() => {
                console.log(ra);
                showScanner(ra.id);
              }}
              variant="outlined"
              style={{ display: "block", margin: "0 auto" }}
              color="primary"
            >
              Scansiona
            </Button>
            <br />
          </Box>
        ) : null}

        {ra.status === "CANCELED" ? (
          <Box mt={2}>
            <Typography align="center" gutterBottom>
              Linterazione è stata cancellata e con essa i punti attribuiti.
              Contatta lassistenza per ulteriori informazioni
            </Typography>
          </Box>
        ) : null} */}

        <Box mt={2}>
          <Button
            onClick={() => {
              setDetailsView(false);
            }}
            style={{ display: "block", margin: "0 auto" }}
            variant="contained"
            color="primary"
          >
            Indietro
          </Button>
        </Box>
      </React.Fragment>
    )
  );
}

function DetailsMerchantView({
  ra,
  /*showQRCode,*/ setDetailsView,
  user,
  setDisplayUserInfo,
}) {
  const getDetail = (ra) => {
    if (ra.type === "FAIR") {
      return "Stand operatore: " + ra.business_name;
    } else if (ra.type === "PURCHASE") {
      return parseBmeIntToFloat(ra.expense) + "€";
    } else if (ra.type === "REVIEW") {
      return ra.comment;
    } else {
      return "-";
    }
  };

  return (
    ra && (
      <React.Fragment>
        <Typography variant="h6" align="center" gutterBottom>
          Ecco il dettaglio della tua interazione
        </Typography>
        <Card>
          <CardContent>
            {[
              {
                name: "DATA:",
                datum: format(parseBmarkenDate(getDate(ra)), "dd-MM-yy"),
              },
              { name: "USER:", datum: ra.user_name },
              { name: "EMAIL:", datum: ra.user_email },
              { name: "TELEFONO:", datum: user.mobile },
              {
                name: "DATA MATRIMONIO:",
                datum: format(
                  parseBmarkenDate(user.custom_fields.wedding_date),
                  "dd-MM-yy"
                ),
              },
              {
                name: "PROVINCIA MATRIMONIO:",
                datum: user.custom_fields.wedding_province,
              },
              { name: "PUNTI:", datum: parseBmeIntToInt(ra.reward) },
              { name: "DETTAGLIO:", datum: getDetail(ra) },
              {
                name: "STATO",
                datum: STATUS_ACTIONS.find((s) => s.value === ra.status).label,
              },
            ].map((el, i) => (
              <React.Fragment key={i}>
                {i !== 0 && <Divider />}
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <Typography gutterBottom>{el.name}</Typography>
                  </Grid>
                  <Grid item sm={6}>
                    <Typography align="right" gutterBottom>
                      {el.datum}
                    </Typography>
                  </Grid>
                </Grid>
              </React.Fragment>
            ))}
          </CardContent>
        </Card>
        {/* {showQRCode !== null &&
        ra.status === "PENDING" &&
        ra.type !== "FAIR" &&
        ra.type != "REVIEW" ? (
          <Box mt={2}>
            <Typography align="center" gutterBottom>
              I punti della tua interazione non sono ancora stati raddoppiati:
              recati presso il punto vendita e scansiona il codice del negozio
            </Typography>
            <Typography align="center" gutterBottom>
              COSI’ POTRAI RADDOPPIARE I TUOI PUNTI
            </Typography>

            <Button
              onClick={() => {
                console.log(ra);
                showQRCode(ra.id);
              }}
              variant="outlined"
              style={{ display: "block", margin: "0 auto" }}
              color="primary"
            >
              Scansiona
            </Button>
            <br />
          </Box>
        ) : null}

        {ra.status === "CANCELED" ? (
          <div>
            Linterazione è stata cancellata e con essa i punti attribuiti.
            Contatta lassistenza per ulteriori informazioni
            <br />
          </div>
        ) : null} */}

        <Box mt={2}>
          <Button
            onClick={() => {
              setDetailsView(false);
              setDisplayUserInfo(null);
            }}
            style={{ display: "block", margin: "0 auto" }}
            variant="contained"
            color="primary"
          >
            Indietro
          </Button>
        </Box>
      </React.Fragment>
    )
  );
}

export default function ActionsDetails(props) {
  const intl = useIntl();
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const [ra, setRa] = useState(null);
  const [user, setUser] = useState(null);
  const [detailsView, setDetailsView] = useState(false);
  const [title, setTitle] = useState(props.title);
  const merchantStatsView = props.merchantStatsView;

  title;
  setTitle;

  function translateAction(act) {
    if (act === "PURCHASE") return "Acquisto";
    if (act === "VISIT") return "Visita";
    if (act === "FAIR") return "Fiera";
    if (act === "REVIEW") return "Recensione";
    if (act === "GIFT") return "Benvenuto";
    if (act === "PROFILE50") return "Bonus profilo";
    if (act === "ENTRY") return "Ingresso";
    return act;
  }

  const getStatusColor = (status) => {
    let color;
    switch (status) {
      case "APPROVED":
        color = "green";
        break;
      case "CANCELED":
        color = "red";
        break;
      default:
        color = "orange";
    }

    return color;
  };

  const showDetails = (ra) => {
    startLoading();
    if (ra.type === "GIFT") return;
    if (ra.type === "PROFILE50") return;
    setDetailsView(true);

    bmapi
      .getUser(ra.user_id)
      .then((resp) => {
        if (!resp) {
          resp = {};
        }
        resp.custom_fields = JSON.parse(resp.custom_fields);
        if (props.updateUserPhoto && props.updateUserPhoto != null) {
          props.updateUserPhoto(resp);
        }
        setUser(resp);
        setRa(ra);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  };

  return (
    <React.Fragment>
      {!detailsView && (
        <>
          <Card
            style={{
              marginTop: "25px",
              paddingLeft: "5px",
              paddingRight: "5px",
              overflow: "scroll",
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>DATA</TableCell>
                  {merchantStatsView ? (
                    <TableCell style={{ fontWeight: "bold" }}>USER</TableCell>
                  ) : (
                    <TableCell style={{ fontWeight: "bold" }}>
                      FORNITORE
                    </TableCell>
                  )}
                  <TableCell style={{ fontWeight: "bold" }}>AZIONE</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>P.TI</TableCell>
                </TableRow>
              </TableHead>
              {props.actions.length > 0 ? (
                <TableBody>
                  {props.actions.map((act) => (
                    <TableRow
                      onClick={() => showDetails(act)}
                      key={act.id}
                      style={{ cursor: "pointer" }}
                      hover={true}
                    >
                      <TableCell align="right">
                        {act.business_name == "SposiClub.it" ? (
                          <span></span>
                        ) : (
                          <FiberManualRecord
                            style={{
                              color: getStatusColor(act.status),
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {format(parseBmarkenDate(getDate(act)), "dd/MM/yy")}
                      </TableCell>
                      {merchantStatsView ? (
                        <TableCell>
                          {act.user_name + " " + act.user_email}
                        </TableCell>
                      ) : (
                        <TableCell>{act.business_name}</TableCell>
                      )}
                      <TableCell>{translateAction(act.type)}</TableCell>
                      <TableCell align="right">
                        {parseBmeIntToInt(act.reward)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4}>
                      Non hai ancora effettuato interazioni
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </Card>
          <Box mt={1}>
            <FiberManualRecord
              fontSize="small"
              style={{
                color: "green",
                marginLeft: 25,
              }}
            />
            <Typography variant="caption">Punti raddoppiati</Typography>
            <FiberManualRecord
              fontSize="small"
              style={{
                color: "orange",
                marginLeft: 25,
              }}
            />
            <Typography variant="caption">Punti da raddoppiare</Typography>
            <FiberManualRecord
              fontSize="small"
              style={{
                color: "red",
                marginLeft: 25,
              }}
            />
            <Typography variant="caption">Interazione annullata</Typography>
          </Box>
        </>
      )}
      {detailsView &&
        (merchantStatsView ? (
          <DetailsMerchantView
            ra={ra}
            showQRCode={props.showQRCode}
            setDetailsView={setDetailsView}
            user={user}
            setDisplayUserInfo={props.setDisplayUserInfo}
          />
        ) : (
          <DetailsView
            ra={ra}
            showScanner={props.showScanner}
            setDetailsView={setDetailsView}
          />
        ))}
    </React.Fragment>
  );
}
