import React from "react";

import {
  AccountCircle as AccountCircleIcon,
  Apps,
  Assignment,
  Book,
  CenterFocusStrong as CenterFocusStrongIcon,
  CloudDownload as CloudDownloadIcon,
  CloudOff,
  ConfirmationNumber,
  Email,
  EmojiEvents,
  Equalizer as EqualizerIcon,
  ExitToApp as ExitIcon,
  Fingerprint,
  GroupWork,
  Home as HomeIcon,
  ListAlt,
  LiveHelp,
  Loyalty as LoyaltyIcon,
  MobileScreenShare,
  Museum,
  MyLocation as MyLocationIcon,
  Notifications,
  //OpenInNew,
  Person,
  PersonAdd,
  PinDrop as PinDropIcon,
  Room,
  School,
  Send as SendIcon,
  Settings as SettingsIcon,
  SettingsApplications,
  Store,
  Timeline as TimelineIcon,
  MenuBook as MenuBookIcon,
  ViewList as ViewListIcon,
  LocalMall as LocalMallIcon,
  Euro,
  LockOpen,
  Event,
  Settings,
  FormatListBulleted,
  Storefront,
  NotificationImportant,
  Equalizer,
  RateReview,
  Home,
  Help,
  DateRange,
} from "@material-ui/icons";

import {
  CONSUMER_ROUTES,
  CONSUMER,
  FEATURES,
  LINK_TYPE,
  MANAGER_ROUTES,
  MANAGER,
  ROLES,
  USER_STATUS,
  SKINS,
  BUSINESS_TYPES,
} from "../utils/constants";
import { common, navigation, subscriptions } from "../messages";

import EmptyPage from "../components/EmptyPage";
import LoginPage from "../components/LoginPage";

import LuccaBuy from "../pages/custom/lcng/LuccaBuy";
import LuccaConfirm from "../pages/custom/lcng/LuccaConfirm";
import LuccaStats from "../pages/custom/lcng/LuccaStats";

import Account from "../pages/Account";
import Action from "../pages/Action";
//import Activation from "../pages/Activation";
import Calendar from "../pages/consumer/Calendar";
import Campaign from "../pages/consumer/Campaign";
import CampaignClone from "../pages/manager/CampaignClone";
import CampaignCreate from "../pages/manager/CampaignCreate";
import CampaignEdit from "../pages/manager/CampaignEdit";
import CampaignEvents from "../pages/manager/CampaignEvents";
import CampaignManager from "../pages/manager/Campaign";
import CampaignNotifications from "../pages/manager/CampaignNotifications";
import Campaigns from "../pages/manager/Campaigns";
import Checkin from "../pages/consumer/Scanner";
import Content from "../pages/Content";
import Dashboard from "../pages/manager/Dashboard";
import Entry from "../pages/Entry";
import Export from "../pages/manager/Export";
import Friend from "../pages/consumer/Friend";
import FriendCode from "../pages/consumer/FriendCode";
import HomeConsumer from "../pages/consumer/Home";
import Locations from "../pages/manager/Locations";
import Logout from "../pages/Logout";
import Loops from "../pages/manager/Loops";
import Lots from "../pages/manager/Lots";
import ManagePrizes from "../pages/manager/ManagePrizes";
import ManageSubscription from "../pages/manager/ManageSubscription";
import Message from "../pages/Message";
import Messages from "../pages/Messages";
import MessagesCampaign from "../pages/manager/MessagesCampaign";
import MessagesCampaignCreate from "../pages/manager/MessagesCampaignCreate";
import MessagesCampaignEdit from "../pages/manager/MessagesCampaignEdit";
import MyLocation from "../pages/consumer/MyLocation";
import PasswordForget from "../pages/PasswordReset";
import Play from "../pages/consumer/Play";
//import Pricing from "../pages/consumer/Pricing";
import Profile from "../pages/Profile";
import QrCode from "../pages/consumer/QrCode";
import Receive from "../pages/consumer/Receive";
import Reporting from "../pages/manager/Reporting";
import Reservations from "../pages/manager/Reservations";
import Scanner from "../pages/manager/Scanner";
import SendPoints from "../pages/manager/SendPoints";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import StampScanner from "../pages/consumer/StampScanner";
import Stores from "../pages/manager/Stores";
import SubscribeStep1 from "../pages/consumer/SubscribeStep1";
import Test from "../pages/Test";
import VoidRequest from "../pages/manager/VoidRequest";
import ProductsInventory from "../pages/manager/ProductsInventory";
import SubscribeStep3 from "../pages/consumer/SubscribeStep3";
import ManagePoiList from "../pages/manager/ManagePoiList";
import ManageTourList from "../pages/manager/ManageTourList";
import Tour from "../pages/manager/Tour";
import POI from "../pages/manager/POI";
import EventActionCreate from "../pages/manager/EventActionCreate";
import EProductCategory from "../pages/manager/EProductCategory";
import EProduct from "../pages/manager/EProduct";
import UserInspection from "../pages/manager/UserInspection";
import Museums from "../pages/manager/Museums";
//import { isSubscriptionIncomplete } from "./utils";
import Glossary from "../pages/manager/Glossary";
import SubscriptionStart from "../pages/consumer/SubscriptionStart";
import ManageOrders from "../pages/manager/ManageOrders";
import ConfigurationGen from "../pages/manager/ConfigurationGen";
import ManageEProducts from "../pages/manager/ManageEProducts";
import Subscriptions from "../pages/consumer/Subscriptions";
import OrderCreate from "../pages/manager/OrderCreate";
import ReportingOrders from "../pages/manager/ReportingOrders";
import EmployeeAccess from "../pages/manager/EmployeeAccess";
import EventAction from "../pages/manager/EventAction";
import ConsentManagement from "../pages/manager/ConsentManagement";
import ConsentManagementCreate from "../pages/manager/ConsentManagementCreate";
import ManageTest from "../pages/manager/ManageTest";
import SettingsManagement from "../pages/manager/SettingsManagement";
import SubscriptionCreate from "../pages/manager/SubscriptionCreate";
import AdminSubscriptions from "../pages/manager/AdminSubscriptions";
import HomeManager from "../pages/manager/HomeManager";
import SubscribeStep2 from "../pages/manager/SubscribeStep2";
import DistributionLists from "../pages/manager/DistributionLists";
import DistributionListCreate from "../pages/manager/DistributionListCreate";
import DistributionListEdit from "../pages/manager/DistributionListEdit";
import Activation from "../pages/Activation";
import Fair from "../pages/manager/Fair";
import PushNotifications from "../components/PushNotifications";
import Statistics from "../pages/manager/Statistics";
import Reviews from "../pages/manager/Reviews";
import ScannerGdsp from "../pages/manager/ScannerGdsp";
import Assistance from "../pages/manager/Assistance";
import ActionsMerchant from "../pages/manager/ActionsMerchant";
import ReservationsConfig from "../pages/manager/ReservationsConfig";
import HomeMenu from "../pages/HomeMenu";
import Operator from "../pages/manager/Operator";
import ManageReservations from "../pages/manager/ManageReservations";
import ManageReservationsGen from "../pages/manager/ManageReservationsGen";

export default (bmapi, app, status, baseUrl) => {
  function canView(link) {
    // check if the merchant / museum pages are allowed by the subscription
    if (
      ((link === PAGES.MANAGE_BUSINESSES ||
        link === PAGES.EXPORT ||
        link === PAGES.REPORTING ||
        link === PAGES.REPORTING_ORDERS) &&
        bmapi.subscription?.business_types?.indexOf(BUSINESS_TYPES.MERCHANT) ===
          -1) ||
      ((link === PAGES.MANAGE_MUSEUMS || link === PAGES.GLOSSARY) &&
        (!bmapi.subscription?.business_types ||
          bmapi.subscription?.business_types?.indexOf(BUSINESS_TYPES.MUSEUM) ===
            -1))
    ) {
      return false;
    }

    // check if the subscription has the campaigns
    if (
      (link === PAGES.CAMPAIGNS_MANAGER ||
        link === PAGES.DASHBOARD ||
        PAGES.SCAN_QR) &&
      bmapi.subscription?.campaigns_disabled
    ) {
      return false;
    }

    if (
      link === PAGES.EVENT_ACTION &&
      !(bmapi.getUserInfo().business?.type === "Loop")
    ) {
      return false;
    }
    return (
      link &&
      !link.disabled &&
      (!link.feature || bmapi.can(link.feature)) &&
      // filter on business type
      (!link.selectedBusinessTypes ||
        bmapi.hasBusinessType(link.selectedBusinessTypes)) &&
      // ecommerce ordering
      (!link.isEcommerceOrdering || bmapi.hasEcommerceOrdering()) &&
      // filter on campaigns disabled
      !(link.requiresCampaigns && bmapi.campaignsDisabled) &&
      // filter on roles
      (!link.roles ||
        bmapi.validateRoles(link.roles, bmapi.getUserInfo().role)) &&
      // filter on tenant
      (!link.tenants || link.tenants.includes(bmapi.tenant))
    );
  }

  const ROUTES = app === CONSUMER ? CONSUMER_ROUTES : MANAGER_ROUTES;

  /*********** PAGE OBJECT EXAMPLE {
    disabled: Boolean,  // Hide page
    feature: FEATURES.*,  // Enable page only when feature flag is enabled in tenant configuration
    home: Boolean,  // Use the page as home
    roles: [ROLES.*],  // Enable the page only for this roles
    tenants: [TENANTS.*],  // Enable the page only for this tenants

    // Use this object to create an item in the main menu
    menu: {
      action: async function,  // Function to execute on click, it has to be async
      badge: Boolean,  // true to show a notification badge on the icon
      icon: <ReactComponent />,  // Icon component rendered
      label: String,  // Label for the menu item
      position: LINK_TYPE.*,  // It can be ACTION to show the menu item in the bottom part of the main menu
      sublabel: String,  // Optional second line for the menu label
      url: String,  // Url to link
    },
    // If there is no `action` or `url` the menu item onclick will redirect to the path found in the next `routing` object

    // Use this object if you need to add a page to the web app
    routing: {
      Component: ReactComponent,  // https://v5.reactrouter.com/web/api/Route/component
      exact: Boolean,  // https://v5.reactrouter.com/web/api/Route/exact-bool
      path: String [MANAGER_ROUTES.* || CONSUMER_ROUTES.*],  // https://v5.reactrouter.com/web/api/Route/path-string-string
      Wrapper: LoginPage || EmptyPage,  // React component to wrap the page and shared between navigations [default PageWrapper]
      [LoginPage is the one with primary color used as background and without navbar]
    },
  } ************/

  const PAGES = {
    LOGOUT: {
      feature: FEATURES.LOGOUT,
      menu: {
        icon: <ExitIcon />,
        label: navigation.logout,
        position: LINK_TYPE.ACTION,
        sublabel: bmapi.getUserInfo().email,
      },
      routing: {
        Component: Logout,
        path: ROUTES.LOGOUT,
        Wrapper: LoginPage,
      },
    },
    ACTION: {
      routing: {
        Component: Action,
        path: CONSUMER_ROUTES.ACTION,
        Wrapper: LoginPage,
      },
    },
    CAMPAIGN: {
      routing: {
        Component: Campaign,
        path: CONSUMER_ROUTES.CAMPAIGN,
      },
    },
    RECEIVE_PRODUCT: {
      routing: {
        Component: Receive,
        path: CONSUMER_ROUTES.RECEIVE,
      },
    },
    CALENDAR: {
      routing: {
        Component: Calendar,
        path: CONSUMER_ROUTES.CALENDAR,
      },
    },
    STATIC: {
      routing: {
        Component: Content,
        path: ROUTES.STATIC,
      },
    },
    /*
    PRICING: {
      menu: {
        icon: <Assignment />,
        label: navigation.subscribe,
      },
      routing: {
        Component: Pricing,
        path: CONSUMER_ROUTES.PRICING,
      },
      tenants: [TENANTS.SELF],
    },*/
    FAQ: {
      feature: FEATURES.FAQ,
      menu: {
        icon: <LiveHelp />,
        label: navigation.faq,
        url: ROUTES.STATIC.replace(":content?/", "faq"),
      },
    },
    TEST: {
      routing: {
        Component: Test,
        path: CONSUMER_ROUTES.TEST,
        Wrapper: EmptyPage,
      },
    },
    /*
    EKOM_LINK: {
      feature: FEATURES.EKOM_LINK,
      menu: {
        icon: <OpenInNew />,
        label: "www.ekomdiscount.it",
        url: "http://www.ekomdiscount.it/",
      },
      tenants: [TENANTS.EKOM],
    },*/
    SIGN_IN: {
      routing: {
        Component: SignIn,
        path: ROUTES.SIGN_IN_ACTION,
        Wrapper: LoginPage,
      },
    },
    SIGN_IN_EMAIL: {
      routing: {
        Component: SignIn,
        path: ROUTES.SIGN_IN_EMAIL,
        Wrapper: LoginPage,
      },
    },
    SIGN_UP: {
      routing: {
        Component: SignUp,
        path: ROUTES.SIGN_UP,
        Wrapper: LoginPage,
      },
    },
    PASSWORD_FORGET: {
      routing: {
        Component: PasswordForget,
        path: ROUTES.PASSWORD_FORGET,
        Wrapper: LoginPage,
      },
    },
    ENTRY: {
      //home: app === MANAGER || !bmapi.can(FEATURES.PUBLIC_HOME),
      home: !bmapi.can(FEATURES.PUBLIC_HOME),
      routing: {
        Component: Entry,
        exact: true,
        path: ROUTES.SIGN_IN,
        Wrapper: LoginPage,
      },
    },
    PUBLIC_HOME: {
      feature: FEATURES.PUBLIC_HOME,
      home: true,
      routing: {
        Component: HomeConsumer,
        exact: true,
        path: CONSUMER_ROUTES.HOME,
      },
    },
    PUBLIC_HOME_MANAGER: {
      feature: FEATURES.PUBLIC_HOME_MANAGER,
      home: true,
      routing: {
        Component: HomeManager,
        exact: true,
        path: MANAGER_ROUTES.HOME,
      },
    },
    SUBSCRIPTION_START: {
      routing: {
        Component: SubscriptionStart,
        exact: true,
        path: CONSUMER_ROUTES.SUBSCRIPTION_START,
      },
    },
    /*
    MANAGERSUBSCRIPTION_START: {
      routing: {
        Component: SubscriptionStart,
        exact: true,
        path: MANAGER_ROUTES.SUBSCRIPTION_START,
      },
    },*/
    FRIEND_CODE_LANDING: {
      feature: FEATURES.FRIEND_CODE,
      routing: {
        Component: FriendCode,
        path: CONSUMER_ROUTES.FRIEND_CODE,
        Wrapper: LoginPage,
      },
    },

    ACTIVATION: {
      home: true,
      routing: {
        Component: Activation,
        exact: true,
        path: ROUTES.HOME,
      },
    },
    HOME_CONSUMER: {
      id: "consumer.home",
      home: true,
      menu: {
        icon: <HomeIcon />,
        label: navigation.home,
      },
      routing: {
        Component: HomeConsumer,
        exact: true,
        path: CONSUMER_ROUTES.HOME,
      },
    },
    /*
    SUBSCRIBE: {
      routing: {
        Component: Subscribe,
        path: CONSUMER_ROUTES.JOIN,
      },
      tenants: [TENANTS.SELF],
    },*/
    MANAGER_SUBSCRIBE: {
      feature: FEATURES.SUBSCRIBE_STEP1,
      routing: {
        Component: SubscribeStep1,
        path: MANAGER_ROUTES.SUBSCRIBE_STEP1,
      },
      //tenants: [TENANTS.SELF],
    },
    MANAGER_SUBSCRIBE_BUY: {
      feature: FEATURES.SUBSCRIBE_STEP2,
      routing: {
        Component: SubscribeStep2,
        path: MANAGER_ROUTES.SUBSCRIBE_STEP2,
      },
      //tenants: [TENANTS.SELF],
    },
    /*
    SUBSCRIBE_PAYMENT_OUTCOME: {
      feature: FEATURES.SUBSCRIBE,
      routing: {
        Component: SubscribePaymentOutcome,
        path: CONSUMER_ROUTES.SUBSCRIBE_PAYMENT_OUTCOME,
      },
      tenants: [TENANTS.SELF],
    },*/
    MANAGER_SUBSCRIBE_PAYMENT_OUTCOME: {
      feature: FEATURES.SUBSCRIBE_STEP2,
      routing: {
        Component: SubscribeStep3,
        path: MANAGER_ROUTES.SUBSCRIBE_STEP3,
      },
    },
    MY_QR: {
      feature: FEATURES.MY_CODE,
      menu: {
        icon: <Apps />,
        label: navigation.qrcode,
      },
      routing: {
        Component: QrCode,
        path: CONSUMER_ROUTES.QR_CODE,
      },
    },
    MESSAGE: {
      feature: FEATURES.MESSAGES,
      routing: {
        Component: Message,
        path: CONSUMER_ROUTES.MESSAGE,
      },
    },
    MESSAGES: {
      feature: FEATURES.MESSAGES,
      menu: {
        icon: <Notifications />,
        label: navigation.notifications,
        position: LINK_TYPE.ACTION,
      },
      routing: {
        Component: Messages,
        path: CONSUMER_ROUTES.MESSAGES,
      },
    },
    CHECKIN: {
      feature: FEATURES.CHECKIN,
      menu: {
        icon: <CenterFocusStrongIcon />,
        label: navigation.checkin,
      },
      routing: {
        Component: Checkin,
        path: CONSUMER_ROUTES.SCAN,
      },
    },
    FRIEND_CODE: {
      feature: FEATURES.FRIEND_CODE,
      menu: {
        icon: <PersonAdd />,
        label: navigation.friendCode,
      },
      routing: {
        Component: Friend,
        path: CONSUMER_ROUTES.FRIEND,
      },
    },
    PLAY: {
      feature: FEATURES.INSTANT_WIN,
      routing: {
        Component: Play,
        path: CONSUMER_ROUTES.PLAY,
        Wrapper: EmptyPage,
      },
    },
    GO_TO_ADMIN_LINK: {
      disabled: !bmapi.canGoToAdmin(),
      feature: FEATURES.CROSS_LINKS,
      menu: {
        action: () => {
          console.info("redirect ##15");
          return Promise.resolve(
            (window.location.href = baseUrl + MANAGER_ROUTES.HOME.slice(1))
          );
        },
        icon: <SettingsApplications />,
        label: navigation.appManager,
        position: LINK_TYPE.ACTION,
      },
    },
    ACCOUNT_LINK: {
      disabled:
        !bmapi.can(FEATURES.ACCOUNT) && !bmapi.can(FEATURES.PROFILE_PAGE),
      menu: {
        badge:
          bmapi.isConsumer() &&
          bmapi.can(FEATURES.PROFILE_COMPLETE) &&
          !bmapi.userData?.profile_complete,
        icon: <Person />,
        label: navigation.account,
        position: LINK_TYPE.ACTION,
        url:
          bmapi.isConsumer() && bmapi.can(FEATURES.PROFILE_PAGE)
            ? ROUTES.PROFILE
            : ROUTES.ACCOUNT,
      },
    },
    ACCOUNT: {
      feature: FEATURES.ACCOUNT,
      routing: {
        Component: Account,
        path: ROUTES.ACCOUNT,
      },
    },
    PROFILE: {
      feature: FEATURES.PROFILE_PAGE,
      routing: {
        Component: Profile,
        path: ROUTES.PROFILE,
      },
    },
    LCNG_FAQ: {
      feature: FEATURES.LCNG,
      menu: {
        icon: <AccountCircleIcon />,
        label: navigation.campfireFaq,
        url: CONSUMER_ROUTES.STATIC.replace(":content?/", "faq"),
      },
    },
    LCNG_BUY: {
      feature: FEATURES.LCNG,
      routing: {
        Component: LuccaBuy,
        path: CONSUMER_ROUTES.LUCCA_BUY,
      },
    },
    LCNG_CONFIRM: {
      feature: FEATURES.LCNG,
      routing: {
        Component: LuccaConfirm,
        path: CONSUMER_ROUTES.LUCCA_CONFIRM,
      },
    },
    SCANNER_CONSUMER: {
      feature: FEATURES.SCAN_QR_CONSUMER,
      menu: {
        icon: <CenterFocusStrongIcon />,
        label: navigation.scanner,
      },
      routing: {
        Component: StampScanner,
        path: CONSUMER_ROUTES.SCAN,
      },
    },
    CAMPAIGNS_MANAGER: {
      feature: FEATURES.CAMPAIGNS_MANAGER,
      home: !bmapi.can(FEATURES.HOME_MENU), // true
      menu: {
        icon:
          bmapi.settings.skin === SKINS.EDUCATION ? (
            <School />
          ) : (
            <LoyaltyIcon />
          ),
        label: navigation.campaigns,
      },
      routing: {
        Component: Campaigns,
        path: MANAGER_ROUTES.CAMPAIGNS,
      },
    },
    DASHBOARD: {
      feature: FEATURES.VIEW_DASHBOARD,
      menu: {
        icon: <EqualizerIcon />,
        label: navigation.dashboard,
      },
      roles: ROLES.TENANT_MANAGER,
      routing: {
        Component: Dashboard,
        path: MANAGER_ROUTES.DASHBOARD,
      },
    },
    CAMPAIGN_EVENTS: {
      routing: {
        Component: CampaignEvents,
        path: MANAGER_ROUTES.CAMPAIGN_EVENTS,
      },
    },
    CAMPAIGN_MANAGER: {
      routing: {
        Component: CampaignManager,
        exact: true,
        path: MANAGER_ROUTES.CAMPAIGN,
      },
    },
    CAMPAIGN_NOTIFICATIONS: {
      disabled: !bmapi.isSubscriptionManager() && !bmapi.isTenantManager(),
      feature: FEATURES.NOTIFICATIONS,
      routing: {
        Component: CampaignNotifications,
        exact: true,
        path: MANAGER_ROUTES.CAMPAIGN_NOTIFICATIONS,
      },
    },
    CAMPAIGN_CREATE: {
      feature: FEATURES.MANAGE_CAMPAIGN,
      routing: {
        Component: CampaignCreate,
        path: MANAGER_ROUTES.CREATE_CAMPAIGN,
      },
    },
    CAMPAIGN_EDIT: {
      feature: FEATURES.MANAGE_CAMPAIGN,
      routing: {
        Component: CampaignEdit,
        path: MANAGER_ROUTES.EDIT_CAMPAIGN,
      },
    },
    CAMPAIGN_CLONE: {
      feature: FEATURES.MANAGE_CAMPAIGN,
      routing: {
        Component: CampaignClone,
        path: MANAGER_ROUTES.CLONE_CAMPAIGN,
      },
    },
    CONSENT_MANAGEMENT: {
      feature: FEATURES.CONSENT_MANAGEMENT,
      roles: ROLES.TENANT_MANAGER,
      menu: {
        icon: <Assignment />,
        label: navigation.consentManagement,
      },
      routing: {
        Component: ConsentManagement,
        path: MANAGER_ROUTES.CONSENT_MANAGEMENT,
      },
    },
    SETTINGS_MANAGEMENT: {
      feature: FEATURES.SETTINGS_MANAGEMENT,
      roles: ROLES.TENANT_MANAGER,
      menu: {
        icon: <Settings />,
        label: navigation.settings,
      },
      routing: {
        Component: SettingsManagement,
        path: MANAGER_ROUTES.SETTINGS_MANAGEMENT,
      },
    },
    CONSENT_MANAGEMENT_CREATE: {
      //feature: FEATURES.POI,
      /*menu: {
        icon: <MyLocationIcon />,
        label: navigation.poi,
      },*/
      routing: {
        Component: ConsentManagementCreate,
        path: MANAGER_ROUTES.CONSENT_MANAGEMENT_CREATE,
      },
    },
    MESSAGES_CAMPAIGN_CREATE: {
      feature: FEATURES.MANAGE_CAMPAIGN,
      routing: {
        Component: MessagesCampaignCreate,
        path: MANAGER_ROUTES.CREATE_MESSAGE_CAMPAIGN,
      },
    },
    MESSAGES_CAMPAIGN_EDIT: {
      feature: FEATURES.MANAGE_CAMPAIGN,
      routing: {
        Component: MessagesCampaignEdit,
        path: MANAGER_ROUTES.EDIT_MESSAGE_CAMPAIGN,
      },
    },
    MESSAGES_MANAGER: {
      feature: FEATURES.MESSAGES_MANAGER,
      menu: {
        icon: <Email />,
        label: common.messages,
      },
      routing: {
        Component: MessagesCampaign,
        path: MANAGER_ROUTES.MESSAGES_CAMPAIGNS,
      },
    },
    RESERVATIONS: {
      routing: {
        Component: Reservations,
        path: MANAGER_ROUTES.RESERVATIONS,
      },
    },
    SCANNER_MANAGER: {
      feature: FEATURES.SCAN_QR,
      menu: {
        icon: <CenterFocusStrongIcon />,
        label: navigation.scanner,
      },
      routing: {
        Component: Scanner,
        path: MANAGER_ROUTES.SCAN,
      },
      requiresCampaigns: true,
    },
    SEND_POINTS: {
      feature: FEATURES.SEND_POINTS,
      menu: {
        icon: <SendIcon />,
        label: navigation.sendPoints,
      },
      routing: {
        Component: SendPoints,
        path: MANAGER_ROUTES.SEND_POINTS,
      },
    },
    LCNG_STATS: {
      feature: FEATURES.LCNG,
      menu: {
        label: navigation.campfireStats,
      },
      routing: {
        Component: LuccaStats,
        path: MANAGER_ROUTES.LUCCA_STATS,
      },
    },
    MANAGE_BUSINESSES: {
      disabled: !bmapi.canManageLoop() || !bmapi.hasExceptions(),
      menu: {
        icon: bmapi.settings.skin === SKINS.EDUCATION ? <Book /> : <Store />,
        label: navigation.stores,
      },
      routing: {
        Component: Stores,
        path: MANAGER_ROUTES.STORES,
      },
    },
    MANAGE_MUSEUMS: {
      disabled: !bmapi.canManageLoop() || !bmapi.hasExceptions(),
      menu: {
        icon: <Museum />,
        label: navigation.museums,
      },
      routing: {
        Component: Museums,
        path: MANAGER_ROUTES.MUSEUMS,
      },
    },
    MANAGE_LOOPS: {
      disabled: !bmapi.canManageLoop(),
      feature: FEATURES.LOOPS,
      menu: {
        icon: <GroupWork />,
        label: navigation.loops,
      },
      routing: {
        Component: Loops,
        path: MANAGER_ROUTES.LOOPS,
      },
    },
    MANAGE_LOCATIONS: {
      disabled: !bmapi.canManageLoop(),
      feature: FEATURES.LOCATIONS,
      menu: {
        icon: <Room />,
        label: navigation.locations,
      },
      routing: {
        Component: Locations,
        path: MANAGER_ROUTES.LOCATIONS,
      },
    },
    REPORTING: {
      feature: FEATURES.REPORTING,
      menu: {
        icon: <ListAlt />,
        label: navigation.reporting,
      },
      routing: {
        Component: Reporting,
        path: MANAGER_ROUTES.REPORTING,
      },
    },
    REPORTING_ORDERS: {
      feature: FEATURES.REPORTING_ORDERS,
      menu: {
        icon: <Euro />,
        label: navigation.reportingOrders,
      },
      routing: {
        Component: ReportingOrders,
        path: MANAGER_ROUTES.REPORTING_ORDERS,
      },
    },
    EMPLOYEE_ACCESS: {
      feature: FEATURES.EMPLOYEE_ACCESS,
      menu: {
        icon: <LockOpen />,
        label: navigation.employeeAccess,
      },
      routing: {
        Component: EmployeeAccess,
        path: MANAGER_ROUTES.EMPLOYEE_ACCESS,
      },
    },
    EVENT_ACTION: {
      feature: FEATURES.EVENT_ACTION,
      roles: ROLES.TENANT_MANAGER,
      menu: {
        icon: <Event />,
        label: navigation.events,
      },
      routing: {
        Component: EventAction,
        path: MANAGER_ROUTES.EVENT_ACTION,
      },
    },
    EVENT_ACTION_CREATE: {
      //feature: FEATURES.POI,
      /*menu: {
        icon: <MyLocationIcon />,
        label: navigation.poi,
      },*/
      routing: {
        Component: EventActionCreate,
        path: MANAGER_ROUTES.EVENT_ACTION_CREATE,
      },
    },
    EXPORT: {
      feature: FEATURES.EXPORT,
      menu: {
        icon: <CloudDownloadIcon />,
        label: navigation.export,
      },
      routing: {
        Component: Export,
        path: MANAGER_ROUTES.EXPORT,
      },
    },
    MANAGE_SUBSCRIPTION: {
      disabled: !bmapi.canManageLoop() || !bmapi.subscription,
      menu: {
        icon: <SettingsIcon />,
        label:
          bmapi.settings.skin === SKINS.MUSEUM
            ? subscriptions.custom
            : subscriptions.subscription,
      },
      routing: {
        Component: ManageSubscription,
        path: MANAGER_ROUTES.SUBSCRIPTION,
      },
    },
    VIEW_SUBSCRIPTIONS: {
      id: "manager.view_subscriptions",
      routing: {
        Component: Subscriptions,
        path: MANAGER_ROUTES.VIEW_SUBSCRIPTIONS,
      },
    },
    VOID_REQUEST: {
      menu: {
        icon: <CloudOff />,
        label: navigation.voidRequest,
      },
      roles: ROLES.TENANT_MANAGER,
      routing: {
        Component: VoidRequest,
        path: MANAGER_ROUTES.VOID_REQUEST,
      },
    },
    MANAGE_PRIZES: {
      feature: FEATURES.INSTANT_WIN,
      menu: {
        icon: <EmojiEvents />,
        label: navigation.prizes,
      },
      roles: ROLES.TENANT_MANAGER,
      routing: {
        Component: ManagePrizes,
        path: MANAGER_ROUTES.PRIZES,
      },
    },
    MANAGE_LOTS: {
      feature: FEATURES.LOTS,
      menu: {
        icon: <ConfirmationNumber />,
        label: navigation.lots,
      },
      roles: ROLES.TENANT_MANAGER,
      routing: {
        Component: Lots,
        path: MANAGER_ROUTES.LOTS,
      },
    },
    PRODUCTS_INVENTORY: {
      routing: {
        Component: ProductsInventory,
        exact: true,
        path: MANAGER_ROUTES.PRODUCTS_INVENTORY,
      },
    },
    GO_TO_APP_LINK: {
      disabled: !bmapi.isConsumerEnabled,
      feature: FEATURES.CROSS_LINKS,
      menu: {
        action: () => {
          console.info("redirect ##16");
          return Promise.resolve(
            (window.location.href = baseUrl + CONSUMER_ROUTES.HOME.slice(1))
          );
        },
        icon: <MobileScreenShare />,
        label: navigation.appConsumer,
        position: LINK_TYPE.ACTION,
      },
    },
    MY_LOCATION: {
      feature: FEATURES.MY_LOCATION,
      menu: {
        icon: <MyLocationIcon />,
        label: navigation.myLocation,
      },
      routing: {
        Component: MyLocation,
        path: CONSUMER_ROUTES.MY_LOCATION,
      },
    },
    POI: {
      //feature: FEATURES.POI,
      /*menu: {
        icon: <MyLocationIcon />,
        label: navigation.poi,
      },*/
      routing: {
        Component: POI,
        path: MANAGER_ROUTES.POI,
      },
    },
    MANAGE_POI_LIST: {
      //feature: FEATURES.POI,
      selectedBusinessTypes: [BUSINESS_TYPES.MUSEUM],
      menu: {
        icon: <PinDropIcon />,
        label: navigation.pois,
      },
      routing: {
        Component: ManagePoiList,
        path: MANAGER_ROUTES.MANAGE_POI_LIST,
      },
    },
    TOUR: {
      //feature: FEATURES.MY_LOCATION,
      /*menu: {
        icon: <MyLocationIcon />,
        label: navigation.tour,
      },*/

      routing: {
        Component: Tour,
        path: MANAGER_ROUTES.TOUR,
      },
    },
    MANAGE_TOUR_LIST: {
      //feature: FEATURES.POI,
      selectedBusinessTypes: [BUSINESS_TYPES.MUSEUM],
      menu: {
        icon: <TimelineIcon />,
        label: navigation.tours,
      },
      routing: {
        Component: ManageTourList,
        path: MANAGER_ROUTES.MANAGE_TOUR_LIST,
      },
    },
    GLOSSARY: {
      menu: {
        icon: <MenuBookIcon />,
        label: navigation.glossary,
      },
      routing: {
        Component: Glossary,
        path: MANAGER_ROUTES.GLOSSARY,
      },
    },
    USER_INSPECTION: {
      feature: FEATURES.USER_INSPECTION,
      menu: {
        icon: <Fingerprint />,
        label: navigation.userInspection,
      },
      routing: {
        Component: UserInspection,
        path: MANAGER_ROUTES.USER_INSPECTION,
      },
    },
    MANAGE_ORDERS: {
      //selectedBusinessTypes: [BUSINESS_TYPES.MERCHANT],
      isEcommerceOrdering: true,
      menu: {
        icon: <ViewListIcon />,
        label:
          bmapi.getUserInfo().business.type === "Museum"
            ? navigation.reservations
            : navigation.orders,
      },
      routing: {
        //Component: ManageOrders,
        Component:
          bmapi.getUserInfo().business.type === "Museum"
            ? ManageReservationsGen
            : ManageOrders,
        path: MANAGER_ROUTES.MANAGE_ORDERS,
      },
    },
    ORDER_CONFIG: {
      //selectedBusinessTypes: [BUSINESS_TYPES.MERCHANT],
      isEcommerceOrdering: true,
      menu: {
        icon: <SettingsApplications />,
        label:
          bmapi.getUserInfo().business.type === "Museum"
            ? navigation.reservationsConfig
            : navigation.orderConfig,
      },
      routing: {
        //Component: OrderConfiguration,
        Component: ConfigurationGen,
        path: MANAGER_ROUTES.ORDER_CONFIG,
      },
    },
    ORDER: {
      //feature: FEATURES.POI,
      /*menu: {
        icon: <MyLocationIcon />,
        label: navigation.poi,
      },*/
      routing: {
        Component: OrderCreate,
        path: MANAGER_ROUTES.ORDER,
      },
    },
    MANAGE_PRODUCTS: {
      //selectedBusinessTypes: [BUSINESS_TYPES.MERCHANT],
      isEcommerceOrdering: true,
      menu: {
        icon: <LocalMallIcon />,
        label: navigation.eProducts,
      },
      routing: {
        Component: ManageEProducts,
        path: MANAGER_ROUTES.MANAGE_PRODUCTS,
      },
    },
    EPRODUCT_CATEGORY: {
      //feature: FEATURES.POI,
      /*menu: {
        icon: <MyLocationIcon />,
        label: navigation.poi,
      },*/
      routing: {
        Component: EProductCategory,
        path: MANAGER_ROUTES.EPRODUCT_CATEGORY,
      },
    },
    EPRODUCT: {
      //feature: FEATURES.POI,
      /*menu: {
        icon: <MyLocationIcon />,
        label: navigation.poi,
      },*/
      routing: {
        Component: EProduct,
        path: MANAGER_ROUTES.EPRODUCT,
      },
    },
    MANAGE_TEST: {
      feature: FEATURES.MANAGE_TEST,
      roles: ROLES.TENANT_MANAGER,
      menu: {
        icon: <SettingsApplications />,
        label: navigation.manageTest,
      },
      routing: {
        Component: ManageTest,
        path: MANAGER_ROUTES.MANAGE_TEST,
      },
    },
    ADMIN_SUBSCRIPTIONS: {
      feature: FEATURES.ADMIN_SUBSCRIPTIONS,
      menu: {
        icon: <FormatListBulleted />,
        label: navigation.adminSubscriptions,
      },
      routing: {
        Component: AdminSubscriptions,
        path: MANAGER_ROUTES.ADMIN_SUBSCRIPTIONS,
      },
    },
    SUBSCRIPTION_CREATE: {
      routing: {
        Component: SubscriptionCreate,
        path: MANAGER_ROUTES.SUBSCRIPTION_CREATE,
      },
    },
    DISTRIBUTION_LISTS: {
      feature: FEATURES.DISTRIBUTION_LISTS,
      menu: {
        icon: <FormatListBulleted />,
        label: navigation.distributionList,
      },
      routing: {
        Component: DistributionLists,
        path: MANAGER_ROUTES.DISTRIBUTION_LISTS,
      },
    },
    DISTRIBUTION_LIST_CREATE: {
      routing: {
        Component: DistributionListCreate,
        path: MANAGER_ROUTES.DISTRIBUTION_LIST_CREATE,
      },
    },
    DISTRIBUTION_LIST_EDIT: {
      routing: {
        Component: DistributionListEdit,
        path: MANAGER_ROUTES.DISTRIBUTION_LIST_EDIT,
      },
    },
    FAIR: {
      feature: FEATURES.FAIR,
      menu: {
        icon: <Storefront />,
        label: navigation.fair,
      },
      roles: ROLES.TENANT_MANAGER,
      routing: {
        Component: Fair,
        path: MANAGER_ROUTES.FAIR,
      },
    },
    STATISTICS: {
      feature: FEATURES.STATISTICS,
      menu: {
        icon: <Equalizer />,
        label: navigation.statistics,
      },
      roles: ROLES.TENANT_MANAGER,
      routing: {
        Component: Statistics,
        path: MANAGER_ROUTES.STATISTICS,
      },
    },
    REVIEWS: {
      feature: FEATURES.REVIEWS,
      menu: {
        icon: <RateReview />,
        label: navigation.reviews,
      },
      roles: ROLES.TENANT_MANAGER,
      routing: {
        Component: Reviews,
        path: MANAGER_ROUTES.REVIEWS,
      },
    },
    OPERATOR: {
      feature: FEATURES.OPERATOR,
      roles: ROLES.STORE_MANAGER,
      menu: {
        icon: <Person />,
        label: navigation.operator,
      },
      routing: {
        Component: Operator,
        path: MANAGER_ROUTES.OPERATOR,
      },
    },
    HOME_MENU: {
      feature: FEATURES.HOME_MENU,
      home: true,
      menu: {
        icon: <Home />,
        label: navigation.home,
      },
      routing: {
        Component: HomeMenu,
        path: MANAGER_ROUTES.HOME_MENU,
      },
    },
    SCANNER_GDSP: {
      feature: FEATURES.SCANNER_GDSP,
      roles: ROLES.STORE_MANAGER,
      menu: {
        icon: <CenterFocusStrongIcon />,
        label: navigation.scanner,
      },
      routing: {
        Component: ScannerGdsp,
        path: MANAGER_ROUTES.SCANNER_GDSP,
      },
    },
    ASSISTANCE: {
      feature: FEATURES.ASSISTANCE,
      roles: ROLES.STORE_MANAGER,
      menu: {
        icon: <Help />,
        label: navigation.assistance,
      },
      routing: {
        Component: Assistance,
        path: MANAGER_ROUTES.ASSISTANCE,
      },
    },
    ACTIONS_MERCHANT: {
      feature: FEATURES.ACTIONS_MERCHANT,
      roles: ROLES.STORE_MANAGER,
      menu: {
        icon: <ListAlt />,
        label: navigation.actionMerchant,
      },
      routing: {
        Component: ActionsMerchant,
        path: MANAGER_ROUTES.ACTIONS_MERCHANT,
      },
    },
    MANAGE_RESERVATIONS: {
      //selectedBusinessTypes: [BUSINESS_TYPES.MERCHANT],
      feature: FEATURES.MANAGE_RESERVATIONS,
      //isEcommerceOrdering: true,
      menu: {
        icon: <ViewListIcon />,
        label: navigation.reservations,
      },
      routing: {
        Component: ManageReservations,
        path: MANAGER_ROUTES.MANAGE_RESERVATIONS,
      },
    },
    RESERVATIONS_CONFIG: {
      feature: FEATURES.RESERVATIONS_CONFIG,
      menu: {
        icon: <DateRange />,
        label: navigation.reservationsConfig,
      },
      routing: {
        Component: ReservationsConfig,
        path: MANAGER_ROUTES.RESERVATIONS_CONFIG,
      },
    },
    PUSH_NOTIFICATIONS: {
      feature: FEATURES.PUSH_NOTIFICATIONS,
      roles: ROLES.TENANT_MANAGER,
      menu: {
        icon: <NotificationImportant />,
        label: navigation.pushNotifications,
      },
      routing: {
        Component: PushNotifications,
        path: MANAGER_ROUTES.PUSH_NOTIFICATIONS,
      },
    },
  };

  const TREE = {
    [CONSUMER]: {
      [USER_STATUS.ANONYMOUS]: [
        PAGES.SIGN_IN,
        PAGES.SIGN_IN_EMAIL,
        PAGES.SIGN_UP,
        PAGES.PASSWORD_FORGET,
        PAGES.ENTRY,
        PAGES.PUBLIC_HOME,
        PAGES.SUBSCRIPTION_START,
        PAGES.FRIEND_CODE_LANDING,
        PAGES.CAMPAIGN,
        PAGES.CALENDAR,
        PAGES.PRICING,
        PAGES.STATIC,
        PAGES.ACTION,
        PAGES.RECEIVE_PRODUCT,
        PAGES.EKOM_LINK,
        PAGES.FAQ,
        PAGES.TEST,
        PAGES.MANAGER_SUBSCRIBE,
      ],

      [USER_STATUS.ACTIVATION]: [
        PAGES.ACTIVATION,
        PAGES.LOGOUT,
        PAGES.STATIC,
        PAGES.ACTION,
        PAGES.EKOM_LINK,
        PAGES.FAQ,
        PAGES.TEST,
      ],
      [USER_STATUS.LOGGED]: [
        PAGES.MY_LOCATION,
        PAGES.PROFILE,
        PAGES.ACCOUNT_LINK,
        PAGES.HOME_CONSUMER,
        PAGES.SUBSCRIBE,
        PAGES.SUBSCRIBE_PAYMENT_OUTCOME,
        PAGES.SUBSCRIBE_ERROR,
        PAGES.MY_QR,
        PAGES.MESSAGE,
        PAGES.MESSAGES,
        PAGES.CHECKIN,
        PAGES.FRIEND_CODE,
        PAGES.PLAY,
        PAGES.GO_TO_ADMIN_LINK,
        PAGES.ACCOUNT,
        PAGES.LOGOUT,
        PAGES.CAMPAIGN,
        PAGES.CALENDAR,
        PAGES.PRICING,
        PAGES.STATIC,
        PAGES.ACTION,
        PAGES.RECEIVE_PRODUCT,
        PAGES.EKOM_LINK,
        PAGES.TEST,
        PAGES.LCNG_FAQ,
        PAGES.LCNG_BUY,
        PAGES.LCNG_CONFIRM,
        PAGES.SCANNER_CONSUMER,
        PAGES.FAQ,
      ],
    },
    [MANAGER]: {
      [USER_STATUS.ANONYMOUS]: [
        PAGES.SIGN_IN,
        PAGES.SIGN_IN_EMAIL,
        PAGES.SIGN_UP,
        PAGES.PASSWORD_FORGET,
        PAGES.ENTRY,
        PAGES.PUBLIC_HOME_MANAGER,
        PAGES.STATIC,
        PAGES.FAQ,
        PAGES.MANAGER_SUBSCRIPTION_START,
        PAGES.MANAGER_SUBSCRIBE,
        PAGES.MANAGER_SUBSCRIBE_BUY,
      ],
      [USER_STATUS.ACTIVATION]: [
        PAGES.ACTIVATION,
        PAGES.STATIC,
        PAGES.LOGOUT,
        PAGES.FAQ,
        PAGES.MESSAGES_MANAGER,
        PAGES.MESSAGES_CAMPAIGN_CREATE,
        PAGES.MESSAGES_CAMPAIGN_EDIT,
        PAGES.MANAGER_SUBSCRIBE,
      ],
      [USER_STATUS.LOGGED]: [
        PAGES.HOME_MENU,
        PAGES.CAMPAIGNS_MANAGER,
        PAGES.EVENT_ACTION,
        PAGES.MESSAGES_MANAGER,
        PAGES.MESSAGES_CAMPAIGN_CREATE,
        PAGES.MESSAGES_CAMPAIGN_EDIT,
        PAGES.DASHBOARD,
        PAGES.CAMPAIGN_EVENTS,
        PAGES.CAMPAIGN_MANAGER,
        PAGES.CAMPAIGN_NOTIFICATIONS,
        PAGES.CAMPAIGN_CREATE,
        PAGES.CAMPAIGN_EDIT,
        PAGES.CAMPAIGN_CLONE,
        PAGES.RESERVATIONS,
        PAGES.SCANNER_MANAGER,
        PAGES.SEND_POINTS,
        PAGES.LCNG_STATS,
        PAGES.MANAGE_BUSINESSES,
        PAGES.MANAGE_MUSEUMS,
        PAGES.MANAGE_POI_LIST,
        PAGES.MANAGE_TOUR_LIST,
        PAGES.MANAGE_ORDERS,
        PAGES.ACTIONS_MERCHANT,
        PAGES.MANAGE_RESERVATIONS,
        PAGES.ORDER_CONFIG,
        PAGES.MANAGE_PRODUCTS,
        PAGES.GLOSSARY,
        PAGES.MANAGE_LOOPS,
        PAGES.MANAGE_LOCATIONS,
        PAGES.REPORTING,
        PAGES.REPORTING_ORDERS,
        PAGES.ADMIN_SUBSCRIPTIONS,
        PAGES.DISTRIBUTION_LISTS,
        PAGES.EMPLOYEE_ACCESS,
        PAGES.CONSENT_MANAGEMENT,
        PAGES.RESERVATIONS_CONFIG,
        PAGES.OPERATOR,
        PAGES.SCANNER_GDSP,
        PAGES.ASSISTANCE,
        PAGES.FAIR,
        PAGES.STATISTICS,
        PAGES.REVIEWS,
        PAGES.PUSH_NOTIFICATIONS,
        PAGES.SETTINGS_MANAGEMENT,
        PAGES.EXPORT,
        PAGES.MANAGE_SUBSCRIPTION,
        PAGES.VIEW_SUBSCRIPTIONS,
        PAGES.VOID_REQUEST,
        PAGES.MANAGE_PRIZES,
        PAGES.MANAGE_LOTS,
        PAGES.PRODUCTS_INVENTORY,
        PAGES.GO_TO_APP_LINK,
        PAGES.ACCOUNT_LINK,
        PAGES.ACCOUNT,
        PAGES.PROFILE,
        PAGES.POI,
        PAGES.EVENT_ACTION_CREATE,
        PAGES.DISTRIBUTION_LIST_CREATE,
        PAGES.DISTRIBUTION_LIST_EDIT,
        PAGES.CONSENT_MANAGEMENT_CREATE,
        PAGES.EPRODUCT_CATEGORY,
        PAGES.EPRODUCT,
        PAGES.ORDER,
        PAGES.STATIC,
        PAGES.LOGOUT,
        PAGES.FAQ,
        PAGES.TOUR,
        PAGES.USER_INSPECTION,
        PAGES.MANAGE_TEST,
        PAGES.SUBSCRIPTION_CREATE,
        //PAGES.MANAGER_SUBSCRIBE,
        PAGES.MANAGER_SUBSCRIBE_BUY,
        PAGES.MANAGER_SUBSCRIBE_PAYMENT_OUTCOME,
      ],
    },
  };
  let newPages = TREE[app][status].filter(canView);

  // adjust the manager home:
  // - if bmapi.museumsOnly and museum is selected => home = tours
  // - otherwise it is selected a visible page has page.home=true if it exists
  // - if no visible page has page.home=true, home = first visible page
  if (app === MANAGER) {
    let homePresent = false;
    let defaultHome;
    //const homeInSubscription =
    //  status === USER_STATUS.LOGGED &&
    //  isSubscriptionIncomplete(bmapi.subscription);
    const homeInTourList =
      !!bmapi.museumsOnly && !!bmapi.getUserInfo().business;
    console.log(".............", newPages);
    newPages = newPages.map((page) => {
      //if (homeInSubscription) {
      //  page = { ...page, home: page === PAGES.MANAGE_SUBSCRIPTION };
      //} else
      if (homeInTourList) {
        page = { ...page, home: page === PAGES.MANAGE_TOUR_LIST };
      }
      // check if there is at least an home page
      if (page.home) {
        homePresent = true;
      } else {
        if (!defaultHome && page.menu) {
          defaultHome = page;
        }
      }
      return page;
    });
    if (!homePresent && defaultHome) {
      defaultHome.home = true;
    }
  }

  // adjust the menu external links depending on the settings
  const externalMenuLinks = bmapi.settings.externalMenuLinks;
  //console.log("EXTERNAL MENU links present", externalMenuLinks);
  if (externalMenuLinks) {
    newPages = newPages.map((page) => {
      if (page.id) {
        const link = externalMenuLinks[page.id];
        if (link?.url) {
          const newUrl = bmapi.checkExternalUrl(link.url);
          const newPage = { ...page };
          if (page.menu) {
            newPage.menu.url = newUrl;
            newPage.menu.target = link.target;
          }
          if (page.routing) {
            newPage.routing.redirectUrl = newUrl;
          }
          console.log(
            "EXTERNAL MENU page id=" + page.id + " has url=" + link.url
          );
          return newPage;
        }
      }
      return page;
    });
  }

  console.log("pages.......", newPages, app, status);
  //console.log(".......", bmapi.canManageLoop(), bmapi.subscription);

  return newPages;
};
