import React from "react";

import { Fab } from "@material-ui/core";

import styles from "../utils/styles";

export function Action({ label, action, icon, ...props }) {
  const classes = styles.useStyles();

  return (
    <Fab
      key={label}
      variant="extended"
      color="primary"
      onClick={action}
      {...props}
    >
      {icon}
      {!!label && (
        <span className={classes.floatingActionsButtonLabel}>{label}</span>
      )}
    </Fab>
  );
}

export default function FloatingActions({ children }) {
  const classes = styles.useStyles();

  return (
    <React.Fragment>
      <div className={classes.floatingActionsBg}></div>
      <div className={classes.floatingActionsWrapper} data-fab>
        {children}
      </div>
    </React.Fragment>
  );
}
