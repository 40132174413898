import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  //Button,
  Container,
  FormControl,
  Link,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { Add as AddIcon, CenterFocusStrong } from "@material-ui/icons";
import CampaignsTable from "../../ui/CampaignsTable";
import FloatingActions, { Action } from "../../ui/FloatingActions";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { useCampaigns } from "../../utils/campaigns";
import { FEATURES, MANAGER_ROUTES } from "../../utils/constants";
import MessagesCampaignList from "./MessagesCampaignList";
import { common } from "../../messages";
import { DatePicker } from "../../ui/DatePicker";
import { startOfDay } from "date-fns";

function byBusinessName(a, b) {
  return a.business_name.localeCompare(b.business_name);
}

export default function MessagesCampaigns() {
  const { bmapi, businessId, language, startLoading, stopLoading } = useBmapi();
  const history = useHistory();
  const intl = useIntl();
  const { campaigns, loadCampaigns } = useCampaigns();
  const [currentStore, setCurrentStore] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const { campaignId } = useParams();
  const [campaignView /*setCampaignView*/] = useState(
    /*campaignId != undefined ? false :*/ true
  );
  const [stores, setStores] = useState(null);
  const [messages, setMessages] = useState([]);
  const [filters, setFilters] = useState({
    campaign_name: "",
    title: "",
    from: null,
    to: null,
  });

  const byParams = useCallback(
    (message) => {
      const matchCampaign =
        message.message.campaign_id === filters.campaign_name;

      const matchTitle =
        message.message.title.indexOf(filters.title.toLowerCase()) !== -1;

      const matchDate =
        startOfDay(new Date(message.message.expire_at)) >=
          startOfDay(filters.from || new Date("1/1/0001 00:00:00")) &&
        startOfDay(new Date(message.message.expire_at)) <=
          startOfDay(filters.to || new Date());

      if (
        filters.campaign_name &&
        filters.campaign_name !== "-" &&
        filters.title &&
        (filters.from || filters.to)
      ) {
        return matchCampaign && matchTitle && matchDate;
      }
      if (
        filters.campaign_name &&
        filters.campaign_name !== "-" &&
        filters.title
      ) {
        return matchCampaign && matchTitle;
      }
      if (
        filters.campaign_name &&
        filters.campaign_name !== "-" &&
        (filters.from || filters.to)
      ) {
        return matchCampaign && matchDate;
      }
      if (filters.title && (filters.from || filters.to)) {
        return matchTitle && matchDate;
      }
      if (filters.campaign_name && filters.campaign_name !== "-") {
        return matchCampaign;
      }
      if (filters.title) {
        return matchTitle;
      }
      if (filters.from || filters.to) {
        return matchDate;
      }
      return message;
    },
    [filters]
  );

  const selectStore = (id) => {
    setCurrentStore(id);
    setAnchorEl(null);
  };

  /*const goToCampaign = () => {
    setCampaignView(!campaignView);
    history.push(
      MANAGER_ROUTES.MESSAGES_CAMPAIGNS.replace(":campaignId?/", "")
    );
  };*/

  const handleFilters = useCallback((label) => {
    const updateValue = (val) => {
      setFilters((v) => ({ ...v, [label]: val }));
    };

    return (i, f) => {
      if (typeof f === "boolean") updateValue(f);
      else if (i?.target) updateValue(i.target.value);
      else updateValue(i);
    };
  }, []);

  useEffect(() => {
    !campaigns ? startLoading() : stopLoading();
  }, [campaigns, startLoading, stopLoading]);

  useEffect(() => {
    if (campaigns) {
      setStores(
        Object.entries(
          campaigns.sort(byBusinessName).reduce(
            (acc, e) => ({
              ...acc,
              [e.business_owner_id]: e.business_owner_name,
            }),
            {}
          )
        )
      );
    }
  }, [campaigns]);

  const loadMessages = useCallback(() => {
    startLoading();
    bmapi.getMessagesCampaign().then((messages) => {
      setMessages(messages == null ? [] : messages.filter(byParams));
      loadCampaigns();
      stopLoading();
    });
  }, [loadCampaigns, bmapi, startLoading, stopLoading, byParams]);

  useEffect(() => {
    loadMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const create = () => {
    history.push(MANAGER_ROUTES.CREATE_MESSAGE_CAMPAIGN);
  };

  const createLink = (str) => {
    const url =
      language === "it"
        ? "https://liberacta.com/demo-e-tutorial"
        : "https://liberacta.com/en/demo-tutorial/";
    return (
      <Link href={url} target="_blank" key={url}>
        {str}
      </Link>
    );
  };

  return !businessId ? (
    <Container maxWidth="md" /*className="tablistNone"*/>
      <Typography variant="body1" gutterBottom>
        <FormattedMessage
          id="pages.dashboard.emptyMessage"
          defaultMessage="Non ci sono negozi associati al tuo indirizzo email."
        />
      </Typography>
      <Typography variant="body1" gutterBottom>
        <FormattedMessage
          id="pages.dashboard.emptySolution"
          defaultMessage="Contatta l'amministratore per risolvere il problema."
        />
      </Typography>
    </Container>
  ) : campaigns ? (
    <Container maxWidth="xl" /*className="tablistNone"*/>
      <Title>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box>
            <FormattedMessage
              id="pages.messageCampaigns.title"
              defaultMessage="Messaggi Campagne"
            />
          </Box>
          {/* 
          {campaigns.length > 0 && (
            <Button
              onClick={() =>
                !campaignView ? goToCampaign() : setCampaignView(!campaignView)
              }
              color={currentStore ? "primary" : "inherit"}
            >
              <FormattedMessage
                id="common.filters"
                defaultMessage={
                  !campaignView ? "Lista Messaggi" : "Mostra Campagne"
                }
              />
            </Button>
          )}
          */}
          <Menu
            anchorEl={anchorEl}
            keepMounted={false}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={() => selectStore(0)}>
              <FormattedMessage
                id="common.allStores"
                defaultMessage="Tutti i negozi"
              />
            </MenuItem>
            {stores?.map((option) => (
              <MenuItem
                key={option[0]}
                onClick={() => selectStore(option[0])}
                selected={option[0] === currentStore}
              >
                {option[1]}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Title>
      <Container maxWidth="md">
        <Box mb={2}>
          <Card>
            <CardContent>
              <Box
                display="flex"
                flexDirection="column"
                style={{ width: "50%" }}
              >
                <TextField
                  style={{ minWidth: "50%" }}
                  select
                  label="Filtra per campagna"
                  value={filters.campaign_name}
                  onChange={handleFilters("campaign_name")}
                >
                  {[
                    { campaign_data: { id: "-" }, name: "Nessuna campagna" },
                    ...campaigns,
                  ].map((option) => (
                    <MenuItem
                      key={option.campaign_data.id}
                      value={option.campaign_data.id}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  label="Filtra per titolo"
                  value={filters.title}
                  onChange={handleFilters("title")}
                  margin="normal"
                />
              </Box>
              <Box
                display="flex"
                flexWrap="wrap"
                justifyContent="space-between"
                style={{ width: "50%" }}
              >
                <DatePicker
                  label={intl.formatMessage(common.filterFrom)}
                  value={filters.from}
                  onChange={handleFilters("from")}
                  margin="dense"
                  clearable
                />
                <DatePicker
                  label={intl.formatMessage(common.filterTo)}
                  value={filters.to}
                  onChange={handleFilters("to")}
                  margin="dense"
                  clearable
                />
              </Box>
              <Box mt={2}>
                <FormControl style={{ float: "right" }} margin="normal">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={loadMessages}
                  >
                    {intl.formatMessage(common.update)}
                  </Button>
                </FormControl>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Container>
      {!campaignView && (
        <CampaignsTable
          campaigns={campaigns.filter(
            (c) => !currentStore || c.business_owner_id === currentStore
          )}
          messages={messages}
          loadCampaigns={loadCampaigns}
        />
      )}
      {campaignView && (
        <MessagesCampaignList
          campaignId={campaignId}
          campaigns={campaigns}
          messages={messages}
        />
      )}
      <Box mt={3}>
        <Typography>
          {intl.formatMessage(
            {
              id: "page.campaigns.campaignsInfo",
              defaultMessage:
                "Per saperne di più sulle campagne, consulta i tutorial <link>qui</link>",
            },
            {
              link: createLink,
            }
          )}
        </Typography>
      </Box>
      <FloatingActions>
        {bmapi.can(FEATURES.MANAGE_CAMPAIGN) && (
          <Action
            icon={<AddIcon />}
            label={intl.formatMessage({
              id: "page.campaigns.createCampaigns",
              defaultMessage: "Crea messaggio campagna",
            })}
            action={create}
          />
        )}
        {!bmapi.isTenantManager() &&
          bmapi.can(FEATURES.SCAN_QR) &&
          campaigns.some((c) => c.isActive) && (
            <Action
              icon={<CenterFocusStrong />}
              style={
                bmapi.can(FEATURES.MANAGE_CAMPAIGN) ? { marginLeft: 10 } : {}
              }
              label={
                bmapi.can(FEATURES.MANAGE_CAMPAIGN)
                  ? ""
                  : intl.formatMessage({
                      id: "common.scan",
                      defaultMessage: "Scansiona",
                    })
              }
              action={() => history.push(MANAGER_ROUTES.SCAN)}
            />
          )}
      </FloatingActions>
    </Container>
  ) : null;
}
