import React from "react";
import { useIntl } from "react-intl";

import { FormControl, FormControlLabel, Switch } from "@material-ui/core";

import { form } from "../../../messages";
import { PRODUCTS } from "../../../utils/constants";

export default function PrimaryProductMandatory({ values, handleChange }) {
  const intl = useIntl();

  const onChange = (_, v) => {
    handleChange("primary_product_not_mandatory")(!v);
  };

  return (
    [PRODUCTS.EVENT_PASS].includes(values.front_end_type) && (
      <FormControl margin="normal">
        <FormControlLabel
          control={
            <Switch
              checked={!values.primary_product_not_mandatory}
              color="primary"
              onChange={onChange}
              key="primary_product_not_mandatory"
              name="primary_product_not_mandatory"
            />
          }
          label={intl.formatMessage(form.primary_product_mandatory)}
        />
      </FormControl>
    )
  );
}
