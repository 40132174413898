import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { common, form } from "../../messages";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import { Refresh, CropFree } from "@material-ui/icons";
import QRCodeGenerator from "qrcode";
import QRCodeDialog from "../../ui/QRCodeDialog";
import FormSection from "../../ui/forms/input/FormSection";

const emptyValues = {
  play_campaign_id: "",
  test_users: [],
  play_qrcode_version: "",
  play_qrcode_template: "",
  test_products: [],
};

export default function ManageTest() {
  const intl = useIntl();
  const { bmapi, notifyError, notifySuccess } = useBmapi();

  const [values, setValues] = useState(emptyValues);
  const [tpCache, setTpCache] = useState({});
  const [code, setCode] = useState("");
  const [campaigns, setCampaigns] = useState([]);

  const loadTestConfig = useCallback(() => {
    bmapi
      .getTenantTestConfig()
      .then((resp) => {
        if (!resp) {
          setValues(emptyValues);
        } else {
          const newValues = { ...resp };
          setValues(newValues);
          _checkTestProducts(newValues["test_products"]);
        }
      })
      .catch((e) => {
        if (e + "" === "ApiError: Entity not found") {
          setValues(emptyValues);
        } else {
          notifyError(getErrorMessageString(e, intl));
        }
      });
  }, [bmapi, intl, notifyError, _checkTestProducts]);

  useEffect(() => {
    const readCampaigns = async () => {
      let list = await bmapi.getTenantCampaigns();
      list = list.filter((c) => c.type === "CAMPAIGN_MULTIWIN");
      console.log(list);
      setCampaigns(list);
    };
    readCampaigns();
  }, [bmapi]);

  useEffect(() => {
    loadTestConfig();
  }, [loadTestConfig]);

  const handleValue = useCallback(
    (varName) => (e) => {
      if (varName === "forcewin") {
        ((val) => setValues((v) => ({ ...v, [varName]: val })))(
          e.target.checked
        );
        /*} else if (varName === "play_campaign_id") {
        ((val) =>
          setValues((v) => {
            console.log("ddddddddddddd", val);
            return { ...v, [varName]: val?.id };
          }))(e);*/
      } else {
        ((val) => setValues((v) => ({ ...v, [varName]: val })))(e.target.value);
      }
    },
    []
  );

  const handleArray = useCallback(
    (varName, index) => (e) => {
      ((val) =>
        setValues((v) => {
          const newVal = [...v[varName]];
          newVal[index] = val;
          return { ...v, [varName]: newVal };
        }))(e.target.value);
    },
    []
  );

  const addElement = useCallback(
    (varName) => () => {
      setValues((v) => {
        let newVal = v[varName] || [];
        newVal = [...newVal, ""];
        return { ...v, [varName]: newVal };
      });
    },
    []
  );

  const removeElement = useCallback(
    (varName, index) => () => {
      setValues((v) => {
        let newVal = [...v[varName]];
        newVal.splice(index, 1);
        return { ...v, [varName]: newVal };
      });
    },
    []
  );

  const showTpStatus = (tp) => {
    const p = tpCache[tp];
    if (!p) return "";
    return `batch: ${p.batch_id}, status: ${p.status}, played: ${p.play_date} ${p.play_result}`;
  };
  /*
  const canRefreshTp = (tp) => {
    const p = tpCache[tp];
    if (!p) return false;
    return !!p.status;
  };*/

  const _checkTestProducts = useCallback(
    async (tpValues) => {
      if (!tpValues) return;
      const newTpCache = {};
      for (let tp of tpValues) {
        if (tpCache[tp]) {
          newTpCache[tp] = tpCache[tp];
        } else {
          try {
            const resp = await bmapi.getProduct(tp);
            newTpCache[tp] = resp;
          } catch (e) {
            newTpCache[tp] = "ND";
          }
        }
      }
      setTpCache(newTpCache);
    },
    [tpCache, bmapi]
  );

  const checkTestProducts = useCallback(() => {
    console.log("ee");
    const arr = values["test_products"];
    _checkTestProducts(arr);
  }, [values, _checkTestProducts]);

  const confirmTestConfig = (event) => {
    event.preventDefault();
    let testConfig = {
      ...values,
    };
    bmapi
      .updateTenantTestConfig(testConfig)
      .then(() => {
        notifySuccess("Dati salvati con successo");
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
  };

  const handleShowQR = (tp) => () => {
    const qrcode = values.play_qrcode_template
      ? values.play_qrcode_template.replace("$$$", tp)
      : tp;
    QRCodeGenerator.toDataURL(qrcode, { scale: 8 })
      .then(setCode)
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  };

  const cleanElement = (tp) => async () => {
    try {
      await bmapi.cleanPlayByProduct(tp);
      notifySuccess("Bollino ripristinato");
      checkTestProducts();
    } catch (e) {
      notifyError(getErrorMessageString(e, intl));
    }
  };

  const cleanUser = (tp) => async () => {
    try {
      await bmapi.cleanTodayPlays(tp);
      notifySuccess("Giocate giornaliere e green ripristinate");
    } catch (e) {
      notifyError(getErrorMessageString(e, intl));
    }
  };

  return (
    <Container maxWidth="sm">
      <Title>Configurazione Test</Title>

      <form onSubmit={confirmTestConfig}>
        <Box mb={2}>
          <Card>
            <CardContent>
              <Box>
                <FormSection>
                  <TextField
                    select
                    label="Campagna multiwin"
                    value={values["play_campaign_id"]}
                    onChange={handleValue("play_campaign_id")}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  >
                    {campaigns?.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    name="play_qrcode_template"
                    label="Template QR code"
                    value={values["play_qrcode_template"]}
                    onChange={handleValue("play_qrcode_template")}
                    fullWidth
                    margin="normal"
                  />
                </FormSection>
              </Box>
              <FormSection>
                <FormLabel>Utenti di test</FormLabel>
                <IconButton onClick={addElement("test_users")}>
                  <Add />
                </IconButton>
                {values?.test_users?.map((tu, i) => (
                  <Grid container key={"test_users" + i}>
                    <Grid item xs={10}>
                      <TextField
                        name="test_users"
                        label="Utente di test"
                        value={tu}
                        onChange={handleArray("test_users", i)}
                        fullWidth
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton onClick={removeElement("test_users", i)}>
                        <Delete />
                      </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton onClick={cleanUser(tu)}>
                        <Refresh />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              </FormSection>

              <FormSection>
                <FormLabel>Bollini di test</FormLabel>

                <IconButton onClick={addElement("test_products")}>
                  <Add />
                </IconButton>
                {values?.test_products?.map((tu, i) => (
                  <Grid container key={"test_products" + i}>
                    <Grid item xs={10}>
                      <TextField
                        name="test_products"
                        label="ID bollino"
                        value={tu}
                        onChange={handleArray("test_products", i)}
                        fullWidth
                        margin="normal"
                        onBlur={checkTestProducts}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton onClick={removeElement("test_products", i)}>
                        <Delete />
                      </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton onClick={handleShowQR(tu)}>
                        <CropFree />
                      </IconButton>
                    </Grid>
                    <Grid item xs={10}>
                      {showTpStatus(values["test_products"][i])}
                    </Grid>
                    <Grid item xs={2}>
                      {/*canRefreshTp(values["test_products"][i]) && ( */}
                      <IconButton onClick={cleanElement(tu)}>
                        <Refresh />
                      </IconButton>
                      {/*) */}
                    </Grid>
                  </Grid>
                ))}
              </FormSection>
            </CardContent>
          </Card>
        </Box>

        <CardActions>
          <Button type="submit" variant="contained" color="primary">
            {intl.formatMessage(common.update)}
          </Button>
        </CardActions>
      </form>

      <QRCodeDialog code={code} setCode={setCode} itemTitle="Bollino" />
    </Container>
  );
}
