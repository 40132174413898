import { defineMessages } from "react-intl";

export const settings = defineMessages({
  //task
  dateTimeError: {
    id: "settings.dateTimeError",
    defaultMessage: "Data/ora",
  },
  errorsCount: {
    id: "settings.errorsCount",
    defaultMessage: "Num errori",
  },
  executing: {
    id: "settings.executing",
    defaultMessage: "Esecuzione in corso",
  },
  errorId: {
    id: "settings.errorId",
    defaultMessage: "ID",
  },
  name: {
    id: "settings.name",
    defaultMessage: "Nome",
  },
  payload: {
    id: "settings.payload",
    defaultMessage: "Input",
  },
  processRef: {
    id: "settings.processRef",
    defaultMessage: "Rif",
  },
  processType: {
    id: "settings.processType",
    defaultMessage: "Tipo",
  },
  queueStatus: {
    id: "settings.queueStatus",
    defaultMessage: "Stato coda",
  },
  results: {
    id: "settings.results",
    defaultMessage: "Output",
  },
  showErrors: {
    id: "settings.showErrors",
    defaultMessage: "Mostra errori",
  },
  status: {
    id: "settings.status",
    defaultMessage: "Stato",
  },
  taskErrors: {
    id: "settings.taskErrors",
    defaultMessage: "Errori",
  },
  taskMonitoring: {
    id: "settings.taskMonitoring",
    defaultMessage: "Monitoraggio task",
  },
  taskQuantity: {
    id: "settings.taskQuantity",
    defaultMessage: "Num task",
  },
});
