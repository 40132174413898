import React from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  Avatar,
  Card,
  CardActionArea,
  SvgIcon,
  Typography,
} from "@material-ui/core";

import styles from "../utils/styles";
import Grid from "../ui/Grid";

export default function MenuButton({ items }) {
  const classes = styles.useStyles();

  return (
    !!items.length && (
      <Grid
        menu
        items={items}
        render={(item) => (
          <Card style={{ height: "180px" }}>
            <CardActionArea
              component={RouterLink}
              to={item.url}
              className={
                items.length > 9 ? classes.buttonMenuSmall : classes.buttonMenu
              }
              style={{ height: "100%" }}
            >
              <Avatar className={classes.menuIcon}>
                <SvgIcon style={{ fontSize: "230%" }}>{item.icon}</SvgIcon>
              </Avatar>
              <Typography
                align="center"
                variant="h6"
                style={{ color: "white", marginTop: items.length > 9 ? 0 : 10 }}
              >
                {item.label.defaultMessage}
              </Typography>
            </CardActionArea>
          </Card>
        )}
      />
    )
  );
}
