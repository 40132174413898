import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  Fab,
  FormControl,
  FormControlLabel,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Title from "../../ui/Title";
import { parseBmarkenDate } from "../../utils/utils";
import { addDays, addMonths, format } from "date-fns";
import FloatingActions from "../../ui/FloatingActions";
import QrReader from "react-qr-reader";
import { ArrowBack, CenterFocusStrong, Error } from "@material-ui/icons";
import { account, common, confirm, errors, form } from "../../messages";
import { useIntl } from "react-intl";
import { useBmapi } from "../../utils/bmapi-context";
import AccessDialog from "../../ui/AccessDialog";
import Confirm from "../../ui/Confirm";
import { BUSINESS_TYPES, MANAGER_ROUTES, ROLES } from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";
import { employeeAccess } from "../../messages/employeeAccess";
import { Link as RouterLink } from "react-router-dom";

const byDate = (a, b) => {
  return new Date(a.expiring_at) - new Date(b.expiring_at);
};

const warningExpDate = (date) => {
  if (parseBmarkenDate(date) === null) return false;
  const warning = format(addDays(parseBmarkenDate(date), -10), "yyyy-MM-dd");
  const today = format(new Date(), "yyyy-MM-dd");
  return today > warning;
};

const checkExpDate = (date) => {
  if (parseBmarkenDate(date) === null) return false;
  const expired = format(parseBmarkenDate(date), "yyyy-MM-dd");
  const today = format(new Date(), "yyyy-MM-dd");
  return today > expired;
};

const getDaysLeft = (date) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  return Math.round(Math.abs((parseBmarkenDate(date) - new Date()) / oneDay));
};

function Warning({ exp }) {
  const intl = useIntl();

  return (
    <Tooltip
      disableFocusListener
      title={
        checkExpDate(exp)
          ? intl.formatMessage(employeeAccess.accessExpired)
          : intl.formatMessage(employeeAccess.expDateWarning, {
              days: getDaysLeft(exp),
            })
      }
    >
      <IconButton
        style={{
          color: checkExpDate(exp) ? "red" : "#ffb600",
          backgroundColor: "transparent",
          padding: "0 2px",
        }}
      >
        <Error />
      </IconButton>
    </Tooltip>
  );
}

function Report({
  registrations,
  businesses,
  handleDelete,
  isTenantManager,
  showDeleteAlert,
  setShowDeleteAlert,
  selectedRegs,
  setSelectedRegs,
  authorizeAccess,
  selection,
  setSelection,
}) {
  const intl = useIntl();

  const onSelect = (reg, flag) => {
    setSelection((sel) =>
      flag ? [...sel, reg] : sel.filter((r) => r !== reg)
    );
  };

  const selectAll = () => {
    setSelection(
      selection.length === registrations.length
        ? []
        : registrations.map((r) => r)
    );
  };

  const handleChange = (e, reg) => {
    onSelect(reg, e.target.checked);
  };

  const businessId = [...new Set(registrations?.map((r) => r.business_id))];

  const bsRegistrations = (id) => {
    return registrations.filter((reg) => reg.business_id === id);
  };

  const handleCancel = () => {
    setShowDeleteAlert(false);
    setSelectedRegs(false);
  };

  return (
    <React.Fragment>
      <Box mb={2} mt={3} display="flex" justifyContent="center">
        <Button
          variant="contained"
          style={{ marginRight: 15 }}
          onClick={selectAll}
        >
          {selection.length === registrations.length
            ? intl.formatMessage(form.deselectAll)
            : intl.formatMessage(form.selectAll)}
        </Button>
        {isTenantManager && (
          <Button
            variant="contained"
            style={{ marginRight: 15 }}
            disabled={selection.length === 0}
            onClick={() => {
              authorizeAccess(selection);
            }}
          >
            {intl.formatMessage(employeeAccess.enableSelected)}
          </Button>
        )}
        <Button
          variant="contained"
          disabled={selection.length === 0}
          onClick={() => {
            setShowDeleteAlert(true);
            setSelectedRegs(selection);
          }}
        >
          {intl.formatMessage(form.deleteSelected)}
        </Button>
      </Box>

      {businessId.map((id) => (
        <Box mb={2} key={id}>
          <Card>
            <CardContent>
              <Box>
                {isTenantManager && (
                  <Typography variant="h6">
                    {`${
                      businesses.find((b) => b.id === id).name
                    } - ${intl.formatMessage(employeeAccess.enableTot, {
                      tot: bsRegistrations(id).filter(
                        (r) => r.status === "ACTIVE"
                      ).length,
                    })}`}
                  </Typography>
                )}
              </Box>
            </CardContent>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      {intl.formatMessage(employeeAccess.accessDate)}
                    </TableCell>
                    <TableCell>
                      {intl.formatMessage(employeeAccess.expDate)}
                    </TableCell>
                    {isTenantManager && (
                      <React.Fragment>
                        <TableCell>
                          {intl.formatMessage(form.completeName)}
                        </TableCell>
                        <TableCell>
                          {intl.formatMessage(common.email)}
                        </TableCell>
                      </React.Fragment>
                    )}
                    <TableCell>
                      {intl.formatMessage(employeeAccess.access)}
                    </TableCell>
                  </TableRow>
                </TableHead>
                {bsRegistrations(id)
                  .sort(byDate)
                  .map((r) => (
                    <TableBody key={r.id}>
                      <TableRow>
                        <TableCell>
                          <Checkbox
                            edge="start"
                            checked={selection.includes(r)}
                            onChange={(e) => handleChange(e, r)}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell>
                          {format(parseBmarkenDate(r.created_at), "dd/MM/yy")}
                        </TableCell>
                        <TableCell>
                          <div style={{ display: "flex" }}>
                            {!parseBmarkenDate(r.expiring_at)
                              ? intl.formatMessage(employeeAccess.noExpDate)
                              : format(
                                  parseBmarkenDate(r.expiring_at),
                                  "dd/MM/yy"
                                )}
                            {warningExpDate(r.expiring_at) && (
                              <Warning exp={r.expiring_at} />
                            )}
                          </div>
                        </TableCell>
                        {isTenantManager && (
                          <React.Fragment>
                            <TableCell>
                              {`${r.first_name} ${r.last_name}`}
                            </TableCell>
                            <TableCell>{r.email}</TableCell>
                          </React.Fragment>
                        )}
                        <TableCell>
                          <Checkbox
                            checked={r.status === "ACTIVE"}
                            disabled
                            style={{
                              color: r.status === "ACTIVE" ? "#ffb600" : "",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
              </Table>
            </TableContainer>
          </Card>
        </Box>
      ))}
      {showDeleteAlert && (
        <Confirm
          open={!!showDeleteAlert}
          onConfirm={() => {
            handleDelete(selectedRegs);
            handleCancel();
          }}
          onCancel={handleCancel}
          text={
            selectedRegs.length < 2
              ? intl.formatMessage(confirm.deleteElement)
              : intl.formatMessage(confirm.deleteElements, {
                  num: selectedRegs.length,
                })
          }
          flag
        />
      )}
    </React.Fragment>
  );
}

export default function EmployeeAccess() {
  const intl = useIntl();
  const {
    notifyError,
    bmapi,
    businesses,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  const [open, setOpen] = useState(false);
  const initialValues = {
    value: false,
    type: "",
    accesses: [],
  };
  const [values, setValues] = useState(initialValues);
  const [registrations, setRegistrations] = useState([]);
  const [selection, setSelection] = useState([]);
  const [storedCode, setStoredCode] = useState(null);
  const [email, setEmail] = useState(null);
  const [showNonAuth, setShowNonAuth] = useState(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [selectedRegs, setSelectedRegs] = useState(false);
  const [name, setName] = useState("");
  const isTenantManager = bmapi.getUserInfo().role === ROLES.TENANT_MANAGER;
  const selectedStoreId = bmapi.getUserInfo().business.id;

  const bsLoop = () => {
    return businesses
      .filter((b) => b.type === BUSINESS_TYPES.LOOP)
      .find((b) => b.id === selectedStoreId);
  };

  const reset = () => {
    setStoredCode(null);
    setValues(initialValues);
  };

  const byParams = useCallback(
    (user) => {
      if (showNonAuth && email && name) {
        return (
          user.status === "NONAUTH" &&
          user.email.indexOf(email.trim()) !== -1 &&
          user.complete_name.indexOf(name.toLowerCase()) !== -1
        );
      }
      if (email && showNonAuth) {
        return (
          user.status === "NONAUTH" && user.email.indexOf(email.trim()) !== -1
        );
      }
      if (name && showNonAuth) {
        return (
          user.status === "NONAUTH" &&
          user.complete_name.indexOf(name.toLowerCase()) !== -1
        );
      }
      if (email && name) {
        return (
          user.email.indexOf(email.trim()) !== -1 &&
          user.complete_name.indexOf(name.toLowerCase()) !== -1
        );
      }
      if (email) {
        return user.email.indexOf(email.trim()) !== -1;
      }
      if (name) {
        return user.complete_name.indexOf(name.toLowerCase()) !== -1;
      }
      if (showNonAuth) {
        return user.status === "NONAUTH";
      }
      return user;
    },
    [email, showNonAuth, name]
  );

  const loadRegistrations = useCallback(() => {
    startLoading();

    const activeRegistrationsPromise = bmapi
      .getRegistrations(
        {
          business: selectedStoreId,
          type: "access",
        },
        { status: "ACTIVE" }
      )
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });

    const allRegistrationsPromise = bmapi
      .getRegistrations(
        {
          business: selectedStoreId,
          type: "access",
        },
        { status: "NONAUTH" }
      )
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });

    Promise.all([activeRegistrationsPromise, allRegistrationsPromise])
      .then(([activeRegistrations, allRegistrations]) => {
        const combinedRegistrations = [
          ...(activeRegistrations || []),
          ...(allRegistrations || []),
        ];
        const newCombinedRegistrations = combinedRegistrations.map((r) => ({
          ...r,
          complete_name: `${r.first_name.toLowerCase()} ${r.last_name.toLowerCase()}`,
        }));
        setRegistrations(newCombinedRegistrations.filter(byParams));
      })
      .finally(stopLoading);
  }, [
    bmapi,
    intl,
    notifyError,
    startLoading,
    stopLoading,
    selectedStoreId,
    byParams,
  ]);

  useEffect(() => {
    loadRegistrations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReset = () => {
    setValues({ ...values, value: false });
    setStoredCode(null);
    setSelectedRegs(false);
    setSelection([]);
  };

  const handleError = (err) => {
    notifyError(err || intl.formatMessage(errors.error));
    setStoredCode(null);
  };

  const handleScan = (code) => {
    if (code && code !== storedCode) {
      setStoredCode(code);
      const qrCode = { qrcode: code };
      bmapi
        .accessRegistration(qrCode, selectedStoreId)
        // apiPost -> http Code 200 (then)
        .then((resp) => {
          setValues({ ...values, accesses: resp, value: true, type: "access" });
        })
        // apiPost -> http Code 404 (catch)
        .catch((e) => {
          if (e.httpCode === 404) {
            if (bsLoop()) {
              notifyError(intl.formatMessage(employeeAccess.errorBusiness));
              setStoredCode(null);
            } else {
              setValues({ ...values, value: true, type: "confirm" });
            }
          } else if (e.httpCode === 400) {
            notifyError(intl.formatMessage(employeeAccess.errorUser));
            setStoredCode(null);
          } else {
            notifyError(getErrorMessageString(e, intl));
          }
        });
      setOpen(false);
    }
  };

  const confirmRequestAuthotization = (date) => {
    const code = { qrcode: storedCode, expiring_at: date };
    bmapi
      .createEmployeeAccess(code, selectedStoreId)
      .then(() => {
        notifySuccess(intl.formatMessage(account.saveConfirm));
        loadRegistrations();
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
    handleReset();
  };

  const handleDelete = (regs, disable) => {
    if (!disable) {
      regs.map((r) =>
        bmapi
          .deleteEmployeeAccess(selectedStoreId, r.id)
          .then(() => {
            notifySuccess(intl.formatMessage(common.deleteElement));
            loadRegistrations();
            setShowDeleteAlert(false);
          })
          .catch((e) => {
            notifyError(getErrorMessageString(e, intl));
          })
      );
    } else {
      bmapi
        .deleteEmployeeAccess(selectedStoreId, regs.id)
        .then(() => {
          notifySuccess(intl.formatMessage(common.deleteElement));
          loadRegistrations();
          setShowDeleteAlert(false);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
    handleReset();
  };

  const requestAuthorization = (date) => {
    if (typeof date === "number" || typeof date === "string") {
      date = format(addMonths(new Date(), +date), "yyyy-MM-dd");
    } else {
      date = format(date, "yyyy-MM-dd");
    }
    confirmRequestAuthotization(date);
  };

  const authorizeAccess = (regs) => {
    const newRegs = regs
      .filter((reg) => reg.status !== "ACTIVE")
      .map((r) => r.id);
    bmapi
      .authorizeEmployeeAccess(newRegs, "ACTIVE")
      .then(() => {
        notifySuccess(intl.formatMessage(account.saveConfirm));
        loadRegistrations();
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
    handleReset();
  };

  const authRegs = registrations.filter((reg) => reg.status === "ACTIVE");

  return (
    <Container maxWidth="lg">
      <Title>
        {intl.formatMessage(employeeAccess.employeeAccessAuthorization, {
          tot: authRegs.length,
        })}
      </Title>
      <Box mb={2} mt={2}>
        <Typography gutterBottom variant="body2">
          {intl.formatMessage(employeeAccess.insertAuthorization)}
        </Typography>
        <Typography gutterBottom variant="body2">
          {isTenantManager
            ? intl.formatMessage(employeeAccess.deleteAuthorization)
            : intl.formatMessage(employeeAccess.deleteAuthorizationMerchant)}
        </Typography>
      </Box>

      <React.Fragment>
        <Container maxWidth="md">
          <Card>
            <CardContent>
              {isTenantManager && (
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ width: "50%" }}
                >
                  <TextField
                    name="email"
                    label={intl.formatMessage(common.filterEmail)}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <TextField
                    margin="normal"
                    name="name"
                    label={intl.formatMessage(common.name)}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Box>
              )}
              <FormControl margin={isTenantManager ? "normal" : ""}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showNonAuth}
                      onChange={(e) => {
                        setShowNonAuth(e.target.checked);
                      }}
                      name="checkedA"
                      color="primary"
                    />
                  }
                  label={intl.formatMessage(employeeAccess.toAuthorizeOnly)}
                />
              </FormControl>
            </CardContent>
            <CardActions style={{ float: "right", display: "block" }}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={loadRegistrations}
              >
                {intl.formatMessage(common.search)}
              </Button>
            </CardActions>
          </Card>
          <Box mt={2}>
            <Button
              component={RouterLink}
              to={MANAGER_ROUTES.HOME}
              startIcon={<ArrowBack />}
            >
              {intl.formatMessage(common.backHome)}
            </Button>
          </Box>
        </Container>

        <Box mt={2}>
          {registrations.length === 0 ? (
            <Card>
              <CardContent>
                {intl.formatMessage(employeeAccess.noRegistrations)}
              </CardContent>
            </Card>
          ) : (
            <Report
              registrations={registrations}
              businesses={businesses}
              handleDelete={handleDelete}
              isTenantManager={isTenantManager}
              showDeleteAlert={showDeleteAlert}
              setShowDeleteAlert={setShowDeleteAlert}
              selectedRegs={selectedRegs}
              setSelectedRegs={setSelectedRegs}
              authorizeAccess={authorizeAccess}
              selection={selection}
              setSelection={setSelection}
            />
          )}
        </Box>
      </React.Fragment>

      {open && (
        <Dialog
          onClose={() => {
            setOpen(false), setStoredCode(null);
          }}
          open={open}
          fullWidth
        >
          <QrReader onError={handleError} onScan={handleScan} />
          <DialogActions margin="dense">
            <Button
              variant="contained"
              onClick={() => {
                setOpen(false);
                setStoredCode(null);
              }}
            >
              {intl.formatMessage(common.close)}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <AccessDialog
        values={values}
        businesses={businesses}
        requestAuthorization={requestAuthorization}
        handleDelete={handleDelete}
        reset={reset}
      />
      <FloatingActions>
        <Fab variant="extended" color="primary" onClick={() => setOpen(true)}>
          {intl.formatMessage({
            id: "common.scan",
            defaultMessage: "Scansiona",
          })}
          <CenterFocusStrong />
        </Fab>
      </FloatingActions>
    </Container>
  );
}
