import React, { useEffect, useState } from "react";

import { Link, Tooltip, Typography } from "@material-ui/core";

import { useBmapi } from "../utils/bmapi-context";

function Info({ word }) {
  const [meaning, setMeaning] = useState(false);
  const { bmapi } = useBmapi();

  useEffect(() => {
    bmapi.getTassonomy(word).then(setMeaning).catch(console.error);
  }, [bmapi, word]);

  return meaning ? (
    <Tooltip title={meaning.text} placement="top">
      <Link>{word}</Link>
    </Tooltip>
  ) : (
    word
  );
}

export default function RichText({ text }) {
  return text.split("\n").map(
    (item, key, arr) =>
      item && (
        <Typography variant="body2" key={key} gutterBottom={!arr[key + 1]}>
          {item.split(/\s/).map((word, i) => (
            <React.Fragment key={i}>
              {word.startsWith("http") ? (
                <Link href={word}>{word}</Link>
              ) : word.startsWith("[[") && word.endsWith("]]") ? (
                <Info word={word.slice(2, -2)} />
              ) : (
                word
              )}{" "}
            </React.Fragment>
          ))}
        </Typography>
      )
  );
}
