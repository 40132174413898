import React from "react";
import Markdown from "markdown-to-jsx";
import DOMPurify from "dompurify";

const SafeMarkdownView = ({ content }) => {
  // Sanitize the markdown content
  const cleanContent = DOMPurify.sanitize(content);

  return <Markdown style={{ with: "100%" }}>{cleanContent}</Markdown>;
};

export default SafeMarkdownView;
