import React from "react";
import { useIntl } from "react-intl";

import NumberInput from "../../NumberInput";
import { form } from "../../../messages";
import { PRODUCT_TYPES } from "../../../utils/constants";

export default function UseFrequency({
  values,
  handleChange,
  campaign,
  setError,
  performance,
}) {
  const intl = useIntl();

  const checkUseFrequency = () => {
    let changeUses;
    if (+values.use_period === -7) {
      changeUses = +values.use_frequency * 4;
    } else if (+values.use_period === -1) {
      changeUses = +values.use_frequency * 30;
    } else {
      changeUses = +values.use_frequency;
    }
    setError(changeUses < campaign.use_frequency_per_month);

    return changeUses < campaign.use_frequency_per_month;
  };

  return (
    [PRODUCT_TYPES.CAMPAIGN_COUPON].includes(values.type) && (
      <NumberInput
        name="use_frequency"
        label={intl.formatMessage(form.useFrequency)}
        value={values.use_frequency}
        onChange={handleChange("use_frequency")}
        fullWidth
        required={values.use_period !== ""}
        margin="normal"
        min={1}
        //disabled={!!campaign}
        error={
          performance?.issued_qty > 0 &&
          campaign &&
          values.use_period !== "" &&
          checkUseFrequency()
        }
        helperText={
          performance?.issued_qty > 0 &&
          campaign &&
          values.use_period !== "" &&
          checkUseFrequency() &&
          "Il numero massimo di utilizzi non può essere diminuito"
        }
      />
    )
  );
}
