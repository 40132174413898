import { defineMessages } from "react-intl";

export const cookieConsent = defineMessages({
  acceptAll: {
    id: "component.cookieConsent.acceptAll",
    defaultMessage: "Accetta tutti",
  },
  acceptChanges: {
    id: "component.cookieConsent.acceptChanges",
    defaultMessage: "Accetta modifiche",
  },
  cookieInfo: {
    id: "component.cookieConsent.cookieInfo",
    defaultMessage: "Informazioni sui cookie",
  },
  cookieSettings: {
    id: "component.cookieConsent.cookieSettings",
    defaultMessage: "Impostazioni dei cookie",
  },
  cookieStatus: {
    id: "component.cookieConsent.cookieStatus",
    defaultMessage: "Il suo stato attuale",
  },
  consent: {
    id: "component.cookieConsent.consent",
    defaultMessage: "Consenso",
  },
  customize: {
    id: "component.cookieConsent.customize",
    defaultMessage: "Personalizza",
  },
  denyConsent: {
    id: "component.cookieConsent.denyConsent",
    defaultMessage: "Rifiuta",
  },
  mandatory: {
    id: "component.cookieConsent.mandatory",
    defaultMessage: "Necessari",
  },
  marketing: {
    id: "component.cookieConsent.marketing",
    defaultMessage: "Marketing",
  },
  modifyConsent: {
    id: "component.cookieConsent.modifyConsent",
    defaultMessage: "Modificare il consenso",
  },
  preferences: {
    id: "component.cookieConsent.preferences",
    defaultMessage: "Preferenze",
  },
  revokeConsent: {
    id: "component.cookieConsent.revokeConsent",
    defaultMessage: "Revocare il consenso",
  },
  statistics: {
    id: "component.cookieConsent.statistics",
    defaultMessage: "Statistiche",
  },
  useCookie: {
    id: "component.cookieConsent.useCookie",
    defaultMessage: "Questo sito web utilizza i cookie",
  },
});
