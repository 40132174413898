import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
} from "@material-ui/core";

export default function FidelityForm({ getPermissionByMail }) {
  const intl = useIntl();
  const [email, setEmail] = useState("");

  function handleEmailInput(event) {
    setEmail(event.target.value.trim().toLocaleLowerCase());
  }

  function handleSubmit(event) {
    event.preventDefault();
    getPermissionByMail(email);
  }

  return (
    <Card>
      <CardContent>
        <Typography gutterBottom>
          <FormattedMessage
            id="component.fidelityForm.mainInfo"
            defaultMessage="Inserisci l'indirizzo email a cui vuoi inviare dei punti e premi avanti. Se l'indirizzo è corretto si aprirà il form per l'inserimento dei punti"
          />
        </Typography>
        <Typography variant="body2" gutterBottom>
          <FormattedMessage
            id="component.fidelityForm.secondaryInfo"
            defaultMessage="Per motivi di sicurezza avrai 3 minuti per completare l'invio dei punti prima che scada il permesso, in caso di scadenza basterà premere ANNULLA, tornare qui e reinserire la mail."
          />
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            autoComplete="email"
            autoFocus
            id="email"
            name="email"
            onChange={handleEmailInput}
            type="email"
            value={email}
            label={intl.formatMessage({
              id: "common.email",
              defaultMessage: "Email",
            })}
            fullWidth
            margin="normal"
            required
          />

          <Button type="submit" fullWidth variant="contained" color="primary">
            <FormattedMessage id="common.forward" defaultMessage="Avanti" />
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}
