import React from "react";

import {
  Avatar,
  Hidden,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@material-ui/core";

export default function ListItemInfo({
  children,
  Icon,
  label,
  text,
  ...props
}) {
  return (
    <ListItem {...props}>
      {Icon && (
        <Hidden only="xs">
          <ListItemAvatar>
            <Avatar>
              <Icon />
            </Avatar>
          </ListItemAvatar>
        </Hidden>
      )}
      {text ? (
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            color: "textSecondary",
            variant: "body2",
          }}
          secondary={text}
          secondaryTypographyProps={{
            color: "textPrimary",
            variant: "body1",
          }}
        />
      ) : (
        children
      )}
    </ListItem>
  );
}
