import React, { useState } from "react";
import {
  Container,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Card,
  Menu,
  MenuItem,
  ListItemIcon,
  Chip,
  Box,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Button,
} from "@material-ui/core";
import {
  Delete,
  MoreVert,
  Create,
  FileCopy,
  ArrowBack,
} from "@material-ui/icons";
import { MANAGER_ROUTES } from "../../utils/constants";
import { useBmapi } from "../../utils/bmapi-context";
import { useIntl } from "react-intl";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { getErrorMessageString } from "../../utils/errors";
import { campaign, common, confirm, form } from "../../messages";
import Confirm from "../../ui/Confirm";
import styles from "../../utils/styles";
import { parseBmarkenDate } from "../../utils/utils";
import { format } from "date-fns";

function byDate(a, b) {
  return new Date(a.message.expire_at) < new Date(b.message.expire_at);
}

export default function MessagesCampaignList({
  campaignId,
  campaigns,
  messages,
}) {
  const history = useHistory();
  const classes = styles.useStyles();
  //const [openDialog, setOpenDialog] = React.useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = React.useState(false);
  const [message, setMessage] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const intl = useIntl();
  const messagesCampaigns = campaignId
    ? messages.filter((x) => x.message.campaign_id == campaignId)
    : messages;
  const {
    bmapi,
    startLoading,
    stopLoading,
    notifySuccess,
    notifyError,
  } = useBmapi();

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const getExpDate = (date) => {
    const chechedDate = parseBmarkenDate(date);
    return !chechedDate
      ? "-"
      : format(new Date(parseBmarkenDate(date)), "dd/MM/yyyy");
  };

  const handleOpen = (e, id) => {
    const target = e.currentTarget;
    setAnchorEl(target);
    setMessage(id);
  };

  /*const handleClose = () => {
    setOpenDialog(false);
  };*/

  const handleEdit = () => {
    history.push(
      MANAGER_ROUTES.EDIT_MESSAGE_CAMPAIGN.replace(":messageId?/", message)
    );
  };

  const handleDuplicate = () => {
    history.push(
      (MANAGER_ROUTES.EDIT_MESSAGE_CAMPAIGN + "/clone").replace(
        ":messageId?/",
        message
      )
    );
  };

  /*const handleRemove = (obj) => {
    setOpenDialog(true);
    setMessage(obj);
  };*/

  const handleConfirm = () => {
    startLoading();
    return bmapi
      .removeMessageCampaign(message)
      .then(() => {
        notifySuccess(
          intl.formatMessage({
            id: "pages.messageCampaign.removed",
            defaultMessage: "Messaggio cancellato con successo",
          })
        );
        location.reload();
        history.push(
          MANAGER_ROUTES.MESSAGES_CAMPAIGNS.replace(":campaignId?/", "")
        );
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
        stopLoading();
      });
  };

  return (
    <Container maxWidth="xl">
      {/* NEW */}
      <Box mt={2} mb={2}>
        <Button
          component={RouterLink}
          to={MANAGER_ROUTES.HOME}
          startIcon={<ArrowBack />}
        >
          {intl.formatMessage(common.backHome)}
        </Button>
      </Box>
      <Card>
        {smallScreen &&
          messagesCampaigns.sort(byDate).map((option, i) => (
            <React.Fragment key={option.message.id}>
              {i !== 0 && <Divider />}
              <List>
                <ListItem>
                  <ListItemText
                    style={{ marginRight: "10%" }}
                    primary={
                      <Box>
                        <Typography>{option.message.title}</Typography>
                        <div style={{ display: "flex", marginTop: "3px" }}>
                          {option.mobile_config.banner && (
                            <Typography gutterBottom>
                              <Chip
                                component="span"
                                label={intl.formatMessage(form.banner)}
                                size="small"
                                className={classes.listChip}
                              />
                            </Typography>
                          )}
                          {option.mobile_config.push_notification && (
                            <Typography gutterBottom>
                              <Chip
                                component="span"
                                label={intl.formatMessage(form.push)}
                                size="small"
                                className={classes.listChip}
                              />
                            </Typography>
                          )}
                        </div>
                      </Box>
                    }
                    secondary={
                      <React.Fragment>
                        <Typography>
                          {campaigns.find(
                            (x) => x.campaign_id == option.message.campaign_id
                          )
                            ? campaigns.find(
                                (x) =>
                                  x.campaign_id == option.message.campaign_id
                              ).name
                            : intl.formatMessage(campaign.noCampaign)}
                        </Typography>
                        <Typography>
                          {`${intl.formatMessage(
                            common.expirationDate
                          )}: ${getExpDate(option.message.expire_at)}`}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <ListItemSecondaryAction>
                  <IconButton onClick={(e) => handleOpen(e, option.message.id)}>
                    <MoreVert />
                  </IconButton>
                </ListItemSecondaryAction>
              </List>
            </React.Fragment>
          ))}
        {!smallScreen && (
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{intl.formatMessage(form.title)}</TableCell>
                  <TableCell>{intl.formatMessage(common.campaign)}</TableCell>
                  <TableCell>
                    {intl.formatMessage(common.expirationDate)}
                  </TableCell>
                  <TableCell>{intl.formatMessage(form.push)}</TableCell>
                  <TableCell>{intl.formatMessage(form.banner)}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              {messagesCampaigns.sort(byDate).map((option) => (
                <TableBody key={option.id}>
                  <TableRow>
                    <TableCell>{option.message.title}</TableCell>
                    <TableCell>
                      {campaigns.find(
                        (x) => x.campaign_id == option.message.campaign_id
                      )
                        ? campaigns.find(
                            (x) => x.campaign_id == option.message.campaign_id
                          ).name
                        : intl.formatMessage(campaign.noCampaign)}
                    </TableCell>
                    <TableCell>
                      {getExpDate(option.message.expire_at)}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={option.mobile_config.push_notification}
                        disabled
                        style={{
                          color: option.mobile_config.push_notification
                            ? "#ffb600"
                            : "",
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={option.mobile_config.banner}
                        disabled
                        style={{
                          color: option.mobile_config.banner ? "#ffb600" : "",
                          padding: 0,
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={(e) => handleOpen(e, option.message.id)}
                        style={{ padding: 0 }}
                      >
                        <MoreVert />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
            </Table>
          </TableContainer>
        )}
      </Card>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
          setMessage(null);
        }}
      >
        <MenuItem onClick={handleEdit}>
          <ListItemIcon>
            <Create fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">
            {intl.formatMessage(common.modify)}
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleDuplicate}>
          <ListItemIcon>
            <FileCopy fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">
            {" "}
            {intl.formatMessage(common.clone)}
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => setShowDeleteAlert(true)}>
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">
            {intl.formatMessage(common.delete)}
          </Typography>
        </MenuItem>
      </Menu>

      <Confirm
        open={showDeleteAlert}
        onConfirm={handleConfirm}
        onCancel={() => {
          setMessage(null);
          setShowDeleteAlert(false);
        }}
        text={intl.formatMessage(confirm.deleteElement)}
      />
      {/* END */}

      {/* OLD 
      <Dialog
        open={openDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Rimuovere il messsaggio selezionato?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Annulla
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
      <FormSection>
        {messagesCampaigns.map((option) => (
          <Accordion key={option.message.id}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={option.message.id + "-content"}
              id={option.message.id + "-content"}
            >
              {option.message.name}
              <div style={{ display: "block" }}>
                <Typography variant="h6">{option.message.title}</Typography>
                <Typography variant="caption" color="primary">
                  {campaigns.find(
                    (x) => x.campaign_id == option.message.campaign_id
                  )
                    ? campaigns.find(
                        (x) => x.campaign_id == option.message.campaign_id
                      ).name
                    : "Nessuna campagna"}
                </Typography>
              </div>
              <div style={{ position: "absolute", right: "60px", top: "25px" }}>
                <Edit
                  style={{ margin: "0px 10px" }}
                  onClick={() => {
                    handleEdit(option.message.id);
                  }}
                />
                <Delete
                  style={{ margin: "0px 10px" }}
                  onClick={() => {
                    handleRemove(option.message.id);
                  }}
                />
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div
                style={{ position: "absolute", right: "50px", top: "20px" }}
              ></div>
              <div style={{ paddingLeft: "20px", display: "block" }}>
                <Typography>
                  <b>{intl.formatMessage(form.title)}: </b>
                  {option.message.title}
                </Typography>
                <Typography>
                  <b>Testo:</b> {option.message.body}
                </Typography>
              </div>
            </AccordionDetails>
          </Accordion>
        ))}
      </FormSection>
      */}
    </Container>
  );
}
