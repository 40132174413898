import React from "react";
import { useIntl } from "react-intl";

import { FormControl, FormControlLabel, Switch } from "@material-ui/core";

import { useBmapi } from "../../../utils/bmapi-context";
import { BUSINESS_TYPES } from "../../../utils/constants";
import { form } from "../../../messages";

export default function AutoJoin({ campaign, values, handleChange }) {
  const { bmapi } = useBmapi();
  const intl = useIntl();

  return (
    bmapi.getUserInfo().business.type === BUSINESS_TYPES.LOOP && (
      <FormControl margin="normal">
        <FormControlLabel
          control={
            <Switch
              checked={values.auto_join}
              color="primary"
              onChange={handleChange("auto_join")}
              key="auto_join"
              name="auto_join"
              disabled={!!values.external_ids_format}
            />
          }
          label={intl.formatMessage(form.autoJoin)}
          disabled={!!campaign}
        />
      </FormControl>
    )
  );
}
