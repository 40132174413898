import React, { /*useEffect,*/ useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  FormControlLabel,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CardMedia,
  RadioGroup,
  Radio,
  InputLabel,
  Select,
  TextField,
  makeStyles,
  Typography,
  Fab,
  Link,
} from "@material-ui/core";
import {
  Image as ImageIcon,
  Audiotrack as AudiotrackIcon,
  Videocam as VideocamIcon,
  Map as MapIcon,
  ThreeDRotation as ThreeDRotationIcon,
  InsertLink as InsertLink,
  Add,
} from "@material-ui/icons";
import { useIntl } from "react-intl";
import { common, confirm, form } from "../../messages";
import Confirm from "../../ui/Confirm";
import ButtonChoice from "../../components/ButtonChoice";
import { wikiMuseo } from "../../messages/wikiMuseo";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { /*compareByTitle,*/ getLanguageLabel } from "../../utils/utils";
import MediaContentList from "../../components/MediaContentList";
import GltfViewer from "../../components/GltfViewer";
import MapEditor from "../../components/MapEditor";
//import { bgPos } from "../../utils/constants";
import { bgPos } from "../../utils/wikimuseoConstants";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import FloatingActions from "../../ui/FloatingActions";
import { media } from "../../messages/media";

const cropModes = new Map();
cropModes.set("x", "noCrop");
cropModes.set("contain", "cropContain");
cropModes.set("center", "cropCenter");
cropModes.set("top", "cropTop");
cropModes.set("bottom", "cropBottom");
cropModes.set("right", "cropRight");
cropModes.set("left", "cropLeft");

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline"],
    ["clean"],
  ],
  //htmlEditButton: { debug: true, syntax: true },
};

/*new Quill("#editor", {
    theme: "snow",
    modules: modules,
  });*/

//Quill.register("modules/htmlEditButton", htmlEditButton);

export default function MediaContentForm(props) {
  const { mediaContentValues, setMediaContentValues } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [contentSelected, setContentSelected] = React.useState(null);
  const [fileUpload, setFileUpload] = useState(null);
  //const [allPois, setAllPois] = useState([]);
  const [mapPois, setMapPois] = useState([]);
  const [viewContent, setViewContent] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [open, setOpen] = React.useState(false);
  //const [newUpload, setNewUpload] = React.useState(false);
  const intl = useIntl();
  const [cropModesArray, setCropModesArray] = useState(
    Array.from(cropModes.keys())
  );
  const {
    bmapi,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();

  const [valueRichEditor, setValueRichEditor] = useState("");
  const [errorRichEditor, setErrorRichEditor] = useState("");
  const mediaContentProp = bmapi.settings.mediaContent;
  const mediaContentViewer = {
    maxWidth: mediaContentProp ? mediaContentProp.maxmaxWidth : 570,
    height: mediaContentProp ? mediaContentProp.height : 350,
    ratio:
      (mediaContentProp ? mediaContentProp.height : 350) /
      (mediaContentProp ? mediaContentProp.maxWidth : 570),
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const useStyles = makeStyles({
    topScrollPaper: {
      alignItems: "center",
    },
    topPaperScrollBody: {
      verticalAlign: "top",
    },
  });

  const classes = useStyles();

  const handleMediaContentValues = (varName) => (e) => {
    if (
      varName == "external" &&
      e.target.value == "true" &&
      mediaContentValues.byteArray != null
    ) {
      // Restore item
      ((val) => setMediaContentValues((v) => ({ ...v, ["url"]: val })))(
        mediaContentValues.restoreItems["url"]
      );
    }
    if (
      varName == "external" &&
      e.target.value == "false" &&
      mediaContentValues.byteArray != null
    ) {
      // Restore bytearray
      ((val) => setMediaContentValues((v) => ({ ...v, ["url"]: val })))("");
    }
    ((val) => setMediaContentValues((v) => ({ ...v, [varName]: val })))(
      e.target.value
    );
  };

  const handleClickOpen = (uploadType) => () => {
    setMediaContentValues({
      title: "",
      external: "false",
      url: "",
      media_type: uploadType,
      crop_mode: "x",
      priority: 0,
      byteArray: null,
    });
    setOpen(true);
    setValueRichEditor("");
    //setErrorRichEditor(props.attributionMandatory ? "y" : "");
    setFileUpload(null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputFile = (event) => {
    const file = event.target.files[0];
    setFileUpload(file);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        ((val) => setMediaContentValues((v) => ({ ...v, ["byteArray"]: val })))(
          event.target.result
        );
        ((val) => setMediaContentValues((v) => ({ ...v, ["url"]: val })))("");
        if (mediaContentValues.external == "false") {
          ((val) =>
            setMediaContentValues((v) => ({ ...v, ["restoreItems"]: val })))({
            url: mediaContentValues.url,
          });
          //mediaContentValues.url = "";
        }
      };
      reader.onerror = (err) => {
        ((val) => setMediaContentValues((v) => ({ ...v, ["byteArray"]: val })))(
          null
        );
        reject(err);
      };
      reader.readAsDataURL(file);
    });
  };

  const hideViewContent = () => {
    setViewContent(false);
    setContentSelected(null);
  };

  // recover datas to show/modify mediacontent
  const showMediaContent = (param) => {
    if (param) {
      if (
        contentSelected.media_type === "map" &&
        contentSelected.media_subtype === "openstreetmap"
      ) {
        // recover array of strings "id" for MapEditor component from JSON
        const newConfiguration = JSON.parse(contentSelected.configuration);
        let newMapPois = newConfiguration.pois.map((poi) => poi.poi_id);
        setMapPois(newMapPois);
        setMediaContentValues({
          ...contentSelected,
          external: contentSelected.external ? "true" : "false",
        });
      } else if (contentSelected.media_type === "download") {
        setMediaContentValues({
          ...contentSelected,
          external: contentSelected.external ? "true" : "false",
        });
      } else {
        let img = new Image();
        //img.src = "https://picsum.photos/200/300";
        img.src = contentSelected.url;
        img.onload = () => {
          var tempArray = Array.from(cropModes.keys());
          let isSquare = Math.abs(img.height - img.width) <= 10;
          if (isSquare) {
            tempArray = tempArray.filter(
              (data) =>
                data != "top" &&
                data != "bottom" &&
                data != "left" &&
                data != "right"
            );
          } else if (
            img.height / img.width >=
            mediaContentViewer.ratio /*350 / 570*/
          ) {
            tempArray = tempArray.filter(
              (data) => data != "left" && data != "right"
            );
          } else {
            tempArray = tempArray.filter(
              (data) => data != "top" && data != "bottom"
            );
          }
          setCropModesArray(tempArray);
        };
        img.onerror = (err) => {
          console.log("img error");
          console.error(err);
        };
        setValueRichEditor(
          contentSelected.attribution_text == "undefined"
            ? ""
            : contentSelected.attribution_text
        );
        setErrorRichEditor("");
        setMediaContentValues({
          ...contentSelected,
          external: contentSelected.external ? "true" : "false",
        });
      }
    }
    setOpen(true);
  };

  const handleAddMediaContent = () => {
    //e.preventDefault();
    // collect the unchanging elements
    let newValues = {
      ...mediaContentValues,
      lang: language,
    };
    if (
      mediaContentValues.media_type === "map" &&
      mediaContentValues.media_subtype === "openstreetmap"
    ) {
      const configurationObject = {
        pois: mapPois.map((poiId) => ({ poi_id: poiId })),
      };
      const configurationJson = JSON.stringify(configurationObject);
      newValues.configuration = configurationJson;
      newValues.media_subtype = mediaContentValues.media_subtype;
      newValues.external = true;
      // clear datas
      newValues.crop_mode = "";
    } else if (
      mediaContentValues.media_type === "image" ||
      mediaContentValues.media_type === "map"
    ) {
      newValues.crop_mode =
        mediaContentValues.crop_mode === "x"
          ? ""
          : mediaContentValues.crop_mode;
      newValues.external = mediaContentValues.external === "true";
      // clear datas (may go in let newValues)
      newValues.alternative_text = mediaContentValues.alternative_text
        ? mediaContentValues.alternative_text
        : "";
      newValues.configuration = mediaContentValues.configuration
        ? mediaContentValues.configuration
        : "";
      newValues.media_subtype = mediaContentValues.media_subtype
        ? mediaContentValues.media_subtype
        : "";
    } else if (mediaContentValues.media_type === "audio") {
      newValues.external = props.allowAudioUpload
        ? mediaContentValues.external === "true"
        : true;
    } else if (mediaContentValues.media_type === "video") {
      newValues.external = props.allowVideoUpload
        ? mediaContentValues.external === "true"
        : true;
    } else if (mediaContentValues.media_type === "3dmodel") {
      newValues.external = props.allow3DModelUpload
        ? mediaContentValues.external === "true"
        : true;
    } else if (mediaContentValues.media_type === "externalContent") {
      newValues.external = props.allow3DModelUpload
        ? mediaContentValues.external === "true"
        : true;
    } else {
      console.log("error");
    }
    // Attribution text
    var normalizedAttributionText = valueRichEditor.replace(/<[^>]+>/g, "");
    newValues.attribution_text =
      normalizedAttributionText != "" ? valueRichEditor : "";
    if (
      !(
        mediaContentValues.media_type === "map" &&
        mediaContentValues.media_subtype === "openstreetmap"
      )
    ) {
      // Controllo Alternative Text
      if (props.attributionMandatory && newValues.attribution_text == "") {
        setErrorRichEditor("y");
        return;
      }
      setErrorRichEditor("");
    }
    if (!mediaContentValues.id) {
      startLoading();
      bmapi
        .addMediaContent(
          props.id,
          newValues,
          fileUpload,
          props.entity_type,
          props.entity_type === "Business" ? props.id : undefined
        )
        .then(() => {
          notifySuccess(intl.formatMessage(common.addElement));
          setFileUpload(null);
        })
        .then(() => {
          if (props.loadData) {
            props.loadData(props.id);
          }
        })
        .then(handleClose)
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    } else {
      startLoading();
      bmapi
        .updateMediaContent(
          props.id,
          newValues,
          fileUpload,
          props.entity_type,
          mediaContentValues.id,
          props.entity_type === "Business" ? props.id : undefined
        )
        .then(() => {
          notifySuccess(intl.formatMessage(common.addElement));
          setFileUpload(null);
        })
        .then(() => {
          if (props.loadData) {
            props.loadData(props.id);
          }
        })
        .then(handleClose)
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    }
  };

  const handleDeleteMediaContent = () => {
    // no business default language
    startLoading();
    let lang = language;
    if (!lang) {
      lang = contentSelected.language;
    }
    bmapi
      .deleteMediaContent(
        props.id,
        contentSelected.id,
        lang,
        props.entity_type,
        props.entity_type === "Business" ? props.id : undefined
      )
      .then(setShowDeleteAlert(false))
      .then(() => {
        notifySuccess(intl.formatMessage(common.deleteElement));
      })
      .then(() => {
        if (props.loadData) {
          props.loadData(props.id);
        }
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  };

  function youtubeParser(url) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : false;
  }

  function GenericVideo({ url }) {
    return (
      <div style={{ height: 0, paddingBottom: "62.5%", position: "relative" }}>
        <video
          controls
          style={{
            display: "block",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <source src={url} />
        </video>
      </div>
    );
  }

  function YouTubeVideo({ id }) {
    return (
      <div style={{ height: 0, paddingBottom: "62.5%", position: "relative" }}>
        <iframe
          src={`https://www.youtube.com/embed/${id}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{
            display: "block",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
      </div>
    );
  }

  const [language, setLanguage] = React.useState(props.defaultLanguage);

  const handleChange = (event) => {
    setLanguage(event.target.value);
  };

  const availableLanguages = [
    props.defaultLanguage,
    ...props.availableLanguages,
  ];

  let imageList = [],
    mapList = [],
    audioList = [],
    videoList = [],
    threeDmodelList = [],
    externalContents = [],
    downloadList = [];

  if (props.entity_type === "EcommerceItem") {
    imageList = props.media_contents;
  } else if (props.entity_type === "Business") {
    //imageList = props.media_contents;
    imageList = props.media_contents.filter(
      (content) => content.media_type === "image"
    );
    videoList = props.media_contents.filter(
      (content) => content.media_type === "video"
    );
    downloadList = props.media_contents.filter(
      (content) => content.media_type === "download"
    );
  } else {
    if (props.media_contents?.get(language)) {
      imageList = props.media_contents
        .get(language)
        .filter((content) => content.media_type === "image");

      mapList = props.media_contents
        .get(language)
        .filter((content) => content.media_type === "map");

      audioList = props.media_contents
        .get(language)
        .filter((content) => content.media_type === "audio");

      videoList = props.media_contents
        .get(language)
        .filter((content) => content.media_type === "video");

      threeDmodelList = props.media_contents
        .get(language)
        .filter((content) => content.media_type === "3dmodel");
      externalContents = props.media_contents
        .get(language)
        .filter((content) => content.media_type === "externalContent");
      downloadList = props.media_contents
        .get(language)
        .filter((content) => content.media_type === "downloadList");
    }
  }

  const items = [
    {
      text: intl.formatMessage(wikiMuseo.fileImage),
      icon: <ImageIcon fontSize="small" />,
      onClick: handleClickOpen("image"),
      type: "image",
    },
    {
      text: intl.formatMessage(wikiMuseo.fileMap),
      icon: <MapIcon fontSize="small" />,
      onClick: handleClickOpen("map"),
      type: "map",
    },
    {
      text: intl.formatMessage(wikiMuseo.fileAudio),
      icon: <AudiotrackIcon fontSize="small" />,
      onClick: handleClickOpen("audio"),
      type: "audio",
    },
    {
      text: intl.formatMessage(wikiMuseo.fileVideo),
      icon: <VideocamIcon fontSize="small" />,
      onClick: handleClickOpen("video"),
      type: "video",
    },
    {
      text: intl.formatMessage(wikiMuseo.file3D),
      icon: <ThreeDRotationIcon fontSize="small" />,
      onClick: handleClickOpen("3dmodel"),
      type: "3dmodel",
    },
    {
      text: intl.formatMessage(wikiMuseo.externalContent),
      icon: <InsertLink fontSize="small" />,
      onClick: handleClickOpen("externalContent"),
      type: "externalContent",
    },
    {
      text: intl.formatMessage(wikiMuseo.download),
      icon: <InsertLink fontSize="small" />,
      onClick: handleClickOpen("download"),
      type: "download",
    },
  ];

  let newItems = [];

  for (let i = 0; i < props.available_types.length; i++) {
    for (let y = 0; y < items.length; y++) {
      if (props.available_types[i] === items[y].type) {
        newItems.push(items[y]);
      }
    }
  }

  // {:} to assign the name already in use
  const Preview = ({ mediaContent: contentSelected }) => {
    return (
      <div>
        {contentSelected.external == "false" && (
          <Box component="form">
            {contentSelected.media_type === "image" &&
              (contentSelected.url || contentSelected.byteArray) && (
                <CardMedia
                  image={
                    contentSelected.url != ""
                      ? contentSelected.url
                      : contentSelected.byteArray
                  }
                  style={{
                    maxWidth: mediaContentViewer.maxWidth + "px",
                    height: mediaContentViewer.height + "px",
                    paddingTop: mediaContentViewer ? "" : `${100}%`,
                    backgroundPosition:
                      bgPos[contentSelected.crop_mode] &&
                      bgPos[contentSelected.crop_mode].backgroundPosition,
                    backgroundSize:
                      bgPos[contentSelected.crop_mode] &&
                      bgPos[contentSelected.crop_mode].backgroundSize,
                  }}
                />
              )}
          </Box>
        )}
        {contentSelected.external == "true" && (
          <Box
            style={{
              maxWidth: mediaContentViewer.maxWidth + "px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {contentSelected.media_type === "image" && (
              <CardMedia
                image={contentSelected.url}
                style={{
                  height: mediaContentViewer.height + "px",
                  marginTop: "50px",
                  border: "1px dotted",
                  backgroundPosition:
                    bgPos[contentSelected.crop_mode] &&
                    bgPos[contentSelected.crop_mode].backgroundPosition,
                  backgroundSize:
                    bgPos[contentSelected.crop_mode] &&
                    bgPos[contentSelected.crop_mode].backgroundSize,
                }}
              />
            )}
            {/*
        contentSelected.media_type === "map" && (
          <Card style={{ borderRadius: 0 }}>
            <CardMedia
              image={contentSelected.url}
              style={{
                height: 0,
                paddingTop: `${100}%`,
              }}
            />
            <CardContent>
              {contentSelected.external && (
                <TextField
                  fullWidth
                  multiline
                  value={contentSelected.url}
                  disabled
                />
              )}
            </CardContent>
          </Card>
        );
      }
      {
        contentSelected.media_type === "audio" && (
          <Card id="audio">
            <audio
              controls
              src={contentSelected.url}
              style={{ width: "100%", display: "block" }}
            >
              Your browser does not support the
              <code>audio</code> element.
            </audio>
          </Card>
        );
      }
      {
        contentSelected.media_type === "video" && (
          <Card id="video">
            {youtubeParser(contentSelected.url) ? (
              <YouTubeVideo id={youtubeParser(contentSelected.url)} />
            ) : (
              <GenericVideo url={contentSelected.url} />
            )}
          </Card>
        );
      }
      {
        contentSelected.media_type === "3dmodel" && (
          <Card id="3dmodel">
            <GltfViewer url={contentSelected.url} />
          </Card>
        );
        */}
          </Box>
        )}
        {contentSelected.external == "false" && (
          <Box>
            {contentSelected.media_type === "map" &&
              contentSelected.media_subtype === "image" && (
                <CardMedia
                  image={
                    contentSelected.url != ""
                      ? contentSelected.url
                      : contentSelected.byteArray
                  }
                  style={{
                    height: 0,
                    paddingTop: `${100}%`,
                  }}
                />
              )}
            {}
          </Box>
        )}
        {contentSelected.external == "true" && (
          <Box>
            {contentSelected.media_type === "map" &&
              contentSelected.media_subtype === "image" && (
                <CardMedia
                  image={
                    contentSelected.url != ""
                      ? contentSelected.url
                      : contentSelected.byteArray
                  }
                  style={{
                    height: 0,
                    paddingTop: `${100}%`,
                  }}
                />
              )}
            {}
          </Box>
        )}
        {contentSelected.external == "false" &&
          contentSelected.media_type === "download" && (
            <Box>
              <Link
                href={contentSelected.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.formatMessage(wikiMuseo.download)}
              </Link>
            </Box>
          )}
      </div>
    );
  };

  const getButtonType = () => {
    if (props.entity_type === "EcommerceItem") {
      return (
        <FloatingActions>
          <Fab
            variant="extended"
            color="primary"
            onClick={handleClickOpen(newItems[0].type)}
          >
            <Add />
            {intl.formatMessage(common.add)}
          </Fab>
        </FloatingActions>
      );
    } else if (props.entity_type === "Business") {
      return (
        <Button
          style={{ float: "left", marginTop: 20 }}
          color="primary"
          variant="contained"
          onClick={handleClickOpen(newItems[0].type)}
        >
          {intl.formatMessage(common.add)}
        </Button>
      );
    }
  };

  const getCropText = () => {
    return props.entity_type === "Tour"
      ? intl.formatMessage(wikiMuseo.cropTour)
      : intl.formatMessage(wikiMuseo.cropPoi);
  };

  return (
    <Container
      maxWidth={
        props.entity_type === "PointOfInterest" || props.entity_type === "Tour"
          ? "md"
          : "sm"
      }
      style={{ padding: 0 }}
    >
      {/* Check if business has different languages */}
      {props.availableLanguages.length > 0 && (
        <FormControl fullWidth>
          <InputLabel>{intl.formatMessage(common.language)}</InputLabel>
          <Select value={language} onChange={handleChange}>
            {availableLanguages.map((language) => (
              <MenuItem key={language.value} value={language}>
                {getLanguageLabel(language).label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <Box mb={2}>
        {props.available_types?.indexOf("image") !== -1 && (
          <CardContent>
            <MediaContentList
              title={intl.formatMessage(form.images)}
              mediaContents={imageList}
              setContentSelected={setContentSelected}
              setViewContent={showMediaContent}
              setShowDeleteAlert={setShowDeleteAlert}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              imageList={imageList}
              mapList={mapList}
              audioList={audioList}
              videoList={videoList}
              threeDmodelList={threeDmodelList}
              externalContents={externalContents}
              contentSelected={contentSelected}
            />
          </CardContent>
        )}
        {props.available_types?.indexOf("map") !== -1 && (
          <CardContent>
            <MediaContentList
              title={intl.formatMessage(wikiMuseo.maps)}
              mediaContents={mapList}
              setContentSelected={setContentSelected}
              setViewContent={showMediaContent}
              setShowDeleteAlert={setShowDeleteAlert}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              imageList={imageList}
              mapList={mapList}
              audioList={audioList}
              videoList={videoList}
              threeDmodelList={threeDmodelList}
              externalContents={externalContents}
              downloadList={downloadList}
            />
          </CardContent>
        )}
        {props.available_types?.indexOf("audio") !== -1 && (
          <CardContent>
            <MediaContentList
              title={intl.formatMessage(wikiMuseo.audio)}
              mediaContents={audioList}
              setContentSelected={setContentSelected}
              setViewContent={showMediaContent}
              setShowDeleteAlert={setShowDeleteAlert}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              imageList={imageList}
              mapList={mapList}
              audioList={audioList}
              videoList={videoList}
              threeDmodelList={threeDmodelList}
              externalContents={externalContents}
              downloadList={downloadList}
            />
          </CardContent>
        )}
        {props.available_types?.indexOf("video") !== -1 && (
          <CardContent>
            <MediaContentList
              title={intl.formatMessage(wikiMuseo.video)}
              mediaContents={videoList}
              setContentSelected={setContentSelected}
              setViewContent={showMediaContent}
              setShowDeleteAlert={setShowDeleteAlert}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              imageList={imageList}
              mapList={mapList}
              audioList={audioList}
              videoList={videoList}
              threeDmodelList={threeDmodelList}
              externalContents={externalContents}
              downloadList={downloadList}
            />
          </CardContent>
        )}
        {props.available_types?.indexOf("3dmodel") !== -1 && (
          <CardContent>
            <MediaContentList
              title={intl.formatMessage(wikiMuseo.model3D)}
              mediaContents={threeDmodelList}
              setContentSelected={setContentSelected}
              setViewContent={showMediaContent}
              setShowDeleteAlert={setShowDeleteAlert}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              imageList={imageList}
              mapList={mapList}
              audioList={audioList}
              videoList={videoList}
              threeDmodelList={threeDmodelList}
              externalContents={externalContents}
              downloadList={downloadList}
            />
          </CardContent>
        )}
        {props.available_types?.indexOf("externalContent") !== -1 && (
          <CardContent>
            <MediaContentList
              title={intl.formatMessage(wikiMuseo.externalContent)}
              mediaContents={externalContents}
              setContentSelected={setContentSelected}
              setViewContent={showMediaContent}
              setShowDeleteAlert={setShowDeleteAlert}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              imageList={imageList}
              mapList={mapList}
              audioList={audioList}
              videoList={videoList}
              threeDmodelList={threeDmodelList}
              externalContents={externalContents}
              downloadList={downloadList}
            />
          </CardContent>
        )}
        {props.available_types?.indexOf("download") !== -1 && (
          <CardContent>
            <MediaContentList
              title={intl.formatMessage(wikiMuseo.download)}
              mediaContents={downloadList}
              setContentSelected={setContentSelected}
              setViewContent={showMediaContent}
              setShowDeleteAlert={setShowDeleteAlert}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              imageList={imageList}
              mapList={mapList}
              audioList={audioList}
              videoList={videoList}
              threeDmodelList={threeDmodelList}
              externalContents={externalContents}
              downloadList={downloadList}
            />
          </CardContent>
        )}

        {/* non serve più? */}
        {contentSelected && (
          <Dialog
            open={viewContent}
            onClose={() => {
              hideViewContent(), setContentSelected(null);
            }}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>{contentSelected.title}</DialogTitle>
            <DialogContent>
              {contentSelected.media_type === "image" && (
                <Card style={{ borderRadius: 0 }}>
                  <CardMedia
                    image={contentSelected.url}
                    style={{
                      height: 0,
                      paddingTop: `${100}%`,
                    }}
                  ></CardMedia>
                  <CardContent>
                    {contentSelected.external && (
                      <TextField
                        fullWidth
                        multiline
                        value={contentSelected.url}
                        disabled
                      />
                    )}
                  </CardContent>
                </Card>
              )}
              {contentSelected.media_type === "map" && (
                <Card style={{ borderRadius: 0 }}>
                  <CardMedia
                    image={contentSelected.url}
                    style={{
                      height: 0,
                      paddingTop: `${100}%`,
                    }}
                  ></CardMedia>
                  <CardContent>
                    {contentSelected.external && (
                      <TextField
                        fullWidth
                        multiline
                        value={contentSelected.url}
                        disabled
                      />
                    )}
                  </CardContent>
                </Card>
              )}
              {contentSelected.media_type === "audio" && (
                <Card id="audio">
                  <audio
                    controls
                    src={contentSelected.url}
                    style={{ width: "100%", display: "block" }}
                  >
                    Your browser does not support the
                    <code>audio</code> element.
                  </audio>
                </Card>
              )}
              {contentSelected.media_type === "video" && (
                <Card id="video">
                  {youtubeParser(contentSelected.url) ? (
                    <YouTubeVideo id={youtubeParser(contentSelected.url)} />
                  ) : (
                    <GenericVideo url={contentSelected.url} />
                  )}
                </Card>
              )}
              {contentSelected.media_type === "3dmodel" && (
                <Card id="3dmodel">
                  <GltfViewer url={contentSelected.url} />
                </Card>
              )}
            </DialogContent>
          </Dialog>
        )}
        <Confirm
          open={showDeleteAlert}
          onConfirm={() => handleDeleteMediaContent()}
          onCancel={() => setShowDeleteAlert(false)}
          text={intl.formatMessage(confirm.deleteElement)}
        />
        {newItems.length > 1 ? (
          <ButtonChoice
            items={newItems}
            setContentSelected={setContentSelected}
          />
        ) : (
          getButtonType()
        )}
      </Box>

      {/* Dialog add/modify media /*}
        {/* scroll: form submit removed / add changes in DialogTitle*/}
      {mediaContentValues && (
        <Dialog
          fullWidth
          maxWidth={"md"}
          open={open}
          onClose={() => {
            handleClose();
            setMapPois([]);
            setContentSelected(null);
          }}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          scroll="paper"
          classes={{
            scrollPaper: classes.topScrollPaper,
            paperScrollBody: classes.topPaperScrollBody,
          }}
        >
          <DialogContent ref={descriptionElementRef} tabIndex={-1}>
            {mediaContentValues.media_type === "image" && (
              <DialogTitle>
                {mediaContentValues.id
                  ? intl.formatMessage(wikiMuseo.modifyImage)
                  : intl.formatMessage(wikiMuseo.addImage)}
              </DialogTitle>
            )}
            {mediaContentValues.media_type === "map" && (
              <DialogTitle>
                {mediaContentValues.id
                  ? intl.formatMessage(wikiMuseo.modifyMap)
                  : intl.formatMessage(wikiMuseo.addMap)}
              </DialogTitle>
            )}
            {mediaContentValues.media_type === "audio" && (
              <DialogTitle>
                {mediaContentValues.id
                  ? intl.formatMessage(wikiMuseo.modifyAudio)
                  : intl.formatMessage(wikiMuseo.addAudio)}
              </DialogTitle>
            )}
            {mediaContentValues.media_type === "video" && (
              <DialogTitle>
                {mediaContentValues.id
                  ? intl.formatMessage(wikiMuseo.modifyVideo)
                  : intl.formatMessage(wikiMuseo.addVideo)}
              </DialogTitle>
            )}
            {mediaContentValues.media_type === "3dmodel" && (
              <DialogTitle>
                {mediaContentValues.id
                  ? intl.formatMessage(wikiMuseo.modify3D)
                  : intl.formatMessage(wikiMuseo.add3DModel)}
              </DialogTitle>
            )}
            {mediaContentValues.media_type === "externalContent" && (
              <DialogTitle>
                {mediaContentValues.id
                  ? intl.formatMessage(wikiMuseo.modifyExternalContent)
                  : intl.formatMessage(wikiMuseo.addExternalContent)}
              </DialogTitle>
            )}
            {mediaContentValues.media_type === "download" && (
              <DialogTitle>
                {mediaContentValues.id
                  ? intl.formatMessage(wikiMuseo.modifyDownload)
                  : intl.formatMessage(wikiMuseo.addDownload)}
              </DialogTitle>
            )}
            <Box style={{ width: "100%" }}>
              <TextField
                name="title"
                label={intl.formatMessage(form.title)}
                value={mediaContentValues.title}
                onChange={handleMediaContentValues("title")}
                fullWidth
                margin="normal"
                //required
              />

              {mediaContentValues.media_type === "map" && (
                //<FormControl margin="dense">
                <RadioGroup
                  row
                  name="MapType"
                  value={mediaContentValues.media_subtype}
                  onChange={handleMediaContentValues("media_subtype")}
                >
                  <FormControlLabel
                    value="image"
                    control={<Radio required />}
                    label="Immagine"
                  />
                  <FormControlLabel
                    value="openstreetmap"
                    control={<Radio required />}
                    label="Openstreet"
                  />
                </RadioGroup>
                //</FormControl>
              )}

              <FormControl margin="dense">
                {(mediaContentValues.media_type === "image" ||
                  mediaContentValues.media_type === "download" ||
                  (mediaContentValues.media_type === "map" &&
                    mediaContentValues.media_subtype === "image")) && (
                  <RadioGroup
                    row
                    name="MultimediaContent"
                    value={mediaContentValues.external}
                    onChange={handleMediaContentValues("external")}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio required />}
                      label={intl.formatMessage(common.url)}
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio required />}
                      label={intl.formatMessage(common.file)}
                    />
                  </RadioGroup>
                )}

                {mediaContentValues.media_type === "audio" &&
                  props.allowAudioUpload && (
                    <RadioGroup
                      row
                      name="MultimediaContent"
                      value={mediaContentValues.external}
                      onChange={handleMediaContentValues("external")}
                    >
                      <>
                        <FormControlLabel
                          value="true"
                          control={<Radio required />}
                          label={intl.formatMessage(common.url)}
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio required />}
                          label={intl.formatMessage(common.file)}
                        />
                      </>
                    </RadioGroup>
                  )}
                {mediaContentValues.media_type === "video" &&
                  props.allowVideoUpload && (
                    <RadioGroup
                      row
                      name="MultimediaContent"
                      value={mediaContentValues.external}
                      onChange={handleMediaContentValues("external")}
                    >
                      <>
                        <FormControlLabel
                          value="true"
                          control={<Radio required />}
                          label={intl.formatMessage(common.url)}
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio required />}
                          label={intl.formatMessage(common.file)}
                        />
                      </>
                    </RadioGroup>
                  )}
                {mediaContentValues.media_type === "3dmodel" &&
                  props.allow3DModelUpload && (
                    <RadioGroup
                      row
                      name="MultimediaContent"
                      value={mediaContentValues.external}
                      onChange={handleMediaContentValues("external")}
                    >
                      <>
                        <FormControlLabel
                          value="true"
                          control={<Radio required />}
                          label={intl.formatMessage(common.url)}
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio required />}
                          label={intl.formatMessage(common.file)}
                        />
                      </>
                    </RadioGroup>
                  )}
              </FormControl>
              {(mediaContentValues.media_type === "image" ||
                mediaContentValues.media_type === "download") &&
                mediaContentValues.external === "true" && (
                  <TextField
                    name="url"
                    label={intl.formatMessage(common.url)}
                    value={mediaContentValues.url}
                    onChange={handleMediaContentValues("url")}
                    fullWidth
                    required
                  />
                )}
              {mediaContentValues.media_type === "map" &&
                mediaContentValues.media_subtype === "image" &&
                mediaContentValues.external === "true" && (
                  <TextField
                    name="url"
                    label={intl.formatMessage(common.url)}
                    value={mediaContentValues.url}
                    onChange={handleMediaContentValues("url")}
                    fullWidth
                    required
                  />
                )}
              {mediaContentValues.media_type === "audio" &&
                (!props.allowAudioUpload ||
                  (mediaContentValues.media_type === "audio" &&
                    mediaContentValues.external === "true")) && (
                  <TextField
                    name="url"
                    label={intl.formatMessage(common.url)}
                    value={mediaContentValues.url}
                    onChange={handleMediaContentValues("url")}
                    fullWidth
                    required
                  />
                )}
              {mediaContentValues.media_type === "video" &&
                (!props.allowVideoUpload ||
                  (mediaContentValues.media_type === "video" &&
                    mediaContentValues.external === "true")) && (
                  <TextField
                    name="url"
                    label={intl.formatMessage(common.url)}
                    value={mediaContentValues.url}
                    onChange={handleMediaContentValues("url")}
                    fullWidth
                    required
                  />
                )}
              {mediaContentValues.media_type === "3dmodel" &&
                (!props.allow3DModelUpload ||
                  (mediaContentValues.media_type === "3dmodel" &&
                    mediaContentValues.external === "true")) && (
                  <TextField
                    name="url"
                    label={intl.formatMessage(common.url)}
                    value={mediaContentValues.url}
                    onChange={handleMediaContentValues("url")}
                    fullWidth
                    required
                  />
                )}
              {mediaContentValues.media_type === "externalContent" &&
                (!props.allow3DModelUpload ||
                  (mediaContentValues.media_type === "externalContent" &&
                    mediaContentValues.external === "true")) && (
                  <TextField
                    name="url"
                    label={intl.formatMessage(common.url)}
                    value={mediaContentValues.url}
                    onChange={handleMediaContentValues("url")}
                    fullWidth
                    required
                  />
                )}
              {(mediaContentValues.media_type === "audio" ||
                mediaContentValues.media_type === "video" ||
                mediaContentValues.media_type === "externalContent" ||
                mediaContentValues.media_type === "3dmodel") && (
                <React.Fragment>
                  <Typography
                    className="MuiFormLabel-root"
                    style={
                      errorRichEditor == ""
                        ? { fontSize: "0.75rem", margin: "10px 0px" }
                        : {
                            fontSize: "0.75rem",
                            margin: "10px 0px",
                            color: "red",
                          }
                    }
                  >
                    {intl.formatMessage(form.attribution_text) + " *"}
                  </Typography>
                  <ReactQuill
                    className={errorRichEditor != "" ? "red1d" : ""}
                    theme="snow"
                    modules={modules}
                    value={valueRichEditor}
                    placeholder="Inserire il nome dell’autore e il tipo di licenza con il quale il contenuto può essere condiviso"
                    onChange={setValueRichEditor}
                    error={errorRichEditor == ""}
                  />
                </React.Fragment>
              )}
              {(mediaContentValues.media_type === "image" ||
                mediaContentValues.media_type === "download") &&
                mediaContentValues.external === "false" && (
                  <>
                    <input
                      accept={mediaContentValues.media_type + "/*"}
                      style={{ display: "none" }}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={handleInputFile}
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        color="primary"
                        component="span"
                        style={{ marginTop: "10px" }}
                      >
                        {intl.formatMessage(common.upload)}
                      </Button>
                    </label>
                    {fileUpload && fileUpload.name}
                  </>
                )}

              {mediaContentValues.media_type === "map" &&
                mediaContentValues.media_subtype === "image" &&
                mediaContentValues.external === "false" && (
                  <>
                    <input
                      accept={mediaContentValues.media_type + "/*"}
                      style={{ display: "none" }}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={handleInputFile}
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        color="primary"
                        component="span"
                        style={{ marginTop: "10px" }}
                      >
                        {intl.formatMessage(common.upload)}
                      </Button>
                    </label>
                    {fileUpload && fileUpload.name}
                  </>
                )}

              {mediaContentValues.media_subtype === "openstreetmap" && (
                <MapEditor
                  allPois={props.selectedPois}
                  selectedPois={mapPois}
                  onPoiSelected={setMapPois}
                />
              )}

              {mediaContentValues.media_type === "audio" &&
                props.allowAudioUpload &&
                mediaContentValues.external === "false" && (
                  <>
                    <input
                      accept={mediaContentValues.media_type + "/*"}
                      style={{ display: "none" }}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={handleInputFile}
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        color="primary"
                        component="span"
                        style={{ marginTop: "10px" }}
                      >
                        {intl.formatMessage(common.upload)}
                      </Button>
                    </label>
                    {fileUpload && fileUpload.name}
                  </>
                )}

              {mediaContentValues.media_type === "video" &&
                props.allowVideoUpload &&
                mediaContentValues.external === "false" && (
                  <>
                    <input
                      accept={mediaContentValues.media_type + "/*"}
                      style={{ display: "none" }}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={handleInputFile}
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        color="primary"
                        component="span"
                        style={{ marginTop: "10px" }}
                      >
                        {intl.formatMessage(common.upload)}
                      </Button>
                    </label>
                    {fileUpload && fileUpload.name}
                  </>
                )}

              {mediaContentValues.media_type === "3dmodel" &&
                props.allow3DModelUpload &&
                mediaContentValues.external === "false" && (
                  <>
                    <input
                      accept=".glb"
                      style={{ display: "none" }}
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={handleInputFile}
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        color="primary"
                        component="span"
                        style={{ marginTop: "10px" }}
                      >
                        {intl.formatMessage(common.upload)}
                      </Button>
                    </label>
                    {fileUpload && fileUpload.name}
                  </>
                )}

              {mediaContentValues.media_type === "image" &&
                props.entity_type === "Business" &&
                props.subtypes && (
                  <RadioGroup
                    row
                    name="BusinessImageType"
                    value={mediaContentValues.media_subtype}
                    onChange={handleMediaContentValues("media_subtype")}
                  >
                    {props.subtypes.map((sub, i) => (
                      <FormControlLabel
                        key={i}
                        value={sub === "image" ? "" : sub}
                        control={<Radio required />}
                        //label={sub}
                        label={intl.formatMessage(media[sub])}
                      />
                    ))}
                  </RadioGroup>
                )}

              {(mediaContentValues.media_type === "image" ||
                (mediaContentValues.media_type === "map" &&
                  mediaContentValues.media_subtype === "image")) && (
                <React.Fragment>
                  <TextField
                    name="alternative_text"
                    label={intl.formatMessage(form.alternative_text)}
                    value={mediaContentValues.alternative_text}
                    onChange={handleMediaContentValues("alternative_text")}
                    fullWidth
                    style={{ marginTop: "10px" }}
                    /* required error={mediaContentValues.alternative_text == ""}
                      helperText="Campo obbligatorio"*/
                  />
                  <Typography
                    className="MuiFormLabel-root"
                    style={
                      errorRichEditor == ""
                        ? { fontSize: "0.75rem", margin: "10px 0px" }
                        : {
                            fontSize: "0.75rem",
                            margin: "10px 0px",
                            color: "red",
                          }
                    }
                  >
                    {intl.formatMessage(form.attribution_text)}
                  </Typography>
                  <ReactQuill
                    className={errorRichEditor != "" ? "red1d" : ""}
                    theme="snow"
                    modules={modules}
                    value={valueRichEditor}
                    placeholder="Inserire il nome dell’autore e il tipo di licenza con il quale il contenuto può essere condiviso"
                    onChange={setValueRichEditor}
                    error={errorRichEditor == ""}
                  />
                  <TextField
                    margin="dense"
                    label={getCropText()}
                    value={
                      mediaContentValues.crop_mode == ""
                        ? "x"
                        : mediaContentValues.crop_mode
                    }
                    onChange={handleMediaContentValues("crop_mode")}
                    fullWidth
                    select
                  >
                    {cropModesArray.map((key) => {
                      const value = cropModes.get(key) + "";
                      const intlMsg = wikiMuseo[value];
                      return (
                        <MenuItem key={key} value={key}>
                          {intl.formatMessage(intlMsg)}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </React.Fragment>
              )}
            </Box>
            <Preview mediaContent={mediaContentValues} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
                setFileUpload(null);
                setMapPois([]);
              }}
              variant="contained"
            >
              {intl.formatMessage(common.cancel)}
            </Button>
            <Button
              onClick={handleAddMediaContent}
              color="primary"
              autoFocus
              variant="contained"
            >
              {intl.formatMessage(
                mediaContentValues.id ? common.update : common.add
              )}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Container>
  );
}
