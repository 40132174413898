import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import {
  Button,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
} from "@material-ui/core";
import { ArrowBack, Visibility, VisibilityOff } from "@material-ui/icons";

import { useBmapi } from "../utils/bmapi-context";
import LoginCard from "./LoginCard";
import { getSignInSubscriptionData, propagateUrlTracing } from "../utils/utils";
import { subscriptions } from "../messages";

export default function SignUpForm({ onSubmit, routes }) {
  const { bmapi } = useBmapi();
  const history = useHistory();
  const intl = useIntl();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [valid, setValid] = useState(false);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");

  useEffect(() => {
    setValid(bmapi.validatePassword(password) && bmapi.validateEmail(email));
  }, [email, password, bmapi]);

  function handleSubmit(event) {
    event.preventDefault();
    onSubmit(email, password);
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleMouseDownPassword(event) {
    event.preventDefault();
  }

  useEffect(() => {
    let iTitle = "",
      iSubtitle = "";
    /*
    const trace = getQueryVariable(location.search, URL_TRACE_PARAM);
    const callback = getQueryVariable(location.search, URL_CALLBACK_PARAM);
    if (trace === URL_TRACE_VALUES.SUBSCRIBE) {
      //if (callback !== MANAGER_ROUTES.SUBSCRIPTION_START) {
      if (isJoinSubscribeBuyUrl(callback)) {
        iSubtitle = intl.formatMessage(subscriptions.buySignup);
      } else {
        iSubtitle = intl.formatMessage(subscriptions.noBuySignup);
      }*/
    if (getSignInSubscriptionData()?.isSubscribing) {
      iSubtitle = intl.formatMessage(subscriptions.buySignup);
    } else {
      iTitle = intl.formatMessage({
        id: "component.signup.title",
        defaultMessage: "Registrazione utente",
      });
    }
    setTitle(iTitle);
    setSubtitle(iSubtitle);
  }, [intl]);

  const backButton = (
    <Button
      onClick={() => history.push(routes.SIGN_IN + propagateUrlTracing())}
      startIcon={<ArrowBack />}
      color="inherit"
    >
      <FormattedMessage id="common.back" defaultMessage="Indietro" />
    </Button>
  );

  return (
    <LoginCard title={title} subtitle={subtitle} back={backButton}>
      <form onSubmit={handleSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="email">
            <FormattedMessage id="common.email" defaultMessage="Email" />
          </InputLabel>
          <Input
            id="email"
            name="email"
            value={email}
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
          />
        </FormControl>

        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="password">
            <FormattedMessage id="common.password" defaultMessage="Password" />
          </InputLabel>
          <Input
            name="password"
            value={password}
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            onChange={(e) => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <FormControl margin="normal" fullWidth>
          <Button
            disabled={!valid}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            <FormattedMessage
              id="component.signup.action"
              defaultMessage="Registrati"
            />
          </Button>
        </FormControl>
      </form>
    </LoginCard>
  );
}
