import React from "react";
import { useIntl } from "react-intl";

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";

import { activation } from "../../../messages";

export function DirectMarketing({ value, onChange, formatValues }) {
  const intl = useIntl();

  return (
    <FormControl margin="normal" fullWidth>
      <Typography gutterBottom style={{ whiteSpace: "pre-line" }}>
        {intl.formatMessage(activation.directMarketing, formatValues)}
      </Typography>
      <RadioGroup row value={value} onChange={onChange}>
        <FormControlLabel
          value={true}
          control={<Radio required color="primary" />}
          label={intl.formatMessage(activation.agreeDirect)}
        />
        <FormControlLabel
          value={false}
          control={<Radio required color="primary" />}
          label={intl.formatMessage(activation.dontAgreeDirect)}
        />
      </RadioGroup>
    </FormControl>
  );
}
