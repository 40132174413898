import React from "react";
import { useIntl } from "react-intl";

import { MenuItem, TextField } from "@material-ui/core";

import { CODE_FORMATS, PRODUCTS } from "../../../utils/constants";
import { form } from "../../../messages";

export default function ExternalIdFormat({ values, handleChange, campaign }) {
  const intl = useIntl();

  const onChange = (v) => {
    handleChange("external_ids_format")(v);
    if (v) {
      handleChange("auto_join")(false);
      handleChange("max_issue_number")("");
    }
  };

  return (
    [
      PRODUCTS.COUPON_DISCOUNT_PERC,
      PRODUCTS.COUPON_MULTISHOT,
      PRODUCTS.COUPON_SIMPLE,
      PRODUCTS.COUPON_DISCOUNT_VALUE,
      PRODUCTS.EVENT_PASS,
    ].includes(values.front_end_type) && (
      <TextField
        name="external_ids_format"
        label={intl.formatMessage(form.externalCodes)}
        value={values.external_ids_format}
        onChange={onChange}
        select
        fullWidth
        margin="normal"
        //disabled={!!campaign}
        disabled={
          performance?.issued_qty > 0 && campaign && campaign.max_issue_number
        }
      >
        {[
          { label: form.disabled, value: "" },
          { label: form.externalCodesText, value: CODE_FORMATS.STRING },
          { label: form.externalCodesQr, value: CODE_FORMATS.QR_CODE },
        ].map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {intl.formatMessage(option.label)}
          </MenuItem>
        ))}
      </TextField>
    )
  );
}
