import React from "react";

import {
  Divider,
  Drawer,
  List,
  ListItemIcon,
  MenuItem,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

import { useBmapi } from "../utils/bmapi-context";
import styles from "../utils/styles";
import ListItemLink from "./ListItemLink";

export default function MainMenu({
  open,
  onClose,
  links,
  actions,
  logoImage,
  anchor = "left",
}) {
  const classes = styles.useStyles();
  const { bmapi } = useBmapi();

  function createLinkItem(link, i) {
    return <ListItemLink key={i} link={link} onClick={onClose} />;
  }

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor={anchor}
      classes={{ paper: classes.mainMenu }}
    >
      <MenuItem
        button
        onClick={onClose}
        className={classes.mainMenuBack}
        dir={bmapi.isConsumer() ? "rtl" : "ltr"}
      >
        <ListItemIcon style={{ color: "inherit" }}>
          {bmapi.isConsumer() ? <ChevronRight /> : <ChevronLeft />}
        </ListItemIcon>
      </MenuItem>
      <div className={classes.mainMenuLogoWrapper}>
        <img className={classes.mainMenuLogo} src={logoImage} alt="logo" />
      </div>
      <List className={classes.mainMenuLinks}>{links.map(createLinkItem)}</List>
      {actions.length > 0 && <Divider />}
      <List>{actions.map(createLinkItem)}</List>
    </Drawer>
  );
}
