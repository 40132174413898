import React, {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import Title from "../ui/Title";
import { useBmapi } from "../utils/bmapi-context";
import { useIntl } from "react-intl";
import { getErrorMessageString } from "../utils/errors";
import {
  ArrowBack,
  ArrowDownward,
  ArrowUpward,
  FiberManualRecord,
} from "@material-ui/icons";
import { common } from "../messages";
import utils, { parseBmeIntToInt } from "../utils/utils";
import { INIT_QUERY } from "../utils/gdspConstants";
import { format } from "date-fns";
import { MANAGER_ROUTES } from "../utils/constants";
import { useHistory } from "react-router-dom";
import Confirm from "../ui/Confirm";
import ActionsDetails from "./ActionsDetails";
import PrizesDetails from "./PrizesDetails";
import { DatePicker } from "../ui/DatePicker";

/*function translateAction(act) {
  if (act === "PURCHASE") return "Acquisto";
  if (act === "VISIT") return "Visita";
  if (act === "FAIR") return "Fiera";
  if (act === "REVIEW") return "Recensione";
  if (act === "GIFT") return "Benvenuto";
  if (act === "PROFILE50") return "Bonus profilo";
  if (act === "ENTRY") return "Ingresso";
  return act;
}*/

const ProvinceList = lazy(() =>
  import(/* webpackChunkName: 'ProvinceList' */ "./ProvinceList.js")
);

export default function UsersStatistics() {
  const initialOrder = useMemo(
    () => ({
      status: "",
      email: "",
      issued: "",
      used: "",
    }),
    []
  );

  const intl = useIntl();
  const history = useHistory();
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const [users, setUsers] = useState([]);
  const [actions, setActions] = useState([]);
  const [userPrizes, setUserPrizes] = useState([]);
  const [userSelected, setUserSelected] = useState([]);
  const [province, setProvince] = useState([]);
  const [query, setQuery] = useState(INIT_QUERY);
  const [count, setCount] = useState(0);
  const [points, setPoints] = useState(0);
  const [email, setEmail] = useState("");
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [showData, setShowData] = useState(false);
  //const [detailsView, setDetailsView] = useState(false);
  const [disableUser, setDisableUser] = React.useState(false);
  const [ordered, setOrdered] = useState(initialOrder);

  //const [user, setUser] = useState(null);
  //const [ra, setRa] = useState(null);

  points;

  //const isTenantManager = bmapi.getUserInfo().role === ROLES.TENANT_MANAGER;

  const changeStatusLabel =
    userSelected.status == 0 ? "Disabilita Utente" : "Abilita Utente";
  const changeStatusMessage =
    userSelected.status == 0 ? "disabilitare" : "abilitare";
  const action = userSelected.status == 0 ? "DISABLE" : "ENABLE";

  const goToHome = () => {
    history.push(MANAGER_ROUTES.HOME_MENU);
  };

  const getUsers = useCallback(
    (q) => {
      startLoading();
      if (bmapi) {
        bmapi
          .getUserStatsWithPoints(q || query)
          .then((resp) => {
            resp.map((r) => {
              r.user.custom_fields = JSON.parse(r.user.custom_fields);
              return r;
            });
            setUsers(resp);
            stopLoading();
          })
          .catch((e) => {
            notifyError(getErrorMessageString(e, intl));
            stopLoading();
          });
        bmapi
          .getUserStatsWithPointsCount(q || query)
          .then((resp) => {
            setCount(resp);
            stopLoading();
          })
          .catch((e) => {
            notifyError(getErrorMessageString(e, intl));
            stopLoading();
          });
      }
    },
    [bmapi, intl, notifyError, startLoading, stopLoading, query]
  );

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeStatus = (action, user) => {
    startLoading();

    if (action === "ENABLE") {
      bmapi
        .enableUser(user, "gdsp-x-bme")
        .then(() => {
          handleClose();
          getUsers();
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    } else {
      bmapi
        .disableUser(user, "gdsp-x-bme")
        .then(() => {
          handleClose();
          getUsers();
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }

    stopLoading();
  };

  const search = () => {
    email.trim();
    const params = {
      ...query,
      email: email || null,
      prov: province || null,
      from_created_at: dateFrom ? format(dateFrom, "yyyy-MM-dd") : null,
      to_created_at: dateTo ? format(dateTo, "yyyy-MM-dd") : null,
    };
    for (const property in params) {
      if (params[property] === null) {
        delete params[property];
      }
    }
    setQuery(params);
    /*
    setQuery((v) => ({
      ...v,
      email: email || "",
      prov: province || "",
      from_created_at: dateFrom ? format(dateFrom, "yyyy-MM-dd") : "",
      to_created_at: dateTo ? format(dateTo, "yyyy-MM-dd") : "",
    }));
    */
    //getUsers({ ...query, email: params.email || "" });
    getUsers(params);
  };

  const download = () => {
    startLoading();
    bmapi
      .getUserStats()
      .then((blob) => utils.download(blob, "export.csv"))
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  };

  const handleChangeOrderBy = useCallback(
    (value, varName) => {
      setOrdered(initialOrder);
      setOrdered((v) => ({
        ...v,
        [varName]: value.startsWith("-") ? "minor" : "major",
      }));

      setQuery({
        ...INIT_QUERY,
        orderBy: value,
      });
      getUsers({
        ...INIT_QUERY,
        orderBy: value,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialOrder]
  );

  const handleChangePage = (goTop) => (event, newPage) => {
    setQuery((v) => ({ ...v, page: newPage }));
    getUsers({ ...query, page: newPage });
    if (goTop) window.scrollTo(0, 0);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setQuery((v) => ({ ...v, qty: newRowsPerPage, page: 0 }));
    getUsers({ ...query, qty: newRowsPerPage, page: 0 });
  };

  /*const getDate = (ra) => {
    if (ra.type === "FAIR" || ra.type === "REVIEW") {
      return ra.created_at;
    } else if (ra.type === "ENTRY") {
      return ra.created_at;
    }
    return ra.action_date;
  };*/

  /*const getStatusColor = (status) => {
    let color;
    switch (status) {
      case "APPROVED":
        color = "green";
        break;
      case "CANCELED":
        color = "red";
        break;
      default:
        color = "orange";
    }

    return color;
  };*/

  const getWelcomeRow = (createdAt) => {
    const row = {
      action_date: createdAt,
      business_name: "SposiClub.it",
      created_at: createdAt,
      id: "welcome-0000",
      status: "CONFIRMED",
      type: "GIFT",
      reward: 1000,
    };

    return row;
  };

  const getCompleteProfileRow = (user) => {
    const row = {
      action_date: user.created_at,
      business_name: "SposiClub.it",
      created_at: user.created_at,
      id: "profile-0000",
      status: "CONFIRMED",
      type: "PROFILE50",
      reward: 5000,
    };

    return row;
  };

  const showActions = (user) => {
    startLoading();
    setShowData(true);
    setUserSelected(user);

    bmapi
      .getAllRewardingActions({ user: user.id })
      .then((act) => {
        if (!act) {
          act = [];
        }
        let customFields = user.custom_fields;
        const profileComplete =
          customFields.profile_complete != null &&
          customFields.profile_complete != ""
            ? customFields.profile_complete
            : false;
        if (profileComplete) {
          act.push(getCompleteProfileRow(user));
        }

        act.push(getWelcomeRow(user.created_at));
        setActions(act);

        return bmapi.getUserInspectionProducts("", { user: user.id });
      })
      .then((products) => {
        let card = null;
        let prizes = [];

        products.map((product) => {
          if (product.default) {
            card = product;
          } else {
            prizes.push(product);
          }
        });
        setPoints(card !== null ? parseBmeIntToInt(card.available_value) : 0);
        setUserPrizes(prizes);
        stopLoading();
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
        stopLoading();
      });
  };

  /*const showDetails = (ra) => {
    if (ra.type === "GIFT") return;
    if (ra.type === "PROFILE50") return;
    setDetailsView(true);

    bmapi
      .getUser(ra.user_id)
      .then((resp) => {
        if (!resp) {
          resp = {};
        }
        resp.custom_fields = JSON.parse(resp.custom_fields);
        setUser(resp);
        setRa(ra);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  };*/

  const handleClose = () => {
    setShowData(false);
    setDisableUser(false);
    setPoints(0);
    setActions([]);
    setUserPrizes([]);
  };

  return (
    <React.Fragment>
      <Title>Statistiche Utenti</Title>
      {/* TABELLA PRINCIPALE STATISTICHE UTENTI */}
      <Container maxWidth="sm">
        {/*<Box mb={5} display="flex" justifyContent="space-around">*/}
        {/* <FormControl>
            <InputLabel id="demo-simple-select-helper-label">
              Ordina per
            </InputLabel>
            <Select
              id="select-orderby"
              value={query.orderBy}
              onChange={handleChangeOrderBy}
            >
              {USERS_ORDER.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl> */}

        <TextField
          label={intl.formatMessage(common.filterEmail)}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          name="email"
          fullWidth
        />
        <Suspense fallback={<div>...{intl.formatMessage(common.loading)}</div>}>
          <ProvinceList
            province={province}
            handleValue={(e) => setProvince(e.target.value)}
            label="Filtra per provincia"
            name="competence_locations"
            margin="dense"
            noProvince={true}
          />
        </Suspense>
        <div style={{ display: "flex" }}>
          <DatePicker
            label={intl.formatMessage(common.filterFrom)}
            value={dateFrom}
            onChange={(e) => setDateFrom(e)}
            fullWidth
            margin="dense"
            clearable
          />
          <DatePicker
            label={intl.formatMessage(common.filterTo)}
            value={dateTo}
            onChange={(e) => setDateTo(e)}
            fullWidth
            margin="dense"
            minDate={dateFrom}
            clearable
            //onError={(e) => (e ? setError(true) : setError(false))}
          />
        </div>

        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={search}>
            {intl.formatMessage(common.search)}
          </Button>
        </Box>
        {/*</Box>*/}
      </Container>

      <Box my={2}>
        <Button onClick={goToHome} startIcon={<ArrowBack />}>
          {intl.formatMessage(common.backHome)}
        </Button>
      </Box>

      <Card>
        <CardContent>
          <Box mb={5} mt={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={download}
              style={{ float: "right" }}
            >
              Scarica utenti
            </Button>
            <FiberManualRecord
              fontSize="small"
              style={{
                color: "green",
                marginLeft: 25,
              }}
            />
            <Typography variant="caption">Utenti attivi</Typography>
            <FiberManualRecord
              fontSize="small"
              style={{
                color: "red",
                marginLeft: 25,
              }}
            />
            <Typography variant="caption">Utenti disabilitati</Typography>
          </Box>
          <TableContainer>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    onClick={() =>
                      handleChangeOrderBy(
                        ordered.status === "minor" ? "status" : "-status",
                        "status"
                      )
                    }
                    style={{ cursor: "pointer" }}
                    hover
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      Stato
                    </Typography>
                    {ordered.status && (
                      <IconButton style={{ padding: 0 }}>
                        {ordered.status === "minor" ? (
                          <ArrowDownward fontSize="small" />
                        ) : (
                          <ArrowUpward fontSize="small" />
                        )}
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() =>
                      handleChangeOrderBy(
                        ordered.email === "minor" ? "email" : "-email",
                        "email"
                      )
                    }
                    style={{ cursor: "pointer" }}
                    hover
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      Email
                    </Typography>
                    {ordered.email && (
                      <IconButton style={{ padding: 0 }}>
                        {ordered.email === "minor" ? (
                          <ArrowDownward fontSize="small" />
                        ) : (
                          <ArrowUpward fontSize="small" />
                        )}
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>Nome e cognome</TableCell>
                  <TableCell
                    onClick={() =>
                      handleChangeOrderBy(
                        ordered.issued === "minor"
                          ? "issuedValue"
                          : "-issuedValue",
                        "issued"
                      )
                    }
                    style={{ cursor: "pointer" }}
                    hover
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      P.TI ricevuti
                    </Typography>
                    {ordered.issued && (
                      <IconButton style={{ padding: 0 }}>
                        {ordered.issued === "minor" ? (
                          <ArrowDownward fontSize="small" />
                        ) : (
                          <ArrowUpward fontSize="small" />
                        )}
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() =>
                      handleChangeOrderBy(
                        ordered.used === "minor" ? "usedValue" : "-usedValue",
                        "used"
                      )
                    }
                    style={{ cursor: "pointer" }}
                    hover
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      P.TI usati/cancellati
                    </Typography>
                    {ordered.used && (
                      <IconButton style={{ padding: 0 }}>
                        {ordered.used === "minor" ? (
                          <ArrowDownward fontSize="small" />
                        ) : (
                          <ArrowUpward fontSize="small" />
                        )}
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>Provincia</TableCell>
                  <TableCell>Data matrimonio</TableCell>
                  <TableCell>Data registrazione</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((u) => (
                  <TableRow
                    key={u.user.id}
                    onClick={() => showActions(u.user)}
                    style={{ cursor: "pointer" }}
                    hover
                  >
                    <TableCell>
                      <FiberManualRecord
                        style={{
                          color: u.user.status !== 2 ? "green" : "red",
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {u.user.email || "-"}
                    </TableCell>
                    <TableCell>
                      {u.user.complete_name
                        ? u.user.complete_name + " " + u.user.last_name
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {parseBmeIntToInt(u.performance.issued_value)}
                    </TableCell>
                    <TableCell>
                      {parseBmeIntToInt(u.performance.used_value)}
                    </TableCell>
                    <TableCell>
                      {u.user.custom_fields.wedding_province || "-"}
                    </TableCell>
                    <TableCell>
                      {u.user.custom_fields.wedding_date
                        ? format(
                            new Date(u.user.custom_fields.wedding_date),
                            "dd-MM-yyyy"
                          )
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {u.user.created_at
                        ? format(new Date(u.user.created_at), "dd-MM-yyyy")
                        : "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={count}
              rowsPerPage={query.qty}
              page={query.page}
              onChangePage={handleChangePage(true)}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </CardContent>
      </Card>

      <Box my={2}>
        <Button onClick={goToHome} startIcon={<ArrowBack />}>
          {intl.formatMessage(common.backHome)}
        </Button>
      </Box>

      {/* DIALOG USER ACTIONS */}

      <Dialog open={showData} onClose={handleClose} fullWidth maxWidth="md">
        <DialogContent>
          <Box mb={3}>
            {userSelected.picture ? (
              <CardMedia
                image={userSelected.picture}
                style={{
                  height: "100px",
                  width: "100px",
                  margin: "0 auto",
                }}
              />
            ) : (
              <Avatar
                style={{
                  height: "100px",
                  width: "100px",
                  margin: "0 auto",
                }}
              />
            )}
            <Typography align="center">
              {`${userSelected.complete_name} ${userSelected.last_name}`}
            </Typography>
            <Typography align="center">{`(${userSelected.email})`}</Typography>
            <Button
              style={{ display: "block", margin: "20px auto" }}
              variant="contained"
              color="primary"
              onClick={() => setDisableUser(true)}
            >
              {changeStatusLabel}
            </Button>
          </Box>

          {/* TABELLA PREMI */}
          <PrizesDetails prizes={userPrizes} />
          {/* TABELLA AZIONI */}
          <ActionsDetails showQRCode={null} title={null} actions={actions} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleClose}>
            {intl.formatMessage(common.close)}
          </Button>
        </DialogActions>
      </Dialog>

      {disableUser && (
        <Confirm
          open={!!disableUser}
          onConfirm={() => changeStatus(action, userSelected.id)}
          onCancel={() => setDisableUser(false)}
          text={
            "Vuoi " +
            changeStatusMessage +
            " l'accesso e l'utilizzo dell'applicazione all'utente " +
            userSelected.email +
            "?"
          }
        />
      )}
    </React.Fragment>
  );
}
