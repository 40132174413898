import React from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  useTheme,
} from "@material-ui/core";

import { CardTop } from "../Product";
import styles from "../../utils/styles";
import { useIntl } from "react-intl";
import { useBmapi } from "../../utils/bmapi-context";
import { Info } from "@material-ui/icons";
import { CAMPAIGN_ACTIONS } from "../../utils/constants";

export function ProductAncotEvent({
  campaign,
  campaignStatus,
  cta,
  hasProducts,
  link = false,
  textDateInfo,
  textStatus,
}) {
  const classes = styles.useStyles();

  return (
    <Card className={classes.card}>
      <CardTop campaign={campaign} link={link}>
        {hasProducts && (
          <React.Fragment>
            <Typography variant="body2">{textStatus}</Typography>
            <Typography variant="body2">{textDateInfo}</Typography>
          </React.Fragment>
        )}
      </CardTop>

      <CardActions disableSpacing>
        {campaignStatus !== CAMPAIGN_ACTIONS.ENABLED && cta}
        {link && (
          <Button component={RouterLink} to={link} startIcon={<Info />}>
            Dettagli
          </Button>
        )}
      </CardActions>
    </Card>
  );
}

// stato pagamento quota: "Regolare" / "Non regolare"

// stato polizza: "Attiva" + data scadenza / "Scaduta" + data scadenza
// certificazione uni: "No" / "Si" e se si metti di ficanco l'anno certificazione (cioè non fare una label apposta per l'anno certificazione)

export default function ProductAncot({ campaign, cta, link = false }) {
  const classes = styles.useStyles();
  const theme = useTheme();
  const intl = useIntl();
  const { bmapi } = useBmapi();

  console.log(bmapi.userInfo);

  return (
    <Card className={classes.card}>
      <CardTop
        campaign={campaign}
        link={link}
        ratio={0.45}
        title={false}
        subtitle={false}
      />

      <CardContent
        style={{
          color: theme.palette.getContrastText(theme.palette.primary.main),
          backgroundColor: theme.palette.primary.main,
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography variant="body2">
              <strong>
                {bmapi.userInfo.UserInfo?.Name}{" "}
                {bmapi.userInfo.UserInfo?.LastName}
              </strong>
            </Typography>
            <Typography variant="body2">
              {bmapi.userInfo.UserInfo?.Birthday &&
                `Nato il ${intl.formatDate(
                  new Date(bmapi.userInfo.UserInfo?.Birthday)
                )}`}
            </Typography>
            <Typography variant="body2">
              <strong>{bmapi.userInfo.UserInfo?.FiscalCode}</strong>
            </Typography>
            <Typography variant="body2">
              <strong>{bmapi.userInfo.UserInfo?.Role}</strong>
            </Typography>
            <Typography variant="body2">
              Codice socio: <strong>{bmapi.user.registration_code}</strong>
            </Typography>
            <Typography variant="body2">
              Sede di appartenenza:{" "}
              <strong>{bmapi.userInfo.UserInfo?.MainSite}</strong>
            </Typography>
            <Typography variant="body2">
              Stato pagamento quota:{" "}
              <strong>{bmapi.userInfo.UserInfo?.FeePaymentStatus}</strong>
            </Typography>
            <Typography variant="body2">
              Stato polizza:{" "}
              <strong>{bmapi.userInfo.UserInfo?.PolicyStatus}</strong>{" "}
              {bmapi.userInfo.UserInfo?.PolicyExpiration}
            </Typography>
            <Typography variant="body2">
              Certificazione UNI:{" "}
              <strong>
                {bmapi.userInfo.UserInfo?.UniCertification === "Si"
                  ? bmapi.userInfo.UserInfo?.CertificationYear
                  : bmapi.userInfo.UserInfo?.UniCertification}
              </strong>
            </Typography>
          </Box>
          <Box>
            <img
              style={{ width: 75, height: 100, objectFit: "cover" }}
              src={
                bmapi.userInfo.UserInfo?.ImageURL ||
                "https://via.placeholder.com/75x100?text=_"
              }
            />
          </Box>
        </Box>
      </CardContent>

      <CardContent>
        <Typography variant="h5">{campaign.name}</Typography>
      </CardContent>

      <CardActions disableSpacing>
        <Box>
          <Box>
            {link && (
              <Button component={RouterLink} to={link} startIcon={<Info />}>
                Dettagli socio
              </Button>
            )}
          </Box>
          <Box>{cta}</Box>
        </Box>
      </CardActions>
    </Card>
  );
}
