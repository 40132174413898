import React from "react";
import { useIntl } from "react-intl";

import { Typography } from "@material-ui/core";

import { DatePicker } from "../../DatePicker";
import { PRODUCT_SUBTYPES } from "../../../utils/constants";
import { form, product } from "../../../messages";
import { addDays, addMonths, format } from "date-fns";
import { parseBmarkenDate } from "../../../utils/utils";

export default function ProductLimitDate({
  values,
  handleChange,
  productType,
  campaigns,
  campaign,
  performance,
}) {
  const intl = useIntl();

  const onModify = (val) => {
    if (campaign.product_limit_date) {
      handleChange("product_limit_date")(val);
    }
    if (
      val &&
      val.setHours(0, 0, 0, 0) < campaign.max_product_date.setHours(0, 0, 0, 0)
    ) {
      alert(
        `La data non può essere minore di ${format(
          campaign.max_product_date,
          "dd/MM/yyyy"
        )}`
      );
      return;
    }
    let prizesToModify = [];
    for (let i = 0; i < values.prizes.length; i++) {
      const prize = campaigns.find(
        (c) => values.prizes[i].prize_id === c.campaign_id
      );
      const prizeExp = addDays(
        addMonths(
          parseBmarkenDate(prize.campaign_data.expiration_date),
          prize.campaign_data.product_lifespan
        ),
        prize.campaign_data.grace_period
      );
      if (val > prizeExp) {
        prizesToModify.push(values.prizes[i].name);
      }
    }
    if (prizesToModify.length === 0) {
      handleChange("product_limit_date")(val);
    } else {
      alert(
        `Per estendere la durata di questa campagna è necessario posticipare la data di scadenza dei premi: ${prizesToModify.join(
          ", "
        )}`
      );
    }
  };

  return (
    values.subtype !== PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE && (
      <React.Fragment>
        <Typography variant="caption" display="block" gutterBottom>
          {intl.formatMessage(product[productType].productValidityInfo)}
        </Typography>

        <DatePicker
          label={intl.formatMessage(product[productType].productLimitDate)}
          value={values.product_limit_date}
          onChange={
            performance?.issued_qty > 0
              ? onModify
              : handleChange("product_limit_date")
          }
          //onChange={handleChange("product_limit_date")}
          key="product_limit_date"
          fullWidth
          clearable
          autoOk
          required={!values.product_lifespan || +values.product_lifespan === 0}
          minDate={values.expiration_date}
          minDateMessage={intl.formatMessage(form.productLimitDateError)}
          //disabled={!!campaign}
        />
      </React.Fragment>
    )
  );
}
