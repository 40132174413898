import React from "react";

import Content from "../ui/Content";

export default function PageWrapper({ component: Page }) {
  return (
    <Content>
      <Page />
    </Content>
  );
}
