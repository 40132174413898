import React from "react";
import { FormattedMessage } from "react-intl";

import { Grid, Typography } from "@material-ui/core";

export default function NotFound() {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{ height: "100vh" }}
    >
      <Typography variant="h1">404</Typography>
      <Typography variant="h2">
        <FormattedMessage
          id="pages.404.notFound"
          defaultMessage="Pagina non trovata"
        />
      </Typography>
    </Grid>
  );
}
