import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import {
  Button,
  Card,
  CardContent,
  Container,
  Typography,
} from "@material-ui/core";

import Title from "../ui/Title";
import { useBmapi } from "../utils/bmapi-context";
import { CONSUMER_ROUTES } from "../utils/constants";
import { getErrorMessageString } from "../utils/errors";

export default function Message() {
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const intl = useIntl();
  const history = useHistory();
  const { id, messageId } = useParams();
  const [message, setMessage] = useState(false);

  const goToCampaign = () => {
    history.push(
      CONSUMER_ROUTES.CAMPAIGN.replace(":campaignId", message.campaign_id)
    );
  };

  useEffect(() => {
    startLoading();

    bmapi
      .getMessage(messageId)
      .then(setMessage)
      .then(() => bmapi.markAsRead(id))
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  }, [bmapi, id, intl, messageId, notifyError, startLoading, stopLoading]);

  return (
    message && (
      <Container maxWidth="sm">
        <Title backUrl={CONSUMER_ROUTES.MESSAGES}>{message.title}</Title>
        <Card>
          <CardContent>
            <Typography>{message.body}</Typography>
          </CardContent>
          {message.campaign_id && (
            <CardContent>
              <Button
                color="primary"
                variant="contained"
                onClick={goToCampaign}
              >
                {intl.formatMessage({
                  id: "page.message.goToCampaign",
                  defaultMessage: "Vai alla campagna",
                })}
              </Button>
            </CardContent>
          )}
        </Card>
      </Container>
    )
  );
}
