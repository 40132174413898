import { defineMessages } from "react-intl";

export const confirm = defineMessages({
  acceptElement: {
    id: "component.acceptElement",
    defaultMessage: "Sei sicuro di voler accettare l'elemento selezionato?",
  },
  clonePrompt: {
    id: "component.clonePrompt",
    defaultMessage:
      "Attenzione! I giorni precedentemente impostati verranno sostituiti. Vuoi continuare?",
  },
  deleteManager: {
    id: "component.manageStores.confirm",
    defaultMessage: "Rimuovere il manager {email}?",
  },
  deleteStore: {
    id: "component.manageLoops.confirm",
    defaultMessage: "Rimuovere il negozio {name}?",
  },
  deleteLocation: {
    id: "component.manageLocations.confirm",
    defaultMessage: "Rimuovere il luogo {name}?",
  },
  exitPrompt: {
    id: "component.form.exitPrompt",
    defaultMessage:
      "Ci sono modifiche non salvate. Sei sicuro di voler uscire?",
  },
  tabPrompt: {
    id: "component.form.tabPrompt",
    defaultMessage:
      "Cambiando sezione le modifiche non salvate non saranno valide. Sei sicuro di voler continuare?",
  },
  processingPrompt: {
    id: "component.processingPrompt",
    defaultMessage:
      "C'è un processo in lavorazione. Sei sicuro di voler uscire?",
  },
  requestVoid: {
    id: "component.requestVoid.confirm",
    defaultMessage:
      "Eliminare definitivalmente l'utente <strong>{email}</strong>?",
  },
  revokeProduct: {
    id: "component.revokeProduct",
    defaultMessage: "Sei sicuro di voler revocare il prodotto?",
  },
  shareProduct: {
    id: "component.shareProduct",
    defaultMessage: "Sei sicuro di voler condividere il tuo prodotto?",
  },
  deleteElement: {
    id: "component.deleteElement",
    defaultMessage: "Sei sicuro di voler eliminare l'elemento selezionato?",
  },
  deleteElements: {
    id: "component.deleteElements",
    defaultMessage: "Sei sicuro di voler eliminare {num} elementi?",
  },
  deleteLogo: {
    id: "component.deleteLogo.confirm",
    defaultMessage: "Confermi la cancellazione del logo?",
  },
});
