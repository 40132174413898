import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import FloatingActions from "../../ui/FloatingActions";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import {
  Create as CreateIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
} from "@material-ui/icons";
import { useIntl } from "react-intl";
import { account, common, confirm, errors } from "../../messages";
import { getErrorMessageString } from "../../utils/errors";
import { getLanguageLabel } from "../../utils/utils";
import Confirm from "../../ui/Confirm";
import { wikiMuseo } from "../../messages/wikiMuseo";

const byName = (a, b) => a.name.localeCompare(b.name);

export default function Glossary() {
  const [values, setValues] = useState({
    name: "",
    description: "",
  });

  const { bmapi, notifyError, notifySuccess } = useBmapi();
  const theme = useTheme();
  const intl = useIntl();
  const [glossary, setGlossary] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [keywordIdToDelete, setkeywordIdToDelete] = React.useState(null);
  const [nameFilter, setNameFilter] = useState("");
  const [language, setLanguage] = React.useState(
    bmapi.getUserInfo().business.default_language
  );

  const defaultLanguage = bmapi.getUserInfo().business.default_language;
  const availableLanguages = bmapi.getUserInfo().business.available_languages;

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  //const languageList = [defaultLanguage, ...availableLanguages];

  const languageList = [...new Set([defaultLanguage, ...availableLanguages])];

  const handleValue = useCallback(
    (varName) => (e) => {
      ((val) => setValues((v) => ({ ...v, [varName]: val })))(e.target.value);
    },
    []
  );

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setValues("");
  };

  const handleChangeLanguage = (event) => {
    setLanguage(event.target.value);
  };

  useEffect(() => {
    if (language) loadGlossaryList();
  }, [loadGlossaryList, language]);

  const loadGlossaryList = useCallback(() => {
    if (bmapi) {
      bmapi
        .getGlossary({
          business: bmapi.getUserInfo().business.id,
          subscription: bmapi.getUserInfo().business.subscription_id,
          lang: language,
        })
        .then((resp) => {
          if (!resp) {
            resp = [];
            setGlossary(resp);
          } else {
            resp.sort(byName);
            setGlossary(resp);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
  }, [bmapi, intl, notifyError, language]);

  const confirmKeyword = () => {
    let keyword = {
      ...values,
      language: language,
      subscription_id: bmapi.getUserInfo().business.subscription_id,
    };
    if (!keyword.id) {
      bmapi
        .createGlossaryKeyword(keyword)
        .then(() => {
          setValues(keyword);
          setOpenDialog(false);
          notifySuccess(intl.formatMessage(account.saveConfirm));
        })
        .then(() => loadGlossaryList())
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    } else {
      bmapi
        .updateGlossaryKeyword(keyword)
        .then(() => {
          notifySuccess(intl.formatMessage(account.saveConfirm));
        })
        .then(() => {
          setOpenDialog(false);
        })
        .then(() => loadGlossaryList())
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
  };

  const loadKeyword = useCallback(
    (keywordId) => {
      bmapi
        .getGlossaryKeyword({ keyword: keywordId })
        .then((resp) => {
          if (!resp) {
            notifyError(intl.formatMessage(errors.genericError));
            setOpenDialog(false);
          } else {
            setOpenDialog(true);
            setValues(resp);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    },
    [bmapi, intl, notifyError]
  );

  const deleteKeyword = (keywordId) => {
    if (keywordId !== null) {
      //loading true
      bmapi
        .deleteGlossaryKeyword(keywordId)
        .then(() => {
          setShowDeleteAlert(false);
          notifySuccess(intl.formatMessage(common.deleteElement));
        })
        .then(() => loadGlossaryList())
        .then(() => {
          //loading false
        })
        .catch((e) => {
          //loading false
          notifyError(getErrorMessageString(e, intl));
        });
    }
  };

  return (
    <>
      <Container maxWidth="md">
        <Title>{intl.formatMessage(wikiMuseo.glossary)}</Title>
        <Box mt={2}>
          <Card>
            <CardContent>
              <FormControl fullWidth>
                <InputLabel>{intl.formatMessage(common.language)}</InputLabel>
                <Select value={language} onChange={handleChangeLanguage}>
                  {languageList.map((language) => (
                    <MenuItem key={language} value={language}>
                      {getLanguageLabel(language).label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {!!glossary?.length && (
                <TextField
                  name="campaignName"
                  label={intl.formatMessage(common.filterName)}
                  value={nameFilter}
                  onChange={(e) => setNameFilter(e.target.value)}
                  fullWidth
                  margin="dense"
                />
              )}
              {glossary && glossary.length ? (
                <Box mt={2}>
                  <List>
                    {glossary
                      .filter((kw) =>
                        kw.name
                          .toLowerCase()
                          .startsWith(nameFilter.toLowerCase())
                      )
                      .map((keyword, i) => {
                        return (
                          <React.Fragment key={keyword.id}>
                            {i !== 0 && (
                              <Divider
                                key={i}
                                component="li"
                                style={{
                                  border: "1px solid #858585",
                                  margin: "2% 0",
                                }}
                              />
                            )}
                            <ListItem key={keyword.id}>
                              <ListItemText
                                primary={
                                  <Typography gutterBottom variant="h6">
                                    {keyword.name}
                                  </Typography>
                                }
                              />
                              <ListItemSecondaryAction key={keyword.id}>
                                <IconButton
                                  onClick={() => {
                                    loadKeyword(keyword.id);
                                  }}
                                >
                                  <CreateIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    setkeywordIdToDelete(keyword.id);
                                    setShowDeleteAlert(true);
                                  }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                            <Divider
                              variant="inset"
                              style={{ margin: "0 0 0 25%" }}
                            />
                            <ListItem>
                              <ListItemText
                                style={{
                                  maxHeight: "6rem",
                                  overflow: "hidden",
                                  position: "relative",
                                }}
                                primary={
                                  <Typography gutterBottom>
                                    {keyword.description}
                                  </Typography>
                                }
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  width: "100%",
                                  height: "75%",
                                  bottom: "0",
                                  left: "0",
                                  content: '""',
                                  backgroundImage:
                                    "linear-gradient(to bottom, transparent, white)",
                                  filter: "blur(1px)",
                                }}
                              />
                            </ListItem>
                          </React.Fragment>
                        );
                      })}
                  </List>
                </Box>
              ) : (
                <Typography style={{ marginTop: 30 }}>
                  {intl.formatMessage(common.noElement)}
                </Typography>
              )}

              <Confirm
                open={showDeleteAlert}
                onConfirm={() => deleteKeyword(keywordIdToDelete)}
                onCancel={() => {
                  setkeywordIdToDelete(null);
                  setShowDeleteAlert(false);
                }}
                text={intl.formatMessage(confirm.deleteElement)}
              />
            </CardContent>
          </Card>
        </Box>
      </Container>

      <FloatingActions>
        <Fab variant="extended" color="primary" onClick={handleOpenDialog}>
          <AddIcon />
          {intl.formatMessage(common.add)}
        </Fab>
      </FloatingActions>

      <Dialog fullScreen={fullScreen} fullWidth maxWidth="md" open={openDialog}>
        <DialogTitle>
          {intl.formatMessage(wikiMuseo.glossaryAddName)}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth required margin="normal">
            <InputLabel>
              {intl.formatMessage(wikiMuseo.glossaryName)}
            </InputLabel>
            <Input
              type="text"
              value={values.name}
              name="title"
              onChange={handleValue("name")}
            />
          </FormControl>
          <FormControl fullWidth required margin="normal">
            <InputLabel>
              {intl.formatMessage(wikiMuseo.glossaryDescription)}
            </InputLabel>
            <Input
              type="text"
              value={values.description}
              name="title"
              onChange={handleValue("description")}
              multiline
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} variant="contained">
            {intl.formatMessage(common.cancel)}
          </Button>
          <Button onClick={confirmKeyword} variant="contained" color="primary">
            {intl.formatMessage(values.id ? common.update : common.add)}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
