import React from "react";

import { Slide, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import { VARIANTS } from "../utils/constants";

function Transition(props) {
  return <Slide {...props} direction="up" />;
}

export default function Toast({
  autoHideDuration = 6000,
  message,
  onClose,
  open = false,
  variant = VARIANTS.INFO,
}) {
  const fabH = document.querySelector("[data-fab]")?.getBoundingClientRect()
    .height;

  return (
    <Snackbar
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      open={open}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      TransitionComponent={Transition}
      style={fabH ? { bottom: fabH + 32 } : {}}
    >
      <Alert
        variant="filled"
        severity={variant}
        onClose={onClose}
        elevation={2}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}
