import React from "react";
import { useIntl } from "react-intl";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";
import NumberInput from "../../ui/NumberInput";
import {
  Add,
  Remove,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";
import { common } from "../../messages";

const Notification = ({
  position,
  elem,
  name,
  title,
  values,
  status,
  disabled,
  handleChange,
  handleUpdateEventsList,
  handleSubmit,
  //handleSubmitWithDialog,
}) => {
  const intl = useIntl();
  const [checked, setChecked] = React.useState(elem.active);
  const [selectEvent, setSelectEvent] = React.useState(false);
  const [event, setEvent] = React.useState("");

  const handleSwitch = (event) => {
    setChecked(event.target.checked);
    setSelectEvent(!selectEvent);
    handleChange(name, "show", position)(!elem.show);
    handleUpdateEventsList(position, elem.event_type, !elem.active);
  };

  const handleListEvents = (event) => {
    setEvent(event.target.value);
    name = event.target.value;
  };

  const toggle = () => {
    // Open/Close Card
    handleChange(name, "show", position)(!elem.show);
    setSelectEvent(!selectEvent);
  };

  return (
    <Box mb={2}>
      <Card>
        {status == "init" ? (
          <CardHeader
            action={
              <IconButton disabled={disabled} onClick={toggle}>
                {elem.show ? <Remove /> : <Add />}
              </IconButton>
            }
            title={
              name == "EXP_REMIND"
                ? title + "(" + values[name].remind_days + " gg)"
                : title
            }
          />
        ) : null}
        {status != "init" ? (
          <CardHeader
            title={
              name == "EXP_REMIND"
                ? title + " (" + elem.remind_days + " gg)"
                : title
            }
            action={
              <React.Fragment>
                <IconButton onClick={toggle}>
                  {elem.show ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>
              </React.Fragment>
            }
          />
        ) : null}
        {status != "init" ? (
          <Switch
            checked={checked}
            onChange={handleSwitch}
            inputProps={{ "aria-label": "controlled" }}
            color="primary"
          />
        ) : null}

        {(elem.show || event == "EXP_REMIND") && (
          <CardContent>
            <Box style={{ width: "100%" }}>
              {status == "init" && selectEvent ? (
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Tipologia evento
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={event}
                    label="Tipologia evento"
                    onChange={handleListEvents}
                  >
                    <MenuItem
                      value="ISSUE"
                      disabled={
                        values.filter(
                          (x) => x.event_type == "ISSUE" && x.active
                        ).length != 0
                      }
                    >
                      Emissione
                    </MenuItem>
                    <MenuItem
                      value="ISSUE_WL"
                      disabled={
                        values.filter(
                          (x) => x.event_type == "ISSUE_WL" && x.active
                        ).length != 0
                      }
                    >{`Emissione in lista d'attesa`}</MenuItem>
                    <MenuItem value="EXP_REMIND">Sollecito</MenuItem>
                    <MenuItem
                      value="EXP_EXTENSION"
                      disabled={
                        values.filter(
                          (x) => x.event_type == "EXP_EXTENSION" && x.active
                        ).length != 0
                      }
                    >
                      Prolungamento scadenza
                    </MenuItem>
                  </Select>
                </FormControl>
              ) : null}
              {(name === "EXP_REMIND" || event === "EXP_REMIND") && (
                <NumberInput
                  label="Giorni alla scadenza"
                  value={elem.remind_days}
                  onChange={handleChange(name, "remind_days", position)}
                  fullWidth
                  max={9999}
                  required={elem.enabled}
                />
              )}
              <Box mt={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={elem.in_app_active}
                      onChange={handleChange(name, "in_app_active", position)}
                      color="primary"
                    />
                  }
                  label="Notifica in app"
                />
                {elem.in_app_active && (
                  <TextField
                    margin="dense"
                    label={"Titolo"}
                    value={elem.in_app_title}
                    onChange={handleChange(name, "in_app_title", position)}
                    fullWidth
                    required={elem.in_app_active}
                  />
                )}
                {elem.in_app_active && (
                  <TextField
                    margin="dense"
                    label={"Testo"}
                    value={elem.in_app_body}
                    onChange={handleChange(name, "in_app_body", position)}
                    fullWidth
                    multiline
                    required={elem.in_app_active}
                  />
                )}
              </Box>
              <Box mt={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={elem.push_active}
                      onChange={handleChange(name, "push_active", position)}
                      color="primary"
                    />
                  }
                  label="Notifica push"
                  required
                />
                {elem.push_active && (
                  <TextField
                    margin="dense"
                    label={"Titolo"}
                    value={elem.push_title}
                    onChange={handleChange(name, "push_title", position)}
                    fullWidth
                    required={elem.push_active}
                  />
                )}
                {elem.push_active && (
                  <TextField
                    margin="dense"
                    label={"Testo"}
                    value={elem.push_body}
                    onChange={handleChange(name, "push_body", position)}
                    fullWidth
                    multiline
                    required={elem.push_active}
                  />
                )}
                {elem.push_active && (
                  <TextField
                    margin="dense"
                    label={"Url Img"}
                    value={elem.push_image_url}
                    onChange={handleChange(name, "push_image_url", position)}
                    fullWidth
                    multiline
                    required={elem.push_active}
                  />
                )}
              </Box>
              <Box mt={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={elem.banner_active}
                      onChange={handleChange(name, "banner_active", position)}
                      color="primary"
                    />
                  }
                  label="Notifica Banner"
                  required
                />
                {elem.banner_active && (
                  <TextField
                    margin="dense"
                    label={"Titolo"}
                    value={elem.banner_title}
                    onChange={handleChange(name, "banner_title", position)}
                    fullWidth
                    required={elem.banner_active}
                  />
                )}
                {elem.banner_active && (
                  <TextField
                    margin="dense"
                    label={"Testo"}
                    value={elem.banner_body}
                    onChange={handleChange(name, "banner_body", position)}
                    fullWidth
                    multiline
                    required={elem.banner_active}
                  />
                )}
              </Box>
              <Box mt={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={elem.email_active}
                      onChange={handleChange(name, "email_active", position)}
                      color="primary"
                    />
                  }
                  label="Notifica email"
                />
                {elem.email_active && (
                  <TextField
                    margin="dense"
                    label={"Mittente"}
                    value={elem.email_from}
                    onChange={handleChange(name, "email_from", position)}
                    fullWidth
                    required={elem.email_active}
                  />
                )}
                {elem.email_active && (
                  <TextField
                    margin="dense"
                    label={"Oggetto"}
                    value={elem.email_subject}
                    onChange={handleChange(name, "email_subject", position)}
                    fullWidth
                    required={elem.email_active}
                  />
                )}
                {elem.email_active && (
                  <TextField
                    margin="dense"
                    label={"Testo"}
                    value={elem.email_body}
                    onChange={handleChange(name, "email_body", position)}
                    fullWidth
                    required={elem.email_active}
                    multiline
                  />
                )}
                <Button
                  variant="contained"
                  fullWidth
                  color="primary"
                  type="submit"
                  style={{ marginTop: "15px" }}
                  disabled={event == "" && elem.event_type == "CREATOR"}
                  onClick={() => {
                    setSelectEvent(!selectEvent);
                    handleSubmit(position, event);
                    handleChange(name, "show", position)(!elem.show);
                  }}
                >
                  {elem.event_type == "CREATOR"
                    ? intl.formatMessage(common.save)
                    : intl.formatMessage(common.update)}{" "}
                </Button>
              </Box>
            </Box>
          </CardContent>
        )}
      </Card>
    </Box>
  );
};

export default Notification;
