import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import QRCodeGenerator from "qrcode";
import openGeocoder from "node-open-geocoder";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";

import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/leaflet.css";

const DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
});
L.Marker.prototype.options.icon = DefaultIcon;

import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  Add,
  Apps,
  Delete,
  Edit,
  MoreVert,
  PlaylistAdd,
} from "@material-ui/icons";

import { common, confirm, form } from "../messages";
import FloatingActions, { Action } from "../ui/FloatingActions";
import NumberInput from "../ui/NumberInput";
import { useBmapi } from "../utils/bmapi-context";
import { LOCATION_PREFIX } from "../utils/constants";
import { getErrorMessageString } from "../utils/errors";
import styles from "../utils/styles";
import Confirm from "../ui/Confirm";

const byName = (a, b) => a.name.localeCompare(b.name);

function LocationElement({
  location,
  onAdd,
  onModify,
  onDelete,
  onShow,
  subLocations,
}) {
  const classes = styles.useStyles();
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(null);
  const [deleteRequest, setDeleteRequest] = useState(false);

  const handleAdd = () => {
    setAnchorEl(null);
    onAdd(location);
  };

  const handleDelete = () => {
    setLoading(true);
    setAnchorEl(null);
    onDelete(deleteRequest.id).then(() => {
      setLoading(false);
      setDeleteRequest(false);
    });
  };

  const handleModify = () => {
    setAnchorEl(null);
    onModify(location);
  };

  const showCode = (loc) => {
    setAnchorEl(null);
    onShow(loc || location);
  };

  return (
    <React.Fragment>
      <Confirm
        open={!!deleteRequest}
        onConfirm={handleDelete}
        onCancel={() => setDeleteRequest(false)}
        text={intl.formatMessage(confirm.deleteLocation, deleteRequest)}
      />
      <ListItem>
        <ListItemText
          primary={location.name}
          primaryTypographyProps={{ variant: "h6" }}
          secondary={
            location.description || (
              <i>
                <FormattedMessage
                  id="component.manageLocations.noDescription"
                  defaultMessage="Nessuna descrizione"
                />
              </i>
            )
          }
        />
        <ListItemSecondaryAction>
          <span style={{ display: "inline-block", position: "relative" }}>
            <IconButton
              onClick={(e) => setAnchorEl(e.currentTarget)}
              edge="end"
            >
              <MoreVert />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              keepMounted
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem disabled>
                <Typography variant="overline">{location.name}</Typography>
              </MenuItem>
              <MenuItem onClick={() => showCode()}>
                <ListItemIcon>
                  <Apps fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                  <FormattedMessage
                    id="component.manageLocations.showCode"
                    defaultMessage="Mostra QR Code"
                  />
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleAdd}>
                <ListItemIcon>
                  <PlaylistAdd fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                  <FormattedMessage
                    id="component.manageLocations.addSubLocation"
                    defaultMessage="Aggiungi località"
                  />
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleModify}>
                <ListItemIcon>
                  <Edit fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                  <FormattedMessage
                    id="component.manageLocations.editLocation"
                    defaultMessage="Modifica località"
                  />
                </Typography>
              </MenuItem>
              <MenuItem
                onClick={() => setDeleteRequest(location)}
                disabled={!!subLocations.length}
              >
                <ListItemIcon>
                  <Delete fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">
                  <FormattedMessage
                    id="component.manageLocations.deleteLocation"
                    defaultMessage="Elimina località"
                  />
                </Typography>
              </MenuItem>
            </Menu>
            {loading && (
              <CircularProgress size={48} className={classes.fabProgress} />
            )}
          </span>
        </ListItemSecondaryAction>
      </ListItem>
      {!!subLocations.length && (
        <ListItem>
          <ListItemText
            primary={
              <React.Fragment>
                {subLocations.sort(byName).map((s) => (
                  <Typography variant="body2" key={s.id}>
                    - <strong>{s.name}</strong>{" "}
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => showCode(s)}
                    >
                      {intl.formatMessage({
                        id: "component.manageLocations.showCode",
                        defaultMessage: "Mostra QR Code",
                      })}
                    </Button>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => setDeleteRequest(s)}
                    >
                      {intl.formatMessage(common.delete)}
                    </Button>
                  </Typography>
                ))}
              </React.Fragment>
            }
          />
        </ListItem>
      )}
    </React.Fragment>
  );
}

const initialValues = (bs = {}) => ({
  complete_address: bs.complete_address || "",
  description: bs.description || "",
  lat: "",
  lng: "",
  name: bs.name || "",
  parent_location_id: bs.parent_location_id || "",
  type: bs.type || "physical",
});

export default function ManageLocations() {
  const intl = useIntl();
  const { bmapi, businessId, notifySuccess, notifyError } = useBmapi();
  const [openDialog, setOpenDialog] = useState(false);
  const [code, setCode] = useState(false);
  const [codeLocation, setCodeLocation] = useState(false);
  const [locations, setLocations] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [currentId, setCurrentId] = useState(false);
  const [values, setValues] = useState(initialValues());
  const [debounceTimeout, setDebounceTimeout] = useState(false);
  const [loadingCoords, setLoadingCoords] = useState(false);
  const [map, setMap] = useState(false);

  const handleValue = useCallback(
    (label) => (e) => {
      ((val) => setValues((v) => ({ ...v, [label]: val })))(
        e.target ? e.target.value : e
      );
    },
    []
  );

  useEffect(() => {
    handleValue("lat")("");
    handleValue("lng")("");
    handleValue("complete_address")("");
  }, [handleValue, values.type]);

  useEffect(() => {
    clearTimeout(debounceTimeout);
    setLoadingCoords(true);
    setDebounceTimeout(
      setTimeout(() => {
        openGeocoder()
          .geocode(values.complete_address)
          .end((_, res) => {
            setLoadingCoords(false);
            if (res && res.length) {
              handleValue("lat")(res[0].lat);
              handleValue("lng")(res[0].lon);
            }
          });
      }, 2000)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.complete_address]);

  const handleAdd = (parent) => {
    setOpenDialog(true);
    parent && handleValue("parent_location_id")(parent.id);
  };

  const handleDelete = (id) => {
    return (Array.isArray(id)
      ? Promise.all(id.map((l) => bmapi.deleteLocation(l)))
      : bmapi.deleteLocation(id)
    )
      .then(() => bmapi.getLocations().then(saveLocations))
      .then(() =>
        notifySuccess(
          intl.formatMessage({
            id: "component.manageLocations.locationRemoved",
            defaultMessage: "Località eliminata con successo",
          })
        )
      )
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  };

  const handleModify = (location) => {
    setValues(initialValues(location));
    setCurrentId(location.id);
    handleAdd();
  };

  const hideCode = () => setCode(false);

  const handleShow = (location) => {
    setCodeLocation(location);
    QRCodeGenerator.toDataURL(`${LOCATION_PREFIX}${location.id}`, { scale: 16 })
      .then(setCode)
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  };

  const clear = () => {
    setValues(initialValues());
    setOpenDialog(false);
    setCurrentId(false);
  };

  const saveLocations = (locations) => {
    setLocations(
      (locations || []).filter(
        (l) =>
          !l.parent_location_id ||
          locations.some((f) => f.id === l.parent_location_id)
      ) || []
    );
  };

  const handleCreate = (e) => {
    e.preventDefault();
    setSaving(true);

    bmapi
      .saveLocation(
        {
          ...values,
          lat: parseFloat(values.lat) || 0,
          lng: parseFloat(values.lng) || 0,
        },
        currentId
      )
      .then(() => bmapi.getLocations().then(saveLocations))
      .then(() => {
        notifySuccess(
          intl.formatMessage({
            id: "component.manageLocations.locationSaved",
            defaultMessage: "Località salvata con successo",
          })
        );
        clear();
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(() => setSaving(false));
  };

  const update = useCallback(() => {
    setLoading(true);
    bmapi
      .getLocations()
      .then(saveLocations)
      .then(() => setLoading(false))
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  }, [bmapi, intl, notifyError]);

  useEffect(() => {
    update();
  }, [update, businessId]);

  useEffect(() => {
    if (map) map.panTo([values.lat, values.lng]);
  }, [map, values.lat, values.lng]);

  const createLink = (str) => <Link onClick={() => handleAdd()}>{str}</Link>;

  return (
    <Card>
      {(!locations || loading) && <LinearProgress />}

      <Dialog open={!!code} onClose={hideCode} maxWidth={"sm"} fullWidth>
        <DialogTitle>{codeLocation.name}</DialogTitle>
        <DialogContent>
          {code && (
            <img src={code} alt="QR Code" style={{ maxWidth: "100%" }} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={hideCode} variant="contained" color="primary">
            {intl.formatMessage(common.close)}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDialog} onClose={clear}>
        <form onSubmit={handleCreate}>
          <DialogTitle>
            {currentId ? (
              <FormattedMessage
                id="component.manageLocations.editLocation"
                defaultMessage="Modifica località"
              />
            ) : (
              <FormattedMessage
                id="component.manageLocations.createLocation"
                defaultMessage="Crea località"
              />
            )}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label={intl.formatMessage(form.name)}
              value={values.name}
              onChange={handleValue("name")}
              required
              fullWidth
            />
            <TextField
              margin="dense"
              label={intl.formatMessage(form.description)}
              value={values.description}
              onChange={handleValue("description")}
              fullWidth
            />
            <TextField
              margin="dense"
              label={intl.formatMessage({
                id: "component.manageLocations.parent",
                defaultMessage: "Luogo padre",
              })}
              value={values.parent_location_id}
              onChange={handleValue("parent_location_id")}
              fullWidth
              select
            >
              <MenuItem value="">
                {intl.formatMessage({
                  id: "component.manageLocations.noParent",
                  defaultMessage: "Nessuno",
                })}
              </MenuItem>
              {(locations || [])
                .filter((l) => l.parent_location_id === "")
                .sort(byName)
                .map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              margin="dense"
              label={intl.formatMessage({
                id: "component.manageLocations.type",
                defaultMessage: "Tipologia",
              })}
              value={values.type}
              onChange={handleValue("type")}
              fullWidth
              select
            >
              <MenuItem value="physical">
                {intl.formatMessage({
                  id: "component.manageLocations.physical",
                  defaultMessage: "Fisica",
                })}
              </MenuItem>
              <MenuItem value="virtual">
                {intl.formatMessage({
                  id: "component.manageLocations.virtual",
                  defaultMessage: "Virtuale",
                })}
              </MenuItem>
            </TextField>
            {values.type === "physical" && (
              <React.Fragment>
                <TextField
                  margin="dense"
                  label={intl.formatMessage(form.address)}
                  value={values.complete_address}
                  onChange={handleValue("complete_address")}
                  fullWidth
                />
                {loadingCoords && <LinearProgress />}
                <Grid container spacing={2} alignItems="flex-start">
                  <Grid item xs={6}>
                    <NumberInput
                      label={intl.formatMessage(form.latitude)}
                      value={values.lat}
                      onChange={handleValue("lat")}
                      fullWidth
                      margin="dense"
                      float
                      disabled={loadingCoords}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <NumberInput
                      label={intl.formatMessage(form.longitude)}
                      value={values.lng}
                      onChange={handleValue("lng")}
                      fullWidth
                      margin="dense"
                      float
                      disabled={loadingCoords}
                    />
                  </Grid>
                </Grid>
                <MapContainer
                  center={[values.lat, values.lng]}
                  zoom={13}
                  scrollWheelZoom={false}
                  style={{ height: 200, width: "100%" }}
                  whenCreated={setMap}
                >
                  <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={[values.lat, values.lng]} />
                </MapContainer>
              </React.Fragment>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={clear} disabled={saving}>
              <FormattedMessage id="common.cancel" defaultMessage="Annulla" />
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={saving}
            >
              {currentId ? (
                <FormattedMessage id="common.save" defaultMessage="Salva" />
              ) : (
                <FormattedMessage id="common.create" defaultMessage="Crea" />
              )}
            </Button>
          </DialogActions>
        </form>
        {saving && <LinearProgress />}
      </Dialog>

      {locations &&
        (!locations.length ? (
          <CardContent>
            <Typography gutterBottom>
              <FormattedMessage
                id="component.manageLocations.noLocationRegistered"
                defaultMessage="Nessuna località ancora registrata."
              />
            </Typography>
            <Typography>
              <FormattedMessage
                id="component.manageLocations.emptyAction"
                defaultMessage="Crea la prima località cliccando sul bottone a fondo pagina o <link>qui</link>."
                values={{ link: createLink }}
              />
            </Typography>
          </CardContent>
        ) : (
          <List component="div" disablePadding>
            {locations
              .filter((l) => l.parent_location_id === "")
              .sort(byName)
              .map((l, i) => (
                <React.Fragment key={l.id}>
                  {i !== 0 ? <Divider component="li" /> : null}
                  <LocationElement
                    location={l}
                    onAdd={handleAdd}
                    onDelete={handleDelete}
                    onModify={handleModify}
                    onShow={handleShow}
                    update={update}
                    subLocations={locations.filter(
                      (s) => s.parent_location_id === l.id
                    )}
                  />
                </React.Fragment>
              ))}
          </List>
        ))}

      <FloatingActions>
        <Action
          icon={<Add />}
          label={intl.formatMessage({
            id: "component.manageLocations.createLocation",
            defaultMessage: "Crea località",
          })}
          action={() => handleAdd()}
        />
      </FloatingActions>
    </Card>
  );
}
