import React from "react";
import { useIntl } from "react-intl";

import { MenuItem, TextField } from "@material-ui/core";

import { FEATURES, PRODUCTS } from "../../../utils/constants";
import { useBmapi } from "../../../utils/bmapi-context";
import { campaign as campaignMsg, form } from "../../../messages";

export default function FrontEndType({ values, handleChange, campaign }) {
  const { bmapi } = useBmapi();
  const intl = useIntl();

  return (
    <TextField
      name="front_end_type"
      label={intl.formatMessage(form.type)}
      value={values.front_end_type}
      onChange={handleChange("front_end_type")}
      select
      fullWidth
      required
      margin="normal"
      disabled={!!campaign}
    >
      {[
        {
          feature: FEATURES.CAMPAIGN_COUPON,
          label: campaignMsg.couponSimple,
          value: PRODUCTS.COUPON_SIMPLE,
        },
        {
          feature: FEATURES.CAMPAIGN_COUPON,
          label: campaignMsg.couponMulti,
          value: PRODUCTS.COUPON_MULTISHOT,
        },
        {
          feature: FEATURES.CAMPAIGN_COUPON,
          label: campaignMsg.couponDiscount,
          value: PRODUCTS.COUPON_DISCOUNT_PERC,
        },
        {
          feature: FEATURES.CAMPAIGN_COUPON,
          label: campaignMsg.couponValue,
          value: PRODUCTS.COUPON_DISCOUNT_VALUE,
        },
        {
          feature: FEATURES.CAMPAIGN_EARNING_CARD,
          label: campaignMsg.fidelity,
          value: PRODUCTS.EARNING_CARD,
        },
        {
          disabled: !bmapi.isTenantManager(),
          feature: FEATURES.CAMPAIGN_INSTANTWIN,
          label: campaignMsg.instantwinShort,
          value: PRODUCTS.INSTANT_WIN,
        },
        {
          feature: FEATURES.CAMPAIGN_MULTIWIN,
          label: campaignMsg.multiwinShort,
          value: PRODUCTS.MULTI_WIN,
        },
        {
          feature: FEATURES.CAMPAIGN_SHOPPING_CARD,
          label: campaignMsg.giftcard,
          value: PRODUCTS.SHOPPING_CARD,
        },
        {
          feature: FEATURES.CAMPAIGN_PROVISIONING_CARD,
          label: campaignMsg.provisioning,
          value: PRODUCTS.PROVISIONING_CARD,
        },
        {
          feature: FEATURES.CAMPAIGN_EVENT_PASS,
          label: campaignMsg.eventPass,
          value: PRODUCTS.EVENT_PASS,
        },
        {
          feature: FEATURES.CAMPAIGN_COURSE_PASS,
          label: campaignMsg.coursePass,
          value: PRODUCTS.COURSE_PASS,
        },
      ]
        .filter((s) => !s.disabled)
        .filter((s) => bmapi.can(s.feature))
        .map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {intl.formatMessage(option.label)}
          </MenuItem>
        ))}
    </TextField>
  );
}
