import React from "react";
import { useIntl } from "react-intl";

import { PRODUCT_SUBTYPES } from "../../../utils/constants";

import NumberInput from "../../NumberInput";
import { product } from "../../../messages";

export default function MaxProductsPerUser({
  values,
  handleChange,
  productType,
  campaign,
  setError,
  performance,
}) {
  const intl = useIntl();

  const checkMaxProductPerUser = () => {
    setError(
      +values.max_products_per_user < campaign.rules.max_products_per_user
    );
    return +values.max_products_per_user < campaign.rules.max_products_per_user;
  };

  return (
    ![
      PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE,
      PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE,
      PRODUCT_SUBTYPES.PROVISIONING_CARD,
    ].includes(values.subtype) && (
      <NumberInput
        name="max_products_per_user"
        label={intl.formatMessage(product[productType].maxProductsPerUser)}
        value={values.max_products_per_user}
        onChange={handleChange("max_products_per_user")}
        key="max_products_per_user"
        fullWidth
        margin="normal"
        //disabled={!!values.link_distribution || !!campaign}
        disabled={!!values.link_distribution}
        error={
          performance?.issued_qty > 0 && campaign && checkMaxProductPerUser()
        }
        helperText={
          performance?.issued_qty > 0 &&
          campaign &&
          checkMaxProductPerUser() &&
          "Il numero massimo di card per utente non può essere diminuito"
        }
      />
    )
  );
}
