import React from "react";
import { useIntl } from "react-intl";

import { TextField } from "@material-ui/core";

import { form } from "../../../messages";

export default function TosConsumerUrl({ values, handleChange }) {
  const intl = useIntl();

  return (
    <TextField
      name="tos_consumer_url"
      label={intl.formatMessage(form.tosConsumerUrl)}
      value={values.tos_consumer_url}
      onChange={handleChange("tos_consumer_url")}
      key="tos_consumer_url"
      fullWidth
      margin="normal"
    />
  );
}
