import React from "react";
import { useIntl } from "react-intl";

import { TimePicker } from "../../DatePicker";

import { PRODUCTS } from "../../../utils/constants";
import { form } from "../../../messages";
import { format, parse } from "date-fns";

export default function StartHour({ values, handleChange }) {
  const intl = useIntl();

  const fixHour = (time) => {
    if (typeof time === "string") {
      const newDate = format(new Date(), "dd/MM/yyyy");
      const date = newDate + " " + time;
      return parse(date, "dd/MM/yyyy HH:mm", new Date());
    } else return time;
  };

  return (
    values.front_end_type === PRODUCTS.EVENT_PASS && (
      <TimePicker
        label={intl.formatMessage(form.startHour)}
        //value={values.start_hour}
        value={fixHour(values.start_hour)}
        onChange={handleChange("start_hour")}
        key="start_hour"
        fullWidth
        autoOk
        clearable
        required={!!values.end_hour}
      />
    )
  );
}
