import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

import { common } from "../../messages";
import { DatePicker } from "../../ui/DatePicker";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { BUSINESS_TYPES, MANAGER_ROUTES } from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";
import utils from "../../utils/utils";

const DFLT_BUSINESS_ID = ".";
const byName = (a, b) => a.name.localeCompare(b.name);

export default function ExportPage() {
  const {
    bmapi,
    businesses,
    notifyError,
    startLoading,
    stopLoading,
  } = useBmapi();
  const intl = useIntl();

  const [from, setFrom] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
  );
  const [to, setTo] = useState(new Date());
  const [businessID, setBusinessID] = useState(
    businesses?.length === 1 ? businesses[0].id : DFLT_BUSINESS_ID
  );

  function processExport(e) {
    e.preventDefault();
    startLoading();

    bmapi
      .queryPointsUsers({
        businessID: businessID !== DFLT_BUSINESS_ID ? businessID : "",
        campaignID: "",
        from,
        to,
      })
      .then((blob) => utils.download(blob, "export.csv"))
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  }

  return (
    <Container maxWidth="sm">
      <Title>
        <FormattedMessage id="pages.export.title" defaultMessage="Esporta" />
      </Title>

      <Card>
        <CardContent>
          <form onSubmit={processExport}>
            <TextField
              name="businessID"
              label={intl.formatMessage(common.store)}
              value={businessID}
              onChange={(e) => setBusinessID(e.target.value)}
              select
              fullWidth
              margin="normal"
              required
            >
              {(businesses?.length > 1
                ? [
                    { id: DFLT_BUSINESS_ID, name: "Tutti i negozi" },
                    ...businesses.sort(byName),
                  ]
                : businesses || []
              )
                .filter((b) => b.type !== BUSINESS_TYPES.LOOP)
                .map((b) => (
                  <MenuItem key={b.id} value={b.id}>
                    {b.name}
                  </MenuItem>
                ))}
            </TextField>

            <DatePicker
              label={intl.formatMessage(common.from)}
              value={from}
              onChange={setFrom}
              fullWidth
              margin="normal"
              required
            />
            <DatePicker
              label={intl.formatMessage(common.to)}
              value={to}
              onChange={setTo}
              fullWidth
              margin="normal"
              required
            />
            <FormControl fullWidth margin="normal">
              <Button variant="contained" color="primary" type="submit">
                {intl.formatMessage(common.download)}
              </Button>
            </FormControl>
          </form>
        </CardContent>
      </Card>

      <Box my={4}>
        <Button
          component={RouterLink}
          to={MANAGER_ROUTES.HOME}
          startIcon={<ArrowBack />}
        >
          <FormattedMessage
            id="common.backHome"
            defaultMessage="Torna alla home"
          />
        </Button>
      </Box>
    </Container>
  );
}
