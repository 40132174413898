import React from "react";
import {
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { intlFormatMessage } from "../../../utils/utils";
import { common } from "../../../messages";
import { useIntl } from "react-intl";

export default function Status({ values, handleChange }) {
  const intl = useIntl();

  return (
    <FormControl margin="normal">
      <InputLabel> {intl.formatMessage(common.status)}</InputLabel>
      <Select
        value={values.status}
        onChange={handleChange("status")}
        input={<Input />}
      >
        {[
          { value: 0, label: "active" },
          { value: 1, label: "inactive" },
          { value: 2, label: "obsolete" },
          { value: 3, label: "canceled" },
          { value: 4, label: "pending" },
          { value: 5, label: "testing" },
        ].map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {intlFormatMessage(common[option.label], option.label, intl)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
