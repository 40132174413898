import React from "react";
import { useIntl } from "react-intl";

import { Avatar, Box, Button, Grid, Hidden } from "@material-ui/core";

import { account } from "../../../messages";
import { useBmapi } from "../../../utils/bmapi-context";

export function ProfilePicture({ filePreview, handleCapture }) {
  const intl = useIntl();
  const { bmapi } = useBmapi();

  return (
    <Grid container spacing={3} wrap="nowrap" alignItems="center">
      <Grid item xs>
        <Avatar
          src={filePreview || bmapi.userData?.picture}
          style={{ width: 100, height: 100 }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box mb={1}>
          <Button
            component="label"
            htmlFor="avatarUpload"
            color="secondary"
            variant="contained"
            disableElevation
          >
            {intl.formatMessage(account.changePhoto)}
          </Button>
          <Hidden only={["xs", "sm", "md", "lg", "xl"]} implementation="css">
            <input
              accept="image/*"
              onChange={handleCapture}
              type="file"
              id="avatarUpload"
            />
          </Hidden>
        </Box>
      </Grid>
    </Grid>
  );
}
