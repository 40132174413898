import React, { useState } from "react";
import { useIntl } from "react-intl";

import {
  CardMedia,
  Fab,
  FormControl,
  Hidden,
  Typography,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";

import { useBmapi } from "../../../utils/bmapi-context";
import styles from "../../../utils/styles";
import { form, product } from "../../../messages";

export default function Cover({ values, handleChange, productType }) {
  const intl = useIntl();
  const { bmapi } = useBmapi();
  const classes = styles.useStyles();
  const [coverPreview, setCoverPreview] = useState(values.image_url || false);

  const removeCover = () => {
    setCoverPreview(false);
    handleChange("cover")(false);
    handleChange("image_url")("");
  };

  const handleCapture = (event) => {
    const file = event.target.files[0];
    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);
    fileReader.onload = (e) => setCoverPreview(e.target.result);
    handleChange("cover")(file);
  };

  return (
    <FormControl margin="normal">
      <Typography variant="caption" gutterBottom>
        {intl.formatMessage(product[productType].coverFor)}
      </Typography>

      <div className={classes.coverUpload}>
        <CardMedia
          style={{
            height: 0,
            paddingTop: `${(10 / 16) * 100}%`,
          }}
          image={coverPreview || bmapi.createBg(values.subtype)}
          title="Campaign cover"
        />
        <div className={classes.coverUploadButtons}>
          {coverPreview && (
            <Fab color="secondary" aria-label="delete" onClick={removeCover}>
              <Delete />
            </Fab>
          )}
          <label>
            <Fab color="secondary" component="div" aria-label="edit">
              <Edit />
              <Hidden
                only={["xs", "sm", "md", "lg", "xl"]}
                implementation="css"
              >
                <input accept="image/*" onChange={handleCapture} type="file" />
              </Hidden>
            </Fab>
          </label>
        </div>
      </div>
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        color="textSecondary"
      >
        {intl.formatMessage(form.coverHelp, {
          height: "625",
          ratio: "16:10",
          width: "1000",
        })}
      </Typography>
    </FormControl>
  );
}
