import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { useIntl } from "react-intl";
import { format } from "date-fns";
import { parseBmeIntToInt, parseBmeIntToFloat } from "../../utils/utils";
import { MERCHANT_ACTIONS, STATUS_ACTIONS } from "../../utils/gdspConstants";
import { account, common, confirm } from "../../messages";
import Confirm from "../../ui/Confirm";

const getDate = (ra) => {
  if (ra.type === "FAIR" || ra.type === "REVIEW") {
    return ra.timestamp;
  } else if (ra.type === "ENTRY") {
    return ra.created_at;
  }
  return ra.action_date;
};

const getDetail = (ra) => {
  if (ra.type === "FAIR") {
    return "Visita in fiera";
  } else if (ra.type === "PURCHASE") {
    return parseBmeIntToFloat(ra.expense) + "€";
  } else if (ra.type === "REVIEW") {
    return ra.comment;
  } else if (ra.type === "ENTRY") {
    return ra.comment;
  } else {
    return "-";
  }
};

export default function ActionsMerchant() {
  const {
    bmapi,
    notifyError,
    startLoading,
    stopLoading,
    notifySuccess,
  } = useBmapi();
  const intl = useIntl();

  const [actions, setActions] = useState([]);
  const [showData, setShowData] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [action, setAction] = useState(null);
  const [user, setUser] = useState(null);
  const [actionId, setActionId] = useState(null);
  const [type, setType] = useState("");

  const bs = bmapi.getUserInfo().business.id;

  const onDelete = type === "delete";

  const loadActions = useCallback(() => {
    startLoading();
    if (bmapi) {
      bmapi
        .getBusinessRewardingAction()
        .then((resp) => {
          if (!resp) {
            resp = [];
          }
          setActions(resp);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    }
  }, [bmapi, notifyError, startLoading, stopLoading, intl]);

  useEffect(() => {
    loadActions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadActions]);

  const showDetails = (act) => {
    setShowData(true);

    startLoading();
    if (bmapi) {
      bmapi
        .getUser(act.user_id)
        .then((resp) => {
          if (!resp) {
            resp = {};
          }
          resp.custom_fields = JSON.parse(resp.custom_fields);
          setAction(act);
          setUser(resp);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    }
  };

  const handleActions = (actId, type) => {
    setActionId(actId);
    setType(type);
    setShowAlert(true);
  };

  const updateAction = () => {
    startLoading();

    (onDelete
      ? bmapi.cancelAction(actionId, bs)
      : bmapi.confirmAction(actionId, bs)
    )
      .then(() => {
        notifySuccess(
          onDelete
            ? intl.formatMessage(common.deleteElement)
            : intl.formatMessage(account.saveConfirm)
        );
        setShowAlert(false);
      })
      .then(() => {
        handleClose();
        loadActions();
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  };

  const handleClose = () => {
    setShowData(false);
    setUser(null);
    setAction(null);
  };

  return (
    <Container maxWidth="sm">
      <Title>INTERAZIONI DEGLI UTENTI</Title>
      <Card>
        <CardContent>
          {actions.length === 0 ? (
            <Typography>Non sono state trovate interazioni.</Typography>
          ) : (
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold" }}>DATA</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>UTENTE</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>AZIONE</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>P.TI</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {actions.map((act) => (
                  <TableRow
                    onClick={() => showDetails(act)}
                    key={act.id}
                    style={{ cursor: "pointer" }}
                    hover={true}
                  >
                    <TableCell>
                      {format(new Date(getDate(act)), "dd/MM/yyyy")}
                    </TableCell>
                    <TableCell>{act.user_name}</TableCell>
                    <TableCell>
                      {MERCHANT_ACTIONS.find((a) => a.value === act.type).label}
                    </TableCell>
                    <TableCell align="right">
                      {parseBmeIntToInt(act.reward)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Card>

      <Dialog open={showData} onClose={handleClose} fullWidth>
        {user && action && (
          <DialogContent>
            <Box mb={3}>
              {user.picture ? (
                <>
                  <CardMedia
                    image={user.picture}
                    style={{
                      height: "200px",
                      width: "200px",
                      margin: "0 auto",
                    }}
                  />
                  <Typography align="center">
                    {user.complete_name + " " + user.last_name}
                  </Typography>
                </>
              ) : (
                <>
                  <Avatar
                    style={{
                      height: "200px",
                      width: "200px",
                      margin: "0 auto",
                    }}
                  />
                  <Typography align="center">
                    {user.complete_name + " " + user.last_name}
                  </Typography>
                </>
              )}
            </Box>
            <Table>
              <TableBody>
                {[
                  {
                    name: "DATA INTERAZIONE:",
                    datum: format(new Date(getDate(action)), "dd-MM-yy"),
                  },
                  {
                    name: "DATA NOZZE:",
                    datum: format(
                      new Date(user.custom_fields.wedding_date),
                      "dd-MM-yy"
                    ),
                  },
                  { name: "EMAIL:", datum: user.email },
                  { name: "TELEFONO:", datum: user.mobile },
                  {
                    name: "PROVINCIA NOZZE:",
                    datum: user.custom_fields.wedding_province,
                  },
                  { name: "DETTAGLIO:", datum: getDetail(action) },
                  {
                    name: "STATO:",
                    datum: STATUS_ACTIONS.find((s) => s.value === action.status)
                      .label,
                  },
                ].map((act) => (
                  <TableRow key={act.name}>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {act.name}
                    </TableCell>
                    <TableCell align="right">
                      {act.datum === user.email ? (
                        <Link target="_blank" href={"mailto:" + act.datum}>
                          {act.datum}
                        </Link>
                      ) : (
                        act.datum
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <Box mt={2}>
              <Typography gutterBottom variant="body">
                Cliccando sulla mail verrà aperta una nuova pagina nel suo
                browser. Può chiudere quella pagina dopo aver inviato la mail
                per tornare a questa schermata.
              </Typography>
            </Box>
            <Box mt={2}>
              <Typography gutterBottom variant="h6" align="center">
                Se ritieni che questa interazione non sia autentica, puoi
                eliminarla cancellando all’utente i punti assegnati
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleActions(action.id, "delete")}
                size="large"
                style={{ margin: "0 auto", display: "block" }}
                disabled={action.status !== "PENDING"}
              >
                {intl.formatMessage(common.delete)}
              </Button>
            </Box>
            <Box mt={2}>
              <Typography gutterBottom variant="h6" align="center">
                Non hai potuto approvare subito questa interazione? Fai in modo
                che il tuo cliente raddoppi i propri punti
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleActions(action.id, "confirm")}
                size="large"
                style={{ margin: "0 auto", display: "block" }}
                disabled={action.status !== "PENDING"}
              >
                {intl.formatMessage(common.confirm)}
              </Button>
            </Box>
          </DialogContent>
        )}
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            {intl.formatMessage(common.close)}
          </Button>
        </DialogActions>
      </Dialog>

      <Confirm
        open={showAlert}
        onConfirm={updateAction}
        onCancel={() => {
          setShowAlert(false);
        }}
        text={
          onDelete
            ? intl.formatMessage(confirm.deleteElement)
            : intl.formatMessage(confirm.acceptElement)
        }
      />
    </Container>
  );
}
