import React from "react";
import { useIntl } from "react-intl";

import { TextField } from "@material-ui/core";

import { form } from "../../../messages";
import { useBmapi } from "../../../utils/bmapi-context";

export default function CodesPrefix({ values, handleChange }) {
  const intl = useIntl();
  const { bmapi } = useBmapi();

  return (
    bmapi.settings.codesPrefix && (
      <TextField
        name="codes_prefix"
        label={intl.formatMessage(form.codes_prefix)}
        value={values.codes_prefix}
        onChange={handleChange("codes_prefix")}
        key="codes_prefix"
        fullWidth
        margin="normal"
      />
    )
  );
}
