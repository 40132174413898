import React from "react";
import { useIntl } from "react-intl";

import { TextField } from "@material-ui/core";

import { form } from "../../../messages";

export default function Name({ values, handleChange }) {
  const intl = useIntl();

  return (
    <TextField
      name="name"
      label={intl.formatMessage(form.name)}
      value={values.name}
      onChange={handleChange("name")}
      key="name"
      fullWidth
      margin="normal"
      required
    />
  );
}
