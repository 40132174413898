import React from "react";
import { Container } from "@material-ui/core";
import MenuButton from "../components/MenuButton";
import { LINK_TYPE } from "../utils/constants";
import { useBmapi } from "../utils/bmapi-context";
import { useHistory } from "react-router-dom";

export default function HomeMenu() {
  const history = useHistory();
  const { pages } = useBmapi();

  function isPage(link) {
    return (
      (link.menu && !link.menu.position) ||
      link.menu?.position === LINK_TYPE.PAGE
    );
  }

  function setActive(link) {
    return {
      ...link.menu,
      url: link.menu.url || link.routing?.path.replace(/:([\w?]+)\/$/g, ""),
      active: link.menu.url === history.location.pathname,
    };
  }

  const menuItem = pages.filter(isPage).map(setActive);

  const items = menuItem.filter((item) => item.label.defaultMessage !== "Home");

  const getViewport = (items) => {
    if (items.length > 9 && items.length < 16) return "lg";
    else if (items.length > 16) return "xl";
    else return "md";
  };

  return (
    <Container maxWidth={getViewport(items)}>
      <MenuButton items={items} />
    </Container>
  );
}
