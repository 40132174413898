import React from "react";
import { useBmapi } from "../../utils/bmapi-context";
import Pricing from "../../components/Pricing";
import Subscriptions from "./Subscriptions";
import ConsumerSubscriptions from "./ConsumerSubscriptions";

export default function SubscriptionStart() {
  const { userId } = useBmapi();

  return userId ? (
    <>
      <Subscriptions />
      <ConsumerSubscriptions />
    </>
  ) : (
    <Pricing />
  );
}
