import React from "react";
import { useIntl } from "react-intl";

import NumberInput from "../../NumberInput";
import { PRODUCT_SUBTYPES } from "../../../utils/constants";
import { form } from "../../../messages";

export default function Quantum({ values, handleChange, campaign }) {
  const intl = useIntl();

  return (
    [PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE].includes(values.type) && (
      <NumberInput
        name="quantum"
        label={intl.formatMessage(form.quantum)}
        placeholder="1"
        value={values.quantum}
        onChange={handleChange("quantum")}
        fullWidth
        margin="normal"
        helperText={intl.formatMessage(form.quantumHelp)}
        disabled={!!campaign}
      />
    )
  );
}
