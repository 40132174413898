import {
  Avatar,
  Box,
  Card,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import {
  Beenhere,
  CheckCircle,
  EventAvailable,
  ExitToApp,
} from "@material-ui/icons";
import { format } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useBmapi } from "../utils/bmapi-context";
import { RESOURCE_TYPES } from "../utils/constants";

function Checkin({ checkin, onCheckout }) {
  const intl = useIntl();
  const { bmapi, notifySuccess } = useBmapi();

  const checkout = () => {
    const resource_id =
      checkin.type === RESOURCE_TYPES.LOCATION
        ? checkin.location_id
        : checkin.business_event_id;
    bmapi.checkout(resource_id, checkin.type).then(() => {
      if (onCheckout) onCheckout();
      notifySuccess(
        intl.formatMessage({
          id: "common.checkoutDone",
          defaultMessage: "Effettuato chekout",
        })
      );
    });
  };

  const Icon =
    {
      [RESOURCE_TYPES.LOCATION]: Beenhere,
      [RESOURCE_TYPES.EVENT]: EventAvailable,
    }[checkin.type] || CheckCircle;

  return (
    <Box mb={2}>
      <Card>
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <Icon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={`${intl.formatDate(
                new Date(checkin.check_in_at)
              )} ${format(new Date(checkin.check_in_at), "HH:mm")}`}
              secondary={checkin.location_name}
            />
            <ListItemSecondaryAction>
              <IconButton onClick={checkout}>
                <ExitToApp />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Card>
    </Box>
  );
}

export default function Checkins() {
  const { bmapi } = useBmapi();
  const [checkins, setCheckins] = useState(false);

  const update = useCallback(() => {
    bmapi
      .getUserCheckIns()
      .then((cs) => setCheckins(cs.filter((c) => !c.checked_out)));
  }, [bmapi]);

  useEffect(() => {
    update();
  }, [update]);

  return (checkins || []).map((checkin) => (
    <Checkin checkin={checkin} key={checkin.id} onCheckout={update} />
  ));
}
