export const STATUS = [
  { value: "-", label: "anyStatus" },
  { value: "CREATED", label: "created" },
  { value: "RUNNING", label: "running" },
  { value: "SUCCESSFULLY-EXECUTED", label: "successfullyExecuted" },
  { value: "EXECUTED-W-ERRORS", label: "executedErrors" },
];

export const TASK_RESULTS = [
  { value: "process_ref", label: "processRef" },
  { value: "process_type", label: "processType" },
  { value: "name", label: "name" },
  { value: "status", label: "status" },
  { value: "queue_status", label: "queueStatus" },
  { value: "task_quantity", label: "taskQuantity" },
  //{ value: "errors_count", label: "errorsCount" },
];

export const STATS = [
  { value: "Total", label: "Totale task" },
  { value: "Executed", label: "Task completati" },
  { value: "Errors", label: "Task in errore" },
];

export const TASK_ERRORS = [
  { value: "id", label: "errorId" },
  { value: "executed_at", label: "dateTimeError" },
  { value: "payload", label: "payload" },
  { value: "results", label: "results" },
  { value: "errors", label: "taskErrors" },
];
