import React from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  LinearProgress,
  Link,
  Typography,
} from "@material-ui/core";

import { useBmapi } from "../utils/bmapi-context";
import styles from "../utils/styles";
import { CONSUMER_ROUTES, FEATURES, MANAGER_ROUTES } from "../utils/constants";
import LanguageSelector from "./LanguageSelector";
import { FormattedMessage } from "react-intl";

export default function LoginCard({
  back,
  children,
  forward,
  loading,
  title,
  subtitle,
}) {
  const { baseUrl, bmapi } = useBmapi();
  const classes = styles.useStyles();

  const mode =
    bmapi.isManager() && bmapi.isConsumerEnabled ? (
      <Button
        href={baseUrl + CONSUMER_ROUTES.HOME.slice(1)}
        className={classes.loginColor}
      >
        <FormattedMessage
          id="pages.entry.iAmConsumer"
          defaultMessage="Clicca qui se sei un consumatore"
        />
      </Button>
    ) : bmapi.isConsumer() && bmapi.isManagerEnabled ? (
      <Button
        href={baseUrl + MANAGER_ROUTES.HOME.slice(1)}
        className={classes.loginColor}
      >
        <FormattedMessage
          id="pages.entry.iAmManager"
          defaultMessage="Clicca qui se sei un esercente"
        />
      </Button>
    ) : null;

  const switchMode = bmapi.can(FEATURES.UNIQUE_SIGNIN) ? null : mode;

  return (
    <Container
      maxWidth="xs"
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        paddingBottom: 40,
      }}
    >
      <Box textAlign="center" mb={3} mt={6}>
        <Link
          component={RouterLink}
          to={(bmapi.isConsumer() ? CONSUMER_ROUTES : MANAGER_ROUTES).HOME}
        >
          <img
            id="main-logo"
            tabIndex={1}
            src={bmapi.logo()}
            alt="logo bmarken - torna alla Home" // bmarken logo
            style={{ maxWidth: "100%", width: bmapi.themeConf.logoWidth }}
          />
        </Link>
      </Box>

      {(back || forward) && (
        <Box mt={3}>
          <Grid
            container
            justify="space-between"
            className={classes.loginColor}
          >
            <Grid item>{back}</Grid>
            <Grid item>{forward}</Grid>
          </Grid>
        </Box>
      )}

      <Box
        textAlign="center"
        mt={3}
        mb={1}
        id="login-card"
        tabIndex={title ? 0 : -1}
      >
        <Card>
          <CardContent>
            {title && (
              <Typography component="h1" variant="h5">
                {title}
              </Typography>
            )}
            {subtitle && (
              <Typography component="h1" variant="h6">
                {subtitle}
              </Typography>
            )}
            {children}
          </CardContent>
          {loading && <LinearProgress />}
        </Card>
      </Box>

      {(back || forward) && (
        <Box mt={2} mb={3}>
          <Grid
            container
            justify="space-between"
            className={classes.loginColor}
          >
            <Grid item>{back}</Grid>
            <Grid item>{forward}</Grid>
          </Grid>
        </Box>
      )}

      <Box textAlign="center" pt={2} mb={1} style={{ marginTop: "auto" }}>
        <LanguageSelector />
        {switchMode && (
          <Box pt={2} mb={2} textAlign="center">
            {switchMode}
          </Box>
        )}
        {bmapi.settings.poweredBy !== false && (
          <Box
            textAlign="center"
            mt={1}
            mb={1}
            style={{
              opacity: 0.65,
            }}
          >
            {bmapi.subscriptionSlug !== "lamilanochepremia" ? (
              <Link href={bmapi.settings.poweredByLink} target="_blank">
                <Typography
                  className={classes.loginColor}
                  gutterBottom
                  variant="body2"
                >
                  <i>{bmapi.settings.poweredBy}</i>
                </Typography>
              </Link>
            ) : (
              <React.Fragment>
                <Typography
                  className={classes.loginColor}
                  gutterBottom
                  variant="body2"
                >
                  <Link
                    href={bmapi.settings.poweredByLink}
                    target="_blank"
                    className={classes.loginColor}
                  >
                    <i>{bmapi.settings.poweredBy}</i>
                  </Link>
                  ,{" "}
                  <Link
                    href="https://anykey75.it/"
                    target="_blank"
                    className={classes.loginColor}
                  >
                    <i>marketing by AnyKey 75</i>
                  </Link>
                </Typography>
                <Typography
                  className={classes.loginColor}
                  gutterBottom
                  variant="body2"
                  style={{ marginTop: 10 }}
                >
                  {" "}
                  per segnalare un&apos;anomalia o ricevere assistenza scrivici
                  a{" "}
                  <a
                    href="mailto:support-lamilanochepremia@liberacta.com"
                    className={classes.loginColor}
                  >
                    support-lamilanochepremia@liberacta.com
                  </a>
                </Typography>
              </React.Fragment>
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
}
