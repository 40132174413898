import React from "react";
import { useIntl } from "react-intl";

import { TextField } from "@material-ui/core";

import { form } from "../../../messages";
import { useBmapi } from "../../../utils/bmapi-context";

export function Address({ value, onChange }) {
  const intl = useIntl();
  const { bmapi } = useBmapi();

  return (
    <TextField
      fullWidth
      label={intl.formatMessage(form.address)}
      margin="normal"
      name="address"
      onChange={onChange}
      required={bmapi.settings.profileRequired.includes("address")}
      value={value}
    />
  );
}
