import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link as RouterLink } from "react-router-dom";

import { Box, Button, Container } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

import FidelityForm from "../../ui/FidelityForm";
import FormManager from "../../ui/FormManager";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { MANAGER_ROUTES } from "../../utils/constants";

export default function SendPoints() {
  const {
    bmapi,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  const intl = useIntl();
  const [productInfo, setProductInfo] = useState(null);
  const [qrCode, setQrCode] = useState(null);

  function handleSuccess(msg) {
    notifySuccess(msg);
    setProductInfo(null);
    setQrCode(null);
  }

  function getPermissionByMail(email) {
    startLoading();

    bmapi
      .getUsePermissionByEmail(email)
      .then((up) => {
        setQrCode(up.id);
        return bmapi.getProductInfo(up.id);
      })
      .then(setProductInfo)
      .catch((error) => {
        stopLoading();
        notifyError(getErrorMessageString(error, intl));
      });
  }

  return (
    <Container maxWidth="sm">
      <Title>
        <FormattedMessage
          id="pages.sendPoints.title"
          defaultMessage="Invia punti"
        />
      </Title>

      {productInfo ? (
        <FormManager
          onCancel={() => setProductInfo(null)}
          onSuccess={handleSuccess}
          info={productInfo}
          qrCode={qrCode}
        />
      ) : (
        <FidelityForm getPermissionByMail={getPermissionByMail} />
      )}

      <Box my={4}>
        <Button
          component={RouterLink}
          to={MANAGER_ROUTES.HOME}
          startIcon={<ArrowBack />}
        >
          <FormattedMessage
            id="common.backHome"
            defaultMessage="Torna alla home"
          />
        </Button>
      </Box>
    </Container>
  );
}
