import React from "react";

import { Grid as MuiGrid } from "@material-ui/core";

export default function Grid({
  items = [],
  render,
  large = false,
  menu = false,
}) {
  const getColByItems = () => {
    if (items.length > 9 && items.length < 16) return 3;
    else if (items.length > 16) return 2;
    else return 4;
  };

  const sizes = large
    ? { xs: 12, sm: 12, md: 6 }
    : { xs: 12, sm: 6, md: menu ? getColByItems() : 4 };
  return (
    !!items.length && (
      <MuiGrid
        container
        spacing={4}
        direction="row"
        justify="flex-start"
        alignItems="stretch"
      >
        {items.map((item, i) => (
          <MuiGrid key={i} item {...sizes}>
            {render(item)}
          </MuiGrid>
        ))}
      </MuiGrid>
    )
  );
}
