import React from "react";
import { useIntl } from "react-intl";

import { TextField } from "@material-ui/core";

import { common } from "../../../messages";
import { useBmapi } from "../../../utils/bmapi-context";

export function InvitedBy({ value, onChange, disabled }) {
  const intl = useIntl();
  const { bmapi } = useBmapi();

  return (
    <TextField
      fullWidth
      label={intl.formatMessage(common.invitedBy)}
      margin="normal"
      name="invited_by"
      onChange={onChange}
      required={bmapi.settings.profileRequired.includes("invited_by")}
      value={value}
      disabled={disabled}
    />
  );
}
