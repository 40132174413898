import React from "react";
import { useHistory } from "react-router-dom";

import { ChevronLeft } from "@material-ui/icons";
import { IconButton, Typography } from "@material-ui/core";

import styles from "../utils/styles";

export default function Title({ children, subtitle, backUrl }) {
  const classes = styles.useStyles();
  const history = useHistory();

  return (
    <header className={classes.header}>
      <Typography variant="h5">
        {backUrl && (
          <IconButton
            color="inherit"
            aria-label="back"
            onClick={() => history.push(backUrl)}
          >
            <ChevronLeft />
          </IconButton>
        )}
        {children}
      </Typography>
      {subtitle && (
        <Typography variant="h6" className={classes.subtitle}>
          {subtitle}
        </Typography>
      )}
    </header>
  );
}
