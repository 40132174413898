import React, { useState } from "react";
import { Button } from "@material-ui/core";
import GenListAdminDialog from "./GenListAdminDialog";

export default function GenListAdminButton(props) {
  const {
    style,
    dialogTitle = "Management",
    buttonProps = {},
    listId,
    businessId,
    allLang = [],
    onDialogClose,
  } = props || {};
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        {...buttonProps}
        onClick={() => setOpen(true)}
        variant="contained"
        color="primary"
        style={{ ...style, height: "40px" }}
      >
        Gestione
      </Button>
      {open && (
        <GenListAdminDialog
          open={open}
          setOpen={setOpen}
          title={dialogTitle}
          allLang={allLang}
          listId={listId}
          businessId={businessId}
          onClose={onDialogClose}
        />
      )}
    </>
  );
}
