import React, { useState } from "react";
import { useIntl } from "react-intl";

import { InputAdornment, MenuItem, TextField } from "@material-ui/core";

import NumberInput from "../../NumberInput";
import { PRODUCTS, PRODUCT_TYPES } from "../../../utils/constants";
import { common, form } from "../../../messages";

export default function ShotNumber({
  values,
  handleChange,
  campaign,
  performance,
}) {
  const intl = useIntl();
  const [shots, setShots] = useState(values.shot_number || "1");

  const handleShots = (e) => {
    setShots(e.target.value);
    handleChange("shot_number")(
      e.target.value > -2 ? e : { target: { value: "1" } }
    );
  };

  const checkError = () => {
    return (
      (campaign.rules.shot_number === -1 && +values.shot_number !== -1) ||
      (campaign &&
        +values.shot_number !== -1 &&
        +values.shot_number < campaign.rules.shot_number) ||
      false
    );
  };

  return (
    [PRODUCT_TYPES.CAMPAIGN_COUPON, PRODUCT_TYPES.CAMPAIGN_EVENT_PASS].includes(
      values.type
    ) &&
    ![PRODUCTS.COUPON_SIMPLE, PRODUCTS.COURSE_PASS].includes(
      values.front_end_type
    ) && (
      <React.Fragment>
        {/*{!!campaign || (*/}
        <TextField
          name="shots"
          label={intl.formatMessage(form.shots)}
          value={shots}
          onChange={handleShots}
          select
          fullWidth
          margin="normal"
          error={performance?.issued_qty > 0 && checkError()}
          helperText={
            performance?.issued_qty > 0 &&
            checkError() &&
            "Il numero di utilizzi non può essere diminuito"
          }
        >
          {[
            { value: "-1", label: intl.formatMessage(form.unlimited) },
            { value: "1", label: intl.formatMessage(form.singleUse) },
            { value: "2" },
            { value: "3" },
            { value: "5" },
            { value: "7" },
            { value: "10" },
            { value: "15" },
            { value: "20" },
            { value: "25" },
            { value: "30" },
            { value: "-2", label: intl.formatMessage(form.custom) },
          ].map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label || option.value}
            </MenuItem>
          ))}
        </TextField>
        {/*)}*/}
        {shots === "-2" && (
          <NumberInput
            name="shot_number"
            label={intl.formatMessage(campaign ? form.shots : form.shotsCustom)}
            value={values.shot_number}
            onChange={handleChange("shot_number")}
            fullWidth
            required
            margin="normal"
            max={25}
            error={performance?.issued_qty > 0 && checkError()}
            helperText={
              performance?.issued_qty > 0 &&
              checkError() &&
              "Il numero di utilizzi non può essere diminuito"
            }
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {intl.formatMessage(common.times)}
                </InputAdornment>
              ),
            }}
            //disabled={!!campaign}
          />
        )}
      </React.Fragment>
    )
  );
}
