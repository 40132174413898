import React, { useState, useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { Link as RouterLink, useHistory, useParams } from "react-router-dom";

import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import { Box, Button, Container } from "@material-ui/core";

import ProductUI from "../../ui/Product";
import { useBmapi } from "../../utils/bmapi-context";
import { CONSUMER_ROUTES } from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";
import { actions, common, notifications } from "../../messages";

export default function Receive() {
  const { permissionId } = useParams();
  const history = useHistory();
  const intl = useIntl();
  const {
    bmapi,
    userId,
    startLoading,
    stopLoading,
    notifySuccess,
    notifyError,
  } = useBmapi();
  const [campaign, setCampaign] = useState(null);
  const [permission, setPermission] = useState(null);

  const receive = () => {
    startLoading();

    bmapi
      .receiveProduct(permission.id)
      .then(() => {
        notifySuccess(intl.formatMessage(notifications.productReceived));
        history.push(
          CONSUMER_ROUTES.CAMPAIGN.replace(":campaignId", campaign.id)
        );
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  };

  const login = () => {
    bmapi.setCallbackUrl(window.location.href);
    history.push(CONSUMER_ROUTES.HOME);
  };

  const updateData = useCallback(() => {
    startLoading();

    bmapi
      .getPermission(permissionId)
      .then((perm) => {
        setPermission(perm);
        return bmapi.getCampaign(perm.campaign_id);
      })
      .then((campaign) => setCampaign(campaign.campaign))
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  }, [bmapi, intl, notifyError, permissionId, startLoading, stopLoading]);

  useEffect(() => {
    updateData();
  }, [updateData]);

  return (
    !!campaign && (
      <Container maxWidth="sm">
        <Box my={2}>
          <Button
            component={RouterLink}
            to={CONSUMER_ROUTES.HOME}
            startIcon={<ArrowBackIcon />}
          >
            {intl.formatMessage(common.back)}
          </Button>
        </Box>

        <Box mb={4}>
          <ProductUI
            campaign={campaign}
            cta={
              userId ? (
                <Button color="primary" onClick={receive} disableElevation>
                  {intl.formatMessage(common.accept)}
                </Button>
              ) : (
                <Button color="primary" onClick={login} disableElevation>
                  {intl.formatMessage(actions.loginOrRegister)}
                </Button>
              )
            }
            products={[]}
          />
        </Box>

        <Box my={2}>
          <Button
            component={RouterLink}
            to={CONSUMER_ROUTES.HOME}
            startIcon={<ArrowBackIcon />}
          >
            {intl.formatMessage(common.back)}
          </Button>
        </Box>
      </Container>
    )
  );
}
