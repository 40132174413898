import React, { useEffect, useCallback, useState } from "react";
import { FormattedMessage, FormattedRelativeTime, useIntl } from "react-intl";

import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  List,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  Adjust as AdjustIcon,
  ConfirmationNumber as ConfirmationNumberIcon,
  Person as PersonIcon,
  PhonelinkSetup,
  SwapHoriz as SwapHorizIcon,
} from "@material-ui/icons";

import ListItemInfo from "../ListItemInfo";
import { useBmapi } from "../../utils/bmapi-context";
import {
  FEATURES,
  TENANTS,
  TX_TYPES,
  UUID_REGEX_PREFIX,
  VARIANTS,
} from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";
import { useCampaigns } from "../../utils/campaigns";
import { common } from "../../messages";
import { endOfDay } from "date-fns";

function byDate(a, b) {
  return new Date(a.start_date) - new Date(b.start_date);
}

function future(a) {
  return new Date() <= endOfDay(new Date(a.expiration_date));
}

export default function EventPassActionForm({ info, qrCode, transactions }) {
  const intl = useIntl();
  const { bmapi, startLoading, stopLoading } = useBmapi();
  const [isBurning, setIsBurning] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [mode, setMode] = useState("checkin");
  const [campaign, setCampaign] = useState("");
  const [campaigns, setCampaigns] = useState(false);
  const { loadCampaigns } = useCampaigns();

  function onSubmit(event) {
    event.preventDefault();
    setIsBurning(true);
  }

  const burn = useCallback(() => {
    startLoading();

    const [, , uuid] = UUID_REGEX_PREFIX.exec(qrCode);

    bmapi
      .burnCoupon(
        uuid,
        bmapi.can(FEATURES.PASS_CHECKIN) ? mode : "",
        bmapi.can(FEATURES.PASS_CHECKIN) ? campaign : ""
      )
      .then(() => setSuccess(true))
      .catch((e) => setError(getErrorMessageString(e, intl)))
      .finally(() => {
        stopLoading();
        setIsBurning(false);
      });
  }, [bmapi, campaign, intl, mode, qrCode, startLoading, stopLoading]);

  useEffect(() => {
    if (isBurning) burn();
  }, [isBurning, burn]);

  useEffect(() => {
    if (bmapi.settings.fastScan) setIsBurning(true);
  }, [bmapi.settings.fastScan]);

  useEffect(() => {
    const { burnMode, burnCampaign } = bmapi.getUserData();
    loadCampaigns().then((cs) => {
      const campaignList = cs
        .filter((c) => c.campaign_data.rules.main_event_id === info.campaign_id)
        .filter(future)
        .sort(byDate);

      if (campaignList.find((c) => c.campaign_data.id === burnCampaign)) {
        setCampaign(burnCampaign);
        burnMode && setMode(burnMode);
      } else {
        setCampaign(campaignList[0].campaign_data.id);
      }
      setCampaigns(campaignList);
    });
  }, [bmapi, info, loadCampaigns]);

  useEffect(() => {
    bmapi.setUserData({ burnMode: mode, burnCampaign: campaign });
  }, [bmapi, campaign, mode]);

  const hasRestrictions = () =>
    !!info.business_restriction &&
    !info.business_restriction.includes(bmapi.getUserInfo().business.id);

  return (
    <React.Fragment>
      {hasRestrictions() && (
        <Box mb={2}>
          <Alert severity="warning">
            <FormattedMessage
              id="component.actionForm.wrongBusiness"
              defaultMessage="Coupon valido ma associato ad un altro punto vendita"
            />
          </Alert>
        </Box>
      )}
      <Card>
        <CardContent>
          <form onSubmit={onSubmit}>
            <List>
              {![TENANTS.ANCOT].includes(bmapi.tenant) && (
                <ListItemInfo
                  Icon={ConfirmationNumberIcon}
                  label={intl.formatMessage({
                    id: "common.product",
                    defaultMessage: "Prodotto",
                  })}
                  text={intl.formatMessage({
                    id: "common.campaign.eventPass",
                    defaultMessage: "Pass evento",
                  })}
                />
              )}
              {![TENANTS.ANCOT].includes(bmapi.tenant) && (
                <ListItemInfo
                  Icon={AdjustIcon}
                  label={intl.formatMessage(common.event)}
                  text={info.campaign_name}
                />
              )}
              {[TENANTS.ANCOT].includes(bmapi.tenant) && (
                <ListItemInfo
                  Icon={PersonIcon}
                  label={intl.formatMessage({
                    id: "component.actionForm.issuedTo",
                    defaultMessage: "Emessa a",
                  })}
                  text={
                    info &&
                    `${info.ancot_data.UserInfo.Name} ${info.ancot_data.UserInfo.LastName}`
                  }
                />
              )}
              {![TENANTS.ANCOT].includes(bmapi.tenant) && (
                <ListItemInfo
                  Icon={SwapHorizIcon}
                  label={intl.formatMessage({
                    id: "component.actionForm.lastTransactions",
                    defaultMessage: "Ultime transazioni",
                  })}
                  text={
                    transactions &&
                    transactions.filter((t) => t.type === TX_TYPES.BURN)
                      .length > 0 ? (
                      <React.Fragment>
                        {transactions
                          .filter((t) => t.type === TX_TYPES.BURN)
                          .map((t) => (
                            <span key={t.id} style={{ display: "block" }}>
                              <FormattedRelativeTime
                                value={
                                  (new Date(t.timestamp) - Date.now()) / 1000
                                }
                                numeric="auto"
                                updateIntervalInSeconds={10}
                              />
                            </span>
                          ))}
                      </React.Fragment>
                    ) : (
                      <FormattedMessage
                        id="component.actionForm.noTransactions"
                        defaultMessage="Nessuna transazione ancora effettuata"
                      />
                    )
                  }
                />
              )}

              {bmapi.can(FEATURES.PASS_CHECKIN) && (
                <React.Fragment>
                  <ListItemInfo Icon={PhonelinkSetup}>
                    <RadioGroup
                      value={mode}
                      onChange={(_, val) => setMode(val)}
                      row
                    >
                      <FormControlLabel
                        value="checkin"
                        control={<Radio required />}
                        label="Checkin"
                      />
                      <FormControlLabel
                        value="checkout"
                        control={<Radio required />}
                        label="Checkout"
                      />
                    </RadioGroup>
                  </ListItemInfo>
                  {mode !== "" && (
                    <ListItemInfo>
                      <TextField
                        name="campaign"
                        label={intl.formatMessage(common.event)}
                        value={campaigns ? campaign : ""}
                        onChange={(e) => setCampaign(e.target.value)}
                        select
                        fullWidth
                        variant="filled"
                        id="campaign"
                        required
                        autoFocus
                        margin="normal"
                        disabled={mode === "burn"}
                      >
                        {(campaigns || []).map((campaign) => (
                          <MenuItem
                            key={campaign.campaign_data.id}
                            value={campaign.campaign_data.id}
                          >
                            {intl.formatDate(new Date(campaign.start_date))}{" "}
                            {campaign.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </ListItemInfo>
                  )}
                </React.Fragment>
              )}
            </List>

            <FormControl fullWidth margin="normal">
              {!success && !error ? (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isBurning}
                >
                  <FormattedMessage
                    id="common.confirm"
                    defaultMessage="Conferma"
                  />
                </Button>
              ) : (
                <Alert
                  variant="filled"
                  severity={success ? VARIANTS.SUCCESS : VARIANTS.ERROR}
                >
                  {success ? (
                    <FormattedMessage
                      id="component.eventPassActionForm.burned"
                      defaultMessage="Pass usato correttamente"
                    />
                  ) : (
                    error
                  )}
                </Alert>
              )}
            </FormControl>
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}
