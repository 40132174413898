import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import Title from "../ui/Title";
import { useBmapi } from "../utils/bmapi-context";
import { useIntl } from "react-intl";
import { getErrorMessageString } from "../utils/errors";
import {
  ArrowBack,
  ArrowDownward,
  ArrowUpward,
  FiberManualRecord,
} from "@material-ui/icons";
import utils, { parseBmeIntToInt } from "../utils/utils";
import { INIT_QUERY_PRIZES } from "../utils/gdspConstants";
import { differenceInDays, format } from "date-fns";
import { useHistory } from "react-router-dom";
import { MANAGER_ROUTES } from "../utils/constants";
import { common } from "../messages";
import PrizesDetails from "./PrizesDetails";
import ActionsDetails from "./ActionsDetails";
import Confirm from "../ui/Confirm";

export default function PrizesStatistics() {
  const initialOrder = useMemo(
    () => ({
      status: "",
      email: "",
      prize: "",
      received: "",
    }),
    []
  );
  const intl = useIntl();
  const history = useHistory();
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const [prizes, setPrizes] = useState([]);
  const [query, setQuery] = useState(INIT_QUERY_PRIZES);
  const [count, setCount] = useState(0);
  const [actions, setActions] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [points, setPoints] = useState(0);
  const [userPrizes, setUserPrizes] = useState([]);
  const [showData, setShowData] = useState(false);
  const [disableUser, setDisableUser] = React.useState(false);
  const [ordered, setOrdered] = useState(initialOrder);

  points;

  const changeStatusLabel =
    selectedUser?.status == 0 ? "Disabilita Utente" : "Abilita Utente";
  const changeStatusMessage =
    selectedUser?.status == 0 ? "disabilitare" : "abilitare";
  const action = selectedUser?.status == 0 ? "DISABLE" : "ENABLE";

  const goToHome = () => {
    history.push(MANAGER_ROUTES.HOME_MENU);
  };

  const checkIfEnabled = (date) => {
    const created = new Date(date);
    const today = new Date();

    return differenceInDays(today, created) > 7;
  };

  const getIconColor = (status, date) => {
    let color;
    const isEnabled = checkIfEnabled(date);

    if (status === 0 && isEnabled) {
      color = "orange";
    } else if (status === 0 && !isEnabled) {
      color = "red";
    } else if (status === 3) {
      color = "green";
    } else {
      color = "red";
    }

    return color;
  };

  // get count: getUserStatsWithPointsCount
  // get users: getUserStatsWithPoints

  const getPrizes = useCallback(
    (q) => {
      startLoading();
      if (bmapi) {
        bmapi
          .getUsersPrizesStats(q || query)
          .then((resp) => {
            setPrizes(resp.prizes);
            setCount(resp.total_prizes);
            stopLoading();
          })
          .catch((e) => {
            notifyError(getErrorMessageString(e, intl));
            stopLoading();
          });
      }
    },
    [bmapi, intl, notifyError, startLoading, stopLoading, query]
  );

  useEffect(() => {
    getPrizes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const download = () => {
    startLoading();
    bmapi
      .getPrizesStats()
      .then((blob) => utils.download(blob, "export.csv"))
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  };

  const handleChangeOrderBy = useCallback(
    (value, varName) => {
      setOrdered(initialOrder);
      setOrdered((v) => ({
        ...v,
        [varName]: value.startsWith("-") ? "minor" : "major",
      }));

      setQuery({
        ...INIT_QUERY_PRIZES,
        orderBy: value,
      });
      getPrizes({
        ...INIT_QUERY_PRIZES,
        orderBy: value,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialOrder]
  );

  const handleChangePage = (goTop) => (event, newPage) => {
    setQuery((v) => ({ ...v, page: newPage }));
    getPrizes({ ...query, page: newPage });
    if (goTop) window.scrollTo(0, 0);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setQuery((v) => ({ ...v, qty: newRowsPerPage, page: 0 }));
    getPrizes({ ...query, qty: newRowsPerPage, page: 0 });
  };

  const getWelcomeRow = (createdAt) => {
    const row = {
      action_date: createdAt,
      business_name: "SposiClub.it",
      created_at: createdAt,
      id: "welcome-0000",
      status: "CONFIRMED",
      type: "GIFT",
      reward: 1000,
    };

    return row;
  };

  const showActions = (userId) => {
    startLoading();
    const user = bmapi.getUser(userId);
    let u;
    user
      .then((resp) => {
        u = resp;
        return bmapi.getAllRewardingActions({ user: u.id });
      })
      .then((act) => {
        if (act && act !== null) {
          //aggiungo l'interazione finta di benvenuto con 10 punti
          act.push(getWelcomeRow(u.created_at));
          setActions(act);
        } else {
          let act = [];
          act.push(getWelcomeRow(u.created_at));
          setActions(act);
        }
        return bmapi.getUserInspectionProducts("", { user: u.id });
      })
      .then((products) => {
        let card = null;
        let prizes = [];

        products.map((product) => {
          if (product.default) {
            card = product;
          } else {
            prizes.push(product);
          }
        });
        setSelectedUser(u);
        setPoints(card !== null ? parseBmeIntToInt(card.available_value) : 0);
        setUserPrizes(prizes);
        setShowData(true);
        stopLoading();
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
        stopLoading();
      });
  };

  const changeStatus = (action, user) => {
    startLoading();

    if (action === "ENABLE") {
      bmapi
        .enableUser(user, "gdsp-x-bme")
        .then(() => {
          handleClose();
          getPrizes();
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    } else {
      bmapi
        .disableUser(user, "gdsp-x-bme")
        .then(() => {
          handleClose();
          getPrizes();
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }

    stopLoading();
  };

  const handleClose = () => {
    setShowData(false);
    setPoints(0);
    setActions([]);
    setUserPrizes([]);
  };

  return (
    <React.Fragment>
      <Title>Statistiche Premi</Title>

      {/*<Container maxWidth="sm">
        <Box mb={5}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="demo-simple-select-helper-label">
              Ordina per
            </InputLabel>
            <Select
              id="select-orderby"
              value={query.orderBy}
              onChange={handleChangeOrderBy}
            >
              {PRIZES_ORDER.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
              </Container>*/}

      <Box my={2}>
        <Button onClick={goToHome} startIcon={<ArrowBack />}>
          {intl.formatMessage(common.backHome)}
        </Button>
      </Box>

      <Card>
        <CardContent>
          <Box mb={5} mt={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={download}
              style={{ float: "right" }}
            >
              Scarica report
            </Button>
            <FiberManualRecord
              fontSize="small"
              style={{
                color: "green",
                marginLeft: 25,
              }}
            />
            <Typography variant="caption">Premi utilizzati</Typography>
            <FiberManualRecord
              fontSize="small"
              style={{
                color: "orange",
                marginLeft: 25,
              }}
            />
            <Typography variant="caption">Premi utilizzabili</Typography>
            <FiberManualRecord
              fontSize="small"
              style={{
                color: "red",
                marginLeft: 25,
              }}
            />
            <Typography variant="caption">
              Premi non ancora utilizzabili
            </Typography>
          </Box>
          <TableContainer>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    onClick={() =>
                      handleChangeOrderBy(
                        ordered.status === "minor" ? "Status" : "-Status",
                        "status"
                      )
                    }
                    style={{ cursor: "pointer" }}
                    hover
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      Stato
                    </Typography>
                    {ordered.status && (
                      <IconButton style={{ padding: 0 }}>
                        {ordered.status === "minor" ? (
                          <ArrowDownward fontSize="small" />
                        ) : (
                          <ArrowUpward fontSize="small" />
                        )}
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell
                    onClick={() =>
                      handleChangeOrderBy(
                        ordered.email === "minor" ? "Email" : "-Email",
                        "email"
                      )
                    }
                    style={{ cursor: "pointer" }}
                    hover
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      Email
                    </Typography>
                    {ordered.email && (
                      <IconButton style={{ padding: 0 }}>
                        {ordered.email === "minor" ? (
                          <ArrowDownward fontSize="small" />
                        ) : (
                          <ArrowUpward fontSize="small" />
                        )}
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() =>
                      handleChangeOrderBy(
                        ordered.prize === "minor" ? "Prize" : "-Prize",
                        "prize"
                      )
                    }
                    style={{ cursor: "pointer" }}
                    hover
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      Premio
                    </Typography>
                    {ordered.prize && (
                      <IconButton style={{ padding: 0 }}>
                        {ordered.prize === "minor" ? (
                          <ArrowDownward fontSize="small" />
                        ) : (
                          <ArrowUpward fontSize="small" />
                        )}
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() =>
                      handleChangeOrderBy(
                        ordered.received === "minor" ? "Received" : "-Received",
                        "received"
                      )
                    }
                    style={{ cursor: "pointer" }}
                    hover
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      Data richiesta
                    </Typography>
                    {ordered.received && (
                      <IconButton style={{ padding: 0 }}>
                        {ordered.received === "minor" ? (
                          <ArrowDownward fontSize="small" />
                        ) : (
                          <ArrowUpward fontSize="small" />
                        )}
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>Utilizzabile dal</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {prizes.map((p) => (
                  <TableRow
                    key={p.prize_id}
                    onClick={() => showActions(p.user_id)}
                    style={{ cursor: "pointer" }}
                    hover={true}
                  >
                    <TableCell>
                      <FiberManualRecord
                        style={{
                          color: getIconColor(p.status, p.prize_received_date),
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {p.user_last_name
                        ? p.user_last_name + " " + p.user_name
                        : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {p.user_email || "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {p.prize_name || "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {p.prize_received_date
                        ? format(new Date(p.prize_received_date), "dd-MM-yyyy")
                        : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {p.prize_usable_date
                        ? format(new Date(p.prize_usable_date), "dd-MM-yyyy")
                        : "-"}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={count}
              rowsPerPage={query.qty}
              page={query.page}
              onChangePage={handleChangePage(true)}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </CardContent>
      </Card>

      {/* DIALOG USER PRIZES */}

      <Dialog open={showData} onClose={handleClose} fullWidth maxWidth="md">
        <DialogContent>
          <Box mb={3}>
            {selectedUser?.picture ? (
              <CardMedia
                image={selectedUser.picture}
                style={{
                  height: "100px",
                  width: "100px",
                  margin: "0 auto",
                }}
              />
            ) : (
              <Avatar
                style={{
                  height: "100px",
                  width: "100px",
                  margin: "0 auto",
                }}
              />
            )}
            <Typography align="center">
              {`${selectedUser?.complete_name} ${selectedUser?.last_name}`}
            </Typography>
            <Typography align="center">{`(${selectedUser?.email})`}</Typography>
            <Button
              style={{ display: "block", margin: "20px auto" }}
              variant="contained"
              color="primary"
              onClick={() => setDisableUser(true)}
            >
              {changeStatusLabel}
            </Button>
          </Box>

          {/* TABELLA PREMI */}
          <PrizesDetails prizes={userPrizes} />
          {/* TABELLA AZIONI */}
          <ActionsDetails showQRCode={null} title={null} actions={actions} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleClose}>
            {intl.formatMessage(common.close)}
          </Button>
        </DialogActions>
      </Dialog>

      {disableUser && (
        <Confirm
          open={!!disableUser}
          onConfirm={() => changeStatus(action, selectedUser?.id)}
          onCancel={() => setDisableUser(false)}
          text={
            "Vuoi " +
            changeStatusMessage +
            " l'accesso e l'utilizzo dell'applicazione all'utente " +
            selectedUser?.email +
            "?"
          }
        />
      )}

      <Box my={2}>
        <Button onClick={goToHome} startIcon={<ArrowBack />}>
          {intl.formatMessage(common.backHome)}
        </Button>
      </Box>
    </React.Fragment>
  );
}
