import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  TextField,
} from "@material-ui/core";
import { Create, Delete, AddCircle } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { account, common, confirm, form } from "../messages";
import Confirm from "../ui/Confirm";
import { useBmapi } from "../utils/bmapi-context";
import { getErrorMessageString } from "../utils/errors";

const byName = (a, b) => {
  return a.values[0].str_value.localeCompare(b.values[0].str_value);
};

export default function ManageGenList({ open, setOpen, title, loadData }) {
  const initialValues = { name: "" };
  const initialItem = { id: "", index: "" };

  const intl = useIntl();
  const {
    bmapi,
    notifyError,
    startLoading,
    stopLoading,
    notifySuccess,
  } = useBmapi();
  const [genList, setGenList] = useState([]);
  const [openHandleList, setOpenHandleList] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);
  const [item, setItem] = useState(initialItem);
  const [values, setValues] = useState(initialValues);

  const handleValue = useCallback((label) => {
    const updateValue = (val) => {
      setValues((v) => ({ ...v, [label]: val }));
    };

    return (i, f) => {
      if (typeof f === "boolean") updateValue(f);
      else if (i?.target) updateValue(i.target.value);
      else updateValue(i);
    };
  }, []);

  const loadGenList = useCallback(() => {
    startLoading();

    bmapi
      .getGenListCategoriesAdmin()
      .then((resp) => {
        if (!resp) {
          resp = [];
        }
        setGenList(resp);
      })
      .catch((e) => {
        /*if (e?.code === "NOT_FOUND") {
          setGenList([]);
        } else {*/
        console.log(console.log(JSON.stringify(e, null, 4)));
        notifyError(getErrorMessageString(e, intl));
        //}
      })
      .finally(() => {
        stopLoading();
      });
  }, [bmapi, intl, notifyError, startLoading, stopLoading]);

  useEffect(() => {
    loadGenList();
  }, [loadGenList]);

  const update = (action) => {
    switch (action) {
      case "create":
        genList.items?.push({
          item_id: "",
          values: [{ str_value: values.name }],
        });
        break;
      case "modify":
        genList.items.splice(item.index, 1, {
          item_id: item.id,
          values: [{ str_value: values.name }],
        });
        break;
      case "delete":
        genList.items.splice(item.index, 1);
    }

    setOpenHandleList(false);
    setValues(initialValues);
    setItem(initialItem);
    setDeleteRequest(false);

    startLoading();

    bmapi
      .updateGenListCategories(genList)
      .then(() => {
        notifySuccess(
          deleteRequest
            ? intl.formatMessage(common.deleteElement)
            : intl.formatMessage(account.saveConfirm)
        );
      })
      .then(() => {
        loadGenList();
        loadData();
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  };

  const handleModify = (item, i) => {
    setOpenHandleList(true);
    setItem({ id: item.item_id, index: i });
    setValues({ ...values, name: item.values[0].str_value });
  };

  const handleDelete = (item, i) => {
    setItem({ id: item.item_id, index: i });
    setDeleteRequest(true);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{title}</DialogTitle>

        <DialogContent>
          {genList?.items?.sort(byName).map((item, i) => (
            <List key={item.item_id}>
              <ListItem>{item.values[0].str_value}</ListItem>
              <ListItemSecondaryAction>
                <IconButton onClick={() => handleModify(item, i)}>
                  <Create fontSize="small" />
                </IconButton>
                <IconButton>
                  <Delete
                    fontSize="small"
                    onClick={() => handleDelete(item, i)}
                  />
                </IconButton>
              </ListItemSecondaryAction>
            </List>
          ))}

          <IconButton onClick={() => setOpenHandleList(true)}>
            <AddCircle color="primary" />
          </IconButton>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            {intl.formatMessage(common.close)}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openHandleList}
        onClose={() => setOpenHandleList(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <TextField
            margin="normal"
            label={intl.formatMessage(form.name)}
            value={values.name}
            onChange={handleValue("name")}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenHandleList(false);
              setValues(initialValues);
            }}
            color="primary"
          >
            {intl.formatMessage(common.cancel)}
          </Button>
          <Button
            onClick={() => update(item.id ? "modify" : "create")}
            color="primary"
            variant="contained"
            disabled={!values.name}
          >
            {intl.formatMessage(common.confirm)}
          </Button>
        </DialogActions>
      </Dialog>

      {deleteRequest && (
        <Confirm
          open={!!deleteRequest}
          onConfirm={() => update("delete")}
          onCancel={() => setDeleteRequest(false)}
          text={intl.formatMessage(confirm.deleteElement)}
        />
      )}
    </React.Fragment>
  );
}
