import { defineMessages } from "react-intl";

export const errors = defineMessages({
  campaignExpirationPast: {
    id: "errors.campaignExpirationPast",
    defaultMessage: "La campagna non puo' terminare la distribuzione oggi",
  },
  campaignNotDeletable: {
    id: "errors.campaignNotDeletable",
    defaultMessage:
      "Impossibile eliminare la campagna. Sono già stati emessi dei prodotti",
  },
  demoLimitReached: {
    id: "errors.demoLimitReached",
    defaultMessage: "Campagna demo: numero massimo di prodotti già raggiunto",
  },
  emailAlreadyInUse: {
    id: "errors.emailAlreadyInUse",
    defaultMessage: "L'indirizzo email inserito risulta già registrato",
  },
  error: {
    id: "errors.error",
    defaultMessage:
      "Si è verificato un errore. Riprovare tra qualche istante o contattare il supporto",
  },
  expiredProduct: {
    id: "errors.expiredProduct",
    defaultMessage: "Il prodotto scansionato risulta scaduto",
  },
  expiredQrCode: {
    id: "errors.expiredQrCode",
    defaultMessage:
      "Il QR Code mostrato risulta scaduto. Chiedere all'utente di aggiornare il QR Code e riprovare la scansione",
  },
  frequency: {
    id: "errors.frequency",
    defaultMessage: "Numero massimo di utilizzi raggiunto per il periodo",
  },
  invalidCode: {
    id: "errors.invalidCode",
    defaultMessage: "Il codice non appartiene ad un prodotto valido",
  },
  maxProducts: {
    id: "errors.maxProducts",
    defaultMessage:
      "L'utente ha già raggiunto il limite massimo di prodotti per questa campagna",
  },
  notAuthorized: {
    id: "errors.notAuthorized",
    defaultMessage:
      "Il codice non appartiene a un prodotto gestito dallo user autenticato",
  },
  ordersLimitExceeded: {
    id: "errors.ordersLimitExceeded",
    defaultMessage:
      "Hai già acquistato questo prodotto e non puoi acquistarlo di nuovo",
  },
  permissionError: {
    id: "errors.permissionError",
    defaultMessage: "Operazione non consentita",
  },
  planQuotaExceededBusiness: {
    id: "errors.planQuotaExceededBusiness",
    defaultMessage: "Raggiunto il limite massimo di negozi",
  },
  planQuotaExceededBusinessManager: {
    id: "errors.planQuotaExceededBusinessManager",
    defaultMessage: "Raggiunto il limite massimo di manager per questo negozio",
  },
  tokenNotAuthorized: {
    id: "errors.tokenNotAuthorized",
    defaultMessage:
      "Non è possibile eseguire l'operazione richiesta: non si possiedono i permessi necessari",
  },
  notSigned: {
    id: "errors.notSigned",
    defaultMessage:
      "Per utilizzare il prodotto scansionato devi prima aderire alla campagna",
  },
  usedProduct: {
    id: "errors.usedProduct",
    defaultMessage: "ll prodotto risulta già utilizzato",
  },
  userNotFound: {
    id: "errors.userNotFound",
    defaultMessage: "La mail inserita non appartiene ad un utente registrato",
  },
  userNotVerified: {
    id: "errors.userNotVerified",
    defaultMessage: "Prima di accedere devi certificare la tua mail",
  },
  wrongUser: {
    id: "errors.wrongUser",
    defaultMessage: "Email o password errati",
  },
  maxQtyExcedeed: {
    id: "errors.maxQtyExcedeed",
    defaultMessage: "Prodotti esauriti",
  },
  primaryMandatory: {
    id: "errors.primaryMandatory",
    defaultMessage:
      "Per ottenere questo prodotto è necessario aderire all'evento principale",
  },
  codesSoldOut: {
    id: "errors.codesSoldOut",
    defaultMessage: "Prodotti esauriti",
  },
  wrongFriendCode: {
    id: "errors.wrongFriendCode",
    defaultMessage: "Codice amico sbagliato",
  },
  txLimitPerMerchantExceeded: {
    id: "errors.txLimitPerMerchantExceeded",
    defaultMessage:
      "Il cliente ha raggiunto il numero massimo di transazioni giornaliere sul negozio",
  },
  existingOperationIdInTx: {
    id: "errors.existingOperationIdInTx",
    defaultMessage: "Codice già utilizzato",
  },
  genericError: {
    id: "errors.genericError",
    defaultMessage:
      "Si è verificato un errore. Ritenta l'operazione o contatta il supporto",
  },
  cardNotFound: {
    id: "errors.cardNotFound",
    defaultMessage: "Non hai aderito alla campagna",
  },
  stampNotFound: {
    id: "errors.stampNotFound",
    defaultMessage: "Bollino non riconosciuto",
  },
  syncCRM: {
    id: "errors.syncCRM",
    defaultMessage: "Errore di sincronizzazione con il CRM",
  },
  productNotFound: {
    id: "errors.productNotFound",
    defaultMessage: "Utente non registrato all’evento",
  },
  wrongBusiness: {
    id: "errors.wrongBusiness",
    defaultMessage:
      "Il Coupon/Gift Cart non può essere utilizzabile presso questo punto vendita",
  },
  // authentication errors
  missingFacebookAuthEmailText: {
    id: "errors.missingFacebookAuthEmailText",
    defaultMessage:
      "Si è verificato un problema con la tua autenticazione tramite Facebook. Verifica di aver acconsentito a mostrare la tua email. Per maggiori informazioni vai al link qui sotto o invia una mail a support-bmarken@liberacta.com",
  },
  authErrorTitle: {
    id: "errors.authErrorTitle",
    defaultMessage: "Errore di autenticazione",
  },
  missingFacebookAuthEmailLinkURL: {
    id: "errors.missingFacebookAuthEmailLinkURL",
    defaultMessage: "https://liberacta.com/en/facebook-auth-it/",
  },
  moreInfoLabel: {
    id: "errors.moreInfoLabel",
    defaultMessage: "Maggiori informazioni",
  },
  //order configuration errors
  equalTime: {
    id: "errors.equalTime",
    defaultMessage:
      "L'orario di inizio non può essere uguale o successivo all'orario di fine",
  },
  slotOverlaps: {
    id: "errors.slotOverlaps",
    defaultMessage: "Lo slot selezionato si sovrappone a uno già esistente",
  },
  permissionDenied: {
    id: "errors.permissionDenied",
    defaultMessage: "Permesso negato",
  },
  positionUnavailable: {
    id: "errors.positionUnavailable",
    defaultMessage: "Informazioni sulla posizione non disponibili",
  },
  timeout: {
    id: "errors.timeout",
    defaultMessage: "Richiesta di posizione scaduta",
  },
  unknownError: {
    id: "errors.unknownError",
    defaultMessage: "Errore sconosciuto",
  },
  gpsNotSupported: {
    id: "errors.gpsNotSupported",
    defaultMessage: "Geolocalizzazione non supportata o lato server",
  },
});
