import React from "react";
import { useIntl } from "react-intl";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
  useTheme,
} from "@material-ui/core";
import { ExitToApp, Lens } from "@material-ui/icons";

import { common } from "../../messages";
import { useBmapi } from "../../utils/bmapi-context";

export default function CardEventRunning({ lesson, onUpdate }) {
  const intl = useIntl();
  const theme = useTheme();
  const { bmapi, notifySuccess } = useBmapi();

  const checkoutEvent = () => {
    return bmapi.checkoutEvent(lesson.business_event_id).then(() => {
      onUpdate();
      notifySuccess(
        intl.formatMessage({
          id: "common.checkoutDone",
          defaultMessage: "Effettuato chekout",
        })
      );
    });
  };

  return (
    <Card>
      <CardContent>
        <Grid
          container
          spacing={1}
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="h5">
              {intl.formatMessage(common.eventRunning)}
            </Typography>
          </Grid>
          <Grid item>
            <Lens style={{ color: theme.palette.error.main }} />
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          startIcon={<ExitToApp />}
          color="primary"
          onClick={checkoutEvent}
        >
          {intl.formatMessage({
            id: "common.doCheckout",
            defaultMessage: "Effettua checkout",
          })}
        </Button>
      </CardActions>
    </Card>
  );
}
