export const PERIOD_TYPE = [
  {
    id: "DAY",
    label: "date",
  },
  {
    id: "H",
    label: "hours",
  },
  {
    id: "D",
    label: "days",
  },
  {
    id: "W",
    label: "weeks",
  },
  {
    id: "M",
    label: "months",
  },
];
