import React, { useState } from "react";
import { Box } from "@material-ui/core";
import GenListDropdown from "./GenListDropdown";
import GenListAdminButton from "./GenListAdminButton";

export default function GenListDropdownAdmin({
  onChange,
  value,
  businessId,
  allLang,
  currentLang,
  listId,
  dialogTitle,
  name,
  label,
  multiple,
}) {
  const [refreshKey, setRefreshKey] = useState(0);
  const onDialogClose = (changeApplied) => {
    if (changeApplied) {
      setRefreshKey((prevKey) => prevKey + 1);
    }
  };
  return (
    <Box display="flex" mt={2} alignItems="flex-end">
      <GenListDropdown
        onChange={onChange}
        margin="dense"
        label={label}
        listId={listId}
        value={value}
        name={name}
        multiple={multiple}
        lang={currentLang}
        businessId={businessId}
        refreshKey={refreshKey}
      />
      <GenListAdminButton
        dialogTitle={dialogTitle}
        listId={listId}
        businessId={businessId}
        allLang={allLang}
        onDialogClose={onDialogClose}
      />
    </Box>
  );
}
