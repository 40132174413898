import React from "react";
import { useIntl } from "react-intl";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";

import { activation } from "../../../messages";
import { useBmapi } from "../../../utils/bmapi-context";

export function Privacy({ value, onChange, formatValues }) {
  const intl = useIntl();
  const { bmapi } = useBmapi();

  return (
    <FormControl margin="normal" fullWidth>
      <Typography variant="h6" gutterBottom>
        {intl.formatMessage(activation.privacyTitle, formatValues)}
      </Typography>
      <Typography gutterBottom>
        {intl.formatMessage(activation.privacyPart1, formatValues)}
      </Typography>
      <Typography gutterBottom>
        {intl.formatMessage(activation.privacyPart2, formatValues)}
      </Typography>
      {bmapi.settings.privacySingleCheck ? (
        <FormControlLabel
          control={<Checkbox required onChange={onChange} color="primary" />}
          label={intl.formatMessage(activation.agreePrivacy)}
        />
      ) : (
        <RadioGroup row value={value} onChange={onChange}>
          <FormControlLabel
            value={true}
            control={<Radio required color="primary" />}
            label={intl.formatMessage(activation.agreePrivacy)}
          />
          <FormControlLabel
            value={false}
            control={<Radio required color="primary" />}
            label={intl.formatMessage(activation.dontAgreePrivacy)}
          />
        </RadioGroup>
      )}
    </FormControl>
  );
}
