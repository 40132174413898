import React, { useEffect } from "react";
//import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useBmapi } from "../../utils/bmapi-context";

export default function ProductsInventory() {
  const { bmapi } = useBmapi();

  useEffect(() => {}, [bmapi]);

  return <div>Products inventory</div>;
}
