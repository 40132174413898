import React from "react";
import { useIntl } from "react-intl";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";

import { common } from "../messages";
// import { common, subscriptions } from "../messages";

export default function PlanLimitAlert({ open, onClose }) {
  const intl = useIntl();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        {intl.formatMessage({
          id: "subscriptions.limitReached",
          defaultMessage:
            "Raggiunto il limite del tuo abbonamento. Aggiorna il tuo piano.",
        })}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {intl.formatMessage(common.close)}
        </Button>
        {/* <Button variant="contained" color="primary">
          {intl.formatMessage(subscriptions.changePlan)}
        </Button> */}
      </DialogActions>
    </Dialog>
  );
}
