import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Card,
  Container,
  Divider,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import Title from "../../ui/Title";
import { Add, Create, Delete, MoreVert } from "@material-ui/icons";
import FloatingActions from "../../ui/FloatingActions";
import { useIntl } from "react-intl";
import { common } from "../../messages";
import { MANAGER_ROUTES } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";

export default function AdminSubscriptions() {
  const intl = useIntl();
  const history = useHistory();
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const [subscriptions, setSubscriptions] = useState([]);
  const [slug, setSlug] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = (e) => {
    const target = e.currentTarget;
    setAnchorEl(target);
  };

  const loadSubscriptions = useCallback(() => {
    startLoading();
    if (bmapi) {
      bmapi
        .getAdminSubscriptions()
        .then((resp) => {
          if (!resp) {
            resp = [];
            setSubscriptions(resp);
          } else {
            setSubscriptions(resp);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    }
  }, [bmapi, intl, notifyError, startLoading, stopLoading]);

  useEffect(() => {
    loadSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadSubscriptions]);

  return (
    <Container maxWidth="sm">
      <Title>Gestione Abbonamenti</Title>
      {subscriptions.length > 0 ? (
        <React.Fragment>
          <TextField
            name="slug"
            label="Filtra per slug"
            value={slug}
            onChange={(e) => setSlug(e.target.value)}
            fullWidth
            margin="dense"
          />

          <Box mt={2}>
            <Card>
              {subscriptions
                .filter((sub) => sub.slug.includes(slug))
                .map((s, i) => (
                  <React.Fragment key={s.slug}>
                    {i !== 0 && <Divider />}
                    <List>
                      <ListItem>
                        <ListItemText primary={s.name} secondary={s.slug} />
                      </ListItem>
                      <ListItem>
                        <ListItemText primary={`Tenant: ${s.tenant}`} />
                      </ListItem>
                      <ListItem>
                        <ListItemText
                          primary={`SuperTenant: ${s.super_tenant}`}
                        />
                      </ListItem>
                      <ListItemSecondaryAction>
                        <IconButton onClick={handleOpen}>
                          <MoreVert />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </List>
                  </React.Fragment>
                ))}
            </Card>
          </Box>
        </React.Fragment>
      ) : (
        <Box mt={2}>
          <Typography>Non ci sono abbonamenti.</Typography>
        </Box>
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem>
          <ListItemIcon>
            <Create fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">
            {intl.formatMessage(common.modify)}
          </Typography>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">
            {intl.formatMessage(common.delete)}
          </Typography>
        </MenuItem>
      </Menu>

      <FloatingActions>
        <Fab
          variant="extended"
          color="primary"
          onClick={() => history.push(MANAGER_ROUTES.SUBSCRIPTION_CREATE)}
        >
          <Add />
          {intl.formatMessage(common.add)}
        </Fab>
      </FloatingActions>
    </Container>
  );
}
