import React from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import PasswordForgetForm from "../ui/PasswordForgetForm";
import { useBmapi } from "../utils/bmapi-context";
import { CONSUMER_ROUTES, MANAGER_ROUTES } from "../utils/constants";

export default function PasswordReset() {
  const { bmapi, notifySuccess, startLoading, stopLoading } = useBmapi();
  const intl = useIntl();
  const history = useHistory();

  const routes = bmapi.isConsumer() ? CONSUMER_ROUTES : MANAGER_ROUTES;

  function onSubmit(email) {
    startLoading();

    return bmapi.doPasswordReset(email).finally(() => {
      history.push(routes.SIGN_IN);
      stopLoading();
      notifySuccess(
        intl.formatMessage({
          id: "pages.passwordReset.comfirm",
          defaultMessage:
            "Email inviata, controlla la tua casella di posta elettronica",
        })
      );
    });
  }

  return <PasswordForgetForm onSubmit={onSubmit} routes={routes} />;
}
