import React from "react";
import { useIntl } from "react-intl";

import { CircularProgress, Grid, Typography } from "@material-ui/core";

export default function ProductDetails({ label, value }) {
  const intl = useIntl();
  return (
    <Grid
      alignItems="baseline"
      container
      direction="row"
      justify="space-between"
      spacing={0}
    >
      <Grid item xs="auto">
        <Typography variant="body2">{label}</Typography>
      </Grid>
      <Grid item xs="auto">
        {value === false ? (
          <CircularProgress size={14} thickness={7} />
        ) : (
          <Typography variant="body2">
            {typeof value === "number" ? intl.formatNumber(value) : value}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
