import React from "react";

import { Box, Chip, LinearProgress } from "@material-ui/core";

export default function LimitProgress({ value, limit }) {
  return (
    <Box mt={1} mb={2} display="flex" alignItems="center">
      <Chip color="primary" label={`${value}/${limit}`} />
      <Box width="100%" ml={1}>
        <LinearProgress
          variant="determinate"
          value={((value || 0) / limit) * 100}
        />
      </Box>
    </Box>
  );
}
