import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, Link as RouterLink } from "react-router-dom";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Sector,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import {
  Add,
  Adjust,
  ArrowBack,
  CenterFocusStrong,
  DoneAll,
  Loyalty,
  PlayArrow,
  Schedule,
} from "@material-ui/icons";

import CreateCampaignButton from "../../ui/CreateCampaignButton";
import FloatingActions, { Action } from "../../ui/FloatingActions";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { useCampaigns } from "../../utils/campaigns";
import { FEATURES, MANAGER_ROUTES, PRODUCT_TYPES } from "../../utils/constants";
import { campaign, common, navigation } from "../../messages";
import styles from "../../utils/styles";
import { DatePicker } from "../../ui/DatePicker";
import { addYears, format } from "date-fns";

const SimpleBarChart = ({ data, dataMax }) => {
  return (
    <ResponsiveContainer width="100%" height={data.length * 40}>
      <BarChart data={data} layout="vertical">
        <YAxis
          dataKey="zip"
          type="category"
          axisLine={false}
          tickLine={false}
          width={120}
        />
        <XAxis type="number" hide={true} domain={[0, dataMax || "dataMax"]} />
        <Bar dataKey="value" stackId="a">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.fill} />
          ))}
        </Bar>
        <Bar dataKey="loop" stackId="a">
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.fillLoop} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default function Dashboard() {
  const { bmapi, businessId, toggleLoading } = useBmapi();
  const intl = useIntl();
  const history = useHistory();
  const { campaigns, loadCampaigns } = useCampaigns();
  const classes = styles.useStyles();
  const [stats, setStats] = useState();
  const [filter, setFilter] = useState("play");
  const [from, setFrom] = useState(addYears(new Date(), -2));
  const [to, setTo] = useState(new Date());
  const [state, setState] = useState({ activeIndex: 0 });

  const byDate = (val) => {
    return (
      format(new Date(val.date), "yyyy-MM") >= format(from, "yyyy-MM") &&
      format(new Date(val.date), "yyyy-MM") <= format(to, "yyyy-MM")
    );
  };

  const formatValues = (val) => {
    if (filter === "age") {
      return `${((val / 5022) * 100).toFixed(2)}%`; // 5022 totale
    } else {
      return val.toLocaleString("it-IT");
    }
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            zIndex: 100,
            backgroundColor: "#fff",
            padding: 10,
            border: "1px solid #ddd",
          }}
        >
          <Typography>{label}</Typography>
          {payload.map((p) => (
            <Typography key={p.name} style={{ color: p.fill }}>{`${
              p.name
            }: ${formatValues(p.value)}`}</Typography>
          ))}
        </div>
      );
    }

    return null;
  };

  const onPieEnter = (_, index) => {
    setState({
      activeIndex: index,
    });
  };

  const renderActiveShape = (props, fill) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      payload,
      percent,
      //value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={8}
          textAnchor="middle"
          fill={
            payload.gender
              ? payload.gender === "Maschio"
                ? fill[1]
                : fill[0]
              : fill
          }
          fontSize={20}
        >
          {payload.gender || payload.work || `cap: ${payload.zip}`}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={
            payload.gender
              ? payload.gender === "Maschio"
                ? fill[1]
                : fill[0]
              : fill
          }
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={
            payload.gender
              ? payload.gender === "Maschio"
                ? fill[1]
                : fill[0]
              : fill
          }
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={
            payload.gender
              ? payload.gender === "Maschio"
                ? fill[1]
                : fill[0]
              : fill
          }
          fill="none"
        />
        <circle
          cx={ex}
          cy={ey}
          r={2}
          fill={
            payload.gender
              ? payload.gender === "Maschio"
                ? fill[1]
                : fill[0]
              : fill
          }
          stroke="none"
        />
        {/*  <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
          fontSize={16}
        >
          {value}
        </text>*/}
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
          fontSize={16}
        >
          {`${(percent * 100).toFixed(2)}%`}
        </text>
      </g>
    );
  };

  const axisFormatter = (values) => {
    return `${((values / 5022) * 100).toFixed(0)}%`;
  };

  const getChart = () => {
    if (filter === "new_customer" || filter === "age") {
      return (
        <BarChart
          data={currentCard.data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey={filter === "new_customer" ? "date" : "age"}
            angle={-30}
            textAnchor="end"
            height={50}
            interval={0}
          />
          <YAxis tickFormatter={axisFormatter} />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey="value"
            fill={filter === "new_customer" ? "#8884d8" : "#00BAE4"}
          />
        </BarChart>
      );
    } else if (filter === "play") {
      return (
        <BarChart
          width={500}
          height={300}
          data={currentCard.data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="date"
            angle={-30}
            textAnchor="end"
            height={50}
            interval={0}
          />
          <YAxis />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey="bollino" stackId="a" fill="#8884d8" />
          <Bar dataKey="visite" stackId="a" fill="#FFA500" />
          <Bar dataKey="green" stackId="a" fill="#03c04a" />
          <Bar dataKey="bonus_cat" stackId="a" fill="#d03216" />
          <Bar dataKey="eco_comp" stackId="a" fill="#00BAE4">
            <LabelList
              dataKey={(v) => {
                return v.value.toLocaleString("it-IT");
              }}
              position="top"
            />
          </Bar>
        </BarChart>
      );
    } else if (
      filter === "zip_code" ||
      filter === "occupation" ||
      filter === "gender"
    ) {
      return (
        <PieChart>
          <Pie
            activeIndex={state.activeIndex}
            activeShape={(p) =>
              renderActiveShape(
                p,
                (filter === "zip_code" && "#0c0c95") ||
                  (filter === "occupation" && "#00761A") ||
                  (filter === "gender" && ["#FC2DD3", "#2BA5FC"])
              )
            }
            data={currentCard.data}
            cx="50%"
            cy="50%"
            innerRadius={140}
            outerRadius={220}
            fill={
              (filter === "zip_code" && "#8884d8") ||
              (filter === "occupation" && "#03c04a") ||
              (filter === "gender" && "#ddd")
            }
            dataKey="percentage"
            onMouseEnter={onPieEnter}
          />
        </PieChart>
      );
    }
  };

  useEffect(() => {
    const activeCampaigns = (c) => c.isActive;
    const futureCampaigns = (c) => c.isFuture;
    const availableCampaigns = (c) => c.isAvailable;
    const expiredCampaigns = (c) => c.isExpired;

    toggleLoading(!campaigns);
    if (campaigns) {
      const emptyStat = () => ({
        total: 0,
        loop: 0,
        types: {
          ...(bmapi.can(FEATURES.CAMPAIGN_COUPON)
            ? { [PRODUCT_TYPES.CAMPAIGN_COUPON]: { total: 0, loop: 0 } }
            : {}),
          ...(bmapi.can(FEATURES.CAMPAIGN_EVENT_PASS)
            ? { [PRODUCT_TYPES.CAMPAIGN_EVENT_PASS]: { total: 0, loop: 0 } }
            : {}),
          ...(bmapi.can(FEATURES.CAMPAIGN_EARNING_CARD)
            ? { [PRODUCT_TYPES.CAMPAIGN_EARNING_CARD]: { total: 0, loop: 0 } }
            : {}),
          ...(bmapi.can(FEATURES.CAMPAIGN_MULTIWIN)
            ? { [PRODUCT_TYPES.CAMPAIGN_MULTIWIN]: { total: 0, loop: 0 } }
            : {}),
          ...(bmapi.can(FEATURES.CAMPAIGN_SHOPPING_CARD) ||
          bmapi.can(FEATURES.CAMPAIGN_PROVISIONING_CARD)
            ? { [PRODUCT_TYPES.CAMPAIGN_SHOPPING_CARD]: { total: 0, loop: 0 } }
            : {}),
        },
      });

      const statsByType = campaigns.reduce(
        (stats, c) => {
          const newStats = (key, extra = {}) => ({
            ...stats,
            [key]: {
              total: ++stats[key].total,
              loop: stats[key].loop + Number(c.loop_campaign),
              types: {
                ...stats[key].types,
                [c.campaign_data.type]: {
                  total:
                    (stats[key].types[c.campaign_data.type]?.total || 0) + 1,
                  loop:
                    (stats[key].types[c.campaign_data.type]?.loop || 0) +
                    +Number(c.loop_campaign),
                },
              },
              ...extra,
            },
          });

          if (futureCampaigns(c)) {
            return newStats("futureCampaigns", {
              startingNext:
                !stats.futureCampaigns.startingNext ||
                stats.futureCampaigns.startingNext.start_date > c.start_date
                  ? c
                  : stats.futureCampaigns.startingNext,
            });
          }
          if (activeCampaigns(c)) {
            return newStats("activeCampaigns", {
              expiringNext:
                !stats.activeCampaigns.expiringNext ||
                stats.activeCampaigns.expiringNext.expiration_date >
                  c.expiration_date
                  ? c
                  : stats.activeCampaigns.expiringNext,
            });
          }
          if (availableCampaigns(c)) {
            return newStats("availableCampaigns", {
              future:
                (stats.availableCampaigns.future || 0) + Number(c.isFuture),
              active:
                (stats.availableCampaigns.active || 0) + Number(c.isActive),
              expired:
                (stats.availableCampaigns.expired || 0) + Number(c.isExpired),
            });
          }
          if (expiredCampaigns(c)) {
            return newStats("expiredCampaigns", {
              lastExpired:
                !stats.expiredCampaigns.lastExpired ||
                stats.expiredCampaigns.lastExpired.expiration_date <
                  c.expiration_date
                  ? c
                  : stats.expiredCampaigns.lastExpired,
            });
          }
          return stats;
        },
        {
          futureCampaigns: emptyStat(),
          activeCampaigns: emptyStat(),
          availableCampaigns: emptyStat(),
          expiredCampaigns: emptyStat(),
        }
      );

      const getTotals = (types) => Object.values(types).map((v) => v.total);
      const maxValue = Math.max(
        ...getTotals(statsByType.futureCampaigns.types),
        ...getTotals(statsByType.activeCampaigns.types),
        ...getTotals(statsByType.availableCampaigns.types),
        ...getTotals(statsByType.expiredCampaigns.types)
      );

      setStats({
        ...statsByType,
        maxValue: maxValue && maxValue > 10 ? maxValue : 10,
      });
    }
  }, [bmapi, campaigns, toggleLoading]);

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  const goTo = (filter) => () =>
    history.push(MANAGER_ROUTES.CAMPAIGNS.replace(":filter?", filter));

  const formatDate = (date) => (date ? intl.formatDate(new Date(date)) : "");

  const campaignMap = {
    CAMPAIGN_COUPON: {
      color: "#B71C1C",
      lightColor: "#C62828",
      friendlyName: intl.formatMessage(campaign.couponShort),
    },
    CAMPAIGN_EVENT_PASS: {
      color: "#F57F17",
      lightColor: "#F9A825",
      friendlyName: intl.formatMessage(campaign.eventPassShort),
    },
    CAMPAIGN_MULTIWIN: {
      color: "#FFEB3B",
      lightColor: "#FFF176",
      friendlyName: intl.formatMessage(campaign.multiwinShort),
    },
    CAMPAIGN_EARNING_CARD: {
      color: "#1B5E20",
      lightColor: "#2E7D32",
      friendlyName: intl.formatMessage(campaign.fidelityShort),
    },
    CAMPAIGN_SHOPPING_CARD: {
      color: "#0D47A1",
      lightColor: "#1565C0",
      friendlyName: intl.formatMessage(campaign.giftcardShort),
    },
  };

  const cards = [
    {
      enabled: filter === "active",
      stats: stats?.activeCampaigns,
      label: intl.formatMessage({
        id: "pages.dashboard.runningCampaigns",
        defaultMessage: "Campagne in corso",
      }),
      Icon: PlayArrow,
      text: !!stats?.activeCampaigns.expiringNext && (
        <React.Fragment>
          <Typography variant="body2">
            <FormattedMessage
              id="pages.dashboard.nextEndingCampaign"
              defaultMessage="Prossima campagna in scadenza:"
            />
          </Typography>
          <Typography variant="body2">
            <strong>{stats?.activeCampaigns.expiringNext.name}</strong> (
            {formatDate(stats?.activeCampaigns.expiringNext.expiration_date)})
          </Typography>
        </React.Fragment>
      ),
      cta: [
        {
          action: goTo("active"),
          color: "primary",
          Icon: Loyalty,
          label: intl.formatMessage(navigation.viewAllCampaigns),
        },
      ],
    },
    {
      enabled: filter === "next",
      stats: stats?.futureCampaigns,
      label: intl.formatMessage({
        id: "pages.dashboard.futureCampaigns",
        defaultMessage: "Prossime campagne",
      }),
      Icon: Schedule,
      text: !!stats?.futureCampaigns.startingNext && (
        <React.Fragment>
          <Typography variant="body2">
            <FormattedMessage
              id="pages.dashboard.nextStartingCampaign"
              defaultMessage="Prossima campagna a partire:"
            />
          </Typography>
          <Typography variant="body2">
            <strong>{stats?.futureCampaigns.startingNext.name}</strong> (
            {formatDate(stats?.futureCampaigns.startingNext.start_date)})
          </Typography>
        </React.Fragment>
      ),
      cta: [
        {
          action: goTo("next"),
          color: "primary",
          Icon: Loyalty,
          label: intl.formatMessage(navigation.viewAllCampaigns),
        },
      ],
    },
    {
      enabled: filter === "availables",
      stats: stats?.availableCampaigns,
      label: intl.formatMessage({
        id: "pages.dashboard.availableCampaigns",
        defaultMessage: "Campagne disponibili",
      }),
      Icon: Adjust,
      text: !!stats?.futureCampaigns.startingNext && (
        <React.Fragment>
          <Typography variant="body2">
            <FormattedMessage
              id="pages.dashboard.runningCampaigns"
              defaultMessage="Campagne in corso"
            />
            : <strong>{stats?.availableCampaigns.active || 0}</strong>
          </Typography>
          <Typography variant="body2">
            <FormattedMessage
              id="pages.dashboard.futureCampaigns"
              defaultMessage="Prossime campagne"
            />
            : <strong>{stats?.availableCampaigns.future || 0}</strong>
          </Typography>
        </React.Fragment>
      ),
      cta: [
        {
          action: goTo("availables"),
          color: "primary",
          Icon: Loyalty,
          label: intl.formatMessage(navigation.viewAllCampaigns),
        },
      ],
    },
    {
      enabled: filter === "expired",
      stats: stats?.expiredCampaigns,
      label: intl.formatMessage({
        id: "pages.dashboard.endedCampaigns",
        defaultMessage: "Campagne concluse",
      }),
      Icon: DoneAll,
      text: !!stats?.expiredCampaigns.lastExpired && (
        <React.Fragment>
          <Typography variant="body2">
            <FormattedMessage
              id="pages.dashboard.lastEndedCampaign"
              defaultMessage="Ultima campagna conclusa:"
            />
          </Typography>
          <Typography variant="body2">
            <strong>{stats?.expiredCampaigns.lastExpired.name}</strong> (
            {formatDate(stats?.expiredCampaigns.lastExpired.expiration_date)})
          </Typography>
        </React.Fragment>
      ),
      cta: [
        {
          action: goTo("expired"),
          color: "primary",
          Icon: Loyalty,
          label: intl.formatMessage(navigation.viewAllCampaigns),
        },
      ],
    },
    {
      enabled: filter === "play",
      title: "Giocate divise per tipologia",
      statistics: true,
      data: [
        {
          date: "2021-01",
          value: 7619,
          bollino: 5160,
          visite: 2459,
          green: 0,
          bonus_cat: 0,
          eco_comp: 0,
        },
        {
          date: "2021-02",
          value: 7478,
          bollino: 4827,
          visite: 2651,
          green: 0,
          bonus_cat: 0,
          eco_comp: 0,
        },
        {
          date: "2021-03",
          value: 6946,
          bollino: 4303,
          visite: 2643,
          green: 0,
          bonus_cat: 0,
          eco_comp: 0,
        },
        {
          date: "2021-04",
          value: 6950,
          bollino: 4415,
          visite: 2535,
          green: 0,
          bonus_cat: 0,
          eco_comp: 0,
        },
        {
          date: "2021-05",
          value: 7025,
          bollino: 4184,
          visite: 2841,
          green: 0,
          bonus_cat: 0,
          eco_comp: 0,
        },
        {
          date: "2021-06",
          value: 17237,
          bollino: 9830,
          visite: 7407,
          green: 0,
          bonus_cat: 0,
          eco_comp: 0,
        },
        {
          date: "2021-07",
          value: 21598,
          bollino: 13295,
          visite: 8303,
          green: 0,
          bonus_cat: 0,
          eco_comp: 0,
        },
        {
          date: "2021-08",
          value: 20518,
          bollino: 12337,
          visite: 8181,
          green: 0,
          bonus_cat: 0,
          eco_comp: 0,
        },
        {
          date: "2021-09",
          value: 25134,
          bollino: 15043,
          visite: 10091,
          green: 0,
          bonus_cat: 0,
          eco_comp: 0,
        },
        {
          date: "2021-10",
          value: 29021,
          bollino: 17416,
          visite: 11605,
          green: 0,
          bonus_cat: 0,
          eco_comp: 0,
        },
        {
          date: "2021-11",
          value: 28428,
          bollino: 17176,
          visite: 10614,
          green: 638,
          bonus_cat: 0,
          eco_comp: 0,
        },
        {
          date: "2021-12",
          value: 48116,
          bollino: 30851,
          visite: 15569,
          green: 1696,
          bonus_cat: 0,
          eco_comp: 0,
        },
        {
          date: "2022-01",
          value: 30045,
          bollino: 16177,
          visite: 12180,
          green: 1688,
          bonus_cat: 0,
          eco_comp: 0,
        },
        {
          date: "2022-02",
          value: 27409,
          bollino: 13325,
          visite: 12271,
          green: 1813,
          bonus_cat: 0,
          eco_comp: 0,
        },
        {
          date: "2022-03",
          value: 31000,
          bollino: 15299,
          visite: 13712,
          green: 1989,
          bonus_cat: 0,
          eco_comp: 0,
        },
        {
          date: "2022-04",
          value: 34241,
          bollino: 18049,
          visite: 14172,
          green: 2020,
          bonus_cat: 0,
          eco_comp: 0,
        },
        {
          date: "2022-05",
          value: 30630,
          bollino: 15190,
          visite: 13447,
          green: 1993,
          bonus_cat: 0,
          eco_comp: 0,
        },
        {
          date: "2022-06",
          value: 27778,
          bollino: 13675,
          visite: 12330,
          green: 1773,
          bonus_cat: 0,
          eco_comp: 0,
        },
        {
          date: "2022-07",
          value: 29277,
          bollino: 15008,
          visite: 12525,
          green: 1744,
          bonus_cat: 0,
          eco_comp: 0,
        },
        {
          date: "2022-08",
          value: 25091,
          bollino: 12568,
          visite: 10929,
          green: 1594,
          bonus_cat: 0,
          eco_comp: 0,
        },
        {
          date: "2022-09",
          value: 30286,
          bollino: 14670,
          visite: 13561,
          green: 2055,
          bonus_cat: 0,
          eco_comp: 0,
        },
        {
          date: "2022-10",
          value: 35157,
          bollino: 17248,
          visite: 15441,
          green: 2468,
          bonus_cat: 0,
          eco_comp: 0,
        },
        {
          date: "2022-11",
          value: 36140,
          bollino: 18015,
          visite: 15613,
          green: 2512,
          bonus_cat: 0,
          eco_comp: 0,
        },
        {
          date: "2022-12",
          value: 54203,
          bollino: 28699,
          visite: 22651,
          green: 2853,
          bonus_cat: 0,
          eco_comp: 0,
        },
        {
          date: "2023-01",
          value: 42744,
          bollino: 19940,
          visite: 20048,
          green: 2684,
          bonus_cat: 72,
          eco_comp: 0,
        },
        {
          date: "2023-02",
          value: 38400,
          bollino: 16731,
          visite: 18887,
          green: 2590,
          bonus_cat: 192,
          eco_comp: 0,
        },
        {
          date: "2023-03",
          value: 39584,
          bollino: 17690,
          visite: 18886,
          green: 2734,
          bonus_cat: 274,
          eco_comp: 0,
        },
        {
          date: "2023-04",
          value: 36737,
          bollino: 16299,
          visite: 17603,
          green: 2540,
          bonus_cat: 295,
          eco_comp: 0,
        },
        {
          date: "2023-05",
          value: 36813,
          bollino: 16242,
          visite: 17647,
          green: 2634,
          bonus_cat: 290,
          eco_comp: 0,
        },
        {
          date: "2023-06",
          value: 33248,
          bollino: 14620,
          visite: 15909,
          green: 2418,
          bonus_cat: 301,
          eco_comp: 0,
        },
        {
          date: "2023-07",
          value: 35226,
          bollino: 15821,
          visite: 16658,
          green: 2499,
          bonus_cat: 248,
          eco_comp: 0,
        },
        {
          date: "2023-08",
          value: 29089,
          bollino: 12579,
          visite: 14046,
          green: 2201,
          bonus_cat: 263,
          eco_comp: 0,
        },
        {
          date: "2023-09",
          value: 33584,
          bollino: 14823,
          visite: 15835,
          green: 2564,
          bonus_cat: 362,
          eco_comp: 128,
        },
      ].filter(byDate),
    },
    {
      enabled: filter === "new_customer",
      statistics: true,
      data: [
        {
          date: "2021-01",
          value: 449,
        },
        {
          date: "2021-02",
          value: 386,
        },
        {
          date: "2021-03",
          value: 281,
        },
        {
          date: "2021-04",
          value: 252,
        },
        {
          date: "2021-05",
          value: 418,
        },
        {
          date: "2021-06",
          value: 1127,
        },
        {
          date: "2021-07",
          value: 1171,
        },
        {
          date: "2021-08",
          value: 1120,
        },
        {
          date: "2021-09",
          value: 1043,
        },
        {
          date: "2021-10",
          value: 1197,
        },
        {
          date: "2021-11",
          value: 1210,
        },
        {
          date: "2021-12",
          value: 5267,
        },
        {
          date: "2022-01",
          value: 1473,
        },
        {
          date: "2022-02",
          value: 722,
        },
        {
          date: "2022-03",
          value: 738,
        },
        {
          date: "2022-04",
          value: 1006,
        },
        {
          date: "2022-05",
          value: 862,
        },
        {
          date: "2022-06",
          value: 754,
        },
        {
          date: "2022-07",
          value: 862,
        },
        {
          date: "2022-08",
          value: 769,
        },
        {
          date: "2022-09",
          value: 805,
        },
        {
          date: "2022-10",
          value: 2385,
        },
        {
          date: "2022-11",
          value: 2760,
        },
        {
          date: "2022-12",
          value: 3680,
        },
        {
          date: "2023-01",
          value: 2900,
        },
        {
          date: "2023-02",
          value: 2100,
        },
        {
          date: "2023-03",
          value: 1013,
        },
        {
          date: "2023-04",
          value: 1020,
        },
        {
          date: "2023-05",
          value: 813,
        },
        {
          date: "2023-06",
          value: 968,
        },
        {
          date: "2023-07",
          value: 2356,
        },
        {
          date: "2023-08",
          value: 1431,
        },
        {
          date: "2023-09",
          value: 730,
        },
      ].filter(byDate),
    },
    {
      enabled: filter === "zip_code",
      statistics: true,
      title: "Statistiche cap utenti profilati",
      data: [
        {
          zip: 20099,
          percentage: 1138,
        },
        {
          zip: 20092,
          percentage: 616,
        },
        {
          zip: 20162,
          percentage: 554,
        },
        {
          zip: 20126,
          percentage: 364,
        },
        {
          zip: 20091,
          percentage: 327,
        },
        {
          zip: 20125,
          percentage: 255,
        },
        {
          zip: 20159,
          percentage: 127,
        },
        {
          zip: 20128,
          percentage: 123,
        },
        {
          zip: 20900,
          percentage: 90,
        },
        {
          zip: 20161,
          percentage: 78,
        },
        {
          zip: 20127,
          percentage: 64,
        },
        {
          zip: 20095,
          percentage: 64,
        },
        {
          zip: 20032,
          percentage: 61,
        },
        {
          zip: 20093,
          percentage: 56,
        },
        {
          zip: 20090,
          percentage: 51,
        },
        { zip: "altri CAP", percentage: 1054 },
      ],
    },
    {
      enabled: filter === "occupation",
      title: "Statistiche professione utenti profilati",
      statistics: true,
      data: [
        {
          work: "Dipendente azienda privata",
          percentage: 2028,
        },
        {
          work: "Pensionato/a",
          percentage: 618,
        },
        {
          work: "Dipendente pubblico",
          percentage: 445,
        },
        {
          work: "Studente",
          percentage: 440,
        },
        {
          work: "Lavoratore a contratto",
          percentage: 358,
        },
        {
          work: "Libero professionista",
          percentage: 328,
        },
        {
          work: "Casalingo/a",
          percentage: 252,
        },
        {
          work: "Disoccupato/a",
          percentage: 160,
        },
        {
          work: "Insegnante",
          percentage: 151,
        },
        {
          work: "Manager",
          percentage: 115,
        },
        {
          work: "Imprenditore",
          percentage: 84,
        },
        {
          work: "Artigiano",
          percentage: 73,
        },
      ],
    },
    {
      enabled: filter === "gender",
      title: "Statistiche genere utenti profilati",
      statistics: true,
      data: [
        {
          gender: "Femmina",
          percentage: 3166,
        },
        {
          gender: "Maschio",
          percentage: 1922,
        },
      ],
    },
    {
      enabled: filter === "age",
      title: "Statistiche fascia d'età utenti profilati",
      statistics: true,
      data: [
        {
          age: "20",
          value: 290,
        },
        {
          age: "25",
          value: 418,
        },
        {
          age: "30",
          value: 410,
        },
        {
          age: "35",
          value: 413,
        },
        {
          age: "40",
          value: 472,
        },
        {
          age: "45",
          value: 546,
        },
        {
          age: "50",
          value: 643,
        },
        {
          age: "55",
          value: 615,
        },
        {
          age: "60",
          value: 443,
        },
        {
          age: "65",
          value: 302,
        },
        {
          age: "70",
          value: 214,
        },
        {
          age: "75",
          value: 159,
        },
        {
          age: "80",
          value: 69,
        },
        {
          age: "85",
          value: 23,
        },
        {
          age: "90",
          value: 5,
        },
        {
          age: "95",
          value: 1,
        },
      ],
    },
  ];
  const currentCard = cards.find((i) => i.enabled);

  return !!campaigns && !!businessId ? (
    <Container
      maxWidth={
        (currentCard.data.length > 24 && "xl") ||
        (currentCard.data.length <= 24 && "lg")
      }
    >
      <Title>
        <FormattedMessage
          id="pages.dashboard.title"
          defaultMessage="Cruscotto"
        />
      </Title>

      {campaigns.length === 0 ? (
        bmapi.can(FEATURES.MANAGE_CAMPAIGN) ? (
          <CreateCampaignButton
            trigger={(onClick) => (
              <Button onClick={onClick} variant="contained">
                <FormattedMessage
                  id="component.campaigns.createFirst"
                  defaultMessage="Crea la tua prima campagna"
                />
              </Button>
            )}
          />
        ) : (
          <Typography gutterBottom>
            <FormattedMessage
              id="component.campaigns.noCampaign"
              defaultMessage="Nessuna campagna presente."
            />
          </Typography>
        )
      ) : (
        <React.Fragment>
          <Box mb={2}>
            <Tabs
              value={filter}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              onChange={(_, v) => {
                setFilter(v);
                setFrom(addYears(new Date(), -2));
                setTo(new Date());
              }}
            >
              {/*
               <Tab
                label={intl.formatMessage({
                  id: "common.actives",
                  defaultMessage: "Attive",
                })}
                value="active"
                style={{ minWidth: 0 }}
              />
              <Tab
                label={intl.formatMessage({
                  id: "common.next",
                  defaultMessage: "Prossime",
                })}
                value="next"
                style={{ minWidth: 0 }}
              />
              <Tab
                label={intl.formatMessage({
                  id: "common.availables",
                  defaultMessage: "Disponibili",
                })}
                value="availables"
                style={{ minWidth: 0 }}
              />
              <Tab
                label={intl.formatMessage({
                  id: "common.expired",
                  defaultMessage: "Concluse",
                })}
                value="expired"
                style={{ minWidth: 0 }}
              /> */}
              <Tab
                label="Statistiche giocate"
                value="play"
                style={{ minWidth: 0 }}
              />
              <Tab
                label="Statistiche nuovi iscritti"
                value="new_customer"
                style={{ minWidth: 0 }}
              />
              <Tab
                label="Statistiche CAP"
                value="zip_code"
                style={{ minWidth: 0 }}
              />
              <Tab
                label="Statistiche professione"
                value="occupation"
                style={{ minWidth: 0 }}
              />
              <Tab
                label="Statistiche genere"
                value="gender"
                style={{ minWidth: 0 }}
              />
              <Tab
                label="Statistiche fascia d'età"
                value="age"
                style={{ minWidth: 0 }}
              />
            </Tabs>
          </Box>

          {(filter === "play" || filter === "new_customer") && (
            <Container maxWidth="sm">
              <Box display="flex" justifyContent="space-around">
                <DatePicker
                  autoOk
                  format="MMMM yyyy"
                  views={["month", "year"]}
                  label={intl.formatMessage(common.filterFrom)}
                  value={from}
                  onChange={(e) => setFrom(e)}
                />
                <DatePicker
                  autoOk
                  format="MMMM yyyy"
                  views={["month", "year"]}
                  label={intl.formatMessage(common.filterTo)}
                  value={to}
                  onChange={(e) => setTo(e)}
                />
              </Box>
            </Container>
          )}

          <Typography variant="h6" gutterBottom>
            {currentCard.title}
          </Typography>

          <Card>
            {!currentCard.statistics ? (
              <React.Fragment>
                <CardHeader
                  avatar={
                    <Avatar className={classes.statsIcon}>
                      <currentCard.Icon fontSize="large" />
                    </Avatar>
                  }
                  classes={{ content: classes.statsTitle }}
                  title={currentCard.label}
                  titleTypographyProps={{ variant: "body1" }}
                  subheader={currentCard.stats?.total}
                  subheaderTypographyProps={{
                    variant: "h4",
                    style: { color: "inherit", fontWeight: "bold" },
                  }}
                />
                <CardContent>
                  {!!currentCard.stats && (
                    <React.Fragment>
                      <Box mb={2}>
                        <SimpleBarChart
                          data={Object.keys(currentCard.stats.types).map(
                            (k) => ({
                              name: campaignMap[k].friendlyName,
                              value:
                                (currentCard.stats.types[k].total || 0.05) -
                                currentCard.stats.types[k].loop,
                              loop: currentCard.stats.types[k].loop || 0,
                              fill: campaignMap[k].color,
                              fillLoop: campaignMap[k].lightColor,
                            })
                          )}
                          dataMax={stats.maxValue}
                        />
                      </Box>
                      {currentCard.text}
                    </React.Fragment>
                  )}
                </CardContent>
                <CardActions>
                  {currentCard.cta.map((cta) => (
                    <Button
                      key={cta.label}
                      color={cta.color}
                      startIcon={!!cta.Icon && <cta.Icon />}
                      onClick={cta.action}
                    >
                      {cta.label}
                    </Button>
                  ))}
                  {bmapi.can(FEATURES.MANAGE_CAMPAIGN) && (
                    <CreateCampaignButton
                      trigger={(onClick) => (
                        <Button
                          color="primary"
                          startIcon={<Add />}
                          onClick={onClick}
                        >
                          {intl.formatMessage({
                            id: "page.campaigns.createCampaign",
                            defaultMessage: "Crea campagna",
                          })}
                        </Button>
                      )}
                    />
                  )}
                </CardActions>
              </React.Fragment>
            ) : (
              <div style={{ width: "100%", height: 600 }}>
                <ResponsiveContainer>{getChart()}</ResponsiveContainer>
              </div>
            )}
          </Card>
        </React.Fragment>
      )}

      <Box mt={2}>
        <Button
          component={RouterLink}
          to={MANAGER_ROUTES.HOME}
          startIcon={<ArrowBack />}
        >
          {intl.formatMessage(common.backHome)}
        </Button>
      </Box>

      {!bmapi.isTenantManager() &&
        bmapi.can(FEATURES.SCAN_QR) &&
        campaigns.some((c) => c.isActive) && (
          <FloatingActions>
            <Action
              icon={<CenterFocusStrong />}
              label={intl.formatMessage({
                id: "common.scan",
                defaultMessage: "Scansiona",
              })}
              action={() => history.push(MANAGER_ROUTES.SCAN)}
            />
          </FloatingActions>
        )}
    </Container>
  ) : !businessId && campaigns?.length === 0 ? (
    <Container maxWidth="md">
      <Typography variant="body1" gutterBottom>
        <FormattedMessage
          id="pages.dashboard.emptyMessage"
          defaultMessage="Non ci sono negozi associati al tuo indirizzo email."
        />
      </Typography>
      <Typography variant="body1" gutterBottom>
        <FormattedMessage
          id="pages.dashboard.emptySolution"
          defaultMessage="Contatta l'amministratore per risolvere il problema."
        />
      </Typography>
    </Container>
  ) : null;
}
