import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import {
  Box,
  Card,
  CardActionArea,
  CardHeader,
  CardMedia,
} from "@material-ui/core";

import { CONSUMER_ROUTES, SUBSCRIPTIONS, TENANTS } from "../utils/constants";
import styles from "../utils/styles";
import { useBmapi } from "../utils/bmapi-context";
import Grid from "../ui/Grid";

export default function Banners({ products }) {
  const classes = styles.useStyles();
  const { bmapi } = useBmapi();
  const [banners] = useState([
    {
      tenants: [TENANTS.LUCCA],
      campaignId: bmapi.settings.luccaPassId,
      endDate: new Date("2021/2/28 23:59"),
      link: CONSUMER_ROUTES.LUCCA_BUY,
      bgImg:
        "https://storage.googleapis.com/bmarken-assets/tenants/lcng/banner/pass.png",
      cta: "Acquista il tuo Pass",
    },
    {
      tenants: [TENANTS.LUCCA],
      campaignId: bmapi.settings.luccaRakutenId,
      endDate: new Date("2021/2/28 23:59"),
      link: CONSUMER_ROUTES.CAMPAIGN.replace(
        ":campaignId",
        bmapi.settings.luccaRakutenId
      ),
      bgImg:
        "https://storage.googleapis.com/bmarken-assets/tenants/lcng/banner/rakuten.png",
      cta: "Riscatta il coupon",
    },
    {
      tenants: [TENANTS.APPS],
      subscriptions: ["lamilanochepremia"],
      campaignId: bmapi.settings.instantWinId,
      endDate: new Date("2021/12/31 23:59"),
      link: CONSUMER_ROUTES.CAMPAIGN.replace(
        ":campaignId",
        bmapi.settings.instantWinId
      ),
      bgColor: "#efefef",
      bgImg:
        "https://storage.googleapis.com/bmarken-assets/assets/banners/milano-che-premia.png",
      cta:
        "Per partecipare al concorso clicca qui e poi clicca su 'Aderisci al concorso'",
    },
    {
      tenants: [TENANTS.APPS],
      subscriptions: ["natalesottocasa"],
      campaignId: bmapi.settings.instantWinIdNataleSottoCasa,
      endDate: new Date("2022/01/08 23:59"),
      link: CONSUMER_ROUTES.CAMPAIGN.replace(
        ":campaignId",
        bmapi.settings.instantWinIdNataleSottoCasa
      ),
      bgColor: "#efefef",
      bgImg:
        "https://storage.googleapis.com/bmarken-assets/assets/banners/natale-sotto-casa-2.png",
      cta:
        "Per partecipare al concorso clicca qui e poi clicca su 'Aderisci al concorso'",
    },
  ]);

  const isValid = (banner) => {
    const wrongTenant =
      banner.tenants && !banner.tenants.includes(bmapi.tenant);
    const wrongSubscription =
      banner.subscriptions &&
      (!SUBSCRIPTIONS ||
        !banner.subscriptions.includes(bmapi.subscriptionSlug));
    const hasProducts =
      banner.campaignId &&
      products.some((p) => p.campaign_id === banner.campaignId);
    const notStarted = banner.startDate && new Date() < banner.startDate;
    const ended = banner.endDate && new Date() > banner.endDate;

    return !(
      wrongTenant ||
      wrongSubscription ||
      hasProducts ||
      notStarted ||
      ended
    );
  };

  const bs = banners.filter(isValid);

  return (
    !!bs.length && (
      <Box mb={6} mt={3}>
        <Grid
          items={bs}
          render={(banner) => (
            <Card>
              <CardActionArea component={RouterLink} to={banner.link}>
                <CardMedia
                  className={classes.bannerMedia}
                  image={banner.bgImg}
                  style={{ backgroundColor: banner.bgColor }}
                />
                <CardHeader
                  title={banner.cta}
                  titleTypographyProps={{ variant: "h6" }}
                  style={{ padding: "4px 16px" }}
                />
              </CardActionArea>
            </Card>
          )}
        />
      </Box>
    )
  );
}
