import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { FormControl, Button, Grid } from "@material-ui/core";
import {
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
} from "@material-ui/icons";

import CouponActionForm from "./forms/CouponActionForm";
import EventPassActionForm from "./forms/EventPassActionForm";
import EarningCardActionForm from "./forms/EarningCardActionForm";
import InstantWinCardActionForm from "./forms/InstantWinCardActionForm";
import ProvisioningCardActionForm from "./forms/ProvisioningCardActionForm";
import ShoppingCardActionForm from "./forms/ShoppingCardActionForm";

import { useBmapi } from "../utils/bmapi-context";
import {
  PRODUCT_SUBTYPES,
  MANAGER_ROUTES,
  QRCODE_TYPES,
} from "../utils/constants";
import { getErrorMessageString } from "../utils/errors";
import { navigation, notifications } from "../messages";
import OrderActionForm from "./forms/OrderActionForm";

export default function FormManager({ info, qrCode, onSuccess, onCancel }) {
  const intl = useIntl();
  const history = useHistory();
  const [transactions, setTransactions] = useState(false);
  const [loading, setLoading] = useState(true);
  const [rules, setRules] = useState(undefined);
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();

  useEffect(() => {
    console.log("INFOOOOOOOO ", info);

    startLoading();
    if (info.type != "EcomOrder") {
      Promise.all([
        bmapi.getCampaigns(),
        bmapi.apiGet(info.transactions_link.url),
      ])
        .then(([campaigns, txs]) => {
          setTransactions(txs);
          if (campaigns && campaigns.length > 0) {
            const c = campaigns.find((c) => c.campaign_id === info.campaign_id);

            return c
              ? bmapi.getRules(c.id).then(setRules)
              : notifyError(intl.formatMessage(notifications.wrongStore));
          }
        })
        .catch((e) => notifyError(getErrorMessageString(e, intl)))
        .finally(() => {
          stopLoading();
          setLoading(false);
        });
    } else {
      stopLoading();
      setLoading(false);
    }
  }, [bmapi, info, intl, notifyError, setLoading, startLoading, stopLoading]);

  const Component = {
    [PRODUCT_SUBTYPES.COUPON_DISCOUNT]: CouponActionForm,
    [PRODUCT_SUBTYPES.COUPON_SIMPLE]: CouponActionForm,
    [PRODUCT_SUBTYPES.COUPON_VALUE]: CouponActionForm,
    [PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE]: EarningCardActionForm,
    [PRODUCT_SUBTYPES.EARNING_CARD_INSTANT_WIN]: InstantWinCardActionForm,
    [PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE]: EventPassActionForm,
    [PRODUCT_SUBTYPES.PROVISIONING_CARD]: ProvisioningCardActionForm,
    [PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE]: ShoppingCardActionForm,
    [QRCODE_TYPES.ORDER]: OrderActionForm,
  }[info.subtype];

  const goHome = () => history.push(MANAGER_ROUTES.HOME);

  return !loading ? (
    <React.Fragment>
      <Component
        info={info}
        onCancel={onCancel}
        onSuccess={onSuccess}
        qrCode={qrCode}
        rules={rules}
        transactions={transactions}
      />
      <FormControl margin="normal" fullWidth>
        <Grid
          alignItems="flex-start"
          container
          direction="row"
          justify="space-between"
          spacing={1}
        >
          <Grid item>
            <Button onClick={onCancel} startIcon={<ArrowBackIcon />}>
              {intl.formatMessage(navigation.newScan)}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={goHome} startIcon={<ArrowForwardIcon />}>
              {intl.formatMessage(navigation.goHome)}
            </Button>
          </Grid>
        </Grid>
      </FormControl>
    </React.Fragment>
  ) : null;
}
