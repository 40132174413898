import React, { useCallback, useEffect, useState } from "react";
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  Container,
  FormControl,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { DatePicker, TimePicker } from "../../ui/DatePicker";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { useIntl } from "react-intl";
import { common, confirm } from "../../messages";
import {
  Add,
  Remove,
  Delete as DeleteIcon,
  ShoppingBasket,
} from "@material-ui/icons";
import Confirm from "../../ui/Confirm";
import { format } from "date-fns";

const byName = (a, b) => a.name.localeCompare(b.name);

export default function OrderCreate() {
  const { bmapi, notifyError } = useBmapi();
  const intl = useIntl();
  const [products, setProducts] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [productToRemove, setProductToRemove] = React.useState(null);
  const [order, setOrder] = useState({
    items: [],
    planned_delivery: "",
  });
  const [values, setValues] = useState({
    product: "", // object TextField select
    date: new Date(),
    time: new Date(),
  });

  /*const handleOrder = useCallback(
    (varName) => (e) => {
      ((val) => setOrder((v) => ({ ...v, [varName]: val })))(e.target.value);
    },
    []
  );*/

  const handleValue = useCallback(
    (varName) => (e) => {
      if (varName === "time") {
        ((val) => setValues((v) => ({ ...v, [varName]: val })))(e);
      } else {
        ((val) => setValues((v) => ({ ...v, [varName]: val })))(e.target.value);
      }
    },
    []
  );

  useEffect(() => {
    loadProductList();
  }, [loadProductList]);

  const loadProductList = useCallback(() => {
    if (bmapi) {
      bmapi
        .getEProduct({
          business: bmapi.getUserInfo().business.id,
          //status: "0", // auto: 0
        })
        .then((resp) => {
          let newProductList = [];
          if (!resp) {
            resp = [];
            setProducts(resp);
          } else {
            newProductList = resp.map((product) => {
              return {
                item_id: product.id,
                name: product.name,
                price: product.price,
                quantity: 0,
              };
            });
            newProductList.sort(byName);
            setProducts(newProductList);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
  }, [bmapi, intl, notifyError]);

  const isSelected = (product) => {
    const filtered = order.items.filter(
      (obj) => obj.item_id === product.item_id
    );
    return filtered && filtered.length > 0;
  };

  //const selectedProducts = products.filter((product) => isSelected(product));

  const addProductToOrder = () => {
    const newProducts = [...order.items];
    if (values.product) {
      newProducts.push({ ...values.product, quantity: 1 }); // add quantity 1
    }
    setOrder({
      ...order,
      items: newProducts,
    });
    setValues({
      ...values,
      product: "",
    });
  };

  const removeProductFromOrder = (index) => {
    const newProducts = [...order.items];
    newProducts.splice(index, 1);
    setOrder({
      ...order,
      items: newProducts,
    });
    setValues({
      ...values,
      product: "",
    });
  };

  const handleQuantity = (i, val) => {
    let newItem = { ...order.items[i] };
    if (val === 1) {
      newItem.quantity = order.items[i].quantity + 1;
    } else if (val === -1) {
      newItem.quantity = order.items[i].quantity - 1;
    }
    let foundIndex = order.items.findIndex(
      (item) => item.item_id === newItem.item_id
    );
    if (foundIndex !== -1) order.items.splice(foundIndex, 1, newItem);
    setOrder({
      ...order,
      items: order.items,
    });
    if (!newItem.quantity) {
      /*
      if (foundIndex !== -1) order.items.splice(foundIndex, 1);
      setOrder({
        ...order,
        items: order.items,
      });
      setValues({
        ...values,
        product: "",
      });
      */
      // quantity 0
      setShowDeleteAlert(true);
      setProductToRemove(foundIndex);
    }
  };

  const confirmOrder = (event) => {
    event.preventDefault();
    const date = format(values.date, "yyyy-MM-dd");
    const time = format(values.time, "HH:mm:ss:ms");
    const delivery = date.concat("T", time, "Z");
    let order = {
      ...order,
      planned_delivery: delivery,
    };
    console.log(order);
  };

  return (
    <Container maxWidth="sm">
      <form>
        <Box mb={2}>
          <Card>
            <CardContent>
              <FormControl fullWidth>
                <TextField
                  margin="normal"
                  label="Prodotti"
                  value={values.product}
                  onChange={handleValue("product")}
                  fullWidth
                  select
                  InputLabelProps={{
                    shrink: values.product ? true : false,
                  }}
                >
                  {products.sort(byName).map(
                    (product) =>
                      !isSelected(product) && (
                        <MenuItem key={product.item_id} value={product}>
                          {product.name}
                        </MenuItem>
                      )
                  )}
                </TextField>
              </FormControl>
              <Button
                onClick={addProductToOrder}
                variant="text"
                color="primary"
              >
                {intl.formatMessage(common.add)}
              </Button>
              {order.items && order.items.length > 0 && (
                <List>
                  {order.items.map((item, i) => (
                    <ListItem key={item.item_id} value={item.item_id}>
                      <ListItemText
                        primary={
                          <Grid
                            container
                            justify="flex-start"
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>
                              <IconButton>
                                <DeleteIcon
                                  onClick={() => {
                                    setShowDeleteAlert(true);
                                    setProductToRemove(i);
                                  }}
                                />
                              </IconButton>
                            </Grid>
                            <Grid item>{item.name}</Grid>
                          </Grid>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Badge
                          color="primary"
                          badgeContent={item.quantity}
                          showZero
                        >
                          <ShoppingBasket />
                        </Badge>
                        <ButtonGroup>
                          <Button
                            onClick={() => {
                              handleQuantity(i, -1);
                            }}
                          >
                            <Remove fontSize="small" />
                          </Button>
                          <Button
                            onClick={() => {
                              handleQuantity(i, 1);
                            }}
                          >
                            <Add fontSize="small" />
                          </Button>
                        </ButtonGroup>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              )}
            </CardContent>
          </Card>
        </Box>

        <Box>
          <Card>
            <CardContent>
              <DatePicker
                margin="normal"
                fullWidth
                disabled
                label="Data di ritiro"
                inputFormat="dd/MM/yyyy"
                value={values.date}
                renderInput={(params) => <TextField {...params} />}
              />
              <TimePicker
                fullWidth
                margin="normal"
                label="Orario di ritiro"
                value={values.time}
                onChange={handleValue("time")}
                renderInput={(params) => <TextField {...params} />}
              />
            </CardContent>
          </Card>
        </Box>

        <Box mb={2}>
          <CardActions>
            <Button
              type="submit"
              onClick={confirmOrder}
              variant="contained"
              color="primary"
            >
              {intl.formatMessage(common.create)}
            </Button>
          </CardActions>
        </Box>
      </form>

      <Confirm
        open={showDeleteAlert}
        onConfirm={() => {
          removeProductFromOrder(productToRemove);
          setShowDeleteAlert(false);
        }}
        onCancel={() => {
          setProductToRemove(null);
          setShowDeleteAlert(false);
        }}
        text={intl.formatMessage(confirm.deleteElement)}
      />
    </Container>
  );
}
