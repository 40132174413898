import React from "react";
import { FormatListBulleted, Sync } from "@material-ui/icons";

export const LISTS = [
  {
    type: "static",
    label: "static",
    icon: <FormatListBulleted />,
  },
  {
    type: "dynamic",
    label: "dynamic",
    icon: <Sync />,
  },
];

export const LISTS_FILTER = [
  {
    type: "all",
    label: "allList",
  },
  {
    type: "static",
    label: "static",
  },
  {
    type: "dynamic",
    label: "dynamic",
  },
];

export const FILTER_GENDER = [
  {
    value: "F",
    label: "female",
  },
  {
    value: "M",
    label: "male",
  },
  {
    value: "OTHER",
    label: "other",
  },
  {
    value: "NOT_SPECIFIED",
    label: "notSpecified",
  },
  {
    value: "ALL",
    label: "all",
  },
];
