import React from "react";
import { useIntl } from "react-intl";

import NumberInput from "../../NumberInput";
import { PRODUCT_TYPES } from "../../../utils/constants";
import { form } from "../../../messages";

export default function EarningMinimumThreshold({
  values,
  handleChange,
  setError,
  performance,
}) {
  const intl = useIntl();

  const checkTreshold = () => {
    setError(
      +values.earning_minimum_threshold >
        values.rules.earning_minimum_threshold / 100
    );
    return (
      +values.earning_minimum_threshold >
      values.rules.earning_minimum_threshold / 100
    );
  };

  return (
    [PRODUCT_TYPES.CAMPAIGN_EARNING_CARD].includes(values.type) && (
      <NumberInput
        name="earning_minimum_threshold"
        label={intl.formatMessage(form.earningMinimumThreshold)}
        value={values.earning_minimum_threshold}
        onChange={handleChange("earning_minimum_threshold")}
        fullWidth
        margin="normal"
        //disabled={!!campaign}
        error={performance?.issued_qty > 0 && values.rules && checkTreshold()}
        helperText={
          performance?.issued_qty > 0 &&
          values.rules &&
          checkTreshold() &&
          "Il differenziale di spesa non può essere aumentato"
        }
      />
    )
  );
}
