import React from "react";
import {
  Done,
  HowToReg,
  Delete,
  NotInterested,
  Flag,
  Settings,
  ArrowBack,
  ArrowForward,
  Alarm,
} from "@material-ui/icons";

export const ORDER_STATUS = [
  { value: "x", label: "openOrder" },
  {
    value: 0,
    label: "toMake",
    icon: <Flag fontSize="large" />,
    icon_small: <Flag fontSize="small" />,
    icon_color: <Flag fontSize="large" color="primary" />,
  },
  {
    value: 1,
    label: "inProgress",
    icon: <Settings fontSize="large" />,
    icon_small: <Settings fontSize="small" />,
    icon_color: <Settings fontSize="large" color="primary" />,
  },
  {
    value: 2,
    label: "ready",
    icon: <Done fontSize="large" />,
    icon_small: <Done fontSize="small" />,
    icon_color: <Done fontSize="large" color="primary" />,
  },
  {
    value: 3,
    label: "delivered",
    icon: <HowToReg fontSize="large" />,
    icon_small: <HowToReg fontSize="small" />,
    icon_color: <HowToReg fontSize="large" color="primary" />,
  },
  {
    value: 4,
    label: "deleted",
    icon: <Delete fontSize="large" />,
    icon_small: <Delete fontSize="small" />,
    icon_color: <Delete fontSize="large" color="primary" />,
  },
  {
    value: 5,
    label: "notCollect",
    icon: <NotInterested fontSize="large" />,
    icon_small: <NotInterested fontSize="small" />,
    icon_color: <NotInterested fontSize="large" color="primary" />,
  },
];

export const ACTIONS = [
  {
    label: "statusBack",
    icon: <ArrowBack fontSize="small" />,
  },
  {
    label: "statusNext",
    icon: <ArrowForward fontSize="small" />,
  },
  {
    label: "deleteOrder",
    icon: <Delete fontSize="small" />,
  },
  {
    label: "delayCollect",
    icon: <Alarm fontSize="small" />,
  },
  /*
  {
    label: "phoneContact",
    icon: <Phone fontSize="small" />,
  },
  */
];

export const OUTCOMES = [
  { value: "CONTACTED", label: "contacted" },
  { value: "NOANSWER", label: "noAnswer" },
  { value: "BUSY", label: "busy" },
  { value: "DROPPED", label: "dropped" },
  { value: "UNREACHABLE", label: "unreachable" },
];

export const PRODUCT_STATUS = [
  { value: "0|2", label: "allProducts" },
  { value: "0", label: "activeProducts" },
  { value: "2", label: "inactiveProducts" },
];

export const TAGS = [
  { value: "SANDWICH", label: "sandwich" },
  { value: "SALADS", label: "salads" },
  { value: "BURGERS", label: "burgers" },
  { value: "DRINKS", label: "drinks" },
  { value: "PIZZA", label: "pizza" },
  { value: "SIDES", label: "sides" },
];
