import React from "react";

import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  LinearProgress,
  Typography,
} from "@material-ui/core";

import Title from "../ui/Title";
import { useBmapi } from "../utils/bmapi-context";
import { CONSUMER_ROUTES, MANAGER_ROUTES } from "../utils/constants";
import { useHistory } from "react-router";

function UserStats({ picture, level = 3 }) {
  return (
    <Box display="flex" alignItems="center">
      <Box minWidth={100} mr={3}>
        <Avatar src={picture} style={{ width: 100, height: 100 }} />
      </Box>
      <Box width="100%">
        <Typography>Livello {level}</Typography>
        <Box display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <LinearProgress
              variant="determinate"
              value={50}
              style={{
                width: "100%",
                height: 10,
                borderRadius: 5,
              }}
            />
          </Box>
          <Box minWidth={80}>
            <Typography variant="body2" color="textSecondary">
              25/50 punti
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default function Profile() {
  const { bmapi } = useBmapi();
  const history = useHistory();

  const ROUTES = bmapi.isManager() ? MANAGER_ROUTES : CONSUMER_ROUTES;

  return (
    <Container maxWidth="sm">
      <Title backUrl={ROUTES.HOME}>Profilo</Title>

      <Card>
        <CardContent>
          <Box mb={4}>
            <Grid
              container
              justify="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Typography variant="h6">I tuoi dati</Typography>
              </Grid>
              <Grid item>
                <Button
                  size="small"
                  onClick={() => history.push(ROUTES.ACCOUNT)}
                >
                  Modifica
                </Button>
              </Grid>
            </Grid>
            <Typography>{bmapi.userData.email}</Typography>
          </Box>
          <UserStats picture={bmapi.userData.picture} />
        </CardContent>
      </Card>
    </Container>
  );
}
