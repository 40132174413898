import React from "react";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";
import { addDays, format } from "date-fns";
import { parseBmarkenDate } from "../utils/utils";

export default function PrizesDetails({ prizes }) {
  const checkIfEnabled = (date) => {
    const today = new Date();
    const created = new Date(date);

    const differenceInDays = Math.floor(
      (today - created) / (1000 * 60 * 60 * 24)
    );

    return differenceInDays > 7;
  };

  const getIconColor = (status, date) => {
    let color;
    const isEnabled = checkIfEnabled(date);

    if (status == 0 && isEnabled) {
      color = "orange";
    } else if (status == 0 && !isEnabled) {
      color = "red";
    } else if (status == 3) {
      color = "green";
    } else {
      color = "red";
    }

    return color;
  };

  const getAvailableDate = (date) => {
    const newDate = addDays(parseBmarkenDate(date), 8);
    const availableDate = format(newDate, "dd-MM-yy");
    return availableDate;
  };

  return (
    prizes && (
      <React.Fragment>
        <Card>
          <TableContainer>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Premio</TableCell>
                  <TableCell>Campagna</TableCell>
                  <TableCell>Ricevuto il</TableCell>
                  <TableCell>Utilizzabile dal</TableCell>
                </TableRow>
              </TableHead>
              {prizes.length ? (
                <TableBody>
                  {prizes.map((p) => {
                    return (
                      <TableRow key={p.id} hover={true}>
                        <TableCell align="right">
                          <FiberManualRecord
                            style={{
                              color: getIconColor(p.status, p.created_at),
                            }}
                          />
                        </TableCell>
                        <TableCell>{p.business_name}</TableCell>
                        <TableCell>{p.campaign_name}</TableCell>
                        <TableCell>
                          {format(parseBmarkenDate(p.created_at), "dd-MM-yy")}
                        </TableCell>
                        <TableCell>{getAvailableDate(p.created_at)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={4}>
                      Non sono ancora stati richiesti premi
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
        <Box mb={8} mt={1}>
          <FiberManualRecord
            fontSize="small"
            style={{
              color: "green",
              marginLeft: 25,
            }}
          />
          <Typography variant="caption">Premi utilizzati</Typography>
          <FiberManualRecord
            fontSize="small"
            style={{
              color: "orange",
              marginLeft: 25,
            }}
          />
          <Typography variant="caption">Premi utilizzabili</Typography>
          <FiberManualRecord
            fontSize="small"
            style={{
              color: "red",
              marginLeft: 25,
            }}
          />
          <Typography variant="caption">
            Premi non ancora utilizzabili
          </Typography>
        </Box>
      </React.Fragment>
    )
  );
}
