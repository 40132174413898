import React from "react";
import {
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { intlFormatMessage } from "../../../utils/utils";
import { common } from "../../../messages";
import { useIntl } from "react-intl";

export default function Tags({ values, handleChange }) {
  const intl = useIntl();

  return (
    <FormControl margin="normal">
      <InputLabel>Tags</InputLabel>
      <Select
        multiple
        value={values.tags}
        onChange={handleChange("tags")}
        input={<Input />}
        renderValue={(selected) => (
          <div>
            {selected.map((value) => (
              <Chip
                key={value}
                label={intlFormatMessage(common[value], value, intl)}
              />
            ))}
          </div>
        )}
      >
        {[{ id: "agreement", label: "agreement" }].map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {intlFormatMessage(common[option.label], option.label, intl)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
