import React from "react";

import { Card, CardActions, Typography } from "@material-ui/core";

import { CardTop, PrimaryActions, SecondaryActions } from "../Product";
import styles from "../../utils/styles";

export default function ProductEkom({
  campaign,
  canPlay,
  canShare,
  cta,
  firstProductId,
  hasEvents,
  hasPrize,
  hasProducts,
  link = false,
  textDateInfo,
  textStatus,
}) {
  const classes = styles.useStyles();

  return (
    <Card className={classes.card}>
      <CardTop campaign={campaign} link={link} subtitle="">
        {hasProducts && (
          <React.Fragment>
            <Typography variant="body2">{textStatus}</Typography>
            <Typography variant="body2">{textDateInfo}</Typography>
          </React.Fragment>
        )}
      </CardTop>

      <CardActions disableSpacing>
        <PrimaryActions
          campaign={campaign}
          canPlay={canPlay}
          canShare={canShare}
          cta={cta}
          firstProductId={firstProductId}
          hasEvents={hasEvents}
          link={link}
        />

        <SecondaryActions
          campaign={campaign}
          canPlay={canPlay}
          firstProductId={firstProductId}
          hasPrize={hasPrize}
          link={link}
        />
      </CardActions>
    </Card>
  );
}
