import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocation, Link as RouterLink } from "react-router-dom";

import { Box, Container, Link } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

import { useBmapi } from "../utils/bmapi-context";
import { CONSUMER_ROUTES, FEATURES } from "../utils/constants";

export function Banner() {
  const { bmapi } = useBmapi();
  const [banner, setBanner] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (bmapi.can(FEATURES.BANNERS)) {
      bmapi.getBanner().then(setBanner);
    }
  }, [bmapi, location.pathname]);

  return (
    !!banner && (
      <Box align="center" key={location.pathname} my={4}>
        <Link href={banner.link} target="_blank">
          <img src={banner.image_url} style={{ maxWidth: "100%" }} />
        </Link>
      </Box>
    )
  );
}

export default function Content({ children }) {
  const { bmapi } = useBmapi();
  const [dismissed, setDismissed] = useState(false);
  const [dismissedNotification, setDismissedNotification] = useState(false);

  const [notificationDisabled, setNotificationDisabled] = useState(false);
  const daysLeft = () =>
    Math.floor(
      bmapi.settings.unverified -
        (new Date() - new Date(bmapi.user.metadata.creationTime)) / 864e5
    );

  const dismiss = () => {
    setDismissed(true);
    bmapi.setUserData({ saveBannerClosed: true });
  };

  const notificationPermissionGiven = () => {
    const permission = Notification.permission;
    if (permission !== "granted") {
      return false;
    }

    bmapi.setFirebaseMessaginNotificationHandler();
    return true;
  };

  const notificationSupported = () => {
    return bmapi.getFirebaseMessaging() != null;
  };

  const askNotificationPermission = async () => {
    if (bmapi.getFirebaseMessaging() != null) {
      bmapi
        .getFirebaseMessaging()
        .requestPermission()
        .then(() => {
          return bmapi.getFirebaseMessagingToken();
        })
        .then((currentToken) => {
          if (currentToken) {
            console.log("Got messaging token: ", currentToken);
            return bmapi.saveMessagingToken("ORDER", currentToken);
          } else {
            console.log("Messaging token impossible to retrive");
          }
        })
        .then(() => {
          bmapi.setFirebaseMessaginNotificationHandler();
          setDismissedNotification(true);
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          setNotificationDisabled(false);
        });
    } else {
      console.log("Firebase messaging not supported");
    }
  };

  return (
    <Box my={5} pb={10}>
      {bmapi.needsVerification() && (
        <Container maxWidth="sm">
          <Box mb={5}>
            <Alert severity="warning">
              <AlertTitle>
                <FormattedMessage
                  id="component.content.notVerifiedAccount"
                  defaultMessage="Account non verificato"
                />
              </AlertTitle>
              {typeof bmapi.settings.unverified === "number" ? (
                <FormattedMessage
                  id="component.content.certifyMessageDays"
                  defaultMessage={`Hai ancora {daysLeft} giorni per certificare il tuo indirizzo email cliccando sul link che hai ricevuto via email al momento della registrazione.`}
                  values={{ daysLeft: daysLeft() }}
                />
              ) : (
                <FormattedMessage
                  id="component.content.certifyMessage"
                  defaultMessage={`Certifica il tuo indirizzo email cliccando sul link che hai ricevuto via email al momento della registrazione.`}
                />
              )}
            </Alert>
          </Box>
        </Container>
      )}

      {bmapi.can(FEATURES.INFO_SAVE) &&
        !bmapi.getUserData().saveBannerClosed &&
        !dismissed && (
          <Container maxWidth="sm">
            <Box mb={5}>
              <Alert severity="info" onClose={dismiss}>
                <Link
                  component={RouterLink}
                  to={
                    CONSUMER_ROUTES.STATIC.replace(":content?/", "faq") +
                    "?save"
                  }
                  onClick={dismiss}
                  color="inherit"
                >
                  <FormattedMessage
                    id="component.content.saveApp"
                    defaultMessage={`Salva l'applicazione sul tuo dispositivo per raggiungerla più velocemente. Clicca qui per scoprire come fare.`}
                  />
                </Link>
              </Alert>
            </Box>
          </Container>
        )}

      {notificationSupported() &&
        bmapi.hasEcommerceOrdering() &&
        window.location.href.endsWith("/manager/orders/") &&
        !dismissedNotification &&
        !notificationPermissionGiven() && (
          <Container maxWidth="sm">
            <Box mb={5}>
              <Alert
                severity="warning"
                onClose={() => setDismissedNotification(true)}
              >
                <Link
                  onClick={() => askNotificationPermission()}
                  color="inherit"
                >
                  {notificationDisabled ? (
                    <FormattedMessage
                      id="component.content.notificationPermissionDenied"
                      defaultMessage={`Clicca qui per abilitare le notifiche push e ricevere aggiornamenti sui nuovi ordini inseriti`}
                    />
                  ) : (
                    <FormattedMessage
                      id="component.content.askNotificationPermission"
                      defaultMessage={`Sembra che le notifiche siano state disabilitate. Per riabilitarle andare nelle impostazioni del browser.`}
                    />
                  )}
                </Link>
              </Alert>
            </Box>
          </Container>
        )}

      {bmapi.isConsumer() && <Banner />}
      {children}
      {bmapi.isConsumer() && <Banner />}
    </Box>
  );
}
