import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useBmapi } from "../utils/bmapi-context";

import { Button, FormControl, Input, InputLabel } from "@material-ui/core";

import LoginCard from "./LoginCard";
import { ArrowBack } from "@material-ui/icons";
import { propagateUrlTracing } from "../utils/utils";

export default function PasswordForgetForm({ onSubmit, routes }) {
  const { bmapi } = useBmapi();
  const history = useHistory();
  const intl = useIntl();
  const [email, setEmail] = useState("");
  const [valid, setValid] = useState(false);

  useEffect(() => {
    setValid(bmapi.validateEmail(email));
  }, [email, bmapi]);

  function handleSubmit(event) {
    event.preventDefault();
    onSubmit(email);
  }

  const backButton = (
    <Button
      onClick={() => history.push(routes.SIGN_IN + propagateUrlTracing())}
      startIcon={<ArrowBack />}
      color="inherit"
    >
      <FormattedMessage id="common.back" defaultMessage="Indietro" />
    </Button>
  );
  return (
    <LoginCard
      title={intl.formatMessage({
        id: "component.passwordForget.title",
        defaultMessage: "Password dimenticata",
      })}
      back={backButton}
    >
      <form onSubmit={handleSubmit}>
        <FormControl margin="normal" required fullWidth>
          <InputLabel htmlFor="email">
            <FormattedMessage id="common.email" defaultMessage="Email" />
          </InputLabel>
          <Input
            id="email"
            name="email"
            value={email}
            autoComplete="email"
            autoFocus
            onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
          />
        </FormControl>

        <FormControl margin="normal" fullWidth>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={!valid}
          >
            <FormattedMessage
              id="component.passwordForget.reset"
              defaultMessage="Richiedi reset"
            />
          </Button>
        </FormControl>
      </form>
    </LoginCard>
  );
}
