import { defineMessages } from "react-intl";

export const distributionLists = defineMessages({
  allList: {
    id: "component.distributionLists.allList",
    defaultMessage: "Tutte le liste",
  },
  createList: {
    id: "component.distributionLists.createList",
    defaultMessage: "Crea lista",
  },
  csvExport: {
    id: "component.distributionLists.csvExport",
    defaultMessage: "Esporta su csv",
  },
  dynamic: {
    id: "component.distributionLists.dynamic",
    defaultMessage: "Lista dinamica",
  },
  dynamicDescription: {
    id: "component.distributionLists.dynamicDescription",
    defaultMessage:
      "La lista dinamica permette di creare una lista di utenti impostando dei filtri. L'elenco di utenti viene automaticamente aggiornato in base alla corrispondenza con i filtri.",
  },
  minAge: {
    id: "component.distributionLists.minAge",
    defaultMessage: "L'età minima è di 16 anni.",
  },
  listType: {
    id: "component.distributionLists.listType",
    defaultMessage: "Tipo di lista",
  },
  selectConsent: {
    id: "component.distributionLists.selectConsent",
    defaultMessage: "Seleziona consenso",
  },
  selectValue: {
    id: "component.distributionLists.selectValue",
    defaultMessage: "Seleziona valore",
  },
  selection: {
    id: "component.distributionLists.selection",
    defaultMessage: "Seleziona gli utenti da inserire nella lista",
  },
  successCreate: {
    id: "component.distributionLists.successCreate",
    defaultMessage: "Lista creata con successo",
  },
  static: {
    id: "component.distributionLists.static",
    defaultMessage: "Lista statica",
  },
  staticDescription: {
    id: "component.distributionLists.staticDescription",
    defaultMessage:
      "La lista statica permette di selezionare gli utenti manualmente utilizzando dei filtri o di importare un elenco di utenti tramite file csv.",
  },
  usersCount: {
    id: "component.distributionLists.usersCount",
    defaultMessage: "{num} utenti",
  },
  usersImport: {
    id: "component.distributionLists.usersImport",
    defaultMessage: "Importa utenti",
  },
  usersSelection: {
    id: "component.distributionLists.usersSelection",
    defaultMessage: "Selezione utenti",
  },
});
