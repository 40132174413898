import React from "react";
import { useIntl } from "react-intl";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

import { campaign as campaignMsg, common } from "../../messages";
import ProductDetails from "../../ui/ProductDetails";
import { PRODUCT_SUBTYPES, PRODUCT_TYPES } from "../../utils/constants";
import { getAvailability } from "../../utils/campaigns";

function getDetails({ campaign, statistics }) {
  const availability = getAvailability({ campaign, statistics });

  switch (campaign.type) {
    case PRODUCT_TYPES.CAMPAIGN_EARNING_CARD:
      switch (campaign.rules.subtype) {
        case PRODUCT_SUBTYPES.EARNING_CARD_INSTANT_WIN:
          return [
            ...(campaign.rules.instant_win_threshold !== 1
              ? [
                  {
                    label: campaignMsg.receivedPoints,
                    value: statistics.received_value / 100,
                  },
                ]
              : []),
            {
              label: campaignMsg.availablePlays,
              value: availability,
            },
            {
              label: campaignMsg.usedPlays,
              value:
                statistics.used_value / campaign.rules.instant_win_threshold,
            },
          ];

        default:
          return [
            {
              label: campaignMsg.balance,
              value: `${availability} ${campaign.rules.currency}`,
            },
            {
              label: campaignMsg.receivedPoints,
              value: `${statistics.received_value / 100} ${
                campaign.rules.currency
              }`,
            },
            {
              label: campaignMsg.usedPoints,
              value: `${statistics.used_value / 100} ${
                campaign.rules.currency
              }`,
            },
            {
              label: campaignMsg.expiredPoints,
              value: `${statistics.expired_value / 100} ${
                campaign.rules.currency
              }`,
            },
          ];
      }

    case PRODUCT_TYPES.CAMPAIGN_SHOPPING_CARD:
      return [
        {
          label: campaignMsg.receivedCards,
          value: statistics.received_qty,
        },
        {
          label: campaignMsg.remainingValue,
          value: `${availability} ${campaign.rules.currency}`,
        },
        {
          label: campaignMsg.usedValue,
          value: `${statistics.used_value / 100} ${campaign.rules.currency}`,
        },
        {
          label: campaignMsg.sharedValue,
          value: `${statistics.issued_value / 100} ${campaign.rules.currency}`,
        },
        {
          label: campaignMsg.expiredValue,
          value: `${statistics.expired_value / 100} ${campaign.rules.currency}`,
        },
      ];
    case PRODUCT_TYPES.CAMPAIGN_COUPON:
      return [
        {
          label: campaignMsg.availablesCoupons,
          value: availability,
        },
        {
          label: campaignMsg.receivedCoupons,
          value: statistics.received_qty,
        },
        {
          label: campaignMsg.usedCoupons,
          value: statistics.used_qty,
        },
        {
          label: campaignMsg.sharedCoupons,
          value: statistics.issued_qty,
        },
        {
          label: campaignMsg.expiredCoupons,
          value: statistics.expired_qty,
        },
      ];

    case PRODUCT_TYPES.CAMPAIGN_EVENT_PASS:
      return [
        {
          label: campaignMsg.availablePasses,
          value: availability,
        },
        {
          label: campaignMsg.receivedPasses,
          value: statistics.received_qty,
        },
        {
          label: campaignMsg.usedPasses,
          value: statistics.used_qty,
        },
        {
          label: campaignMsg.sharedPasses,
          value: statistics.issued_qty,
        },
        {
          label: campaignMsg.expiredPasses,
          value: statistics.expired_qty,
        },
      ];

    default:
      break;
  }

  return [];
}

export default function CardStatistics({ stats }) {
  const intl = useIntl();

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>{intl.formatMessage(common.statistics)}</Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{ display: "block" }}
        id="statistics"
        tabIndex={0}
      >
        {getDetails(stats).map((d, i) => (
          <ProductDetails
            key={i}
            label={intl.formatMessage(d.label)}
            value={d.value}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
}
