import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";

import {
  Accordion,
  AccordionSummary,
  Box,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import {
  Add,
  ArrowBack,
  ArrowForward,
  CancelPresentation,
  Clear,
  EmojiEvents,
  ExpandMore,
  FiberNew,
  TimerOff,
} from "@material-ui/icons";

import { campaign as campaignMsg, common } from "../../messages";
import { ProductCard } from "../../pages/consumer/Campaign";
import { useBmapi } from "../../utils/bmapi-context";
import {
  PRODUCT_TYPES,
  TX_DESCRIPTIONS,
  TX_TYPES,
} from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";

function toMerge(tx1, tx2) {
  return (
    tx1.business_name === tx2.business_name &&
    tx1.description === tx2.description &&
    tx1.type === tx2.type &&
    ![TX_TYPES.DECREASE, TX_TYPES.INCREASE].includes(tx1.type) &&
    tx1.timestamp.slice(0, 16) === tx2.timestamp.slice(0, 16)
  );
}

function mergeTxs(acc, tx) {
  if (acc.length === 0) {
    return [tx];
  }
  const last = acc[acc.length - 1];
  return toMerge(last, tx)
    ? [
        ...acc.slice(0, -1),
        { ...last, counter: last.counter ? last.counter + 1 : 2 },
      ]
    : [...acc, tx];
}

function formatTransaction(campaign, palette, intl) {
  return (t) => {
    switch (campaign.type) {
      case PRODUCT_TYPES.CAMPAIGN_COUPON:
        switch (t.type) {
          case TX_TYPES.ISSUE:
            return {
              id: t.id,
              counter: t.counter || 1,
              title: intl.formatMessage(campaignMsg.couponReceived, {
                counter: t.counter || 1,
              }),
              subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                date: new Date(t.timestamp),
                store: t.business_name,
              }),
              Icon: FiberNew,
              iconBg: palette.info.main,
            };
          case TX_TYPES.BURN:
            return {
              id: t.id,
              counter: t.counter || 1,
              title: intl.formatMessage(campaignMsg.couponUsed, {
                counter: t.counter || 1,
              }),
              subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                date: new Date(t.timestamp),
                store: t.business_name,
              }),
              Icon: ArrowBack,
              iconBg: palette.warning.main,
            };
          case TX_TYPES.EXPIRE:
            return {
              id: t.id,
              counter: t.counter || 1,
              title: intl.formatMessage(campaignMsg.couponExpired, {
                counter: t.counter || 1,
              }),
              subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                date: new Date(t.timestamp),
                store: t.business_name,
              }),
              Icon: TimerOff,
              iconBg: palette.error.main,
            };

          default:
            break;
        }
        break;

      case PRODUCT_TYPES.CAMPAIGN_EVENT_PASS:
        switch (t.type) {
          case TX_TYPES.ISSUE:
            return {
              id: t.id,
              counter: t.counter || 1,
              title: intl.formatMessage(campaignMsg.passReceived, {
                counter: t.counter || 1,
              }),
              subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                date: new Date(t.timestamp),
                store: t.business_name,
              }),
              Icon: FiberNew,
              iconBg: palette.info.main,
            };
          case TX_TYPES.BURN:
            return {
              id: t.id,
              counter: t.counter || 1,
              title: intl.formatMessage(campaignMsg.passUsed, {
                counter: t.counter || 1,
              }),
              subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                date: new Date(t.timestamp),
                store: t.business_name,
              }),
              Icon: ArrowBack,
              iconBg: palette.warning.main,
            };
          case TX_TYPES.EXPIRE:
            return {
              id: t.id,
              counter: t.counter || 1,
              title: intl.formatMessage(campaignMsg.passExpired, {
                counter: t.counter || 1,
              }),
              subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                date: new Date(t.timestamp),
                store: t.business_name,
              }),
              Icon: TimerOff,
              iconBg: palette.error.main,
            };

          default:
            break;
        }
        break;

      case PRODUCT_TYPES.CAMPAIGN_SHOPPING_CARD:
        switch (t.type) {
          case TX_TYPES.ISSUE:
            return {
              id: t.id,
              counter: t.counter || 1,
              title: intl.formatMessage(campaignMsg.cardsReceived, {
                counter: t.counter || 1,
              }),
              subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                date: new Date(t.timestamp),
                store: t.business_name,
              }),
              Icon: FiberNew,
              iconBg: palette.info.main,
            };
          case TX_TYPES.DECREASE:
            return {
              id: t.id,
              counter: t.counter || 1,
              title: intl.formatMessage(campaignMsg.cardValueUsed, {
                value: t.value / 100,
                currency: campaign.rules.currency,
              }),
              subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                date: new Date(t.timestamp),
                store: t.business_name,
              }),
              Icon: ArrowBack,
              iconBg: palette.warning.main,
            };
          case TX_TYPES.BURN:
            return {
              id: t.id,
              counter: t.counter || 1,
              title: intl.formatMessage(campaignMsg.emptyCard, {
                counter: t.counter || 1,
              }),
              subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                date: new Date(t.timestamp),
                store: t.business_name,
              }),
              Icon: CancelPresentation,
              iconBg: palette.error.main,
            };
          case TX_TYPES.EXPIRE:
            return {
              id: t.id,
              counter: t.counter || 1,
              title: intl.formatMessage(campaignMsg.cardExpired, {
                counter: t.counter || 1,
              }),
              subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                date: new Date(t.timestamp),
                store: t.business_name,
              }),
              Icon: TimerOff,
              iconBg: palette.error.main,
            };

          default:
            break;
        }
        break;

      case PRODUCT_TYPES.CAMPAIGN_EARNING_CARD:
        switch (t.type) {
          case TX_TYPES.ISSUE:
            return {
              id: t.id,
              counter: t.counter || 1,
              title:
                t.description === TX_DESCRIPTIONS.PRIZE_ISSUE
                  ? intl.formatMessage(campaignMsg.prizeReceived)
                  : intl.formatMessage(campaignMsg.cardReceived),
              subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                date: new Date(t.timestamp),
                store: t.business_name,
              }),
              Icon:
                t.description === TX_DESCRIPTIONS.PRIZE_ISSUE
                  ? EmojiEvents
                  : FiberNew,
              iconBg:
                t.description === TX_DESCRIPTIONS.PRIZE_ISSUE
                  ? palette.warning.main
                  : palette.info.main,
            };
          case TX_TYPES.DECREASE:
            return {
              id: t.id,
              counter: t.counter || 1,
              title: intl.formatMessage(campaignMsg.pointsUsed, {
                value: t.value / 100,
              }),
              subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                date: new Date(t.timestamp),
                store: t.business_name,
              }),
              Icon: ArrowBack,
              iconBg: palette.error.main,
            };
          case TX_TYPES.INCREASE:
            return {
              id: t.id,
              counter: t.counter || 1,
              title: intl.formatMessage(campaignMsg.pointsReceived, {
                value: t.value / 100,
              }),
              subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                date: new Date(t.timestamp),
                store: t.business_name,
              }),
              Icon: ArrowForward,
              iconBg: palette.success.main,
            };
          case TX_TYPES.INCREASE_BONUS:
            return {
              id: t.id,
              counter: t.counter || 1,
              title: intl.formatMessage(campaignMsg.bonusReceived),
              subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                date: new Date(t.timestamp),
                store: t.business_name,
              }),
              Icon: Add,
              iconBg: palette.info.main,
            };
          case TX_TYPES.FRIEND_BONUS:
            return {
              id: t.id,
              counter: t.counter || 1,
              title: intl.formatMessage(campaignMsg.friendBonusReceived),
              subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                date: new Date(t.timestamp),
                store: t.business_name,
              }),
              Icon: Add,
              iconBg: palette.info.main,
            };
          case TX_TYPES.PROFILE_WEEKLY_BONUS:
            return {
              id: t.id,
              counter: t.counter || 1,
              title: intl.formatMessage(campaignMsg.weeklyBonusReceived),
              subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                date: new Date(t.timestamp),
                store: t.business_name,
              }),
              Icon: Add,
              iconBg: palette.info.main,
            };
          case TX_TYPES.EXPIRE:
            return {
              id: t.id,
              counter: t.counter || 1,
              title: intl.formatMessage(campaignMsg.cardExpired, {
                counter: t.counter || 1,
              }),
              subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                date: new Date(t.timestamp),
                store: t.business_name,
              }),
              Icon: TimerOff,
              iconBg: palette.error.main,
            };
          case TX_TYPES.PLAY:
            return {
              id: t.id,
              counter: t.counter || 1,
              title:
                t.description === TX_DESCRIPTIONS.WINNER
                  ? intl.formatMessage(campaignMsg.win, {
                      counter: t.counter || 1,
                    })
                  : intl.formatMessage(campaignMsg.played, {
                      counter: t.counter || 1,
                    }),
              subtitle: intl.formatMessage(campaignMsg.couponReceivedInfo, {
                date: new Date(t.timestamp),
                store: t.business_name,
              }),
              Icon:
                t.description === TX_DESCRIPTIONS.WINNER ? EmojiEvents : Clear,
              iconBg:
                t.description === TX_DESCRIPTIONS.WINNER
                  ? palette.success.main
                  : palette.warning.main,
            };

          default:
            break;
        }
        break;

      default:
        break;
    }
    return false;
  };
}

export default function CardTransactions({ campaign }) {
  const intl = useIntl();
  const { bmapi, notifyError } = useBmapi();
  const [transactions, setTransactions] = useState(null);
  const [viewTransactions, setViewTransactions] = useState(false);

  const updateTransactions = useCallback(() => {
    if (!viewTransactions) {
      bmapi
        .getTransactionsByCampaign(campaign.id)
        .then((transactions) => {
          setTransactions(
            transactions
              ?.reduce(mergeTxs, [])
              .map(formatTransaction(campaign, bmapi.theme.app.palette, intl))
              .filter(Boolean) || []
          );
        })
        .catch((e) => notifyError(getErrorMessageString(e, intl)));
    }
  }, [bmapi, campaign, intl, notifyError, viewTransactions]);

  const handleOpenTransactions = (f) => {
    if (f && !transactions) updateTransactions();
    setViewTransactions(f);
  };

  return (
    <Accordion
      expanded={viewTransactions}
      onChange={(_, f) => handleOpenTransactions(f)}
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography>{intl.formatMessage(common.transactions)}</Typography>
      </AccordionSummary>
      {!transactions ? (
        <Box display="flex" justifyContent="center" alignItems="center" m={2}>
          <CircularProgress />
        </Box>
      ) : (
        transactions.map((item) => <ProductCard key={item.id} {...item} />)
      )}
    </Accordion>
  );
}
