import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Container,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from "@material-ui/core";
import { TAGS } from "../../utils/ecommerceConstants";
import Title from "../../ui/Title";
import { useIntl } from "react-intl";
import { account, common, confirm, errors, form } from "../../messages";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { MANAGER_ROUTES } from "../../utils/constants";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NumberInput from "../../ui/NumberInput";
import MediaContentForm from "./MediaContentForm";
import {
  checkNullArray,
  decodeFromArray,
  intlFormatMessage,
  isInArray,
} from "../../utils/utils";
import { ecommerce } from "../../messages/ecommerce";
import { Prompt } from "react-router-dom";

const byName = (a, b) => a.name.localeCompare(b.name);

export default function EProduct() {
  const intl = useIntl();
  const { bmapi, notifyError, notifySuccess } = useBmapi();
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  //const [hasCategory, setHasCategory] = useState(null);
  const [mediaContentValues, setMediaContentValues] = useState(null);
  const [dirty, setDirty] = useState(null);
  const [showPage, setShowPage] = useState(true);
  const [filter, setFilter] = useState("general");
  const [values, setValues] = useState({
    name: "",
    description: "",
    category_id: "",
    price: "",
    tags: [],
    media_contents: [],
    priority: 0,
  });

  const handleValue = useCallback(
    (varName) => (e) => {
      ((val) => {
        setDirty(true);
        return setValues((v) => ({ ...v, [varName]: val }));
      })(e.target.value);
    },
    []
  );

  const deleteFromArray = (field, value) => () => {
    let newArr = [];
    values[field].map((item) => {
      if (item !== value) {
        newArr.push(item);
      }
    });
    setValues((v) => ({
      ...v,
      [field]: newArr,
    }));
  };

  /*const isSelected = (code) => {
    if (!values.tags) {
      return false;
    }
    const filtered = values.tags.filter((obj) => obj.value === code);
    return filtered && filtered.length;
  };*/

  /*const deleteTag = (tagToDelete) => {
    let newTag = [];
    values.tags.map((tag) => {
      if (tag.value != tagToDelete.value) {
        newTag.push(tag);
      }
    });
    setValues((v) => ({
      ...v,
      ["tags"]: newTag,
    }));
  };*/

  /*const getLabel = useCallback((code) => {
    const filtered = TAGS.filter((obj) => obj.value === code);
    return filtered && filtered.length ? filtered[0].label : "";
  }, []);*/

  useEffect(() => {
    const productId = new URLSearchParams(location.search).get("id");
    if (productId) {
      if (bmapi) {
        loadProduct(productId);
      }
    }
    loadCategoryList();
    //const categoryId = new URLSearchParams(location.search).get("has_category");
    //setHasCategory(categoryId);
  }, [loadCategoryList, bmapi, loadProduct]);

  const loadProduct = useCallback(
    (productId) => {
      bmapi
        .getEProduct({
          item: productId,
          business: bmapi.getUserInfo().business.id,
        })
        .then((resp) => {
          if (!resp) {
            notifyError(intl.formatMessage(errors.genericError));
            setShowPage(false);
          } else {
            setShowPage(true);
            let product = {
              ...resp[0],
              price: !resp[0].price ? "" : resp[0].price / 100,
              priority: resp[0].priority === 0 ? "0" : resp[0].priority,
              media_contents: !resp[0].media_contents
                ? []
                : resp[0].media_contents,
              tags: checkNullArray(resp[0].tags),
              /*tags: !resp[0].tags
                ? []
                : resp[0].tags.map((code) => {
                    return { value: code, label: getLabel(code) };
                  }),*/
            };
            setValues(product);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
          setShowPage(false);
        });
    },
    [bmapi, intl, notifyError]
  );

  const loadCategoryList = useCallback(() => {
    if (bmapi) {
      bmapi
        .getEProductCategories({
          //business: bmapi.getUserInfo().business.id,
        })
        .then((resp) => {
          setCategories(resp);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
  }, [bmapi, intl, notifyError]);

  const confirmProduct = (event) => {
    event.preventDefault();
    setDirty(false);
    let product = {
      ...values,
      //category_id: values.id ? values.category_id : hasCategory,
      category_id: values.category_id,
      price: Math.round(values.price * 100),
      tags: checkNullArray(values.tags),
      //tags: !values.tags ? [] : values.tags.map((obj) => obj.value),
      priority: +values.priority,
    };
    if (!product.id) {
      bmapi
        .createEProduct(product)
        .then((resp) => {
          history.push(MANAGER_ROUTES.EPRODUCT + "?id=" + resp.id);
          setValues(product);
          loadProduct(resp.id);
          notifySuccess(intl.formatMessage(account.saveConfirm));
          setFilter("multimedia");
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    } else {
      bmapi
        .updateProduct(product)
        .then(() => {
          notifySuccess(intl.formatMessage(account.saveConfirm));
        })
        .then(() => {
          history.push(MANAGER_ROUTES.MANAGE_PRODUCTS);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
  };

  const tabSwitch = (v) => {
    if (!dirty || (dirty && filter === "multimedia")) {
      setFilter(v);
    }
    if (v !== "general" && filter === "general" && dirty) {
      const res = window.confirm(intl.formatMessage(confirm.tabPrompt));
      if (res === true) {
        setFilter(v);
      }
    }
  };

  return (
    <React.Fragment>
      <Prompt when={dirty} message={intl.formatMessage(confirm.exitPrompt)} />
      <Container maxWidth="sm">
        <Title>{intl.formatMessage(ecommerce.newProduct)}</Title>
        <Tabs
          value={filter}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          onChange={(_, v) => {
            tabSwitch(v);
            //setFilter(v);
          }}
        >
          <Tab
            label={intl.formatMessage(common.generalData)}
            value="general"
            style={{ minWidth: 0 }}
          />
          {values.id && (
            <Tab
              label={intl.formatMessage(common.multimediaData)}
              value="multimedia"
              style={{ minWidth: 0 }}
            />
          )}
        </Tabs>

        {filter === "general" && (
          <form onSubmit={confirmProduct}>
            {showPage && (
              <>
                <Box mb={2}>
                  <Card>
                    <CardContent>
                      <TextField
                        margin="normal"
                        label={intl.formatMessage(ecommerce.category)}
                        //value={values.id ? values.category_id : hasCategory}
                        value={values.category_id}
                        onChange={handleValue("category_id")}
                        InputLabelProps={{
                          shrink: values.category_id ? true : false,
                        }}
                        fullWidth
                        select
                        required
                        //disabled={values.id ? false : true}
                      >
                        {categories &&
                          categories.sort(byName).map((category) => (
                            <MenuItem key={category.id} value={category.id}>
                              {category.name}
                            </MenuItem>
                          ))}
                      </TextField>
                      <FormControl fullWidth required margin="normal">
                        <InputLabel>
                          {intl.formatMessage(common.name)}
                        </InputLabel>
                        <Input
                          type="text"
                          value={values.name}
                          name="name"
                          onChange={handleValue("name")}
                        />
                      </FormControl>
                      <FormControl fullWidth required margin="normal">
                        <InputLabel>
                          {intl.formatMessage(form.description)}
                        </InputLabel>
                        <Input
                          type="text"
                          value={values.description}
                          name="description"
                          onChange={handleValue("description")}
                          multiline
                        />
                      </FormControl>
                      <FormControl fullWidth>
                        <NumberInput
                          name="value"
                          label={intl.formatMessage(form.price)}
                          value={values.price}
                          onChange={handleValue("price")}
                          fullWidth
                          required
                          margin="normal"
                          cents
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">€</InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                      {bmapi.settings.flagTag === true && (
                        <FormControl fullWidth margin="normal">
                          <InputLabel>
                            {intl.formatMessage(ecommerce.tag)}
                          </InputLabel>
                          <Select
                            name="tags"
                            multiple
                            fullWidth
                            value={values.tags}
                            onChange={handleValue("tags")}
                            input={<Input />}
                            renderValue={(selected) => (
                              <Box
                                style={{ display: "flex", flexWrap: "wrap" }}
                              >
                                {selected.map((tag) => (
                                  <Chip
                                    onMouseDown={(event) => {
                                      event.stopPropagation();
                                    }}
                                    //onDelete={() => deleteTag(tag)}
                                    onDelete={deleteFromArray("tags", tag)}
                                    key={tag}
                                    label={intlFormatMessage(
                                      ecommerce[decodeFromArray(TAGS, tag)],
                                      tag,
                                      intl
                                    )}
                                  />
                                ))}
                              </Box>
                            )}
                          >
                            {TAGS.map(
                              (tag) =>
                                !isInArray(values.tags, tag.value) && (
                                  <MenuItem key={tag.value} value={tag.value}>
                                    {intlFormatMessage(
                                      ecommerce[tag.label],
                                      tag.label,
                                      intl
                                    )}
                                  </MenuItem>
                                )
                            )}
                          </Select>
                        </FormControl>
                      )}
                      <FormControl fullWidth>
                        <NumberInput
                          name="value"
                          label={intl.formatMessage(ecommerce.displayPriority)}
                          value={values.priority}
                          onChange={handleValue("priority")}
                          fullWidth
                          margin="normal"
                        />
                      </FormControl>
                    </CardContent>
                  </Card>
                </Box>
                <Box mb={2}>
                  <CardActions>
                    <Button type="submit" variant="contained" color="primary">
                      {intl.formatMessage(
                        values.id ? common.update : common.create
                      )}
                    </Button>
                    {values.id && (
                      <>
                        <Button
                          onClick={() => {
                            history.push(MANAGER_ROUTES.MANAGE_PRODUCTS);
                          }}
                          variant="contained"
                        >
                          {intl.formatMessage(common.cancel)}
                        </Button>
                      </>
                    )}
                  </CardActions>
                </Box>
              </>
            )}
          </form>
        )}

        {filter === "multimedia" && (
          <MediaContentForm
            media_contents={values.media_contents}
            setMediaContentValues={setMediaContentValues}
            mediaContentValues={mediaContentValues}
            id={values.id}
            loadData={loadProduct}
            availableLanguages={[]}
            defaultLanguage="" // store without languages - backend control
            entity_type="EcommerceItem"
            available_types={["image"]}
          />
        )}

        <FormControl margin="normal">
          <Button
            onClick={() => history.push(MANAGER_ROUTES.MANAGE_PRODUCTS)}
            startIcon={<ArrowBackIcon />}
          >
            {intl.formatMessage(common.back)}
          </Button>
        </FormControl>
      </Container>
    </React.Fragment>
  );
}
