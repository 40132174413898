import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Cancel, Clear } from "@material-ui/icons";
import React, { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { account, common } from "../../messages";
import { distributionLists } from "../../messages/distributionLists";
import { useBmapi } from "../../utils/bmapi-context";
import { MANAGER_ROUTES } from "../../utils/constants";
import { FILTER_GENDER, LISTS } from "../../utils/distributionListsConstants";
import { getErrorMessageString } from "../../utils/errors";
import {
  compareObjects,
  getLabelFromArrObj,
  intlFormatMessage,
} from "../../utils/utils";
import NumberInput from "../NumberInput";
import FormSection from "./input/FormSection";

function StaticList({ values, setValues, handleValues }) {
  const initialFilters = {
    email: "",
  };
  const intl = useIntl();
  const { bmapi, notifyError } = useBmapi();
  const [open, setOpen] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const [filters, setFilters] = React.useState(initialFilters);
  const [checked, setChecked] = React.useState(false);

  const importCsv = useMemo(() => values.import_csv === "true", [
    values.import_csv,
  ]);
  const userSelection = useMemo(() => values.import_csv === "false", [
    values.import_csv,
  ]);

  const handleFilters = useCallback(
    (varName) => (event) => {
      setFilters({ ...filters, [varName]: event.target.value });
    },
    [filters]
  );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUsers([]);
    setFilters(initialFilters);
    setChecked(false);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    const updatedUsers = users.map((user) => ({
      ...user,
      in_list: event.target.checked,
    }));
    setUsers(updatedUsers);
  };

  const search = () => {
    bmapi
      .getUsersByParams({ email: filters.email })
      .then((resp) => {
        if (!resp) {
          resp = [];
          setUsers(resp);
        } else {
          setUsers(resp);
        }
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
  };

  const confirmList = () => {
    const list = users.filter((user) => user.in_list).map((user) => user);

    let mergeList = values.static_list.concat(list);
    let newList = [
      ...new Set(
        mergeList.filter(
          (item, index) =>
            mergeList.findIndex((obj) => compareObjects(obj, item)) === index
        )
      ),
    ];
    setValues({
      ...values,
      static_list: newList,
    });

    handleClose();
  };

  const removeFromList = (u) => {
    const newList = values.static_list.filter((users) => users.id !== u.id);
    setValues({
      ...values,
      static_list: newList,
    });
  };

  return (
    <React.Fragment>
      <FormSection
        title={
          importCsv
            ? intl.formatMessage(common.uploadFile)
            : intl.formatMessage(distributionLists.usersSelection)
        }
      >
        <FormControl>
          <RadioGroup
            row
            value={values.import_csv}
            onChange={handleValues("import_csv")}
          >
            <FormControlLabel
              value="true"
              control={<Radio required color="primary" />}
              label={intl.formatMessage(distributionLists.usersImport)}
            />
            <FormControlLabel
              value="false"
              control={<Radio required color="primary" />}
              label={intl.formatMessage(distributionLists.usersSelection)}
            />
          </RadioGroup>
        </FormControl>
        <Box mt={2}>
          {importCsv && (
            <Button variant="contained" color="secondary">
              {intl.formatMessage(common.upload)}
            </Button>
          )}
          {userSelection && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleClickOpen}
            >
              {intl.formatMessage(common.add)}
            </Button>
          )}
        </Box>
        <Box mt={3}>
          <Divider />
          {values.static_list.length > 0 && (
            <List>
              {values.static_list.map((user) => (
                <ListItem key={user.id}>
                  <ListItemText
                    primary={user.complete_name || user.first_name}
                    secondary={user.email}
                  />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => removeFromList(user)}>
                      <Cancel />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      </FormSection>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle>
          {intl.formatMessage(distributionLists.usersSelection)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {intl.formatMessage(distributionLists.selection)}
          </DialogContentText>
          <TextField
            fullWidth
            required
            label={intl.formatMessage(common.filterEmail)}
            value={filters.email}
            onChange={handleFilters("email")}
            name="email"
          />
          <Box mt={2} mb={2}>
            <Button
              variant="contained"
              fullWidth
              color="primary"
              onClick={search}
            >
              {intl.formatMessage(common.search)}
            </Button>
          </Box>
          {users.length > 0 && (
            <Box mt={2}>
              <List>
                <Typography variant="h6">
                  {intl.formatMessage(common.results)}
                </Typography>
                {users.map((u) => (
                  <ListItem key={u.id} role={undefined} dense>
                    <ListItemIcon>
                      <Checkbox checked={checked} onChange={handleChange} />
                    </ListItemIcon>
                    <ListItemText
                      primary={u.complete_name}
                      secondary={u.email}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          )}
        </DialogContent>
        {users.length > 0 && (
          <DialogActions>
            <Button onClick={confirmList} color="primary" variant="contained">
              {intl.formatMessage(common.add)}
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
}

function DynamicList({ values, setValues, handleValues, consentsList }) {
  const intl = useIntl();

  const addConsent = () => {
    const { consents, consent_id, consent_value } = values;
    const newConsents = [...consents];

    if (consent_id && consent_value) {
      newConsents.push({ id: consent_id, value: consent_value });
    }

    setValues({
      ...values,
      consents: newConsents,
      consent_id: "",
      consent_value: false,
    });
  };

  const removeConsent = (index) => {
    const newConsents = values.consents.filter((_, i) => i !== index);
    setValues({ ...values, consents: newConsents });
  };

  const isSelected = (id) => {
    const filtered = values.consents?.filter((obj) => obj.id === id);
    return filtered && filtered.length > 0;
  };

  return (
    <React.Fragment>
      <FormSection title={intl.formatMessage(common.filters)}>
        <FormControl>
          <TextField
            select
            label={intl.formatMessage(common.gender)}
            value={values.gender || "ALL"}
            onChange={handleValues("gender")}
          >
            {FILTER_GENDER.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {intlFormatMessage(common[option.label], option.label, intl)}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>

        <Box mt={3}>
          <InputLabel>{intl.formatMessage(common.age)}</InputLabel>
        </Box>
        <Box display="flex">
          <NumberInput
            fullWidth
            margin="normal"
            name="age_from"
            label={intl.formatMessage(common.from)}
            value={values.age_from}
            onChange={handleValues("age_from")}
            error={values.age_from < 16}
            helperText={
              values.age_from < 16 &&
              intl.formatMessage(distributionLists.minAge)
            }
          />
          <NumberInput
            fullWidth
            margin="normal"
            name="age_to"
            label={intl.formatMessage(common.to)}
            value={values.age_to}
            onChange={handleValues("age_to")}
            max={120}
          />
        </Box>

        <FormControl margin="normal">
          <Typography variant="body1" gutterBottom>
            {intl.formatMessage(account.marketing)}
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={values.consent_id ? 8 : 12}>
              <TextField
                fullWidth
                label={intl.formatMessage(distributionLists.selectConsent)}
                value={values.consent_id}
                onChange={handleValues("consent_id")}
                select
                defaultValue=""
              >
                {!!consentsList &&
                  consentsList.map(
                    (c) =>
                      !isSelected(c.id) && (
                        <MenuItem key={c.id} value={c.id}>
                          {c.title}
                        </MenuItem>
                      )
                  )}
              </TextField>
            </Grid>
            {values.consent_id && (
              <Grid item xs={4}>
                <FormLabel component="legend">
                  {intl.formatMessage(distributionLists.selectValue)}
                </FormLabel>
                <RadioGroup
                  row
                  value={values.consent_value}
                  onChange={handleValues("consent_value")}
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio required color="primary" />}
                    label={intl.formatMessage(common.yes)}
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio required color="primary" />}
                    label={intl.formatMessage(common.no)}
                  />
                </RadioGroup>
              </Grid>
            )}
          </Grid>
          {values.consent_id && (
            <Box>
              <Button
                disabled={!values.consent_value}
                onClick={addConsent}
                variant="contained"
                color="secondary"
              >
                {intl.formatMessage(common.add)}
              </Button>
            </Box>
          )}
          <List>
            {values.consents?.map((consent, i) => (
              <ListItem key={consent.id}>
                <ListItemText
                  primary={getLabelFromArrObj(
                    consent.id,
                    consentsList,
                    "id",
                    "title"
                  )}
                />
                <ListItemSecondaryAction>
                  {consent.value === "true"
                    ? intl.formatMessage(common.yes)
                    : intl.formatMessage(common.no)}
                  <IconButton
                    onClick={() => {
                      removeConsent(i);
                    }}
                  >
                    <Clear fontSize="small" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </FormControl>
      </FormSection>
    </React.Fragment>
  );
}

export default function DistributionListForm({
  handleChange,
  handleValues,
  staticType,
  consentsList,
  listType,
  dynamicType,
  values,
  setValues,
  listId,
}) {
  const intl = useIntl();
  const history = useHistory();
  const { bmapi, notifyError, notifySuccess } = useBmapi();

  console.log(listId);

  const submitList = (e) => {
    e.preventDefault();
    let list = {
      ...values,
      dynamic: dynamicType,
      age_from: dynamicType ? +values.age_from : 0,
      age_to: dynamicType ? +values.age_to : 0,
      import_csv: values.import_csv === "true",
      static_list: dynamicType ? [] : values.static_list.map((u) => u.id),
      gender: values.gender === "ALL" ? "" : values.gender,
    };

    if (!listId) {
      bmapi
        .createDistributionList(list)
        .then(() => {
          notifySuccess(intl.formatMessage(distributionLists.successCreate));
          history.push(MANAGER_ROUTES.DISTRIBUTION_LISTS);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    } else {
      bmapi
        .updateDistributionList({ ...list, id: listId })
        .then(() => {
          notifySuccess(intl.formatMessage(distributionLists.successCreate));
          history.push(MANAGER_ROUTES.DISTRIBUTION_LISTS);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
  };

  const inputProps = {
    handleValues,
    consentsList,
    values,
    setValues,
  };

  return (
    <React.Fragment>
      <form onSubmit={submitList}>
        <Box mb={4}>
          <FormSection>
            <FormControl margin="normal">
              <InputLabel>
                {intl.formatMessage(distributionLists.listType)}
              </InputLabel>
              <Select
                value={listType}
                onChange={handleChange}
                disabled={listId}
              >
                {LISTS.map((list) => (
                  <MenuItem key={list.type} value={list.type}>
                    {intlFormatMessage(
                      distributionLists[list.type],
                      list.type,
                      intl
                    )}
                  </MenuItem>
                ))}
              </Select>
              <Box mt={1}>
                <Typography variant="caption">
                  {staticType &&
                    intl.formatMessage(distributionLists.staticDescription)}
                  {dynamicType &&
                    intl.formatMessage(distributionLists.dynamicDescription)}
                </Typography>
              </Box>
            </FormControl>
          </FormSection>
          <FormSection title={intl.formatMessage(common.generalInfo)}>
            <TextField
              required
              label={intl.formatMessage(common.name)}
              value={values.name}
              onChange={handleValues("name")}
              name="name"
            />
          </FormSection>
          {staticType && <StaticList {...inputProps} />}
          {dynamicType && <DynamicList {...inputProps} />}

          <Box>
            <Button variant="contained" fullWidth color="primary" type="submit">
              {listId
                ? intl.formatMessage(common.update)
                : intl.formatMessage(common.create)}
            </Button>
          </Box>
        </Box>
      </form>
    </React.Fragment>
  );
}
