import { defineMessages } from "react-intl";

export const museumLabels = defineMessages({
  editBusiness: {
    id: "component.manageMuseums.editStore",
    defaultMessage: "Modifica Destinazione/Luogo della cultura",
  },
  deleteBusiness: {
    id: "component.manageMuseums.deleteStore",
    defaultMessage: "Elimina Destinazione/Luogo della cultura",
  },
  noBusinessRegistered: {
    id: "component.manageMuseums.noStoreRegistered",
    defaultMessage:
      "Nessun Destinazione/Luogo della cultura ancora registrato.",
  },
  emptyAction: {
    id: "component.manageMuseums.emptyAction",
    defaultMessage:
      "Crea il primo Destinazione/Luogo della cultura cliccando sul bottone a fondo pagina o <link>qui</link>.",
  },
  pageTitle: {
    id: "pages.museums.title",
    defaultMessage: "Impost. accesso/fruizione",
  },
  createBusiness: {
    id: "component.manageMuseums.createStore",
    defaultMessage: "Crea Destinazione/Luogo della cultura",
  },
});
