import React, { useCallback, useEffect } from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";

import { MuiThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme, CssBaseline } from "@material-ui/core";

import Routing from "./Routing";
import NotFound from "../pages/NotFound";

import Toast from "../ui/Toast";
import Loading from "../ui/Loading";
import { useBmapi } from "../utils/bmapi-context";
import {
  CONSUMER_NAMESPACE,
  MANAGER_NAMESPACE,
  OLD_MANAGER_NAMESPACE,
} from "../utils/constants";
import InfoDialog from "../ui/InfoDialog";
import CookiePreferences from "../ui/CookiePreferences";
import CookieBanner2 from "../ui/CookieBanner2";
import { oneYearExpiration } from "../utils/utils";
export default function App() {
  const {
    bmapi,
    hideMessage,
    loading,
    message,
    showMessage,
    showDialog,
    dialogInfo,
    hideDialog,
    starting,
  } = useBmapi();
  const location = useLocation();
  const [openConsent, setOpenConsent] = React.useState(true);
  const [hide, setHide] = React.useState(true);
  const [customConsent, setCustomConsent] = React.useState({});
  const tenantId = bmapi.settings.tenantId;

  useEffect(() => {
    if (bmapi)
      ["rc", "SC"].map((key) => {
        const val = new URLSearchParams(location.search).get(key);
        if (val) bmapi.setTenantData({ [key]: val });
      });
    checkACookieExists();
  }, [bmapi, location, checkACookieExists]);

  const checkACookieExists = useCallback(() => {
    if (
      document.cookie
        .split(";")
        .some((item) => item.trim().startsWith(`cconsents${tenantId}=`))
    ) {
      setOpenConsent(false);
      setHide(false);
    } else setOpenConsent(true);
  }, [tenantId]);

  const setCookie = (
    tenantId,
    mandatoryConsent,
    preferencesConsent,
    marketingConsent,
    statisticsConsent
  ) => {
    /*
    console.log(
      "secure test",
      tenantId,
      window.location.hostname,
      window.location.hostname.split(".").slice(1).join(".")
    );*/
    var secure = window.location.protocol.indexOf("https") >= 0 ? "Secure" : "";
    var domain =
      ";domain=." + window.location.hostname.split(".").slice(1).join(".");
    /*let arr = window.location.hostname.split(".");
    if (arr.length > 2) {
      arr = arr.shift(arr.length - 2);
    }
    const domain = arr.join(".");*/
    var expires = oneYearExpiration().toUTCString();
    document.cookie = `cconsents${tenantId}=mandatory:${mandatoryConsent}|preferences:${preferencesConsent}|marketing:${marketingConsent}|statistics:${statisticsConsent}; ${secure} ; path=/; ${domain}; expires= ${expires}`;
  };

  const reloadConsent = (data) => {
    setOpenConsent(data);
    setHide(data);
  };

  const updateCustomConsent = (custom) => {
    setCustomConsent(custom);
  };

  console.log(
    "LOADING, Api.js: rendering starting=",
    starting,
    ", bmapi.isManagerEnabled",
    !!bmapi.isManagerEnabled,
    ", bmapi.isConsumerEnabled",
    !!bmapi.isConsumerEnabled,
    "location.pathname=",
    location.pathname,
    "bmapi.app=",
    bmapi.app,
    "location=",
    location,
    window.location.href
  );
  console.log("URL", window.location.href);

  return (
    <MuiThemeProvider theme={bmapi.theme.app || createMuiTheme()}>
      <CssBaseline />
      {!starting && (
        <main className={`bme-${bmapi.app}`}>
          {bmapi.customCSS && <link rel="stylesheet" href={bmapi.customCSS} />}
          <Switch>
            <Route path={OLD_MANAGER_NAMESPACE}>
              <Redirect to={MANAGER_NAMESPACE} />
            </Route>
            {!!bmapi.isManagerEnabled && (
              <Route path={MANAGER_NAMESPACE} component={Routing} />
            )}
            {!!bmapi.isConsumerEnabled && (
              <Route path={CONSUMER_NAMESPACE} component={Routing} />
            )}
            {!bmapi.isConsumerEnabled && !!bmapi.isManagerEnabled && (
              <Route exact path={CONSUMER_NAMESPACE}>
                <Redirect to={MANAGER_NAMESPACE} />
              </Route>
            )}
            <Route component={NotFound} />
          </Switch>
          <Toast
            message={message.text}
            onClose={hideMessage}
            open={showMessage}
            variant={message.type}
          />
          <InfoDialog
            info={dialogInfo}
            onClose={hideDialog}
            open={showDialog}
          />
          <CookieBanner2
            openConsent={openConsent}
            setOpenConsent={setOpenConsent}
            checkACookieExists={checkACookieExists}
            setCookie={setCookie}
            customConsent={customConsent}
            setCustomConsent={setCustomConsent}
          />
          {!hide && (
            <CookiePreferences
              setCookie={setCookie}
              reloadConsent={reloadConsent}
              updateCustomConsent={updateCustomConsent}
            />
          )}
        </main>
      )}
      <Loading
        loading={starting || loading}
        color={starting ? "inherit" : bmapi.themeConf.primary}
      />
    </MuiThemeProvider>
  );
}
