import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/messaging";

function logError({ code, message }) {
  console.error("Firebase persistance: ERROR");
  console.error(`Code: ${code}`);
  console.error(`MESSAGE: ${message}`);
}

class Firebase {
  constructor(
    config,
    name,
    persistance = "none",
    listener /*, analytics*/,
    firebaseTenant
  ) {
    try {
      this.app = firebase.app(name);
    } catch {
      this.app = firebase.initializeApp(config, name);

      /*
      try {
        if (analytics) this.analytics = this.app.analytics();
      } catch (e) {
        // console.log(e);
      }*/
    }

    this.messaging = firebase.messaging.isSupported()
      ? firebase.messaging(this.app)
      : null;
    this.messagingApiKey = config.messagingApiKey;

    this.auth = this.app.auth();
    if (firebaseTenant) {
      console.log("eeeeee", firebaseTenant);
      this.auth.tenantId = firebaseTenant;
    }
    //this.log = (event) => !!analytics && this.analytics.logEvent(event);

    this.app.auth().setPersistence(persistance).catch(logError);
    this.app.auth().onAuthStateChanged(listener);
  }
}

export default Firebase;
export const EmailAuthProvider = firebase.auth.EmailAuthProvider;
export const FacebookAuthProvider = firebase.auth.FacebookAuthProvider;
export const GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
export const TwitterAuthProvider = firebase.auth.TwitterAuthProvider;
