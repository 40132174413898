// SafeMarkdownEditor.js
import React, { useState, useEffect } from "react";
import { TextField, IconButton, Box } from "@material-ui/core";
import { Visibility, TextFields } from "@material-ui/icons";
import SafeMarkdownView from "./SafeMarkdownView";

const SafeMarkdownEditor = (props) => {
  const { value, onChange, ...rest } = props;
  const [text, setText] = useState(value || "");
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    setText(value || "");
  }, [value]);

  const handleTextChange = (event) => {
    const txt = event.target.value;
    setText(txt);
    onChange?.(txt);
  };

  const togglePreview = () => {
    setShowPreview(!showPreview);
  };

  return (
    <Box width="100%" display="flex" flexDirection="column">
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        height="40px"
        paddingRight="8px"
        borderBottom="1px solid #ccc"
      >
        <IconButton onClick={togglePreview} size="small">
          {showPreview ? (
            <TextFields fontSize="small" />
          ) : (
            <Visibility fontSize="small" />
          )}
        </IconButton>
      </Box>
      <Box
        flex="1"
        border="1px solid #ccc"
        borderRadius="4px"
        padding="8px"
        overflow="hidden"
      >
        {showPreview ? (
          <Box height="100%" overflow="auto">
            <SafeMarkdownView content={text} />
          </Box>
        ) : (
          <TextField
            {...rest}
            onChange={handleTextChange}
            value={text}
            fullWidth
          />
        )}
      </Box>
    </Box>
  );
};

export default SafeMarkdownEditor;
