import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { Add, Delete, ExpandMore } from "@material-ui/icons";
import { format, parse } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { account, common, confirm, errors } from "../../messages";
import Confirm from "../../ui/Confirm";
import { TimePicker } from "../../ui/DatePicker";
import NumberInput from "../../ui/NumberInput";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import styles from "../../utils/styles";
import { sortByDaysOfTheWeek } from "../../utils/utils";
import EOrderClosingDates from "../../components/EOrderClosingDates";

const duration = [
  { value: 10, label: "10" },
  { value: 15, label: "15" },
  { value: 20, label: "20" },
  { value: 30, label: "30" },
  { value: 60, label: "60" },
  { value: 90, label: "90" },
  { value: 120, label: "120" },
];

const days = [
  { value: "MON", label: "Lunedì" },
  { value: "TUE", label: "Martedì" },
  { value: "WED", label: "Mercoledì" },
  { value: "THU", label: "Giovedì" },
  { value: "FRI", label: "Venerdì" },
  { value: "SAT", label: "Sabato" },
  { value: "SUN", label: "Domenica" },
];

const getDateText = (date) => {
  const day = days.find((day) => day.value === date);
  return day.label;
};

function ConfList({
  confList,
  deleteRequest,
  deleteAllRequest,
  onDelete,
  setDeleteRequest,
  setDeleteAllRequest,
  onAction,
  openConfig,
}) {
  const classes = styles.useStyles();
  const intl = useIntl();
  const [confToDelete, setConfToDelete] = React.useState(null);
  const [filterTab, setFilterTab] = useState("closingDates");

  const getTimeText = (time) => {
    return time.slice(0, 2) + ":" + time.slice(2);
  };

  const getFormattedTime = (time) => {
    const newDate = format(new Date(), "dd/MM/yyyy");
    const newTime = time.slice(0, 2) + ":" + time.slice(2);
    const dateTime = newDate + " " + newTime;
    return parse(dateTime, "dd/MM/yyyy HH:mm", new Date());
  };

  const formatConf = (conf, clone) => {
    // format conf obj for bmapi
    const formatTimeSlots = conf.time_slots.map((item) => {
      return {
        start: getFormattedTime(item.start),
        end: getFormattedTime(item.end),
      };
    });

    let formattedConf = {
      ...conf,
      production_capacity: conf.time_slots[0].production_capacity,
      // production_capacity has the same value in every slots
      time_slots: formatTimeSlots,
    };

    // remove existing day from dates array for cloning on other available days
    if (clone) {
      const daysArr = days.filter((day) => day.value !== formattedConf.day);
      onAction(formattedConf, daysArr);
    } else {
      onAction(formattedConf, "edit");
    }
  };

  const handleDelete = (c) => {
    setDeleteRequest(true);
    setConfToDelete(c);
  };

  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      {deleteRequest && (
        <Confirm
          open={!!deleteRequest}
          onConfirm={() => onDelete(confToDelete)}
          onCancel={() => setDeleteRequest(false)}
          text={intl.formatMessage(confirm.deleteElement)}
        />
      )}
      {deleteAllRequest && (
        <Confirm
          open={!!deleteAllRequest}
          onConfirm={() => onDelete(confList)}
          onCancel={() => setDeleteAllRequest(false)}
          text={intl.formatMessage(confirm.deleteElements, {
            num: confList.length,
          })}
        />
      )}
      <Title>Configurazioni</Title>
      <Tabs
        value={filterTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        onChange={(_, v) => setFilterTab(v)}
      >
        <Tab
          label="Giorni di chiusura"
          value="closingDates"
          style={{ minWidth: 0 }}
        />
        <Tab
          label="Conf. giornaliera"
          value="configuration"
          style={{ minWidth: 0 }}
        />
      </Tabs>
      {filterTab === "closingDates" && <EOrderClosingDates />}
      {filterTab === "configuration" && (
        <React.Fragment>
          {/*Inserire una configurazione per i giorni di disponibilità.*/}
          <Box mt={2}>
            {confList.length > 0 ? (
              confList.sort(sortByDaysOfTheWeek).map((c) => (
                <Accordion key={c.id}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1c-content"
                    id="panel1c-header"
                  >
                    <div>
                      <Typography>{getDateText(c.day)}</Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box>
                      <Typography
                        gutterBottom
                        variant="body1"
                        className={classes.disabledText}
                      >
                        Durata slot: {c.time_slot_value} minuti
                      </Typography>
                      <Typography
                        gutterBottom
                        variant="body1"
                        className={classes.disabledText}
                      >
                        Num. max prenotazioni per slot:{" "}
                        {c.time_slots[0].production_capacity}
                        {/* production_capacity has the same value in every slots */}
                      </Typography>
                      {c.time_slots.map((slot) => (
                        <>
                          <TextField
                            margin="normal"
                            label="Orario di inizio"
                            value={getTimeText(slot.start)}
                            disabled
                          />
                          <TextField
                            margin="normal"
                            label="Orario di fine"
                            value={getTimeText(slot.end)}
                            disabled
                          />
                        </>
                      ))}
                    </Box>
                  </AccordionDetails>
                  <Divider />
                  <AccordionActions>
                    <Button
                      color="primary"
                      onClick={() => handleDelete(c)}
                      variant="outlined"
                    >
                      Elimina
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => formatConf(c, 1)}
                      disabled={days.length === confList.length}
                      variant="outlined"
                    >
                      Duplica
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => formatConf(c, 0)}
                      variant="outlined"
                    >
                      Modifica
                    </Button>
                  </AccordionActions>
                </Accordion>
              ))
            ) : (
              <Card>
                <CardContent>
                  <Typography>Nessuna configurazione inserita.</Typography>
                </CardContent>
              </Card>
            )}
          </Box>

          <Box mt={2} style={{ float: "right" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => openConfig()}
            >
              {intl.formatMessage(common.add)}
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: 10 }}
              onClick={() => setDeleteAllRequest(true)}
            >
              Elimina tutte
            </Button>
          </Box>

          {/* <FloatingActions>
            <Fab
              variant="extended"
              color="primary"
              onClick={() => openConfig()}
            >
              <Add />
              {intl.formatMessage(form.configAdd)}
            </Fab>
          </FloatingActions> */}
        </React.Fragment>
      )}
    </Container>
  );
}

const getEmpyTimeSlot = (arr) =>
  !arr || arr.length === 0 ? [{ start: null, end: null }] : arr;

const initialValues = (conf = {}) => ({
  content_id: conf.content_id || "",
  day: conf.day || "",
  id: conf.id || "",
  inactive: conf.inactive || false,
  time_slot_value: conf.time_slot_value || 0,
  production_capacity: conf.production_capacity || "",
  over_production: conf.over_production || 0,
  time_slots: getEmpyTimeSlot(conf.time_slots) || getEmpyTimeSlot(null),
});

const initialDays = [
  { value: "MON", label: "Lunedì" },
  { value: "TUE", label: "Martedì" },
  { value: "WED", label: "Mercoledì" },
  { value: "THU", label: "Giovedì" },
  { value: "FRI", label: "Venerdì" },
  { value: "SAT", label: "Sabato" },
  { value: "SUN", label: "Domenica" },
];

export default function ConfigurationGen() {
  const intl = useIntl();
  const {
    bmapi,
    notifyError,
    startLoading,
    stopLoading,
    notifySuccess,
  } = useBmapi();
  const [values, setValues] = useState(initialValues());
  const [availableDays, setAvailableDays] = useState(initialDays);
  const [openDialog, setOpenDialog] = useState(false);
  const [edit, setEdit] = useState(false);
  const [clone, setClone] = useState(false);
  const [deleteRequest, setDeleteRequest] = React.useState(false);
  const [deleteAllRequest, setDeleteAllRequest] = React.useState(false);
  const [confList, setConfList] = React.useState(null);
  const [dates, setDates] = React.useState("");
  const bs = bmapi.getUserInfo().business.id;

  const loadConfig = useCallback(() => {
    if (bmapi) {
      bmapi
        .getEcomConf({
          business: bs,
        })
        .then((resp) => {
          if (!resp) {
            resp = [];
          }
          setConfList(resp);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
  }, [bmapi, intl, notifyError, bs]);

  useEffect(() => {
    loadConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadConfig]);

  const getFormattedTimeSlots = useCallback(() => {
    // time_slots: [{start: "", end: "", production_capacity: ""}] for bmapi
    let confTimeSlots = [];

    for (let i = 0; i < values.time_slots.length; i++) {
      if (values.time_slots[i].start && values.time_slots[i].end) {
        let timeslots = {};
        timeslots.start = format(values.time_slots[i].start, "HHmm");
        timeslots.end = format(values.time_slots[i].end, "HHmm");
        timeslots.production_capacity = +values.production_capacity;
        confTimeSlots.push(timeslots);
      }
    }
    return confTimeSlots;
  }, [values]);

  const getFormattedObj = useCallback(
    (i) => {
      let conf = {};
      conf.day = dates[i] || values.day;
      conf.id = clone ? "" : values.id || "";
      conf.inactive = false;
      conf.time_slot_value = +values.time_slot_value;
      conf.time_slots = getFormattedTimeSlots();

      return conf;
    },
    [dates, values, getFormattedTimeSlots, clone]
  );

  const handleValue = useCallback((label) => {
    const updateValue = (val) => {
      setValues((v) => ({ ...v, [label]: val }));
    };

    return (i, f) => {
      if (typeof f === "boolean") updateValue(f);
      else if (i?.target) updateValue(i.target.value);
      else updateValue(i);
    };
  }, []);

  const handleTimeValue = useCallback(
    (label, index) => (e) => {
      const value = e;
      const newValues = { ...values };
      newValues.time_slots[index][label] = value;
      setValues(newValues);
    },
    [values]
  );

  const handleAddTime = useCallback(
    (index) => () => {
      const newValues = { ...values };
      newValues.time_slots.splice(index + 1, 0, {
        start: null,
        end: null,
      });
      setValues(newValues);
    },
    [values]
  );

  const handleDeleteTime = useCallback(
    (index) => () => {
      const newValues = { ...values };
      newValues.time_slots.splice(index, 1);
      if (!newValues.time_slots.length) {
        newValues.time_slots.push({ start: null, end: null });
      }
      setValues(newValues);
    },
    [values]
  );

  const handleToggle = (value) => () => {
    const currentIndex = dates.indexOf(value);
    const newChecked = [...dates];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setDates(newChecked);
  };

  const openConfig = () => {
    setOpenDialog(true);
  };

  const closeConfig = () => {
    setOpenDialog(false);
    setEdit(false);
    setClone(false);
    setDates("");
    setValues(initialValues());
    setAvailableDays(initialDays);
  };

  const onDelete = (conf) => {
    const deleteConf = (c) => {
      bmapi
        .deleteEcomConf(c.id, bs)
        .then(() => {
          setDeleteRequest(false);
          setDeleteAllRequest(false);
          notifySuccess(intl.formatMessage(common.deleteElement));
        })
        .then(() => loadConfig())
        .catch((e) => {
          setDeleteRequest(false);
          setDeleteAllRequest(false);
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    };

    startLoading();
    if (!conf.length) deleteConf(conf);
    else conf.map((c) => deleteConf(c));
  };

  const onAction = (conf, params) => {
    params === "edit" ? setEdit(true) : setClone(true);
    params !== "edit" ? setAvailableDays(params) : null;
    openConfig();
    setValues(initialValues(conf));
  };

  const update = useCallback(
    (c) => {
      // format conf obj for bmapi
      const conf = getFormattedObj();

      // check if a time range is selected (start - end)
      if (!conf.time_slots.length) {
        notifyError(
          "È obbligatorio indicare almeno un orario di inizio e un orario di fine."
        );
      } else {
        startLoading();

        bmapi
          .updateEcomConf(c || conf, bs)
          .then(() => {
            notifySuccess(intl.formatMessage(account.saveConfirm));
            closeConfig();
          })
          .then(() => loadConfig())
          .catch((e) => {
            if (e.message === "range must be a multiple of time slot value") {
              notifyError(
                "Si è verificato un errore. Gli orari scelti non sono compatibili con la durata dello slot."
              );
            } else if (
              e.message ===
              "start time cannot be equal or greater than end time"
            ) {
              notifyError(intl.formatMessage(errors.equalTime));
            } else notifyError(getErrorMessageString(e, intl));
          })
          .finally(() => {
            stopLoading();
          });
      }
    },
    [
      bmapi,
      intl,
      loadConfig,
      notifyError,
      notifySuccess,
      startLoading,
      stopLoading,
      getFormattedObj,
      bs,
    ]
  );

  const submit = (e) => {
    e.preventDefault();

    if (!edit) {
      let confs = [];

      for (let i = 0; i < dates.length; i++) {
        // format conf obj for bmapi for selected dates
        let conf = getFormattedObj(i);
        confs.push(conf);
      }

      if (!confs.length) {
        // check if at list one day is checked
        notifyError(
          "È obbligatorio indicare almeno un giorno per impostare la configurazione."
        );
      } else if (!confs[0].time_slots.length) {
        // check if a time range is selected (start - end)
        notifyError(
          "È obbligatorio indicare almeno un orario di inizio e un orario di fine."
        );
      } else {
        startLoading();
        // obtain array of dates to compare and decide if the configuration is new,
        // or if already exists for a certain day and needs to be modified.

        const days = confList.map((obj) => obj.day);

        const confToUpdate = confs.filter((obj) => days.includes(obj.day));
        const confToCreate = confs.filter((obj) => !days.includes(obj.day));

        // assign existing conf id to the new conf for the same day, so it is edit and not create.
        for (let i = 0; i < confToUpdate.length; i++) {
          const id = confList.find((conf) => conf.day === confToUpdate[i].day)
            .id;

          confToUpdate[i].id = id;
        }

        // check if there are conf to edit
        if (confToUpdate.length > 0) {
          if (
            window.confirm(
              "Attenzione! I giorni precedentemente impostati verranno sostituiti dalla nuova configurazione. Vuoi continuare?"
            )
          ) {
            confToUpdate.map((c) => update(c));
          } else {
            closeConfig();
          }
        }

        // check if there are conf to create
        confToCreate.length > 0 &&
          bmapi
            .createEcomConf({ confs: confToCreate }, bs)
            .then(() => {
              notifySuccess(intl.formatMessage(account.saveConfirm));
              closeConfig();
            })
            .then(() => loadConfig())
            .catch((e) => {
              if (e.message === "range must be a multiple of time slot value") {
                notifyError(
                  "Si è verificato un errore. Gli orari scelti non sono compatibili con la durata dello slot."
                );
              } else if (
                e.message ===
                "start time cannot be equal or greater than end time"
              ) {
                notifyError(intl.formatMessage(errors.equalTime));
              } else notifyError(getErrorMessageString(e, intl));
            })
            .finally(() => {
              stopLoading();
            });
      }
    } else {
      // edit single configuration
      update();
    }
  };

  return (
    <Container maxWidth="sm">
      <Dialog open={openDialog} onClose={closeConfig} fullWidth>
        <DialogTitle>Aggiungi configurazione</DialogTitle>
        <form onSubmit={submit}>
          <DialogContent>
            <React.Fragment>
              <FormControl margin="dense" required>
                <FormLabel>Durata slot (minuti)</FormLabel>
                <RadioGroup
                  row
                  name="slot_value"
                  value={+values.time_slot_value}
                  onChange={handleValue("time_slot_value")}
                >
                  {duration.map((time) => (
                    <FormControlLabel
                      key={time}
                      value={time.value}
                      control={<Radio required size="small" color="primary" />}
                      label={time.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              {values.time_slots.map((time, i) => (
                <React.Fragment key={i}>
                  <List style={{ padding: 0 }}>
                    <TimePicker
                      style={{ marginTop: 0 }}
                      label={intl.formatMessage(common.from)}
                      value={time.start}
                      onChange={handleTimeValue("start", i)}
                      clearable
                      required
                    />
                    <TimePicker
                      style={{ marginTop: 0 }}
                      label={intl.formatMessage(common.to)}
                      value={time.end}
                      onChange={handleTimeValue("end", i)}
                      clearable
                      required
                    />
                    <ListItemSecondaryAction>
                      <IconButton onClick={handleAddTime(i)}>
                        <Add />
                      </IconButton>
                      <IconButton onClick={handleDeleteTime(i)}>
                        <Delete />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </List>
                </React.Fragment>
              ))}
              <NumberInput
                margin="dense"
                float
                name="reservations_quantity"
                label="Num. max prenotazioni per slot"
                value={values.production_capacity}
                onChange={handleValue("production_capacity")}
                fullWidth
                required
              />
              {!edit && (
                <Box mt={2}>
                  <List>
                    <Typography variant="h6">
                      Indica i giorni per i quali vuoi impostare la
                      configurazione
                    </Typography>
                    <Box display="flex" flexWrap="wrap">
                      {availableDays.map((day) => {
                        const labelId = day.label;
                        return (
                          <ListItem
                            style={{ width: "50%" }}
                            key={day.value}
                            dense
                            button
                            onClick={handleToggle(day.value)}
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={dates.indexOf(day.value) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={day.label} />
                          </ListItem>
                        );
                      })}
                    </Box>
                  </List>
                </Box>
              )}
            </React.Fragment>
          </DialogContent>
          <DialogActions style={{ marginTop: 20 }}>
            <Button variant="outlined" onClick={closeConfig}>
              {intl.formatMessage(common.cancel)}
            </Button>
            <Button variant="contained" color="primary" type="submit">
              {intl.formatMessage(common.confirm)}
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {confList ? (
        <ConfList
          confList={confList}
          deleteRequest={deleteRequest}
          setDeleteRequest={setDeleteRequest}
          deleteAllRequest={deleteAllRequest}
          setDeleteAllRequest={setDeleteAllRequest}
          onDelete={onDelete}
          onAction={onAction}
          openConfig={openConfig}
        />
      ) : (
        <Card>
          <CardContent>
            <Typography>Nessuna configurazione inserita</Typography>
          </CardContent>
        </Card>
      )}
    </Container>
  );
}
