import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { endOfDay } from "date-fns";

import { Card, List, Menu, MenuItem } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";

import { campaign as campaignMsg, product as productMsg } from "../../messages";
import { ProductCard } from "../../pages/consumer/Campaign";
import {
  PERMISSION_TYPES,
  PRODUCT_SUBTYPES,
  PRODUCT_TYPES,
} from "../../utils/constants";
import { getLabel, IconsMap } from "../../utils/campaigns";
import { useBmapi } from "../../utils/bmapi-context";
import { getShareUrl } from "../../ui/Product";

function getProductInfo(products, type, businesses = [], intl) {
  switch (type) {
    case PRODUCT_TYPES.CAMPAIGN_SHOPPING_CARD:
      return products.map((p) => ({
        id: p.id,
        title: intl.formatMessage(campaignMsg.giftcardBalance, {
          balance: p.available_value / 100,
          currency: p.currency,
        }),
        subtitle: intl.formatMessage(campaignMsg.expiration, {
          expirationDate: new Date(p.expiration_date),
        }),
        Icon: IconsMap[p.subtype],
      }));

    case PRODUCT_TYPES.CAMPAIGN_EVENT_PASS:
    case PRODUCT_TYPES.CAMPAIGN_COUPON:
      return products
        .reduce(
          (acc, p) =>
            acc.some(
              (prod) =>
                prod.expiration_date ===
                  endOfDay(new Date(p.expiration_date)).toISOString() &&
                prod.business_restrictions?.join(";") ===
                  p.business_restrictions?.join(";") &&
                prod.available_shots === p.available_shots
            )
              ? acc
              : [
                  ...acc,
                  {
                    product: p,
                    expiration_date: endOfDay(
                      new Date(p.expiration_date)
                    ).toISOString(),
                    available_shots: p.available_shots,
                    counter: products.filter(
                      (prod) =>
                        endOfDay(
                          new Date(prod.expiration_date)
                        ).toISOString() ===
                          endOfDay(new Date(p.expiration_date)).toISOString() &&
                        prod.business_restrictions?.join(";") ===
                          p.business_restrictions?.join(";") &&
                        prod.available_shots === p.available_shots
                    ).length,
                  },
                ],
          []
        )
        .map((p) => {
          const restrictions = p.product.business_restrictions
            ? p.product.business_restrictions
                .map((bs) => businesses.find((b) => b.id === bs)?.name)
                .join(", ")
            : false;

          return {
            id: p.product.id,
            title:
              type === PRODUCT_TYPES.CAMPAIGN_EVENT_PASS
                ? intl.formatMessage(
                    restrictions
                      ? campaignMsg.eventPassInfoRestricted
                      : campaignMsg.eventPassInfo,
                    {
                      counter: p.counter,
                      restrictions,
                    }
                  )
                : p.product.subtype === PRODUCT_SUBTYPES.COUPON_VALUE
                ? intl.formatMessage(
                    restrictions
                      ? campaignMsg.couponValueInfoRestricted
                      : campaignMsg.couponValueInfo,
                    {
                      counter: p.counter,
                      value:
                        (p.counter *
                          p.product.value *
                          (p.product.shots === 0 ? 1 : p.available_shots)) /
                        100,
                      currency: p.product.currency,
                      restrictions,
                    }
                  )
                : intl.formatMessage(
                    restrictions
                      ? campaignMsg.couponInfoRestricted
                      : campaignMsg.couponInfo,
                    {
                      counter: p.counter,
                      restrictions,
                    }
                  ),
            subtitle: intl.formatMessage(campaignMsg.expiration, {
              expirationDate: new Date(p.expiration_date),
            }),
            counter: p.counter,
            Icon: IconsMap[p.product.subtype],
          };
        });

    default:
      return [];
  }
}

export function SharedProduct({ campaign, permission, product, onUpdate }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const intl = useIntl();
  const {
    baseUrl,
    bmapi,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();

  const shareUrl = getShareUrl(baseUrl, permission?.short_code);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const copy = useCallback(() => {
    return navigator.clipboard?.writeText(shareUrl).then(() =>
      notifySuccess(
        intl.formatMessage({
          id: "component.friend.urlCopied",
          defaultMessage: "Link copiato",
        })
      )
    );
  }, [intl, notifySuccess, shareUrl]);

  const share = () => {
    handleClose();

    return navigator.share
      ? navigator.share({ url: shareUrl }).catch(copy)
      : copy();
  };

  const stopShare = () => {
    handleClose();
    startLoading();
    bmapi
      .disableProductPermissions(permission.id)
      .then(onUpdate)
      .finally(stopLoading);
  };

  return (
    <>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={share}>
          {intl.formatMessage({
            id: "component.product.donate",
            defaultMessage: "Condividi",
          })}
        </MenuItem>
        <MenuItem onClick={stopShare}>
          {intl.formatMessage({
            id: "component.product.stopDonate",
            defaultMessage: "Annulla condivisione",
          })}
        </MenuItem>
      </Menu>
      <ProductCard
        key={product.id}
        title={intl.formatMessage(
          productMsg[getLabel(campaign.rules.subtype)].shared,
          { counter: 1 }
        )}
        subtitle={
          permission
            ? intl.formatMessage(campaignMsg.sharingExpiration, {
                expiration_date: new Date(permission?.expire_at),
              })
            : ""
        }
        Icon={IconsMap[product.subtype]}
        action={{
          Icon: MoreVert,
          fn: handleClick,
        }}
      />
    </>
  );
}

export default function CardProducts({ campaign, products, onUpdate }) {
  const intl = useIntl();
  const { bmapi } = useBmapi();
  const [businesses, setBusinesses] = useState([]);
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    Promise.all(
      products
        .map((p) => p.business_restrictions)
        .filter((res) => res)
        .flat()
        .map((bId) => bmapi.getBusiness(bId))
    ).then(setBusinesses);
  }, [bmapi, products]);

  useEffect(() => {
    products
      .filter((p) => p.status === 4)
      .map((p) =>
        bmapi.getProductPermissions(p.id).then((ps) =>
          setPermissions((perms) => ({
            ...perms,
            [p.id]: (ps || []).filter(
              (p) => p.type === PERMISSION_TYPES.SEND_PERMISSION
            ),
          }))
        )
      );
  }, [bmapi, products]);

  const activeProducts = getProductInfo(
    products.filter((p) => p.status === 0),
    campaign.type,
    businesses,
    intl
  );

  return (
    <Card>
      <List>
        {activeProducts.map((item) => (
          <ProductCard key={item.id} {...item} />
        ))}
        {(products || [])
          .filter((p) => p.status === 4)
          .map((prod) => (
            <SharedProduct
              key={prod.id}
              campaign={campaign}
              permission={permissions[prod.id]?.[0]}
              product={prod}
              onUpdate={onUpdate}
            />
          ))}
      </List>
    </Card>
  );
}
