import React from "react";
import { ListItemIcon, Menu, MenuItem, Typography } from "@material-ui/core";

export default function AnchorMenu({
  setItemSelected,
  items,
  anchorEl,
  setAnchorEl,
}) {
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={!!anchorEl}
      onClose={() => {
        setAnchorEl(null);
        setItemSelected(null);
      }}
    >
      {items.map((item) => (
        <MenuItem
          key={item}
          onClick={item.onClick}
          disabled={item.disabled ? true : false}
        >
          <ListItemIcon>{item.icon}</ListItemIcon>
          <Typography variant="inherit">{item.text}</Typography>
        </MenuItem>
      ))}
    </Menu>
  );
}
