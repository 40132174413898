import React, { useState } from "react";
import { Box, Button, Container, Menu, MenuItem } from "@material-ui/core";
import Title from "../../ui/Title";
import UsersStatistics from "../../components/UsersStatistics";
import { STATISTIC_TYPE } from "../../utils/gdspConstants";
import PrizesStatistics from "../../components/PrizesStatistics";
import MerchantsStatistics from "../../components/MerchantsStatistics";

export default function Statistics() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [type, setType] = useState("users");

  const selectType = (type) => {
    setType(type);
    setAnchorEl(null);
  };

  return (
    <Container maxWidthMd>
      <Title>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box>
            <Button
              onClick={(event) => setAnchorEl(event.currentTarget)}
              variant="contained"
              color="primary"
            >
              Seleziona Statistiche
            </Button>
          </Box>
          {/*<Button
            onClick={(event) => setAnchorEl(event.currentTarget)}
            variant="contained"
          >
            <FormattedMessage id="common.filter" defaultMessage="Filtro" />
  </Button>*/}
          <Menu
            anchorEl={anchorEl}
            keepMounted={false}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {STATISTIC_TYPE.map((option) => (
              <MenuItem
                key={option.value}
                onClick={() => selectType(option.value)}
                selected={option.value === type}
              >
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Title>

      {type === "users" && <UsersStatistics />}
      {type === "prizes" && <PrizesStatistics />}
      {type === "merchants" && <MerchantsStatistics />}
    </Container>
  );
}
