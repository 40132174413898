import React from "react";
import { useIntl } from "react-intl";

import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Switch,
} from "@material-ui/core";

import { PRODUCT_SUBTYPES } from "../../../utils/constants";
import { form } from "../../../messages";

export default function DynamicValue({ values, handleChange, performance }) {
  const intl = useIntl();

  const onChange = (_, v) => {
    if (values.dynamic_value && performance?.issued_qty > 0) return;

    handleChange("dynamic_value")(v);
    if (v) handleChange("value")("");
  };

  return (
    [
      PRODUCT_SUBTYPES.COUPON_DISCOUNT,
      PRODUCT_SUBTYPES.COUPON_VALUE,
      PRODUCT_SUBTYPES.SHOPPING_CARD_SIMPLE,
      PRODUCT_SUBTYPES.PROVISIONING_CARD,
    ].includes(values.subtype) && (
      <FormControl margin="normal">
        <FormControlLabel
          disabled={
            values.link_distribution ||
            (values.value && performance?.issued_qty > 0)
          }
          control={
            <Switch
              checked={values.dynamic_value}
              color="primary"
              key="dynamic_value"
              name="dynamic_value"
              onChange={onChange}
            />
          }
          label={intl.formatMessage(form.dynamicValue)}
        />
        {values.link_distribution && !performance?.issued_qty > 0 && (
          <FormHelperText>
            {intl.formatMessage(form.dynamicValueHelp)}
          </FormHelperText>
        )}
        {values.dynamic_value && performance?.issued_qty > 0 && (
          <FormHelperText>
            Questa campagna ha valore dinamico e non può essere cambiato
          </FormHelperText>
        )}
        {!values.dynamic_value && performance?.issued_qty > 0 && (
          <FormHelperText>
            Questa campagna ha valore fisso e non può essere cambiato
          </FormHelperText>
        )}
      </FormControl>
    )
  );
}
