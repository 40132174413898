import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { common } from "../messages";

export default function QRCodeDialog({
  code,
  clearQrCode,
  item,
  itemTitle,
  displayedCode,
}) {
  const intl = useIntl();

  const relatedTours = () => {
    let colors = [];
    if (item && item.tours) {
      colors = item.tours
        .filter((t) => t.color)
        .sort(function (a, b) {
          return a.id > b.id ? 1 : b.id > a.id ? -1 : 0;
        })
        .map(function (a) {
          return a.color;
        });
      if (colors.length > 5) {
        if (colors.length > 5) {
          colors = ["lightgrey"];
        }
      }
    } else {
      colors = null;
    }
    return colors;
  };

  return (
    <Dialog
      open={!!code}
      onClose={() => clearQrCode()}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle>{itemTitle}</DialogTitle>
      <DialogContent align="center">
        {relatedTours() ? (
          <Grid container spacing={3}>
            <Grid item xs={10}>
              {code && (
                <img src={code} alt="QR Code" style={{ maxWidth: "100%" }} />
              )}
            </Grid>
            <Grid item xs={2} style={{ paddingTop: "50px", margin: "-10px" }}>
              {relatedTours() &&
                relatedTours().map((item) => {
                  return (
                    <p
                      style={{ background: item, padding: "50%" }}
                      key={item}
                    ></p>
                  );
                })}
            </Grid>
          </Grid>
        ) : (
          code && <img src={code} alt="QR Code" style={{ maxWidth: "100%" }} />
        )}
        {displayedCode || (item && item.id)}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => clearQrCode()}
          variant="contained"
          color="primary"
        >
          {intl.formatMessage(common.close)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
