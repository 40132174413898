import React from "react";
import { useIntl } from "react-intl";

import { InputAdornment } from "@material-ui/core";

import NumberInput from "../../NumberInput";
import { PRODUCT_SUBTYPES } from "../../../utils/constants";
import { common, form, product } from "../../../messages";

export default function GracePeriod({
  values,
  handleChange,
  performance,
  errorGracePeriod,
  productType,
}) {
  const intl = useIntl();

  return (
    ![
      PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE,
      PRODUCT_SUBTYPES.EARNING_CARD_INSTANT_WIN,
      PRODUCT_SUBTYPES.PROVISIONING_CARD,
    ].includes(values.subtype) && (
      <NumberInput
        name="grace_period"
        label={intl.formatMessage(form.gracePeriod)}
        value={values.grace_period}
        onChange={handleChange("grace_period")}
        key="grace_period"
        fullWidth
        margin="normal"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {intl.formatMessage(common.days)}
            </InputAdornment>
          ),
        }}
        helperText={
          performance?.issued_qty > 0 && errorGracePeriod
            ? "Non puoi diminuire il periodo di giorni extra di validità."
            : intl.formatMessage(product[productType].gracePeriodHelp)
        }
        error={performance?.issued_qty > 0 && errorGracePeriod}
        //disabled={!!campaign}
      />
    )
  );
}
