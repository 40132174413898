import React from "react";

import { CircularProgress, Dialog } from "@material-ui/core";

export default function Loading({ color, loading }) {
  return (
    <Dialog
      open={loading}
      PaperProps={{
        style: { borderRadius: 1000, padding: 10, color: color || "inherit" },
      }}
    >
      <CircularProgress color="inherit" />
    </Dialog>
  );
}
