import { ecommerce } from "../messages/ecommerce";
import { common } from "../messages";
import { wikiMuseo } from "../messages/wikiMuseo";

export const RESERVATIONS_STATUS = new Map([
  ["x", common.anyStatus],
  [0, ecommerce.toConfirm],
  [2, ecommerce.confirmed],
]);

export const RESERVATIONS_VISITOR_TYPES = {
  school: wikiMuseo.school,
  association: wikiMuseo.association,
  touristic_operator: wikiMuseo.touristicOperator,
  private: wikiMuseo.privateGroup,
};

export const RESERVATIONS_VISIT_LANGUAGES = {
  it: common.italian,
  en: common.english,
};
