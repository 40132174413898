import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import { BmapiProvider } from "./utils/bmapi-context";
import {
  CONSUMER,
  CONSUMER_NAMESPACE,
  DEFAULT_TENANT,
  DEFAULT_TENANT_MAP,
  DEV_SUBSCRIPTION,
  ENVS,
  LOAD_DEFAULT_TENANT,
  MANAGER,
  MANAGER_NAMESPACE,
  MULTIPLE_TENANTS,
  OLD_MANAGER_NAMESPACE,
  SUBSCRIPTIONS,
  DOMAIN_CONFIG,
} from "./utils/constants";
import { LocalizationProvider } from "./utils/i18n";
import * as serviceWorker from "./utils/serviceWorker";

import App from "./components/App";

import "./index.css";

function isManager(app) {
  return (
    app === MANAGER_NAMESPACE.replaceAll("/", "") ||
    app === OLD_MANAGER_NAMESPACE.replaceAll("/", "")
  );
}

function bootstrap(basename, tenant, subscription, app = CONSUMER) {
  console.log(
    "LOADING, index.js: bootstrap basename=",
    basename,
    "tenant=",
    tenant,
    "subscription=",
    subscription,
    "app=",
    app
  );
  serviceWorker.unregister();

  if (!window.location.pathname.startsWith(basename)) {
    console.log(
      "LOADING, index.js: bootstrap changing location with",
      `${window.location.origin}${basename}`
    );
    console.info("redirect ##0");
    window.location.replace(`${window.location.origin}${basename}`);
    return;
  }
  const newApp = isManager(app) ? MANAGER : CONSUMER;
  console.log("LOADING, index.js: bootstrap app=", newApp);
  ReactDOM.render(
    <Router basename={basename}>
      <BmapiProvider
        tenant={tenant}
        subscription={subscription}
        app={newApp}
        baseUrl={`${window.location.origin}${basename}`}
      >
        <LocalizationProvider>
          <App />
        </LocalizationProvider>
      </BmapiProvider>
    </Router>,
    document.getElementById("root")
  );
}

if (window.location.href.includes("//bmarken.web.app/mysarca/")) {
  console.info("redirect ##1 to mysarca");
  window.location.href = window.location.href.replace(
    "//bmarken.web.app/mysarca/",
    "//mysarca.bmarken.com/"
  );
}

if (process.env["REACT_APP_NOLOG"] === "true") console.log = () => {};

const path = window.location.pathname.split("/").map((p) => p.toLowerCase());

if (DOMAIN_CONFIG?.TENANT) {
  console.log(
    "LOADING, index.js: DOMAIN_CONFIG.TENANT found:",
    DOMAIN_CONFIG?.TENANT
  );
  if (DOMAIN_CONFIG.SUBSCRIPTION) {
    console.log(
      "LOADING, index.js: DOMAIN_CONFIG.SUBSCRIPTION found:",
      DOMAIN_CONFIG.SUBSCRIPTION
    );
    bootstrap("/", DOMAIN_CONFIG.TENANT, DOMAIN_CONFIG.SUBSCRIPTION, path[1]);
  } else {
    console.log("LOADING, index.js: DOMAIN_CONFIG.SUBSCRIPTION not found");
    if (
      !path[1] &&
      DOMAIN_CONFIG.EXTERNAL_ROOT &&
      DOMAIN_CONFIG.ALTERNATIVESITE
    ) {
      console.info(
        "redirect ##0.A alternative site",
        DOMAIN_CONFIG.ALTERNATIVESITE
      );
      window.location = DOMAIN_CONFIG.ALTERNATIVESITE;
    } else {
      if (SUBSCRIPTIONS) {
        console.log("LOADING, index.js: subscriptions in path");
        if (path[1]) {
          console.log(
            "LOADING, index.js: subscription = basename found:",
            path[1]
          );
          bootstrap(`/${path[1]}/`, DOMAIN_CONFIG.TENANT, path[1], path[2]);
        } else {
          console.log("LOADING, index.js: subscription not found");
          /*
          bootstrap(
            `/${DEV_SUBSCRIPTION}/`,
            DOMAIN_CONFIG.TENANT,
            DEV_SUBSCRIPTION,
            path[2]
          );*/
          bootstrap("/", DOMAIN_CONFIG.TENANT, undefined, path[2]);
        }
      } else {
        console.log("LOADING, index.js: no subscriptions in path");
        bootstrap("/", DOMAIN_CONFIG.TENANT, undefined, path[1]);
      }
    }
  }
} else if (MULTIPLE_TENANTS) {
  console.log("LOADING, index.js: DOMAIN_CONFIG.TENANT not found");
  if (!path[1]) {
    console.log("LOADING, index.js: PATH[1] not present");
    if (LOAD_DEFAULT_TENANT) {
      console.info(
        "redirect ##0.A.1 default tenant, consumer app",
        window.location.href +
          (DEFAULT_TENANT_MAP[window.location.hostname] || DEFAULT_TENANT) +
          CONSUMER_NAMESPACE
      );
      window.location =
        window.location.href +
        (DEFAULT_TENANT_MAP[window.location.hostname] || DEFAULT_TENANT) +
        CONSUMER_NAMESPACE;
    } else {
      console.error("Tenant multipli attivati. Tenant non impostato");

      if (process.env.NODE_ENV === ENVS.PRODUCTION) {
        console.info(
          "redirect ##0.A.2 multitenat without tenant set",
          "https://liberacta.com/"
        );
        window.location.replace("https://liberacta.com/");
      }
    }
  } else {
    console.log("LOADING, index.js: PATH[1] present", path[1]);
    if (SUBSCRIPTIONS) {
      console.log("LOADING, index.js: config SUBSCRIPTIONS=true");
      if (!path[2]) {
        console.log("LOADING, index.js: PATH[2] not present");
        bootstrap(
          `/${path[1]}/${DEV_SUBSCRIPTION}/`,
          path[1],
          DEV_SUBSCRIPTION,
          path[3]
        );
      } else {
        console.log("LOADING, index.js: PATH[2] present", path[2]);
        bootstrap(`/${path[1]}/${path[2]}/`, path[1], path[2], path[3]);
      }
    } else {
      console.log("LOADING, index.js: config SUBSCRIPTIONS not true");
      bootstrap(`/${path[1]}/`, path[1], null, path[2]);
    }
  }
} else {
  //if (SUBSCRIPTION) {
  //  bootstrap("/", DEFAULT_TENANT, SUBSCRIPTION, path[0]);
  //}
  if (SUBSCRIPTIONS) {
    if (!path[1]) {
      bootstrap(
        `/${DEV_SUBSCRIPTION}/`,
        DEFAULT_TENANT,
        DEV_SUBSCRIPTION,
        path[2]
      );
    } else {
      bootstrap(`/${path[1]}/`, DEFAULT_TENANT, path[1], path[2]);
    }
  } else {
    bootstrap("/", DEFAULT_TENANT, undefined, path[1]);
  }
}
/*
if ("serviceWorker" in navigator) {
  console.log("SERVICE WORKER 999");
  navigator.serviceWorker
    .register(process.env.PUBLIC_URL + "/sw.js")
    .then(() => {
      console.log("Service Worker Registered");
    })
    .catch((e) => console.log("REGISTRETION SW FAILED: ", e));
}*/
