import React from "react";
import { useIntl } from "react-intl";

import { common } from "../../../messages";
import { useBmapi } from "../../../utils/bmapi-context";
import { DatePicker } from "../../DatePicker";

export function Birthday({ value, onChange }) {
  const intl = useIntl();
  const { bmapi } = useBmapi();

  return (
    <DatePicker
      autoOk
      clearable
      disableFuture
      fullWidth
      initialFocusedDate="1970/01/01"
      label={intl.formatMessage(common.birthday)}
      onChange={onChange}
      openTo="year"
      required={bmapi.settings.profileRequired.includes("birthday")}
      value={value}
    />
  );
}
