import { RESERVATIONS_STATUS } from "./ecommerceGenConstants";

export const getStatusLabel = (status, intl) => {
  let statusLabel = "";
  if (status !== null && status !== undefined) {
    const label = RESERVATIONS_STATUS.get(status);
    if (label) {
      statusLabel = intl.formatMessage(label);
    }
  }
  return statusLabel;
};
