import React, { useCallback, useEffect, useState } from "react";
import QRCodeGenerator from "qrcode";

import {
  Box,
  Card,
  CardContent,
  Container,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";

import QrScanner, { isCompatibleChar } from "../ui/QrScanner";
import { UUID_LENGTH } from "../utils/constants";

export default function Test() {
  const [qrCode, setQrCode] = useState("");
  const [code, setCode] = useState("");
  const [cleanCode, setCleanCode] = useState("");
  const [filteredCode, setFilteredCode] = useState("");
  const [filtered2Code, setFiltered2Code] = useState("");
  const [qrImage, setQrImage] = useState("");
  const [log, setLog] = useState([]);
  const [section, setSection] = useState("qr");

  const handleInput = useCallback((e) => {
    setLog((l) => [...l, e]);
    setCleanCode((c) => ((c || "") + e.key).slice(-(UUID_LENGTH + 30)));
    if (isCompatibleChar(e.key)) {
      setCode((c) => ((c || "") + e.key).slice(-(UUID_LENGTH + 30)));
    }
    if (e.key.length === 1) {
      setFilteredCode((c) => ((c || "") + e.key).slice(-(UUID_LENGTH + 30)));
    }
    if (isCompatibleChar(e.key) || e.key === "-") {
      setFiltered2Code((c) => ((c || "") + e.key).slice(-(UUID_LENGTH + 30)));
    }
  }, []);

  const clean = () => {
    setQrCode("");
    setCode("");
    setCleanCode("");
    setFilteredCode("");
    setFiltered2Code("");
    setLog([]);
  };

  const openTab = (event, tab) => {
    setSection(tab);
    clean();
    event.currentTarget.blur();
  };

  useEffect(() => {
    document.addEventListener("keydown", handleInput);

    return () => {
      document.removeEventListener("keydown", handleInput);
    };
  }, [handleInput]);

  useEffect(() => {
    QRCodeGenerator.toDataURL("UID@20bac685-d79b-4e21-b6fd-12bb461de2d5", {
      scale: 16,
    }).then(setQrImage);
  }, []);

  return (
    <Box my={5}>
      <Box my="2">
        <Container maxWidth="xs">
          <QrScanner
            handleCode={setQrCode}
            handleError={console.log}
            back={false}
          />
        </Container>
      </Box>
      <Box my="2">
        <Container maxWidth="xs">
          <Tabs
            value={section}
            indicatorColor="primary"
            textColor="primary"
            onChange={openTab}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab value="qr" label="Qr Code" />
            <Tab value="bar" label="Barcode" />
          </Tabs>
        </Container>
      </Box>
      {section === "qr" && (
        <Box my="2">
          <Container maxWidth="xs">
            <Card>
              {qrImage && (
                <img
                  src={qrImage || ""}
                  alt="QR Code"
                  style={{ maxWidth: "100%" }}
                />
              )}
            </Card>
          </Container>
        </Box>
      )}
      {section === "bar" && (
        <Box my="2" align="center">
          <Container maxWidth="xs">
            <Card>
              <CardContent>
                <img
                  src="https://storage.googleapis.com/bmarken-assets/assets/test/barcode.gif"
                  alt="Barcode BAR@12abc345"
                  style={{ maxWidth: "100%" }}
                />
              </CardContent>
            </Card>
          </Container>
        </Box>
      )}
      <Container maxWidth="md">
        <Typography>Scanner output: {qrCode}</Typography>
        <Typography>
          Scanner output:{" "}
          {qrCode ===
          (section === "qr"
            ? "20bac685-d79b-4e21-b6fd-12bb461de2d5"
            : "BAR@12abc345")
            ? "Ok"
            : "Ko"}
        </Typography>
        <Typography>Input code: {code}</Typography>
        <Typography>Input cleanCode: {cleanCode}</Typography>
        <Typography>Input filteredCode: {filteredCode}</Typography>
        <Typography>Input filtered2Code: {filtered2Code}</Typography>
        <Typography>Input code short: {code.slice(-UUID_LENGTH)}</Typography>
        {/* <Typography>
          Input log:
          <br />
          {log.map((e) => (
            <>
              {e.code} | {e.key} | {e.keyCode} | {e.which} |{" "}
              {e.key.charCodeAt(0)}
              <br />
            </>
          ))}
        </Typography> */}
        <Typography>
          Input log code: {log.map((e) => e.code).join(" | ")}
        </Typography>
        <Typography>
          Input log key: {log.map((e) => e.key).join(" | ")}
        </Typography>
        <Typography>
          Input log keyCode: {log.map((e) => e.keyCode).join(" | ")}
        </Typography>
        <Typography>
          Input log which: {log.map((e) => e.which).join(" | ")}
        </Typography>
        <Typography>
          Input log charCodeAt:{" "}
          {log.map((e) => e.key.charCodeAt(0)).join(" | ")}
        </Typography>
      </Container>
    </Box>
  );
}
