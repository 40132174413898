import React, { forwardRef, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import {
  Drawer,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@material-ui/core";

import { FEATURES, MANAGER_ROUTES, PRODUCTS } from "../utils/constants";
import { IconsMap, subtypeMap, useCampaigns } from "../utils/campaigns";
import { useBmapi } from "../utils/bmapi-context";
import { campaign } from "../messages";
import PlanLimitAlert from "./PlanLimitAlert";

function CampaignMenuItemBase({ name, Icon, onClick }, ref) {
  return (
    <MenuItem onClick={onClick} innerRef={ref}>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={name} />
    </MenuItem>
  );
}

const CampaignMenuItem = forwardRef(CampaignMenuItemBase);

export default function CreateCampaignButton({ trigger }) {
  const intl = useIntl();
  const { bmapi } = useBmapi();
  const { campaigns, loadCampaigns } = useCampaigns();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  const handleClick = (event) => {
    if (
      campaigns &&
      bmapi.subscription &&
      bmapi.subscription.plan_limits.max_campaigns > 0 &&
      campaigns.length >= bmapi.subscription.plan_limits.max_campaigns
    ) {
      setAlert(true);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const create = (subtype) => {
    handleClose();
    history.push(
      MANAGER_ROUTES.CREATE_CAMPAIGN.replace(":subtype?", subtype.toLowerCase())
    );
  };

  const buttons = [
    {
      name: "AI",
      type: "AI",
      feature: FEATURES.CAMPAIGN_COUPON,
    },
    {
      name: intl.formatMessage(campaign.couponShort),
      type: PRODUCTS.COUPON_SIMPLE,
      feature: FEATURES.CAMPAIGN_COUPON,
    },
    {
      name: intl.formatMessage(campaign.fidelity),
      type: PRODUCTS.EARNING_CARD,
      feature: FEATURES.CAMPAIGN_EARNING_CARD,
    },
    {
      name: intl.formatMessage(campaign.giftcard),
      type: PRODUCTS.SHOPPING_CARD,
      feature: FEATURES.CAMPAIGN_SHOPPING_CARD,
    },
    {
      name: intl.formatMessage(campaign.provisioning),
      type: PRODUCTS.PROVISIONING_CARD,
      feature: FEATURES.CAMPAIGN_PROVISIONING_CARD,
    },
    {
      name: intl.formatMessage(campaign.eventPass),
      type: PRODUCTS.EVENT_PASS,
      feature: FEATURES.CAMPAIGN_EVENT_PASS,
    },
    {
      name: intl.formatMessage(campaign.coursePass),
      type: PRODUCTS.COURSE_PASS,
      feature: FEATURES.CAMPAIGN_COURSE_PASS,
    },
    {
      disabled: !bmapi.isTenantManager(),
      name: intl.formatMessage(campaign.instantwinShort),
      type: PRODUCTS.INSTANT_WIN,
      feature: FEATURES.CAMPAIGN_INSTANTWIN,
    },
    {
      name: "Multiwin",
      type: PRODUCTS.MULTI_WIN,
      feature: FEATURES.CAMPAIGN_MULTIWIN,
    },
  ]
    .filter((b) => !b.disabled)
    .filter((b) => bmapi.can(b.feature))
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <React.Fragment>
      <PlanLimitAlert open={alert} onClose={() => setAlert(false)} />
      {trigger(handleClick)}
      <Drawer anchor="bottom" open={Boolean(anchorEl)} onClose={handleClose}>
        <List>
          {buttons.map((button) => (
            <CampaignMenuItem
              name={button.name}
              key={button.name}
              Icon={IconsMap[subtypeMap[button.type]]}
              onClick={() => create(button.type)}
            />
          ))}
        </List>
      </Drawer>
    </React.Fragment>
  );
}
