import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Switch,
  TextField,
} from "@material-ui/core";
import { useIntl } from "react-intl";
import { common, form } from "../../messages";
import { MANAGER_ROUTES } from "../../utils/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Title from "../../ui/Title";
import { EVENT_TYPES } from "../../utils/eventActionsConstants";
import { useBmapi } from "../../utils/bmapi-context";
import { format } from "date-fns";
import { getErrorMessageString } from "../../utils/errors";
import { parseBmarkenDate } from "../../utils/utils";
import { DatePicker, TimePicker } from "../../ui/DatePicker";
import NumberInput from "../../ui/NumberInput";
import CheckInExtraction from "../../components/CheckInExtraction";
import { eventActions } from "../../messages/eventActions";
import { campaign } from "../../messages/campaign";

const getType = (campaignId) => {
  const type = new URLSearchParams(location.search)
    .get("subtype")
    .toUpperCase();
  return campaignId ? (type === "MULTIPLY_PLAY_EVENT_ACTION" ? "" : "-") : type;
};

export default function EventActionCreate() {
  const intl = useIntl();
  const history = useHistory();
  const {
    bmapi,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  const bsId = bmapi.getUserInfo().business.id;
  const id = new URLSearchParams(location.search).get("id");
  const clone = location.href.includes("clone");
  const filters = location.href.includes("filters");

  const [eventType, setEventType] = useState(null);
  const [campaignList, setCampaignList] = useState([]);

  const [values, setValues] = useState({
    //type: "",
    title: "",
    start_at: null,
    end_at: null,
    message: "",
    alt_message: "",
    fe: false,
    label: "",
    status: false,
    url: "",
    value: "",
    test: false,
    campaign_id: id ? "" : getType("campaignId"),
    dir_marketing_required: false,
    business_id: bsId,
  });

  const multiplay = eventType === "MULTIPLY_PLAY_EVENT_ACTION";

  const getLabel = (type) => {
    const filtered = EVENT_TYPES.filter((obj) => obj.value === type);
    return filtered && filtered.length
      ? intl.formatMessage(eventActions[filtered[0].label])
      : "";
  };

  const showCampaign = () => {
    const eventsList = EVENT_TYPES.filter((e) => e.show_campaign);
    //return eventsList.map((e) => e.value).includes(values.type || eventType);
    return eventsList.map((e) => e.value).includes(eventType);
  };

  const getTitle = () => {
    const label = getLabel(eventType);
    return id && clone
      ? `${intl.formatMessage(eventActions.eventClone)}: ${label}`
      : id
      ? `${intl.formatMessage(eventActions.eventEdit)}: ${label}`
      : `${intl.formatMessage(eventActions.eventCreation)}: ${label}`;
  };

  const loadEventAction = useCallback(() => {
    startLoading();
    bmapi
      .getEventActionById({ business: bsId, event: id })
      .then((resp) => {
        if (!resp) {
          resp = [];
          setValues(resp);
        } else {
          setValues({
            ...resp,
            campaign_id: resp.campaign_id || "-",
            start_at: parseBmarkenDate(resp.start_at),
            end_at: parseBmarkenDate(resp.end_at),
            start_hour: parseBmarkenDate(resp.start_at),
            end_hour: parseBmarkenDate(resp.end_at),
            value: resp.value || "",
            status: resp.status ? false : true,
          });
          setEventType(resp.type);
        }
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  }, [bmapi, bsId, id, intl, notifyError, startLoading, stopLoading]);

  useEffect(() => {
    const readCampaigns = async () => {
      let list = await bmapi.getAllTenantCampaigns();
      list = list.filter(
        (c) =>
          c.type === "CAMPAIGN_MULTIWIN" ||
          c.type === "EARNING_CARD_INSTANT_WIN"
      );
      list.unshift({
        name: intl.formatMessage(campaign.noCampaign),
        id: "-",
        business_name: "",
        isActive: true,
      });
      setCampaignList(list);
    };
    readCampaigns();
  }, [bmapi, intl]);

  useEffect(() => {
    if (!id) {
      const type = getType();
      setEventType(type);
    } else {
      loadEventAction();
    }
  }, [id, loadEventAction]);

  const handleValue = useCallback((label) => {
    const updateValue = (val) => {
      setValues((v) => ({ ...v, [label]: val }));
    };

    return (i, f) => {
      if (typeof f === "boolean") updateValue(f);
      else if (i?.target) updateValue(i.target.value);
      else updateValue(i);
    };
  }, []);

  const extractFilterValues = (str) => {
    const filterIndex = str.indexOf("filters=");
    if (filterIndex === -1) {
      return null;
    }
    const filterValues = str.substring(filterIndex);
    return filterValues;
  };

  const handleCancel = () => {
    if (filters) {
      const filterResult = extractFilterValues(location.pathname);
      history.push(MANAGER_ROUTES.EVENT_ACTION + filterResult);
    } else history.push(MANAGER_ROUTES.EVENT_ACTION);
  };

  const confirmEvent = async (event) => {
    event.preventDefault();

    const {
      start_at,
      start_hour,
      end_at,
      end_hour,
      campaign_id,
      status,
      value,
      //type,
    } = values;

    const startDate = `${format(start_at, "yyyy-MM-dd")}T${format(
      start_hour,
      "HH:mm:ss"
    )}`;
    const endDate = `${format(end_at, "yyyy-MM-dd")}T${format(
      end_hour,
      "HH:mm:ss"
    )}`;

    const eAction = {
      ...values,
      //type: eventType || type,
      type: eventType,
      campaign_id: campaign_id === "-" ? "" : campaign_id,
      status: status ? 0 : 1,
      value: +value || 0,
      start_at: new Date(startDate),
      end_at: new Date(endDate),
      url: values.fe ? values.url : "",
    };

    const excludedKeys = {
      created_at: true,
      deleted_at: true,
      updated_at: true,
      tenant_id: true,
      id: true,
    };

    const eActionClone = Object.keys(eAction)
      .filter((key) => !excludedKeys[key])
      .reduce((obj, key) => {
        obj[key] = eAction[key];
        return obj;
      }, {});

    try {
      startLoading();

      if (!id) {
        await bmapi.createEventAction(eAction);
        notifySuccess(intl.formatMessage(eventActions.successCreate));
      } else if (id && clone) {
        await bmapi.createEventAction(eActionClone);
        notifySuccess(intl.formatMessage(eventActions.successClone));
      } else {
        await bmapi.updateEventAction(eAction);
        notifySuccess(intl.formatMessage(eventActions.successEdit));
      }

      if (filters) {
        const filterResult = extractFilterValues(location.pathname);
        history.push(MANAGER_ROUTES.EVENT_ACTION + filterResult);
      } else history.push(MANAGER_ROUTES.EVENT_ACTION);
    } catch (e) {
      notifyError(getErrorMessageString(e, intl));
    } finally {
      stopLoading();
    }
  };

  return (
    <Container maxWidth="sm">
      <Title>{getTitle()}</Title>

      <form onSubmit={confirmEvent}>
        <Box mb={2}>
          <Card>
            <CardContent>
              <FormControl fullWidth>
                {showCampaign() && (
                  <TextField
                    name="front_end_type"
                    label={intl.formatMessage(common.campaign)}
                    value={values.campaign_id}
                    onChange={handleValue("campaign_id")}
                    select
                    fullWidth
                    required
                    margin="normal"
                  >
                    {multiplay
                      ? campaignList
                          .filter((c) => c.id !== "-")
                          .map((option) => (
                            <MenuItem key={option.name} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))
                      : campaignList.map((option) => (
                          <MenuItem key={option.name} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                  </TextField>
                )}
                <TextField
                  name="title"
                  label={intl.formatMessage(form.title)}
                  value={values.title}
                  onChange={handleValue("title")}
                  key="title"
                  fullWidth
                  margin="normal"
                  required
                />
                <DatePicker
                  label={intl.formatMessage(form.startDatePass)}
                  value={values.start_at}
                  onChange={handleValue("start_at")}
                  fullWidth
                  margin="normal"
                  required
                  //minDate={id ? values.start_at : null}
                  //clearable={!id}
                  clearable
                />
                <TimePicker
                  label={intl.formatMessage(form.startHour)}
                  value={values.start_hour || null}
                  onChange={handleValue("start_hour")}
                  fullWidth
                  margin="normal"
                  required
                  clearable
                />
                <DatePicker
                  label={intl.formatMessage(form.expirationDatePass)}
                  value={values.end_at}
                  onChange={handleValue("end_at")}
                  fullWidth
                  margin="normal"
                  required
                  minDate={values.start_at}
                  clearable
                />
                <TimePicker
                  label={intl.formatMessage(form.endHour)}
                  value={values.end_hour || null}
                  onChange={handleValue("end_hour")}
                  fullWidth
                  margin="normal"
                  required
                  clearable
                />
                <TextField
                  label={intl.formatMessage(form.insertMessage)}
                  multiline
                  minRows={4}
                  variant="outlined"
                  value={values.message}
                  onChange={handleValue("message")}
                  name="message"
                  margin="normal"
                />
                <TextField
                  label={intl.formatMessage(form.insertAltMessage)}
                  multiline
                  minRows={4}
                  variant="outlined"
                  value={values.alt_message}
                  onChange={handleValue("alt_message")}
                  name="alt_message"
                  margin="normal"
                />
                <TextField
                  name="label"
                  label={intl.formatMessage(eventActions.actionLabel)}
                  value={values.label}
                  onChange={handleValue("label")}
                  key="label"
                  fullWidth
                  margin="normal"
                />
                <NumberInput
                  required={multiplay}
                  helperText={
                    multiplay && intl.formatMessage(eventActions.minValue)
                  }
                  name="value"
                  label={intl.formatMessage(form.value)}
                  value={values.value}
                  onChange={handleValue("value")}
                  fullWidth
                  margin="normal"
                  onInput={(e) => {
                    e.target.value = multiplay
                      ? e.target.value.replace(/^[0]|[^0-9]/g, "")
                      : e.target.value;
                  }}
                />
                {values.fe && (
                  <TextField
                    name="url"
                    label={intl.formatMessage(common.url)}
                    value={values.url}
                    onChange={handleValue("url")}
                    key="url"
                    fullWidth
                    margin="normal"
                  />
                )}
              </FormControl>

              <FormControl component="fieldset" margin="normal">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.fe}
                        onChange={handleValue("fe")}
                        name="fe"
                      />
                    }
                    label={intl.formatMessage(eventActions.fe)}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.status}
                        onChange={handleValue("status")}
                        name="status"
                      />
                    }
                    label={intl.formatMessage(eventActions.enabled)}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.test}
                        onChange={handleValue("test")}
                        name="test"
                      />
                    }
                    label={intl.formatMessage(eventActions.test)}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.dir_marketing_required}
                        onChange={handleValue("dir_marketing_required")}
                        name="dir_marketing_required"
                      />
                    }
                    label={intl.formatMessage(eventActions.mktRequired)}
                  />
                </FormGroup>
              </FormControl>
              {id && /*values.type*/ eventType === "CHECKIN_EVENT_ACTION" && (
                <CheckInExtraction id={id} />
              )}
            </CardContent>
            <CardActions style={{ float: "right" }}>
              <Button
                onClick={() => {
                  handleCancel();
                }}
                variant="outlined"
              >
                {intl.formatMessage(common.cancel)}
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {intl.formatMessage(
                  id && clone
                    ? common.create
                    : id
                    ? common.update
                    : common.create
                )}
              </Button>
            </CardActions>
          </Card>
        </Box>
      </form>
    </Container>
  );
}
