import React from "react";

import { Box, Grid, Typography, useTheme } from "@material-ui/core";

import styles from "../utils/styles";

const MAX_BLOCKS = 4;

export function Block({ background, children }) {
  const classes = styles.useStyles();

  return (
    <Box className={classes.tetrisBlock} style={{ background }}>
      {children}
    </Box>
  );
}

export default function TetrisChart({ data }) {
  const theme = useTheme();
  const colors = [
    theme.palette.error.main,
    theme.palette.info.main,
    theme.palette.warning.main,
    theme.palette.success.main,
  ];

  return data.map((col, pos) => (
    <Grid
      container
      direction="row"
      alignItems="center"
      spacing={1}
      key={col.label}
    >
      <Grid item xs={4} zeroMinWidth>
        <Typography noWrap align="right" variant="body2">
          {col.label}
        </Typography>
      </Grid>
      <Grid item>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Block>
            <Typography variant="h6">{col.value}</Typography>
          </Block>
          {new Array(col.value)
            .slice(0, MAX_BLOCKS)
            .fill(0)
            .map((_, i) => (
              <Block key={i} background={colors[pos % colors.length]} />
            ))}
          <Block>
            <Typography variant="h6">
              {col.value > MAX_BLOCKS ? `+${col.value - MAX_BLOCKS}` : ""}
            </Typography>
          </Block>
        </Box>
      </Grid>
    </Grid>
  ));
}
