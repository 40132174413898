import React from "react";
import { useIntl } from "react-intl";

import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import { account } from "../../../messages";

export function Password({
  confirm = false,
  dirty,
  onChange,
  show,
  toggleShow,
  valid,
  value,
}) {
  const intl = useIntl();

  return (
    <TextField
      autoComplete={confirm ? "new-password" : "current-password"}
      error={dirty && !valid}
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={toggleShow}
            >
              {show ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      helperText={
        dirty && !valid && intl.formatMessage(account.invalidPassword)
      }
      label={intl.formatMessage(
        confirm ? account.newPassword : account.currentPassword
      )}
      margin="normal"
      name={confirm ? "newPassword" : "password"}
      onChange={onChange}
      type={show ? "text" : "password"}
      value={value}
    />
  );
}
