import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@material-ui/core";
import { useIntl } from "react-intl";
//import { form } from "../messages/form.js";
import { MapContainer, TileLayer, Marker, Tooltip } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { wikiMuseo } from "../messages/wikiMuseo";

/**
 * esempio
 * 
 * 
      <MapEditor
        allPois={[
          {
            id: "101",
            title: "m1",
            address: { lat: 48.77230807030938, lng: 2.305225195957276 },
          },
          {
            id: "102",
            title: "m2",
            address: { lat: 48.77185553247162, lng: 2.31217748182488 },
          },
          {
            id: "103",
            title: "m3",
            address: { lat: 48.8179584998612, lng: 2.2246727496518575 },
          },
        ]}
        selectedPois={["102"]}
        onPoiSelected={(poisSelected) => console.log(poisSelected)}
      />

 */

const EMPTY_CENTER = { lat: "", lng: "" };
const EMPTY_SELECTED_POIS = [];

export default function MapEditor({
  allPois,
  selectedPois = EMPTY_SELECTED_POIS,
  onPoiSelected,
}) {
  const intl = useIntl();
  const [center, setCenter] = useState(EMPTY_CENTER);
  const [bounds, setBounds] = useState();
  const [map, setMap] = useState(false);
  const [values, setValues] = useState(false);
  const [markers, setMarkers] = useState([]);

  useEffect(() => {
    const L = require("leaflet");

    delete L.Icon.Default.prototype._getIconUrl;

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
      iconUrl: require("leaflet/dist/images/marker-icon.png"),
      shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    });
  }, []);

  useEffect(() => {
    const markers = [];
    let minLat, maxLat, minLng, maxLng;
    if (!allPois) {
      return;
    }
    const newValues = {};
    for (const poi of allPois) {
      const selected = selectedPois.indexOf(poi.id) !== -1;
      newValues[poi.id] = selected;
      const lat = poi.address?.lat;
      const lng = poi.address?.lng;
      if (lat || lng) {
        markers.push(poi);
        if (!minLat || minLat > lat) {
          minLat = lat;
        }
        if (!maxLat || maxLat < lat) {
          maxLat = lat;
        }
        if (!minLng || minLng > lng) {
          minLng = lng;
        }
        if (!maxLng || maxLng < lng) {
          maxLng = lng;
        }
      }
    }
    if (!minLat || !maxLat || !minLng || !maxLng) {
      return;
    }
    const L = require("leaflet");
    const ibounds = L.latLngBounds(
      L.latLng(minLat, minLng),
      L.latLng(maxLat, maxLng)
    );
    setBounds(ibounds);

    const icenter = {
      lat: (minLat + maxLat) / 2,
      lng: (minLng + maxLng) / 2,
    };
    setCenter(icenter);

    setMarkers(markers);

    setValues(newValues);
  }, [allPois, selectedPois]);

  useEffect(() => {
    if (map && center.lat !== "" && center.lng !== "")
      map.panTo([center.lat, center.lng]);
  }, [map, center]);
  /*
  const handleValue = useCallback(
    (key) => (e) => {
      ((val) => setValues((v) => ({ ...v, [key]: val })))(e.target.checked);
    },
    []
  );
  */
  const handleValue = useCallback(
    (key) => (e) => {
      ((val) =>
        setValues((v) => {
          const newValues = { ...v, [key]: val };
          if (onPoiSelected) {
            const arrSelected = [];
            for (const [key, value] of Object.entries(newValues)) {
              if (value) [arrSelected.push(key)];
            }
            onPoiSelected(arrSelected);
          }

          return newValues;
        }))(e.target.checked);
    },
    [onPoiSelected]
  );

  /*
  useEffect(() => {
    console.log("x4");
    if (onPoiSelected && values) {
      const arrSelected = [];
      for (const [key, value] of Object.entries(values)) {
        if (value) [arrSelected.push(key)];
      }
      onPoiSelected(arrSelected);
    }
  }, [onPoiSelected, values]);
  */

  return (
    <Box>
      {center.lat !== "" && center.lng !== "" && (
        <MapContainer
          center={[center.lat, center.lng]}
          bounds={bounds}
          scrollWheelZoom={false}
          style={{ height: 400, width: "100%" }}
          whenCreated={setMap}
        >
          <TileLayer
            //attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {markers.map((poi) => (
            <Marker
              key={poi.id}
              position={[poi.address.lat, poi.address.lng]}
              opacity={values[poi.id] ? 1 : 0.4}
            >
              <Tooltip direction="top" offset={[10, 0]} permanent>
                {poi.title}
              </Tooltip>
            </Marker>
          ))}
        </MapContainer>
      )}
      {(center.lat === "" || center.lng === "") && (
        <Typography>{intl.formatMessage(wikiMuseo.noPOIMap)}</Typography>
      )}
      <Box>
        {values && (
          <FormGroup>
            {allPois.map((poi) => (
              <FormControlLabel
                key={poi.id}
                control={
                  <Checkbox
                    checked={values[poi.id]}
                    onChange={handleValue(poi.id)}
                  />
                }
                label={poi.title}
              />
            ))}
          </FormGroup>
        )}
      </Box>
    </Box>
  );
}
