import { POI_PREFIX, TOUR_PREFIX } from "./constants";

export async function getPoiQrCode(bmapi, poi) {
  if (!poi?.id) {
    return "";
  }
  if (bmapi.settings.qrCodePrefix) {
    const subscription = await bmapi.getSubscription(poi.subscription_id);
    const friendlyUrl = subscription?.friendly_url || "";
    return `${bmapi.settings.qrCodePrefix}/p/${poi.id}?s=${friendlyUrl}`;
  } else {
    return `${POI_PREFIX}${poi.id}`;
  }
}

export async function getTourQrCode(bmapi, tour) {
  if (!tour?.id) {
    return "";
  }
  if (bmapi.settings.qrCodePrefix) {
    const subscription = await bmapi.getSubscription(tour.subscription_id);
    const friendlyUrl = subscription?.friendly_url || "";
    return `${bmapi.settings.qrCodePrefix}/t/${tour.id}?s=${friendlyUrl}`;
  } else {
    return `${TOUR_PREFIX}${tour.id}`;
  }
}
