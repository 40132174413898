import React, { useState, useEffect, useCallback } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, Link as RouterLink } from "react-router-dom";

import { Box, Button, Link } from "@material-ui/core";

import LoginCard from "../ui/LoginCard";
import { useBmapi } from "../utils/bmapi-context";
import {
  ACTIONS,
  CONSUMER_ROUTES,
  FEATURES,
  MANAGER_ROUTES,
} from "../utils/constants";
import {
  getAuthEmailNotPresentInfo,
  getErrorMessageString,
} from "../utils/errors";
import styles from "../utils/styles";
import { getSignInSubscriptionData, propagateUrlTracing } from "../utils/utils";
import { ArrowBack } from "@material-ui/icons";
import { subscriptions } from "../messages";

function MoreInfo() {
  const { bmapi } = useBmapi();

  if (bmapi.subscriptionSlug === "lamilanochepremia") {
    return (
      <Link href="https://lamilanochepremia.it/" target="_blank">
        <FormattedMessage
          id="pages.entry.moreInfo"
          defaultMessage="Maggiori informazioni"
        />
      </Link>
    );
  }

  if (bmapi.settings.moreInfo) {
    return bmapi.settings.moreInfo.startsWith("http") ? (
      <Link href={bmapi.settings.moreInfo} target="_blank">
        <FormattedMessage
          id="pages.entry.moreInfo"
          defaultMessage="Maggiori informazioni"
        />
      </Link>
    ) : (
      <Link component={RouterLink} to={bmapi.settings.moreInfo}>
        <FormattedMessage
          id="pages.entry.moreInfo"
          defaultMessage="Maggiori informazioni"
        />
      </Link>
    );
  }
  return null;
}

export default function Entry() {
  const {
    baseUrl,
    bmapi,
    loading,
    notifyError,
    notifyDialog,
    startLoading,
    stopLoading,
  } = useBmapi();
  const classes = styles.useStyles();
  const intl = useIntl();
  const history = useHistory();
  const [uiConfig, setUiConfig] = useState({ signInOptions: [] });
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [showBackButton, setShowBackButton] = useState(false);

  const routes = bmapi.isConsumer() ? CONSUMER_ROUTES : MANAGER_ROUTES;

  const signIn = useCallback(() => {
    history.push(routes.SIGN_IN_EMAIL + propagateUrlTracing());
  }, [history, routes]);

  const afterLoginUrl = useCallback(() => {
    return routes.SIGN_IN_ACTION.replace(":action?", ACTIONS.LOGGED);
  }, [routes]);

  const customSignIn = useCallback(() => {
    console.info("redirect ##5");
    window.location.href = bmapi.settings.customLogin.replace(
      "{cbUrl}",
      `${baseUrl}${afterLoginUrl().slice(1)}`
    );
  }, [afterLoginUrl, baseUrl, bmapi.settings.customLogin]);

  const backButtonComponent = (
    <Button
      onClick={() => history.push(routes.HOME)}
      startIcon={<ArrowBack />}
      color="inherit"
    >
      <FormattedMessage id="common.back" defaultMessage="Indietro" />
    </Button>
  );

  const completeSocialLogin = useCallback(() => {
    //console.log("completeSocialLogin");
    startLoading();
    bmapi
      .doSocialSignIn()
      .catch((e) => {
        if (e && e.missingEmail) {
          const info = getAuthEmailNotPresentInfo(e.signInProvider, e, intl);
          if (info.dialog) {
            return notifyDialog(info);
          } else {
            return notifyError(getErrorMessageString(e, intl));
          }
        } else {
          return notifyError(getErrorMessageString(e, intl));
        }
      })
      .finally(stopLoading);
    return false;
  }, [bmapi, intl, notifyError, notifyDialog, startLoading, stopLoading]);

  const socialLogout = useCallback(
    (e) => {
      console.log("socil logout", e);
      bmapi.logout();
    },
    [bmapi]
  );

  useEffect(() => {
    setUiConfig(bmapi.getSocialSignInConfig(completeSocialLogin, socialLogout));
  }, [bmapi, completeSocialLogin, socialLogout]);

  useEffect(() => {
    if (
      bmapi.isConsumer() &&
      document.cookie
        .split(";")
        .map((c) => c.trim().split("="))
        .some((c) => c[0] === "luccacrea_user_jwt")
    ) {
      history.push(afterLoginUrl());
    }
  }, [afterLoginUrl, bmapi, history]);

  useEffect(() => {
    let iTitle = "",
      iSubtitle = "",
      iBackButton = false;
    /*
    const trace = getQueryVariable(location.search, URL_TRACE_PARAM);
    const callback = getQueryVariable(location.search, URL_CALLBACK_PARAM);
    if (trace === URL_TRACE_VALUES.SUBSCRIBE) {
      //if (callback !== MANAGER_ROUTES.SUBSCRIPTION_START) {
      if (isJoinSubscribeBuyUrl(callback)) {
        iSubtitle = intl.formatMessage(subscriptions.buyLogin);
      } else {
        iSubtitle = intl.formatMessage(subscriptions.noBuyLogin);
      }*/
    if (getSignInSubscriptionData()?.isSubscribing) {
      iSubtitle = intl.formatMessage(subscriptions.buyLogin);
      iBackButton = true;
    } else {
      iTitle = intl.formatMessage({
        id: "pages.entry.title",
        defaultMessage: "Benvenuto!",
      });
    }
    setTitle(iTitle);
    setSubtitle(iSubtitle);
    setShowBackButton(iBackButton);
  }, [intl]);

  //console.log("zzzzzzzzz", isSignInSubscription());
  /*
  console.log("Entry render 1", bmapi.getFirebaseAuth().currentUser);
  console.log("Entry render 2", uiConfig);
  console.log("Entry render 3", bmapi.getFirebaseAuth());
  console.log("Entry render 4", loading);
  */

  return (
    <Box my={5} pb={10}>
      <LoginCard
        title={title}
        subtitle={subtitle}
        back={showBackButton && backButtonComponent}
      >
        <Box mt={4} mb={4}>
          {!bmapi.can(FEATURES.HIDE_FB_LOGIN) &&
            !bmapi.can(FEATURES.HIDE_GOOGLE_LOGIN) && (
              <React.Fragment>
                {loading || !uiConfig.signInOptions.length ? null : (
                  <StyledFirebaseAuth
                    uiConfig={uiConfig}
                    firebaseAuth={bmapi.getFirebaseAuth()}
                  />
                )}
              </React.Fragment>
            )}

          {(bmapi.settings.loginEmail || bmapi.isManager()) && (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.loginButton}
              onClick={signIn}
            >
              <FormattedMessage
                id="pages.account.signInEmail"
                defaultMessage="Sign in with Email"
              />
            </Button>
          )}

          {bmapi.settings.customLogin && (
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.loginButton}
              onClick={customSignIn}
            >
              <FormattedMessage
                id="pages.account.signIn"
                defaultMessage="Sign in"
              />
            </Button>
          )}
        </Box>

        <MoreInfo />
      </LoginCard>
    </Box>
  );
}
