import React, { useEffect } from "react";
import {
  Card,
  CardContent,
  Button,
  //TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";

const ConsentDialog = ({ consentModal }) => {
  const [open, setOpen] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState("");
  const [consents, setConsents] = React.useState([]);
  const [transactions, setTransactions] = React.useState([]);

  useEffect(() => {
    consentModal.current = openDialog;
    // eslint-disable-next-line
  }, [consentModal]);

  function openDialog() {
    if (consentModal.current.value.type == "transactions") {
      setDialogTitle("Transazioni");
      setTransactions(consentModal.current.value.data);
      setConsents([]);
    }
    if (consentModal.current.value.type == "consents") {
      setDialogTitle("Consensi");
      setConsents(consentModal.current.value.data);
      setTransactions([]);
    }
    setOpen(true);
  }

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Card>
            {consents.length != 0 ? (
              <CardContent>
                <div style={{ width: "100%" }}>
                  {consents.map((k) => {
                    return (
                      <table key={k.id}>
                        <tbody>
                          <tr>
                            <td>Tipo</td>
                            <td>{k.type}</td>
                          </tr>
                          <tr>
                            <td>Titolo</td>
                            <td>{k.title}</td>
                          </tr>
                          <tr>
                            <td>Testo</td>
                            <td>{k.text}</td>
                          </tr>
                          <tr>
                            <td>Richiesta</td>
                            <td>{k.agreement_text}</td>
                          </tr>
                          <tr>
                            <td>Valido da</td>
                            <td>
                              {k.from} a {k.to}
                            </td>
                          </tr>
                          <tr>
                            <td>Stato</td>
                            <td>{k.status}</td>
                          </tr>
                          <tr>
                            <td>Obbligatorio</td>
                            <td>{k.mandatory}</td>
                          </tr>
                          <tr>
                            <td>Richiesto</td>
                            <td>{k.force_signature}</td>
                          </tr>
                        </tbody>
                      </table>
                    );
                  })}
                </div>
              </CardContent>
            ) : null}
            {transactions.length != 0 ? (
              <CardContent fullWidth="true">
                <div style={{ width: "100%" }}>
                  {transactions.map((k) => {
                    return (
                      <table
                        key={k.id}
                        style={{
                          margin: "10px",
                          borderTop: "1px solid grey",
                          width: "100%",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td>
                              <Typography variant="h6">Data:</Typography>
                              {k.created_at.substring(0, 10)}
                            </td>
                            <td>
                              <Typography variant="h6">Tipo:</Typography>
                              {k.type}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography variant="h6">Stato:</Typography>
                              {k.status}
                            </td>
                            <td>
                              <Typography variant="h6">Valore:</Typography>
                              {k.value}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography variant="h6">
                                Valore spesa :
                              </Typography>
                              {k.value_from_expense}
                            </td>
                            <td>
                              <Typography variant="h6">
                                Valore extra:
                              </Typography>
                              {k.value_from_extra}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography variant="h6">Emittente:</Typography>
                              {k.status}
                            </td>
                            <td>
                              <Typography variant="h6">
                                Tipo ricevente:
                              </Typography>
                              {k.available_shots}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography variant="h6">Ricevente:</Typography>
                              {k.status}
                            </td>
                            <td>
                              <Typography variant="h6">
                                Tipo ricevente:
                              </Typography>
                              {k.available_shots}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Typography variant="h6">
                                ID operazione:
                              </Typography>
                              {k.operation_id}
                            </td>
                            <td>
                              <Typography variant="h6">
                                Valore operazione:
                              </Typography>
                              {k.operation_value}
                            </td>
                          </tr>
                          <tr col="2">
                            <td>
                              <Typography variant="h6">Descrizione:</Typography>
                              {k.description}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    );
                  })}
                </div>
              </CardContent>
            ) : null}
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            Chiudi
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConsentDialog;
