import { compareByOrder } from "./utils";

export function getConsentOkValue(consent) {
  const val = consent.okValues?.length ? consent.okValues[0] : null;
  return val || "T";
}

export function getConsentKoValue(consent) {
  const val = consent.koValues?.length ? consent.koValues[0] : null;
  return val || "F";
}

export function checkConsents(consents, consentExpressions) {
  if (consents?.length) {
    // filter the mandatory consents without agreement
    let missingAgreements = consents
      .filter(
        (c) =>
          c.mandatory && !(consentExpressions?.[c.id] === getConsentOkValue(c))
      )
      .sort(compareByOrder);

    // after manage the consents without title
    if (missingAgreements?.length) {
      return false;
    }
  }
  return true;
}

export function getExpressionsObject(consents, consentExpressions) {
  const list = consents.map((consent) => ({
    consent_id: consent.id,
    value: consentExpressions[consent.id] || getConsentKoValue(consent),
  }));
  return { expressions: list };
}

export function hasConsentAgreedByType(
  consents,
  consentExpressions,
  consentType
) {
  return (
    consents.filter(
      (consent) =>
        consent.type === consentType &&
        consentExpressions[consent.id] === getConsentOkValue(consent)
    )?.length > 0
  );
}

export function getConsentsAndExpressions(consExpr) {
  let iconsents = consExpr?.consents || [];
  if (!Array.isArray(iconsents)) {
    iconsents = [];
  }
  let iconsentExpressions = consExpr?.consent_expressions?.reduce(
    (acc, obj) => {
      acc[obj.consent_id] = obj.value;
      return acc;
    },
    {}
  );
  return { consents: iconsents, consentExpressions: iconsentExpressions };
}
