import {
  Drawer,
  Fab,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@material-ui/core";
import React from "react";
import AddIcon from "@material-ui/icons/Add";

import FloatingActions from "../ui/FloatingActions";
import { useIntl } from "react-intl";
import { common } from "../messages";

const ButtonChoice = ({ items, setContentSelected }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const intl = useIntl();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <FloatingActions>
      <Fab variant="extended" color="primary" onClick={handleClick}>
        <AddIcon />
        {intl.formatMessage(common.add)}
      </Fab>
      <Drawer
        anchor="bottom"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        {items.map((item) => (
          <MenuItem
            key={item.text}
            onClick={() => {
              item.onClick();
              setContentSelected(false);
            }}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </MenuItem>
        ))}
      </Drawer>
    </FloatingActions>
  );
};

export default ButtonChoice;
