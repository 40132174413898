import React, { useState } from "react";
import { useIntl } from "react-intl";

import Confirm from "./Confirm";
import { useBmapi } from "../utils/bmapi-context";
import { getErrorMessageString } from "../utils/errors";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { common } from "../messages";

const TARGETS = {
  ALL: "all",
  USER: "user",
};

export default function CampaignProdsDelete({
  campaign,
  open,
  onSuccess,
  onCancel,
}) {
  const {
    bmapi,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  const intl = useIntl();
  const [target, setTarget] = useState("");
  const [email, setEmail] = useState("");

  const handleDelete = () => {
    startLoading();

    return bmapi
      .deleteProducts(
        campaign.campaign_id,
        target === TARGETS.USER ? email : undefined
      )
      .then(() => {
        onSuccess();
        notifySuccess(
          intl.formatMessage(
            {
              id: "component.campaignDeleteProds.successMessage",
              defaultMessage:
                "Hai eliminato con successo i prodotti della campagna {name}",
            },
            {
              name: campaign.name,
            }
          )
        );
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    onCancel();
  };

  return (
    <Confirm
      open={open}
      disabled={!target || (target === TARGETS.USER && !email)}
      onConfirm={handleDelete}
      onCancel={handleCancel}
      title={intl.formatMessage(
        {
          id: "component.campaignDeleteProds.confirm",
          defaultMessage: "Eliminare i prodotti della campagna {campaign}",
        },
        { campaign: campaign.name }
      )}
    >
      <RadioGroup value={target} onChange={(_, val) => setTarget(val)}>
        <FormControlLabel
          value={TARGETS.ALL}
          control={<Radio required color="primary" />}
          label={intl.formatMessage({
            id: "component.campaignDeleteProds.all",
            defaultMessage: "Tutti i prodotti",
          })}
        />
        <FormControlLabel
          value={TARGETS.USER}
          control={<Radio required color="primary" />}
          label={intl.formatMessage({
            id: "component.campaignDeleteProds.user",
            defaultMessage: "Prodotti utente",
          })}
        />
      </RadioGroup>
      {target === TARGETS.USER && (
        <TextField
          autoFocus
          margin="normal"
          label={intl.formatMessage(common.email)}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          required
          fullWidth
        />
      )}
    </Confirm>
  );
}
