import { defineMessages } from "react-intl";

export const common = defineMessages({
  accept: {
    id: "common.accept",
    defaultMessage: "Accetta",
  },
  active: {
    id: "common.active",
    defaultMessage: "Attivo",
  },
  add: {
    id: "common.add",
    defaultMessage: "Aggiungi",
  },
  addElement: {
    id: "common.addElement",
    defaultMessage: "Elemento aggiunto con successo",
  },
  addEmail: {
    id: "common.addEmail",
    defaultMessage: "Aggiungi email destinatario",
  },
  addressee: {
    id: "common.addressee",
    defaultMessage: "Destinatario",
  },
  addressees: {
    id: "common.addressees",
    defaultMessage: "Destinatari",
  },
  age: {
    id: "common.age",
    defaultMessage: "Età",
  },
  agreement: {
    id: "common.agreement",
    defaultMessage: "Convenzione",
  },
  all: {
    id: "common.all",
    defaultMessage: "Tutti",
  },
  anyStatus: {
    id: "common.anyStatus",
    defaultMessage: "Qualsiasi stato",
  },
  app: {
    id: "common.app",
    defaultMessage: "Applicazione",
  },
  apply: {
    id: "common.apply",
    defaultMessage: "Applica",
  },
  automatic: {
    id: "common.form.automatic",
    defaultMessage: "Automatica",
  },
  back: {
    id: "common.back",
    defaultMessage: "Indietro",
  },
  backHome: {
    id: "common.backHome",
    defaultMessage: "Torna alla home",
  },
  backMessages: {
    id: "common.backMessages",
    defaultMessage: "Torna ai messaggi",
  },
  backTour: {
    id: "common.backTour",
    defaultMessage: "Torna al tour",
  },
  backTours: {
    id: "common.backTours",
    defaultMessage: "Torna ai tours",
  },
  nextPOI: {
    id: "common.nextPOI",
    defaultMessage: "Successivo",
  },
  prevPOI: {
    id: "common.prevPOI",
    defaultMessage: "Precedente",
  },
  birthday: {
    id: "common.form.birthday",
    defaultMessage: "Data di nascita",
  },
  business: {
    id: "common.business",
    defaultMessage: "Negozio",
  },
  campaign: {
    id: "common.campaign",
    defaultMessage: "Campagna",
  },
  campaigns: {
    id: "common.campaigns",
    defaultMessage: "Campagne",
  },
  cancel: {
    id: "common.cancel",
    defaultMessage: "Annulla",
  },
  canceled: {
    id: "common.canceled",
    defaultMessage: "Cancellato",
  },
  check: {
    id: "common.check",
    defaultMessage: "Controlla",
  },
  clickHere: {
    id: "common.clickHere",
    defaultMessage: "Premi qui",
  },
  clone: {
    id: "common.clone",
    defaultMessage: "Duplica",
  },
  close: {
    id: "common.close",
    defaultMessage: "Chiudi",
  },
  confirm: {
    id: "common.confirm",
    defaultMessage: "Conferma",
  },
  consents: {
    id: "common.consents",
    defaultMessage: "Consensi",
  },
  continue: {
    id: "common.continue",
    defaultMessage: "Continua",
  },
  create: {
    id: "common.create",
    defaultMessage: "Crea",
  },
  created: {
    id: "common.created",
    defaultMessage: "Creato",
  },
  date: {
    id: "common.date",
    defaultMessage: "Data",
  },
  day: {
    id: "common.day",
    defaultMessage: "Giorno",
  },
  days: {
    id: "common.form.days",
    defaultMessage: "Giorni",
  },
  deactivated: {
    id: "common.deactivated",
    defaultMessage: "Disattivo",
  },
  deleted: {
    id: "common.deleted",
    defaultMessage: "Eliminato",
  },
  delete: {
    id: "common.delete",
    defaultMessage: "Elimina",
  },
  deleteElement: {
    id: "common.deleteElement",
    defaultMessage: "Elemento eliminato con successo",
  },
  demo: {
    id: "common.demo",
    defaultMessage: "Demo",
  },
  deutch: {
    id: "common.deutch",
    defaultMessage: "Tedesco",
  },
  disable: {
    id: "common.disable",
    defaultMessage: "Disabilita",
  },
  download: {
    id: "common.download",
    defaultMessage: "Download",
  },
  email: {
    id: "common.email",
    defaultMessage: "Email",
  },
  emailVerified: {
    id: "common.emailVerified",
    defaultMessage: "Email verificata con successo",
  },
  executedErrors: {
    id: "component.executedErrors",
    defaultMessage: "Completato con errori",
  },
  expense: {
    id: "common.expense",
    defaultMessage: "Spesa",
  },
  expirationDate: {
    id: "common.expirationDate",
    defaultMessage: "Data scadenza",
  },
  obsolete: {
    id: "common.obsolete",
    defaultMessage: "Scaduto",
  },
  event: {
    id: "common.event",
    defaultMessage: "Evento",
  },
  eventRunning: {
    id: "common.eventRunning",
    defaultMessage: "Evento in corso",
  },
  file: {
    id: "common.file",
    defaultMessage: "File",
  },
  filters: {
    id: "common.filters",
    defaultMessage: "Filtri",
  },
  filterEmail: {
    id: "common.filterEmail",
    defaultMessage: "Filtra per email",
  },
  filterFrom: {
    id: "common.filterFrom",
    defaultMessage: "Filtra per data Da:",
  },
  filterName: {
    id: "common.filterName",
    defaultMessage: "Filtra per nome",
  },
  filterStatus: {
    id: "common.filterStatus",
    defaultMessage: "Filtra per stato",
  },
  filterTo: {
    id: "common.filterTo",
    defaultMessage: "Filtra per data A:",
  },
  forward: {
    id: "common.forward",
    defaultMessage: "Avanti",
  },
  inactive: {
    id: "common.inactive",
    defaultMessage: "Inattivo",
  },
  noEventRunning: {
    id: "common.noEventRunning",
    defaultMessage: "Nessun evento in corso",
  },
  noResult: {
    id: "common.noResult",
    defaultMessage: "Nessun risultato da mostrare",
  },
  end: {
    id: "common.end",
    defaultMessage: "Fine",
  },
  endEvent: {
    id: "common.endEvent",
    defaultMessage: "Concludi evento",
  },
  eventCode: {
    id: "common.eventCode",
    defaultMessage: "Codice evento",
  },
  duration: {
    id: "common.duration",
    defaultMessage: "Durata",
  },
  english: {
    id: "common.english",
    defaultMessage: "Inglese",
  },
  start: {
    id: "common.start",
    defaultMessage: "Inizio",
  },
  startEvent: {
    id: "common.startEvent",
    defaultMessage: "Avvia evento",
  },
  notPresent: {
    id: "common.notPresent",
    defaultMessage: "Assente",
  },
  female: {
    id: "common.form.female",
    defaultMessage: "Femmina",
  },
  fiscalCode: {
    id: "common.form.fiscal_code",
    defaultMessage: "Codice fiscale",
  },
  french: {
    id: "common.french",
    defaultMessage: "Francese",
  },
  from: {
    id: "common.from",
    defaultMessage: "Da",
  },
  gender: {
    id: "common.form.gender",
    defaultMessage: "Genere",
  },
  generalData: {
    id: "common.generalData",
    defaultMessage: "Dati generali",
  },
  generalInfo: {
    id: "common.generalInfo",
    defaultMessage: "Informazioni generali",
  },
  hours: {
    id: "common.hours",
    defaultMessage: "Ore",
  },
  hoursCount: {
    id: "common.hoursCount",
    defaultMessage: "{hours, plural, one {1 ora} other {# ore}}",
  },
  invitedBy: {
    id: "common.form.invitedBy",
    defaultMessage: "Codice amico",
  },
  issueNotification: {
    id: "common.form.issueNotification",
    defaultMessage: "Notifica di emissione",
  },
  italian: {
    id: "common.italian",
    defaultMessage: "Italiano",
  },
  language: {
    id: "common.form.language",
    defaultMessage: "Lingua",
  },
  lastName: {
    id: "common.form.last_name",
    defaultMessage: "Cognome",
  },
  loading: {
    id: "common.loading",
    defaultMessage: "Caricamento",
  },
  location: {
    id: "common.location",
    defaultMessage: "Località",
  },
  locations: {
    id: "common.locations",
    defaultMessage: "Località",
  },
  loop: {
    id: "common.loop",
    defaultMessage: "Circuito",
  },
  male: {
    id: "common.form.male",
    defaultMessage: "Maschio",
  },
  multimediaData: {
    id: "common.multimediaData",
    defaultMessage: "Dati multimediali",
  },
  mobile: {
    id: "common.mobile",
    defaultMessage: "Telefono",
  },
  modify: {
    id: "common.modify",
    defaultMessage: "Modifica",
  },
  month: {
    id: "common.month",
    defaultMessage: "Mese",
  },
  months: {
    id: "common.months",
    defaultMessage: "Mesi",
  },
  name: {
    id: "common.name",
    defaultMessage: "Nome",
  },
  no: {
    id: "common.no",
    defaultMessage: "No",
  },
  noElement: {
    id: "common.noElement",
    defaultMessage: "Nessun elemento presente",
  },
  noElementToShow: {
    id: "common.noElementToShow",
    defaultMessage: "Nessun elemento da mostrare",
  },
  noSubscriptions: {
    id: "common.noSubscriptions",
    defaultMessage:
      "Non hai ancora sottoscrizioni attive. Registra la tua attività <link>cliccando qui</link>",
  },
  notSpecified: {
    id: "common.notSpecified",
    defaultMessage: "Non specificato",
  },
  ok: {
    id: "common.ok",
    defaultMessage: "Ok",
  },
  openView: {
    id: "common.openView",
    defaultMessage: "Visualizza",
  },
  other: {
    id: "common.other",
    defaultMessage: "Altro",
  },
  password: {
    id: "common.password",
    defaultMessage: "Password",
  },
  passwordUpdated: {
    id: "common.passwordUpdated",
    defaultMessage: "Password cambiata con successo",
  },
  pending: {
    id: "common.pending",
    defaultMessage: "Sospeso",
  },
  prize: {
    id: "common.prize",
    defaultMessage: "Premio",
  },
  prizes: {
    id: "common.prizes",
    defaultMessage: "Premi",
  },
  product: {
    id: "common.product",
    defaultMessage: "Prodotto",
  },
  pushNotifications: {
    id: "common.pushNotifications",
    defaultMessage: "Invio notifiche push",
  },
  qrCode: {
    id: "common.qrCode",
    defaultMessage: "Mostra QR Code",
  },
  quantity: {
    id: "common.quantity",
    defaultMessage: "Quantità",
  },
  referrer: {
    id: "common.referrer",
    defaultMessage: "Referrer",
  },
  results: {
    id: "common.results",
    defaultMessage: "Risultati",
  },
  role: {
    id: "common.role",
    defaultMessage: "Ruolo",
  },
  running: {
    id: "common.running",
    defaultMessage: "In esecuzione",
  },
  save: {
    id: "common.save",
    defaultMessage: "Salva",
  },
  search: {
    id: "common.search",
    defaultMessage: "Cerca",
  },
  secondaryEmail: {
    id: "common.secondaryEmail",
    defaultMessage: "Email secondaria",
  },
  send: {
    id: "common.send",
    defaultMessage: "Invia",
  },
  spanish: {
    id: "common.spanish",
    defaultMessage: "Spagnolo",
  },
  statistics: {
    id: "common.statistics",
    defaultMessage: "Statistiche",
  },
  status: {
    id: "common.status",
    defaultMessage: "Stato",
  },
  store: {
    id: "common.store",
    defaultMessage: "Negozio",
  },
  stores: {
    id: "common.stores",
    defaultMessage: "Negozi",
  },
  subscriptions: {
    id: "common.subscriptions",
    defaultMessage: "I tuoi abbonamenti",
  },
  successfullyExecuted: {
    id: "component.successfullyExecuted",
    defaultMessage: "Completato con successo",
  },
  testing: {
    id: "common.testing",
    defaultMessage: "In test",
  },
  text: {
    id: "common.text",
    defaultMessage: "Testo",
  },
  times: {
    id: "common.form.times",
    defaultMessage: "Volte",
  },
  to: {
    id: "common.to",
    defaultMessage: "A",
  },
  total: {
    id: "common.total",
    defaultMessage: "Totale",
  },
  totalPoints: {
    id: "common.totalPoints",
    defaultMessage: "{points, plural, one {# punto} other {# punti}}",
  },
  transactions: {
    id: "common.transactions",
    defaultMessage: "Transazioni",
  },
  typeOf: {
    id: "common.typeOf",
    defaultMessage: "Tipo",
  },
  update: {
    id: "common.update",
    defaultMessage: "Aggiorna",
  },
  upload: {
    id: "common.upload",
    defaultMessage: "Upload",
  },
  uploadFile: {
    id: "common.uploadFile",
    defaultMessage: "File upload",
  },
  uploadLogo: {
    id: "common.uploadLogo",
    defaultMessage: "Carica logo",
  },
  deleteLogo: {
    id: "common.deleteLogo",
    defaultMessage: "Cancella logo",
  },
  url: {
    id: "common.url",
    defaultMessage: "URL",
  },
  value: {
    id: "common.value",
    defaultMessage: "Valore",
  },
  view: {
    id: "common.view",
    defaultMessage: "Visualizza",
  },
  viewMore: {
    id: "common.viewMore",
    defaultMessage: "Mostra altro",
  },
  week: {
    id: "common.week",
    defaultMessage: "Settimana",
  },
  weeks: {
    id: "common.weeks",
    defaultMessage: "Settimane",
  },
  welcome: {
    id: "common.welcome",
    defaultMessage: "Benvenuto!",
  },
  welcomeMsg: {
    id: "common.welcomeMsg",
    defaultMessage:
      "Utilizza <link>il tuo QR Code</link> per ottenere nuovi card e coupon. Lo puoi trovare aprendo il menu in alto a destra o usando il pulsante in fondo a questa pagina.",
  },
  message: {
    id: "common.message",
    defaultMessage: "Messaggio",
  },
  messages: {
    id: "common.messages",
    defaultMessage: "Messaggi",
  },
  yes: {
    id: "common.yes",
    defaultMessage: "Sì",
  },
  win: {
    id: "common.win",
    defaultMessage: "Vincita",
  },
  selectADate: {
    id: "common.selectADate",
    defaultMessage: "Seleziona una data",
  },
  selectATime: {
    id: "common.selectATime",
    defaultMessage: "Seleziona l'orario",
  },
  confirmDelete: {
    id: "common.confirmDelete",
    defaultMessage: "Conferma cancellazione",
  },
  error: {
    id: "common.error",
    defaultMessage: "Errore",
  },
  proceed: {
    id: "common.proceed",
    defaultMessage: "Procedi",
  },
  prefix: {
    id: "common.prefix",
    defaultMessage: "Prefisso",
  },
  savePhone: {
    id: "common.savePhone",
    defaultMessage: "Memorizza il telefono",
  },
  notes: {
    id: "common.notes",
    defaultMessage: "Note",
  },
  dateAndTime: {
    id: "common.dateAndTime",
    defaultMessage: "Data e ora",
  },
  details: {
    id: "common.details",
    defaultMessage: "Dettagli",
  },
  selectDeselectAll: {
    id: "common.selectDeselectAll",
    defaultMessage: "Seleziona/Deseleziona tutti",
  },
  order: {
    id: "common.order",
    defaultMessage: "Ordine",
  },
  refreshPage: {
    id: "common.refreshPage",
    defaultMessage: "Ricarica la pagina",
  },
  enabled: {
    id: "common.enabled",
    defaultMessage: "Abilitato",
  },
  mandatory: {
    id: "common.mandatory",
    defaultMessage: "Obbligatorio",
  },
  signatureNeeded: {
    id: "common.signatureNeeded",
    defaultMessage: "Firma richiesta",
  },
  multiBusiness: {
    id: "common.multiBusiness",
    defaultMessage: "Multi business",
  },
});
