import React from "react";

import { MuiThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";

import { useBmapi } from "../utils/bmapi-context";

export default function LoginPage({ component: Page }) {
  const { bmapi } = useBmapi();

  return (
    <MuiThemeProvider theme={bmapi.theme.login}>
      <CssBaseline />
      <Page />
    </MuiThemeProvider>
  );
}
