import React from "react";
import { IntlProvider } from "react-intl";

import { useBmapi } from "./bmapi-context";
import { getAppMessages } from "./dictionaries";

const ProviderBase = ({ children, language, defaultLang, skin, tenant }) => (
  <IntlProvider
    locale={language}
    defaultLocale={defaultLang}
    messages={getAppMessages(language, defaultLang, skin, tenant)}
  >
    {children}
  </IntlProvider>
);
const Provider = React.memo(ProviderBase);

export function LocalizationProvider({ children }) {
  const { bmapi, language } = useBmapi();

  return (
    bmapi &&
    language && (
      <Provider
        language={language}
        defaultLang={bmapi.settings.defaultLanguage}
        skin={bmapi.settings.skin}
        tenant={bmapi.tenant}
      >
        {children}
      </Provider>
    )
  );
}
