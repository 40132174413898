import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { useIntl } from "react-intl";
import { format } from "date-fns";
import Title from "../../ui/Title";
import { ArrowBack, CloudDownload, Delete } from "@material-ui/icons";
import Confirm from "../../ui/Confirm";
import { common } from "../../messages";
import { it } from "date-fns/locale";
import { MANAGER_ROUTES } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import utils from "../../utils/utils";

const byEventDate = (a, b) => {
  const dateA = format(new Date(a.start_date), "yyyy-MM-dd");
  const dateB = format(new Date(b.start_date), "yyyy-MM-dd");
  const nameA = a.name?.toUpperCase() || "";
  const nameB = b.name?.toUpperCase() || "";
  const timeA = a.start_hour;
  const timeB = b.start_hour;

  if (dateA < dateB) {
    return 1;
  } else if (dateA > dateB) {
    return -1;
  } else if (nameA < nameB) {
    return -1;
  } else if (nameA > nameB) {
    return 1;
  } else if (timeA < timeB) {
    return 1;
  } else if (timeA > timeB) {
    return -1;
  } else {
    return 0;
  }
};

const byDate = (a, b) => {
  const dateA = format(new Date(a.planned_delivery), "yyyy-MM-dd");
  const dateB = format(new Date(b.planned_delivery), "yyyy-MM-dd");
  const nameA = a.content_name?.toUpperCase() || "";
  const nameB = b.content_name?.toUpperCase() || "";
  const timeA = format(new Date(a.planned_delivery), "HH:mm");
  const timeB = format(new Date(b.planned_delivery), "HH:mm");

  if (dateA < dateB) {
    return 1;
  } else if (dateA > dateB) {
    return -1;
  } else if (nameA < nameB) {
    return -1;
  } else if (nameA > nameB) {
    return 1;
  } else if (timeA < timeB) {
    return 1;
  } else if (timeA > timeB) {
    return -1;
  } else {
    return 0;
  }
};

const byName = (a, b) => {
  const lastNameA = a?.user?.last_name?.toUpperCase() || "";
  const firstNameA = a?.user?.complete_name?.toUpperCase() || "";
  const lastNameB = b?.user?.last_name?.toUpperCase() || "";
  const firstNameB = b?.user?.complete_name?.toUpperCase() || "";

  if (lastNameA < lastNameB) {
    return -1;
  } else if (lastNameA > lastNameB) {
    return 1;
  } else if (firstNameA < firstNameB) {
    return -1;
  } else if (firstNameA > firstNameB) {
    return 1;
  } else {
    return 0;
  }
};

const dateTime = (r, wedding, eventDate) => {
  if (wedding) {
    return `nozze: ${format(
      new Date(r.wedding_date || r.user.custom_fields.wedding_date),
      "dd/MM/yyyy"
    )}`;
  } else if (eventDate) {
    return `${format(new Date(r.start_date), "EEEE dd MMMM", {
      locale: it,
    })} | Ore: ${r.start_hour} - ${r.end_hour}`;
  } else
    return `${format(new Date(r.planned_delivery), "EEEE dd MMMM", {
      locale: it,
    })} | Ore: ${format(new Date(r.planned_delivery), "HH:mm")}`;
};

function ReservationsList({
  currentData,
  openReservation,
  onDelete,
  setItemToDelete,
}) {
  return (
    <List>
      {currentData.list
        ?.sort(!currentData.event ? byDate : byName)
        .map((r, i) => (
          <React.Fragment key={r.id}>
            <ListItem
              button
              onClick={() => openReservation(r)}
              divider={!currentData.event && i !== currentData.list.length - 1}
            >
              <ListItemText
                primary={
                  <React.Fragment>
                    {!currentData.event && (
                      <React.Fragment>
                        {<Typography>{dateTime(r)}</Typography>}
                        <Typography>{r.content_name}</Typography>
                        <Typography>{r.business_name}</Typography>
                      </React.Fragment>
                    )}
                    <Typography style={{ fontWeight: 700 }}>
                      {!currentData.event
                        ? `${r.first_name.toUpperCase()} ${r.last_name.toUpperCase()}`
                        : `${r.user.complete_name.toUpperCase()} ${r.user.last_name.toUpperCase()}`}
                    </Typography>
                  </React.Fragment>
                }
                secondary={
                  <React.Fragment>
                    {<Typography>{dateTime(r, true)}</Typography>}
                    <Typography>{`tel. ${r.mobile || r.user.mobile} - email: ${
                      r.email || r.user.email
                    }`}</Typography>
                  </React.Fragment>
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  onClick={() => {
                    onDelete(true);
                    setItemToDelete(r);
                  }}
                >
                  <Delete />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </React.Fragment>
        ))}
    </List>
  );
}

export default function ManageReservations() {
  const {
    bmapi,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();
  const intl = useIntl();
  const history = useHistory();
  const [reservations, setReservations] = useState(null);
  const [eventReservations, setEventReservations] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [reservSelected, setReservSelected] = useState(null);
  const [fairSelected, setFairSelected] = useState(null);
  const [eventSelected, setEventSelected] = useState(null);
  const [fairList, setFairList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState("merchant");

  const data = [
    {
      enabled: tab === "merchant",
      list: reservations,
      event: false,
    },
    {
      enabled: tab === "events",
      list: eventReservations,
      event: true,
    },
  ];
  const currentData = data.find((i) => i.enabled);

  const goToHome = () => {
    history.push(MANAGER_ROUTES.HOME_MENU);
  };

  const byEventSelected = (event) => {
    if (eventSelected) {
      return event.id === eventSelected.id;
    } else
      return eventReservations.find((res) => res.campaign_id === event.id)
        ? event
        : null;
  };

  const loadReservationList = useCallback(() => {
    startLoading();
    bmapi
      .getEOrder({
        business: bmapi.getUserInfo().business.id,
      })
      .then((resp) => {
        setReservations(resp || []);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
    stopLoading();
  }, [bmapi, intl, notifyError, startLoading, stopLoading]);

  const loadEventReservationList = useCallback(
    (fair, event) => {
      if (fair.event_campaign_id) {
        startLoading();
        bmapi
          .getMainEventPasses(
            fair.event_campaign_id,
            fair.business_id,
            event?.id
          )
          .then((resp) => {
            resp.map((r) => {
              r.user.custom_fields = JSON.parse(r.user.custom_fields);
              return r;
            });
            setEventReservations(resp || []);
          })
          .catch((e) => {
            notifyError(getErrorMessageString(e, intl));
          })
          .finally(() => {
            stopLoading();
          });
      }
    },
    [bmapi, intl, notifyError, startLoading, stopLoading]
  );

  const loadFairs = useCallback(() => {
    startLoading();
    bmapi
      .getFairs({ type: "FAIR" })
      .then((resp) => {
        setFairList(resp || []);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  }, [bmapi, intl, notifyError, startLoading, stopLoading]);

  const loadEvents = (fair) => {
    startLoading();
    bmapi
      .getEventsByCampaign(fair.event_campaign_id)
      .then((resp) => {
        resp.sort(byEventDate);
        setEventList(resp || []);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  };

  useEffect(() => {
    loadReservationList();
  }, [loadReservationList]);

  const openReservation = (res) => {
    setOpen(true);
    setReservSelected(res);
  };

  const onClose = () => {
    setOpen(false);
    setReservSelected(null);
  };

  const changeTab = (tab) => {
    setTab(tab);
    setFairSelected(null);
    setEventSelected(null);
    setEventReservations([]);
    if (tab === "events") {
      loadFairs();
    }
  };

  const reservationDelete = (item) => {
    startLoading();
    (!currentData.event
      ? bmapi.deleteReservation(item.id)
      : bmapi.deleteEventReservation(item.product_id)
    )
      .then(() => {
        notifySuccess(intl.formatMessage(common.deleteElement));
        !currentData.event
          ? loadReservationList()
          : loadEventReservationList(fairSelected, eventSelected);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
    stopLoading();
  };

  const download = () => {
    startLoading();
    bmapi
      .getReservationsCsv({
        business: bmapi.getUserInfo().business.id,
      })
      .then((blob) => {
        utils.download(blob, "prenotazioni_appuntamenti.csv");
      })
      .catch((e) => {
        notifyError("" + e);
      })
      .finally(stopLoading);
  };

  const downloadEvents = () => {
    if (!fairSelected?.event_campaign_id) return;
    startLoading();
    bmapi
      .getMainEventPassesCsv(fairSelected?.event_campaign_id, {
        business: bmapi.getUserInfo().business.id,
      })
      .then((blob) => {
        utils.download(blob, "prenotazioni_eventi.csv");
      })
      .catch((e) => {
        notifyError("" + e);
      })
      .finally(stopLoading);
  };

  return (
    <Container maxWidth="sm">
      <Title>Prenotazioni</Title>
      <Tabs
        value={tab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        onChange={(_, v) => {
          changeTab(v);
        }}
      >
        <Tab label="Appuntamento" value="merchant" />
        <Tab label="Appuntamento eventi" value="events" />
      </Tabs>
      {/* NEW */}
      {currentData.event && (
        <Box mt={2}>
          <Box display="flex">
            <TextField
              required
              fullWidth
              select
              label="Fiera"
              value={fairSelected}
              onChange={(e) => {
                setEventSelected(null);
                setEventReservations(null);
                setFairSelected(e.target.value);
                loadEvents(e.target.value);
                loadEventReservationList(e.target.value);
              }}
            >
              {fairList.map((option) => (
                <MenuItem key={option.id} value={option}>
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              disabled={!fairSelected}
              required
              fullWidth
              select
              label="Evento"
              value={eventSelected}
              onChange={(e) => {
                setEventSelected(e.target.value);
                loadEventReservationList(fairSelected, e.target.value);
              }}
            >
              {eventList.map((option) => (
                <MenuItem key={option.id} value={option}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
      )}
      <Box mt={currentData.event ? 8 : 2} style={{ clear: "right" }}>
        {!currentData.list?.length ? (
          <Typography>Nessuna prenotazione da mostrare</Typography>
        ) : (
          <React.Fragment>
            {currentData.event &&
              eventList.filter(byEventSelected).map((event) => (
                <Box my={2} key={event.id}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">
                        {`${fairSelected.title} - ${event.name}`}
                      </Typography>
                      {
                        <Typography gutterBottom>
                          {dateTime(event, false, true)}
                        </Typography>
                      }
                    </CardContent>
                    <Divider />
                    <ReservationsList
                      currentData={currentData}
                      openReservation={openReservation}
                      onDelete={setShowDeleteAlert}
                      setItemToDelete={setItemToDelete}
                    />
                  </Card>
                </Box>
              ))}
            {!currentData.event && (
              <Card>
                <ReservationsList
                  currentData={currentData}
                  openReservation={openReservation}
                  onDelete={setShowDeleteAlert}
                  setItemToDelete={setItemToDelete}
                />
              </Card>
            )}
          </React.Fragment>
        )}
      </Box>
      {/* END NEW */}

      <Box my={2}>
        <Button onClick={goToHome} startIcon={<ArrowBack />}>
          {intl.formatMessage(common.backHome)}
        </Button>
        {tab === "merchant" && (
          <Button onClick={download} startIcon={<CloudDownload />}>
            Scarica appuntamenti
          </Button>
        )}
        {tab === "events" && fairSelected && (
          <Button onClick={downloadEvents} startIcon={<CloudDownload />}>
            Scarica Prenotazioni eventi
          </Button>
        )}
      </Box>

      {reservSelected && (
        <Dialog open={open} onClose={onClose} fullWidth>
          <DialogContent>
            <Typography style={{ fontWeight: 700 }} gutterBottom>
              {!currentData.event
                ? `${reservSelected.first_name.toUpperCase()} ${reservSelected.last_name.toUpperCase()}`
                : `${reservSelected.user.complete_name.toUpperCase()} ${reservSelected.user.last_name.toUpperCase()}`}
            </Typography>
            {currentData.event && (
              <Typography>
                {`${fairSelected.title} - ${reservSelected.campaign_name}`}
              </Typography>
            )}
            <Typography gutterBottom>
              {!currentData.event
                ? dateTime(reservSelected)
                : dateTime(
                    eventList.find(
                      (ev) => ev.id === reservSelected.campaign_id
                    ),
                    false,
                    true
                  )}
            </Typography>
            <Typography>{dateTime(reservSelected, true)}</Typography>
            <Typography>
              tel.{" "}
              <Link
                onClick={() =>
                  window.open(
                    "tel:" +
                      (reservSelected.mobile || reservSelected.user.mobile),
                    "_blank"
                  )
                }
                style={{ cursor: "pointer" }}
              >
                {reservSelected.mobile || reservSelected.user.mobile}
              </Link>
              {" - "}
              email:{" "}
              <Link
                href={`mailto:${
                  reservSelected.email || reservSelected.user.email
                }`}
              >
                {reservSelected.email || reservSelected.user.email}
              </Link>
            </Typography>
            {!currentData.event && (
              <Box mt={3}>
                <Typography>{`Richiesta utente: ${
                  reservSelected.notes || "-"
                }`}</Typography>
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={onClose}>
              {intl.formatMessage(common.close)}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Confirm
        open={showDeleteAlert}
        onConfirm={() => {
          reservationDelete(itemToDelete);
          setShowDeleteAlert(false);
        }}
        onCancel={() => {
          setItemToDelete(null);
          setShowDeleteAlert(false);
        }}
        text="Stai per cancellare la prenotazione, ti raccomandiamo di contattare il cliente prima di effettuare la cancellazione"
      />
    </Container>
  );
}
