import React from "react";
import { useIntl } from "react-intl";

import { FormControl, FormControlLabel, Switch } from "@material-ui/core";

import { PRODUCT_SUBTYPES } from "../../../utils/constants";
import { form } from "../../../messages";

export default function WaitingList({ values, handleChange }) {
  const intl = useIntl();

  return (
    ![
      PRODUCT_SUBTYPES.EARNING_CARD_SIMPLE,
      PRODUCT_SUBTYPES.EARNING_CARD_INSTANT_WIN,
      PRODUCT_SUBTYPES.PROVISIONING_CARD,
    ].includes(values.subtype) && (
      <FormControl margin="normal">
        <FormControlLabel
          control={
            <Switch
              checked={values.waiting_list}
              color="primary"
              onChange={handleChange("waiting_list")}
              key="waiting_list"
              name="waiting_list"
            />
          }
          label={intl.formatMessage(form.waiting)}
          //disabled={!!campaign}
        />
      </FormControl>
    )
  );
}
