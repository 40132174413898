import React from "react";
import { useIntl } from "react-intl";

import { InputAdornment, MenuItem, Select } from "@material-ui/core";

import NumberInput from "../../NumberInput";
import { PRODUCT_SUBTYPES } from "../../../utils/constants";
import { common, product } from "../../../messages";
import { format } from "date-fns";

export default function ProductLifespan({
  values,
  handleChange,
  productType,
  handleChangePeriodType,
  errorValidity,
  campaign,
  performance,
}) {
  const intl = useIntl();

  return (
    ![
      PRODUCT_SUBTYPES.EARNING_CARD_INSTANT_WIN,
      PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE,
    ].includes(values.subtype) && (
      <NumberInput
        name="product_lifespan"
        label={intl.formatMessage(product[productType].productLifespan)}
        value={values.product_lifespan}
        onChange={handleChange(
          "product_lifespan",
          values.product_lifespan_type
        )}
        key="product_lifespan"
        fullWidth
        margin="normal"
        required={!values.product_limit_date}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Select
                disabled={
                  performance?.issued_qty > 0 && campaign.product_limit_date
                }
                value={
                  values.product_lifespan_type
                    ? values.product_lifespan_type
                    : "M"
                }
                onChange={(e) => {
                  handleChangePeriodType(
                    "product_lifespan_type",
                    e,
                    values.product_lifespan
                  );
                }}
              >
                <MenuItem value={"M"}>
                  {intl.formatMessage(common.months)}
                </MenuItem>
                <MenuItem value={"D"}>
                  {intl.formatMessage(common.days)}
                </MenuItem>
              </Select>
            </InputAdornment>
          ),
        }}
        //disabled={!!campaign}
        disabled={performance?.issued_qty > 0 && campaign.product_limit_date}
        helperText={
          performance?.issued_qty > 0 &&
          errorValidity &&
          `Il periodo di validità della card non può essere inferiore a: ${format(
            campaign.max_product_date,
            "dd/MM/yyyy"
          )}`
        }
        error={performance?.issued_qty > 0 && errorValidity}
      />
    )
  );
}
