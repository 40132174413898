import React from "react";
import { useIntl } from "react-intl";

import { Container } from "@material-ui/core";

import ManageLocations from "../../components/ManageLocations";
import Title from "../../ui/Title";

export default function Locations() {
  const intl = useIntl();

  return (
    <Container maxWidth="sm">
      <Title>
        {intl.formatMessage({
          id: "pages.locations.title",
          defaultMessage: "Luoghi",
        })}
      </Title>
      <ManageLocations />
    </Container>
  );
}
