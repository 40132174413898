import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Container, Divider } from "@material-ui/core";
import { useBmapi } from "../../utils/bmapi-context";
import { campaign as campaignMessages } from "../../messages";
import Notification from "./Notification";
import Title from "../../ui/Title";
import { initCreator, notificationsTypes } from "../../utils/notifications";
import FormDialog from "../../ui/FormDialog";

export default function CampaignNotifications() {
  const { campaignId } = useParams();
  const intl = useIntl();
  const { bmapi, startLoading, stopLoading, notifySuccess } = useBmapi();
  const [campaign, setCampaign] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [values, setValues] = useState([]);
  const [creator, setCreator] = useState(initCreator);
  const [report, setReport] = useState({ total: 0, active: 0 });
  const openModal = React.useRef(null);

  const handleSubmit = (position, event_type, after) => {
    startLoading();
    var data = notifications[position];
    if (position == -1) {
      data = creator;
      data.event_type = event_type;
      return saveNotificationCampaign(data, after);
    } else {
      data = notifications[position];
      updateNotificationCampaign(data, after);
    }
  };

  const handleSubmitWithDialog = () => {
    //setOpenModal(true);
    openModal.current();
  };

  const updateNotificationCampaign = (data, after) => {
    // data.event_type = data.event_type === "CREATOR" ? "ISSUE" : data.event_type;
    return bmapi
      .updateNotificationCampaign(
        data.event_type,
        data.active,
        data.in_app_active,
        data.in_app_title,
        data.in_app_body,
        data.banner_active,
        data.banner_title,
        data.banner_body,
        data.push_active,
        data.push_title,
        data.push_body,
        data.push_image_url,
        data.email_active,
        data.email_subject,
        data.email_body,
        data.email_from,
        data.remind_days == "" ? 0 : data.remind_days,
        campaignId,
        data.id
      )
      .then(after)
      .then(() => {
        notifySuccess(intl.formatMessage(campaign.createdNotification));
        resetCreatorValues();
        stopLoading();
        //history.push(MANAGER_ROUTES.CAMPAIGNS.replace(":filter?/", ""));
      })
      .catch(stopLoading);
  };

  const saveNotificationCampaign = (data, after) => {
    return bmapi
      .saveNotificationCampaign(
        data.event_type,
        data.active,
        data.in_app_active,
        data.in_app_title,
        data.in_app_body,
        data.banner_active,
        data.banner_title,
        data.banner_body,
        data.push_active,
        data.push_title,
        data.push_body,
        data.push_image_url,
        data.email_active,
        data.email_subject,
        data.email_body,
        data.email_from,
        data.remind_days == "" ? 0 : data.daysRemaining,
        campaignId
      )
      .then(after)
      .then(() => {
        notifySuccess(intl.formatMessage(campaign.createdNotification));
        resetCreatorValues();
        stopLoading();
        //history.push(MANAGER_ROUTES.CAMPAIGNS.replace(":filter?/", ""));
      })
      .catch(stopLoading);
  };

  const resetCreatorValues = () => {
    for (const [key, value] of Object.entries(values.CREATOR)) {
      if (key != "type" && key != "title") {
        if (typeof value === "boolean") {
          values.CREATOR[key] = false;
        }
        if (typeof value === "string") {
          values.CREATOR[key] = "";
        }
      }
    }
  };
  const handleChange = (notification, key, position) => {
    const updateNotification = (val) => {
      console.log(notification);
      console.log(val);
      console.log(key);
      if (position == -1) {
        // Work on creator
        creator[key] = val;
        var updateCreator = Object.assign({}, creator);
        setCreator(updateCreator);
      } else {
        notifications[position][key] = val;
        var updateNotificatinos = notifications.slice(0, notifications.length);
        setNotifications(updateNotificatinos);
      }
    };
    return (i, f) => {
      if (typeof f === "boolean") {
        updateNotification(f);
      } else if (i?.target) {
        updateNotification(i.target.value);
      } else {
        updateNotification(i);
      }
    };
  };

  const handleUpdateEventsList = (position, key, val) => {
    console.log(position, key, val);
    values[position].active = !values[position].active;
    var updateValues = values.slice(0, values.length);
    setValues(updateValues);
    creator.notificationsTypes.find((x) => x.type == key).enabled = val;
    setCreator(creator);
    let reportUpdated = { total: 1, active: 1 };
    setReport(reportUpdated);
  };

  useEffect(() => {
    var types = notificationsTypes;
    startLoading();
    bmapi.getCampaign(campaignId).then(({ campaign }) => {
      setCampaign(campaign);
      stopLoading();
    });
    bmapi.getCampaignNotifications(campaignId).then((values) => {
      values.forEach((element) => {
        element["show"] = false;
        element["title"] = types.find(
          (x) => x.type == element.event_type
        ).title;
      });
      values.sort(function (a, b) {
        if (
          a.event_type.indexOf(b.event_type) >= 0 ||
          b.event_type.indexOf(a.event_type) >= 0
        ) {
          if (a.event_type < b.event_type) {
            return -1;
          }
          if (b.event_type < a.event_type) {
            return 1;
          }
        } else {
          if (a.event_type > b.event_type) {
            return -1;
          }
          if (b.event_type > a.event_type) {
            return 1;
          }
        }
        return 0;
      });
      if (values != undefined) {
        setValues(values);
        setNotifications(values);
        setReport({
          total: values.length,
          active: values.filter((x) => x.active).length,
        });
      }
      stopLoading();
    });
  }, [bmapi, campaignId, startLoading, stopLoading]);

  const renderedResults = notifications.map((result, index) => {
    if (result.type != "CREATOR") {
      return (
        <div key={result.id} className="item">
          <Notification
            position={index}
            elem={result}
            disabled={!result.enabled}
            name={result.type}
            title={result.title}
            values={values}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleUpdateEventsList={handleUpdateEventsList}
            handleSubmitWithDialog={handleSubmitWithDialog}
          />
        </div>
      );
    }
  });

  return (
    !!campaign && (
      <React.Fragment>
        <Container maxWidth="sm">
          <Title>{intl.formatMessage(campaignMessages.newNotification)}</Title>
          <Notification
            position={-1}
            elem={creator}
            disabled={creator.enabled}
            handleChange={handleChange}
            name="CREATOR"
            title="Nuova notifica"
            values={values}
            status="init"
            handleSubmit={handleSubmit}
            handleSubmitWithDialog={handleSubmitWithDialog}
          />
          <h3>
            Riepilogo Notifiche:&nbsp;
            {report.total}&nbsp;(di cui attive&nbsp;{report.active})
          </h3>
          <Divider variant="middle" style={{ margin: "10px" }} />
          <div className="ui celled list">{renderedResults}</div>
        </Container>
        <FormDialog
          openModal={openModal}
          handleSubmit={handleSubmit}
          handleSubmitWithDialog={handleSubmitWithDialog}
        ></FormDialog>
      </React.Fragment>
    )
  );
}
