import React, { useEffect, useState, useCallback } from "react";
import { useIntl } from "react-intl";

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@material-ui/core";

import Grid from "../../ui/Grid";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { common } from "../../messages";
import { MANAGER_NAMESPACE } from "../../utils/constants";
import Pricing from "../../components/Pricing";

function getSubUrl(sub, baseUrl) {
  return `${baseUrl}${
    sub.friendly_url || sub.subscription_key
  }${MANAGER_NAMESPACE}`;
}

function ConsumerSubscriptionCard({ subscription }) {
  const { bmapi } = useBmapi();

  return (
    <Box mb={4}>
      <Card>
        <CardActionArea
          onClick={() =>
            window.open(
              getSubUrl(subscription, bmapi.settings.subscriptionsURL),
              "_blank"
            )
          }
        >
          {subscription.logo_big ? (
            <CardMedia
              style={{
                height: 150,
                backgroundColor: subscription.primary_color,
                backgroundSize: "contain",
                border: "20px solid transparent",
              }}
              image={subscription.logo_big}
              title={`${subscription.program_name} logo`}
            />
          ) : (
            <CardMedia
              style={{ height: 150 }}
              image={bmapi.createBg(
                subscription.program_name,
                subscription.primary_color
              )}
            />
          )}
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {subscription.company_name}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
}

export default function ConsumerSubscriptions() {
  const intl = useIntl();
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const [subscriptions, setSubscriptions] = useState(false);
  //const [consentSubscriptions, setConsentSubscriptions] = useState(false);

  const createLoopsMap = useCallback(
    async (products) => {
      const loopsMap = new Map();
      if (!products) {
        return;
      }
      console.log("SUBSCRIPTIONS", products);
      const busMap = new Map(
        products.map((product) => [product.business_id, 1])
      );
      for (const businessId of busMap.keys()) {
        console.log("WWW2", businessId);
        try {
          const business = await bmapi.getBusiness(businessId);
          console.log("SUBSCRIPTIONS", business);
        } catch (e) {
          console.log("ERR", e);
        }
      }
      return loopsMap;
    },
    [bmapi]
  );

  useEffect(() => {
    startLoading();
    bmapi
      .getSubscriptionsByUser()
      .then((subs) => {
        /*
        if (Array.isArray(subs) && subs.length === 1) {
          //location.href = getSubUrl(subs[0], bmapi.settings.subscriptionsURL);
        } else {*/
        setSubscriptions(subs || []);
        /*}*/
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
    bmapi
      .getUserProducts()
      .then((products) => {
        createLoopsMap(products);
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  }, [bmapi, intl, notifyError, startLoading, stopLoading, createLoopsMap]);

  return (
    subscriptions &&
    (subscriptions.length ? (
      <Box my={5} pb={10}>
        <Container maxWidth="md">
          <Title>{intl.formatMessage(common.subscriptions)}</Title>
          <Grid
            items={subscriptions}
            render={(subscription) => (
              <ConsumerSubscriptionCard
                key={subscription.id}
                subscription={subscription}
              />
            )}
          />
        </Container>
      </Box>
    ) : (
      <Pricing />
    ))
  );
}
