import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Link } from "@material-ui/core";

import Confirm from "./Confirm";
import { useBmapi } from "../utils/bmapi-context";
import { useCampaigns } from "../utils/campaigns";
import { getErrorMessageString } from "../utils/errors";

export default function CampaignJoin({ campaign, open, onSuccess, onCancel }) {
  const { startLoading, stopLoading, notifyError, notifySuccess } = useBmapi();
  const intl = useIntl();
  const { signCampaign } = useCampaigns();

  const createLink = (str) => (
    <Link
      href={campaign.tos_merchant_url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {str}
    </Link>
  );

  const handleJoin = () => {
    startLoading();

    return signCampaign(campaign)
      .then(() => {
        onSuccess();
        notifySuccess(
          intl.formatMessage(
            {
              id: "component.campaignJoin.successMessage",
              defaultMessage: "Hai aderito con successo alla campagna {name}",
            },
            {
              name: campaign.name,
            }
          )
        );
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    onCancel();
  };

  return (
    <Confirm
      open={open}
      onConfirm={handleJoin}
      onCancel={handleCancel}
      title={campaign.name}
      text={
        <FormattedMessage
          id="component.campaignJoin.confirm"
          defaultMessage="Sei sicuro di voler aderire alla campagna?"
        />
      }
      flag={
        campaign.tos_merchant_url && (
          <FormattedMessage
            id="component.campaignJoin.tos"
            defaultMessage="Dichiaro di avere letto e approvato il regolamento della campagna e le condizioni di adesione pubblicate su <link>questa pagina</link>"
            values={{ link: createLink }}
          />
        )
      }
    />
  );
}
