import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import Title from "../ui/Title";
import { useBmapi } from "../utils/bmapi-context";
import { useIntl } from "react-intl";
import { getErrorMessageString } from "../utils/errors";
import utils from "../utils/utils";
import { INIT_QUERY_MERCHANTS } from "../utils/gdspConstants";
import { ArrowBack, ArrowDownward, ArrowUpward } from "@material-ui/icons";
import { common } from "../messages";
import { MANAGER_ROUTES } from "../utils/constants";
import { useHistory } from "react-router-dom";
import QRCodeGenerator from "qrcode";
import ActionsDetails from "./ActionsDetails";

export default function MerchantsStatistics() {
  const initialOrder = useMemo(
    () => ({
      name: "",
      category: "",
    }),
    []
  );
  const intl = useIntl();
  const history = useHistory();
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const [merchants, setMerchants] = useState([]);
  const [actions, setActions] = useState([]);
  const [query, setQuery] = useState(INIT_QUERY_MERCHANTS);
  const [count, setCount] = useState(0);
  const [displayUserInfo, setDisplayUserInfo] = useState(false);
  const [showData, setShowData] = useState(false);
  const [selectedMerchant, setSelectedMerchant] = useState(null);
  const [code, setCode] = useState(null);
  const [ordered, setOrdered] = useState(initialOrder);
  const [name, setName] = useState("");

  const goToHome = () => {
    history.push(MANAGER_ROUTES.HOME_MENU);
  };

  // get count: getUserStatsWithPointsCount
  // get users: getUserStatsWithPoints

  const getMerchants = useCallback(
    (q) => {
      startLoading();
      if (bmapi) {
        bmapi
          .getMerchantsActions(q || query)
          .then((resp) => {
            setMerchants(resp);
            return bmapi.getMerchantsActionsCount(q || query);
          })
          .then((resp) => {
            setCount(resp);
            stopLoading();
          })
          .catch((e) => {
            notifyError(getErrorMessageString(e, intl));
            stopLoading();
          });
      }
    },
    [bmapi, intl, notifyError, startLoading, stopLoading, query]
  );

  useEffect(() => {
    getMerchants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const download = () => {
    startLoading();
    bmapi
      .getMerchantsStats()
      .then((blob) => utils.download(blob, "export.csv"))
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  };

  const updateHeaderTitle = (user) => {
    setDisplayUserInfo(user);
  };

  const search = () => {
    const params = {
      ...query,
      name: name || null,
    };
    for (const property in params) {
      if (params[property] === null) {
        delete params[property];
      }
    }
    setQuery(params);
    getMerchants(params);
  };

  const handleChangeOrderBy = useCallback(
    (value, varName) => {
      setOrdered(initialOrder);
      setOrdered((v) => ({
        ...v,
        [varName]: value.startsWith("-") ? "minor" : "major",
      }));

      setQuery({
        ...INIT_QUERY_MERCHANTS,
        orderBy: value,
      });
      getMerchants({
        ...INIT_QUERY_MERCHANTS,
        orderBy: value,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialOrder]
  );

  const handleChangePage = (goTop) => (event, newPage) => {
    setQuery((v) => ({ ...v, page: newPage }));
    getMerchants({ ...query, page: newPage });
    if (goTop) window.scrollTo(0, 0);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setQuery((v) => ({ ...v, qty: newRowsPerPage, page: 0 }));
    getMerchants({ ...query, qty: newRowsPerPage, page: 0 });
  };

  const handleClose = () => {
    setShowData(false);
    setActions([]);
    setSelectedMerchant(null);
    setDisplayUserInfo(null);
  };

  const handleShowQR = (business) => {
    const qrCodeUrl = "MERCHANT_" + business.id;
    const qrProps = { scale: 16 };
    /*
    if (
      business?.id === "9e1650d0-424c-43cb-ade2-e19d9f645521" ||
      business?.id === "4c38d4f9-df04-4613-8712-d86b8ad61848"
    ) {
      qrProps.errorCorrectionLevel = "Q";
    }*/
    qrProps.errorCorrectionLevel = "Q";

    QRCodeGenerator.toDataURL(qrCodeUrl, qrProps)
      .then(setCode)
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  };

  const showActions = (merchant) => {
    startLoading();
    setDisplayUserInfo(false);
    bmapi
      .getBusinessRewardingAction({ business: merchant.id })
      .then((resp) => {
        if (resp !== null && resp !== undefined) {
          setActions(resp);
        } else {
          setActions([]);
        }
        setSelectedMerchant(merchant);
        setShowData(true);
        handleShowQR(merchant);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  };

  return (
    <React.Fragment>
      <Title>Statistiche Operatori</Title>

      <Container maxWidth="sm">
        <TextField
          label={intl.formatMessage(common.filterName)}
          value={name}
          onChange={(e) => setName(e.target.value)}
          name="name"
          fullWidth
        />
        <Box mt={2}>
          <Button variant="contained" color="primary" onClick={search}>
            {intl.formatMessage(common.search)}
          </Button>
        </Box>
      </Container>

      {/*<Container maxWidth="sm">
        <Box mb={5}>
          <FormControl fullWidth margin="normal">
            <InputLabel id="demo-simple-select-helper-label">
              Ordina per
            </InputLabel>
            <Select
              id="select-orderby"
              value={query.orderBy}
              onChange={handleChangeOrderBy}
            >
              {MERCHANTS_ORDER.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
              </Container>*/}

      <Box my={2}>
        <Button onClick={goToHome} startIcon={<ArrowBack />}>
          {intl.formatMessage(common.backHome)}
        </Button>
      </Box>

      <Card>
        <CardContent>
          <Box mb={5} mt={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={download}
              style={{ float: "right" }}
            >
              Scarica report
            </Button>
          </Box>
          <TableContainer>
            <Table aria-label="simple table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    onClick={() =>
                      handleChangeOrderBy(
                        ordered.name === "minor" ? "Name" : "-Name",
                        "name"
                      )
                    }
                    style={{ cursor: "pointer" }}
                    hover
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      Nome
                    </Typography>
                    {ordered.name && (
                      <IconButton style={{ padding: 0 }}>
                        {ordered.name === "minor" ? (
                          <ArrowDownward fontSize="small" />
                        ) : (
                          <ArrowUpward fontSize="small" />
                        )}
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>Email manager</TableCell>
                  <TableCell
                    onClick={() =>
                      handleChangeOrderBy(
                        ordered.category === "minor" ? "Category" : "-Category",
                        "category"
                      )
                    }
                    style={{ cursor: "pointer" }}
                    hover
                  >
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      Categoria
                    </Typography>
                    {ordered.category && (
                      <IconButton style={{ padding: 0 }}>
                        {ordered.category === "minor" ? (
                          <ArrowDownward fontSize="small" />
                        ) : (
                          <ArrowUpward fontSize="small" />
                        )}
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell>Interazioni eseguite</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {merchants.map((m) => (
                  <TableRow
                    onClick={() => showActions(m.merchant)}
                    key={m.merchant.id}
                    style={{ cursor: "pointer" }}
                    hover={true}
                  >
                    <TableCell component="th" scope="row">
                      {m.merchant.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {m.merchant.manager_email || "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {m.merchant.category || "-"}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {m.performance.total_actions}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={count}
              rowsPerPage={query.qty}
              page={query.page}
              onChangePage={handleChangePage(true)}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </TableContainer>
        </CardContent>
      </Card>

      {/* DIALOG USER ACTIONS */}
      <Dialog open={showData} onClose={handleClose} fullWidth maxWidth="md">
        <DialogContent>
          <Box mb={3}>
            {displayUserInfo ? (
              <React.Fragment>
                {displayUserInfo.picture ? (
                  <CardMedia
                    image={displayUserInfo.picture}
                    style={{
                      height: "100px",
                      width: "100px",
                      margin: "0 auto",
                    }}
                  />
                ) : (
                  <Avatar
                    style={{
                      height: "100px",
                      width: "100px",
                      margin: "0 auto",
                    }}
                  />
                )}
              </React.Fragment>
            ) : (
              selectedMerchant?.status === 0 && (
                <img
                  src={code}
                  alt="QR Code"
                  style={{
                    maxWidth: "25%",
                    display: "block",
                    margin: "0 auto",
                  }}
                />
              )
            )}
            {!displayUserInfo && (
              <React.Fragment>
                <Typography align="center">{selectedMerchant?.name}</Typography>
                <Typography align="center">{`(${selectedMerchant?.manager_email})`}</Typography>
              </React.Fragment>
            )}
            <ActionsDetails
              updateUserPhoto={(u) => updateHeaderTitle(u)}
              merchantStatsView={true}
              showQRCode={null}
              title={null}
              actions={actions}
              setDisplayUserInfo={setDisplayUserInfo}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={handleClose}>
            {intl.formatMessage(common.close)}
          </Button>
        </DialogActions>
      </Dialog>

      <Box my={2}>
        <Button onClick={goToHome} startIcon={<ArrowBack />}>
          {intl.formatMessage(common.backHome)}
        </Button>
      </Box>
    </React.Fragment>
  );
}
