import React from "react";
import { useIntl } from "react-intl";

import { TextField } from "@material-ui/core";

import { useBmapi } from "../../../utils/bmapi-context";
import { PRODUCTS } from "../../../utils/constants";
import { form } from "../../../messages";

export default function TosMerchantUrl({ values, handleChange }) {
  const { bmapi } = useBmapi();
  const intl = useIntl();

  return (
    bmapi.isTenantManager() &&
    ![PRODUCTS.PROVISIONING_CARD, PRODUCTS.COURSE_PASS].includes(
      values.front_end_type
    ) && (
      <TextField
        name="tos_merchant_url"
        label={intl.formatMessage(form.tosMerchantUrl)}
        value={values.tos_merchant_url}
        onChange={handleChange("tos_merchant_url")}
        key="tos_merchant_url"
        fullWidth
        margin="normal"
      />
    )
  );
}
