import React, { useCallback, useEffect } from "react";
import { Container } from "@material-ui/core";
import Title from "../../ui/Title";
import { MANAGER_ROUTES } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { common } from "../../messages";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { intlFormatMessage } from "../../utils/utils";
import { distributionLists } from "../../messages/distributionLists";
import DistributionListForm from "../../ui/forms/DistributionListForm";

export default function DistributionListCreate() {
  const initialValues = {
    dynamic: false,
    name: "",
    static_list: [],
    gender: "ALL",
    age_from: 16,
    age_to: 100,
    consents: [],
    consent_id: "",
    consent_value: false,
    import_csv: "false",
  };
  const history = useHistory();
  const intl = useIntl();
  const { bmapi, notifyError } = useBmapi();
  const [listType, setListType] = React.useState("");
  const [consentsList, setConsentsList] = React.useState([]);
  const [values, setValues] = React.useState(initialValues);

  const staticType = listType === "static";
  const dynamicType = listType === "dynamic";

  useEffect(() => {
    const url = window.location;
    const type = url.pathname.split("/").pop();
    setListType(type);
  }, []);

  const loadConsents = useCallback(() => {
    if (bmapi) {
      bmapi
        .getConsent()
        .then((resp) => {
          if (!resp) {
            resp = [];
            setConsentsList(resp);
          } else {
            setConsentsList(resp);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
  }, [bmapi, intl, notifyError]);

  useEffect(() => {
    loadConsents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadConsents]);

  const handleChange = (event) => {
    const val = event.target.value;
    setValues(initialValues);
    setListType(val);
    history.replace(
      MANAGER_ROUTES.DISTRIBUTION_LIST_CREATE.replace(":subtype?/", val)
    );
  };

  const handleValues = useCallback(
    (varName) => (event) => {
      setValues({ ...values, [varName]: event.target.value });
    },
    [values]
  );

  const inputProps = {
    handleChange,
    handleValues,
    staticType,
    consentsList,
    listType,
    dynamicType,
    values,
    setValues,
  };

  return (
    <Container maxWidth="sm">
      <Title backUrl={MANAGER_ROUTES.DISTRIBUTION_LISTS}>
        {intl.formatMessage(common.create)}{" "}
        {intlFormatMessage(distributionLists[listType], listType, intl)}
      </Title>
      <DistributionListForm {...inputProps} />
    </Container>
  );
}
