import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams, useHistory } from "react-router-dom";

import {
  Accordion,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {
  ArrowBack,
  Block,
  Check,
  Clear,
  ExpandMore,
  PlaylistAdd,
  PlaylistAddCheck,
} from "@material-ui/icons";

import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { useCampaigns } from "../../utils/campaigns";
import { getErrorMessageString } from "../../utils/errors";
import styles from "../../utils/styles";

function ReservationElement({ reservation, campaignId }) {
  const classes = styles.useStyles();
  const intl = useIntl();
  const { bmapi, notifyError } = useBmapi();
  const [issuing, setIssuing] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [status, setStatus] = useState(reservation.status);

  const issue = () => {
    setIssuing(true);
    bmapi
      .acceptReservation(campaignId, reservation.id)
      .then(() => setStatus(1))
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(() => setIssuing(false));
  };

  const reject = () => {
    setRejecting(true);
    bmapi
      .rejectReservation(reservation.id)
      .then(() => setStatus(2))
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(() => setRejecting(false));
  };

  const Icon = useMemo(() => {
    if (reservation.status === 2) return Block;
    if (reservation.status === 1) return PlaylistAddCheck;
    if (status === 2) return Clear;
    if (status === 1) return Check;
    return PlaylistAdd;
  }, [status, reservation.status]);

  const bg = useMemo(() => {
    if (reservation.status === 0 && status === 2)
      return bmapi.theme.app.palette.error.main;
    if (reservation.status === 0 && status === 1)
      return bmapi.theme.app.palette.success.main;
    return undefined;
  }, [status, reservation.status, bmapi.theme.app.palette]);

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar style={bg && { backgroundColor: bg }}>
          <Icon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <React.Fragment>
            {reservation.user_name && (
              <Typography variant="caption" display="block">
                {reservation.user_name}
              </Typography>
            )}
            <Typography display="block" gutterBottom>
              {reservation.user_email}
            </Typography>
          </React.Fragment>
        }
        secondary={intl.formatDate(new Date(reservation.created_at))}
      />
      {status === 0 && (
        <ListItemSecondaryAction>
          <span style={{ display: "inline-block", position: "relative" }}>
            <IconButton
              onClick={reject}
              disabled={rejecting || issuing || !campaignId}
            >
              <Clear color="error" />
            </IconButton>
            {rejecting && (
              <CircularProgress size={48} className={classes.fabProgress} />
            )}
          </span>
          <span style={{ display: "inline-block", position: "relative" }}>
            <IconButton
              onClick={issue}
              disabled={issuing || rejecting || !campaignId}
            >
              <Check style={{ color: bmapi.theme.app.palette.success.main }} />
            </IconButton>
            {issuing && (
              <CircularProgress size={48} className={classes.fabProgress} />
            )}
          </span>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
}

function ReservationList({
  reservations,
  title,
  campaignId,
  expanded = false,
}) {
  return (
    !!reservations.length && (
      <Box mb={4}>
        <Accordion defaultExpanded={expanded}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography>{title}</Typography>
          </AccordionSummary>
          <List>
            {reservations.map((reservation, i) => (
              <React.Fragment key={reservation.id}>
                {i !== 0 && <Divider variant="inset" component="li" />}
                <ReservationElement
                  reservation={reservation}
                  campaignId={campaignId}
                />
              </React.Fragment>
            ))}
          </List>
        </Accordion>
      </Box>
    )
  );
}

export default function Reservations() {
  const { campaignId } = useParams();
  const intl = useIntl();
  const history = useHistory();
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const [reservations, setReservations] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const { campaigns, loadCampaigns } = useCampaigns();

  useEffect(() => {
    !campaigns ? startLoading() : stopLoading();
  }, [campaigns, startLoading, stopLoading]);

  useEffect(() => {
    loadCampaigns().then((cs) => {
      setCampaign(cs.find((c) => c.campaign_id === campaignId));
    });
  }, [loadCampaigns, campaignId]);

  useEffect(() => {
    const byDate = (a, b) => new Date(a.created_at) - new Date(b.created_at);
    startLoading();

    bmapi
      .getCampaignReservations(campaignId)
      .then((reservations) => {
        setReservations((reservations || []).sort(byDate));
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  }, [bmapi, campaignId, intl, notifyError, startLoading, stopLoading]);

  return (
    !!reservations && (
      <Container maxWidth="sm">
        {campaign && (
          <Title>
            <IconButton onClick={history.goBack} size="small">
              <ArrowBack />
            </IconButton>{" "}
            {campaign.name}
          </Title>
        )}

        {reservations.length ? (
          <React.Fragment>
            <ReservationList
              campaignId={campaign?.id}
              expanded
              reservations={reservations.filter((r) => r.status === 0)}
              title={intl.formatMessage({
                id: "page.reservations.pending",
                defaultMessage: "Lista d'attesa",
              })}
            />
            <ReservationList
              reservations={reservations.filter((r) => r.status === 1)}
              title={intl.formatMessage({
                id: "page.reservations.accepted",
                defaultMessage: "Prenotazioni accettate",
              })}
            />
            <ReservationList
              reservations={reservations.filter((r) => r.status === 2)}
              title={intl.formatMessage({
                id: "page.reservations.cancelled",
                defaultMessage: "Prenotazioni cancellate",
              })}
            />
          </React.Fragment>
        ) : (
          <Card>
            <CardContent>
              <FormattedMessage
                id="pages.reservations.noReservations"
                defaultMessage="Nessuna prenotazione presente."
              />
            </CardContent>
          </Card>
        )}

        <Box my={4}>
          <Button onClick={history.goBack} startIcon={<ArrowBack />}>
            <FormattedMessage id="common.back" defaultMessage="Indietro" />
          </Button>
        </Box>
      </Container>
    )
  );
}
