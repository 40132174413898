import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, TextField } from "@material-ui/core";
import { useBmapi } from "../../../utils/bmapi-context";
import { useIntl } from "react-intl";
import { form } from "../../../messages";

export default function BusinessLoop({ values, handleChange, campaign }) {
  const [bsLoop, setBsLoop] = useState([]);
  const { bmapi } = useBmapi();
  const intl = useIntl();

  useEffect(() => {
    bmapi.getLoops().then((bs) => {
      bs.unshift({ id: "-", name: intl.formatMessage(form.noLoop) });
      setBsLoop(bs);
    });
  }, [bmapi, intl]);

  return (
    <FormControl margin="dense">
      <TextField
        label={intl.formatMessage(form.loop)}
        value={values.loop_id}
        onChange={handleChange("loop_id")}
        fullWidth
        select
        disabled={campaign}
      >
        {bsLoop.map((bs) => (
          <MenuItem key={bs.id} value={bs.id}>
            {bs.name}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
}
