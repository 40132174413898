import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link as RouterLink } from "react-router-dom";
import downloadjs from "downloadjs";

import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  TextField,
  Typography,
} from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";

import { common, confirm, navigation } from "../../messages";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import { MANAGER_ROUTES } from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";
import { Alert } from "@material-ui/lab";
import Confirm from "../../ui/Confirm";

const download = (file, email) => {
  return downloadjs(file, `oblio-${email}.pdf`);
};

const Bold = (str) => <strong key={str}>{str}</strong>;

export default function VoidRequest() {
  const intl = useIntl();
  const { bmapi, notifyError, notifySuccess } = useBmapi();
  const [email, setEmail] = useState("");
  const [log, setLog] = useState([]);
  const [saving, setSaving] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setDeleteRequest(true);
  };

  const requestVoid = () => {
    setSaving(true);

    return bmapi
      .requestVoid(email)
      .then((blob) => {
        download(blob, email);
        setLog((l) => [{ file: blob, success: true, email }, ...l]);
        setEmail("");
        notifySuccess(
          intl.formatMessage({
            id: "component.requestVoid.success",
            defaultMessage: "Richiesta eseguita con successo",
          })
        );
      })
      .catch((e) => {
        const message = getErrorMessageString(e, intl);
        setLog((l) => [{ success: false, email, message }, ...l]);
        notifyError(message);
      })
      .finally(() => {
        setDeleteRequest(false);
        setSaving(false);
      });
  };

  return (
    <Container maxWidth="sm">
      <Title>{intl.formatMessage(navigation.voidRequest)}</Title>

      {deleteRequest && (
        <Confirm
          open={!!deleteRequest}
          onConfirm={requestVoid}
          onCancel={() => setDeleteRequest(false)}
          text={intl.formatMessage(confirm.requestVoid, {
            email,
            strong: Bold,
          })}
          flag
        />
      )}

      <Box mb={4}>
        <Card>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <TextField
                autoFocus
                margin="normal"
                label={intl.formatMessage(common.email)}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                required
                fullWidth
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={saving}
              >
                {intl.formatMessage(common.send)}
              </Button>
            </form>
          </CardContent>
        </Card>
      </Box>

      {log.map((entry, i) => (
        <Box key={i} mt={1}>
          <Alert
            severity={entry.success ? "success" : "error"}
            action={
              entry.file ? (
                <Button
                  color="inherit"
                  size="small"
                  onClick={() => download(entry.file, entry.email)}
                >
                  {intl.formatMessage({
                    id: "component.requestVoid.downloadPdf",
                    defaultMessage: "Ricevuta",
                  })}
                </Button>
              ) : undefined
            }
          >
            <strong>{entry.email}</strong>
            {entry.message ? ` — ${entry.message}` : ""}
          </Alert>
        </Box>
      ))}
      {log.length > 0 && (
        <Box mt={2}>
          <Typography variant="body2">
            {intl.formatMessage({
              id: "component.requestVoid.logInfo",
              defaultMessage:
                "Questo log scomparirà alla chiusura della pagina",
            })}
          </Typography>
        </Box>
      )}

      <Box my={4}>
        <Button
          component={RouterLink}
          to={MANAGER_ROUTES.HOME}
          startIcon={<ArrowBackIcon />}
        >
          <FormattedMessage
            id="common.backHome"
            defaultMessage="Torna alla home"
          />
        </Button>
      </Box>
    </Container>
  );
}
