import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";

import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";

import { common } from "../messages";
import LoginCard from "../ui/LoginCard";
import { useBmapi } from "../utils/bmapi-context";
import { CONSUMER_ROUTES, FIREBASE_ACTIONS } from "../utils/constants";
import { getErrorMessageString } from "../utils/errors";
import { Visibility, VisibilityOff } from "@material-ui/icons";

export default function Action() {
  const { bmapi, notifyError, notifySuccess } = useBmapi();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const [params] = useState(new URLSearchParams(location.search));
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleSubmit(e) {
    e.preventDefault();
    bmapi
      .handleResetPassword(params.get("oobCode"), newPassword)
      .then(() => {
        notifySuccess(intl.formatMessage(common.passwordUpdated));
        history.push(CONSUMER_ROUTES.HOME);
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  }

  useEffect(() => {
    switch (params.get("mode")) {
      case FIREBASE_ACTIONS.VERIFY_EMAIL:
        bmapi
          .handleVerifyEmail(params.get("oobCode"))
          .then(() => notifySuccess(intl.formatMessage(common.emailVerified)))
          .catch((e) => notifyError(getErrorMessageString(e, intl)))
          .finally(() => history.push(CONSUMER_ROUTES.HOME));
        break;
      case FIREBASE_ACTIONS.RESET_PASSWORD:
        bmapi
          .getResetPasswordEmail(params.get("oobCode"))
          .then(setEmail)
          .catch((e) => {
            notifyError(getErrorMessageString(e, intl));
            history.push(CONSUMER_ROUTES.HOME);
          });
        break;
      // case FIREBASE_ACTIONS.RECOVER_EMAIL:
      //   // Display email recovery handler and UI.
      //   handleRecoverEmail(auth, actionCode, lang);
      //   break;
      default:
        history.push(CONSUMER_ROUTES.HOME);
    }
  }, [bmapi, history, intl, notifyError, notifySuccess, params]);

  switch (params.get("mode")) {
    case FIREBASE_ACTIONS.VERIFY_EMAIL:
      return <LoginCard loading>Verifica dell&apos;email in corso</LoginCard>;
    case FIREBASE_ACTIONS.RESET_PASSWORD:
      return (
        <LoginCard loading={!email}>
          <form onSubmit={handleSubmit}>
            <Typography>
              <FormattedMessage
                id="component.signin.resetPasswordText"
                defaultMessage="Resetta la tua password"
              />
            </Typography>
            <TextField
              label={intl.formatMessage(common.email)}
              id="email"
              name="email"
              autoComplete="email"
              type="email"
              value={email}
              disabled
              margin="normal"
              fullWidth
            />
            <TextField
              margin="normal"
              required
              fullWidth
              label={intl.formatMessage(common.password)}
              name="password"
              type={showPassword ? "text" : "password"}
              id="password"
              autoComplete="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoFocus
            />
            <FormControl margin="normal" fullWidth>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                {intl.formatMessage(common.save)}
              </Button>
            </FormControl>
          </form>
        </LoginCard>
      );
    default:
      return <React.Fragment></React.Fragment>;
  }
}
