import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardHeader,
  CardMedia,
  Container,
  Divider,
  Fab,
  FormControl,
  IconButton,
  InputLabel,
  List,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import {
  MoreVert as MoreVertIcon,
  Add as AddIcon,
  Create as CreateIcon,
  Delete as DeleteIcon,
  Pause as PauseIcon,
  PlayArrow as PlayArrowIcon,
} from "@material-ui/icons";
import { PRODUCT_STATUS, TAGS } from "../../utils/ecommerceConstants";
import FloatingActions from "../../ui/FloatingActions";
import { useIntl } from "react-intl";
import { account, common, confirm } from "../../messages";
import { MANAGER_ROUTES } from "../../utils/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { intlFormatMessage, priceText } from "../../utils/utils";
import AnchorMenu from "../../ui/AnchorMenu";
import Confirm from "../../ui/Confirm";
import { ecommerce } from "../../messages/ecommerce";

const byName = (a, b) => a.name.localeCompare(b.name);

export default function ManageEProducts() {
  const intl = useIntl();
  const history = useHistory();
  const { bmapi, notifyError, notifySuccess } = useBmapi();
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [categorySelected, setCategorySelected] = React.useState(null);
  const [productSelected, setProductSelected] = React.useState(null);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [status, setStatus] = React.useState("0|2");

  const useStyles = makeStyles(() => ({
    image: {
      width: 70,
      height: 70,
      backgroundColor: "#f5f5f5",
    },
    img: {
      margin: "auto",
      display: "block",
      maxWidth: "100%",
      maxHeight: "100%",
    },
    disabled: {
      color: "#B8B8B8",
    },
  }));

  const classes = useStyles();

  const isDisabled = (product) => {
    return product.status === 2 ? classes.disabled : "";
  };

  useEffect(() => {
    loadCategoryList();
    loadProductList();
  }, [loadCategoryList, loadProductList]);

  const loadCategoryList = useCallback(() => {
    if (bmapi) {
      bmapi
        .getEProductCategories({
          //business: bmapi.getUserInfo().business.id,
          //status: "0", // auto: 0
        })
        .then((resp) => {
          if (!resp) {
            resp = [];
            setCategories(resp);
          } else {
            setCategories(resp);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
  }, [bmapi, intl, notifyError]);

  const loadProductList = useCallback(() => {
    if (bmapi) {
      bmapi
        .getEProduct({
          business: bmapi.getUserInfo().business.id,
          status: "0|2", // auto: 0
        })
        .then((resp) => {
          if (!resp) {
            resp = [];
            setProducts(resp);
          } else {
            setProducts(resp);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
  }, [bmapi, intl, notifyError]);

  const getTagsLabel = (arr) => {
    if (bmapi.settings.flagTag === false) {
      return [];
    } else {
      let newTags = [];
      let translatedTags = TAGS.map((tag) =>
        intlFormatMessage(ecommerce[tag.label], tag.label, intl)
      );
      if (arr && TAGS) {
        for (let i = 0; i < TAGS.length; ++i) {
          for (let y = 0; y < arr.length; ++y) {
            if (TAGS[i].value === arr[y]) {
              newTags.push(translatedTags[i]);
            }
          }
        }
      }
      return newTags; // return arr of strings
    }
  };

  const byPriority = (a, b) => {
    if (a.priority === b.priority) {
      return a.name.localeCompare(b.name);
    } else return a.priority - b.priority;
  };

  const byStatus = (p) => {
    if (status === "0") return p.status === 0;
    else if (status === "2") return p.status === 2;
    else return (p.status === 0) | 2;
  };

  const byChildren = (c) => {
    return products.find((p) => p.category_id === c.id);
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleSelectElement = (event, element, name) => {
    setAnchorEl(event.currentTarget);
    if (name === "category") {
      setCategorySelected(element);
    } else if (name === "product") {
      setProductSelected(element);
    }
  };

  /*const handleAddNewCategory = () => {
    history.push(MANAGER_ROUTES.EPRODUCT_CATEGORY);
  };*/

  const handleModifyCategory = () => {
    history.push(
      MANAGER_ROUTES.EPRODUCT_CATEGORY + "?id=" + categorySelected.id
    );
  };

  const handleAddNewProduct = () => {
    history.push(
      //MANAGER_ROUTES.EPRODUCT + "?has_category=" + categorySelected.id
      MANAGER_ROUTES.EPRODUCT
    );
  };

  const handleModifyProduct = () => {
    history.push(MANAGER_ROUTES.EPRODUCT + "?id=" + productSelected.id);
  };

  const handlePauseProduct = () => {
    let product = {
      ...productSelected,
      status: 2,
    };
    bmapi
      .updateProduct(product)
      .then(() => {
        setProductSelected(null);
        notifySuccess(intl.formatMessage(account.saveConfirm));
      })
      .then(() => {
        loadProductList();
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
  };

  const handleRestoreProduct = () => {
    let product = {
      ...productSelected,
      status: 0,
    };
    bmapi
      .updateProduct(product)
      .then(() => {
        setProductSelected(null);
        notifySuccess(intl.formatMessage(account.saveConfirm));
      })
      .then(() => {
        loadProductList();
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
  };

  const alertDeleteElement = () => {
    setShowDeleteAlert(true);
  };

  const hasChildren = () => {
    if (
      products.find((product) => product.category_id === categorySelected?.id)
    ) {
      return true;
    } else return false;
  };

  const handleDeleteCategory = () => {
    let category = {
      ...categorySelected,
      status: 1,
    };
    bmapi
      .updateCategory(category)
      .then(() => {
        setCategorySelected(null);
        notifySuccess(intl.formatMessage(common.deleteElement));
      })
      .then(() => {
        loadCategoryList();
        loadProductList();
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
  };

  const handleDeleteProduct = () => {
    let product = {
      ...productSelected,
      status: 1,
    };
    bmapi
      .updateProduct(product)
      .then(() => {
        setShowDeleteAlert(false);
        setProductSelected(null);
        notifySuccess(intl.formatMessage(common.deleteElement));
      })
      .then(() => {
        loadProductList();
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
  };

  const categoryMenuItems = [
    {
      text: intl.formatMessage(common.modify),
      icon: <CreateIcon fontSize="small" />,
      onClick: handleModifyCategory,
    },
    /*
    {
      text: "Aggiungi prodotto",
      icon: <ShoppingBasketIcon fontSize="small" />,
      onClick: handleAddNewProduct,
    },
    */
    {
      text: intl.formatMessage(common.delete),
      icon: <DeleteIcon fontSize="small" />,
      onClick: alertDeleteElement,
      disabled: hasChildren(),
    },
  ];

  const productMenuItems = [
    {
      text: intl.formatMessage(common.modify),
      icon: <CreateIcon fontSize="small" />,
      onClick: handleModifyProduct,
    },
    {
      text: intl.formatMessage(common.delete),
      icon: <DeleteIcon fontSize="small" />,
      onClick: alertDeleteElement,
    },
    {
      text:
        productSelected?.status === 0
          ? intl.formatMessage(ecommerce.pause)
          : intl.formatMessage(ecommerce.resume),
      icon:
        productSelected?.status === 0 ? (
          <PauseIcon fontSize="small" />
        ) : (
          <PlayArrowIcon fontSize="small" />
        ),
      onClick:
        productSelected?.status === 0
          ? handlePauseProduct
          : handleRestoreProduct,
    },
  ];

  return (
    <Container maxWidth="sm">
      <FormControl fullWidth>
        <InputLabel>{intl.formatMessage(ecommerce.products)}</InputLabel>
        <Select value={status} onChange={handleChangeStatus}>
          {PRODUCT_STATUS.map((status) => (
            <MenuItem key={status.value} value={status.value}>
              {intlFormatMessage(ecommerce[status.label], status.label, intl)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box mb={2}>
        {categories
          .filter(byChildren)
          .sort(byName)
          .map((category) => {
            return (
              <React.Fragment key={category.id}>
                <Card style={{ marginTop: 20 }}>
                  <CardHeader
                    action={
                      <IconButton
                        onClick={(e) => {
                          handleSelectElement(e, category, "category");
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    }
                    title={
                      <Typography variant="h6">{category.name}</Typography>
                    }
                    subheader={
                      <>
                        <Typography gutterBottom>
                          {category.description}
                        </Typography>
                        <Divider />
                        <Typography>
                          {getTagsLabel(category.tags).join(", ")}
                        </Typography>
                      </>
                    }
                    style={{ backgroundColor: "#f5f5f5" }}
                    disableTypography
                  />
                  <List>
                    {products
                      .filter(byStatus)
                      .sort(byPriority)
                      .map((product) =>
                        category.id === product.category_id ? (
                          <div key={product.id}>
                            <CardHeader
                              avatar={
                                product.media_contents ? (
                                  <CardMedia
                                    image={product.media_contents[0].url}
                                    className={classes.image}
                                  />
                                ) : (
                                  <CardMedia className={classes.image} />
                                )
                              }
                              action={
                                <IconButton
                                  onClick={(e) => {
                                    handleSelectElement(e, product, "product");
                                  }}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              }
                              title={
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  className={isDisabled(product)}
                                >
                                  {product.name}
                                </Typography>
                              }
                              subheader={
                                <>
                                  <Typography
                                    style={{ whiteSpace: "pre-line" }}
                                    gutterBottom
                                    className={isDisabled(product)}
                                  >
                                    {product.description}
                                  </Typography>
                                  <Divider
                                    style={{
                                      marginRight: 60,
                                      color:
                                        product.status === 2
                                          ? "#B8B8B8"
                                          : "inherit",
                                    }}
                                  />
                                  <Typography
                                    gutterBottom
                                    className={isDisabled(product)}
                                  >
                                    {getTagsLabel(product.tags).join(", ")}
                                  </Typography>
                                  {product.price != 0 && (
                                    <Typography
                                      gutterBottom
                                      variant="h6"
                                      className={isDisabled(product)}
                                    >
                                      €{priceText(product.price).split(".")[0]},
                                      {priceText(product.price).split(".")[1]}
                                    </Typography>
                                  )}
                                </>
                              }
                              disableTypography
                            />
                          </div>
                        ) : null
                      )}
                  </List>
                </Card>
              </React.Fragment>
            );
          })}

        {categorySelected && (
          <>
            <AnchorMenu
              setItemSelected={setCategorySelected}
              items={categoryMenuItems}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
            />
            <Confirm
              open={showDeleteAlert}
              onConfirm={() => handleDeleteCategory()}
              onCancel={() => {
                setCategorySelected(null);
                setShowDeleteAlert(false);
              }}
              text={intl.formatMessage(confirm.deleteElement)}
            />
          </>
        )}

        {productSelected && (
          <>
            <AnchorMenu
              setItemSelected={setProductSelected}
              items={productMenuItems}
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
            />
            <Confirm
              open={showDeleteAlert}
              onConfirm={() => handleDeleteProduct()}
              onCancel={() => {
                setProductSelected(null);
                setShowDeleteAlert(false);
              }}
              text={intl.formatMessage(confirm.deleteElement)}
            />
          </>
        )}
      </Box>
      {/*  <FloatingActions>
        <Fab variant="extended" color="primary" onClick={handleAddNewCategory}>
          <AddIcon />
          Aggiungi categoria
        </Fab>
      </FloatingActions> */}
      <FloatingActions>
        <Fab variant="extended" color="primary" onClick={handleAddNewProduct}>
          <AddIcon />
          {intl.formatMessage(ecommerce.addProduct)}
        </Fab>
      </FloatingActions>
    </Container>
  );
}
