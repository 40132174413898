import React from "react";
import { useIntl } from "react-intl";

import { InputAdornment } from "@material-ui/core";

import NumberInput from "../../NumberInput";
import { form } from "../../../messages";
import { PRODUCTS } from "../../../utils/constants";

export default function Reimbursement({
  values,
  handleChange,
  setError,
  performance,
}) {
  const intl = useIntl();

  const checkReimbursementeRate = () => {
    setError(
      +values.reimbursement_rate < values.rules.reimbursement_rate / 100
    );
    return +values.reimbursement_rate < values.rules.reimbursement_rate / 100;
  };

  return (
    [PRODUCTS.SHOPPING_CARD, PRODUCTS.COUPON_DISCOUNT_VALUE].includes(
      values.front_end_type
    ) && (
      <NumberInput
        cents
        name="reimbursement_rate"
        label={intl.formatMessage(form.reimbursement)}
        value={values.reimbursement_rate}
        onChange={handleChange("reimbursement_rate")}
        fullWidth
        margin="normal"
        max={100}
        //disabled={!!campaign}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
        error={
          performance?.issued_qty > 0 &&
          values.rules &&
          checkReimbursementeRate()
        }
        helperText={
          performance?.issued_qty > 0 &&
          values.rules &&
          checkReimbursementeRate() &&
          "La percentuale di rimborso non può essere diminuita"
        }
      />
    )
  );
}
