import {
  Box,
  Card,
  CardContent,
  Container,
  Typography,
} from "@material-ui/core";
import { Explore } from "@material-ui/icons";
import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router";

import { useBmapi } from "../../utils/bmapi-context";
import { CONSUMER_ROUTES } from "../../utils/constants";

export default function MyLocation() {
  const history = useHistory();
  const { notifyError } = useBmapi();

  const success = useCallback((position) => {
    console.log(position);
  }, []);

  const error = useCallback(() => {
    history.push(CONSUMER_ROUTES.HOME);
    notifyError("Permessi non dati");
  }, [history, notifyError]);

  useEffect(() => {
    if (!navigator.geolocation) {
      history.push(CONSUMER_ROUTES.HOME);
      notifyError("Geolocation non supportata");
    } else {
      navigator.geolocation.getCurrentPosition(success, error);
    }
  }, [error, history, notifyError, success]);

  console.log("here");

  return (
    <Container maxWidth="sm">
      <Box mt={12}>
        <Card align="center">
          <CardContent>
            <Explore color="primary" style={{ fontSize: 60 }} />
            <Typography variant="h5">
              Abilita la localizzazione del tuo browser per usare questa
              funzionalità
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}
