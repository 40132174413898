import { defineMessages } from "react-intl";

export const storeTypes = defineMessages({
  "SOMMINISTRAZIONE ALIMENTI E BEVANDE": {
    id: "SOMMINISTRAZIONE ALIMENTI E BEVANDE",
    defaultMessage: "Bar & Risto",
  },
  "SERVIZI E ATTIVITA’ ARTIGIANALI": {
    id: "SERVIZI E ATTIVITA’ ARTIGIANALI",
    defaultMessage: "Servizi & Artig",
  },
  "COMMERCIO RETAIL": {
    id: "COMMERCIO RETAIL",
    defaultMessage: "Commercio Retail",
  },
  BONUS: {
    id: "BONUS",
    defaultMessage: "Jolly",
  },
});
