import React, { useCallback, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Collapse,
  Container,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {
  Warning,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";
import Title from "../../ui/Title";
import { addWeeks, format } from "date-fns";
import {
  RESERVATIONS_STATUS,
  RESERVATIONS_VISITOR_TYPES,
  RESERVATIONS_VISIT_LANGUAGES,
} from "../../utils/ecommerceGenConstants";
import { ecommerce } from "../../messages/ecommerce";
import { useIntl } from "react-intl";
import { DatePicker } from "../../ui/DatePicker";
import { common } from "../../messages";
import { it } from "date-fns/locale";
import { useEffect } from "react";
import { useBmapi } from "../../utils/bmapi-context";
import { getStatusLabel } from "../../utils/ecommerceUtils";
import { getErrorMessageString } from "../../utils/errors";

const useRowStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
  headerCheckbox: {
    padding: 0,
  },
  rowCheckbox: {},
  rightSpacer: {
    marginRight: theme.spacing(2),
  },
}));

// COMPONENTE NON GENERICO PER IL MOMENTO

export default function ManageReservationsGen() {
  const today = new Date().setHours(0, 0, 0, 0);
  const classes = useRowStyles();
  const intl = useIntl();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const {
    bmapi,
    startLoading,
    stopLoading,
    notifyError,
    notifyDialog,
    hideDialog,
  } = useBmapi();
  const [openRows, setOpenRows] = useState([]);
  const [openNotes, setOpenNotes] = useState([]);
  const [closingDates, setClosingDates] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [filteredReservations, setFilteredReservations] = useState([]);
  const [filters, setFilters] = useState(() => {
    let from = new Date();
    from.setHours(0, 0, 0, 0);
    let to = addWeeks(from, 1);
    to.setHours(0, 0, 0, 0);
    let toAfter = new Date(to);
    toAfter.setDate(toAfter.getDate() + 1);
    return {
      from: from,
      fromStr: from.toISOString(),
      to: to,
      status: "x",
      toStr: toAfter.toISOString(),
    };
  });
  const [selection, setSelection] = useState([]);

  const businessID = bmapi.getUserInfo().business.id;

  const byFilters = useCallback(
    (item) => {
      const dateRangeCondition =
        (!filters.fromStr || filters.fromStr <= item.planned_delivery) &&
        (!filters.toStr || filters.toStr > item.planned_delivery);
      const statusCondition =
        filters.status === "x" || item.status === filters.status;
      return dateRangeCondition && statusCondition;
    },
    [filters]
  );

  useEffect(() => {
    bmapi.getBusiness(businessID).then((resp) => {
      setClosingDates(resp.closing_dates || []);
    });
    loadReservationList();
  }, [bmapi, businessID, loadReservationList]);

  useEffect(() => {
    const fr = reservations.filter(byFilters);
    setFilteredReservations(fr);
    setSelection((currentSelection) =>
      currentSelection.filter((sel) => fr.some((res) => res.id === sel.id))
    );
  }, [filters, reservations, byFilters]);

  const handleFilters = useCallback((label) => {
    const updateValue = (val) => {
      let newVal = val;
      if (label === "from" || label === "to") {
        if (val) {
          newVal = new Date(val);
          newVal.setHours(0, 0, 0, 0);
        }
      }
      if (label === "from") {
        let from = null;
        if (val) {
          from = val.toISOString();
        }
        setFilters((v) => ({ ...v, [label]: newVal, fromStr: from }));
      } else if (label === "to") {
        let toAfter = null;
        if (val) {
          toAfter = new Date(newVal);
          toAfter.setDate(toAfter.getDate() + 1);
          toAfter = toAfter.toISOString();
        }
        setFilters((v) => ({ ...v, [label]: newVal, toStr: toAfter }));
      } else {
        setFilters((v) => ({ ...v, [label]: newVal }));
      }
    };

    return (i, f) => {
      if (typeof f === "boolean") updateValue(f);
      else if (i?.target) updateValue(i.target.value);
      else updateValue(i);
    };
  }, []);
  /*
  const handleSelection = (reservationId) => {
    setSelection((currentSelection) => {
      const isAlreadySelected = currentSelection.includes(reservationId);
      if (isAlreadySelected) {
        return currentSelection.filter((id) => id !== reservationId);
      } else {
        return [...currentSelection, reservationId];
      }
    });
  };
  */
  const handleSelection = (reservation) => {
    setSelection((currentSelection) => {
      const selectedIndex = currentSelection.findIndex(
        (selected) => selected.id === reservation.id
      );
      let newSelection = [...currentSelection];
      if (selectedIndex === -1) {
        newSelection.push(reservation);
      } else {
        newSelection.splice(selectedIndex, 1);
      }
      return newSelection;
    });
  };
  /*
  const toggleSelectionAll = (checked) => {
    if (checked) {
      const allIds = reservations.map((r) => r.id);
      setSelection(allIds);
    } else {
      setSelection([]);
    }
  };
  */
  const toggleSelectionAll = (checked) => {
    if (checked) {
      setSelection([...filteredReservations]);
    } else {
      setSelection([]);
    }
  };

  const handleToggleRow = (index) => {
    const newOpenRows = [...openRows];
    newOpenRows[index] = !newOpenRows[index];
    setOpenRows(newOpenRows);
  };

  const handleToggleNotes = (index) => {
    const newOpenNotes = [...openNotes];
    newOpenNotes[index] = !newOpenNotes[index];
    setOpenNotes(newOpenNotes);
  };

  const checkClosingDates = (date) => {
    const currentDateFormatted = format(new Date(date), "yyyyMMdd");

    // Verifica corrispondenze dirette
    const directMatch = closingDates.includes(currentDateFormatted);
    if (directMatch) {
      return true;
    }

    // Verifica corrispondenze nell'intervallo
    const rangeMatch = closingDates.some((d) => {
      if (d.includes("-")) {
        const [start, end] = d.split("-");
        return currentDateFormatted >= start && currentDateFormatted <= end;
      }
      return false;
    });

    return rangeMatch;
  };

  const loadReservationList = useCallback(async () => {
    startLoading();
    try {
      const resp = await bmapi.getEOrder({
        business: bmapi.getUserInfo().business.id,
        decode_user: true,
      });
      stopLoading();
      setReservations(resp || []);
      toggleSelectionAll(false);
    } catch (e) {
      stopLoading();
      notifyError(getErrorMessageString(e, intl));
    }
  }, [bmapi, intl, notifyError, startLoading, stopLoading]);

  const getAttributesMap = (reservation) => {
    let attributesMap = {};
    if (reservation.attrs && Array.isArray(reservation.attrs)) {
      reservation.attrs.forEach((attr) => {
        if (attr.key && attr.value) {
          attributesMap[attr.key] = attr.value;
        }
      });
    }
    return attributesMap;
  };
  const filterStatus = (status) => (item) => item.status === status;

  const confirmReservations = async (reservationsToConfirm) => {
    const ordersStatus = reservationsToConfirm.map((r) => ({
      id: r.id,
      status: 2,
    }));
    try {
      startLoading();
      await bmapi.updateOrdersListStatus(ordersStatus);
      stopLoading();
      hideDialog();
      loadReservationList();
    } catch (e) {
      stopLoading();
      hideDialog();
      notifyError(getErrorMessageString(e, intl));
    }
  };

  const askConfirmReservations = () => {
    const toConfirm = selection.filter(filterStatus(0));
    if (!toConfirm?.length) {
      notifyError(
        intl.formatMessage(ecommerce.noSelectedReservationsToConfirm)
      );
      return;
    }
    notifyDialog({
      text:
        toConfirm.length === 1
          ? intl.formatMessage(ecommerce.askConfirm1Reservation)
          : intl.formatMessage(ecommerce.askConfirmNReservations, {
              numReservations: toConfirm.length,
            }),
      title: intl.formatMessage(common.confirm),
      actions: [
        {
          text: intl.formatMessage(common.proceed),
          variant: "contained",
          color: "primary",
          onClick: () => confirmReservations(toConfirm),
        },
      ],
    });
  };

  const cancelReservations = async (reservationsToConfirm) => {
    const ordersStatus = reservationsToConfirm.map((r) => ({
      id: r.id,
      status: 0,
    }));
    try {
      startLoading();
      await bmapi.updateOrdersListStatus(ordersStatus);
      stopLoading();
      hideDialog();
      loadReservationList();
    } catch (e) {
      stopLoading();
      hideDialog();
      notifyError(getErrorMessageString(e, intl));
    }
  };

  const askCancelReservations = () => {
    const toCancel = selection.filter(filterStatus(2));
    if (!toCancel?.length) {
      notifyError(intl.formatMessage(ecommerce.noSelectedReservationsToCancel));
      return;
    }
    notifyDialog({
      text:
        toCancel.length === 1
          ? intl.formatMessage(ecommerce.askCancel1Reservation)
          : intl.formatMessage(ecommerce.askCancelNReservations, {
              numReservations: toCancel.length,
            }),
      title: intl.formatMessage(common.cancel),
      actions: [
        {
          text: intl.formatMessage(common.proceed),
          variant: "contained",
          color: "primary",
          onClick: () => cancelReservations(toCancel),
        },
      ],
    });
  };

  return (
    <Container maxWidth="lg">
      <Title>{intl.formatMessage(ecommerce.reservationsDone)}</Title>
      <Box display="flex" gap={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={askConfirmReservations}
          className={classes.rightSpacer}
          disabled={!selection?.length}
        >
          {intl.formatMessage(common.confirm)}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={askCancelReservations}
          disabled={!selection?.length}
        >
          {intl.formatMessage(common.cancel)}
        </Button>
      </Box>
      <Box display="flex" flexDirection={smallScreen ? "column" : "row"}>
        <TextField
          margin="dense"
          value={filters.status}
          fullWidth
          onChange={handleFilters("status")}
          label={intl.formatMessage(ecommerce.status)}
          select
          InputLabelProps={{
            shrink: true,
          }}
        >
          {Array.from(RESERVATIONS_STATUS).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              {intl.formatMessage(value)}
            </MenuItem>
          ))}
        </TextField>
        <DatePicker
          margin="dense"
          fullWidth
          onChange={handleFilters("from")}
          label={intl.formatMessage(common.from)}
          //inputFormat="dd/MM/yyyy"
          value={filters.from}
          format="dd/MM/yy"
          renderInput={(params) => <TextField {...params} />}
          clearable
        />
        <DatePicker
          margin="dense"
          fullWidth
          onChange={handleFilters("to")}
          label={intl.formatMessage(common.to)}
          //inputFormat="dd/MM/yyyy"
          value={filters.to}
          format="dd/MM/yy"
          renderInput={(params) => <TextField {...params} />}
          clearable
        />
      </Box>
      {!smallScreen && (
        <Box mt={2}>
          <Card>
            <CardContent>
              <TableContainer>
                <Table padding="none">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        padding="checkbox"
                        className={classes.headerCheckbox}
                      >
                        <Checkbox
                          indeterminate={
                            selection.length > 0 &&
                            selection.length < filteredReservations.length
                          }
                          checked={
                            filteredReservations.length > 0 &&
                            selection.length === filteredReservations.length
                          }
                          onChange={(e) => toggleSelectionAll(e.target.checked)}
                          inputProps={{ "aria-label": "select all desserts" }}
                        />
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell>{intl.formatMessage(common.status)}</TableCell>
                      <TableCell>
                        {intl.formatMessage(common.dateAndTime)}
                      </TableCell>
                      <TableCell>
                        {intl.formatMessage(ecommerce.visitorType)}
                      </TableCell>
                      <TableCell>
                        {intl.formatMessage(ecommerce.visitorGroupName)}
                      </TableCell>
                      <TableCell>
                        {intl.formatMessage(ecommerce.participantsNumber)}
                      </TableCell>
                      <TableCell>
                        {intl.formatMessage(ecommerce.visitLanguage)}
                      </TableCell>
                      <TableCell>{intl.formatMessage(common.mobile)}</TableCell>
                      <TableCell>{intl.formatMessage(common.email)}</TableCell>
                    </TableRow>
                  </TableHead>
                  {filteredReservations.filter(byFilters).map((r, index) => {
                    const attrs = getAttributesMap(r);
                    const _visitorType =
                      RESERVATIONS_VISITOR_TYPES[attrs["group_type"]];
                    let visitorType = "";
                    if (_visitorType) {
                      try {
                        visitorType = intl.formatMessage(_visitorType);
                      } catch (e) {
                        visitorType = _visitorType;
                      }
                    }
                    const _visitLang =
                      RESERVATIONS_VISIT_LANGUAGES[attrs["visit_lang"]];
                    let visitLang = "";
                    if (_visitLang) {
                      try {
                        visitLang = intl.formatMessage(_visitLang);
                      } catch (e) {
                        visitLang = _visitorType;
                      }
                    }
                    const plannedDeliveryDate = new Date(r.planned_delivery);
                    const isPastReservation = plannedDeliveryDate < today;
                    return (
                      <TableBody key={r.id}>
                        <TableRow
                          className={classes.root}
                          style={{
                            background: isPastReservation
                              ? "#f2f2f2"
                              : "inherit",
                          }}
                        >
                          <TableCell
                            padding="checkbox"
                            className={classes.rowCheckbox}
                            align="center"
                          >
                            <Checkbox
                              edge="start"
                              //checked={selection.includes(r.id)}
                              checked={selection.some(
                                (item) => item.id === r.id
                              )}
                              //onChange={() => handleSelection(r.id)}
                              onChange={() => handleSelection(r)}
                              color="primary"
                              /*disabled={
                                new Date() >
                                addWeeks(new Date(r.planned_delivery), 1)
                              }*/
                              disabled={isPastReservation}
                            />
                          </TableCell>
                          <TableCell padding="none">
                            <IconButton
                              hidden={true}
                              display="none"
                              aria-label="expand row"
                              size="small"
                              onClick={() => handleToggleRow(index)}
                              disabled={!r.notes}
                            >
                              {openRows[index] ? (
                                <KeyboardArrowUp />
                              ) : (
                                <KeyboardArrowDown />
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell padding="none">
                            {getStatusLabel(r.status, intl)}
                          </TableCell>
                          <TableCell padding="none">
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              {/*checkClosingDates(r.planned_delivery) && (
                                <Tooltip title="La data della prenotazione coincide con un giorno di chiusura.">
                                  <Warning
                                    fontSize="small"
                                    style={{ marginRight: 5 }}
                                    color="primary"
                                  />
                                </Tooltip>
                              )*/}
                              <Typography>
                                {format(plannedDeliveryDate, "dd/MM/yy HH.mm")}
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell padding="none">{visitorType}</TableCell>
                          <TableCell padding="none">
                            {attrs["group_name"]}
                          </TableCell>
                          <TableCell padding="none">
                            {attrs["group_number"]}
                          </TableCell>
                          <TableCell padding="none">{visitLang}</TableCell>
                          <TableCell padding="none">
                            {r.mobile && (
                              <Link
                                onClick={() =>
                                  window.open("tel:" + r.mobile, "_blank")
                                }
                                style={{
                                  cursor: "pointer",
                                  color: "black",
                                  textDecoration: "underline",
                                }}
                              >
                                {r.mobile}
                              </Link>
                            )}
                          </TableCell>
                          <TableCell padding="none">
                            {r.email && (
                              <Link
                                style={{
                                  color: "black",
                                  textDecoration: "underline",
                                }}
                                href={`mailto:${r.email}`}
                              >
                                {r.email}
                              </Link>
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={9}
                          >
                            <Collapse
                              in={openRows[index]}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box margin={1}>
                                <Typography>{"Note: " + r.notes}</Typography>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })}
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Box>
      )}
      {smallScreen && (
        <Box mt={2}>
          <Card>
            <CardContent>
              <Box display="flex" alignItems="center">
                <Checkbox
                  indeterminate={
                    selection.length > 0 &&
                    selection.length < filteredReservations.length
                  }
                  checked={
                    filteredReservations.length > 0 &&
                    selection.length === filteredReservations.length
                  }
                  onChange={(e) => toggleSelectionAll(e.target.checked)}
                  inputProps={{ "aria-label": "select all desserts" }}
                />
                <Typography>
                  {intl.formatMessage(common.selectDeselectAll)}
                </Typography>
              </Box>
              <List>
                {filteredReservations.filter(byFilters).map((r, index) => {
                  const attrs = getAttributesMap(r);
                  const _visitorType =
                    RESERVATIONS_VISITOR_TYPES[attrs["group_type"]];
                  let visitorType = "";
                  if (_visitorType) {
                    try {
                      visitorType = intl.formatMessage(_visitorType);
                    } catch (e) {
                      visitorType = _visitorType;
                    }
                  }
                  const _visitLang =
                    RESERVATIONS_VISIT_LANGUAGES[attrs["visit_lang"]];
                  let visitLang = "";
                  if (_visitLang) {
                    try {
                      visitLang = intl.formatMessage(_visitLang);
                    } catch (e) {
                      visitLang = _visitorType;
                    }
                  }
                  const plannedDeliveryDate = new Date(r.planned_delivery);
                  const isPastReservation = plannedDeliveryDate < today;
                  return (
                    <React.Fragment key={index}>
                      {index !== 0 && <Divider component="li" />}
                      <ListItem
                        style={{
                          background: isPastReservation ? "#f2f2f2" : "inherit",
                        }}
                      >
                        <ListItemText
                          primary={
                            <React.Fragment>
                              <Box display="flex" alignItems="center">
                                <Checkbox
                                  edge="start"
                                  checked={selection.includes(r.id)}
                                  onChange={() => handleSelection(r.id)}
                                  color="primary"
                                  disabled={isPastReservation}
                                />
                                <Typography style={{ fontWeight: 700 }}>
                                  {checkClosingDates(r.planned_delivery) && (
                                    <Tooltip title="La data della prenotazione coincide con un giorno di chiusura.">
                                      <Warning
                                        style={{ marginRight: 5 }}
                                        color="primary"
                                      />
                                    </Tooltip>
                                  )}
                                  {format(
                                    plannedDeliveryDate,
                                    "EEEE dd MMMM | HH:mm",
                                    {
                                      locale: it,
                                    }
                                  )}
                                </Typography>
                              </Box>
                              <Typography style={{ fontWeight: 700 }}>
                                {intl.formatMessage(common.details)}
                              </Typography>
                              <Typography>{`${intl.formatMessage(
                                ecommerce.status
                              )}: ${getStatusLabel(
                                r.status,
                                intl
                              )}`}</Typography>
                              <Typography>{`${intl.formatMessage(
                                ecommerce.visitorType
                              )}: ${visitorType}`}</Typography>
                              <Typography>{`${intl.formatMessage(
                                ecommerce.visitorGroupName
                              )}: ${attrs["group_name"]}`}</Typography>
                              <Typography>{`${intl.formatMessage(
                                ecommerce.participantsNumber
                              )}: ${attrs["group_number"]}`}</Typography>
                              <Typography>{`${intl.formatMessage(
                                ecommerce.visitLanguage
                              )}: ${visitLang}`}</Typography>
                              <Typography>
                                <Link
                                  onClick={() =>
                                    window.open("tel:" + r.mobile, "_blank")
                                  }
                                  style={{
                                    cursor: "pointer",
                                    color: "black",
                                    textDecoration: "underline",
                                  }}
                                >
                                  {r.mobile}
                                </Link>
                                {" - "}
                                <Link
                                  style={{
                                    color: "black",
                                    textDecoration: "underline",
                                  }}
                                  href={`mailto:${r.email}`}
                                >
                                  {r.email}
                                </Link>
                              </Typography>
                              {r.notes && (
                                <>
                                  <Typography>
                                    <Link
                                      style={{ cursor: "pointer" }}
                                      onClick={() => handleToggleNotes(index)}
                                    >
                                      {!openNotes[index]
                                        ? "Mostra note"
                                        : "Nascondi note"}
                                    </Link>
                                  </Typography>
                                  <Typography>
                                    {openNotes[index] && r.notes}
                                  </Typography>
                                </>
                              )}
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    </React.Fragment>
                  );
                })}
              </List>
            </CardContent>
          </Card>
        </Box>
      )}
    </Container>
  );
}
