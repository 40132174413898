import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { useIntl } from "react-intl";
import QRCodeGenerator from "qrcode";
import { ArrowBack } from "@material-ui/icons";
import { common } from "../../messages";
import { MANAGER_ROUTES } from "../../utils/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function Operator() {
  const { bmapi, startLoading, stopLoading, notifyError } = useBmapi();
  const intl = useIntl();
  const history = useHistory();
  const [business, setBusiness] = React.useState(null);
  const [code, setCode] = useState(false);

  const bsInfo = bmapi.getUserInfo().business;

  const goToHome = () => {
    history.push(MANAGER_ROUTES.HOME_MENU);
  };

  const handleShowQR = (businessId) => {
    const qrCodeUrl = "MERCHANT_" + businessId;

    QRCodeGenerator.toDataURL(qrCodeUrl, { scale: 16 })
      .then(setCode)
      .catch((e) => notifyError(getErrorMessageString(e, intl)));
  };

  useEffect(() => {
    startLoading();
    if (bmapi) {
      bmapi
        .getBusiness(bsInfo.id)
        .then((resp) => {
          setBusiness(resp);
          handleShowQR(resp?.id);
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="sm">
      {business && (
        <React.Fragment>
          <Card>
            <CardContent>
              <Typography variant="h6">{"MERCHANT_" + business?.id}</Typography>
              <img
                src={code}
                alt="QR Code"
                style={{ maxWidth: "50%", display: "block", margin: "0 auto" }}
              />
              <Box mt={2}>
                <Typography variant="h6">{business.name}</Typography>
                <ListItem>
                  <ListItemText
                    primary={[
                      {
                        label: "Email aziendale",
                        content: business.manager_email || "-",
                      },
                      {
                        label: "Numero di telefono:",
                        content: business.mobile || "-",
                      },
                      {
                        label: "Cellulare Whatsapp:",
                        content: business.whatsapp_phone || "-",
                      },
                      {
                        label: "Province di competenza:",
                        content:
                          business.competence_locations?.join(", ") || "-",
                      },
                      {
                        label: "Indirizzo:",
                        content:
                          [
                            business.location.street,
                            business.location.zip,
                            business.location.city,
                            business.location.province,
                          ]?.join(", ") || "-",
                      },
                    ].map((el) => (
                      <Grid key={el.label} container spacing={2}>
                        <Grid item sm={5}>
                          {el.label}
                        </Grid>
                        <Grid item sm={7}>
                          {el.content}
                        </Grid>
                      </Grid>
                    ))}
                  />
                </ListItem>
              </Box>
            </CardContent>
          </Card>
          <Box my={2}>
            <Button onClick={goToHome} startIcon={<ArrowBack />}>
              {intl.formatMessage(common.backHome)}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Container>
  );
}
