import React from "react";
import { useIntl } from "react-intl";

import { DatePicker } from "../../DatePicker";

import { CAMPAIGNS_LABELS, PRODUCT_SUBTYPES } from "../../../utils/constants";
import { form } from "../../../messages";
import { addDays, addMonths } from "date-fns";
import { parseBmarkenDate } from "../../../utils/utils";

export default function ExpirationDate({
  values,
  handleChange,
  productType,
  campaigns,
  campaign,
  performance,
}) {
  const intl = useIntl();

  const getExpirationLabel = () => {
    if (values.subtype === PRODUCT_SUBTYPES.MULTIWIN_COUPON) {
      return "Scadenza campagna";
    } else {
      return intl.formatMessage(
        productType === CAMPAIGNS_LABELS.PASS
          ? form.expirationDatePass
          : form.expirationDate
      );
    }
  };

  const getMinDate = () => {
    if (performance?.issued_qty > 0) {
      return campaign.expiration_date;
    } else {
      return values.subtype === PRODUCT_SUBTYPES.MULTIWIN_COUPON
        ? addDays(values.start_date, 1)
        : values.start_date;
    }
    /*
    if (
      values.subtype === PRODUCT_SUBTYPES.MULTIWIN_COUPON &&
      performance?.issued_qty > 0
    ) {
      return campaign.expiration_date;
    } else {
      return performance?.issued_qty > 0
        ? campaign.expiration_date
        : values.start_date;
    }*/
  };

  const getMaxDate = () => {
    return values.subtype === PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE &&
      values.main_event_id &&
      campaigns
      ? campaigns.find((c) => c.campaign_id === values.main_event_id)
          .expiration_date
      : new Date("2100-01-01");
  };

  const onChange = (val) => {
    handleChange("expiration_date")(val);
    if (
      (values.product_limit_date && values.product_limit_date < val) ||
      values.subtype === PRODUCT_SUBTYPES.EVENT_PASS_SIMPLE
    ) {
      handleChange("product_limit_date")(val);
    }
  };

  const onModify = (val) => {
    const newLastDatePossible = addDays(
      values.product_limit_date || values.product_lifespan_type !== "D"
        ? addMonths(val, values.product_lifespan)
        : addDays(val, values.product_lifespan),
      values.grace_period
    );
    if (values.product_limit_date && val > newLastDatePossible) {
      alert(
        "Stai estendendo la distribuzione oltre la data di scadenza della carta"
      );
    } else {
      checkPrizes(newLastDatePossible, val);
    }
  };

  const checkPrizes = (lastDate, val) => {
    let prizesToModify = [];
    for (let i = 0; i < values.prizes.length; i++) {
      const prize = campaigns.find(
        (c) => values.prizes[i].prize_id === c.campaign_id
      );
      const prizeExp = addDays(
        addMonths(
          parseBmarkenDate(prize.campaign_data.expiration_date),
          prize.campaign_data.product_lifespan
        ),
        prize.campaign_data.grace_period
      );
      if (lastDate > prizeExp) {
        prizesToModify.push(values.prizes[i].name);
      }
    }
    if (prizesToModify.length === 0) {
      onChange(val);
    } else {
      alert(
        `Per estendere la durata di questa campagna è necessario posticipare la data di scadenza dei premi: ${prizesToModify.join(
          ", "
        )}`
      );
    }
  };

  return (
    <DatePicker
      label={getExpirationLabel()}
      value={values.expiration_date}
      onChange={performance?.issued_qty > 0 ? onModify : onChange}
      //onChange={onChange}
      key="expiration_date"
      fullWidth
      autoOk
      minDate={getMinDate()}
      //minDate={values.start_date}
      minDateMessage={intl.formatMessage(form.endDateError)}
      maxDate={getMaxDate()}
      required
      clearable
      //disabled={!!campaign}
    />
  );
}
