import React, { useEffect } from "react";

export default function GltfViewer({ url, altText = "" }) {
  useEffect(() => {
    if (window.model3d) {
      return;
    }
    const script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("type", "module");
    script.src = `https://unpkg.com/@google/model-viewer/dist/model-viewer.min.js`;
    document.querySelector("head").appendChild(script);
    //  document.body.appendChild(script);
    window.model3d = true;
  }, []);

  return (
    <model-viewer
      alt={altText}
      src={url}
      ar
      ar-modes="webxr scene-viewer quick-look"
      seamless-poster
      shadow-intensity="1"
      camera-controls
      enable-pan
    ></model-viewer>
  );
}
