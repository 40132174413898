import React, { useState, useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import distance from "gps-distance";

import { Container } from "@material-ui/core";

import { navigation } from "../../messages";
import QrScanner from "../../ui/QrScanner";
import Title from "../../ui/Title";
import { useBmapi } from "../../utils/bmapi-context";
import {
  CONSUMER_ROUTES,
  EVENT_PREFIX,
  LOCATION_PREFIX,
  UUID_REGEX_PREFIX,
} from "../../utils/constants";
import { getErrorMessageString } from "../../utils/errors";
import Checkins from "../../components/Checkins";
import useDocumentTitle from "../../utils/documentTitle";

export default function Scanner() {
  const intl = useIntl();
  const history = useHistory();
  const { bmapi, notifyError, notifySuccess, stopLoading } = useBmapi();
  const [qrCode, setQrCode] = useState(null);
  const [enabled, setEnabled] = useState(true);
  useDocumentTitle(intl.formatMessage(navigation.checkin));

  const onError = useCallback(
    (error) => {
      stopLoading();
      notifyError(error);
      history.push(CONSUMER_ROUTES.HOME);
    },
    [history, notifyError, stopLoading]
  );

  const sendCode = useCallback(
    (code) => {
      function completeLocationCheckin(uuid) {
        return bmapi.checkinLocation(uuid).then(() => {
          notifySuccess(
            intl.formatMessage({
              id: "common.checkinDone",
              defaultMessage: "Checkin effettuato",
            })
          );
          history.push(CONSUMER_ROUTES.HOME);
        });
      }

      setEnabled(false);

      if (code && UUID_REGEX_PREFIX.test(code)) {
        const [, prefix, uuid] = UUID_REGEX_PREFIX.exec(code);

        if (prefix === LOCATION_PREFIX) {
          bmapi
            .getLocation(uuid)
            .then((loc) => {
              function success(position) {
                if (
                  distance(
                    position.coords.latitude,
                    position.coords.longitude,
                    loc.lat,
                    loc.lng
                  ) < 0.2 // Km
                ) {
                  completeLocationCheckin(uuid);
                } else {
                  onError(
                    getErrorMessageString(
                      "Devi trovarti in sede per poter effettuare il checkin",
                      intl
                    )
                  );
                  history.push(CONSUMER_ROUTES.HOME);
                }
              }

              if (loc.lat || loc.lng) {
                if (!navigator.geolocation) {
                  throw "Geolocation is not supported by your browser";
                } else {
                  navigator.geolocation.getCurrentPosition(success, (e) =>
                    onError(getErrorMessageString(e, intl))
                  );
                }
              } else {
                completeLocationCheckin(uuid);
              }
            })
            .catch((e) => onError(getErrorMessageString(e, intl)));
        } else if (prefix === EVENT_PREFIX) {
          bmapi
            .checkinEvent(uuid)
            .then(() => {
              notifySuccess(
                intl.formatMessage({
                  id: "common.checkinDone",
                  defaultMessage: "Checkin effettuato",
                })
              );
              history.push(CONSUMER_ROUTES.HOME);
            })
            .catch((e) => onError(getErrorMessageString(e, intl)));
        }
      } else {
        onError(
          intl.formatMessage({
            id: "pages.scanner.invalidQrCode",
            defaultMessage: "Codice scannerizzato non valido",
          })
        );
      }
    },
    [bmapi, history, intl, notifySuccess, onError]
  );

  useEffect(() => {
    if (qrCode) sendCode(qrCode);
  }, [qrCode, sendCode]);

  return (
    <Container maxWidth="sm">
      <Title>{intl.formatMessage(navigation.checkin)}</Title>
      <Checkins />
      {enabled && (
        <QrScanner handleCode={setQrCode} handleError={notifyError} />
      )}
    </Container>
  );
}
