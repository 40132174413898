export const EVENTS = {
  ISSUE: "Emissione",
  ISSUE_WL: "Emissione da lista d'attesa",
  EXP_REMIND: "Sollecito",
  EXP_EXTENSION: "Prolungamento scadenza",
};

export const notificationsTypes = [
  {
    type: "",
    title: "No Type, Sorry",
    enabled: false,
  },
  {
    type: "CREATOR",
    title: "No Type, Sorry",
    enabled: false,
  },
  {
    type: "ISSUE",
    title: "Emissione",
    enabled: false,
  },
  {
    type: "ISSUE_WL",
    title: "Emissione da lista d'attesa",
    enabled: false,
  },
  {
    type: "EXP_REMIND",
    title: "Sollecito",
    enabled: false,
  },
  {
    type: "EXP_EXTENSION",
    title: "Prolungamento scadenza",
    enabled: false,
  },
];

export const initCreator = {
  show: false,
  title: "Nuova notifica",
  event_type: "CREATOR",
  active: false,
  in_app_active: false,
  in_app_title: "",
  in_app_body: "",
  banner_active: false,
  banner_title: "",
  banner_body: "",
  push_active: false,
  push_title: "",
  push_body: "",
  push_image_url: "",
  email_active: false,
  email_subject: "",
  email_body: "",
  email_from: "",
  remind_days: 0,
  notificationsTypes: notificationsTypes,
};
