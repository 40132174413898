import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Collapse,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import FloatingActions, { Action } from "../../ui/FloatingActions";
import {
  Add,
  Clear,
  ControlPointDuplicate,
  Create,
  Delete,
  FormatListBulleted,
  GetApp,
  MoreVert,
} from "@material-ui/icons";
import Title from "../../ui/Title";
import { FormattedMessage, useIntl } from "react-intl";
import styles from "../../utils/styles";
import {
  FILTER_GENDER,
  LISTS,
  LISTS_FILTER,
} from "../../utils/distributionListsConstants";
import { MANAGER_ROUTES } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { common, form, navigation } from "../../messages";
import { getLabelFromArrObj, intlFormatMessage } from "../../utils/utils";
import { distributionLists } from "../../messages/distributionLists";

function CreateListButton({ trigger }) {
  const history = useHistory();
  const intl = useIntl();
  const [anchorMenu, setAnchorMenu] = useState(null);

  const handleClick = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorMenu(null);
  };

  const create = (type) => {
    handleClose();
    history.replace(
      MANAGER_ROUTES.DISTRIBUTION_LIST_CREATE.replace(":subtype?/", type)
    );
  };

  return (
    <React.Fragment>
      {trigger(handleClick)}
      <Drawer anchor="bottom" open={Boolean(anchorMenu)} onClose={handleClose}>
        <List>
          {LISTS.map((l) => (
            <MenuItem key={l.label} onClick={() => create(l.type)}>
              <ListItemIcon>{l.icon}</ListItemIcon>
              <ListItemText
                primary={intlFormatMessage(
                  distributionLists[l.label],
                  l.label,
                  intl
                )}
              />
            </MenuItem>
          ))}
        </List>
      </Drawer>
    </React.Fragment>
  );
}
function DistributionListActions({
  edge,
  hideInfo,
  showInfo,
  visibleInfo,
  trigger,
  list,
  setSelectedList,
}) {
  const intl = useIntl();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);

  const goTo = (route, id) => () => history.push(route.replace(":listId", id));

  const actions = [
    {
      action: visibleInfo ? hideInfo : showInfo,
      Icon: visibleInfo ? Clear : FormatListBulleted,
      label: intl.formatMessage(form.details),
    },
    {
      action: goTo(MANAGER_ROUTES.DISTRIBUTION_LIST_EDIT, list.id),
      Icon: Create,
      label: intl.formatMessage(common.modify),
    },
    {
      Icon: ControlPointDuplicate,
      label: intl.formatMessage(common.clone),
    },
    {
      Icon: Delete,
      label: intl.formatMessage(common.delete),
    },
    {
      Icon: GetApp,
      label: intl.formatMessage(distributionLists.csvExport),
    },
  ];

  const handleOpen = (e) => {
    const target = e.currentTarget;
    setAnchorEl(target);
  };

  return (
    <span style={{ display: "inline-block", position: "relative" }}>
      <React.Fragment>
        {trigger ? (
          trigger(handleOpen)
        ) : (
          <IconButton onClick={handleOpen} edge={edge}>
            <MoreVert />
          </IconButton>
        )}
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {actions.map((action) => (
            <MenuItem
              key={action.label}
              onClick={() => {
                setAnchorEl(null);
                setSelectedList(list);
                action.action();
              }}
            >
              {!!action.render && action.render()}
              <ListItemIcon>
                <action.Icon fontSize="small" />
              </ListItemIcon>
              <Typography variant="inherit">{action.label}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    </span>
  );
}

function ListElement({ list }) {
  const intl = useIntl();
  const [selectedList, setSelectedList] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const getListChip = (l) => {
    const distributionList = l.dynamic
      ? distributionLists.dynamic
      : distributionLists.static;
    return intlFormatMessage(
      distributionList,
      l.dynamic ? "dynamic" : "static",
      intl
    );
  };

  function handleCloseClick() {
    setExpanded(false);
  }

  function handleExpandClick() {
    setExpanded(true);
  }

  const showDetails = (l) => {
    if (selectedList?.id === l.id) {
      const Expand = selectedList.dynamic
        ? ExpandDynamicList
        : ExpandStaticList;
      return <Expand list={selectedList} />;
    }
  };

  return (
    <React.Fragment>
      <ListItem>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography variant="caption" display="block" color="primary">
                {getListChip(list)}
              </Typography>
              <Typography display="block" gutterBottom>
                {list.name}
                {/*  <Chip
                  component="span"
                  label={getListChip(list)}
                  size="small"
                  className={classes.listChip}
                />*/}
              </Typography>
            </React.Fragment>
          }
          secondary={
            list.static_list
              ? intl.formatMessage(distributionLists.usersCount, {
                  num: list.static_list?.length,
                })
              : null
          }
        />
        <ListItemSecondaryAction>
          <DistributionListActions
            setSelectedList={setSelectedList}
            list={list}
            hideInfo={handleCloseClick}
            showInfo={handleExpandClick}
            edge="end"
            visibleInfo={expanded}
          />
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        {showDetails(list)}
      </Collapse>
    </React.Fragment>
  );
}

function ExpandStaticList({ list }) {
  return (
    <CardContent>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome e cognome</TableCell>
              <TableCell>Email</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list?.static_list?.map((u) => (
              <TableRow key={u.id}>
                <TableCell component="th" scope="row">
                  {u.first_name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {u.email}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CardContent>
  );
}

function ExpandDynamicList({ list }) {
  const intl = useIntl();

  const classes = styles.useStyles();

  const getGenderLabel = (value) => {
    const genderLabel = getLabelFromArrObj(
      value,
      FILTER_GENDER,
      "value",
      "label"
    );
    return intlFormatMessage(common[genderLabel], genderLabel, intl);
  };

  return (
    <List component="div" disablePadding>
      <ListItem key={list.id}>
        <ListItemText
          classes={{ inset: classes.listItemInset }}
          inset
          primary={
            <Box>
              <Typography gutterBottom>N utenti</Typography>
              <Divider component="li" />
              <Box mt={2}>
                {list?.gender && (
                  <Typography gutterBottom>
                    {intl.formatMessage(common.gender)}
                    <Chip
                      component="span"
                      label={getGenderLabel(list.gender)}
                      size="medium"
                      className={classes.listChipFilter}
                    />
                  </Typography>
                )}
                {list?.consents && (
                  <Typography gutterBottom>
                    {intl.formatMessage(common.consents)}
                    {list.consents.map((c) => (
                      <Chip
                        key={c.id}
                        component="span"
                        label={
                          c.value === "true"
                            ? `${c.name} ${intl.formatMessage(common.yes)}`
                            : `${c.name} ${intl.formatMessage(common.no)}`
                        }
                        size="medium"
                        className={classes.listChipFilter}
                      />
                    ))}
                  </Typography>
                )}
                <Typography gutterBottom>
                  {intl.formatMessage(common.age)}
                  <Chip
                    component="span"
                    label={`${list.age_from} - ${list.age_to}`}
                    size="medium"
                    className={classes.listChipFilter}
                  />
                </Typography>
              </Box>
            </Box>
          }
        />
      </ListItem>
    </List>
  );
}

export default function DistributionLists() {
  const intl = useIntl();
  const { bmapi, notifyError } = useBmapi();
  const [lists, setLists] = useState([]);
  const [anchorFilter, setAnchorFilter] = useState(null);
  const [currentFilter, setCurrentFilter] = useState("all");

  const byFilter = (l) => {
    switch (currentFilter) {
      case "all":
        return l;
      case "static":
        return !l.dynamic;
      case "dynamic":
        return l.dynamic;
      default:
        return l;
    }
  };

  const loadLists = useCallback(() => {
    if (bmapi) {
      bmapi
        .getDistributionLists()
        .then((resp) => {
          if (!resp) {
            resp = [];
            setLists(resp);
          } else {
            setLists(resp);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    }
  }, [bmapi, intl, notifyError]);

  useEffect(() => {
    loadLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadLists]);

  const selectFilter = (type) => {
    setCurrentFilter(type);
    setAnchorFilter(null);
  };

  return (
    <Container maxWidth="sm">
      <Title>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box>{intl.formatMessage(navigation.distributionList)}</Box>
          {lists.length > 0 && (
            <Button
              color="secondary"
              onClick={(event) => setAnchorFilter(event.currentTarget)}
            >
              <FormattedMessage id="common.filter" defaultMessage="Filtro" />
            </Button>
          )}
          <Menu
            anchorEl={anchorFilter}
            keepMounted={false}
            open={Boolean(anchorFilter)}
            onClose={() => setAnchorFilter(null)}
          >
            {LISTS_FILTER?.map((option) => (
              <MenuItem
                key={option.type}
                onClick={() => selectFilter(option.type)}
                selected={option.type === currentFilter}
              >
                {intlFormatMessage(
                  distributionLists[option.label],
                  option.label,
                  intl
                )}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Title>
      <Card>
        <List>
          {lists
            .filter((l) => byFilter(l))
            .map((l, i) => (
              <React.Fragment key={l.id}>
                {i !== 0 && <Divider component="li" />}
                <ListElement list={l} />
              </React.Fragment>
            ))}
        </List>
      </Card>

      <FloatingActions>
        <CreateListButton
          trigger={(onClick) => (
            <Action
              icon={<Add />}
              label={intl.formatMessage(distributionLists.createList)}
              action={onClick}
            />
          )}
        />
      </FloatingActions>
    </Container>
  );
}
