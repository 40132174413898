import { useEffect, useState } from "react";

import { useBmapi } from "./bmapi-context";

export default function useDocumentTitle(newTitle) {
  const [title, setTitle] = useState(newTitle);
  const { bmapi } = useBmapi();

  useEffect(() => {
    if (title) document.title = `${title} • ${bmapi.title}`;
  }, [bmapi, title]);

  useEffect(() => {
    return () => {
      document.title = bmapi.title;
    };
  }, [bmapi.title]);

  return setTitle;
}
