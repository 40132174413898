import { defineMessages } from "react-intl";

export const employeeAccess = defineMessages({
  access: {
    id: "employeeAccess.access",
    defaultMessage: "Autor.",
  },
  accessDate: {
    id: "employeeAccess.accessDate",
    defaultMessage: "Data autor.",
  },
  accessExpired: {
    id: "employeeAccess.accessExpired",
    defaultMessage: "Attenzione! L'autorizzazione è scaduta.",
  },
  confirmRequest: {
    id: "employeeAccess.confirmRequest",
    defaultMessage: "Confermi di voler inviare la richiesta di autorizzazione?",
  },
  confirmDeleteAccess: {
    id: "employeeAccess.confirmDeleteAccess",
    defaultMessage:
      "L’abilitazione per l’accesso al Centro Commerciale è stata data il {date}. Per disabilitare l’accesso premi Disabilita",
  },
  deleteAuthorization: {
    id: "employeeAccess.deleteAuthorization",
    defaultMessage:
      "Per rimuovere una autorizzazione prima della scadenza procedere nello stesso modo o in alternativa eliminarla direttamente selezionando la riga o le righe da eliminare e premendo il pulsante 'Elimina selezionati'.",
  },
  deleteAuthorizationMerchant: {
    id: "employeeAccess.deleteAuthorizationMerchant",
    defaultMessage:
      "Per rimuovere una autorizzazione prima della scadenza procedere nello stesso modo o in alternativa eliminarla direttamente selezionando la riga o le righe da eliminare e premendo il pulsante 'Elimina selezionati' (se la si riconosce dalla data di autorizzazione/scadenza).",
  },
  durationMonths: {
    id: "employeeAccess.durationMonths",
    defaultMessage: "Durata (mesi)",
  },
  enableSelected: {
    id: "employeeAccess.enableSelected",
    defaultMessage: "Autorizza selezionati",
  },
  enableTot: {
    id: "employeeAccess.enableTot",
    defaultMessage: "Tot. autorizzati: {tot}",
  },
  employeeAccessAuthorization: {
    id: "employeeAccess.employeeAccessAuthorization",
    defaultMessage:
      "Autorizzazione accesso dipendenti - Totale autorizzazioni: {tot}",
  },
  errorBusiness: {
    id: "employeeAccess.errorBusiness",
    defaultMessage:
      "Per abilitare il dipendente devi selezionare il punto vendita.",
  },
  errorUser: {
    id: "employeeAccess.errorUser",
    defaultMessage: "Il QR Code letto non corrisponde a nessun utente.",
  },
  expDate: {
    id: "employeeAccess.expDate",
    defaultMessage: "Data scad.",
  },
  expDateWarning: {
    id: "employeeAccess.expDateWarning",
    defaultMessage:
      "Attenzione! Mancano {days} giorni alla scadenza dell'autorizzazione.",
  },
  insertAuthorization: {
    id: "employeeAccess.insertAuthorization",
    defaultMessage:
      "Per inserire una nuova autorizzazione scansionare il QR che il dipendente dovrà mostrare dal suo Profilo Utente (click su 'Mostra il tuo QR Code' in fondo alla pagina).",
  },
  monthlyPeriod: {
    id: "employeeAccess.monthlyPeriod",
    defaultMessage: "Durata mensile",
  },
  noExpDate: {
    id: "employeeAccess.noExpDate",
    defaultMessage: "Nessuna scadenza",
  },
  noRegistrations: {
    id: "employeeAccess.noRegistrations",
    defaultMessage: "Nessuna registrazione da mostrare.",
  },
  toAuthorizeOnly: {
    id: "employeeAccess.toAuthorizeOnly",
    defaultMessage: "Mostra solo da autorizzare",
  },
});
