import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";

import { Box, Button, Container } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";

import { useBmapi } from "../utils/bmapi-context";
import useDocumentTitle from "../utils/documentTitle";

export default function Content({ content: cont }) {
  const { bmapi } = useBmapi();
  const { content = cont } = useParams();
  const history = useHistory();
  const [module, setModule] = useState(false);
  const intl = useIntl();
  const setTitle = useDocumentTitle();

  useEffect(() => {
    const lang = intl.locale.slice(0, 2);

    [
      `${bmapi.tenant}/${bmapi.subscriptionSlug}/${content}.${lang}`,
      `${bmapi.tenant}/${bmapi.subscriptionSlug}/${content}`,
      `${bmapi.tenant}/${content}.${lang}`,
      `${bmapi.tenant}/${content}`,
      `${content}.${lang}`,
      `${content}`,
    ]
      .reduce(
        (p, x) => p.catch(() => import(`./contents/${x}`)),
        Promise.reject()
      )
      .then(setModule);
  }, [bmapi, content, intl.defaultLocale, intl.locale]);

  useEffect(() => {
    module && module.title && setTitle(module.title);
  }, [module, setTitle]);

  return module ? (
    <Container maxWidth={module?.size || "md"}>
      {module.default()}
      <Box my={2}>
        {window.history.length > 1 ? (
          <Button onClick={history.goBack} startIcon={<ArrowBack />}>
            <FormattedMessage id="common.back" defaultMessage="Indietro" />
          </Button>
        ) : (
          <Button onClick={window.close}>
            <FormattedMessage id="common.close" defaultMessage="Chiudi" />
          </Button>
        )}
      </Box>
    </Container>
  ) : (
    <React.Fragment />
  );
}
