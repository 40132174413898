import React from "react";
import { useIntl } from "react-intl";

import {
  Accordion,
  AccordionSummary,
  CardContent,
  Typography,
} from "@material-ui/core";
import { Event, ExpandMore } from "@material-ui/icons";

import { ProductCard } from "../../pages/consumer/Campaign";
import { useBmapi } from "../../utils/bmapi-context";

export default function CardCreditiAncot() {
  const intl = useIntl();
  const { bmapi } = useBmapi();

  return (
    <>
      <Accordion expanded={false}>
        <CardContent>
          <Typography variant="h6">Situazione crediti formativi</Typography>
        </CardContent>
      </Accordion>
      {(bmapi.userInfo.Courses || [])
        .sort((a, b) => b.Info.Year - a.Info.Year)
        .map((course) => (
          <Accordion key={course.Info.Year}>
            <AccordionSummary expandIcon={<ExpandMore />}>
              <Typography>
                {course.Info.Year}. Crediti {course.Info.CreditsAccumulated}/
                {course.Info.CreditsGoal}
              </Typography>
            </AccordionSummary>
            {(course.Events || []).map((event, i) => (
              <ProductCard
                key={i}
                title={event.Name}
                subtitle={`${intl.formatDate(new Date(event.Date))} | ${
                  event.Credits
                } crediti`}
                Icon={Event}
              />
            ))}
          </Accordion>
        ))}
    </>
  );
}
