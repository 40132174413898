import React from "react";
import { Container } from "@material-ui/core";
import QrReader from "react-qr-reader";
import { useBmapi } from "../../utils/bmapi-context";
import { useState } from "react";
import { getErrorMessageString } from "../../utils/errors";
import { useIntl } from "react-intl";

export const UUID_REGEX_PREFIX = /(.*)([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})$/i;

export function getIDsFromCode(code, prefix, idQty) {
  const prefixIndex = code.indexOf(prefix);
  if (prefixIndex < 0) {
    return null;
  }

  let ids = [];
  code = code.substring(prefix.length);
  for (let i = 0; i < idQty; i++) {
    const id = code.substring(0, 36);
    if (!UUID_REGEX_PREFIX.test(id)) {
      return null;
    }

    ids.push(id);
    code = code.substring(36);
    if (code.length > 0) {
      code = code.substring(1); //rimuovo il carattere di separazione
    }
  }

  return ids;
}

export default function ScannerGdsp() {
  const { bmapi, notifyError } = useBmapi();
  const intl = useIntl();
  const [couponOwner, setCouponOwner] = useState(null);
  const [activeView, setActiveView] = useState("main");
  const [qrcode, setQRCode] = useState("");
  const [action, setAction] = useState(null);
  const [user, setUser] = useState(null);
  const [couponInfo, setCouponInfo] = useState(null);
  const [fair, setFair] = useState(null);
  const bsInfo = bmapi.getUserInfo();

  const checkCodeType = (code) => {
    if (code.indexOf("confirm:") !== -1) {
      //si tratta di un codice per confermare un'interazione. ritorno il codice pulito
      return 1;
    } else if (code.indexOf("fair:") !== -1) {
      //si tratta di un qrcode di una fiera
      return 2;
    } else if (code.indexOf("ACCESS_FAIR_") !== -1) {
      return 3;
    } else {
      //si tratta di un prodotto oppure non è valido
      return 0;
    }
  };

  const askConfirmation = (code, type, userCode) => {
    // code: arriva come id senza prefisso
    setQRCode(code);
    if (type === 1) {
      bmapi
        .getActionByQRCode({ code: code })
        .then((ra) => {
          setAction(ra);
          setActiveView("confirmAction");
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    } else if (type === 2) {
      bmapi
        .getFairByQRCode({ code: code })
        .then((f) => {
          setFair(f);
          setActiveView("confirmFair");
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    } else if (type === 3) {
      bmapi
        .getContent({ content: code })
        .then((f) => {
          setFair(f);
          return bmapi.getUser(userCode);
        })
        .then((u) => {
          setUser(u);
          setActiveView("confirmEntry");
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        });
    } else {
      bmapi
        .getProductInfo(code, bsInfo.business)
        .then((c) => {
          setCouponInfo(c);
          return bmapi.makeGetRequest(c.owner_link.url); // get generica con url
        })
        .then((owner) => {
          setCouponOwner(owner);
          setActiveView("confirmBurnCoupon");
        })
        .catch((e) => {
          if (e.message == "Entry not found in database") {
            notifyError("USEPERMISSION_WRONG_MERCHANT");
          }
          notifyError(getErrorMessageString(e, intl));
        });
    }
  };

  const handleScan = (code) => {
    if (code) {
      //check code type
      const codeType = checkCodeType(code);
      let userCode = null;
      let error = false;

      if (
        codeType === 0 &&
        (!UUID_REGEX_PREFIX.test(code) || bsInfo.business_type !== "Merchant")
      ) {
        //errore
        error = true;
      } else if (codeType === 1) {
        //azione da confermare
        code = code.substring(8);
        if (
          !UUID_REGEX_PREFIX.test(code) ||
          bsInfo.business_type !== "Merchant"
        ) {
          error = true;
        }
      } else if (codeType === 2) {
        //fiera da confermare
        code = code.substring(5);
        if (
          !UUID_REGEX_PREFIX.test(code) ||
          bsInfo.business_type !== "Merchant"
        ) {
          error = true;
        }
      } else if (codeType === 3) {
        const ids = getIDsFromCode(code, "ACCESS_FAIR_", 2);
        if (ids === null || bsInfo.business_type !== "Fair") {
          error = true;
        }
        userCode = ids[1];
        code = ids[0];
      } else {
        //prodotto da utilizzare
      }

      if (error) {
        //errore codice scannerizzato non è un id valido
        alert("Codice scannerizzato non valido");
        return;
      }

      askConfirmation(code, codeType, userCode);
    }
  };

  const handleError = () => {
    alert(
      "Scanner in errore. Controllare che il browser in uso non stia bloccando la fotocamera e ritentare"
    );
  };

  console.log(couponOwner, activeView, qrcode, action, user, couponInfo, fair);

  return (
    <Container maxWidth="sm">
      <QrReader
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: "100%" }}
      />
    </Container>
  );
}
