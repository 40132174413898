import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { differenceInYears, format } from "date-fns";

import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Typography,
} from "@material-ui/core";

import { extraProfileFields, getFormatter } from "./Account";
import { activation, common, errors } from "../messages";
import {
  DirectMarketing,
  IndirectMarketing,
  InvitedBy,
  Privacy,
  Rules,
} from "../ui/forms/profile";
import Title from "../ui/Title";
import { useBmapi } from "../utils/bmapi-context";
import { FEATURES } from "../utils/constants";
import { getErrorMessageString } from "../utils/errors";
import styles from "../utils/styles";
import {
  compareByOrder,
  getSignInSubscriptionData,
  refreshPage,
} from "../utils/utils";
import { ConsentCheck } from "../ui/forms/profile/ConsentCheck";
import {
  checkConsents,
  getExpressionsObject,
  hasConsentAgreedByType,
} from "../utils/activationUtils";

export default function Activation() {
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const intl = useIntl();
  const classes = styles.useStyles();
  const [values, setValues] = useState({
    direct_marketing:
      bmapi.settings.profileRequired.includes("direct_marketing") && null,
    indirect_marketing:
      bmapi.settings.profileRequired.includes("indirect_marketing") && null,
    invited_by:
      bmapi.userData?.invited_by || bmapi.getTenantData().friendCode || "",
    mobile: bmapi.userData?.mobile || "",
    privacy: !bmapi.settings.profileRequired.includes("privacy") || null,
    rules: !bmapi.settings.profileRequired.includes("rules") || null,
    gender: bmapi.userData?.gender || "",
    name: bmapi.userData?.complete_name || "",
    last_name: bmapi.userData?.last_name || "",
    birthday: bmapi.userData?.birthday
      ? new Date(bmapi.userData?.birthday)
      : null,
  });
  const [subtitle, setSubtitle] = useState(false);
  const [consents, setConsents] = useState(false);
  const [consentExpressions, setConsentExpressions] = useState({});
  const [canProceed, setCanProceed] = useState(true);

  const handleChange = (key) => {
    const updateValue = (val) => setValues((vs) => ({ ...vs, [key]: val }));

    return (i, f) => {
      if (typeof f === "boolean") updateValue(f);
      else if (typeof f === "string") updateValue(JSON.parse(f));
      else if (i?.target) updateValue(i.target.value);
      else updateValue(i);
    };
  };

  const handleConsentCheck = (consentId, expr) => {
    setConsentExpressions((v) => ({ ...v, [consentId]: expr }));
  };

  const accept = useCallback(
    async (e) => {
      e.preventDefault();

      const dynamicConsents = bmapi.settings?.dynamicConsents;

      if (bmapi.settings.profileRequired.includes("birthday")) {
        if (!values.birthday) {
          return notifyError(intl.formatMessage(activation.birthdayError));
        }
        if (
          bmapi.settings.minAge >=
          differenceInYears(new Date(), values.birthday)
        ) {
          return notifyError(
            intl.formatMessage(activation.birthdayMinAge, {
              minAge: bmapi.settings.minAge,
            })
          );
        }
      }
      if (!dynamicConsents) {
        if (!values.rules) {
          return notifyError(intl.formatMessage(activation.acceptTosError));
        }
        if (!values.privacy) {
          return notifyError(intl.formatMessage(activation.privacyError));
        }
      } else {
        if (!checkConsents(consents, consentExpressions))
          return notifyError(intl.formatMessage(activation.agreeAllConsents));
      }

      if (typeof bmapi.getUserData().firstLogin !== "boolean") {
        bmapi.setUserData({ firstLogin: true });
      }

      startLoading();

      try {
        let rules = values.rules;
        let privacy = values.privacy;
        if (dynamicConsents) {
          rules = hasConsentAgreedByType(
            consents,
            consentExpressions,
            "SERVICE-RULES"
          );
          privacy = hasConsentAgreedByType(
            consents,
            consentExpressions,
            "PRIVACY"
          );
        }

        await bmapi.saveUser({
          ...values,
          rules: rules,
          privacy: privacy,
          birthday: values.birthday
            ? format(values.birthday, "yyyy-MM-dd")
            : "",
          metadata: {
            invited_by: values.invited_by,
            profile_version: bmapi.settings.profileVersion,
            registration_code: bmapi.getTenantData().rc || "",
          },
        });
        if (dynamicConsents) {
          const expr = getExpressionsObject(consents, consentExpressions);
          await bmapi.postConsentsSign(expr);
        }
        bmapi.setTenantData({ rc: false });
        stopLoading();
      } catch (e) {
        stopLoading();
        notifyError(getErrorMessageString(e, intl));
      }
    },
    [
      bmapi,
      intl,
      notifyError,
      startLoading,
      stopLoading,
      values,
      consents,
      consentExpressions,
    ]
  );

  const loadConsents = useCallback(async () => {
    if (bmapi.settings?.dynamicConsents) {
      try {
        let iconsents = await bmapi.getConsents();
        if (!Array.isArray(iconsents)) {
          iconsents = [];
        }
        setConsents(iconsents);
        setCanProceed(true);
      } catch (e) {
        setCanProceed(false);
        notifyError(getErrorMessageString(e, intl));
      }
    } else {
      setCanProceed(true);
      setConsents(false);
    }
  }, [bmapi, intl, notifyError]);

  useEffect(() => {
    stopLoading();
    /*
    const trace = getQueryVariable(location.search, URL_TRACE_PARAM);
    const iSubtitle =
      trace === URL_TRACE_VALUES.SUBSCRIPTION
        ? intl.formatMessage({
            id: "pages.subscriptionStartActivation.title",
            defaultMessage:
              "Per proseguire nell'acquisto è necessario completare l'account personale",
          })
        : null;
    */
    loadConsents();
    let iSubtitle = "";
    if (getSignInSubscriptionData()?.isSubscribing) {
      iSubtitle = intl.formatMessage({
        id: "pages.subscriptionStartActivation.title",
        defaultMessage:
          "Per proseguire nell'acquisto è necessario completare l'account personale",
      });
    }

    setSubtitle(iSubtitle);
  }, [stopLoading, intl, loadConsents]);

  const formatValues = getFormatter(bmapi);

  const activeFields = extraProfileFields.filter((i) =>
    bmapi.settings.profileRequired.includes(i.key)
  );

  return (
    <form onSubmit={accept}>
      <Container maxWidth="sm">
        <Title>{intl.formatMessage(activation.title)}</Title>
        {subtitle && (
          <Typography component="h1" variant="h6" style={{ marginBottom: 20 }}>
            {subtitle}
          </Typography>
        )}
        <Card>
          <CardMedia image={bmapi.logo()} className={classes.tosLogo} />
          <CardContent>
            {!!activeFields.length && (
              <React.Fragment>
                <Typography variant="h6" gutterBottom>
                  {intl.formatMessage(activation.completeProfile)}
                </Typography>
                {activeFields.map((field) => (
                  <field.Component
                    key={field.key}
                    value={values[field.key]}
                    onChange={handleChange(field.key)}
                  />
                ))}
              </React.Fragment>
            )}
            {bmapi.can(FEATURES.FRIEND_CODE) && (
              <InvitedBy
                value={values.invited_by}
                onChange={handleChange("invited_by")}
                formatValues={formatValues}
                disabled={Boolean(bmapi.userData?.invited_by)}
              />
            )}
            {!bmapi.settings?.dynamicConsents && (
              <>
                {bmapi.settings.profileRequired.includes("rules") && (
                  <Rules
                    value={values.rules}
                    onChange={handleChange("rules")}
                    formatValues={formatValues}
                  />
                )}
                {bmapi.settings.profileRequired.includes("privacy") && (
                  <Privacy
                    value={values.privacy}
                    onChange={handleChange("privacy")}
                    formatValues={formatValues}
                  />
                )}
                {bmapi.settings.profileRequired.includes(
                  "direct_marketing"
                ) && (
                  <DirectMarketing
                    value={values.direct_marketing}
                    onChange={handleChange("direct_marketing")}
                    formatValues={formatValues}
                  />
                )}
                {bmapi.settings.profileRequired.includes(
                  "indirect_marketing"
                ) && (
                  <IndirectMarketing
                    value={values.indirect_marketing}
                    onChange={handleChange("indirect_marketing")}
                    formatValues={formatValues}
                  />
                )}
              </>
            )}
            {bmapi.settings?.dynamicConsents &&
              consents &&
              consents
                .sort(compareByOrder)
                .map((consent) => (
                  <ConsentCheck
                    key={consent.id}
                    consent={consent}
                    formatValues={formatValues}
                    values={values}
                    onChange={handleConsentCheck}
                  />
                ))}
            <Box textAlign="center" mt={2}>
              {canProceed && (
                <Button variant="contained" color="primary" type="submit">
                  {intl.formatMessage(activation.confirm)}
                </Button>
              )}
              {!canProceed && (
                <>
                  <Typography variant="h6" color="error">
                    {intl.formatMessage(errors.error)}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={refreshPage}
                  >
                    {intl.formatMessage(common.refreshPage)}
                  </Button>
                </>
              )}
            </Box>
          </CardContent>
        </Card>
      </Container>
    </form>
  );
}
