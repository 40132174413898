import React, { useEffect } from "react";
import {
  Box,
  Chip,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItem,
  Typography,
} from "@material-ui/core";
import { useBmapi } from "../../utils/bmapi-context";
import { useIntl } from "react-intl";
import { getErrorMessageString } from "../../utils/errors";

export default function GenListDropdown(props) {
  const {
    listId,
    lang,
    emptyItem,
    compareFunction,
    value: pvalue,
    name,
    multiple,
    onChange,
    required,
    margin,
    label,
    itemTypes,
    businessId,
    refreshKey,
  } = props || {};

  const [listItems, setListItems] = React.useState([]);
  const [filteredItems, setFilteredItems] = React.useState([]);
  const [filterType, setFilterType] = React.useState(
    itemTypes != null && Array.isArray(itemTypes) ? itemTypes[0]?.id : null
  ); // Default to first type if available
  const [langIndex, setLangIndex] = React.useState(-1);
  const { bmapi, notifyError } = useBmapi();
  const intl = useIntl();
  const value = multiple && (!pvalue || !Array.isArray(pvalue)) ? [] : pvalue;

  useEffect(() => {
    let isMounted = true; // Track if component is still mounted

    const fetchItems = async () => {
      try {
        const genList = await bmapi.getGenListAdmin(listId, businessId);
        if (!isMounted) return; // Exit if component has unmounted

        let items = genList?.items || [];
        const allLangs = genList?.all_lang || [];
        const langIndex = allLangs.indexOf(lang);

        if (langIndex === -1) return;
        setLangIndex(langIndex);

        if (emptyItem) {
          items = [emptyItem, ...items];
        }
        if (compareFunction) {
          items = items.sort(compareFunction);
        }
        setListItems(items);
      } catch (e) {
        if (isMounted) {
          if (e.code === "NOT_FOUND") {
            setListItems([]);
          } else {
            notifyError(getErrorMessageString(e, intl));
          }
        }
      }
    };

    fetchItems();

    return () => {
      isMounted = false; // Cleanup on component unmount
    };
  }, [
    bmapi,
    emptyItem,
    listId,
    lang,
    compareFunction,
    intl,
    notifyError,
    businessId,
    refreshKey,
  ]);

  useEffect(() => {
    setFilteredItems(
      filterType
        ? listItems.filter((item) => item.type === filterType)
        : listItems
    );
  }, [listItems, filterType]);

  const ITEM_HEIGHT = 50;
  const ITEM_PADDING_TOP = 10;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300,
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
  };

  const onChangeValue = (event, selectedValue) => {
    if (event.target?.type === "checkbox") {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (onChange) {
      onChange(event, selectedValue);
    }
  };

  const handleDeleteChip = (chipValue) => {
    const newValue = value.filter((val) => val !== chipValue);
    onChange({ target: { value: newValue } });
  };

  return (
    <FormControl fullWidth required={required} margin={margin}>
      <InputLabel>{label || ""}</InputLabel>
      <Select
        name={name}
        multiple={multiple}
        value={value}
        onChange={onChangeValue}
        MenuProps={MenuProps}
        input={<Input />}
        renderValue={(selected) =>
          multiple ? (
            <Box style={{ display: "flex", flexWrap: "wrap" }}>
              {selected.map((sel) => {
                const selectedItem = listItems.find(
                  (item) => item.item_id === sel
                );
                const displayValue =
                  selectedItem &&
                  Array.isArray(selectedItem.values) &&
                  langIndex >= 0 &&
                  selectedItem.values[langIndex]?.str_value !== undefined
                    ? selectedItem.values[langIndex]?.str_value
                    : sel;

                return (
                  <Chip
                    key={sel}
                    label={displayValue}
                    onDelete={() => handleDeleteChip(sel)}
                    onMouseDown={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                    }}
                  />
                );
              })}
            </Box>
          ) : (
            selected
          )
        }
      >
        {/* Filter checkboxes within dropdown */}
        <ListItem disableGutters>
          {itemTypes?.map((type) => (
            <Box key={type.id} display="flex" alignItems="center">
              <Checkbox
                checked={filterType === type.id}
                onClick={(event) => {
                  event.stopPropagation(); // Prevent Select's onChange from firing
                  setFilterType(type.id);
                }}
                color="primary"
              />
              <Typography variant="body2">{type.label}</Typography>
            </Box>
          ))}
        </ListItem>

        {/* Render filtered list items */}
        {filteredItems.map((option) => {
          const displayValue =
            Array.isArray(option.values) &&
            langIndex >= 0 &&
            option.values[langIndex]?.str_value !== undefined
              ? option.values[langIndex]?.str_value
              : "No Data";
          return (
            <MenuItem key={option.item_id} value={option.item_id}>
              {displayValue}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
